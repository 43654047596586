import {attributeService} from "../services/attributeService";
import {ATTRIBUTE_TYPES} from "../types"
import { Notification } from "element-react"


const getAll = (token) => {
  return dispatch => {
    return attributeService.getAll(token).then(options => {
      return dispatch({
        type: ATTRIBUTE_TYPES.GET_ALL_ATTRIBUTE,
        payload : options
      })
    })
  }
};

const getAllRemoved = (token) => {
  return dispatch => {
    return attributeService.getAllRemoved(token).then(options => {
      return dispatch({
        type: ATTRIBUTE_TYPES.GET_ALL_REMOVED_ATTRIBUTE,
        payload : options
      })
    })
  }
};

const getAllNotRemoved = (token) => {
  return dispatch => {
    return attributeService.getAllNotRemoved(token).then(options => {
      return dispatch({
        type: ATTRIBUTE_TYPES.GET_ALL_NOT_REMOVED_ATTRIBUTE,
        payload : options
      })
    })
  }
};

const getItem = (id,token) => {
  return dispatch => {
    return attributeService.getItem(id,token).then(option => {
      return dispatch({
        type: ATTRIBUTE_TYPES.GET_ATTRIBUTE_ITEMS,
        payload : option
      })
    })
  }
};

const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : ATTRIBUTE_TYPES.SELECT_ATTRIBUTE_ITEMS,
      payload : elem
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return attributeService.getPaginationNotRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: ATTRIBUTE_TYPES.GET_PAG_NOT_REMOVED_ATTRIBUTES,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return attributeService.getPaginationRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: ATTRIBUTE_TYPES.GET_PAG_REMOVED_ATTRIBUTES,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt) => {
  return dispatch => {
    attributeService.remove(elem.attributeId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: ATTRIBUTE_TYPES.DELETE_ATTRIBUTE_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    attributeService.recover(elem.attributeId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: ATTRIBUTE_TYPES.RECOVER_ATTRIBUTE_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    attributeService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: ATTRIBUTE_TYPES.ARCHIVE_ATTRIBUTE_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    attributeService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: ATTRIBUTE_TYPES.BULK_RECOVER_ATTRIBUTE_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    attributeService.bulkDestroy(ids,  jwt).then((data) => {
      return dispatch({
        type: ATTRIBUTE_TYPES.BULK_DESTROY_ATTRIBUTE_ITEMS,
        payload : data
      })
    })
  }
};

const destroyAttribute = ( token, attribute ) => {

  return async dispatch => {

    try {

        await attributeService.destroy(token , attribute.attributeId)

        Notification.success( {
          title: 'attribute ',
          message: `${attribute.source} attribute succesfuly deleted `
        }, 20 )

        dispatch( {
            type: ATTRIBUTE_TYPES.DESTROY_ATTRIBUTE,
            payload: attribute
        } )
        
    } catch (e) {

        Notification.error( {
          title: e.name,
          message: `attribute not deleted`
        }, 20) 
       
      }
    }
  }


export {getAll,getAllRemoved,getItem,selectItem,getPaginationNotRemoved, getPaginationRemoved, deleteItem, recoverItem,  bulkDelete, bulkRecover, destroyAttribute, bulkDestroy, getAllNotRemoved}