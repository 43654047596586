import {FILTER_TYPES} from '../../types/index'


const defaultState = {
  pagRemovedFilter: [],
  pagNotRemovedFilter: [],
  pagRemovedFilterCount: 0,
  pagNotRemovedFilterCount: 0,
  filterSelect: [],
  filterGroupSelect: [],
  count: 0
};

const filter = (state = [], action = {}) => {
  if (action.type === FILTER_TYPES.GET_FILTERS) {
    return action.payload;
  } else {
    return state
  }
};

const deletedFilter = (state = [], action = {})=> {
  if (action.type === FILTER_TYPES.GET_DELETED_FILTERS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedFilter = (state = {}, action = {}) => {
  if (action.type === FILTER_TYPES.SELECT_FILTER_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const filters = ( state = defaultState, action = {}) => {
  switch (action.type) {
      case FILTER_TYPES.GET_PAG_REMOVED_FILTERS: {
          return {
            ...state,
            pagRemovedFilter: action.payload.data,
            pagRemovedFilterCount: action.payload.count

          }
      }

      case FILTER_TYPES.GET_PAG_NOT_REMOVED_FILTERS: {
          return {
            ...state,
            pagNotRemovedFilter: action.payload.data,
            pagNotRemovedFilterCount: action.payload.count
          }
      }

      case FILTER_TYPES.DELETE_FILTER_ITEMS: {
        const id = action.payload.filterGroupId;
        return {
            ...state,
            pagNotRemovedFilter: state.pagNotRemovedFilter.filter(item => item.filterGroupId !== id)
        }
      }

      case FILTER_TYPES.RECOVER_FILTER_ITEMS: {
        const id = action.payload.filterGroupId;
        state.pagNotRemovedFilter.unshift(action.payload)
        return {
            ...state,
            pagRemovedFilter: state.pagRemovedFilter.filter(item => item.filterGroupId !== id),
            pagNotRemovedFilter: state.pagNotRemovedFilter,
            pagNotRemovedFilterCount: state.pagNotRemovedFilterCount + 1
        }
      }

      case FILTER_TYPES.ARCHIVE_FILTER_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagNotRemovedFilterCount: state.pagNotRemovedFilterCount - action.payload.length
        }
      }

      case FILTER_TYPES.BULK_RECOVER_FILTER_ITEMS: {
          return { 
              ...state,
              bulkDeletedItems: action.payload,
              pagRemovedFilterCount: state.pagRemovedFilterCount - action.payload.length
        }
      }


      case FILTER_TYPES.FETCH_FILTER_SEE_ALL: {
        let newFilter = []
        action.payload.data.forEach(filterGroup => {
          filterGroup.filters.forEach(filterData => {
              newFilter.push({ value: filterData.filterId, label: filterData.filterId + ' ' + filterData.source })
          })
        })
        

        let filterSelect = [...state.filterSelect, ...newFilter]
      
        filterSelect = filterSelect.filter((elem, index, self) =>
          index === self.findIndex((e) => (
            e.value === elem.value
          ))
        )
      

        return {
            ...state,
            filterSelect: filterSelect,
            filterGroupSelect: [...state.filterGroupSelect, ...action.payload.data],
            pagNotRemovedFilterCount: action.payload.count
        }
    }

    case FILTER_TYPES.DESTROY_FILTER: {
      const id = action.payload.filterGroupId;
      return {
          ...state,
          pagRemovedFilter: state.pagRemovedFilter.filter(item => item.filterGroupId !== id),
          loading: false
      }
    }
  
    default:
            return state;
  }
}

export {filter, deletedFilter, selectedFilter, filters}