const GET_ALL_RECURRINGS = 'GET_ALL_RECURRINGS';
const GET_ALL_REMOVED_RECURRINGS = 'GET_ALL_REMOVED_RECURRINGS';
const GET_RECURRING_ITEMS = 'GET_RECURRING_ITEMS';
const SELECT_RECURRING_ITEMS = 'SELECT_RECURRING_ITEMS';
const ADD_RECURRING = 'ADD_RECURRING';
const DELETE_RECURRING = 'DELETE_RECURRING';
const RECOVER_RECURRING = 'RECOVER_RECURRING';
const REVIEW_FILTER_IS_OPEN = 'REVIEW_FILTER_IS_OPEN'

export {
  GET_ALL_RECURRINGS,
  GET_ALL_REMOVED_RECURRINGS,
  GET_RECURRING_ITEMS,
  SELECT_RECURRING_ITEMS,
  ADD_RECURRING,
  DELETE_RECURRING,
  RECOVER_RECURRING,
  REVIEW_FILTER_IS_OPEN
}

