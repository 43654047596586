import React, { Fragment } from 'react'
import {
    Label
} from 'reactstrap';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";


function FilterInput(props) { 

    const { elem, changeValue, styleName, value } = props

    return (
        <Fragment>
            <Label className={styleName} >{elem.name}</Label>
            {
                elem.key === 'phone' || elem.key === 'telephone'
                ?
                <PhoneInput
                    value={value}
                    onChange={(e) => changeValue({ target: { value: e || '' }}, elem.key)}
                    defaultCountry='US'
                    id='phone-input'
                /> 
                :
                <input  
                    type={ elem.type }
                    className={ elem.styleName }
                    value={ value || '' }
                    onChange= { (event) => changeValue(event, elem.key) }
                />
            }
        </Fragment>
    );
  }
  

export default FilterInput