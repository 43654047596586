import  { CART_TYPES } from '../types';
import config from '../../../config/app'
import axios from 'axios';
import qs from 'qs';

 class CartActions {
  constructor(_props) {
    this.api = config['CART_API']
    this.rest  = 's'
  }

    getStatistics = (jwt, query) => {
      return async dispatch => {
          try {
  
              const searchString = qs.stringify(query)
  
              dispatch({
                  type: CART_TYPES.CART_STATISTICS_P
              })

              const response = await axios.get(`${this.api + `${this.rest}?${searchString}`}`,
                  {
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                      }
                  }
              )
  
              dispatch({
                  type: CART_TYPES.CART_STATISTICS_F,
                  payload: response.data
              })
  
          } catch (e) {

              dispatch({
                  type: CART_TYPES.CART_STATISTICS_R,
                  payload: e
              })

          }
      }
  };
    
}




export default new CartActions()