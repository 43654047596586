const GET_ALL_USERS = 'GET_ALL_USERS';
const GET_ALL_REMOVED_USERS = 'GET_ALL_REMOVED_USERS';
const GET_USERS_ITEMS = 'GET_USERS_ITEMS';
const GET_USERS_FRANCHISE = 'GET_USERS_FRANCHISE';
const SELECT_USERS_ITEMS = 'SELECT_USERS_ITEMS';
const ADD_USERS = 'ADD_USERS';
const DELETE_USERS = 'DELETE_USERS';
const RECOVER_USERS = 'RECOVER_USERS';

export {
  GET_ALL_USERS,
  GET_ALL_REMOVED_USERS,
  GET_USERS_ITEMS,
  GET_USERS_FRANCHISE,
  SELECT_USERS_ITEMS,
  ADD_USERS,
  DELETE_USERS,
  RECOVER_USERS
}