import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../redux-modules/actions';
import ConfirmForm from './ConfirmPassword';
import './ForgotPassword.css';

function ForgotPassword({ registerPage, setRegisterPage, addQuery, getQueryValue }) {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state);
  const darkmode = false

  const darcModColor = darkmode ? '#ffffffdd' : '#000000dd'

  const [formbody, setFormbody] = useState({
    email: ''
  });

  const [control, setControl] = useState({
    email: false
  });

  const [response, setResponse] = useState({
    success: true,
    message: '',
    active: false,
  });

  const responseIcon = response.success ? 'check-circle' : 'x-circle'
  const responseColor = `${response.success ? '#00A19D' : '#B91646'}`
  const activeClassName = `${'response'} ${response.active ? 'active' : null}`
  const emailColor = `${control.email ? '#00A19D' : '#B91646'}`
  const emailIcon = control.email ? 'check-circle' : 'x-circle'

  useEffect(() => {
    return () => {
      dispatch(accountActions.clearError())
    }
  }, [])

  useEffect(() => {
    if (!getQueryValue('email')) {
      if (account.err && account.err.length) {

        setResponse({
          ...response,
          success: false,
          message: account.err[0].msg,
          active: true
        });

      } else if (account.forgotPassword) {

        setResponse({
          ...response,
          success: true,
          message: 'email successfully send',
          active: true
        });

        setFormbody({
          ...formbody, 
          email: ''
        });

        setTimeout(() => {
          addQuery({email: formbody.email})
        }, 1500);
        
      }
    }
  }, [account])


  const handleChange = (event) => {
    setFormbody({
      ...formbody,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!control.email) {
        throw new Error('Form data is not approved, please fill in the form correctly')
      }

      dispatch(accountActions.forgotPassword(formbody))

    }
    catch (error0) {
      setResponse((prev) => ({
        ...prev,
        success: false,
        message: error0.message,
        active: true,
      }));
    }
  }

  useEffect(() => {
    const emailValid = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (emailValid.test(formbody.email)) {
      setControl((prev) => ({
        ...prev,
        email: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        email: false,
      }));
    }

  }, [ formbody ]);

  return (
    <div
      className={`
        ${'login'}
        ${registerPage ? null : 'active'}
        ${darkmode ? 'dark' : null}
      `}
    >
      <div className={'login-wrap'}>
        <div className={'header'}>
          <h2>Forgot Password.</h2>
          <p>
            to continue forgot password
          </p>
          <p>
            your account, please enter your forgot password code that we have sent via Gmail.
          </p>
        </div>
        {
           getQueryValue('email') ? (
            <ConfirmForm
              email = {getQueryValue('email')}
              setRegisterPage={setRegisterPage}
            />
          ) : (
        <form
          method="post"
          className={'control'}
          onSubmit={handleSubmit}
        >
          <label htmlFor="email" className={'cards'}>
            <box-icon
              name="user"
              color={darcModColor}
            >
            </box-icon>
            <span className={'input-field'}>
              <p className={'label'}>Email Address</p>
              <input
                type="text"
                name="email"
                id="email"
                value={formbody.email}
                onChange={handleChange}
                required
              />
            </span>
            <box-icon
              name={emailIcon}
              color={emailColor}
            >
            </box-icon>
          </label>
          <div className={activeClassName}>
            <box-icon
              name={responseIcon}
              color={responseColor}
            >
            </box-icon>
            <p>{response.message}</p>
          </div>
          <span className={'submit-button'}>
            <button type="submit" className={'btn'}>
              Submit
              <box-icon
                type="solid"
                name="right-top-arrow-circle"
                color={darcModColor}
              >
              </box-icon>
            </button>
          </span>
        </form>
        )
       }
        <div className={'footer'}>
            <p className={'text'}>Already have an account?</p>
            <button
                className={'btn'}
                onClick={setRegisterPage}
            >
                Login
            </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
