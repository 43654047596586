import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../redux-modules/actions';
import PasswordValidation from '../PasswordValidation';
import ReCAPTCHA from 'react-google-recaptcha';
import '../ConfirmRegister.css';

function ConfirmPassword({ setRegisterPage, email}) {
  const darkmode = false;
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state);
  const [passwordShown, setPasswordShown] = useState(false)
  const RECAPTCHA_SITE_KEY = '6LcsuoQeAAAAADI5Ey7F0odVrPWOxOlY77cQ3yXN';
  const [resend, setResend] = useState(false) 

  const [control, setControl] = useState({
    key: false,
    password: false
  });

  const [formbody, setFormbody] = useState({
    key: '',
    password: '',
  });

  const [response, setResponse] = useState({
    success: true,
    message: '',
    active: false,
  });

  const handleChange = (event) => {
    setFormbody({
      ...formbody,
      [event.target.name]: event.target.value,
    });
  }

  useEffect(() => {

    if (account.err && account.err.length) {

      setResponse({
        ...response,
        success: false,
        message: account.err[0].msg,
        active: true
      });

    } else if (account.resetPassword) {

      setResponse((prev) => ({
        ...prev,
        success: true,
        message: 'You have completed all stages of registration, now it\'s time to log in to your account',
        active: true,
      }));

      setTimeout(() => {
        setRegisterPage();
      }, 1500);
      
    } else if (account.resendCode) {

      setResponse((prev) => ({
        ...prev,
        success: true,
        message: 'resend code success',
        active: true,
      }));

    }
  }, [account])

  const handleSubmit = async (event) => {
    try {
      
      event.preventDefault();

      if (!control.key || !control.password) {
        throw new Error('Form data is not approved, please fill in the form correctly');
      }

      dispatch(accountActions.resetPassword({
        ...formbody,
        email: email,
        code: formbody.key
      }))

    }
    catch (error0) {
      setResponse((prev) => ({
        ...prev,
        success: false,
        message: error0.message,
        active: true,
      }));
    }
  }

  useEffect(() => {
    const validation = /^[0-9]*$/g.test(formbody.key);
    if (
      validation && formbody.key.length === 4
    ) {
      setControl((prev) => ({
        ...prev,
        key: true
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        key: false
      }));
    }

    const passValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\W).{8,26}$/g;

    if (passValid.test(formbody.password)) {
      setControl((prev) => ({
        ...prev,
        password: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        password: false,
      }));
    }

  }, [formbody]);

  const handleCaptchaChange = (value) => {
    dispatch(accountActions.resendCode({email, captcha: value}))
    setResend(false)
  };

  return (
    <form
      method="post"
      className={`${'form'} ${darkmode ? 'dark' : null}`}
      onSubmit={handleSubmit}
    >
      <label htmlFor="key" className={'cards'}>
        <box-icon
          name="key"
          color={darkmode ? '#ffffffdd' : '#000000dd'}
        >
        </box-icon>
        <span className={'input-field'}>
          <p className={'label'}>4 digit code</p>
          <input
            type="key"
            name="key"
            id="key"
            placeholder="Enter your registration code"
            value={formbody.key}
            onChange={handleChange}
            required
          />
        </span>
        <box-icon
          name={control.key ? 'check-circle' : 'x-circle'}
          color={`${control.key ? '#00A19D' : '#B91646'}`}
        >
        </box-icon>
      </label>
      <label htmlFor="password-regis" className={'cards'}>
        <box-icon
          name={passwordShown ? "lock-open" : "lock"}
          color={darkmode ? '#ffffffdd' : '#000000dd'}
          onClick={() => setPasswordShown(!passwordShown)}
        >
        </box-icon>
        <span className={'input-field'}>
          <p className={'label'}>Password</p>
          <input
            type={passwordShown ? "text" : "password"}
            name="password"
            id="password-regis"
            placeholder="Must contain A-Za-z, 0-9 & symbols"
            value={formbody.password}
            onChange={handleChange}
            required
          />
        </span>
        <box-icon
          name={control.password ? 'check-circle' : 'x-circle'}
          color={`${control.password ? '#00A19D' : '#B91646'}`}
        >
        </box-icon>
      </label>
      <PasswordValidation
        password={formbody.password}
      />
      <div className={`${'response'} ${response.active ? 'active' : null}`}>
        <box-icon
          name={`${response.success ? 'check-circle' : 'x-circle'}`}
          color={`${response.success ? '#00A19D' : '#B91646'}`}
        >
        </box-icon>
        <p>{response.message}</p>
      </div>
      {
        !resend ?
        <p 
          className="label"
          onClick={() => {
            setResponse({
              ...response,
              message: '', 
              active: false
            })
            dispatch(accountActions.clearError())
            setResend(true)
            setResend(true)
          }} 
          style={{cursor: 'pointer', textDecoration: 'underline'}}>
            Resend
        </p>
        :
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleCaptchaChange} />
      }
      <span className={'submit'}>
        <button type="submit" className={'btn'}>
          Submit
          <box-icon
            type="solid"
            name="right-top-arrow-circle"
            color={darkmode ? '#ffffffdd' : '#000000dd'}
          >
          </box-icon>
        </button>
      </span>
    </form>
  );
}

export default ConfirmPassword;
