import React from "react";
import './Add.scss';

function Add (props) {
    let { item, style, updateProduct } = props

    function increment(item) {
        item.quantity = item.quantity + 1
        updateProduct(item)
    }
    
    function decrement(item) {
        item.quantity = item.quantity - 1
        updateProduct(item)
    }

    return (
            <>
                {
                    item.quantity ?
                    <div className="product-quantity">
                        <button 
                            className="product-quantity__btn product-quantity__btn--minus" 
                            onClick={() => decrement(item)} aria-label="product deduct">
                            <svg className="product-quantity__icon">
                                <use xlinkHref={"/assets/svg/sprite.svg#quantity-minus"} />
                            </svg>
                        </button>
                        <div className="product-quantity__number">{item.quantity}</div>
                        <button 
                            className="product-quantity__btn product-quantity__btn--plus" 
                            onClick={()  => increment(item)} aria-label="product append">
                            <svg className="product-quantity__icon">
                                <use xlinkHref={"/assets/svg/sprite.svg#quantity-plus"} />
                            </svg>
                        </button>
                    </div>
                    :
                    <button
                        onClick={() => updateProduct({ ...item, quantity: 1 })} 
                        className={style.className}>
                        <span className="shop__product-btn-text">add</span>      
                    </button>
                }
            </>
    )
}

export default Add;