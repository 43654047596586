const SMS_PHONE_PAG_F = 'SMS_PHONE_PAG_F';
const SMS_PHONE_PAG_R = 'SMS_PHONE_PAG_R';
const SMS_PHONE_SIZE_CHANGE = 'SMS_PHONE_SIZE_CHANGE'
const SMS_PHONE_PAGE_CHANGE = 'SMS_PHONE_PAGE_CHANGE'
const SMS_PHONE_FILETR_TABE_CHANGE = 'SMS_PHONE_FILETR_TABE_CHANGE'
const SMS_PHONE_CHECKBOX_CHANGE = 'SMS_PHONE_CHECKBOX_CHANGE'
const SMS_PHONE_BULK_ARCIVE_F = 'SMS_PHONE_BULK_ARCIVE_F'
const SMS_PHONE_BULK_ARCIVE_R = 'SMS_PHONE_BULK_ARCIVE_R'
const SMS_PHONE_BULK_DESTROY_F = 'SMS_PHONE_BULK_DESTROY_F'
const SMS_PHONE_BULK_DESTROY_R = 'SMS_PHONE_BULK_DESTROY_R'
const SMS_PHONE_TAB_PANEL_CHANGE = 'SMS_PHONE_TAB_PANEL_CHANGE'
const SMS_PHONE_DATA_FILTER = 'SMS_PHONE_DATA_FILTER'
const SMS_PHONE_DELETE_F = 'SMS_PHONE_DELETE_F'
const SMS_PHONE_FILTER_IS_OPEN = 'SMS_PHONE_FILTER_IS_OPEN'
const SMS_PHONE_DATA_FILTER_SEARCH = 'SMS_PHONE_DATA_FILTER_SEARCH'
const SMS_PHONE_SELECT_FILTER_TYPES = 'SMS_PHONE_SELECT_FILTER_TYPES'
const SMS_PHONE_DATA_FILTER_ALL_SEARCH_RESET = 'SMS_PHONE_DATA_FILTER_ALL_SEARCH_RESET'
const SMS_PHONE_GET_ONE_P = 'SMS_PHONE_GET_ONE_P'
const SMS_PHONE_GET_ONE_F = 'SMS_PHONE_GET_ONE_F'
const SMS_PHONE_GET_ONE_R = 'SMS_PHONE_GET_ONE_R'
const SMS_PHONE_DATA_MIN_MAX_F = 'SMS_PHONE_DATA_MIN_MAX_F'
const SMS_PHONE_CREATE_F = 'SMS_PHONE_CREATE_F'
const SMS_PHONE_CREATE_R = 'SMS_PHONE_CREATE_R'
const SMS_PHONE_UPDATE_F = 'SMS_PHONE_UPDATE_F'
const SMS_PHONE_UPDATE_R = 'SMS_PHONE_UPDATE_R'
const NEW_SMS_PHONE = 'NEW_SMS_PHONE'

export {
    SMS_PHONE_PAG_R,
    SMS_PHONE_PAG_F,
    SMS_PHONE_SIZE_CHANGE,
    SMS_PHONE_PAGE_CHANGE,
    SMS_PHONE_FILETR_TABE_CHANGE,
    SMS_PHONE_CHECKBOX_CHANGE,
    SMS_PHONE_BULK_ARCIVE_F,
    SMS_PHONE_BULK_ARCIVE_R,
    SMS_PHONE_BULK_DESTROY_R,
    SMS_PHONE_BULK_DESTROY_F,
    SMS_PHONE_TAB_PANEL_CHANGE,
    SMS_PHONE_DATA_FILTER,
    SMS_PHONE_DELETE_F,
    SMS_PHONE_FILTER_IS_OPEN,
    SMS_PHONE_DATA_FILTER_SEARCH,
    SMS_PHONE_SELECT_FILTER_TYPES,
    SMS_PHONE_DATA_FILTER_ALL_SEARCH_RESET,
    SMS_PHONE_GET_ONE_P,
    SMS_PHONE_GET_ONE_F,
    SMS_PHONE_GET_ONE_R,
    SMS_PHONE_DATA_MIN_MAX_F,
    SMS_PHONE_CREATE_F,
    SMS_PHONE_CREATE_R,
    SMS_PHONE_UPDATE_F,
    SMS_PHONE_UPDATE_R,
    NEW_SMS_PHONE
}