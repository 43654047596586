const STORE_PAG_F = 'STORE_PAG_L_F';
const STORE_PAG_R = 'STORE_PAG_L_R';
const STORE_SIZE_CHANGE = 'STORE_SIZE_CHANGE'
const STORE_PAGE_CHANGE = 'STORE_PAGE_CHANGE'
const STORE_FILETR_TABE_CHANGE = 'STORE_FILETR_TABE_CHANGE'
const STORE_UPDATE_F = 'STORE_UPDATE_F'
const STORE_UPDATE_R = 'STORE_UPDATE_R'
const STORE_CHECKBOX_CHANGE = 'STORE_CHECKBOX_CHANGE'
const STORE_BULK_ARCIVE_F = 'STORE_BULK_ARCIVE_F'
const STORE_BULK_ARCIVE_R = 'STORE_BULK_ARCIVE_R'
const STORE_BULK_DESTROY_F = 'STORE_BULK_DESTROY_F'
const STORE_BULK_DESTROY_R = 'STORE_BULK_DESTROY_R'
const STORE_TAB_PANEL_CHANGE = 'STORE_TAB_PANEL_CHANGE'
const STORE_CREATE_F = 'STORE_CREATE_F'
const STORE_CREATE_R = 'STORE_CREATE_R'
const STORE_GET_ONE_F = 'STORE_GET_ONE_F'
const STORE_GET_ONE_R = 'STORE_GET_ONE_R'
const STORE_REQUIRED_VALIDATION_F = 'STORE_REQUIRED_VALIDATION_F'
const STORE_REQUIRED_VALIDATION_R = 'STORE_REQUIRED_VALIDATION_R'
const NEW_STORE = 'NEW_STORE'
const STORE_DATA_FILTER = 'STORE_DATA_FILTER'
const STORE_DELETE_F = 'STORE_DELETE_F'
const STORE_DELETE_R = 'STORE_DELETE_R'
const STORE_LIVE_DATA = 'STORE_LIVE_DATA'
const STORE_DATA_FILTER_SEARCH = 'STORE_DATA_FILTER_SEARCH'
const STORE_FILTER_IS_OPEN = 'STORE_FILTER_IS_OPEN'
const STORE_LANGUAGE_PANEL_CHANGE = 'STORE_LANGUAGE_PANEL_CHANGE'
const STORE_DATA_FILTER_SEARCH_RESET = 'STORE_DATA_FILTER_SEARCH_RESET'
const STORE_DATA_FILTER_ALL_SEARCH_RESET = 'STORE_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_STORE_SEE_ALL_P = 'FETCH_STORE_SEE_ALL_P'
const FETCH_STORE_SEE_ALL_F = 'FETCH_STORE_SEE_ALL_F'
const FETCH_STORE_SEE_ALL_R = 'FETCH_STORE_SEE_ALL_R'
const STORE_VERIFY_P = 'STORE_VERIFY_P'
const STORE_VERIFY_F = 'STORE_VERIFY_F'
const STORE_VERIFY_R = 'STORE_VERIFY_R'
const STORE_CHANGE_FLOW_P = 'STORE_CHANGE_FLOW_P'
const STORE_CHANGE_FLOW_F = 'STORE_CHANGE_FLOW_F'
const STORE_CHANGE_FLOW_R = 'STORE_CHANGE_FLOW_R'
const STORE_ADD_DOMAIN_P = 'STORE_ADD_DOMAIN_P'
const STORE_ADD_DOMAIN_F = 'STORE_ADD_DOMAIN_F'
const STORE_ADD_DOMAIN_R = 'STORE_ADD_DOMAIN_R'
const FETCH_STORE_CALL_MIN_MAX_P = 'FETCH_STORE_CALL_MIN_MAX_P'
const FETCH_STORE_CALL_MIN_MAX_F = 'FETCH_STORE_CALL_MIN_MAX_F'
const FETCH_STORE_CALL_MIN_MAX_R = 'FETCH_STORE_CALL_MIN_MAX_R'
const FETCH_STORE_CUSTOMER_MIN_MAX_P = 'FETCH_STORE_CUSTOMER_MIN_MAX_P'
const FETCH_STORE_CUSTOMER_MIN_MAX_F = 'FETCH_STORE_CUSTOMER_MIN_MAX_F'
const FETCH_STORE_CUSTOMER_MIN_MAX_R = 'FETCH_STORE_CUSTOMER_MIN_MAX_R'
const FETCH_STORE_ORDER_COUNT_MIN_MAX_P = 'FETCH_STORE_ORDER_COUNT_MIN_MAX_P'
const FETCH_STORE_ORDER_COUNT_MIN_MAX_F = 'FETCH_STORE_ORDER_COUNT_MIN_MAX_F'
const FETCH_STORE_ORDER_COUNT_MIN_MAX_R = 'FETCH_STORE_ORDER_COUNT_MIN_MAX_R'
const FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_P = 'FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_P'
const FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_F = 'FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_F'
const FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_R = 'FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_R'
const FETCH_STORE_ORDER_TOTAL_MIN_MAX_P = 'FETCH_STORE_ORDER_TOTAL_MIN_MAX_P'
const FETCH_STORE_ORDER_TOTAL_MIN_MAX_F = 'FETCH_STORE_ORDER_TOTAL_MIN_MAX_F'
const FETCH_STORE_ORDER_TOTAL_MIN_MAX_R = 'FETCH_STORE_ORDER_TOTAL_MIN_MAX_R'
const STORE_UPDATE_PRODUCTS_F = 'STORE_UPDATE_PRODUCTS_F'
const STORE_UPDATE_PRODUCTS_R = 'STORE_UPDATE_PRODUCTS_R'
const STORE_GET_ADDRESS_F = 'STORE_GET_ADDRESS_F'
const STORE_GET_ADDRESS_R = 'STORE_GET_ADDRESS_R'
const CLEAR_SELECTED_STORE = 'CLEAR_SELECTED_STORE'

export {
    STORE_PAG_F,
    STORE_PAG_R,
    STORE_SIZE_CHANGE,
    STORE_PAGE_CHANGE,
    STORE_FILETR_TABE_CHANGE,
    STORE_UPDATE_F,
    STORE_UPDATE_R,
    STORE_CHECKBOX_CHANGE,
    STORE_BULK_ARCIVE_F,
    STORE_BULK_ARCIVE_R,
    STORE_BULK_DESTROY_F,
    STORE_BULK_DESTROY_R,
    STORE_TAB_PANEL_CHANGE,
    STORE_CREATE_F,
    STORE_CREATE_R,
    STORE_GET_ONE_F,
    STORE_GET_ONE_R,
    STORE_REQUIRED_VALIDATION_F,
    STORE_REQUIRED_VALIDATION_R,
    NEW_STORE,
    STORE_DATA_FILTER,
    STORE_DELETE_F,
    STORE_DELETE_R,
    STORE_LIVE_DATA,
    STORE_DATA_FILTER_SEARCH,
    STORE_FILTER_IS_OPEN,
    STORE_LANGUAGE_PANEL_CHANGE,
    STORE_DATA_FILTER_SEARCH_RESET,
    STORE_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_STORE_SEE_ALL_P,
    FETCH_STORE_SEE_ALL_F,
    FETCH_STORE_SEE_ALL_R,
    STORE_VERIFY_P,
    STORE_VERIFY_F,
    STORE_VERIFY_R,
    STORE_CHANGE_FLOW_P,
    STORE_CHANGE_FLOW_F,
    STORE_CHANGE_FLOW_R,
    STORE_ADD_DOMAIN_P,
    STORE_ADD_DOMAIN_F,
    STORE_ADD_DOMAIN_R,
    FETCH_STORE_CALL_MIN_MAX_P,
    FETCH_STORE_CALL_MIN_MAX_F,
    FETCH_STORE_CALL_MIN_MAX_R,
    FETCH_STORE_CUSTOMER_MIN_MAX_P,
    FETCH_STORE_CUSTOMER_MIN_MAX_F,
    FETCH_STORE_CUSTOMER_MIN_MAX_R,
    FETCH_STORE_ORDER_COUNT_MIN_MAX_P,
    FETCH_STORE_ORDER_COUNT_MIN_MAX_F,
    FETCH_STORE_ORDER_COUNT_MIN_MAX_R,
    FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_P,
    FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_F,
    FETCH_STORE_DONE_ORDER_COUNT_MIN_MAX_R,
    FETCH_STORE_ORDER_TOTAL_MIN_MAX_P,
    FETCH_STORE_ORDER_TOTAL_MIN_MAX_F,
    FETCH_STORE_ORDER_TOTAL_MIN_MAX_R,
    STORE_UPDATE_PRODUCTS_F,
    STORE_UPDATE_PRODUCTS_R,
    STORE_GET_ADDRESS_F,
    STORE_GET_ADDRESS_R,
    CLEAR_SELECTED_STORE
}