const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
const GET_ALL_REMOVED_PRODUCTS = 'GET_ALL_REMOVED_PRODUCTS';
const GET_PRODUCT_ITEMS = 'GET_PRODUCT_ITEMS';
const SELECT_PRODUCT_ITEMS = 'SELECT_PRODUCT_ITEMS';
const ADD_PRODUCT = 'ADD_PRODUCT';
const DESTROY_PRODUCT = 'DESTROY_PRODUCT';
const DELETE_PRODUCT = 'DELETE_PRODUCT';
const RECOVER_PRODUCT = 'RECOVER_PRODUCT';
const GET_PAG_NOT_REMOVED_PRODUCTS_P = 'GET_PAG_NOT_REMOVED_PRODUCTS_P'
const GET_PAG_NOT_REMOVED_PRODUCTS_F = 'GET_PAG_NOT_REMOVED_PRODUCTS_F'
const GET_PAG_NOT_REMOVED_PRODUCTS_R = 'GET_PAG_NOT_REMOVED_PRODUCTS_R'
const GET_PAG_REMOVED_PRODUCTS_P = 'GET_PAG_REMOVED_PRODUCTS_P'
const GET_PAG_REMOVED_PRODUCTS_F = 'GET_PAG_REMOVED_PRODUCTS_F'
const GET_PAG_REMOVED_PRODUCTS_R = 'GET_PAG_REMOVED_PRODUCTS_R'
const DELETE_PRODUCT_ITEMS = 'DELETE_PRODUCT_ITEMS'
const RECOVER_PRODUCT_ITEMS = 'RECOVER_PRODUCT_ITEMS'
const BULK_RECOVER_PRODUCT_ITEMS = 'BULK_RECOVER_PRODUCT_ITEMS'
const ARCHIVE_PRODUCT_ITEMS = 'ARCHIVE_PRODUCT_ITEMS'
const SAVE_PRODUCT_F = 'SAVE_PRODUCT_F'
const UPDATE_PRODUCT_F = 'UPDATE_PRODUCT_F'
const PRODUCT_PREVIEW_F = 'PRODUCT_PREVIEW_F'
const PRODUCTS_DATA_FILTER = 'PRODUCTS_DATA_FILTER'
const PRODUCTS_PAG_F = 'PRODUCTS_PAG_F'
const PRODUCTS_PAG_R = 'PRODUCTS_PAG_R'
const PRODUCTS_OUTSIDE_CLICK_CHANGE = 'PRODUCTS_OUTSIDE_CLICK_CHANGE'
const PRODUCT_LANG_TAB = 'PRODUCT_LANG_TAB'
const BULK_DESTROY_PRODUCT_ITEMS = 'BULK_DESTROY_PRODUCT_ITEMS'
const PRODUCT_TAB_PANEL_CHANGE = 'PRODUCT_TAB_PANEL_CHANGE'
const PRODUCT_FILTER_IS_OPEN = 'PRODUCT_FILTER_IS_OPEN'
const PRODUCT_DATA_FILTER_SEARCH = 'PRODUCT_DATA_FILTER_SEARCH'
const GET_PRODUCTS_N_P = 'GET_PRODUCTS_N_P'
const GET_PRODUCTS_R_P = 'GET_PRODUCTS_R_P'
const PRODUCT_DATA_FILTER_SEARCH_RESET = 'PRODUCT_DATA_FILTER_SEARCH_RESET'
const PRODUCT_DATA_FILTER_ALL_SEARCH_RESET = 'PRODUCT_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_PRODUCT_SEE_ALL = 'FETCH_PRODUCT_SEE_ALL'
const UPLOAD_PRODUCTS_FILES_P = 'UPLOAD_PRODUCTS_FILES_P'
const UPLOAD_PRODUCTS_FILES_F = 'UPLOAD_PRODUCTS_FILES_F'
const UPLOAD_PRODUCTS_FILES_R = 'UPLOAD_PRODUCTS_FILES_R'
const IMPORT_PRODUCTS_P = 'IMPORT_PRODUCTS_P'
const IMPORT_PRODUCTS_F = 'IMPORT_PRODUCTS_F'
const IMPORT_PRODUCTS_R = 'IMPORT_PRODUCTS_R'
const EXPORT_PRODUCTS_P = 'EXPORT_PRODUCTS_P'
const EXPORT_PRODUCTS_F = 'EXPORT_PRODUCTS_F'
const EXPORT_PRODUCTS_R = 'EXPORT_PRODUCTS_R'
const GET_PRODUCT_CATEGORIES_P = 'GET_PRODUCT_CATEGORIES_P'
const GET_PRODUCT_CATEGORIES_F = 'GET_PRODUCT_CATEGORIES_F'
const GET_PRODUCT_CATEGORIES_R = 'GET_PRODUCT_CATEGORIES_R'
const GET_PRODUCT_BRANDS_P = 'GET_PRODUCT_BRANDS_P'
const GET_PRODUCT_BRANDS_F = 'GET_PRODUCT_BRANDS_F'
const GET_PRODUCT_BRANDS_R = 'GET_PRODUCT_BRANDS_R'
const DUBLICATE_PRODUCTS_P = 'DUBLICATE_PRODUCTS_P'
const DUBLICATE_PRODUCTS_F = 'DUBLICATE_PRODUCTS_F'
const DUBLICATE_PRODUCTS_R = 'DUBLICATE_PRODUCTS_R'
const CLEAR_PRODUCT_SEE_ALL = 'CLEAR_PRODUCT_SEE_ALL'
const GET_PAG_ALL_PRODUCTS_P = 'GET_PAG_ALL_PRODUCTS_P'
const GET_PAG_ALL_PRODUCTS_F = 'GET_PAG_ALL_PRODUCTS_F'
const GET_PAG_ALL_PRODUCTS_R = 'GET_PAG_ALL_PRODUCTS_R'

export {
  GET_ALL_PRODUCTS,
  GET_ALL_REMOVED_PRODUCTS,
  GET_PRODUCT_ITEMS,
  SELECT_PRODUCT_ITEMS,
  ADD_PRODUCT,
  DESTROY_PRODUCT,
  DELETE_PRODUCT,
  RECOVER_PRODUCT,
  GET_PAG_NOT_REMOVED_PRODUCTS_P,
  GET_PAG_NOT_REMOVED_PRODUCTS_F,
  GET_PAG_NOT_REMOVED_PRODUCTS_R,
  GET_PAG_REMOVED_PRODUCTS_P,
  GET_PAG_REMOVED_PRODUCTS_F,
  GET_PAG_REMOVED_PRODUCTS_R,
  DELETE_PRODUCT_ITEMS,
  RECOVER_PRODUCT_ITEMS,
  BULK_RECOVER_PRODUCT_ITEMS,
  ARCHIVE_PRODUCT_ITEMS,
  SAVE_PRODUCT_F,
  UPDATE_PRODUCT_F,
  PRODUCT_PREVIEW_F,
  PRODUCTS_DATA_FILTER,
  PRODUCTS_PAG_F,
  PRODUCTS_PAG_R,
  PRODUCTS_OUTSIDE_CLICK_CHANGE,
  PRODUCT_LANG_TAB,
  BULK_DESTROY_PRODUCT_ITEMS,
  PRODUCT_TAB_PANEL_CHANGE,
  PRODUCT_FILTER_IS_OPEN,
  PRODUCT_DATA_FILTER_SEARCH,
  GET_PRODUCTS_R_P,
  GET_PRODUCTS_N_P,
  PRODUCT_DATA_FILTER_SEARCH_RESET,
  PRODUCT_DATA_FILTER_ALL_SEARCH_RESET,
  FETCH_PRODUCT_SEE_ALL,
  UPLOAD_PRODUCTS_FILES_P,
  UPLOAD_PRODUCTS_FILES_F,
  UPLOAD_PRODUCTS_FILES_R,
  IMPORT_PRODUCTS_P,
  IMPORT_PRODUCTS_F,
  IMPORT_PRODUCTS_R,
  EXPORT_PRODUCTS_P,
  EXPORT_PRODUCTS_F,
  EXPORT_PRODUCTS_R,
  GET_PRODUCT_CATEGORIES_P,
  GET_PRODUCT_CATEGORIES_F,
  GET_PRODUCT_CATEGORIES_R,
  GET_PRODUCT_BRANDS_P,
  GET_PRODUCT_BRANDS_F,
  GET_PRODUCT_BRANDS_R,
  DUBLICATE_PRODUCTS_P,
  DUBLICATE_PRODUCTS_F,
  DUBLICATE_PRODUCTS_R,
  CLEAR_PRODUCT_SEE_ALL,
  GET_PAG_ALL_PRODUCTS_P,
  GET_PAG_ALL_PRODUCTS_F,
  GET_PAG_ALL_PRODUCTS_R
}