import {
    setAndShow,
    setAndShowWithMessage
} from "../../redux-modules/actions/dialogActions";

export function showCancelOrderDialog(dispatch, data) {
    dispatch(setAndShow({content: 'cancelOrder', data: data}));
}

export function showStaffDialog(dispatch, data) {
    dispatch(setAndShow({content: 'staffDialog', data: data}));
}

export function showInfoDialogWithMessage(dispatch, {message}) {
    dispatch(setAndShowWithMessage({content: 'infoDialog', message}));
}
