const GET_LINK = 'GET_LINK';
const GET_DELETED_LINK = 'GET_DELETED_LINK';
const SELECT_LINK_ITEMS = 'SELECT_LINK_ITEMS';
const GET_LINK_ITEM = 'GET_LINK_ITEM';
const GET_PAG_NOT_REMOVED_LINKS = 'GET_PAG_NOT_REMOVED_LINKS';
const GET_PAG_REMOVED_LINKS = 'GET_PAG_REMOVED_LINKS';
const DELETE_LINK_ITEMS = 'DELETE_LINK_ITEMS';
const RECOVER_LINK_ITEMS = 'RECOVER_LINK_ITEMS';
const ARCHIVE_LINK_ITEMS = 'ARCHIVE_LINK_ITEMS';
const BULK_RECOVER_LINK_ITEMS = 'BULK_RECOVER_LINK_ITEMS';
const NEW_LINK_FULFILLED = 'NEW_LINK_FULFILLED';
const NEW_LINK_REJECTED = 'NEW_LINK_REJECTED';
const SAVE_LINK_FULFILLED = 'SAVE_LINK_FULFILLED';
const SAVE_LINK_REJECTED = 'SAVE_LINK_REJECTED';
const FETCH_LINK_FULFILLED = 'FETCH_LINK_FULFILLED';
const FETCH_LINK_PENDING = 'FETCH_LINK_PENDING'
const UPDATE_LINK_FULFILLED = 'UPDATE_LINK_FULFILLED';
const LANG_TAB = 'LANG_TAB';
const DESTROY_LINK = 'DESTROY_LINK';
const BULK_DESTROY_LINK_ITEMS = 'BULK_DESTROY_LINK_ITEMS'
const LINK_TAB_PANEL_CHANGE = 'LINK_TAB_PANEL_CHANGE'
const LINK_FILTER_IS_OPEN = 'LINK_FILTER_IS_OPEN'
const LINK_DATA_FILTER = 'LINK_DATA_FILTER'
const LINK_DATA_FILTER_SEARCH = 'LINK_DATA_FILTER_SEARCH'
const LINK_DATA_MIN_MAX_F = 'LINK_DATA_MIN_MAX_F'
const LINK_DATA_FILTER_SEARCH_RESET = 'LINK_DATA_FILTER_SEARCH_RESET'
const LINK_DATA_FILTER_ALL_SEARCH_RESET = 'LINK_DATA_FILTER_ALL_SEARCH_RESET'
const GET_STATISTICS_P = 'GET_STATISTICS_P'
const GET_STATISTICS_F = 'GET_STATISTICS_F'
const GET_STATISTICS_R = 'GET_STATISTICS_R'
const GET_ALL_STATISTICS_P = 'GET_ALL_STATISTICS_P'
const GET_ALL_STATISTICS_F = 'GET_ALL_STATISTICS_F'
const GET_ALL_STATISTICS_R = 'GET_ALL_STATISTICS_R' 

export {
  GET_LINK,
  GET_DELETED_LINK,
  SELECT_LINK_ITEMS,
  GET_LINK_ITEM,
  GET_PAG_NOT_REMOVED_LINKS,
  GET_PAG_REMOVED_LINKS,
  DELETE_LINK_ITEMS,
  RECOVER_LINK_ITEMS,
  ARCHIVE_LINK_ITEMS,
  BULK_RECOVER_LINK_ITEMS,
  NEW_LINK_FULFILLED,
  NEW_LINK_REJECTED,
  SAVE_LINK_FULFILLED,
  SAVE_LINK_REJECTED,
  FETCH_LINK_FULFILLED,
  FETCH_LINK_PENDING,
  UPDATE_LINK_FULFILLED,
  LANG_TAB,
  DESTROY_LINK,
  BULK_DESTROY_LINK_ITEMS,
  LINK_TAB_PANEL_CHANGE,
  LINK_FILTER_IS_OPEN,
  LINK_DATA_FILTER,
  LINK_DATA_FILTER_SEARCH,
  LINK_DATA_MIN_MAX_F,
  LINK_DATA_FILTER_SEARCH_RESET,
  LINK_DATA_FILTER_ALL_SEARCH_RESET,
  GET_STATISTICS_P,
  GET_STATISTICS_F,
  GET_STATISTICS_R,
  GET_ALL_STATISTICS_P,
  GET_ALL_STATISTICS_F,
  GET_ALL_STATISTICS_R
}