import { CALL_GROUP_TYPES } from '../../types'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
    data: [],
    callGroup: {},
    error: {},
    count: 0,
    pageNumber: {
        removed: localStorage.getItem('removedCallGroupPage') || 1,
        notRemoved: localStorage.getItem('notRemovedCallGroupPage') || 1
    },
    showEntries: {
        removed: localStorage.getItem('removedCallGroupPageSize') || 10,
        notRemoved: localStorage.getItem('notRemovedCallGroupPageSize') || 10
    },
    langTab : {
        languageId: 1,
        langTab: 0
    },
    filterTab: {
        filterTab: true,
        path: 'r/',
        removed: 'notRemoved'
    },
    initialFilter: {
        message: '',
        from: '',
        to: '',
        statusF: null,
        datesF:  '',
    },
    dataFilter: {
        search: '',
        message: false,
    },
    minMaxValues: {},
    isOpen: isMobile ? false : localStorage.getItem('callFilter') == 'true' ? true : false,
    tabPanel: '1',
    checked: false,
    ids: [],
};



const callGroup = ( state = initialState, action = {}) => {

  switch (action.type) {

    case CALL_GROUP_TYPES.CALL_GROUP_GET_ONE_P: {
        return {
            ...state,
            loading: true
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_GET_ONE_F: {
        return {
            ...state,
            loading: false,
            callGroup: action.payload
        }
    }


    case CALL_GROUP_TYPES.CALL_GROUP_GET_ONE_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.data
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_PAG_F: {
        return {
            ...state,
            data: action.payload.data.map((elem) => state.ids.includes(elem.callGroupId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
            checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.callGroupId)),
            count: action.payload.count
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_SIZE_CHANGE: {

        const showEntries = Object.assign(state.showEntries, action.payload)
      
        return {
            ...state,
            showEntries: showEntries
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_PAGE_CHANGE: {
      
        const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_FILETR_TABE_CHANGE: {
      
        return {
            ...state,
            filterTab: action.payload,
            checked: false
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_TAB_PANEL_CHANGE: {
      
        return {
            ...state,
            tabPanel: action.payload
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_CHECKBOX_CHANGE: {
        let ids = [...state.ids]
        const { checked, multiple, key } = action.payload
        const data = state.data.map(elem => {

            if (multiple) {

                elem['isChecked'] = checked
                return elem

            } else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.callGroupId) })

        return {
            ...state,
            data: data,
            checked: !multiple ? data.every(element => element.isChecked): checked,
            ids: ids
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_BULK_ARCIVE_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case CALL_GROUP_TYPES.CALL_GROUP_BULK_DESTROY_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case CALL_GROUP_TYPES.CALL_GROUP_LANGUAGE_PANEL_CHANGE: {
        return { 
            ...state,
            langTab: action.payload
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_CREATE_R: {
        return { 
            ...state,
            error: action.payload.response
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_DATA_FILTER: {
        return { 
            ...state,
            dataFilter: { [action.payload['key']]: action.payload['value'] },
            error: []
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_DELETE_F: {
        const id = action.payload;
        return {
            ...state,
            data: state.data.filter(item => item.customer !== id)
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_FILTER_IS_OPEN: {
        localStorage.setItem('callFilter', !state.isOpen)
        return {
            ...state,
            isOpen: !state.isOpen
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
            error: []
        }
    }
    
    case CALL_GROUP_TYPES.CALL_GROUP_DATA_FILTER_ALL_SEARCH_RESET: {
        const { key } = action.payload
        return {
            ...state,
            [key]: { 
                from: '',
                to: '',
                message: '',
                status: null,
                dates:  '',
            }
        }
    }

    case CALL_GROUP_TYPES.CALL_GROUP_DATA_MIN_MAX_F: {
        return {
            ...state,
            minMaxValues: action.payload
        }
    }

    default:
        return state;
    }
}


export {
    callGroup
}

