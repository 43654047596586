import React from "react";

 
function RadioOption (props) {
    const { option, sortArray, product, changeOptions } = props
    
    function addOption(item, optionValue) {
        product.activeOptions = product.activeOptions.filter(id => !optionValue.values.find(elem => elem.optionValueId == id))
        product.activeOptions.push(item.optionValueId)
        changeOptions(product.activeOptions, product)
    } 

    return (
        <>
            <div className={`radio-options radio-options__single option-type`}>

                <div className={`radio-options__title`}>
                    <span>
                        {option.option.descriptions[0].name} 
                    </span>
                </div>

                {
                    sortArray(option.values).map((item, i) =>
                        !!item.subtract &&
                        <a onClick={() => addOption(item, option)}>
                            <div 
                                key = {i} 
                                className={product.activeOptions.includes(item.optionValueId) ? "radio-option__item radio-option__item_size_m radio-option__item--active" : "radio-option__item radio-option__item_size_m"}
                                role="button">
                                <span className="radio-option__item__label">
                                    {item.optionValue.descriptions[0].name} 
                                </span>
                            </div>
                        </a>
                    )
                }

            </div>

        </>
    )
}

export default RadioOption;