const GET_CATEGORY = 'GET_CATEGORY';
const GET_DELETED_CATEGORY = 'GET_DELETED_CATEGORY';
const SELECT_CATEGORY_ITEMS = 'SELECT_CATEGORY_ITEMS';
const GET_CATEGORY_ITEM = 'GET_CATEGORY_ITEM';
const GET_PAG_NOT_REMOVED_CATEGORIES = 'GET_PAG_NOT_REMOVED_CATEGORIES';
const GET_PAG_REMOVED_CATEGORIES = 'GET_PAG_REMOVED_CATEGORIES';
const DELETE_CATEGORY_ITEMS = 'DELETE_CATEGORY_ITEMS';
const RECOVER_CATEGORY_ITEMS = 'RECOVER_CATEGORY_ITEMS';
const ARCHIVE_CATEGORY_ITEMS = 'ARCHIVE_CATEGORY_ITEMS';
const BULK_RECOVER_CATEGORY_ITEMS = 'BULK_RECOVER_CATEGORY_ITEMS';
const NEW_CATEGORY_FULFILLED = 'NEW_CATEGORY_FULFILLED';
const NEW_CATEGORY_REJECTED = 'NEW_CATEGORY_REJECTED';
const SAVE_CATEGORY_FULFILLED = 'SAVE_CATEGORY_FULFILLED';
const SAVE_CATEGORY_REJECTED = 'SAVE_CATEGORY_REJECTED';
const FETCH_CATEGORY_SEE_ALL = 'FETCH_CATEGORY_SEE_ALL';
const FETCH_CATEGORY_FULFILLED = 'FETCH_CATEGORY_FULFILLED';
const FETCH_CATEGORY_PENDING = 'FETCH_CATEGORY_PENDING'
const UPDATE_CATEGORY_FULFILLED = 'UPDATE_CATEGORY_FULFILLED';
const LANG_TAB = 'LANG_TAB';
const DESTROY_CATEGORY = 'DESTROY_CATEGORY';
const BULK_DESTROY_CATEGORY_ITEMS = 'BULK_DESTROY_CATEGORY_ITEMS'
const CATEGORY_TAB_PANEL_CHANGE = 'CATEGORY_TAB_PANEL_CHANGE'
const CHANGE_CATEGORY_BODY = 'CHANGE_CATEGORY_BODY'

export {
  GET_CATEGORY,
  GET_DELETED_CATEGORY,
  SELECT_CATEGORY_ITEMS,
  GET_CATEGORY_ITEM,
  GET_PAG_NOT_REMOVED_CATEGORIES,
  GET_PAG_REMOVED_CATEGORIES,
  DELETE_CATEGORY_ITEMS,
  RECOVER_CATEGORY_ITEMS,
  ARCHIVE_CATEGORY_ITEMS,
  BULK_RECOVER_CATEGORY_ITEMS,
  NEW_CATEGORY_FULFILLED,
  NEW_CATEGORY_REJECTED,
  SAVE_CATEGORY_FULFILLED,
  SAVE_CATEGORY_REJECTED,
  FETCH_CATEGORY_SEE_ALL,
  FETCH_CATEGORY_FULFILLED,
  FETCH_CATEGORY_PENDING,
  UPDATE_CATEGORY_FULFILLED,
  LANG_TAB,
  DESTROY_CATEGORY,
  BULK_DESTROY_CATEGORY_ITEMS,
  CATEGORY_TAB_PANEL_CHANGE,
  CHANGE_CATEGORY_BODY
}