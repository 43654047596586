import config from '../../../config/app'
import {Service} from "./Service";

const all = "c/b/r";
const allR = "c/b/d";
const rest = "c/b";
const pagNotRem = "c/b/r";
const pagRem = "c/b/d";
const bulkDel = 'c/b/b/a';
const bulkRec = 'c/b/b/r'
const bulkDes = 'c/b/b/d'

const brandService = new Service(config["BRAND"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);

export {
    brandService
};