import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../redux-modules/actions';
import PasswordValidation from '../PasswordValidation';
import './Register.css';

import ConfirmRegister from './ConfirmRegister';

function Register({ setRegisterPage, addQuery, getQueryValue }) {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false)
  const passwordInputType = passwordShown ? "text" : "password"
  const { account } = useSelector((state) => state);
  const darkmode = false

  const darkModeColor = darkmode ? '#ffffffdd' : '#000000dd'
  
  const [formbody, setFormbody] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    password: '',
  });

  const [control, setControl] = useState({
    firstname: false,
    lastname: false,
    username: false,
    email: false,
    password: false,
  });

  const [response, setResponse] = useState({
    success: true,
    message: '',
    active: false,
  });

  const responseActive = response.active ? 'active' : null

  const handleChange = (event) => {
    setFormbody({
      ...formbody,
      [event.target.name]: event.target.value,
    });
  }

  useEffect(() => {
    return () => {
      dispatch(accountActions.clearError())
    }
  }, [])

  useEffect(() => {
    if (!getQueryValue('email')) {
      if (account.err && account.err.length) {

        setResponse({
          ...response,
          success: false,
          message: account.err[0].msg,
          active: true
        });
  
      } else if (account.data) {
  
        setResponse({
          ...response,
          success: true,
          message: 'Account created successfully',
          active: true
        });
  
        setFormbody({
          ...formbody, 
          username: '',
          firstname: '',
          lastname: '', 
          email: '', 
          password: ''
        });
  
        setTimeout(() => {
          addQuery({email: formbody.email})
        }, 1500);
        
      }
    }
  }, [account])


  const handleSubmit = async (event) => {
    try {

      event.preventDefault();

      if (!control.username || !control.email || !control.password) {
        throw new Error('Form data is not approved, please fill in the form correctly');
      }

      dispatch(accountActions.signUp(formbody))

    }
    catch (error0) {
      setResponse({
        ...response,
        success: false,
        message: error0.message,
        active: true
      });
    }
  }

  useEffect(() => {

    const usernameValid = /^[a-z]*$/g.test(formbody.username);
    if (
      usernameValid
      && formbody.username.length >= 4
      && formbody.username.length < 17
    ) {
      setControl((prev) => ({
        ...prev,
        username: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        username: false,
      }));
    }

    if (formbody.firstname.length >= 4 && formbody.firstname.length < 17) {
      setControl((prev) => ({
        ...prev,
        firstname: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        firstname: false,
      }));
    }

    if (formbody.lastname.length >= 4 && formbody.lastname.length < 17) {
      setControl((prev) => ({
        ...prev,
        lastname: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        lastname: false,
      }));
    }

    const emailValid = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (emailValid.test(formbody.email)) {
      setControl((prev) => ({
        ...prev,
        email: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        email: false,
      }));
    }

    const passValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\W).{8,26}$/g;

    if (passValid.test(formbody.password)) {
      setControl((prev) => ({
        ...prev,
        password: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        password: false,
      }));
    }

  }, [ formbody ]);

  const getIcon = (value) => {
    return value ? 'check-circle' : 'x-circle'
  }

  const getPasswordIcon = (value) => {
    return value ? "lock-open" : "lock"
  }

  const getColor = (value) => {
    return `${value ? '#00A19D' : '#B91646'}`
  }


  return (
          <div
            className={`
              ${'register active'}
              ${darkmode ? 'dark' : null}
            `}
          >
            <div className={'register-wrap'}>
              <div className={'header'}>
                {
                  getQueryValue('email') 
                  ?
                  <h2>Verify.</h2>
                  :
                  <h2>Register.</h2>
                }
                {
                  getQueryValue('email') ? (
                    <p>
                      Please enter your registration code that we have sent via {getQueryValue('email')}
                    </p>
                  ) : (
                    <p>
                      Register your account & start a conversation with your store any + where/time.
                    </p>
                  )
                }
              </div>
              {
                getQueryValue('email') ? (
                  <ConfirmRegister
                    email = {getQueryValue('email')}
                    setRegisterPage={setRegisterPage}
                  />
                ) : (
                  <form
                    method="post"
                    className={'form'}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <label htmlFor="username" className={'cards'}>
                      <box-icon
                        name="user"
                        color={darkModeColor}
                      >
                      </box-icon>
                      <span className={'input-field'}>
                        <p className={'label'}>Username</p>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          placeholder="Length 4-16, must be lowercase"
                          value={formbody.username}
                          onChange={handleChange}
                          required
                        />
                      </span>
                      <box-icon
                        name={getIcon(control.username)}
                        color={getColor(control.username)}
                      >
                      </box-icon>
                    </label>
                    <label htmlFor="firstname" className={'cards'}>
                      <box-icon
                        name="user"
                        color={darkModeColor}
                      >
                      </box-icon>
                      <span className={'input-field'}>
                        <p className={'label'}>Firstname</p>
                        <input
                          type="text"
                          name="firstname"
                          id="firstname"
                          placeholder="Length 4-16"
                          value={formbody.firstname}
                          onChange={handleChange}
                          required
                        />
                      </span>
                      <box-icon
                        name={getIcon(control.firstname)}
                        color={getColor(control.firstname)}
                      >
                      </box-icon>
                    </label>
                    <label htmlFor="lastname" className={'cards'}>
                      <box-icon
                        name="user"
                        color={darkModeColor}
                      >
                      </box-icon>
                      <span className={'input-field'}>
                        <p className={'label'}>Lasttname</p>
                        <input
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Length 4-16"
                          value={formbody.lastname}
                          onChange={handleChange}
                          required
                        />
                      </span>
                      <box-icon
                        name={getIcon(control.lastname)}
                        color={getColor(control.lastname)}
                      >
                      </box-icon>
                    </label>
                    <label htmlFor="email" className={'cards'}>
                      <box-icon
                        name="envelope"
                        color={darkModeColor}
                      >
                      </box-icon>
                      <span className={'input-field'}>
                        <p className={'label'}>Email Address</p>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={formbody.email}
                          onChange={handleChange}
                          required
                        />
                      </span>
                      <box-icon
                        name={getIcon(control.email)}
                        color={getColor(control.email)}
                      >
                      </box-icon>
                    </label>
                    <label htmlFor="password-regis" className={'cards'}>
                      <box-icon
                        name={getPasswordIcon(passwordShown)}
                        color={darkModeColor}
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                      </box-icon>
                      <span className={'input-field'}>
                        <p className={'label'}>Password</p>
                        <input
                          type={passwordInputType}
                          name="password"
                          id="password-regis"
                          placeholder="Must contain A-Za-z, 0-9 & symbols"
                          value={formbody.password}
                          onChange={handleChange}
                          required
                        />
                      </span>
                      <box-icon
                        name={getIcon(control.password)}
                        color={getColor(control.password)}
                      >
                      </box-icon>
                    </label>
                    <PasswordValidation
                      password={formbody.password}
                    />
                    <div className={`${'response'} ${responseActive}`}>
                      <box-icon
                        name={getIcon(response.success)}
                        color={getColor(response.success)}
                      >
                      </box-icon>
                      <p>{response.message}</p>
                    </div>
                    <span className={'submit-button'}>
                      <button type="submit" className={'btn'}>
                        Create Account
                        <box-icon
                          type="solid"
                          name="right-top-arrow-circle"
                          color={darkModeColor}
                        >
                        </box-icon>
                      </button>
                    </span>
                  </form>
                )
              }
              <div className={'footer'}>
                <p className={'text'}>Already have an account?</p>
                <button
                  className={'btn'}
                  onClick={setRegisterPage}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
  );
}

export default Register;
