import { NOTIFICATION_TYPE_GROUP_TYPES } from '../../types'
import Fields from '../../../store/Store/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  groupsSelect: [],
  count: 0,
  navItem: new Fields().navBar,
  inputFilters: new Fields().dataFilter,
  oneData: new Fields(),
  error: [],
  isOpen: isMobile ? false : localStorage.getItem('notificationTypeGroupFilter') == 'true' ? true : false,
  pageNumber: {
    removed: 1,
    notRemoved: 1
  },
  showEntries: {
    removed: 10,
    notRemoved: 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    name: ''
  },
  dataFilter: {
    search: '',
    notificationTypeGroupId: null,
  },
  loading: false,
  tabPanel: '1',
  checked: false,
  ids: []
};


const notificationTypeGroups = ( state = initialState, action = {}) => {

  switch (action.type) {

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_PAG_F: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        count: action.payload.count
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
        ...state,
        showEntries: showEntries
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_PAGE_CHANGE: {
      const pageNumber = Object.assign(state.pageNumber, action.payload)
      return {
          ...state,
          pageNumber: pageNumber
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_FILETR_TABE_CHANGE: {
      return {
        ...state,
        filterTab: action.payload,
        checked: false
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_TAB_PANEL_CHANGE: {
      return {
        ...state,
        tabPanel: action.payload
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
        if (multiple) {
          elem['isChecked'] = checked
          return elem
        } else {
          state.data[key]['isChecked'] = checked
          return elem
        }
      })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.notificationTypeGroupId) })
      
      return {
        ...state,
        data: data,
        checked: !multiple ? data.every(element => element.isChecked): checked,
        ids: ids
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_BULK_ARCIVE_F: {
      
      return { 
        ...state,
        checked: false
      }

    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_BULK_DESTROY_F: {
      
      return { 
        ...state,
        checked: false
      }

    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_LANGUAGE_PANEL_CHANGE: {
      return { 
        ...state,
        langTab: action.payload,
        ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
      }
    }


    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_CREATE_R: {
      return { 
        ...state,
        error: action.payload.response
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_GET_ONE_F: {

      return { 
        ...state,
        oneData: action.payload,
        navItem: new Fields().navBar,
        error: []
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_REQUIRED_VALIDATION_R: {
      state.navItem = new Fields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
        state.navItem[parseInt(elem.tab) - 1].count++
        state.navItem[parseInt(elem.tab) - 1].color = 'red'
        state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
          if (val.field === elem.name) {
            val['color'] = 'red'
          }
        })
      })
      return { 
        ...state,
        error: action.payload.errorMsg,
        tabPanel: tabPanel
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_REQUIRED_VALIDATION_F: {
      return { 
        ...state,
        error: [],
        navItem: new Fields().navBar
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NEW_NOTIFICATION_TYPE_GROUP: {
      return { 
        ...state,
        oneData: new Fields(),
        navItem: new Fields().navBar,
        error: []
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_DATA_FILTER: {
      return { 
        ...state,
        dataFilter: { [action.payload['key']]: action.payload['value'] },
        error: []
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_DATA_FILTER_SEARCH: {
      return { 
        ...state,
        initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
        error: []
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_DELETE_F: {
      const id = action.payload;
      return {
        ...state,
        data: state.data.filter(item => item.notificationTypeGroupId !== id)
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_FILTER_IS_OPEN: {
      localStorage.setItem('notificationTypeGroupFilter', !state.isOpen)
      return {
        ...state,
        isOpen: !state.isOpen
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
        ...state,
        initialFilter: Object.assign({}, state.initialFilter, { 
          name: '',
          adderss: '',
          businessIds: []
        }),
        error: []
      }
    }


    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_SEE_ALL_P: {
      return {
        ...state,
        businessLoad: true
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_SEE_ALL_F: {
      const newGroups = []
      action.payload.data.forEach(group => {
        newGroups.push({ 
          value: group.notificationTypeGroupId, 
          label: group.name
        })
      })

      let groupsSelect = action.search ? newGroups : [...state.groupsSelect, ...newGroups]
      groupsSelect = groupsSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
        ...state,
        groupsSelect: groupsSelect,
        count: action.payload.count
      }
    }

    case NOTIFICATION_TYPE_GROUP_TYPES.NOTIFICATION_TYPE_GROUP_SEE_ALL_R: {
      return {
        ...state,
        businessLoad: false
      }
    }

    default:
        return state;
  }
}
export {
  notificationTypeGroups
}