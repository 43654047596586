export default class {
    constructor () {
      this.reviewId = ''
      this.productId = ''
      this.storeId = ''
      this.reviewer = ''
      this.rating = ''
      this.text = ''
      this.deleted = false
      this.status = false
      this.dataFilter = [
        {
            name: 'Product',
            field: 'products',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Author',
            field: 'name',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Status',
            field: 'status',
            tag: 'select',
            label: true,
            type: 'boolean',
            color: '',
            value: ''
        },
        {
            name: 'Date Start',
            field: 'dateFrom',
            tag: 'datePicker',
            label: true,
            type: 'dateFrom',
            color: '',
            value: ''
        },
        {
            name: 'Date End',
            field: 'dateTo',
            tag: 'datePicker',
            label: true,
            type: 'dateTo',
            color: '',
            value: ''
        }
      ]
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Product',
                    field: 'product',
                    tag: 'productDescriptions',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: false,
                    message: 'Oops! Fill in the ProductName.',
                    min: null,
                    max: null,
                },
                {
                    name: 'Reviewer',
                    field: 'author',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Reviewer.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Text',
                    field: 'text',
                    tag: 'textarea',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Message.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Reply',
                    field: 'reply',
                    tag: 'reply',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: false,
                    message: 'Oops! Fill in the Reviewer.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Status',
                    field: 'status',
                    tag: 'switch',
                    label: true,
                    tab: '1',
                    type: 'switch',
                    color: '',
                },
                {
                    name: 'Rating',
                    field: 'rating',
                    label: true,
                    tab: '1',
                    type: 'number',
                    color: '',
                },
                {
                    name: 'Date',
                    field: 'date',
                    label: true,
                    tab: '1',
                    type: 'string',
                    color: '',
                }
            ] 
        }
      ]
    }
  }