const WAREHOUSE_PAG_F = 'WAREHOUSE_PAG_L_F';
const WAREHOUSE_PAG_R = 'WAREHOUSE_PAG_L_R';
const WAREHOUSE_SIZE_CHANGE = 'WAREHOUSE_SIZE_CHANGE'
const WAREHOUSE_PAGE_CHANGE = 'WAREHOUSE_PAGE_CHANGE'
const WAREHOUSE_FILETR_TABE_CHANGE = 'WAREHOUSE_FILETR_TABE_CHANGE'
const WAREHOUSE_UPDATE_F = 'WAREHOUSE_UPDATE_F'
const WAREHOUSE_UPDATE_R = 'WAREHOUSE_UPDATE_R'
const WAREHOUSE_CHECKBOX_CHANGE = 'WAREHOUSE_CHECKBOX_CHANGE'
const WAREHOUSE_BULK_ARCIVE_F = 'WAREHOUSE_BULK_ARCIVE_F'
const WAREHOUSE_BULK_ARCIVE_R = 'WAREHOUSE_BULK_ARCIVE_R'
const WAREHOUSE_BULK_DESTROY_F = 'WAREHOUSE_BULK_DESTROY_F'
const WAREHOUSE_BULK_DESTROY_R = 'WAREHOUSE_BULK_DESTROY_R'
const WAREHOUSE_TAB_PANEL_CHANGE = 'WAREHOUSE_TAB_PANEL_CHANGE'
const WAREHOUSE_CREATE_F = 'WAREHOUSE_CREATE_F'
const WAREHOUSE_CREATE_R = 'WAREHOUSE_CREATE_R'
const WAREHOUSE_GET_ONE_F = 'WAREHOUSE_GET_ONE_F'
const WAREHOUSE_GET_ONE_R = 'WAREHOUSE_GET_ONE_R'
const WAREHOUSE_REQUIRED_VALIDATION_F = 'WAREHOUSE_REQUIRED_VALIDATION_F'
const WAREHOUSE_REQUIRED_VALIDATION_R = 'WAREHOUSE_REQUIRED_VALIDATION_R'
const NEW_WAREHOUSE = 'NEW_WAREHOUSE'
const WAREHOUSE_DATA_FILTER = 'WAREHOUSE_DATA_FILTER'
const WAREHOUSE_DELETE_F = 'WAREHOUSE_DELETE_F'
const WAREHOUSE_DELETE_R = 'WAREHOUSE_DELETE_R'
const WAREHOUSE_LIVE_DATA = 'WAREHOUSE_LIVE_DATA'
const WAREHOUSE_DATA_FILTER_SEARCH = 'WAREHOUSE_DATA_FILTER_SEARCH'
const WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH = 'WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH'
const WAREHOUSE_FILTER_IS_OPEN = 'WAREHOUSE_FILTER_IS_OPEN'
const WAREHOUSE_PRODUCTS_FILTER_IS_OPEN = 'WAREHOUSE_PRODUCTS_FILTER_IS_OPEN'
const WAREHOUSE_LANGUAGE_PANEL_CHANGE = 'WAREHOUSE_LANGUAGE_PANEL_CHANGE'
const WAREHOUSE_DATA_FILTER_SEARCH_RESET = 'WAREHOUSE_DATA_FILTER_SEARCH_RESET'
const WAREHOUSE_DATA_FILTER_ALL_SEARCH_RESET = 'WAREHOUSE_DATA_FILTER_ALL_SEARCH_RESET'
const WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH_RESET = 'WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH_RESET'
const WAREHOUSE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET = 'WAREHOUSE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_WAREHOUSE_SEE_ALL_P = 'FETCH_WAREHOUSE_SEE_ALL_P'
const FETCH_WAREHOUSE_SEE_ALL_F = 'FETCH_WAREHOUSE_SEE_ALL_F'
const FETCH_WAREHOUSE_SEE_ALL_R = 'FETCH_WAREHOUSE_SEE_ALL_R'
const WAREHOUSE_PRODUCTS_PAG_P = 'WAREHOUSE_PRODUCTS_PAG_P'
const WAREHOUSE_PRODUCTS_PAG_F = 'WAREHOUSE_PRODUCTS_PAG_F'
const WAREHOUSE_PRODUCTS_PAG_R = 'WAREHOUSE_PRODUCTS_PAG_R'
const WAREHOUSE_PRODUCTS_DATA_FILTER = 'WAREHOUSE_PRODUCTS_DATA_FILTER'
const GET_WAREHOUSE_STATISTICS_P = 'GET_WAREHOUSE_STATISTICS_P'
const GET_WAREHOUSE_STATISTICS_F = 'GET_WAREHOUSE_STATISTICS_F'
const GET_WAREHOUSE_STATISTICS_R = 'GET_WAREHOUSE_STATISTICS_R'

export {
    WAREHOUSE_PAG_F,
    WAREHOUSE_PAG_R,
    WAREHOUSE_SIZE_CHANGE,
    WAREHOUSE_PAGE_CHANGE,
    WAREHOUSE_FILETR_TABE_CHANGE,
    WAREHOUSE_UPDATE_F,
    WAREHOUSE_UPDATE_R,
    WAREHOUSE_CHECKBOX_CHANGE,
    WAREHOUSE_BULK_ARCIVE_F,
    WAREHOUSE_BULK_ARCIVE_R,
    WAREHOUSE_BULK_DESTROY_F,
    WAREHOUSE_BULK_DESTROY_R,
    WAREHOUSE_TAB_PANEL_CHANGE,
    WAREHOUSE_CREATE_F,
    WAREHOUSE_CREATE_R,
    WAREHOUSE_GET_ONE_F,
    WAREHOUSE_GET_ONE_R,
    WAREHOUSE_REQUIRED_VALIDATION_F,
    WAREHOUSE_REQUIRED_VALIDATION_R,
    NEW_WAREHOUSE,
    WAREHOUSE_DATA_FILTER,
    WAREHOUSE_DELETE_F,
    WAREHOUSE_DELETE_R,
    WAREHOUSE_LIVE_DATA,
    WAREHOUSE_DATA_FILTER_SEARCH,
    WAREHOUSE_FILTER_IS_OPEN,
    WAREHOUSE_LANGUAGE_PANEL_CHANGE,
    WAREHOUSE_DATA_FILTER_SEARCH_RESET,
    WAREHOUSE_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_WAREHOUSE_SEE_ALL_P,
    FETCH_WAREHOUSE_SEE_ALL_F,
    FETCH_WAREHOUSE_SEE_ALL_R,
    WAREHOUSE_PRODUCTS_PAG_P,
    WAREHOUSE_PRODUCTS_PAG_F,
    WAREHOUSE_PRODUCTS_PAG_R,
    WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH,
    WAREHOUSE_PRODUCTS_FILTER_IS_OPEN,
    WAREHOUSE_PRODUCTS_DATA_FILTER_SEARCH_RESET,
    WAREHOUSE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET,
    WAREHOUSE_PRODUCTS_DATA_FILTER,
    GET_WAREHOUSE_STATISTICS_P,
    GET_WAREHOUSE_STATISTICS_F,
    GET_WAREHOUSE_STATISTICS_R
}