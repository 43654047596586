const USER_GROUP_PAG_F = 'USER_GROUP_PAG_L_F';
const USER_GROUP_PAG_R = 'USER_GROUP_PAG_L_R';
const USER_GROUP_SIZE_CHANGE = 'USER_GROUP_SIZE_CHANGE'
const USER_GROUP_PAGE_CHANGE = 'USER_GROUP_PAGE_CHANGE'
const USER_GROUP_FILETR_TABE_CHANGE = 'USER_GROUP_FILETR_TABE_CHANGE'
const USER_GROUP_UPDATE_F = 'USER_GROUP_UPDATE_F'
const USER_GROUP_UPDATE_R = 'USER_GROUP_UPDATE_R'
const USER_GROUP_CHECKBOX_CHANGE = 'USER_GROUP_CHECKBOX_CHANGE'
const USER_GROUP_BULK_ARCIVE_F = 'USER_GROUP_BULK_ARCIVE_F'
const USER_GROUP_BULK_ARCIVE_R = 'USER_GROUP_BULK_ARCIVE_R'
const USER_GROUP_BULK_DESTROY_R = 'USER_GROUP_BULK_DESTROY_R'
const USER_GROUP_BULK_DESTROY_F = 'USER_GROUP_BULK_DESTROY_F'
const USER_GROUP_TAB_PANEL_CHANGE = 'USER_GROUP_TAB_PANEL_CHANGE'
const USER_GROUP_CREATE_F = 'USER_GROUP_CREATE_F'
const USER_GROUP_CREATE_R = 'USER_GROUP_CREATE_R'
const USER_GROUP_GET_ONE_F = 'USER_GROUP_GET_ONE_F'
const USER_GROUP_GET_ONE_R = 'USER_GROUP_GET_ONE_R'
const USER_GROUP_REQUIRED_VALIDATION_F = 'USER_GROUP_REQUIRED_VALIDATION_F'
const USER_GROUP_REQUIRED_VALIDATION_R = 'USER_GROUP_REQUIRED_VALIDATION_R'
const NEW_USER_GROUP = 'NEW_USER_GROUP'
const USER_GROUP_DATA_FILTER = 'USER_GROUP_DATA_FILTER'
const USER_GROUP_DELETE_F = 'USER_GROUP_DELETE_F'
const USER_GROUP_DELETE_R = 'USER_GROUP_DELETE_R'
const USER_GROUP_ALL_F = 'USER_GROUP_ALL_F'
const USER_GROUP_LANGUAGE_PANEL_CHANGE = 'USER_GROUP_LANGUAGE_PANEL_CHANGE'
const USER_GROUP_PERMISSIONS_P = 'USER_GROUP_PERMISSIONS_P'
const USER_GROUP_PERMISSIONS_F = 'USER_GROUP_PERMISSIONS_F'
const USER_GROUP_PERMISSIONS_R = 'USER_GROUP_PERMISSIONS_R'

export {
    USER_GROUP_PAG_F,
    USER_GROUP_PAG_R,
    USER_GROUP_SIZE_CHANGE,
    USER_GROUP_PAGE_CHANGE,
    USER_GROUP_FILETR_TABE_CHANGE,
    USER_GROUP_UPDATE_F,
    USER_GROUP_UPDATE_R,
    USER_GROUP_CHECKBOX_CHANGE,
    USER_GROUP_BULK_ARCIVE_F,
    USER_GROUP_BULK_ARCIVE_R,
    USER_GROUP_BULK_DESTROY_R,
    USER_GROUP_BULK_DESTROY_F,
    USER_GROUP_TAB_PANEL_CHANGE,
    USER_GROUP_CREATE_F,
    USER_GROUP_CREATE_R,
    USER_GROUP_GET_ONE_F,
    USER_GROUP_GET_ONE_R,
    USER_GROUP_REQUIRED_VALIDATION_F,
    USER_GROUP_REQUIRED_VALIDATION_R,
    NEW_USER_GROUP,
    USER_GROUP_DATA_FILTER,
    USER_GROUP_DELETE_F,
    USER_GROUP_DELETE_R,
    USER_GROUP_ALL_F,
    USER_GROUP_LANGUAGE_PANEL_CHANGE,
    USER_GROUP_PERMISSIONS_P,
    USER_GROUP_PERMISSIONS_F,
    USER_GROUP_PERMISSIONS_R
}