import  {CATEGORY_TYPES} from '../types'
import  {categoriesService} from '../services/categoriesService'
import {Notification} from 'element-react'

/**
 * @name getAll
 * @description get all doesn't removed categories
 * @returns {function(*)}
 */
const getAll = (token) => {
  return dispatch => {
    return categoriesService.getAll(token).then(data => {
      dispatch({
        type : CATEGORY_TYPES.GET_CATEGORY,
        payload:data
      })
    })
  }
};

/**
 * @name getAllRemoved
 * @description get all removed categories
 * @returns {function(*)}
 */
const getAllRemoved = (token) => {
  return dispatch =>{
    return categoriesService.getAllRemoved(token).then(data => {
      dispatch({
        type : CATEGORY_TYPES.GET_DELETED_CATEGORY,
        payload:data
      })
    })
  }
};

/**
 * @name selectElem
 * @description select category item that edit item
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : CATEGORY_TYPES.SELECT_CATEGORY_ITEMS,
      payload: elem
    })
  }
};


const getItem = (id, token) => {
  return dispatch => {
    return categoriesService.getItem(id, token).then(data => {
      return dispatch({
        type: CATEGORY_TYPES.GET_CATEGORY_ITEM,
        payload: data
      })
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return categoriesService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: CATEGORY_TYPES.GET_PAG_NOT_REMOVED_CATEGORIES,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return categoriesService.getPaginationRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: CATEGORY_TYPES.GET_PAG_REMOVED_CATEGORIES,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt) => {
  return dispatch => {
    categoriesService.remove(elem.categoryId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: CATEGORY_TYPES.DELETE_CATEGORY_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    categoriesService.recover(elem.categoryId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: CATEGORY_TYPES.RECOVER_CATEGORY_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    categoriesService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: CATEGORY_TYPES.ARCHIVE_CATEGORY_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    categoriesService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: CATEGORY_TYPES.BULK_RECOVER_CATEGORY_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    categoriesService.bulkDestroy(ids,  jwt).then((data) => {
      return dispatch({
        type: CATEGORY_TYPES.BULK_DESTROY_CATEGORY_ITEMS,
        payload : data
      })
    })
  }
};

const newCategory = (language) => {
  return async (dispatch, getState) => {
      try {
          const state = getState()
          dispatch({
              type: CATEGORY_TYPES.NEW_CATEGORY_FULFILLED,
              payload: language,
              state
          })
      } catch (e) {
          dispatch({
              type: CATEGORY_TYPES.NEW_CATEGORY_REJECTED,
              payload: e
          });
      }
  }
}

const saveCategory = (category, jwt, history, discard) => {
  return async dispatch => {
      categoriesService.create(category, jwt)
        .then(data => {

          Notification.success({
            title: 'Created',
            message: 'Your category succesfuly created',
          }, 20)

          if (discard) {
            history.push(`${discard}`)
          } else {
            history.push('/catalog/category')
          }

          dispatch({
              type: CATEGORY_TYPES.SAVE_CATEGORY_FULFILLED,
              payload: data
          })

          dispatch({
            type: CATEGORY_TYPES.CATEGORY_TAB_PANEL_CHANGE,
            payload:  '1'
          }) 
          
        })
        .catch(error => {
            if (error.response.status === 422) {
              error.response.data.forEach(err => {
                Notification.error({
                  title: err.path,
                  message: err.message
                }, 20)
              })
            } 
          })
        }
    }


/**
 * @name fetchCategorySeeAll
 * @returns {function(*)}
*/
const fetchCategorySeeAll = (jwt, page, count, search) => {
  return dispatch => {
    return categoriesService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: CATEGORY_TYPES.FETCH_CATEGORY_SEE_ALL,
        payload : options
      })
    })
  }
};

const fetchCategoryId = (id, token, language) => {
  return async (dispatch, getState) => {
      try {
            dispatch({
              type: CATEGORY_TYPES.FETCH_CATEGORY_PENDING
            })
          const data = await categoriesService.getItem(id, token)
          const state = getState()
          dispatch({
              type: CATEGORY_TYPES.FETCH_CATEGORY_FULFILLED,
              payload: {
                data, 
                language
              },
              state
          })
      } catch (e) {
          dispatch({
              type: 'FETCH_CATEGORY_REJECTED',
              // payload: handleError(e)
          });
      }
  }
}

const changeBody = () => {
  return async (dispatch, getState) => {
    const state = getState()
    dispatch({
        type: CATEGORY_TYPES.CHANGE_CATEGORY_BODY,
        payload: {
          language: state.language
        }
    })
  }
}

const updateCategory = (category, id, token, history, discard) => {
  return async dispatch => {
    try {
      delete category.categoryProducts
      const data = await categoriesService.edit(category, id, token)

        Notification.warning({
          title: 'Updated',
          message: 'Your category succesfuly updated '
        }, 20)

        if (discard) {
          history.push(`${discard}`)
        } else {
          history.push('/catalog/category')
        }
        dispatch({
            type: CATEGORY_TYPES.UPDATE_CATEGORY_FULFILLED,
            payload: data
        })

        dispatch({
          type: CATEGORY_TYPES.CATEGORY_TAB_PANEL_CHANGE,
          payload:  '1'
        }) 
        
    } catch (error) {
        if (error.response.status === 422) {
            error.response.data.forEach(err => {
              Notification.error({
                title: err.path,
                message: err.message
              }, 20)
          })
        }
      }
    }
  }

  const changeLangTab = ( id, LangTab ) => {
    return async dispatch => {
          dispatch({
            type: CATEGORY_TYPES.LANG_TAB,
            payload: { languageId: id, LangTab: LangTab }
        })
      }
  }


  const destroyCategory = ( token, category ) => {

    return async dispatch => {

      try {

          await categoriesService.destroy(token ,category.categoryId)
  
          Notification.success( {
            title: 'category ',
            message: `${category.source} category succesfuly deleted `
          }, 20 )

          dispatch( {
              type: CATEGORY_TYPES.DESTROY_CATEGORY,
              payload: category
          } )
          
      } catch (e) {

          Notification.error( {
            title: e.name,
            message: `${e.parent.column}`
          }, 20) 
         
        }
      }
    }


export {getAll, getAllRemoved, getItem,  selectItem, getPaginationNotRemoved, getPaginationRemoved, deleteItem, recoverItem, bulkDelete, bulkRecover, newCategory, saveCategory, fetchCategorySeeAll, fetchCategoryId, updateCategory, changeLangTab, destroyCategory, bulkDestroy, changeBody }