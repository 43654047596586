import config from '../../../config/app'
import {Service} from "./Service";

const all = "l/r";
const allR = "l/d";
const rest = "l";
const pagNotRem = "l/r";
const pagRem = "l/d";
const bulkDel = 'l/b/a';
const bulkRec = 'l/b/r'
const bulkDes = 'l/b/d'

const linkService = new Service(config["TRAFFIC"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);

export {
    linkService
};