const GET_INFORMATIONS = 'GET_INFORMATIONS';
const GET_DELETED_INFORMATIONS = 'GET_DELETED_INFORMATIONS';
const GET_INFORMATION_ITEM = 'GET_INFORMATION_ITEM';
const SELECT_INFORMATION_ITEMS = 'SELECT_INFORMATION_ITEMS';
const RECOVER_INFORMATION_ITEMS = 'RECOVER_INFORMATION_ITEMS';
const DELETE_INFORMATION_ITEMS = 'DELETE_INFORMATION_ITEMS';
const GET_PAG_REMOVED_INFORMATIONS = 'GET_PAG_REMOVED_INFORMATIONS';
const GET_PAG_NOT_REMOVED_INFORMATIONS = 'GET_PAG_NOT_REMOVED_INFORMATIONS';
const BULK_RECOVER_INFORMATION_ITEMS = 'BULK_RECOVER_INFORMATION_ITEMS';
const ARCHIVE_INFORMATION_ITEMS = 'ARCHIVE_INFORMATION_ITEMS';
const DESTROY_INFORMATION = 'DESTROY_INFORMATION';
const BULK_DESTROY_INFORMATION_ITEMS = 'BULK_DESTROY_INFORMATION_ITEMS'
const INFORMATION_TAB_PANEL_CHANGE = 'INFORMATION_TAB_PANEL_CHANGE'

export {
  GET_INFORMATIONS,
  GET_DELETED_INFORMATIONS,
  GET_INFORMATION_ITEM,
  SELECT_INFORMATION_ITEMS,
  RECOVER_INFORMATION_ITEMS,
  DELETE_INFORMATION_ITEMS,
  GET_PAG_REMOVED_INFORMATIONS,
  GET_PAG_NOT_REMOVED_INFORMATIONS,
  BULK_RECOVER_INFORMATION_ITEMS,
  ARCHIVE_INFORMATION_ITEMS,
  DESTROY_INFORMATION,
  BULK_DESTROY_INFORMATION_ITEMS,
  INFORMATION_TAB_PANEL_CHANGE
}