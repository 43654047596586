import config from '../../../config/app'
import {Service} from "./Service";

const all = "c/p/g/r";
const allR = "c/p/g/d";
const rest = "c/p/g";
const pagNotRem = "c/p/g/r";
const pagRem= "c/p/g/d";
const bulkDel = 'c/p/g/b/a';
const bulkRec = 'c/p/g/b/r'
const bulkDes = 'c/p/g/b/d'


const productGroupService = new Service(config['PRODUCT'], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);

export {
    productGroupService
}