const NOTIFICATION_SOUND_PAG_F = 'NOTIFICATION_SOUND_F';
const NOTIFICATION_SOUND_PAG_R = 'NOTIFICATION_SOUND_R';
const NOTIFICATION_SOUND_SIZE_CHANGE = 'NOTIFICATION_SOUND_SIZE_CHANGE'
const NOTIFICATION_SOUND_PAGE_CHANGE = 'NOTIFICATION_SOUND_PAGE_CHANGE'
const NOTIFICATION_SOUND_FILETR_TABE_CHANGE = 'NOTIFICATION_SOUND_FILETR_TABE_CHANGE'
const NOTIFICATION_SOUND_UPDATE_F = 'NOTIFICATION_SOUND_UPDATE_F'
const NOTIFICATION_SOUND_UPDATE_R = 'NOTIFICATION_SOUND_UPDATE_R'
const NOTIFICATION_SOUND_CHECKBOX_CHANGE = 'NOTIFICATION_SOUND_CHECKBOX_CHANGE'
const NOTIFICATION_SOUND_BULK_ARCIVE_F = 'NOTIFICATION_SOUND_BULK_ARCIVE_F'
const NOTIFICATION_SOUND_BULK_ARCIVE_R = 'NOTIFICATION_SOUND_BULK_ARCIVE_R'
const NOTIFICATION_SOUND_BULK_DESTROY_F = 'NOTIFICATION_SOUND_BULK_DESTROY_F'
const NOTIFICATION_SOUND_BULK_DESTROY_R = 'NOTIFICATION_SOUND_BULK_DESTROY_R'
const NOTIFICATION_SOUND_TAB_PANEL_CHANGE = 'NOTIFICATION_SOUND_TAB_PANEL_CHANGE'
const NOTIFICATION_SOUND_CREATE_F = 'NOTIFICATION_SOUND_CREATE_F'
const NOTIFICATION_SOUND_CREATE_R = 'NOTIFICATION_SOUND_CREATE_R'
const NOTIFICATION_SOUND_GET_ONE_F = 'NOTIFICATION_SOUND_GET_ONE_F'
const NOTIFICATION_SOUND_GET_ONE_R = 'NOTIFICATION_SOUND_GET_ONE_R'
const NOTIFICATION_SOUND_REQUIRED_VALIDATION_F = 'NOTIFICATION_SOUND_REQUIRED_VALIDATION_F'
const NOTIFICATION_SOUND_REQUIRED_VALIDATION_R = 'NOTIFICATION_SOUND_REQUIRED_VALIDATION_R'
const NEW_NOTIFICATION_SOUND = 'NEW_NOTIFICATION_SOUND'
const NOTIFICATION_SOUND_DATA_FILTER = 'NOTIFICATION_SOUND_DATA_FILTER'
const NOTIFICATION_SOUND_DELETE_F = 'NOTIFICATION_SOUND_DELETE_F'
const NOTIFICATION_SOUND_DELETE_R = 'NOTIFICATION_SOUND_DELETE_R'
const NOTIFICATION_SOUND_DATA_FILTER_SEARCH = 'NOTIFICATION_SOUND_DATA_FILTER_SEARCH'
const NOTIFICATION_SOUND_FILTER_IS_OPEN = 'NOTIFICATION_SOUND_FILTER_IS_OPEN'
const NOTIFICATION_SOUND_DATA_FILTER_SEARCH_RESET = 'NOTIFICATION_SOUND_DATA_FILTER_SEARCH_RESET'
const NOTIFICATION_SOUND_DATA_FILTER_ALL_SEARCH_RESET = 'NOTIFICATION_SOUND_DATA_FILTER_ALL_SEARCH_RESET'
const NOTIFICATION_SOUND_UPLOAD_FILES_P = 'NOTIFICATION_SOUND_UPLOAD_FILES_P'
const NOTIFICATION_SOUND_UPLOAD_FILES_F = 'NOTIFICATION_SOUND_UPLOAD_FILES_F'
const NOTIFICATION_SOUND_UPLOAD_FILES_R = 'NOTIFICATION_SOUND_UPLOAD_FILES_R'
const NOTIFICATION_SOUND_SEE_ALL_P = 'NOTIFICATION_SOUND_SEE_ALL_P'
const NOTIFICATION_SOUND_SEE_ALL_F = 'NOTIFICATION_SOUND_SEE_ALL_F'
const NOTIFICATION_SOUND_SEE_ALL_R = 'NOTIFICATION_SOUND_SEE_ALL_R'


export {
    NOTIFICATION_SOUND_PAG_F,
    NOTIFICATION_SOUND_PAG_R,
    NOTIFICATION_SOUND_SIZE_CHANGE,
    NOTIFICATION_SOUND_PAGE_CHANGE,
    NOTIFICATION_SOUND_FILETR_TABE_CHANGE,
    NOTIFICATION_SOUND_UPDATE_F,
    NOTIFICATION_SOUND_UPDATE_R,
    NOTIFICATION_SOUND_CHECKBOX_CHANGE,
    NOTIFICATION_SOUND_BULK_ARCIVE_F,
    NOTIFICATION_SOUND_BULK_ARCIVE_R,
    NOTIFICATION_SOUND_BULK_DESTROY_F,
    NOTIFICATION_SOUND_BULK_DESTROY_R,
    NOTIFICATION_SOUND_TAB_PANEL_CHANGE,
    NOTIFICATION_SOUND_CREATE_F,
    NOTIFICATION_SOUND_CREATE_R,
    NOTIFICATION_SOUND_GET_ONE_F,
    NOTIFICATION_SOUND_GET_ONE_R,
    NOTIFICATION_SOUND_REQUIRED_VALIDATION_F,
    NOTIFICATION_SOUND_REQUIRED_VALIDATION_R,
    NEW_NOTIFICATION_SOUND,
    NOTIFICATION_SOUND_DATA_FILTER,
    NOTIFICATION_SOUND_DELETE_F,
    NOTIFICATION_SOUND_DELETE_R,
    NOTIFICATION_SOUND_DATA_FILTER_SEARCH,
    NOTIFICATION_SOUND_FILTER_IS_OPEN,
    NOTIFICATION_SOUND_DATA_FILTER_SEARCH_RESET,
    NOTIFICATION_SOUND_DATA_FILTER_ALL_SEARCH_RESET,
    NOTIFICATION_SOUND_UPLOAD_FILES_P,
    NOTIFICATION_SOUND_UPLOAD_FILES_F,
    NOTIFICATION_SOUND_UPLOAD_FILES_R,
    NOTIFICATION_SOUND_SEE_ALL_P,
    NOTIFICATION_SOUND_SEE_ALL_F,
    NOTIFICATION_SOUND_SEE_ALL_R
}