import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showInfoDialogWithMessage } from './Utils'
import {orderActions, userActions} from "../../redux-modules/actions";
import {hideModal} from "../../redux-modules/actions/dialogActions";

const StaffDialog = (_props) => {
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState('');
    const { data } = useSelector((reduxState) => reduxState.dialog);
    const userState = useSelector((reduxState) => reduxState.users);
    const account = useSelector((reduxState) => reduxState.account);
    const [disabled, setDisabled] = useState(true);
    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        dispatch(userActions.paginationUser(account.token, 'r/', 1, 100, { name: searchValue, permissionsNames: ['Delivery'] }))
    }, [searchValue])
    
    return (
        <div className="modal-register">
            <h4 className="cancel-order__title">Please Choose Driver</h4>
            <div className="selection-multiple">
                <input 
                    className='form-control' 
                    style={{width: '100%'}} 
                    value = {searchValue} 
                    onChange={(e) => setSearchValue(e.target.value)} 
                    placeholder="Search"
                />
            </div>
            <div className="options-multiple">
                {
                    userState.data.map((item, index) => {
                        const { value, label } = item
                        const selected = selectedUser.userId === item.userId
                        let className = "option"
                        if (selected) className += " selected"

                        return (
                            <div key={ index }>
                                <div
                                    key={ value }
                                    data-value={ label }
                                    className={ className }
                                    onClick={ () => {
                                        if (!selected) {
                                            setSelectedUser(item)
                                            setDisabled(false)
                                        } else {
                                            setSelectedUser({})
                                            setDisabled(true)
                                        }
                                    }}
                                >
                                    
                                    { item.firstname } { item.lastname }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <button 
                className="btn modal-button"
                style={{backgroundColor: '#4dbd74', opacity: disabled ? "0.4" : "1"}}
                type={'submit'}
                disabled={disabled}
                form={'change-password'}
                onClick={_e => 
                    dispatch(orderActions.changeStatus(data.id['mongoId'], data, account.token))
                    .then(_payload => {
                    dispatch(orderActions.chooseStaff(account.token, {
                        orderId: data.id['orderId'],
                        userId: selectedUser.userId,
                        status: data.status
                    }))
                    showInfoDialogWithMessage(dispatch, { message: `Your order ${data.status}` })
                    setTimeout(() => dispatch(hideModal()), 5000)
                })}
            >
                    Confirm
            </button>
        </div>
    )
}

export default StaffDialog;