import React, { useState, useEffect } from 'react'
import io from 'socket.io-client/dist/socket.io.js'

function useSocket(url, options) {
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const socketIo = io(url, options)

    setSocket(socketIo)

    function cleanup() {
      socketIo.disconnect()
    }
    return cleanup

    // should only run once and not on every re-render,
    // so pass an empty array
  }, [])
  return socket
}

export { 
    useSocket 
}