import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux'
import ReactSelect from 'react-select';
import { 
    Card, CardBody,
    CardHeader,
    FormGroup,
    Label,
    Row,
    Col,
} from 'reactstrap'
import { 
    dataFilterActions, globalActions, 
    categoryActions, brandActions, 
    productGroupActions, storeActions, 
    franchiseActions 
} from '../../../redux-modules/actions'

const options = [
    { 
        value: 'true', 
        label: 'Enabled'
    },
    { 
        value: 'false', 
        label: 'Disabled'
    }
];

class ProductFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            storePage: 1,
            franchisePage: 1,
            closeOnSelectStors: false,
            closeOnSelectFranchise: false,
            selectValue: '',
            selectedStors: [],
            selectedFranchises: []
        };
    }
    
    componentWillMount() {
        this.selectRefs = {}
    }

    componentDidMount() {
        const { account } = this.props
        this.props.getPagCategoryNotRemoved(account.token, 1, 1000, { status: false })
        this.props.getPagCategoryRemoved(account.token, 1, 1000, { status: false })
        this.props.getPagBrandNotRemoved(account.token, 1, 1000, { languageId: 1 })
        this.props.getPagBrandRemoved(account.token, 1, 1000,  { languageId: 1 })
        this.props.paginationProductGroupNotRemoved(account.token, 1, 1000, { languageId: 1 })
        this.props.paginationProductGroupRemoved(account.token, 1, 1000,  { languageId: 1 })
        this.props.dataMinMaxValues(account.token)
    }

    componentWillReceiveProps(nextProps) {
        const { filter } = this.props
        if (filter.minPrice !== nextProps.minMaxValues.minPrice &&
            filter.maxPrice !== nextProps.minMaxValues.maxPrice &&
            filter.minQuantity !== nextProps.minMaxValues.minQuantity &&
            filter.maxQuantity !== nextProps.minMaxValues.maxQuantity 
            ) {
            filter['minPrice'] = nextProps.minMaxValues.minPrice
            filter['maxPrice'] = nextProps.minMaxValues.maxPrice
            filter['minQuantity'] = nextProps.minMaxValues.minQuantity
            filter['maxQuantity'] = nextProps.minMaxValues.maxQuantity
        }
    } 

    onChange(key, event) {
        const { languageId, filterDataSearch, paginationTable, actionType, dataFil, filter, page, showEntries, account } = this.props
        filterDataSearch(key, event.target.value, actionType)
        if (paginationTable) {
            paginationTable(account.token, 1, showEntries, 
                { ...{languageId: languageId},...dataFil, ...filter, ...{ [key]: event.target.value}}
            )
            this.props.onCurrentChange(1)
        }
        this.forceUpdate()
    }

    clearFilter() {
        const { languageId, paginationTable, actionType, dataFil, page, showEntries, account, resetFilterDataSearch } = this.props
        resetFilterDataSearch('initialFilter', actionType)
        if (paginationTable) {
            paginationTable(account.token, 1, showEntries, 
                { ...{languageId: languageId},...dataFil, ...{}}
            )
            this.props.onCurrentChange(1)
        }
    }

    selectStatus(event, key) {
        const { languageId, filterDataSearch, paginationTable, actionType, dataFil, filter, page, showEntries, account } = this.props
        filterDataSearch(key, event && event.value ? event.value : event, actionType)
        if (paginationTable) {
            paginationTable(account.token, 1, showEntries, 
                { ...{languageId: languageId},...dataFil, ...filter, ...{ [key]: event && event.value ? event.value : event}}
            )
            this.props.onCurrentChange(1)
        }
        this.setState({selectValue: event})
    }

    selectMultiple(values, key) {
        const { languageId, filterDataSearch, paginationTable, actionType, dataFil, filter, page, showEntries, account } = this.props
        const productIds = []
        values.forEach(elem => {
            productIds.push(elem.value)
        })
        filterDataSearch(key, productIds, actionType)
        if (paginationTable) {
            paginationTable(account.token, 1, showEntries, 
                { ...{languageId: languageId},...dataFil, ...filter, ...{ [key]: productIds}}
            )
            this.props.onCurrentChange(1)
        }
    }
    
    selectStore(values, key) {
        const { languageId, filterDataSearch, paginationTable, actionType, dataFil, filter, page, showEntries, account, paginationStore } = this.props
        const { storePage } = this.state
        const selectedStors = [] 
        const storeIds = []
        values.forEach(elem => {
            if (elem.value === 'all') { 
                this.setState({
                    storePage: storePage + 1, 
                    closeOnSelectStors: false
                })
                paginationStore(account.token, storePage + 1, 10)
            } else {
                selectedStors.push(elem)
                storeIds.push(elem.value)
            }
        })

        filterDataSearch(key, storeIds, actionType)
        filterDataSearch('stors', selectedStors, actionType)
        if (paginationTable) {
            paginationTable(account.token, 1, showEntries, 
                { ...{languageId: languageId},...dataFil, ...filter, ...{ [key]: storeIds }}
            )
            this.props.onCurrentChange(1)
        }
    }

    selectFranchise(values, key) {
        const { languageId, filterDataSearch, paginationTable, actionType, dataFil, filter, page, showEntries, account, paginationFranchise } = this.props
        const { franchisePage } = this.state
        const selectedFranchises = [] 
        const franchiseIds = []
        values.forEach(elem => {
            if (elem.value === 'all') { 
                this.setState({
                    franchisePage: franchisePage + 1, 
                    closeOnSelectFranchise: false
                })
                paginationFranchise(account.token, franchisePage + 1, 10)
            } else {
                selectedFranchises.push(elem)
                franchiseIds.push(elem.value)
            }
        })

        filterDataSearch(key, franchiseIds, actionType)
        filterDataSearch('franchises', selectedFranchises, actionType)
        if (paginationTable) {
            paginationTable(account.token, 1, showEntries, 
                { ...{ languageId: languageId },...dataFil, ...filter, ...{ [key]: franchiseIds }}
            )
            this.props.onCurrentChange(1)
        }
    }
    
    onKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\+|-/.test(keyValue))
            event.preventDefault();
    }

    handleInputChange = (newValue, index) => {
        const inputValue = newValue;

        if (index == 0) {
            this.props.paginationStore(this.props.account.token, 1, 10, { name: inputValue }, !!inputValue.length)
        }

        if (index == 1) {
            this.props.paginationFranchise(this.props.account.token, 1, 10, { name: inputValue }, !!inputValue.length)
        }

    };

    openMenu(index) {
        const { account, paginationStore, storesSelect, franchiseSelect, paginationFranchise } = this.props;

        if (index == 0) {
            if (!storesSelect.length) {
                paginationStore(account.token, 1, 10);  
            }
        }

        if (index == 1) {
            if (!franchiseSelect.length) {
                paginationFranchise(account.token, 1, 10);  
            }
        }

        this.selectRefs[index].focus()
    }

render() {
    const { isOpen, setIsOpen, actionType, filter, storesSelect, storesCount, franchiseSelect, franchiseCount, categories, brands, productGroups  } = this.props
    const { closeOnSelectStors, closeOnSelectFranchise } = this.state
    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <Row className='px-3'>
                        <Col className="pointer" onClick={() => setIsOpen(`${actionType}_FILTER`)}>
                            <span>Filter</span>
                        </Col>
                        <span className="pr-2 pointer" onClick={() => this.clearFilter()}>Clear Filter</span>
                        <svg 
                            onClick={() => setIsOpen(`${actionType}_FILTER`)} 
                            width="16" 
                            height="16" 
                            className={!!isOpen ? "svg__rotate pointer" : "pointer"}>
                            <use xlinkHref={'/assets/svg/sprite.svg#down-arrow'}/>
                        </svg>
                    </Row>
                </CardHeader>    
                {
                    isOpen &&
                    <CardBody>
                        <Row>
                            <Col xs="12" md="6">
                            <FormGroup width={'100%'}>
                                    <Label className='h6'>Product Name</Label>
                                    <input type='text' value={filter['name']}  className='form-control' onChange={this.onChange.bind(this, 'name')}/>
                                </FormGroup>
                            </Col>

                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label className='h6'>Product Model</Label>
                                    <input type='text' value={filter['model']}  className='form-control' onChange={this.onChange.bind(this, 'model')}/>
                                </FormGroup> 
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label className='h6'>Min Price</Label>
                                    <input 
                                        min = {'0'}  
                                        type = 'number' 
                                        className='form-control' 
                                        value={filter['minPrice'] || 0} 
                                        onChange={this.onChange.bind(this, 'minPrice')}
                                        onKeyPress={this.onKeyPress.bind(this)}
                                    />               
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label className='h6'>Max Price</Label>
                                        <input 
                                            min = {filter['minPrice'] || 0}  
                                            type = 'number' 
                                            className='form-control' 
                                            value={filter['maxPrice'] || 0} 
                                            onChange={this.onChange.bind(this, 'maxPrice')}
                                            onKeyPress={this.onKeyPress.bind(this)}
                                        />                
                                </FormGroup>
                            </Col>

                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label className='h6'>Min Quantity</Label>
                                    <input 
                                        min = {'0'} 
                                        type = 'number'  
                                        className='form-control' 
                                        value={filter['minQuantity'] || 0} 
                                        onChange={this.onChange.bind(this, 'minQuantity')}
                                        onKeyPress={this.onKeyPress.bind(this)}/>
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                                <FormGroup>
                                    <Label className='h6'>Max Quantity</Label>
                                    <input  
                                        min = {filter['minQuantity'] || 0} 
                                        type = 'number'  
                                        className='form-control' 
                                        value={filter['maxQuantity'] || 0} 
                                        onChange={this.onChange.bind(this, 'maxQuantity')}
                                        onKeyPress={this.onKeyPress.bind(this)}/>
                                </FormGroup>
                            </Col>
                            <Col xs="6" md="3" lg="2">
                                <Label className='h6 mr-2'>{'Status'}</Label>
                                <ReactSelect
                                    value={filter['status']}
                                    onChange={(e) => this.selectStatus(e, 'status')}
                                    options={options}
                                    rules={{ required: true }}
                                />
                            </Col> 

                            <Col xs="6" md="3" lg="2">
                                <Label className='h6 mr-2'>{'Categories'}</Label>
                                <ReactSelect
                                    value={filter['categoryIds']}
                                    onChange={(e) => this.selectMultiple(e, 'categoryIds')}
                                    options={categories.filter((item) => parseInt(item.productscount)).map((item) => ({
                                        value: item.categoryId, 
                                        label: item.descriptions[0].name 
                                    }))}
                                    multi = {true}
                                />
                            </Col> 
                            <Col xs="6" md="3" lg="2">
                                <Label className='h6 mr-2'>{'Brands'}</Label>
                                <ReactSelect
                                    value={filter['brandIds']}
                                    onChange={(e) => this.selectMultiple(e, 'brandIds')}
                                    options={brands.filter((item) => parseInt(item.productscount)).map((item) => ({
                                        value: item.brandId, 
                                        label: item.descriptions[0].name 
                                    }))}
                                    multi = {true}
                                />
                            </Col> 
                            <Col xs="6" md="3" lg="2">
                                <Label className='h6 mr-2'>{'Product Groups'}</Label>
                                <ReactSelect
                                    value={filter['productGroupIds']}
                                    onChange={(e) => this.selectMultiple(e, 'productGroupIds')}
                                    options={productGroups.filter((item) => parseInt(item.productscount)).map((item) => ({
                                        value: item.productGroupId, 
                                        label: item.descriptions[0].name 
                                    }))}
                                    multi = {true}
                                />
                            </Col>
                            <Col xs="6" md="3" lg="2">
                                <Label className='h6 mr-2'>{'Stores'}</Label>
                                <ReactSelect
                                    ref={r => this.selectRefs[0] = r}
                                    onFocus={() => this.openMenu(0)}
                                    isLoading={this.props.storeLoad}
                                    closeOnSelect={closeOnSelectStors}
                                    value={filter['stors']}
                                    onChange={(e) => this.selectStore(e, 'storeIds')}
                                    onInputChange={(event) => this.handleInputChange(event, 0)}
                                    options={
                                    storesCount > storesSelect.length 
                                    ? [...storesSelect, { value: 'all', label: 'See More'}]
                                    : [...storesSelect]
                                    }
                                    multi = {true}
                                />
                            </Col>  
                            <Col xs="6" md="3" lg="2">
                                <Label className='h6 mr-2'>{'Franchses'}</Label>
                                <ReactSelect
                                    ref={r => this.selectRefs[1] = r}
                                    onFocus={() => this.openMenu(1)}
                                    isLoading={this.props.franchiseLoad}
                                    closeOnSelect={closeOnSelectFranchise}
                                    value={filter['franchises']}
                                    onChange={(e) => this.selectFranchise(e, 'franchiseIds')}
                                    onInputChange={(event) => this.handleInputChange(event, 1)}
                                    options={
                                    franchiseCount > franchiseSelect.length 
                                    ? [...franchiseSelect, { value: 'all', label: 'See More'}]
                                    : [...franchiseSelect]
                                    }
                                    multi = {true}
                                />
                            </Col> 
                        </Row>

                    </CardBody>
                }      
            </Card>
        </Fragment>
        );
    }
}


const mapStateToProps = (state) => {

    return {
        categories: [...state.categories.pagNotRemovedCategory,...state.categories.pagRemovedCategory],
        brands: [...state.brands.pagNotRemovedBrand,...state.brands.pagRemovedBrand],
        productGroups: [...state.productGroups.pagRemovedProductGroup, ...state.productGroups.pagNotRemovedProductGroup],
        account: state.account,
        dataFilter: state.dataFilter.filteredData,
        storesCount: state.stors.count,
        minMaxValues: state.dataFilter.minMaxValues,
        storesSelect: state.stors.storesSelect,
        storesSelect: state.stors.storesSelect,
        storeLoad: state.stors.loading,
        franchiseSelect: state.franchises.franchiseSelect,
        franchiseCount: state.franchises.pagNotRemovedCount,
        franchiseLoad: state.franchises.franchiseLoad,
    }

}

const mapDispatchToProps = (dispatch) => {

    return {
        getPagCategoryRemoved: (token, page, count) => dispatch(categoryActions.getPaginationRemoved(token, page, count)),
        getPagCategoryNotRemoved: (token, page, count) => dispatch(categoryActions.getPaginationNotRemoved(token, page, count)),
        getPagBrandRemoved: (token, page, count, search) => dispatch(brandActions.getPaginationRemoved(token, page, count, search)),
        getPagBrandNotRemoved: (token, page, count, search) => dispatch(brandActions.getPaginationNotRemoved(token, page, count, search)),
        paginationProductGroupRemoved: (token, page, count, filter) => dispatch(productGroupActions.paginationRemoved(token, page, count, filter)),
        paginationProductGroupNotRemoved: (token, page, count, filter) => dispatch(productGroupActions.paginationNotRemoved(token, page, count, filter)),
        paginationStore: (token, page, count, filter, search) => dispatch(storeActions.seeAll(token, page, count, filter, search)),
        paginationFranchise: (token, page, count, filter, search) => dispatch(franchiseActions.seeAll(token, page, count, filter, search)),
        setIsOpen: (actionType) => dispatch(globalActions.isOpen(actionType)),
        dataFilterAction: (query, jwt) => dispatch(dataFilterActions.search(query, jwt)),
        resetFilterDataSearch: (key, actionType) => dispatch(globalActions.resetFilterDataSearchAll(key, actionType)),
        dataMinMaxValues: (jwt) => dispatch(dataFilterActions.minMaxValue(jwt)),
        filterDataSearch: (key, value, actionType ) => dispatch(globalActions.filterDataSearch(key, value, actionType))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFilter);