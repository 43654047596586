const PAGE_GET_ONE_P = 'PAGE_GET_ONE_P'
const PAGE_GET_ONE_F = 'PAGE_GET_ONE_F'
const PAGE_GET_ONE_R = 'PAGE_GET_ONE_R'
const FETCH_PAGE_SEE_ALL_P = 'FETCH_PAGE_SEE_ALL_P'
const FETCH_PAGE_SEE_ALL_F = 'FETCH_PAGE_SEE_ALL_F'
const FETCH_PAGE_SEE_ALL_R = 'FETCH_PAGE_SEE_ALL_R'

export {
    FETCH_PAGE_SEE_ALL_P,
    FETCH_PAGE_SEE_ALL_F,
    FETCH_PAGE_SEE_ALL_R,
    PAGE_GET_ONE_P,
    PAGE_GET_ONE_F,
    PAGE_GET_ONE_R
}