import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {urlB64ToUint8Array} from './Utility'

function WebPush(props) {
  const [swRegistration, setSwRegistration] = useState(null) 

  useEffect(() => {
    if (swRegistration == null) {
      onRegisterServiceWorker()
    }
  }, [])

  useEffect(() => {
    if (props.subscriveUserEnabled && swRegistration) {
      onSubscribeUser()
    }
  }, [swRegistration])

  function onSubscribeUser() {
    if (swRegistration == null) {
      return
    }

    var onUpdateSubscriptionOnServer = props.onUpdateSubscriptionOnServer
    var applicationServerPublicKey = props.applicationServerPublicKey

    if (swRegistration.pushManager) {
      swRegistration.pushManager.getSubscription()
      .then(function(subscription) {
        if (subscription !== null) {
          onUpdateSubscriptionOnServer(subscription)
        } else {
          const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)
          swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
          })
          .then(function(subscrip) {
            if (onUpdateSubscriptionOnServer) {
              onUpdateSubscriptionOnServer(subscrip)
            }
          })
          .catch(function(err) {
            console.log('Failed to subscribe the user: ', err)
          });
        }
      })
    }
  }

  function onRegisterServiceWorker() {
    navigator.serviceWorker.register('/sw.js')
    .then(function(swReg) {
        setSwRegistration(swReg);
    })
    
  }

  return (
    <div>

    </div>
  )
  
}

WebPush.propTypes = {
  subscriveUserEnabled: PropTypes.bool.isRequired,
  applicationServerPublicKey: PropTypes.string.isRequired,
  onUpdateSubscriptionOnServer: PropTypes.func,
  onSubscribeFailed: PropTypes.func,
}

export default WebPush;