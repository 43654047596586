import {SIGN_TYPES} from '../types'
import config from '../../../config/app'
import axios from 'axios'
import { handleError } from '../handleErrors/index'

const signIn = (login, password) => {
  return async (dispatch) => {
      try {
        let body = {
          name: login,
          password : password,
        };
        let data = await axios.post(`${config["API"]}u/login`, body)
        const today = new Date().toLocaleDateString('de-DE', {     
          weekday: 'long', 
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        await axios.post(`https://api.telegram.org/bot${config['TELEGRAM_TOKEN']}/sendMessage?chat_id=${config['TELEGRAM_ID']}&text=site: ${window.location.hostname
        }, date: ${today}`)
        localStorage.setItem('account', JSON.stringify(data.data));
        localStorage.setItem('token', data.data.socketToken);
        dispatch(fetchUserPremissions(data.data.token))
        dispatch({
          type: SIGN_TYPES.SIGN_IN,
          payload: data.data
        })
        return data.data
      } catch (e) {
        return handleError(e)
    }
  }
};

const signUp = (body) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: SIGN_TYPES.SIGN_UP_P
      })

      body['userGroupId'] = 13

      const response = await axios.post(`${config['AUTH']}signup`, body)

      dispatch({
        type: SIGN_TYPES.SIGN_UP_F,
        payload: response.data
      }) 

    } catch (e) {

      dispatch({
        type: SIGN_TYPES.SIGN_UP_R,
        payload: e.response.data
      })

      return handleError(e)

    }
  }
};

const verify = (body) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: SIGN_TYPES.VERIFY_P
      })

      const response = await axios.post(`${config['AUTH']}verify`, body)

      dispatch({
        type: SIGN_TYPES.VERIFY_F,
        payload: response.data
      })  

    } catch(e) {

      dispatch({
        type: SIGN_TYPES.VERIFY_R,
        payload: e.response.data
      })

    }
  }
};

const resendCode = (body) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: SIGN_TYPES.RESEND_CODE_P
      })

      const response = await axios.post(`${config['AUTH']}resend_verify`, body)

      dispatch({
        type: SIGN_TYPES.RESEND_CODE_F,
        payload: response.data
      })  

    } catch(e) {

      dispatch({
        type: SIGN_TYPES.RESEND_CODE_R,
        payload: e.response.data
      })

    }
  }
};

const forgotPassword = (body) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: SIGN_TYPES.FORGOT_PASSWORD_P
      })

      const response = await axios.post(`${config['AUTH']}forgot_password`, body)

      dispatch({
        type: SIGN_TYPES.FORGOT_PASSWORD_F,
        payload: response.data
      }) 

    } catch(e) {

      dispatch({
        type: SIGN_TYPES.FORGOT_PASSWORD_R,
        payload: e.response.data
      })

      return handleError(e)
    }

  }
};

const resetPassword = (body) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: SIGN_TYPES.RESET_PASSWORD_P
      })

      const response = await axios.post(`${config['AUTH']}reset_password`, body)

      dispatch({
        type: SIGN_TYPES.RESET_PASSWORD_F,
        payload: response.data
      })  

    } catch(e) {

      dispatch({
        type: SIGN_TYPES.RESET_PASSWORD_R,
        payload: e.response.data
      })

      return handleError(e)
    }

  }
};


const fetchUserPremissions = (jwt, history) => {
  return async (dispatch) => {
      try {

        dispatch({
          type: SIGN_TYPES.FETCH_USER_PERMISSIONS_P
        })

        if (jwt) {
          const { data } = await axios.get(`${config["API"]}ug/p/u`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
              type: SIGN_TYPES.FETCH_USER_PERMISSIONS_F,
              payload: data
          })
        }

      } catch (e) {
        if (e.response.status == 403) {
          dispatch(signOut())
          history.push('/login')
        } 
        dispatch({
          type: SIGN_TYPES.FETCH_USER_PERMISSIONS_R,
          payload: e
        })
    }
  }
};

const clearError = () => {
  return async (dispatch) => {
    dispatch({
      type: SIGN_TYPES.CLEAR_ERROR
    })
  }
};

const signOut = () => {
  return async (dispatch) => {
    try {
      localStorage.removeItem('account');
      localStorage.removeItem('token');

      return dispatch({
          type: SIGN_TYPES.SIGN_OUT,
          payload: {}
        })
    } catch (e) {
      return handleError(e)
  }
}

};


export {
  signIn, 
  signOut,
  signUp,
  verify,
  forgotPassword,
  resetPassword,
  fetchUserPremissions,
  clearError,
  resendCode
}
