const TICKET_PAG_P = 'TICKET_PAG_P';
const TICKET_PAG_F = 'TICKET_PAG_F';
const TICKET_PAG_R = 'TICKET_PAG_R';
const ADD_TICKET_P = 'ADD_TICKET_P';
const ADD_TICKET_F = 'ADD_TICKET_F';
const ADD_TICKET_R = 'ADD_TICKET_R';
const CALL_COUNTS_P = 'CALL_COUNTS_P';
const CALL_COUNTS_F = 'CALL_COUNTS_F';
const CALL_COUNTS_R = 'CALL_COUNTS_R';
const SET_TWILIO_DEVICE = 'SET_TWILIO_DEVICE'

export {
    TICKET_PAG_P,
    TICKET_PAG_R,
    TICKET_PAG_F,
    ADD_TICKET_P,
    ADD_TICKET_F,
    ADD_TICKET_R,
    CALL_COUNTS_P,
    CALL_COUNTS_F,
    CALL_COUNTS_R,
    SET_TWILIO_DEVICE
}