const defaultState = {
  modalData: {
    visible: false
  }
};

const globals = ( state = defaultState, action = {}) => {
  if (action.type === 'OUTSIDE_CLICK_CHANGE') {
    return {
      ...state,
      modalData: action.payload
    }
  } else {
    return state
  }
}


export { globals }