const SUPPLIER_PAG_F = 'SUPPLIER_PAG_L_F';
const SUPPLIER_PAG_R = 'SUPPLIER_PAG_L_R';
const SUPPLIER_SIZE_CHANGE = 'SUPPLIER_SIZE_CHANGE'
const SUPPLIER_PAGE_CHANGE = 'SUPPLIER_PAGE_CHANGE'
const SUPPLIER_FILETR_TABE_CHANGE = 'SUPPLIER_FILETR_TABE_CHANGE'
const SUPPLIER_UPDATE_F = 'SUPPLIER_UPDATE_F'
const SUPPLIER_UPDATE_R = 'SUPPLIER_UPDATE_R'
const SUPPLIER_CHECKBOX_CHANGE = 'SUPPLIER_CHECKBOX_CHANGE'
const SUPPLIER_BULK_ARCIVE_F = 'SUPPLIER_BULK_ARCIVE_F'
const SUPPLIER_BULK_ARCIVE_R = 'SUPPLIER_BULK_ARCIVE_R'
const SUPPLIER_BULK_DESTROY_F = 'SUPPLIER_BULK_DESTROY_F'
const SUPPLIER_BULK_DESTROY_R = 'SUPPLIER_BULK_DESTROY_R'
const SUPPLIER_TAB_PANEL_CHANGE = 'SUPPLIER_TAB_PANEL_CHANGE'
const SUPPLIER_CREATE_F = 'SUPPLIER_CREATE_F'
const SUPPLIER_CREATE_R = 'SUPPLIER_CREATE_R'
const SUPPLIER_GET_ONE_F = 'SUPPLIER_GET_ONE_F'
const SUPPLIER_GET_ONE_R = 'SUPPLIER_GET_ONE_R'
const SUPPLIER_REQUIRED_VALIDATION_F = 'SUPPLIER_REQUIRED_VALIDATION_F'
const SUPPLIER_REQUIRED_VALIDATION_R = 'SUPPLIER_REQUIRED_VALIDATION_R'
const NEW_SUPPLIER = 'NEW_SUPPLIER'
const SUPPLIER_DATA_FILTER = 'SUPPLIER_DATA_FILTER'
const SUPPLIER_DELETE_F = 'SUPPLIER_DELETE_F'
const SUPPLIER_DELETE_R = 'SUPPLIER_DELETE_R'
const SUPPLIER_LIVE_DATA = 'SUPPLIER_LIVE_DATA'
const SUPPLIER_DATA_FILTER_SEARCH = 'SUPPLIER_DATA_FILTER_SEARCH'
const SUPPLIER_FILTER_IS_OPEN = 'SUPPLIER_FILTER_IS_OPEN'
const SUPPLIER_LANGUAGE_PANEL_CHANGE = 'SUPPLIER_LANGUAGE_PANEL_CHANGE'
const SUPPLIER_DATA_FILTER_SEARCH_RESET = 'SUPPLIER_DATA_FILTER_SEARCH_RESET'
const SUPPLIER_DATA_FILTER_ALL_SEARCH_RESET = 'SUPPLIER_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_SUPPLIER_SEE_ALL_P = 'FETCH_SUPPLIER_SEE_ALL_P'
const FETCH_SUPPLIER_SEE_ALL_F = 'FETCH_SUPPLIER_SEE_ALL_F'
const FETCH_SUPPLIER_SEE_ALL_R = 'FETCH_SUPPLIER_SEE_ALL_R'

export {
    SUPPLIER_PAG_F,
    SUPPLIER_PAG_R,
    SUPPLIER_SIZE_CHANGE,
    SUPPLIER_PAGE_CHANGE,
    SUPPLIER_FILETR_TABE_CHANGE,
    SUPPLIER_UPDATE_F,
    SUPPLIER_UPDATE_R,
    SUPPLIER_CHECKBOX_CHANGE,
    SUPPLIER_BULK_ARCIVE_F,
    SUPPLIER_BULK_ARCIVE_R,
    SUPPLIER_BULK_DESTROY_F,
    SUPPLIER_BULK_DESTROY_R,
    SUPPLIER_TAB_PANEL_CHANGE,
    SUPPLIER_CREATE_F,
    SUPPLIER_CREATE_R,
    SUPPLIER_GET_ONE_F,
    SUPPLIER_GET_ONE_R,
    SUPPLIER_REQUIRED_VALIDATION_F,
    SUPPLIER_REQUIRED_VALIDATION_R,
    NEW_SUPPLIER,
    SUPPLIER_DATA_FILTER,
    SUPPLIER_DELETE_F,
    SUPPLIER_DELETE_R,
    SUPPLIER_LIVE_DATA,
    SUPPLIER_DATA_FILTER_SEARCH,
    SUPPLIER_FILTER_IS_OPEN,
    SUPPLIER_LANGUAGE_PANEL_CHANGE,
    SUPPLIER_DATA_FILTER_SEARCH_RESET,
    SUPPLIER_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_SUPPLIER_SEE_ALL_P,
    FETCH_SUPPLIER_SEE_ALL_F,
    FETCH_SUPPLIER_SEE_ALL_R
}