const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';

const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';
const SET_AND_SHOW_MODAL = 'SET_AND_SHOW_MODAL';
const SHOW_DIALOG_WITH_MESSAGE = 'SHOW_DIALOG_WITH_MESSAGE';

export {
    SHOW_MODAL,
    HIDE_MODAL,
    SET_MODAL_CONTENT,
    SET_AND_SHOW_MODAL,
    SHOW_DIALOG_WITH_MESSAGE,
}