import  { NOTIFICATION_SETTINGS_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import {Notification} from 'element-react'

class NotificationTypeActions {
    constructor(_props) {
        this.api = config['NOTIFICATION_API']
        this.rest  = 's/'
        this.arcive = 'b/'
    }


    update = (jwt, body, Type) => {
        return async dispatch => {
            try {

                dispatch({
                    type: NOTIFICATION_SETTINGS_TYPES[Type + '_UPDATE_P']
                })

                await axios.put(
                    this.api + this.rest,
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: NOTIFICATION_SETTINGS_TYPES[Type + '_UPDATE_F'],
                    payload: body
                })
                
            } catch (e) {

                if (Array.isArray(e.response.data)) {
                    e.response.data.forEach(err => {
                        Notification.error({
                            title: err.path,
                            message: err.msg
                        }, 20)
                    })
                } 

                dispatch({
                    type: NOTIFICATION_SETTINGS_TYPES[Type + '_UPDATE_R'],
                    payload: e
                })

            }
        }
    }


    getOne = (jwt, Type) => {
        return async dispatch => {
            try {

                dispatch({
                    type: NOTIFICATION_SETTINGS_TYPES[Type + '_GET_ONE_P']
                })

                const response = await axios.get(
                this.api + this.rest,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: NOTIFICATION_SETTINGS_TYPES[Type + '_GET_ONE_F'],
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: NOTIFICATION_SETTINGS_TYPES[Type + '_GET_ONE_R'],
                    payload: e
                })

            }
        }
    }


}


export default new NotificationTypeActions()