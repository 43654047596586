import  { USER_GROUP_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import {Notification} from 'element-react'

 class UserGroupActions {
  constructor(_props) {
    this.api = config['USER']
    this.rest  = 'ug'
    this.p = '/p'
    this.arcive = '/b/'
  }

    paginationUserGroup = (jwt, params, page, count, query) => {
      return async dispatch => {
          try {
            const searchString = qs.stringify(query)
            const response = await axios.get(
              this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              })
              dispatch({
                  type: USER_GROUP_TYPES.USER_GROUP_PAG_F,
                  payload: response.data
              })
          } catch (e) {
              dispatch({
                  type: USER_GROUP_TYPES.USER_GROUP_PAG_R,
                  payload: e
              })
          }
      }
    }

    getPermissions = (jwt) => {
      return async dispatch => {
          try {

            dispatch({
              type: USER_GROUP_TYPES.USER_GROUP_PERMISSIONS_P
            })

            const response = await axios.get(
              this.api + this.rest + '/p/g',
              {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
              })

            dispatch({
              type: USER_GROUP_TYPES.USER_GROUP_PERMISSIONS_F,
              payload: response.data
            })

          } catch (e) {

            dispatch({
              type: USER_GROUP_TYPES.USER_GROUP_PERMISSIONS_R,
              payload: e
            })
            
          }
      }
    }

    all = (jwt) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })
            dispatch({
                type: USER_GROUP_TYPES.USER_GROUP_ALL_F,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: USER_GROUP_TYPES.USER_GROUP_ALL_R,
                payload: e
            })
        }
    }
}

    update = (jwt, body, Type, history) => {
      return async dispatch => {
        try {
            await axios.put(
            this.api + this.rest + `/${body.userGroupId}`,
            body,
            {
              headers: 
                {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })

            Notification.warning({
              title: 'Updated',
              message: 'UserGroup succesfuly updated '
            }, 20)

            dispatch({
                type: USER_GROUP_TYPES[Type + '_UPDATE_F'],
                payload: body
            })

            history.goBack()
            
        } catch (e) {
console.log(e)
          if (e.response.status === 422) {
            if (Array.isArray(e.response.data)) {
              e.response.data.forEach(err => {
                Notification.error({
                    title: err.path,
                    message: err.msg
                }, 20)
              })
            } else {
              Notification.error({
                  title: e.response.data.path,
                  message: e.response.data.msg
              }, 20)
            }
          } 

            dispatch({
              type: USER_GROUP_TYPES[Type + '_UPDATE_R'],
              payload: e
            })
          }
        }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: USER_GROUP_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: USER_GROUP_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: USER_GROUP_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: USER_GROUP_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: USER_GROUP_TYPES.USER_GROUP_BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.paginationUserGroup(...otherDispatchParams))
              
          } catch (e) {
              dispatch({
                  type: USER_GROUP_TYPES.BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }

      /**
     * @name bulkDestroy
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + this.rest + this.arcive + 'd',
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: USER_GROUP_TYPES.USER_GROUP_BULK_DESTROY_F,
                payload: ids
            })

            dispatch(this.paginationUserGroup(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: USER_GROUP_TYPES.USER_GROUP_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

      create = (jwt, body, Type, history) => {
        return async dispatch => {
          await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                Notification.success({
                  title: 'Created',
                  message: 'UserGroup succesfuly created',
                }, 20)

                dispatch({
                  type: USER_GROUP_TYPES[Type + '_CREATE_F'],
                  payload: response
                })

                history.goBack()

              })
              .catch(e => {

                if (e.response.status === 422) {
                  if (Array.isArray(e.response.data)) {
                      e.response.data.forEach(err => {
                          Notification.error({
                              title: err.path,
                              message: err.msg
                          }, 20)
                      })
                  } else {
                      Notification.error({
                          title: e.response.data.path,
                          message: e.response.data.msg
                      }, 20)
                  }
                } 

                dispatch({
                      type: USER_GROUP_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })
              })
        }
      }

    
}




export default new UserGroupActions()