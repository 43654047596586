import { SMS_TYPES } from '../../types'
import Fields from '../../../sms/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  sms: new Fields(),
  error: {},
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedSmsPage') || 1,
    notRemoved: localStorage.getItem('notRemovedSmsPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedSmsPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedSmsPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    message: '',
    from: '',
    to: '',
    statusF: null,
    datesF:  '',
  },
  dataFilter: {
    search: '',
    message: false,
  },
  balanceTelnyx: null,
  balanceTwilio: null,
  minMaxValues: {},
  isOpen: isMobile ? false : localStorage.getItem('smsFilter') == 'true' ? true : false,
  isOpenMobile: isMobile ? false : localStorage.getItem('isOpenMobile') == 'true' ? true : false,
  tabPanel: '1',
  checked: false,
  ids: [],
  orders: {
    page: 1,
    showEntries: 10,
    data: [],
    count: []
  },
  phonesSelect: [],
  phonesCount: 0,
  loading: false,
  sendSmsLoading: false,
  sendPulseBalance: null,
  numberList: [],
  statistics: {
    data: [],
    count: 0,
    unique: 0
  },
  messages: {
    data: [],
    count: 0
  }
};



const sms = ( state = initialState, action = {}) => {

  switch (action.type) {

    case SMS_TYPES.SMS_MESSAGE_PAG_P: {
      return {
        ...state,
        loading: true
      }
    }

    case SMS_TYPES.SMS_MESSAGE_PAG_F: {
      return {
        ...state,
        loading: false,
        messages: action.payload
      }
    }

    case SMS_TYPES.SMS_MESSAGE_PAG_R: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    

    case SMS_TYPES.SEND_SMS_P: {
      return {
        ...state,
        sendSmsLoading: true
      }
    }

    case SMS_TYPES.SEND_SMS_F: {
      return {
        ...state,
        error: {},
        messages: { 
          data: [...state.messages.data, action.payload], 
          count: state.messages.count + 1
        },
        sendSmsLoading: false
      }
    }

    case SMS_TYPES.SMS_UPDATE_STATUS: {
      return {
        ...state,
        error: {},
        messages: { 
          data: [...state.messages.data.map((obj) => obj.smsId === action.payload.smsId ? { ...obj, ...action.payload } : obj)], 
          count: state.messages.count
        },
        sendSmsLoading: false
      }
    }

    case SMS_TYPES.SMS_INBOUND: {
      const sms = action.payload.content;
      if (sms.from === state.sms.to) {
        return {
          ...state,
          messages: {
            ...state.messages,
            data: [...state.messages.data, sms],
            count: state.messages.count + 1
          },
          error: {},
          sendSmsLoading: false
        };
      }
      return state;
    }

    case SMS_TYPES.CLEAR_SMS_MESSAGES: {
      return {
        ...state,
        messages: { 
          data: [], 
          count: 0
        },
      }
    }

    case SMS_TYPES.SEND_SMS_R: {
      return {
        ...state,
        sendSmsLoading: false,
        error: action.payload.response
      }
    }

    case SMS_TYPES.SMS_PAG_F: {
        return {
          ...state,
          data: action.payload.data.map((elem) => state.ids.includes(elem.id) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.id)),
          count: action.payload.count
        }
    }

    case SMS_TYPES.NEW_SMS: {
      return {
        ...state,
        sms: new Fields()
      }
    }

    case SMS_TYPES.SMS_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case SMS_TYPES.SMS_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case SMS_TYPES.GET_BALANCE_TELNYX_F: {
      
      return {
          ...state,
          balanceTelnyx: action.payload,
        }
    }

    case SMS_TYPES.GET_BALANCE_TWILIO_F: {
      
      return {
          ...state,
          balanceTwilio: action.payload,
        }
    }

    case SMS_TYPES.GET_BALANCE_R: {
      
      return {
          ...state,
          error: action.payload,
        }
    }

    case SMS_TYPES.SEND_PULSE_SMS_BALANCE_F: {
      return {
        ...state,
        sendPulseBalance: action.payload
      }
    }

    case SMS_TYPES.SEND_PULSE_SMS_BALANCE_R: {
      return {
        ...state,
        error: action.payload
      }
    }

    case SMS_TYPES.SMS_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case SMS_TYPES.SMS_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case SMS_TYPES.SMS_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.id) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case SMS_TYPES.SMS_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case SMS_TYPES.SMS_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case SMS_TYPES.SMS_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case SMS_TYPES.SMS_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case SMS_TYPES.SMS_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case SMS_TYPES.SMS_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.customer !== id)
      }
    }

    case SMS_TYPES.SMS_FILTER_IS_OPEN: {
      localStorage.setItem('smsFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case SMS_TYPES.SMS_MOBILE_FILTER_IS_OPEN: {
      localStorage.setItem('isOpenMobile', !state.isOpenMobile)
      return {
          ...state,
          isOpenMobile: !state.isOpenMobile
      }
    }

    case SMS_TYPES.SMS_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case SMS_TYPES.SMS_DATA_MIN_MAX_F: {
      return {
          ...state,
          minMaxValues: action.payload
      }
    }
    
    case SMS_TYPES.SMS_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: { 
          from: '',
          to: '',
          message: '',
          status: null,
          dates:  '',
        }
      }
    }

    case SMS_TYPES.FETCH_SMS_PHONES_P: {
      return {
        ...state,
        // loading: true
      }
    }

    case SMS_TYPES.FETCH_SMS_PHONES_F: {

      const newPhones = []

      action.payload.data.forEach(item => {
        newPhones.push({ 
          label: item.phone,
          value: item.phone
        })
      })

      let phonesSelect = action.search ? newPhones : [...state.phonesSelect, ...newPhones]
      phonesSelect = phonesSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
        ...state,
        // loading: false,
        phonesSelect: phonesSelect,
        phonesCount: action.payload.count
      }
    }

    case SMS_TYPES.FETCH_SMS_PHONES_R: {
      return {
        ...state,
        // loading: false,
        error: action.payload.response
      }
    }


    case SMS_TYPES.TELNYX_NUMBER_LIST_P: {
      return {
        ...state,
        loading: true
      }
    }

    case SMS_TYPES.TELNYX_NUMBER_LIST_F: {
      return {
        ...state,
        error: {},
        numberList: action.payload,
        loading: false
      }
    }

    case SMS_TYPES.TELNYX_NUMBER_LIST_R: {
      return {
        ...state,
        loading: false,
        error: action.payload.response
      }
    }

    case SMS_TYPES.TELNYX_NUMBER_BUY_P: {
      return {
        ...state,
        loading: true
      }
    }

    case SMS_TYPES.TELNYX_NUMBER_BUY_F: {
      return {
        ...state,
        error: {},
        phonesSelect: [
          { 
            value: action.payload.phone, 
            label: action.payload.phone 
          }, 
          ...state.phonesSelect
        ],
        loading: false
      }
    }

    case SMS_TYPES.TELNYX_NUMBER_BUY_R: {
      return {
        ...state,
        loading: false,
        error: action.payload.response
      }
    }

    case SMS_TYPES.UPDATE_SMS: {
      const sms = action.payload
      return {
        ...state,
        data: state.data.map(item => item.id == sms.id ? {...sms, store: item.store, deliveryStatus: sms.status} : item )
      }
    }

    case SMS_TYPES.SMS_STATISTICS_P: {
      return {
          ...state,
          loading: true
      }
    }

    case SMS_TYPES.SMS_STATISTICS_F: {
      return {
          ...state,
          statistics: action.payload
      }
    }

    case SMS_TYPES.SMS_STATISTICS_R: {
      return {
          ...state,
          error: action.payload.data
      }
    }

    case SMS_TYPES.SMS_GET_ONE_P: {
      return {
        ...state,
        loading: true
      }
    }

    case SMS_TYPES.SMS_GET_ONE_F: {
      const sms =  new Fields()

      sms.to = action.payload.to
      sms.message = action.payload.message
      sms.from = action.payload.from
      sms.provider = action.payload.provider
      sms.storeId = action.payload.storeId
      sms.store = action.payload.store
      sms.files = action.payload.files
      sms.message = ''

      if (!action.payload.provider) {
        sms.provider = 'telnyx'
      }

      if (action.payload.inbound) {
        sms.inboundMessage = action.payload.message
        sms.to = action.payload.from
        sms.from = action.payload.to
      }

      return {
        ...state,
        error: {},
        sms: sms,
        loading: false
      }
    }

    case SMS_TYPES.SMS_GET_ONE_R: {
      return {
        ...state,
        loading: false,
        error: action.payload.response
      }
    }

    default:
      return state;
  }
}


export {
    sms
}

