import { SECTION_TYPES } from '../../types'

const initialState = {
  loading: false,
  error: []
};


const sections = ( state = initialState, action = {}) => {

  switch (action.type) {

    case SECTION_TYPES.SECTION_CREATE_P: {
      return { 
        ...state,
        loading: true
      }
    }

    case SECTION_TYPES.SECTION_CREATE_F: {
      return { 
        ...state,
        loading: false
      }
    }

    case SECTION_TYPES.SECTION_CREATE_R: {
      return { 
        ...state,
        loading: false,
        error: action.payload.response
      }
    }

    case SECTION_TYPES.SECTION_UPDATE_P: {
      return { 
        ...state,
        loading: true
      }
    }

    case SECTION_TYPES.SECTION_UPDATE_F: {
      return { 
        ...state,
        loading: false
      }
    }

    case SECTION_TYPES.SECTION_UPDATE_R: {
      return { 
        ...state,
        loading: false,
        error: action.payload.response
      }
    }
   

    default:
        return state;
  }
}
export {
  sections
}