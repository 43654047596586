import {informationService} from "../services/informationService";
import { INFORMATION_TYPES,} from "../types"
import { PREVIEWURL, SITE_NAME } from '../../../config/app.json'
import { Notification } from 'element-react'


/**
 * @name getAllInformations
 * @returns {function(*)}
 */
const getAll = (token) => {
  return dispatch => {
    return informationService.getAll(token).then(options => {
      return dispatch({
        type: INFORMATION_TYPES.GET_INFORMATIONS,
        payload : options
      })
    })
  }
};

/**
 * @name getAllRemovedInformations
 * @returns {function(*)}
 */
const getAllRemoved = (token) => {
  return dispatch => {
    return informationService.getAllRemoved(token).then(options => {
      return dispatch({
        type: INFORMATION_TYPES.GET_DELETED_INFORMATIONS,
        payload : options
      })
    })
  }
};

/**
 * @name selectElem
 * @description select category item that edit item
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : INFORMATION_TYPES.SELECT_INFORMATION_ITEMS,
      payload: elem
    })
  }
};

const getItem = (id, token) => {
  return dispatch => {
    return informationService.getItem(id, token).then(data => {
      return dispatch({
        type: INFORMATION_TYPES.GET_INFORMATION_ITEM,
        payload: data
      })
    })
  }
};


/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return informationService.getPaginationNotRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: INFORMATION_TYPES.GET_PAG_NOT_REMOVED_INFORMATIONS,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return informationService.getPaginationRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: INFORMATION_TYPES.GET_PAG_REMOVED_INFORMATIONS,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt) => {
  return dispatch => {
    informationService.remove(elem.informationId,  jwt).then((data) => {
      if (data.status) {
      return dispatch({
        type: INFORMATION_TYPES.DELETE_INFORMATION_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    informationService.recover(elem.informationId,  jwt).then((data) => {
      if (data.status) {
      return dispatch({
        type: INFORMATION_TYPES.RECOVER_INFORMATION_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    informationService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: INFORMATION_TYPES.ARCHIVE_INFORMATION_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    informationService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: INFORMATION_TYPES.BULK_RECOVER_INFORMATION_ITEMS,
        payload : data
      })
    })
  }
};

const destroyInformation = ( token, information ) => {

  return async dispatch => {

    try {

      const data = await informationService.destroy(token, information.informationId)

        Notification.success( {
          title: 'information ',
          message: `${information.source} information succesfuly deleted `
        }, 20 )

        dispatch( {
            type: INFORMATION_TYPES.DESTROY_INFORMATION,
            payload: information,
            data: data
        } )
        
    } catch (e) {

        Notification.error( {
          title: e.name,
          message: `information not deleted`
        }, 20) 
       
      }
    }
  }

  /**
  * @name bulkDestroy
  * @param elem
  * @returns {function(*)}
  */
  const bulkDestroy = (ids, jwt) => {
    return dispatch => {
      informationService.bulkDestroy(ids,  jwt).then((data) => {
        return dispatch({
          type: INFORMATION_TYPES.BULK_DESTROY_INFORMATION_ITEMS,
          payload : data
        })
      })
    }
  };

  
  const informationPreview = (token, id) => {
    return async _dispatch => {
      try {

          let response = await informationService.preview(token, id)

          if (SITE_NAME !== 'kushweeds') {
           
            window.open(`${PREVIEWURL}blog/default/${response.data.id}`, '_blank')

          } else {

            window.open(`${PREVIEWURL}blog/${response.data.id}`, '_blank')
            
          }
            
          
          
      } catch (error) {
          error = error.response.data.error
          Notification.error({
            title: error.message,
            message: `${error.error.parent.code} / ${error.error.parent.sql}`
        }, 20) 
      }
    }
  }

export {getAll, getAllRemoved, getItem,  selectItem, getPaginationNotRemoved, getPaginationRemoved, deleteItem, recoverItem, bulkDelete, bulkRecover, destroyInformation, informationPreview, bulkDestroy}