import config from '../../../config/app'

class UsersService {
  constructor(api, all) {
    this.api = api;
    this.all = all;
    
  }
  
  getHeaders(jwt) {
    return new Headers({
      "Content-Type": "application/json",
      'Authorization': `Bearer ${jwt}`
    })
  }
  
  getUsers(jwt) {
    const url = `${this.api}${this.all}`;
    return fetch(url, {
      method: 'GET',
      headers : this.getHeaders(jwt),
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  getDeletedUsers(jwt) {
    const url = `${this.api}${this.all}d`;
    return fetch(url, {
      method: "GET",
      headers : this.getHeaders(jwt),
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  /*crud*/
  getUserItem (id, jwt) {
    const url = `${this.api}${this.all}${id}`;
    return fetch(url, {
      method: "GET",
      headers : this.getHeaders(jwt),
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  create(body, jwt) {
    const url = `${this.api}${this.all}`;
    return fetch(url, {
      method: 'POST',
      headers : this.getHeaders(jwt),
      body: JSON.stringify(body),
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  remove(id, jwt) {
    const url = `${this.api}${this.all}${id}`;
    return fetch(url, {
      method: "DELETE",
      headers : this.getHeaders(jwt),
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  edit(_body, id, jwt) {
    const url = `${this.api}${this.all}${id}`;
    return fetch(url, {
      method: "DELETE",
      headers : this.getHeaders(jwt),
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  /*crud*/
  
  getUsersFranchise(id, jwt) {
    const url = `${this.api}${this.all}fr/${id}`;
    return fetch(url, {
      method: 'GET',
      headers : this.getHeaders(jwt),
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
}

const  all = 'u/';

const usersService = new UsersService(config['USER'], all);

export {usersService}