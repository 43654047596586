import React from 'react'
import './Radio.css'

function Radio (props) {
    const { data, title, value, handleChange, type } = props

    return (
        <form style={{display: 'inline-block'}}> 
                <p className="title">{title}</p>
                {
                    data.map((item, index) =>
                        <div key = {index} className="new-checkbox">
                            <label className="h6">
                                <input
                                    type= {type}
                                    value={item.value}
                                    checked={value === item.value}
                                    onChange={handleChange}
                                />
                                {
                                    item.value
                                }
                            </label>
                        </div>
                    )
                }
        </form>
    )
}
  

export default Radio