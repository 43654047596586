import { NOTIFICATION_SETTINGS_TYPES } from '../../types'

const initialState = {
    settings: [],
    error: [],
    loading: false,
};


const notificationSettings = ( state = initialState, action = {}) => {

    switch (action.type) {

        case NOTIFICATION_SETTINGS_TYPES.NOTIFICATION_SETTINGS_UPDATE_P: {
            return { 
                ...state,
                loading: true
            }
        }

        case NOTIFICATION_SETTINGS_TYPES.NOTIFICATION_SETTINGS_UPDATE_F: {
            return { 
                ...state,
                loading: false
            }
        }

        case NOTIFICATION_SETTINGS_TYPES.NOTIFICATION_SETTINGS_UPDATE_R: {
            return { 
                ...state,
                loading: false,
                error: action.payload.response
            }
        }

        case NOTIFICATION_SETTINGS_TYPES.NOTIFICATION_SETTINGS_GET_ONE_P: {

            return { 
                ...state,
                loading: false,
                error: []
            }
        }

        case NOTIFICATION_SETTINGS_TYPES.NOTIFICATION_SETTINGS_GET_ONE_F: {

            const keys = [
                { 
                    name: 'Dashboard',
                    description: 'Choose which notifications you’d like to receive on your Nulla Dashboard.', 
                    key: 'inApp' 
                }, 
                { 
                    name: 'Email', 
                    description: 'Choose which notifications you’d like to receive on your Nulla Dashboard.',
                    key: 'email'
                }, 
                { 
                    name: 'Push',
                    description: 'Choose which notifications you’d like to receive on your Nulla Dashboard.', 
                    key: 'push' 
                }, 
                { 
                    name: 'Sms', 
                    description: 'Choose which notifications you’d like to receive on your Nulla Dashboard.',
                    key: 'sms' 
                }
            ]

            keys.forEach((elemKey, index) => {
              keys[index].notificationsGroup = []
              action.payload.forEach((group) => {
                let elem = {...group}
                elem['notificationTypes'] = []
                group.notificationTypes.forEach((item) => {
                  if (item[elemKey.key]) {
                    const y = group.userSettings.find( x => item.notificationTypeId === x.notificationTypeId);
                    elem['notificationTypes'].push({...item, ...y})
                  }
                })
                if (elem['notificationTypes'].length) {
                  keys[index].notificationsGroup.push(elem)
                }
              })
            })

            return { 
                ...state,
                settings: keys.filter(item => item.notificationsGroup.length),
                loading: false,
                error: []
            }
        }

        case NOTIFICATION_SETTINGS_TYPES.NOTIFICATION_SETTINGS_GET_ONE_R: {

            return { 
                ...state,
                loading: false,
                error: []
            }
        }

        default:
            return state;
    }
}
export {
  notificationSettings
}