import React, { Fragment } from "react";
import "./Dialler.css";
import KeypadButton from "./KeypadButton";

const Dialler = ({ number, setNumber, pleaceholder, setCode, phone, connection }) => {

  const handleNumberChange = event => {
    setNumber(event.target.value);
  };

  const onNumberPress = event => {
    setCode(event.target.value);
    connection.sendDigits(event.target.value);
  };

  const handleBackSpace = () => {
    if (phone) {
      setNumber(number.substring(0, number.length - 1));
    } else {
      setCode(number.substring(0, number.length - 1));
    }
  };

  const handleNumberPressed = newNumber => {
    if (phone) {
      return () => {
        setNumber(`${number}${newNumber}`);
      };
    } else {
      return () => {
        setCode(`${number}${newNumber}`);
        connection.sendDigits(newNumber);
      }
    }
  };

  return (
    <Fragment>
      {
        phone ?
        <input
          type="tel"
          value={number}
          placeholder={pleaceholder}
          onChange={handleNumberChange}
          className="call-input"
        /> 
        :
        <input
          type="text"
          value={number}
          placeholder={pleaceholder}
          onChange={onNumberPress}
          className="call-input"
        />
      }
      <ol className="keypad">
        <li>
          <KeypadButton handleClick={handleNumberPressed("1")}>1</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("2")}>2</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("3")}>3</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("4")}>4</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("5")}>5</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("6")}>6</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("7")}>7</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("8")}>8</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("9")}>9</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("+")}>+</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={handleNumberPressed("0")}>0</KeypadButton>
        </li>
        {number.length > 0 && (
          <li>
            <KeypadButton handleClick={handleBackSpace}>&lt;&lt;</KeypadButton>
          </li>
        )}
      </ol>
    </Fragment>
  );
};

export default Dialler;