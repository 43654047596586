import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import Layout from './Layout';

function NotificationPopup(props) {
    const { isOpen, mainNotifications, loading, destroyNotification, updateNotification, allRead, fetchNotifications } = props

    function timeSince(date) {
        const NOW = new Date()
        const times = [["second", 1], ["minute", 60], ["hour", 3600], ["day", 86400], ["week", 604800], ["month", 2592000], ["year", 31536000]]
        let diff = Math.round((NOW - new Date(date)) / 1000)
        for (let t = 0; t < times.length; t++) {
            if (diff < times[t][1]) {
                if (t == 0) {
                    return "Just now"
                } else {
                    diff = Math.round(diff / times[t - 1][1])
                    return diff + " " + times[t - 1][0] + (diff == 1?" ago":"s ago")
                }
            }
        }
    }


    function acronym_name(str){
        const regular_ex=/\b(\w)/g;
        const matches = str.match(regular_ex);
        // return the acronym
        return  matches.join('');
    }

    return (
        <div 
            className="notification-popup" 
            style={{display: isOpen ?  'block' : 'none'}}>

            <div className="notification-top">
                <h6 className="notification-title">Notification</h6>
                <h6 
                    style={{cursor: 'pointer'}} 
                    className="notification-title"
                    onClick={() => allRead()}>
                        Mark all as read
                </h6>
            </div>

            <div className="notification-content">
                <Layout
                   load={loading}
                   loadComponent={<div className="no-notification">Loading...</div>}
                   className="notification-content"
                   isFetch = {!!(props.count > mainNotifications.length)}
                   fetchData={(page, count) => fetchNotifications(page, count, true)}
                >
                    <div>
                        {

                            mainNotifications.length ? 
                            mainNotifications.map((item, i) => 
                                <div key = { i } className="notification-list" onClick={() => updateNotification(item._id)}>
                                    <div 
                                        className="notification-item"
                                        style = {!item.is_read ? { 'background': '#aaaaaa'}: {}}>
                                        <div 
                                            onClick={() => destroyNotification(item._id)}
                                            className="notification-delete">
                                            <svg viewBox="0 0 12 12">
                                                <path d="M6.967 6L11.8 1.168c.26-.263.266-.7 0-.967-.27-.268-.7-.266-.968 0L6 5.034 1.168.2C.905-.06.468-.065.2.2c-.268.27-.266.7 0 .968L5.034 6 .2 10.832c-.26.263-.266.7 0 .967.27.268.7.266.968 0L6 6.966l4.832 4.832c.263.26.7.266.967 0 .268-.27.266-.7 0-.968L6.966 6z"></path>
                                            </svg>
                                        </div>
                                        <Link className="notification-link" to = {item.type == 'review' ? item.content ? `/catalog/review/update/${item.content.reviewId}` : '/catalog/review' : item.type == 'order' ? item.content ? `/sale/order/update/${item.content.Id}` : '/sale/order'  : item.type == 'chat' ? item.content ? `/user/chat?chatRoomID=${item.content.chatRoomID}&memberID=${item.sender}` : '/user/chat' : '/user/customer'}>
                                            {
                                                !!(item.user && item.user.length) && 
                                                <div className="notification-item-avatar">
                                                    <h2>{acronym_name(item.user[0].firstname ||  (item.user[0].info && item.user[0].info.firstName) || 'Anonymous')}</h2>
                                                </div>
                                            }
                                        </Link>
                                        <Link className="notification-link" to = {item.type == 'inbound' ? `/sms/send/${item.content && item.content.smsId}` : item.type == 'review' ? item.content ? `/catalog/review/update/${item.content.reviewId}` : '/catalog/review' : item.type == 'order' ? item.content ? `/sale/order/update/${item.content.Id}` : '/sale/order' : item.type == 'chat' ? item.content ? `/user/chat?chatRoomID=${item.content.chatRoomID}&memberID=${item.sender}` : '/user/chat' : item.type == 'order_delivery' ? `/delivery/delivery/${item.content.deliveryRouteId}` : '/user/customer'}>
                                            <div className="notification-item-info">
                                                <h2 className="notification-item-info-name">
                                                    {
                                                        !!(item.user && item.user.length) &&
                                                        <Fragment>
                                                            {
                                                                item.user[0].firstname  || (item.user[0].info && item.user[0].info.firstName)} {item.user[0].lastname || (item.user[0].info && item.user[0].info.lastName)
                                                            }
                                                        </Fragment>
                                                    }
                                                    <span> {item.message}</span>
                                                </h2>
                                                <span className="notification-item-info-time">{timeSince(item.created_at)}</span>
                                            </div>
                                        </Link>
                                        
                                    </div>
                                </div>
                            )
                            : 
                            !loading &&  
                            <div className="no-notification">There is no notification</div>
                        }
                    </div>
                </Layout>
            </div>

        </div>
    )
 }
 export default NotificationPopup