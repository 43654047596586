import React, { useEffect, useState, useRef } from 'react';
import './Select.scss'
import './Radio.css'

const ChevronDown = () => (
    <svg viewBox="0 0 10 7">
        <path d="M2.08578644,6.5 C1.69526215,6.89052429 1.69526215,7.52368927 2.08578644,7.91421356 C2.47631073,8.30473785 3.10947571,8.30473785 3.5,7.91421356 L8.20710678,3.20710678 L3.5,-1.5 C3.10947571,-1.89052429 2.47631073,-1.89052429 2.08578644,-1.5 C1.69526215,-1.10947571 1.69526215,-0.476310729 2.08578644,-0.0857864376 L5.37867966,3.20710678 L2.08578644,6.5 Z" transform="translate(5.000000, 3.207107) rotate(90.000000) translate(-5.000000, -3.207107) " />
    </svg>
)

const ChevronUp = () => (
    <svg viewBox="0 0 10 8">
        <path d="M2.08578644,7.29289322 C1.69526215,7.68341751 1.69526215,8.31658249 2.08578644,8.70710678 C2.47631073,9.09763107 3.10947571,9.09763107 3.5,8.70710678 L8.20710678,4 L3.5,-0.707106781 C3.10947571,-1.09763107 2.47631073,-1.09763107 2.08578644,-0.707106781 C1.69526215,-0.316582489 1.69526215,0.316582489 2.08578644,0.707106781 L5.37867966,4 L2.08578644,7.29289322 Z" transform="translate(5.000000, 4.000000) rotate(-90.000000) translate(-5.000000, -4.000000) " />
    </svg>
)


const Check = () => (
    <svg viewBox="0 0 16 16">
        <path d="M13 .156l-1.406 1.438-5.594 5.594-1.594-1.594-1.406-1.438-2.844 2.844 1.438 1.406 3 3 1.406 1.438 1.406-1.438 7-7 1.438-1.406-2.844-2.844z" transform="translate(0 1)" />
    </svg>
)

function Selected ({ account, options, fetchData, actives, label, count, multiple, setValue, storeIds }) {
    const selectedOptions = options.filter(({checked}) => checked);
    const [page, setPage] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [defaultValues, setDefaultValues] = useState([]);
    const [values, setValues] = useState([]);
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState([]);


    useEffect(() => {
        fetchData(account.token, page, 10, { name: search }, true)
    }, [storeIds])

    useEffect(() => {
        if (actives) {
            setValues(actives)
        }
    }, [actives])

    useEffect(() => {
        const newValues = [];
        const newDefaultvalues = [];
        selectedOptions.forEach((value) => {
            const index = values.findIndex((data) => data.value == value.value)
            const selectedIndex = selected.findIndex((data) => data.value == value.value)
            if (index === -1 && selectedIndex === -1) {
                newValues.push({...value})
            }
            const defaultIndex = defaultValues.findIndex((data) => data.value == value.value)
            if (defaultIndex === -1) {
                newDefaultvalues.push({...value})
            }
        })

        setValues([
            ...newValues,
            ...values
        ])
        
        setDefaultValues([
            ...newDefaultvalues,
            ...defaultValues
        ])

    }, [options])

    function open() {
        fetchData(account.token, page, 10, { name: search }, true)
        setIsOpen(!isOpen)
    }

    function searchData(e) {
        setSearch(e.target.value);
        fetchData(account.token, 1, 10, { name: e.target.value }, true)
    }

    function loadMore() {
        setPage(page + 1)
        fetchData(account.token, page + 1, 10,  { name: search })
    }

    function onClickOption(value) {

        if (multiple){

            setValues(prevState => {

                const [...values] = prevState
                const index = values.findIndex((data) => data.value == value.value)
                if (index === -1) {
                    value.checked = true
                    values.push({...value})
                } else {
                    value.checked = false
                    values.splice(index, 1)  
                }

                const selectedData = []

                defaultValues.forEach((selected) => {
                    if (!values.find(el => el.value == selected.value)) {
                        selected.checked = false
                        selectedData.push(selected)
                    } 
                })

                values.forEach(elem => {
                    if (!defaultValues.find(el => el.value == elem.value)) {
                      selectedData.push(elem)
                    }
                })

                setSelected(selectedData)
                
                setValue(selectedData);

                return values

            })

        } else {
            setValues([value])
        }
    }
    
    return (
        <div tabIndex="0">
            <label className="label">{ label }</label>
            <div className="selection-multiple">
                <input 
                    onClick={() => open()} 
                    className='form-control' 
                    style={{width: '100%'}} 
                    value = {search} 
                    onChange={(e) => searchData(e)} 
                    placeholder="Search"
                />
                <span
                    onClick={() => open()} 
                    style={{cursor: 'pointer'}} 
                    className="arrow">
                    { isOpen ? <ChevronUp /> : <ChevronDown /> }
                </span>
            </div>
            {
                isOpen &&
                <div className="options-multiple">
                    {
                        options.map((item, index) => {
                            const { value, label, imgUrl } = item
                            const selected = values.find((data) => data.value == value )
                            let className = "option"
                            if (selected) className += " selected"

                            return (
                                <div key={ index }>
                                    <div
                                        key={ value }
                                        data-value={ label }
                                        className={ className }
                                        onClick={ () => onClickOption(item) }
                                    >
                                        { 
                                            !!multiple ?
                                            <span className="checkbox">
                                                { !!selected && <Check /> }
                                            </span>
                                            :
                                            <span>
                                                <input type="radio" checked={selected} name="radio-group"/>
                                                <label ></label>
                                            </span>
                                        }
                                        <img src={imgUrl}/>
                                        { label }
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        count > options.length &&
                        <div 
                            onClick={() => loadMore()} 
                            style={{ 
                                cursor: 'pointer', 
                                textAlign: 'center', 
                                marginTop: '20px', 
                                marginBottom: '15px' 
                            }}
                        >
                            Load More
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Selected