import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import {
    orderActions, businessActions
  } from "../../projectNulla/redux-modules/actions";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    ButtonToolbar,
    CardTitle,
    Col,
    Row
} from 'reactstrap';
import { DatePicker } from '../../projectNulla/catalogs/Globals';
import ReactSelect from 'react-select';
const brandInfo = getStyle('--info');
const statusColors = {
    all: brandInfo,
    accepted: '#20a8d8',
    cancel: '#2f353a',
    new: '#f86c6b',
    done: '#acb5bc',
    delivering: '#ffc107',
    progress: '#3a9d5d'
}
  
function OrderChart(_props) {
    const dispatch = useDispatch()
    const selectRef = useRef([]);
    const [selectedBusiness, setSelectedBusines] = useState([])
    const [closeOnSelectBusiness, setCloseOnSelectBusiness] = useState(false)
    const [page, setPage] = useState(1)
    const [purchaseTypePage, setPurchaseTypePage] = useState(1);
    const [closeOnSelectPurchaseType, setCloseOnSelectPurchaseType] = useState(false);
    const [selectedPurchiseType, setSelectedPurchiseType] = useState([])
    let [status, setStatus] = useState(['all'])
    const { statistics, purchaseTypes } = useSelector((state) => state.newOrders);
    const { count: businessCount, businessSelect } = useSelector((state) => state.business);
    const maxCount = Math.max(...statistics.data.map(o => o.count));

    const mainChartOpts = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
                    labelColor: function(tooltipItem, chart) {
                    return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                }
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    display: false,
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxCount / 5),
                        max:  maxCount
                    },
                }
            ],
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            },
        },
    };

    const { token } = useSelector((state) => state.account);
    const [date, setDate] = useState({ startDate: '', endDate: '' })
    const [radioSelected, setRadioSelected] = useState('week');

    const selectBusiness = (selectedOption) => {
        let selectedOptions = []
        if (selectedOption.length) {
            selectedOption.forEach(elem => {
                if (elem.value === 'all') {
                    let businessPage = page + 1
                    setPage(businessPage)
                    setCloseOnSelectBusiness(false)
                    dispatch(businessActions.seeAll(token, businessPage, 10))
                }  else {
                    selectedOptions.push(elem)
                }
            })
        }
        setSelectedBusines(selectedOptions)
    }

    const selectPurchaseType = (selectedOption) => {
        let selectedOptions = []
        if (selectedOption.length) {
            selectedOption.forEach(elem => {
                if (elem.value === 'all') {
                    setPurchaseTypePage(purchaseTypePage + 1)
                    setCloseOnSelectPurchaseType(false)
                    dispatch(orderActions.getTypes(token,  purchaseTypePage + 1, 10))
                }  else {
                    selectedOptions.push(elem)
                }
            })
        }
        setSelectedPurchiseType(selectedOptions)
    }

    useEffect(() => {
        dispatch(orderActions.statistics(token, { 
            dateFrom: date.startDate,
            dateTo: date.endDate,
            date: radioSelected,
            status: status,
            deleted: false,
            businessIds: selectedBusiness.map(({value}) => value),
            orderTypeIds: selectedPurchiseType.map(({value}) => value) 
        }));
    }, [date, radioSelected, status, selectedBusiness, selectedPurchiseType])

    useEffect(() => {
        dispatch(businessActions.getStatistics(token, { 
            startDate: date.startDate,
            endDate: date.endDate,
            status: status,
            deleted: false,
        }));
    }, [date, status])

    function onRadioBtnClick(value) {
        setRadioSelected(value)
    }
    
    const getClientLanguage = () => {
        let Locale = navigator.language || navigator.userLanguage;
        if (Locale.includes('-')) Locale = Locale.split('-')[0];
        return Locale.toLowerCase();
    };

    const genericFormattedDate = (DateToFormat) => {
        const UserLanguage = getClientLanguage();
        const Config = { year: 'numeric', month: 'long', hour: '2-digit', minute:'2-digit', day: '2-digit' };
        return new Date(DateToFormat).toLocaleString(UserLanguage, Config);
    };

    const mainChart = {
        labels: statistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'Order',
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: brandInfo,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: statistics.data.map(d => d.count),
            }
        ],
    };

    if (status.length) {
        mainChart.datasets = []
        status.forEach(item => {
            mainChart.datasets.push({
                label: `Status ${item}`,
                fill: item == 'all',
                lineTension: 0.4,
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: statusColors[item], // The main line color
                borderCapStyle: 'square',
                borderDash: [], // try [5, 15] for instance
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: statusColors[item],
                pointBackgroundColor: "white",
                pointBorderWidth: 1,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "yellow",
                pointHoverBorderColor: "brown",
                pointHoverBorderWidth: 1,
                pointRadius: 2,
                pointHitRadius: 5,
                data: statistics.data.map(d => d[item] || 0),
            })
        })
    }

    function handleChangeDate (event, key) {
        setDate({
            ...date,
            [key]: event.target.value
        })
    }

    function openMenu(index) {

        if (index == 0) {
            if (!businessSelect.length) {
                dispatch(businessActions.seeAll(token, 1, 10));  
            }
        }

        if (index == 1) {
            if (!purchaseTypes.data.length) {
                dispatch(orderActions.getTypes(token, 1, 10))
            }
        }

        selectRef.current[index].focus()
    }

    const handleInputChangeBusiness = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        dispatch(businessActions.seeAll(token, 1, 10, { name: inputValue }, !!inputValue))
    };

    const setOrRemoveStatus = (value) => {
        if (status.includes(value)) {
            status = status.filter((elem) => value !== elem)
            if (status.length) {
                setStatus(status)
            } else {
                setStatus(['all'])
            }
        } else {
            setStatus([...status, value])
        }
    }

    return (
        <Card style={{
            marginBottom: '0rem'
        }}>
             <CardBody>
                <Row>
                    <Col sm="1">
                        <CardTitle className="mb-0">Orders</CardTitle>
                    </Col>
                    <Col sm="4">
                        <ButtonToolbar className="float-left" aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-1" aria-label="First group">
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('all')} active={status.includes('all')}>All</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('new')} active={status.includes('new')}>New</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('done')} active={status.includes('done')}>Done</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('cancel')} active={status.includes('cancel')}>Cancel</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('progress')} active={status.includes('progress')}>Progress</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('approval')} active={status.includes('approval')}>Approval</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('delivering')} active={status.includes('delivering')}>Delivering</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('accepted')} active={status.includes('accepted')}>Accepted</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                    <Col sm="1">
                        <div style={{width: "150px"}}>
                            <ReactSelect
                                ref={el => selectRef.current[0] = el}
                                onFocus={() => openMenu(0)}
                                closeOnSelect = {closeOnSelectBusiness}
                                name="form-field-name"
                                value={selectedBusiness}
                                onInputChange={handleInputChangeBusiness}
                                multi = {true}
                                onChange={selectBusiness}
                                options={
                                    businessCount > businessSelect.length 
                                    ? [...businessSelect, { value: 'all', label: 'See More'}]
                                    : [...businessSelect]
                                }
                                placeholder={<div>Business</div>}
                            />
                        </div>
                    </Col>
                    <Col sm="1">
                        <div style={{width: "150px"}}>
                            <ReactSelect
                                ref={el => selectRef.current[1] = el}
                                onFocus={() => openMenu(1)}
                                closeOnSelect = {closeOnSelectPurchaseType}
                                name="form-field-name"
                                value={selectedPurchiseType}
                                multi = {true}
                                onChange={selectPurchaseType}
                                options={
                                    purchaseTypes.count > purchaseTypes.data.length 
                                    ? [...purchaseTypes.data, { value: 'all', label: 'See More'}]
                                    : [...purchaseTypes.data]
                                }
                                placeholder={<div>Purchase Type</div>}
                            />
                        </div>
                    </Col>
                    <Col sm="3">
                        <DatePicker
                            handleChange={handleChangeDate}
                            value={{
                                startDate: date.startDate,
                                endDate: date.endDate 
                            }}
                        />
                    </Col>
                    <Col sm="2" className="d-none d-sm-inline-block">
                        <ButtonToolbar className="float-right" aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-3" aria-label="First group">
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
                    <Line data={mainChart} options={mainChartOpts} height={300} />
                </div>
            </CardBody>
            <CardFooter>
                <Row className="text-center">
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Total</div>
                    <strong>{Math.round((statistics.taxTotal) * Math.pow(10,0)) / Math.pow(10,0) + Math.round((statistics.shippingTotal) * Math.pow(10,0)) / Math.pow(10,0) + Math.round((statistics.priceTotal) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'} Total</strong>
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">minPrice</div>
                    <strong>{Math.round((statistics.minPrice) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'} minPrice</strong>
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">maxPrice</div>
                    <strong>{Math.round((statistics.maxPrice) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'} maxPrice</strong>
                  </Col>
                </Row>
            </CardFooter>
        </Card>
    )
}

export default OrderChart