const REVIEW_PAG_F = 'REVIEW_PAG_L_F';
const REVIEW_PAG_R = 'REVIEW_PAG_L_R';
const REVIEW_SIZE_CHANGE = 'REVIEW_SIZE_CHANGE'
const REVIEW_PAGE_CHANGE = 'REVIEW_PAGE_CHANGE'
const REVIEW_FILETR_TABE_CHANGE = 'REVIEW_FILETR_TABE_CHANGE'
const REVIEW_UPDATE_F = 'REVIEW_UPDATE_F'
const REVIEW_UPDATE_R = 'REVIEW_UPDATE_R'
const REVIEW_CHECKBOX_CHANGE = 'REVIEW_CHECKBOX_CHANGE'
const REVIEW_BULK_ARCIVE_F = 'REVIEW_BULK_ARCIVE_F'
const REVIEW_BULK_ARCIVE_R = 'REVIEW_BULK_ARCIVE_R'
const REVIEW_BULK_DESTROY_F = 'REVIEW_BULK_DESTROY_F'
const REVIEW_BULK_DESTROY_R = 'REVIEW_BULK_DESTROY_R'
const REVIEW_TAB_PANEL_CHANGE = 'REVIEW_TAB_PANEL_CHANGE'
const REVIEW_CREATE_F = 'REVIEW_CREATE_F'
const REVIEW_CREATE_R = 'REVIEW_CREATE_R'
const REVIEW_GET_ONE_F = 'REVIEW_GET_ONE_F'
const REVIEW_GET_ONE_R = 'REVIEW_GET_ONE_R'
const REVIEW_REQUIRED_VALIDATION_F = 'REVIEW_REQUIRED_VALIDATION_F'
const REVIEW_REQUIRED_VALIDATION_R = 'REVIEW_REQUIRED_VALIDATION_R'
const NEW_REVIEW = 'NEW_REVIEW'
const REVIEW_DATA_FILTER = 'REVIEW_DATA_FILTER'
const REVIEW_DELETE_F = 'REVIEW_DELETE_F'
const REVIEW_DELETE_R = 'REVIEW_DELETE_R'
const REVIEW_LIVE_DATA = 'REVIEW_LIVE_DATA'
const REVIEW_DATA_FILTER_SEARCH = 'REVIEW_DATA_FILTER_SEARCH'
const REVIEW_FILTER_IS_OPEN = 'REVIEW_FILTER_IS_OPEN'
const REVIEW_LANGUAGE_PANEL_CHANGE = 'REVIEW_LANGUAGE_PANEL_CHANGE'
const REVIEW_DATA_FILTER_SEARCH_RESET = 'REVIEW_DATA_FILTER_SEARCH_RESET'
const REVIEW_DATA_FILTER_ALL_SEARCH_RESET = 'REVIEW_DATA_FILTER_ALL_SEARCH_RESET'

export {
    REVIEW_PAG_F,
    REVIEW_PAG_R,
    REVIEW_SIZE_CHANGE,
    REVIEW_PAGE_CHANGE,
    REVIEW_FILETR_TABE_CHANGE,
    REVIEW_UPDATE_F,
    REVIEW_UPDATE_R,
    REVIEW_CHECKBOX_CHANGE,
    REVIEW_BULK_ARCIVE_F,
    REVIEW_BULK_ARCIVE_R,
    REVIEW_BULK_DESTROY_F,
    REVIEW_BULK_DESTROY_R,
    REVIEW_TAB_PANEL_CHANGE,
    REVIEW_CREATE_F,
    REVIEW_CREATE_R,
    REVIEW_GET_ONE_F,
    REVIEW_GET_ONE_R,
    REVIEW_REQUIRED_VALIDATION_F,
    REVIEW_REQUIRED_VALIDATION_R,
    NEW_REVIEW,
    REVIEW_DATA_FILTER,
    REVIEW_DELETE_F,
    REVIEW_DELETE_R,
    REVIEW_LIVE_DATA,
    REVIEW_DATA_FILTER_SEARCH,
    REVIEW_FILTER_IS_OPEN,
    REVIEW_LANGUAGE_PANEL_CHANGE,
    REVIEW_DATA_FILTER_SEARCH_RESET,
    REVIEW_DATA_FILTER_ALL_SEARCH_RESET
}