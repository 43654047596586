import config from '../../../config/app';
import {Service} from "./Service";

const all = 'c/f/r';
const allR = 'c/f/d';
const rest = 'c/f';
const pagNotRem = "c/f/r";
const pagRem= "c/f/d";
const bulkDel = 'c/f/b/a';
const bulkRec = 'c/f/b/r'
const bulkDes = 'c/f/b/d'

const filterService = new Service(config["FILTER"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);
export {filterService}