import { CUSTOMER_GROUP_TYPES } from '../../types'
import CustomerGroupFields from '../../../customer/CustomerGroup/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  oneData: new CustomerGroupFields(),
  error: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedCustomerGroupPage') || 1,
    notRemoved: localStorage.getItem('notRemovedCustomerGroupPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedCustomerGroupPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedCustomerGroupPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    nameF: '',
    customerGroupF: '',
    datesF:  '',
  },
  dataFilter: {
  },
  isOpen: isMobile ? false : localStorage.getItem('customerGroupFilter') == 'true' ? true : false,
  tabPanel: '1',
  checked: false,
  ids: [],
  customerGroupSelect: [],
  filters: []
};


const customerGroups = ( state = initialState, action = {}) => {

  switch (action.type) {

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_FILTER_F: {
      return {
        ...state,
        filters: action.payload.data
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_FILTER_R: {
      return {
        ...state,
        error: action.payload.response
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_PAG_F: {
        return {
          ...state,
          data: action.payload.data.map((elem) => state.ids.includes(elem.customerGroupId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.customerGroupId)),
          count: action.payload.count
        }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.customerGroupId) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_GET_ONE_F: {
      let data = action.payload
      if (data.descriptions.length && state.langTab.languageId && data.descriptions.find(({languageId}) => languageId ==state.langTab.languageId )) {
        data.name = data.descriptions.find(({languageId}) => languageId == state.langTab.languageId ).name
      } else {
        data.descriptions = [
          {
            languageId: 1,
            name: '',
            description: ''
          }
        ]
        data.name = ''
      }
      return { 
          ...state,
          oneData: {
            ...data, 
            customers: state.oneData.customers
          },
          error: []
      }
    }

    case CUSTOMER_GROUP_TYPES.NEW_CUSTOMER_GROUP: {
      return { 
          ...state,
          oneData: new CustomerGroupFields(),
          error: []
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.customer !== id)
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_FILTER_IS_OPEN: {
      localStorage.setItem('customerGroupFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: { 
          name: '',
          dates:  ''
        }
      }
    }

    case CUSTOMER_GROUP_TYPES.FETCH_CUSTOMER_GROUP_SEE_ALL_F: {
      const newCustomerGroup = [];

      action.payload.data.forEach(customerGroup => {
        if (customerGroup.descriptions.length) {
          newCustomerGroup.push({
            value: customerGroup.customerGroupId,
            label: customerGroup.descriptions[0].name,
            customerCount: customerGroup.customercount
          });
        }
      });

      // Combine the existing state customer group data with the new customer group data
      let customerGroupSelect = [...state.customerGroupSelect, ...newCustomerGroup];

      // Create a map to store unique customer groups, replacing duplicates with the new values
      const customerGroupMap = new Map();

      customerGroupSelect.forEach(customerGroup => {
        customerGroupMap.set(customerGroup.value, customerGroup);
      });

      // Convert the map back to an array
      customerGroupSelect = Array.from(customerGroupMap.values());

      // Assign the updated customer group select list to the state
      return {
          ...state,
          customerGroupSelect: customerGroupSelect,
          count: action.payload.count
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER: {
      
      let filters = state.oneData.filters

       // todo
      if (!action.id) {

        filters.push({
          value: "",
          value1: "",
          filterGroupId: action.payload.filterGroupId,
          filterGroup: action.payload,
          filter: {},
          categories: [],
          business: []
        })
        
      } else {

        filters = filters.map((filter) => 
          filter.filterGroupId == action.id 
            ? 
            {
              value: "",
              value1: "",
              filterGroupId: action.payload.filterGroupId,
              filterGroup: action.payload,
              filter: {},
              categories: [],
              business: []
            } 
            : 
            filter
          )

        }

      return {
        ...state,
        oneData: { 
          ...state.oneData, 
          filters: filters 
        }
      }

    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_REMOVE_FILTER: {
      return {
        ...state,
        oneData: { 
          ...state.oneData, 
          filters: state.oneData.filters.filter(({filterGroupId}) => filterGroupId !== action.payload)  
        }
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_VALUE: {
      let filters = []

      state.oneData.filters.forEach((filterGroup) => {
        if (filterGroup.filterGroupId == action.payload.filterGroupId) {

          let filter = { 
            ...filterGroup, 
            filter: action.payload 
          }

          if (action.payload.dates.length) {
            filter.filterDate = action.payload.dates[0]
          }

          filters.push(filter)

        } else {

          filters.push(filterGroup)

        }
      })

      return {
        ...state,
        oneData: { 
          ...state.oneData, 
          filters: filters  
        }
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_DATE_VALUE: {
      return {
        ...state,
        oneData: { 
          ...state.oneData, 
          filters: state.oneData.filters.map((filterGroup) => filterGroup.filterGroupId == action.payload.filterGroupId ? { ...filterGroup, filterDate: action.payload }: filterGroup)  
        }
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_CATEGORIES: {
      const { all, checked, data } = action.payload
      const filters = state.oneData.filters.map((filterGroup) => 
        filterGroup.filterGroupId == action.payload.filterGroupId 
        ? 
        { 
          ...filterGroup, 
          categories: all ? checked ? [] : [...data] : filterGroup.categories.find(({categoryId}) => categoryId == action.payload.categoryId) ? filterGroup.categories.filter(({categoryId}) => categoryId !== action.payload.categoryId) : [...filterGroup.categories, action.payload]
        }
        : filterGroup
      ) 

      return {
        ...state,
        oneData: { 
          ...state.oneData, 
          filters: filters
        }
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_BUSINESS: {
      const { all, checked, data } = action.payload

      const filters = state.oneData.filters.map((filterGroup) => 
        filterGroup.filterGroupId == action.payload.filterGroupId 
        ? 
        { 
          ...filterGroup, 
          business: all ? checked ? [] : [...data] : filterGroup.business.find(({businessId}) => businessId == action.payload.businessId) ? filterGroup.business.filter(({businessId}) => businessId !== action.payload.businessId) : [...filterGroup.business, action.payload]
        }
        : filterGroup
      )
      
      return {
        ...state,
        oneData: { 
          ...state.oneData, 
          filters: filters 
        }
      }
    }

    case CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_IMPORT_CUSTOMER: {
      return {
        ...state,
        oneData: { 
          ...state.oneData, 
          customers: action.payload
        }
      }
    }

    default:
            return state;
  }
}
export {
    customerGroups
}

