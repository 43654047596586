import { FILE_TYPES } from '../types/index'


const defaultState = {
	file: null,
	loading: false,
	error: {}
};

const files = ( state = defaultState, action = {}) => {
  	switch (action.type) {

		case FILE_TYPES.UPLOAD_FILES_P: {
			return {
				...state,
				loading: true
			}
		}

		case FILE_TYPES.UPLOAD_FILES_F: {
			return {
				...state,
				loading: false,
				file: action.payload[0]
			}
		}

		case FILE_TYPES.UPLOAD_FILES_R: {
			return {
				...state,
				loading: false,
				error: action.payload
			}
		}

		case FILE_TYPES.GET_FILE_P: {
			return {
				...state,
				loading: true
			}
		}

		case FILE_TYPES.GET_FILE_F: {
			return {
				...state,
				loading: false,
				file: action.payload
			}
		}

		case FILE_TYPES.GET_FILE_R: {
			return {
				...state,
				loading: false,
				error: action.payload
			}
		}
  
    default:
        return state;
  	}
}

export {
    files
}