const GET_FRANCHISE = 'GET_FRANCHISE';
const GET_DELETED_FRANCHISE = 'GET_DELETED_FRANCHISE';
const SELECT_FRANCHISE_ITEMS = 'SELECT_FRANCHISE_ITEMS';
const GET_FRANCHISE_ITEM = 'GET_FRANCHISE_ITEM';
const GET_PAG_NOT_REMOVED_FRANCHISES = 'GET_PAG_NOT_REMOVED_FRANCHISES';
const GET_PAG_REMOVED_FRANCHISES = 'GET_PAG_REMOVED_FRANCHISES';
const DELETE_FRANCHISE_ITEMS = 'DELETE_FRANCHISE_ITEMS';
const RECOVER_FRANCHISE_ITEMS = 'RECOVER_FRANCHISE_ITEMS';
const ARCHIVE_FRANCHISE_ITEMS = 'ARCHIVE_FRANCHISE_ITEMS';
const BULK_RECOVER_FRANCHISE_ITEMS = 'BULK_RECOVER_FRANCHISE_ITEMS';
const NEW_FRANCHISE_FULFILLED = 'NEW_FRANCHISE_FULFILLED';
const NEW_FRANCHISE_REJECTED = 'NEW_FRANCHISE_REJECTED';
const SAVE_FRANCHISE_FULFILLED = 'SAVE_FRANCHISE_FULFILLED';
const SAVE_FRANCHISE_REJECTED = 'SAVE_FRANCHISE_REJECTED';
const FETCH_FRANCHISE_FULFILLED = 'FETCH_FRANCHISE_FULFILLED';
const FETCH_FRANCHISE_PENDING = 'FETCH_FRANCHISE_PENDING'
const UPDATE_FRANCHISE_FULFILLED = 'UPDATE_FRANCHISE_FULFILLED';
const LANG_TAB = 'LANG_TAB';
const DESTROY_FRANCHISE = 'DESTROY_FRANCHISE';
const BULK_DESTROY_FRANCHISE_ITEMS = 'BULK_DESTROY_FRANCHISE_ITEMS'
const FRANCHISE_TAB_PANEL_CHANGE = 'FRANCHISE_TAB_PANEL_CHANGE'
const FRANCHISE_FILTER_IS_OPEN = 'FRANCHISE_FILTER_IS_OPEN'
const FRANCHISE_DATA_FILTER = 'FRANCHISE_DATA_FILTER'
const FRANCHISE_DATA_FILTER_SEARCH = 'FRANCHISE_DATA_FILTER_SEARCH'
const FRANCHISE_DATA_MIN_MAX_F = 'FRANCHISE_DATA_MIN_MAX_F'
const FRANCHISE_SELECT_FILTER_TYPES = 'FRANCHISE_SELECT_FILTER_TYPES'
const FRANCHISE_DATA_FILTER_SEARCH_RESET = 'FRANCHISE_DATA_FILTER_SEARCH_RESET'
const FRANCHISE_DATA_FILTER_ALL_SEARCH_RESET = 'FRANCHISE_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_FRANCHISE_SEE_ALL_P = 'FETCH_FRANCHISE_SEE_ALL_P'
const FETCH_FRANCHISE_SEE_ALL_F = 'FETCH_FRANCHISE_SEE_ALL_F'
const FETCH_FRANCHISE_SEE_ALL_R = 'FETCH_FRANCHISE_SEE_ALL_R'
const GET_FRANCHISE_ORDER_STATISTICS_P = 'GET_FRANCHISE_ORDER_STATISTICS_P'
const GET_FRANCHISE_ORDER_STATISTICS_F = 'GET_FRANCHISE_ORDER_STATISTICS_F'
const GET_FRANCHISE_ORDER_STATISTICS_R = 'GET_FRANCHISE_ORDER_STATISTICS_R'
const GET_FRANCHISE_ORDER_ALL_STATISTICS_P = 'GET_FRANCHISE_ORDER_ALL_STATISTICS_P'
const GET_FRANCHISE_ORDER_ALL_STATISTICS_F = 'GET_FRANCHISE_ORDER_ALL_STATISTICS_F'
const GET_FRANCHISE_ORDER_ALL_STATISTICS_R = 'GET_FRANCHISE_ORDER_ALL_STATISTICS_R'

export {
  GET_FRANCHISE,
  GET_DELETED_FRANCHISE,
  SELECT_FRANCHISE_ITEMS,
  GET_FRANCHISE_ITEM,
  GET_PAG_NOT_REMOVED_FRANCHISES,
  GET_PAG_REMOVED_FRANCHISES,
  DELETE_FRANCHISE_ITEMS,
  RECOVER_FRANCHISE_ITEMS,
  ARCHIVE_FRANCHISE_ITEMS,
  BULK_RECOVER_FRANCHISE_ITEMS,
  NEW_FRANCHISE_FULFILLED,
  NEW_FRANCHISE_REJECTED,
  SAVE_FRANCHISE_FULFILLED,
  SAVE_FRANCHISE_REJECTED,
  FETCH_FRANCHISE_FULFILLED,
  FETCH_FRANCHISE_PENDING,
  UPDATE_FRANCHISE_FULFILLED,
  LANG_TAB,
  DESTROY_FRANCHISE,
  BULK_DESTROY_FRANCHISE_ITEMS,
  FRANCHISE_TAB_PANEL_CHANGE,
  FRANCHISE_FILTER_IS_OPEN,
  FRANCHISE_DATA_FILTER,
  FRANCHISE_DATA_FILTER_SEARCH,
  FRANCHISE_DATA_MIN_MAX_F,
  FRANCHISE_SELECT_FILTER_TYPES,
  FRANCHISE_DATA_FILTER_SEARCH_RESET,
  FRANCHISE_DATA_FILTER_ALL_SEARCH_RESET,
  FETCH_FRANCHISE_SEE_ALL_P,
  FETCH_FRANCHISE_SEE_ALL_F,
  FETCH_FRANCHISE_SEE_ALL_R,
  GET_FRANCHISE_ORDER_STATISTICS_P,
  GET_FRANCHISE_ORDER_STATISTICS_F,
  GET_FRANCHISE_ORDER_STATISTICS_R,
  GET_FRANCHISE_ORDER_ALL_STATISTICS_P,
  GET_FRANCHISE_ORDER_ALL_STATISTICS_F,
  GET_FRANCHISE_ORDER_ALL_STATISTICS_R
}