import  { SECTION_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'

 class StoreActions {
  constructor(_props) {
    this.api = config['STORE']
    this.rest  = 'd/s/'
    this.arcive = 'b/'
  }


    update = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_P
          })

          await axios.put(
            this.api + this.rest + `/${body.storeSectionId}`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_F,
            payload: body
          })
            
        } catch (e) {

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_R,
            payload: e
          })

        }
      }
    }
    
 

    create = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_P
          })

          await axios.post(
            this.api + this.rest,
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_F,
            payload: response
          })

        } catch (e) {
          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_R,
            payload: e
          })
        }
      }
    }


    updateHeader = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_P
          })

          await axios.put(
            this.api + 'c/h' + `/${body.storeId}`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_F,
            payload: body
          })
            
        } catch (e) {

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_R,
            payload: e
          })

        }
      }
    }
    
 

    createHeader = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_P
          })

          await axios.post(
            this.api + 'c/h',
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_F,
            payload: response
          })

        } catch (e) {
          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_R,
            payload: e
          })
        }
      }
    }

    updateFooter = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_P
          })

          await axios.put(
            this.api + 'c/f' + `/${body.storeId}`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_F,
            payload: body
          })
            
        } catch (e) {
          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_R,
            payload: e
          })

        }
      }
    }
    
 
    createFooter = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_P
          })

          await axios.post(
            this.api + 'c/f',
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_F,
            payload: response
          })

        } catch (e) {
          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_R,
            payload: e
          })
        }
      }
    }


    updateBanner = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_P
          })

          await axios.put(
            this.api + 'c/b' + `/${body.bannerId}`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_F,
            payload: body
          })
            
        } catch (e) {

          dispatch({
            type: SECTION_TYPES.SECTION_UPDATE_R,
            payload: e
          })

        }
      }
    }
    
 

    createBanner = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_P
          })

          await axios.post(
            this.api + 'c/b',
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_F,
            payload: response
          })

        } catch (e) {
          dispatch({
            type: SECTION_TYPES.SECTION_CREATE_R,
            payload: e
          })
        }
      }
    }
}


export default new StoreActions()