import React, { useEffect, useState, useRef } from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import {
    linkActions, businessActions
  } from "../../projectNulla/redux-modules/actions";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    ButtonToolbar,
    CardTitle,
    Progress,
    Col,
    Row,
} from 'reactstrap';
import { DatePicker } from '../../projectNulla/catalogs/Globals';
import ReactSelect from 'react-select';
const brandInfo = getStyle('--info')
  
function TrafficChart(_props) {
    const dispatch = useDispatch()
    const selectRef = useRef([]);
    const [selectedBusiness, setSelectedBusines] = useState([])
    const [closeOnSelectBusiness, setCloseOnSelectBusiness] = useState(false)
    const [page, setPage] = useState(1)
    const { allStatistic } = useSelector((state) => state.links);
    const { count: businessCount, businessSelect } = useSelector((state) => state.business);
    const maxCount = Math.max(...allStatistic.data.map(o => o.count));
    const visitsParcent = Math.round(allStatistic.visits / allStatistic.allVisits * 100 * Math.pow(10,0))/Math.pow(10,0)
    const uniqueParcent = Math.round(allStatistic.unique / allStatistic.allUnique * 100 * Math.pow(10,0))/Math.pow(10,0)
    const newUsersParcent = Math.round(allStatistic.users / allStatistic.unique * 100 * Math.pow(10,0))/Math.pow(10,0)
    const bounceRateParcent = Math.round(allStatistic.bounceRate / allStatistic.unique * 100 * Math.pow(10,0))/Math.pow(10,0)

    const mainChartOpts = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
                    labelColor: function(tooltipItem, chart) {
                    return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                }
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    display: false,
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxCount / 5),
                        max:  maxCount
                    },
                }
            ],
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            },
        },
    };

    const { token } = useSelector((state) => state.account);
    const [date, setDate] = useState({ startDate: '', endDate: '' })
    const [radioSelected, setRadioSelected] = useState('month');

    const selectBusiness = (selectedOption) => {
        let selectedOptions = []
        if (selectedOption.length) {
            selectedOption.forEach(elem => {
                if (elem.value === 'all') {
                    let businessPage = page + 1
                    setPage(businessPage)
                    setCloseOnSelectBusiness(false)
                    dispatch(businessActions.seeAll(token, businessPage, 10))
                }  else {
                    selectedOptions.push(elem)
                }
            })
        }
        setSelectedBusines(selectedOptions)
    }

    useEffect(() => {
        dispatch(linkActions.getAllStatistic(token, { 
          startDate: date.startDate,
          endDate: date.endDate,
          date: radioSelected,
          businessIds: selectedBusiness.map(({value}) => value) 
        }));
    }, [date, radioSelected, selectedBusiness])

    function onRadioBtnClick(value) {
        setRadioSelected(value)
    }
    
    const getClientLanguage = () => {
        let Locale = navigator.language || navigator.userLanguage;
        if (Locale.includes('-')) Locale = Locale.split('-')[0];
        return Locale.toLowerCase();
    };

    const genericFormattedDate = (DateToFormat) => {
        const UserLanguage = getClientLanguage();
        const Config = { year: 'numeric', month: 'long', hour: '2-digit', minute:'2-digit', day: '2-digit' };
        return new Date(DateToFormat).toLocaleString(UserLanguage, Config);
    };

    const mainChart = {
        labels: allStatistic.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'Google',
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: brandInfo,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: allStatistic.data.map(d => d.count),
                pointBorderColor: brandInfo,
                pointBackgroundColor: "white",
                pointBorderWidth: 1,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "yellow",
                pointHoverBorderColor: "brown",
                pointHoverBorderWidth: 1,
                pointRadius: 2,
                pointHitRadius: 5,
            },
        ],
    };

    function handleChangeDate (event, key) {
        setDate({
            ...date,
            [key]: event.target.value
        })
    }

    const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

    const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`

    const colors = allStatistic.city.map(() => randomRGB())

    function openMenu(index) {
        if (index == 0) {
            if (!businessSelect.length) {
                dispatch(businessActions.seeAll(token, 1, 10));  
            }
        }
        selectRef.current[index].focus()
    }

    const handleInputChangeBusiness = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        dispatch(businessActions.seeAll(token, 1, 10, { name: inputValue }))
    };
    
    return (
        <Card>
             <CardBody>
                <Row>
                    <Col sm="2">
                        <CardTitle className="mb-0">Traffic</CardTitle>
                    </Col>
                    <Col sm="5">
                        <DatePicker
                            handleChange={handleChangeDate}
                            value={{
                                startDate: date.startDate,
                                endDate: date.endDate 
                            }}
                        />
                    </Col>
                    <Col sm="2">
                        <div style={{width: "200px"}}>
                            <ReactSelect
                                ref={el => selectRef.current[0] = el}
                                onFocus={() => openMenu(0)}
                                closeOnSelect = {closeOnSelectBusiness}
                                name="form-field-name"
                                value={selectedBusiness}
                                onInputChange={handleInputChangeBusiness}
                                multi = {true}
                                onChange={selectBusiness}
                                options={
                                    businessCount > businessSelect.length 
                                    ? [...businessSelect, { value: 'all', label: 'See More'}]
                                    : [...businessSelect]
                                }
                                placeholder={<div>Business</div>}
                            />
                        </div>
                    </Col>
                    <Col sm="3" className="d-none d-sm-inline-block">
                        <ButtonToolbar className="float-right" aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-3" aria-label="First group">
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
                    <Line data={mainChart} options={mainChartOpts} height={300} />
                </div>
            </CardBody>
            <CardFooter>
                <Row className="text-center">
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Visits</div>
                    <strong>{allStatistic.visits} Users ({visitsParcent}%)</strong>
                    <Progress className="progress-xs mt-2" color="success" value={visitsParcent} />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Unique</div>
                    <strong>{allStatistic.unique} Users ({uniqueParcent}%)</strong>
                    <Progress className="progress-xs mt-2" color="info" value={uniqueParcent} />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">New Users</div>
                    <strong>{allStatistic.users} Users ({newUsersParcent}%)</strong>
                    <Progress className="progress-xs mt-2" color="danger" value={newUsersParcent} />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Bounce Rate</div>
                    <strong>{allStatistic.bounceRate} Average Rate ({bounceRateParcent}%)</strong>
                    <Progress className="progress-xs mt-2" color="primary" value={bounceRateParcent} />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Pageviews</div>
                    <strong>{allStatistic.pageViews} Views</strong>
                  </Col>
                    {
                        allStatistic.datedifference.datedifference &&
                        <Col sm={12} md className="mb-sm-2 mb-0">
                            <div className="text-muted">Intermediate length session</div>
                            <strong>{`${allStatistic.datedifference.datedifference.hours}:${allStatistic.datedifference.datedifference.minutes}:${allStatistic.datedifference.datedifference.seconds}`}</strong>
                        </Col>
                    }
                </Row>
            </CardFooter>
            <Row className="text-center">
                  <Col sm={12} md className="mb-sm-2 mb-0">
                        <Doughnut data={{
                            labels: allStatistic.city.map(({city}) => city),
                            datasets: [
                                    {
                                        data: allStatistic.city.map(({count}) => count),
                                        backgroundColor: colors,
                                        hoverBackgroundColor: colors,
                                        borderWidth: 1
                                    }
                                ]
                            }} 
                            width={300}
	                        height={300}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                            }}
                            onClick={(e) => console.log(e)} 
                        />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <Doughnut data={{
                        labels: allStatistic.country.map(({country}) => country),
                        datasets: [
                                {
                                    data: allStatistic.country.map(({count}) => count),
                                    backgroundColor: colors,
                                    hoverBackgroundColor: colors,
                                    borderWidth: 1
                                }
                            ]
                        }} 
                        width={300}
                        height={300}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false
                        }}
                        onClick={(e) => console.log(e)} 
                    />
                  </Col>
            </Row>
        </Card>
    )
}

export default TrafficChart