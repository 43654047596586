const NOTIFICATION_TYPE_PAG_F = 'NOTIFICATION_TYPE_F';
const NOTIFICATION_TYPE_PAG_R = 'NOTIFICATION_TYPE_R';
const NOTIFICATION_TYPE_SIZE_CHANGE = 'NOTIFICATION_TYPE_SIZE_CHANGE'
const NOTIFICATION_TYPE_PAGE_CHANGE = 'NOTIFICATION_TYPE_PAGE_CHANGE'
const NOTIFICATION_TYPE_FILETR_TABE_CHANGE = 'NOTIFICATION_TYPE_FILETR_TABE_CHANGE'
const NOTIFICATION_TYPE_UPDATE_F = 'NOTIFICATION_TYPE_UPDATE_F'
const NOTIFICATION_TYPE_UPDATE_R = 'NOTIFICATION_TYPE_UPDATE_R'
const NOTIFICATION_TYPE_CHECKBOX_CHANGE = 'NOTIFICATION_TYPE_CHECKBOX_CHANGE'
const NOTIFICATION_TYPE_BULK_ARCIVE_F = 'NOTIFICATION_TYPE_BULK_ARCIVE_F'
const NOTIFICATION_TYPE_BULK_ARCIVE_R = 'NOTIFICATION_TYPE_BULK_ARCIVE_R'
const NOTIFICATION_TYPE_BULK_DESTROY_F = 'NOTIFICATION_TYPE_BULK_DESTROY_F'
const NOTIFICATION_TYPE_BULK_DESTROY_R = 'NOTIFICATION_TYPE_BULK_DESTROY_R'
const NOTIFICATION_TYPE_TAB_PANEL_CHANGE = 'NOTIFICATION_TYPE_TAB_PANEL_CHANGE'
const NOTIFICATION_TYPE_CREATE_F = 'NOTIFICATION_TYPE_CREATE_F'
const NOTIFICATION_TYPE_CREATE_R = 'NOTIFICATION_TYPE_CREATE_R'
const NOTIFICATION_TYPE_GET_ONE_F = 'NOTIFICATION_TYPE_GET_ONE_F'
const NOTIFICATION_TYPE_GET_ONE_R = 'NOTIFICATION_TYPE_GET_ONE_R'
const NOTIFICATION_TYPE_REQUIRED_VALIDATION_F = 'NOTIFICATION_TYPE_REQUIRED_VALIDATION_F'
const NOTIFICATION_TYPE_REQUIRED_VALIDATION_R = 'NOTIFICATION_TYPE_REQUIRED_VALIDATION_R'
const NEW_NOTIFICATION_TYPE = 'NEW_NOTIFICATION_TYPE'
const NOTIFICATION_TYPE_DATA_FILTER = 'NOTIFICATION_TYPE_DATA_FILTER'
const NOTIFICATION_TYPE_DELETE_F = 'NOTIFICATION_TYPE_DELETE_F'
const NOTIFICATION_TYPE_DELETE_R = 'NOTIFICATION_TYPE_DELETE_R'
const NOTIFICATION_TYPE_DATA_FILTER_SEARCH = 'NOTIFICATION_TYPE_DATA_FILTER_SEARCH'
const NOTIFICATION_TYPE_FILTER_IS_OPEN = 'NOTIFICATION_TYPE_FILTER_IS_OPEN'
const NOTIFICATION_TYPE_DATA_FILTER_SEARCH_RESET = 'NOTIFICATION_TYPE_DATA_FILTER_SEARCH_RESET'
const NOTIFICATION_TYPE_DATA_FILTER_ALL_SEARCH_RESET = 'NOTIFICATION_TYPE_DATA_FILTER_ALL_SEARCH_RESET'

export {
    NOTIFICATION_TYPE_PAG_F,
    NOTIFICATION_TYPE_PAG_R,
    NOTIFICATION_TYPE_SIZE_CHANGE,
    NOTIFICATION_TYPE_PAGE_CHANGE,
    NOTIFICATION_TYPE_FILETR_TABE_CHANGE,
    NOTIFICATION_TYPE_UPDATE_F,
    NOTIFICATION_TYPE_UPDATE_R,
    NOTIFICATION_TYPE_CHECKBOX_CHANGE,
    NOTIFICATION_TYPE_BULK_ARCIVE_F,
    NOTIFICATION_TYPE_BULK_ARCIVE_R,
    NOTIFICATION_TYPE_BULK_DESTROY_F,
    NOTIFICATION_TYPE_BULK_DESTROY_R,
    NOTIFICATION_TYPE_TAB_PANEL_CHANGE,
    NOTIFICATION_TYPE_CREATE_F,
    NOTIFICATION_TYPE_CREATE_R,
    NOTIFICATION_TYPE_GET_ONE_F,
    NOTIFICATION_TYPE_GET_ONE_R,
    NOTIFICATION_TYPE_REQUIRED_VALIDATION_F,
    NOTIFICATION_TYPE_REQUIRED_VALIDATION_R,
    NEW_NOTIFICATION_TYPE,
    NOTIFICATION_TYPE_DATA_FILTER,
    NOTIFICATION_TYPE_DELETE_F,
    NOTIFICATION_TYPE_DELETE_R,
    NOTIFICATION_TYPE_DATA_FILTER_SEARCH,
    NOTIFICATION_TYPE_FILTER_IS_OPEN,
    NOTIFICATION_TYPE_DATA_FILTER_SEARCH_RESET,
    NOTIFICATION_TYPE_DATA_FILTER_ALL_SEARCH_RESET,
}