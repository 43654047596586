import {LINK_TYPES} from '../../types/index'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const defaultState = {
  pagRemovedLink: [],
  pagNotRemovedLink: [],
  pagRemovedLinkCount: 0,
  pagNotRemovedLinkCount: 0,
  selectedItem: {},
  linkSelect: [],
  tabPanel: localStorage.getItem('linkActiveTab') || '1',
  initialFilter: {
    name: '',
    link: '',
    isActiveve: null,
    dates:  ''
  },
  dataFilter: {
    linkId: null,
    nameF: '',
    linkF: '',
    phoneF: '',
    isActiveF: null,
    dateAdded:  '',
  },
  minMaxValues: {},
  isOpen: isMobile ? false : localStorage.getItem('linkFilter') == 'true' ? true : false,
  licenseTypesSelect: [],
  businesTypesSelect: [],
  loading: false,
  statistic: {
    data: [],
    count: 0
  },
  allStatistic: {
    visits: 0,
    unique: 0,
    data: [],
    city: [],
    country: [],
    datedifference: {
      datedifference: {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  }
};

const links = ( state = defaultState, action = {}) => {
  switch (action.type) {

      case LINK_TYPES.GET_PAG_REMOVED_LINKS: {

        return {
          ...state,
          pagRemovedLink: action.payload.data,
          pagRemovedLinkCount: action.payload.count
        }

      }

      case LINK_TYPES.GET_PAG_NOT_REMOVED_LINKS: {

        return {
          ...state,
          pagNotRemovedLink: action.payload.data,
          pagNotRemovedLinkCount: action.payload.count
        }

      }

      case LINK_TYPES.DELETE_LINK_ITEMS: {

        const id = action.payload[0];
        return {
          ...state,
          pagNotRemovedLink: state.pagNotRemovedLink.filter(item => item.linkId !== id)
        }

      }

      case LINK_TYPES.SELECT_LINK_ITEMS: {

        return {
          ...state,
          selectedItem: action.payload
        }

    }

      case LINK_TYPES.RECOVER_LINK_ITEMS: {
        return {
          ...state
        }
      }

      case LINK_TYPES.ARCHIVE_LINK_ITEMS: {
        return { 
          ...state
        }
      }

      case LINK_TYPES.BULK_RECOVER_LINK_ITEMS: {

        return { 
          ...state,
          bulkDeletedItems: action.payload,
          pagRemovedLinkCount: state.pagRemovedLinkCount - action.payload.length
        }
      }

      case LINK_TYPES.BULK_DESTROY_LINK_ITEMS: {
        const id = action.payload[0];
        return {
          ...state,
          pagRemovedLink: state.pagRemovedLink.filter(item => item.linkId !== id),
        }
      }


    case LINK_TYPES.LINK_TAB_PANEL_CHANGE: {
      return {
        ...state,
        tabPanel: action.payload,
        loading: false
      }
    }

    case LINK_TYPES.LINK_FILTER_IS_OPEN: {
      localStorage.setItem('linkFilter', !state.isOpen)
      return {
        ...state,
        isOpen: !state.isOpen
      }
    }

    case LINK_TYPES.LINK_DATA_FILTER: {
      return { 
        ...state,
        dataFilter: { [action.payload['key']]: action.payload['value'] },
        error: []
      }
    }

    case LINK_TYPES.LINK_DATA_FILTER_SEARCH: {
      return { 
        ...state,
        initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
        error: []
      }
    }

    case LINK_TYPES.LINK_DATA_MIN_MAX_F: {
      return {
        ...state,
        minMaxValues: action.payload
      }
    }


    case LINK_TYPES.LINK_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: Object.assign({}, state.initialFilter, { name: "" })
      }
    }

    case LINK_TYPES.LINK_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: { 
          name: '',
          isActive: null,
          dates:  '',
          dateTo: '',
          dateFrom: ''
        }
      }
    }

    case LINK_TYPES.GET_STATISTICS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case LINK_TYPES.GET_STATISTICS_F: {
      return {
        ...state,
        loading: false,
        statistic: action.payload
      }
    }

    case LINK_TYPES.GET_STATISTICS_R: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case LINK_TYPES.GET_ALL_STATISTICS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case LINK_TYPES.GET_ALL_STATISTICS_F: {
      return {
        ...state,
        loading: false,
        allStatistic: action.payload
      }
    }

    case LINK_TYPES.GET_ALL_STATISTICS_R: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
  
    default:
            return state;
  }
}

export {
  links
}
