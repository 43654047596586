import  { CURRENCY_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

 class CurrencyActions {
  constructor(_props) {
    this.api = config['CURRENCY']
    this.rest  = 'c/cu'
    this.p = '/p'
    this.arcive = '/b/'
  }

    paginationCurrency = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const searchString = qs.stringify(query)
              const response = await axios.get(
                this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                })
                dispatch({
                    type: CURRENCY_TYPES.CURRENCY_PAG_F,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: CURRENCY_TYPES.CURRENCY_PAG_R,
                    payload: e
                })
            }
        }
    }

    all = (jwt) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })
            dispatch({
                type: CURRENCY_TYPES.CURRENCY_ALL_F,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: CURRENCY_TYPES.CURRENCY_ALL_R,
                payload: e
            })
        }
    }
}

    update = (jwt, body, Type, history) => {
      return async dispatch => {
        try {
            await axios.put(
            this.api + this.rest + `/${body.currencyId}`,
            body,
            {
                headers: 
                  {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
            })

            dispatch({
                type: CURRENCY_TYPES[Type + '_UPDATE_F'],
                payload: body
            })


            history.goBack()
            
        } catch (e) {
            dispatch({
                type: CURRENCY_TYPES[Type + '_UPDATE_R'],
                payload: e
            })
          }
        }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )
            dispatch({
                type: CURRENCY_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: CURRENCY_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: CURRENCY_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: CURRENCY_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: CURRENCY_TYPES.CURRENCY_BULK_F,
                  payload: ids
              })

              dispatch(this.paginationCurrency(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: CURRENCY_TYPES.CURRENCY_BULK_R,
                  payload: e
              })

            }
          }
      }

    /**
     * @name bulkDestroy
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + this.rest + this.arcive + 'a',
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: CURRENCY_TYPES.CURRENCY_BULK_DESTROY_F,
                payload: ids
            })

            dispatch(this.paginationCurrency(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: CURRENCY_TYPES.CURRENCY_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

      create = (jwt, body, Type, history) => {
        return async dispatch => {
          await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {
             
                dispatch({
                    type: CURRENCY_TYPES[Type + '_CREATE_F'],
                    payload: response
                })

                history.goBack()

              })
              .catch(e => {
                dispatch({
                      type: CURRENCY_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })
              })
        }
      }

    
}




export default new CurrencyActions()