import config from '../../../config/app'
import {Service} from "./Service"

const all = "c/m/r";
const allR = "c/m/d";
const rest = "c/m";
const pagNotRem = "c/m/r";
const pagRem= "c/m/d";
const bulkDel = 'c/m/b/a';
const bulkRec = 'c/m/b/r'
const bulkDes = 'c/m/b/d'

const manufacturerService = new Service(config["MANUFACTURER"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);

export {
    manufacturerService
};