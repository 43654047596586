import {IMAGE_TYPES} from "../../types/index"

const defaultState = {
  pagRemovedImage: [],
  pagNotRemovedImage: [],
  pagRemovedImageCount: 0,
  pagNotRemovedImageCount: 0,
  selectedImages: {},
  canseled: false
};

const allImages = (state = [], action = {}) => {
  if (action.type === IMAGE_TYPES.GET_ALL_IMAGES) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedImages = (state = [], action = {}) => {
  if (action.type === IMAGE_TYPES.GET_ALL_REMOVED_IMAGES) {
    return action.payload;
  } else {
    return state
  }
};

const images = (state = {}, action = {}) => {
  if (action.type === IMAGE_TYPES.GET_IMAGE_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedImages = (state = {}, action = {}) => {
  if (action.type === IMAGE_TYPES.SELECT_IMAGE_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const img = ( state = defaultState, action = {}) => {
  switch (action.type) {
    case IMAGE_TYPES.SELECT_IMAGE_SIZE_ITEMS: {
        return {
          ...state
        }
    }

    case IMAGE_TYPES.SELECT_IMAGE_ITEMS: {
      return {
        ...state,
        selectedImages: action.payload
      }
    }

    
    case IMAGE_TYPES.GET_PAG_REMOVED_IMAGES: {
        return {
          ...state,
          pagRemovedImage: action.payload.data,
          pagRemovedImageCount: action.payload.count,
          canseled: false,

        }
    }

    case IMAGE_TYPES.GET_PAG_NOT_REMOVED_IMAGES: {
        return {
          ...state,
          pagNotRemovedImage: action.payload.data,
          pagNotRemovedImageCount: action.payload.count,
          canseled: false
        }
    }
    case IMAGE_TYPES.ARCIVE_IMAGE_ITEMS: {
      const id = action.payload.image_id;
      return {
          ...state,
          pagNotRemovedImage: state.pagNotRemovedImage.filter(item => item.image_id !== id)
      }
    }

    case IMAGE_TYPES.DELETE_IMAGE_ITEMS_ALL: {
      const id = action.payload.image_id;
      return {
          ...state,
          pagNotRemovedImage: state.pagNotRemovedImage.filter(item => item.image_id !== id)
      }
    }

    case IMAGE_TYPES.DELETE_IMAGE_ITEMS_ARC: {
      const id = action.payload.image_id;
      return {
          ...state,
          pagRemovedImage: state.pagRemovedImage.filter(item => item.image_id !== id)
      }
    }

    case IMAGE_TYPES.DELETE_IMAGE_ITEMS_ARC: {
      const id = action.payload.image_id;
      return {
          ...state,
          pagNotRemovedImage: state.pagNotRemovedImage.filter(item => item.image_id !== id)
      }
    }

    case IMAGE_TYPES.RECOVER_IMAGE_ITEMS: {
      const id = action.payload.image_id;
      return {
          ...state,
          pagRemovedImage: state.pagRemovedImage.filter(item => item.image_id !== id),
          pagNotRemovedImage: state.pagNotRemovedOption,
          pagNotRemovedImageCount: state.pagNotRemovedImageCount + 1
      }
    }

    case IMAGE_TYPES.UPDATE_IMAGE_ITEM: {
      return {
          ...state
      }
    }

    case IMAGE_TYPES.ICON_BULK_ARCIVE: {
      return {
          ...state
      }
    }

    case IMAGE_TYPES.ADD_MAIN_IMAGE_ALL: {
      return {
        ...state,
        pagNotRemovedImage: [action.payload],
        pagNotRemovedImageCount: 0,
        canseled: true
      }
    }
    case IMAGE_TYPES.ADD_MAIN_IMAGE_ARCHIVE: {
      return {
        ...state,
        pagRemovedImage: [action.payload],
        pagRemovedImageCount: 0,
        canseled: true
      }
    }
    case IMAGE_TYPES.ADD_IMAGES: {
      return {
        ...state,
        pagNotRemovedImage: [action.payload],
        pagNotRemovedImageCount: 0,
        canseled: true
      }
    }

    case IMAGE_TYPES.CANCEL_ADD_IMAGE: {
      return {
        ...state,
        canseled: false
      }
    }

    default:
            return state;
  }
}
export {allImages,allRemovedImages,images,selectedImages, img}