const UPLOAD_FILES_F = 'UPLOAD_FILES_F'
const UPLOAD_FILES_R = 'UPLOAD_FILES_R'
const UPLOAD_FILES_P = 'UPLOAD_FILES_P'
const REMOVE_CHANGED_FILE = 'REMOVE_CHANGED_FILE'
const CHANGE_FILE = 'CHANGE_FILE'
const CHAT_DELETE_F = 'CHAT_DELETE_F'
const CHAT_DELETE_R = 'CHAT_DELETE_R'
const BULK_DELETE_F = 'BULK_DELETE_F'
const BULK_DELETE_R = 'BULK_DELETE_R'
const CHAT_CHECKBOX_CHANGE = 'CHAT_CHECKBOX_CHANGE'
const FETCH_CHAT_NOTIFICATIONS_P = 'FETCH_CHAT_NOTIFICATIONS_P'
const FETCH_CHAT_NOTIFICATIONS_F = 'FETCH_CHAT_NOTIFICATIONS_F'
const FETCH_CHAT_NOTIFICATIONS_R = 'FETCH_CHAT_NOTIFICATIONS_R'
const BULK_REMOVE_CHAT_NOTIFICATIONS_P = 'BULK_REMOVE_CHAT_NOTIFICATIONS_P'
const BULK_REMOVE_CHAT_NOTIFICATIONS_F = 'BULK_REMOVE_CHAT_NOTIFICATIONS_F'
const BULK_REMOVE_CHAT_NOTIFICATIONS_R = 'BULK_REMOVE_CHAT_NOTIFICATIONS_R'
const DESTROY_ONE_USER_CHAT_NOTIFICATIONS_P = 'DESTROY_ONE_USER_CHAT_NOTIFICATIONS_P'
const DESTROY_ONE_USER_CHAT_NOTIFICATIONS_F = 'DESTROY_ONE_USER_CHAT_NOTIFICATIONS_F'
const DESTROY_ONE_USER_CHAT_NOTIFICATIONS_R = 'DESTROY_ONE_USER_CHAT_NOTIFICATIONS_R'
const FETCH_USERS_CHAT_P = 'FETCH_USERS_CHAT_P'
const FETCH_USERS_CHAT_F = 'FETCH_USERS_CHAT_F'
const FETCH_USERS_CHAT_R = 'FETCH_USERS_CHAT_R'
const UPDATE_CHAT_USERS = 'UPDATE_CHAT_USERS'
const FETCH_MESSAGES_CHAT_P = 'FETCH_MESSAGES_CHAT_P'
const FETCH_MESSAGES_CHAT_F = 'FETCH_MESSAGES_CHAT_F'
const FETCH_MESSAGES_CHAT_R = 'FETCH_MESSAGES_CHAT_R'
const ADD_MESSAGE_CHAT = 'ADD_MESSAGE_CHAT'
const UPDATE_CHAT_USER_NOTIFICS_COUNT = 'UPDATE_CHAT_USER_NOTIFICS_COUNT'
const REMOVE_CHAT_LIST = 'REMOVE_CHAT_LIST'
const DESTROY_CHAT_MESSAGE = 'DESTROY_CHAT_MESSAGE'
const UPDATE_CHAT_MESSAGE_CONDITION = 'UPDATE_CHAT_MESSAGE_CONDITION'
const DESTROY_CHAT_ROOM_P = 'DESTROY_CHAT_ROOM_P'
const DESTROY_CHAT_ROOM_F = 'DESTROY_CHAT_ROOM_F'
const DESTROY_CHAT_ROOM_R = 'DESTROY_CHAT_ROOM_R'
const FETCH_ROOM_COUNT_P = 'FETCH_ROOM_COUNT_P'
const FETCH_ROOM_COUNT_F = 'FETCH_ROOM_COUNT_F'
const FETCH_ROOM_COUNT_R = 'FETCH_ROOM_COUNT_R'
const FETCH_MEMBERS_P = 'FETCH_MEMBERS_P'
const FETCH_MEMBERS_F = 'FETCH_MEMBERS_F'
const FETCH_MEMBERS_R = 'FETCH_MEMBERS_R'
const ADD_CHAT_USER = 'ADD_CHAT_USER'
const CHAT_ROOM_DATA_FILTER_SEARCH = 'CHAT_ROOM_DATA_FILTER_SEARCH'

export {
    UPLOAD_FILES_F,
    UPLOAD_FILES_R,
    UPLOAD_FILES_P,
    REMOVE_CHANGED_FILE,
    CHANGE_FILE,
    CHAT_DELETE_F,
    CHAT_DELETE_R,
    BULK_DELETE_F,
    BULK_DELETE_R,
    CHAT_CHECKBOX_CHANGE,
    FETCH_CHAT_NOTIFICATIONS_P,
    FETCH_CHAT_NOTIFICATIONS_F,
    FETCH_CHAT_NOTIFICATIONS_R,
    BULK_REMOVE_CHAT_NOTIFICATIONS_P,
    BULK_REMOVE_CHAT_NOTIFICATIONS_F,
    BULK_REMOVE_CHAT_NOTIFICATIONS_R,
    DESTROY_ONE_USER_CHAT_NOTIFICATIONS_P,
    DESTROY_ONE_USER_CHAT_NOTIFICATIONS_F,
    DESTROY_ONE_USER_CHAT_NOTIFICATIONS_R,
    FETCH_USERS_CHAT_P,
    FETCH_USERS_CHAT_F,
    FETCH_USERS_CHAT_R,
    UPDATE_CHAT_USERS,
    FETCH_MESSAGES_CHAT_P,
    FETCH_MESSAGES_CHAT_F,
    FETCH_MESSAGES_CHAT_R,
    ADD_MESSAGE_CHAT,
    UPDATE_CHAT_USER_NOTIFICS_COUNT,
    REMOVE_CHAT_LIST,
    DESTROY_CHAT_MESSAGE,
    UPDATE_CHAT_MESSAGE_CONDITION,
    DESTROY_CHAT_ROOM_P,
    DESTROY_CHAT_ROOM_F,
    DESTROY_CHAT_ROOM_R,
    FETCH_ROOM_COUNT_P,
    FETCH_ROOM_COUNT_F,
    FETCH_ROOM_COUNT_R,
    FETCH_MEMBERS_P,
    FETCH_MEMBERS_F,
    FETCH_MEMBERS_R,
    ADD_CHAT_USER,
    CHAT_ROOM_DATA_FILTER_SEARCH
}