import {MANUFACTURER_TYPES} from '../../types/index'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const defaultState = {
  pagRemoved: [],
  pagNotRemoved: [],
  pagRemovedCount: 0,
  pagNotRemovedCount: 0,
  selectedItem: {},
  manufacturerSelect: [],
  tabPanel: localStorage.getItem('manufacturerActiveTab') || '1',
  initialFilter: {
    name: '',
    email: '',
    phone: '',
    businesType: '',
    licenseType: '',
    status: null,
    dates:  '',
    minPrice: '',
    maxPrice: ''
  },
  dataFilter: {
    manufacturerId: null,
    nameF: '',
    emailF: '',
    phoneF: '',
    businesTypeF: '',
    licenseTypeF: '',
    statusF: null,
    dateAdded:  '',
    sortOrder: null
  },
  minMaxValues: {},
  isOpen: isMobile ? false : localStorage.getItem('manufacturerFilter') == 'true' ? true : false,
  licenseTypesSelect: [],
  businesTypesSelect: []
};

const manufacturers = ( state = defaultState, action = {} ) => {
  switch (action.type) {

      case MANUFACTURER_TYPES.GET_PAG_REMOVED_MANUFACTURERS: {

          return {
            ...state,
            pagRemoved: action.payload.data,
            pagRemovedCount: action.payload.count
          }

      }

      case MANUFACTURER_TYPES.GET_PAG_NOT_REMOVED_MANUFACTURERS: {

          return {
            ...state,
            pagNotRemoved: action.payload.data,
            pagNotRemovedCount: action.payload.count
          }

      }

      case MANUFACTURER_TYPES.DELETE_MANUFACTURER_ITEMS: {

          const id = action.payload[0];

          return {
            ...state,
            pagNotRemoved: state.pagNotRemoved.filter(item => item.manufacturerId !== id)
          }

      }

      case MANUFACTURER_TYPES.SELECT_MANUFACTURER_ITEMS: {

        return {
          ...state,
          selectedItem: action.payload
        }

    }

      case MANUFACTURER_TYPES.RECOVER_MANUFACTURER_ITEMS: {
          const id = action.payload[0];
          return {
              ...state,
              pagRemoved: state.pagRemoved.filter(item => item.ManufacturerId !== id),
              pagNotRemovedCount: state.pagNotRemovedCount + 1
            }
      }

      case MANUFACTURER_TYPES.ARCHIVE_MANUFACTURER_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagNotRemovedCount: state.pagNotRemovedCount - action.payload.length
          }
      }

      case MANUFACTURER_TYPES.BULK_RECOVER_MANUFACTURER_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagRemovedCount: state.pagRemovedCount - action.payload.length
        }
      }

      case MANUFACTURER_TYPES.BULK_DESTROY_MANUFACTURER_ITEMS: {
        const id = action.payload[0];
        return {
            ...state,
            pagRemoved: state.pagRemoved.filter(item => item.manufacturerId !== id),
        }
      }

      case MANUFACTURER_TYPES.MANUFACTURER_TAB_PANEL_CHANGE: {
        return {
            ...state,
            tabPanel: action.payload,
            loading: false
        }
      }

      case MANUFACTURER_TYPES.FETCH_MANUFACTURER_SEE_ALL: {
        const newManufacturer = []
        action.payload.data.forEach(manufacturer => {
          if (manufacturer.descriptions && manufacturer.descriptions[0] && manufacturer.descriptions[0].name) {
            newManufacturer.push({ 
              value: manufacturer.manufacturerId, 
              label: manufacturer.descriptions[0].name 
            })
          }
        })

        let manufacturerSelect = [...state.manufacturerSelect, ...newManufacturer]
        manufacturerSelect = manufacturerSelect.filter((elem, index, self) =>
          index === self.findIndex((e) => (
            e.value === elem.value
          ))
        )
  
        return {
            ...state,
            manufacturerSelect: manufacturerSelect,
            pagNotRemovedCount: action.payload.count
        }
    }


    case MANUFACTURER_TYPES.MANUFACTURER_FILTER_IS_OPEN: {
      localStorage.setItem('manufacturerFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case MANUFACTURER_TYPES.MANUFACTURER_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case MANUFACTURER_TYPES.MANUFACTURER_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case MANUFACTURER_TYPES.MANUFACTURER_DATA_MIN_MAX_F: {
      return {
          ...state,
          minMaxValues: action.payload
      }
    }


    case  MANUFACTURER_TYPES.MANUFACTURER_SELECT_FILTER_TYPES: {
      function getUnique(array, key) {
        return array.reduce((acc, cur) => [
          ...acc.filter((obj) => obj[key] !== cur[key]), cur
        ], [])
      }

      return {
        ...state,
        licenseTypesSelect: getUnique(action.payload, 'licenseType'),
        businesTypesSelect: getUnique(action.payload, 'type')
      }
    }

    case MANUFACTURER_TYPES.MANUFACTURER_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  Object.assign({}, state.initialFilter, { name: "" })
      }
    }


    case MANUFACTURER_TYPES.MANUFACTURER_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: Object.assign({}, state.initialFilter, { 
          name: '',
          email: '',
          phone: '',
          businesType: '',
          licenseType: '',
          status: null,
          dates:  '',
          minPrice: '',
          maxPrice: '',
          dateTo: '',
          dateFrom: ''
        })
      }
    }
  
    default:
            return state;
  }
}
export {
  manufacturers
}
