import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../redux-modules/actions';
import ReCAPTCHA from 'react-google-recaptcha';
import '../ConfirmRegister.css'

function ConfirmRegister({ setRegisterPage, email}) {
  const darkmode = false;
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state);
  const RECAPTCHA_SITE_KEY = '6LcsuoQeAAAAADI5Ey7F0odVrPWOxOlY77cQ3yXN';
  const [resend, setResend] = useState(false) 

  const [control, setControl] = useState(false);
  const [key, setKey] = useState('');
  const [response, setResponse] = useState({
    success: true,
    message: '',
    active: false,
  });

  const handleChange = (event) => setKey(event.target.value);

  useEffect(() => {

    if (account.err && account.err.length) {

      setResponse({
        ...response,
        success: false,
        message: account.err[0].msg,
        active: true
      });

    } else if (account.verify) {

      setResponse((prev) => ({
        ...prev,
        success: true,
        message: 'You have completed all stages of registration, now it\'s time to log in to your account',
        active: true
      }));

      setTimeout(() => {
        setRegisterPage();
      }, 1500);
      
    }

  }, [account])

  const handleSubmit = async (event) => {
    try {
      
      event.preventDefault();

      if (!control) {
        throw new Error('Form data is not approved, please fill in the form correctly');
      }
      
      dispatch(accountActions.verify({
        email: email,
        code: key
      }))

    } catch (error0) {
      setResponse((prev) => ({
        ...prev,
        success: false,
        message: error0.message,
        active: true
      }));
    }
  }

  useEffect(() => {
    const validation = /^[0-9]*$/g.test(key);
    if (validation && key.length === 4) {
      setControl(true);
    } else {
      setControl(false);
    }
  }, [key]);

  const handleCaptchaChange = (value) => {
    dispatch(accountActions.resendCode({email, captcha: value}))
    setResend(false)
  };

  return (
    <form
      method="post"
      className={`${'form'} ${darkmode ? 'dark' : null}`}
      onSubmit={handleSubmit}
    >
      <label htmlFor="key" className={'cards'}>
        <box-icon
          name="key"
          color={darkmode ? '#ffffffdd' : '#000000dd'}
        >
        </box-icon>
        <span className={'input-field'}>
          <p className={'label'}>4 digit code</p>
          <input
            type="key"
            name="key"
            id="key"
            placeholder="Enter your registration code"
            value={key}
            onChange={handleChange}
            required
          />
        </span>
        <box-icon
          name={control ? 'check-circle' : 'x-circle'}
          color={`${control ? '#00A19D' : '#B91646'}`}
        >
        </box-icon>
      </label>
      <div className={`${'response'} ${response.active ? 'active' : null}`}>
        <box-icon
          name={`${response.success ? 'check-circle' : 'x-circle'}`}
          color={`${response.success ? '#00A19D' : '#B91646'}`}
        >
        </box-icon>
        <p>{response.message}</p>
      </div>
      {
        !resend ?
        <p 
          className="label"
          onClick={() => {
            setResponse({
              ...response,
              message: '', 
              active: false
            })
            dispatch(accountActions.clearError())
            setResend(true)
          }} 
          style={{cursor: 'pointer', textDecoration: 'underline'}}>
            Resend
        </p>
        :
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleCaptchaChange} />
      }
      <span className={'submit-button'}>
        <button type="submit" className={'btn'}>
          Create Account
          <box-icon
            type="solid"
            name="right-top-arrow-circle"
            color={darkmode ? '#ffffffdd' : '#000000dd'}
          >
          </box-icon>
        </button>
      </span>
    </form>
  );
}

export default ConfirmRegister;
