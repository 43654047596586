import { BULK_CALL_TYPES } from '../../types'
import Fields from '../../../callService/call/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
    data: [],
    call: new Fields(),
    error: {},
    count: 0,
    pageNumber: {
        removed: localStorage.getItem('removedBulkCallPage') || 1,
        notRemoved: localStorage.getItem('notRemovedBulkCallPage') || 1
    },
    showEntries: {
        removed: localStorage.getItem('removedBulkCallPageSize') || 10,
        notRemoved: localStorage.getItem('notRemovedBulkCallPageSize') || 10
    },
    langTab : {
        languageId: 1,
        langTab: 0
    },
    filterTab: {
        filterTab: true,
        path: 'r/',
        removed: 'notRemoved'
    },
    initialFilter: {
        message: '',
        from: '',
        to: '',
        statusF: null,
        datesF:  '',
    },
    dataFilter: {
        search: '',
        message: false,
    },
    balance: null,
    minMaxValues: {},
    isOpen: isMobile ? false : localStorage.getItem('callFilter') == 'true' ? true : false,
    tabPanel: '1',
    checked: false,
    ids: [],
    phonesSelect: [],
    phonesCount: 0,
    numberList: [],
    statistics: {
        data: [],
        count: 0,
        unique: 0,
        callCount: 0,
        uniqueCallCount: 0,
        deliveredPriceAvg: 0,
        deliveredPriceTotal: 0,
        priceAvg: 0,
        priceTotal: 0,
        stopCount: 0
    },
    countriesSelect: [],
    countriesCount: [],
    loading: false,
    sendCallLoading: false
};



const bulkCall = ( state = initialState, action = {}) => {

  switch (action.type) {

    case BULK_CALL_TYPES.BULK_CALL_GET_ONE_P: {
        return {
            ...state,
            loading: true
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_GET_ONE_F: {
        const call =  new Fields()
        call.store = action.payload.store
        if (action.payload.inbound) {
            call.to = [{phone: action.payload.from}]
            call.from = [action.payload.to]
            call.provider = action.payload.carrier
            call.inboundMessage = action.payload.message
            if (action.payload.store && action.payload.store.business) {
                call.businessId = action.payload.store.business.businessId
                call.business = action.payload.store.business
            }
        } else {
            call.message = action.payload.message
            call.to = [{phone: action.payload.to}]
            call.from = [action.payload.from]
        }
        
        if (action.payload.callGroup) {
            call.provider = action.payload.callGroup.senderName
            if (action.payload.callGroup.business) {
                call.businessId = action.payload.callGroup.business.businessId
                call.business = action.payload.callGroup.business
            }
        }

        return {
            ...state,
            error: {},
            call: call,
            loading: false
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_GET_ONE_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.response
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_SEND_P: {
        return {
            ...state,
            sendCallLoading: true
        }
    }
    

    case BULK_CALL_TYPES.BULK_CALL_SEND_F: {
        return {
            ...state,
            error: {},
            sendCallLoading: false
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_SEND_R: {
        return {
            ...state,
            sendCallLoading: false,
            error: action.payload.response
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_PAG_F: {
        return {
            ...state,
            data: action.payload.data.map((elem) => state.ids.includes(elem.callId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
            checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.callId)),
            count: action.payload.count
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_NEW: {
        return {
            ...state,
            call: new Fields()
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_SIZE_CHANGE: {

        const showEntries = Object.assign(state.showEntries, action.payload)
      
        return {
            ...state,
            showEntries: showEntries
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_PAGE_CHANGE: {
      
        const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_BALANCE_F: {
      
        return {
            ...state,
            balance: action.payload,
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_BALANCE_R: {
      
        return {
            ...state,
            error: action.payload,
        }
    }

    case BULK_CALL_TYPES.GET_TELNYX_BALANCE_F: {
      
        return {
            ...state,
            balance: action.payload,
        }
    }
  
    case BULK_CALL_TYPES.GET_TELNYX_BALANCE_R: {
        
        return {
            ...state,
            error: action.payload,
            balance: null
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_FILETR_TABE_CHANGE: {
      
        return {
            ...state,
            filterTab: action.payload,
            checked: false
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_TAB_PANEL_CHANGE: {
      
        return {
            ...state,
            tabPanel: action.payload
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_CHECKBOX_CHANGE: {
        let ids = [...state.ids]
        const { checked, multiple, key } = action.payload
        const data = state.data.map(elem => {

            if (multiple) {

                elem['isChecked'] = checked
                return elem

            } else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.callId) })

        return {
            ...state,
            data: data,
            checked: !multiple ? data.every(element => element.isChecked): checked,
            ids: ids
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_BULK_ARCIVE_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case BULK_CALL_TYPES.BULK_CALL_BULK_DESTROY_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case BULK_CALL_TYPES.BULK_CALL_LANGUAGE_PANEL_CHANGE: {
        return { 
            ...state,
            langTab: action.payload
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_CREATE_R: {
        return { 
            ...state,
            error: action.payload.response
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_DATA_FILTER: {
        return { 
            ...state,
            dataFilter: { [action.payload['key']]: action.payload['value'] },
            error: []
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_DELETE_F: {
        const id = action.payload;
        return {
            ...state,
            data: state.data.filter(item => item.customer !== id)
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_FILTER_IS_OPEN: {
        localStorage.setItem('callFilter', !state.isOpen)
        return {
            ...state,
            isOpen: !state.isOpen
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
            error: []
        }
    }
    
    case BULK_CALL_TYPES.BULK_CALL_DATA_FILTER_ALL_SEARCH_RESET: {
        const { key } = action.payload
        return {
            ...state,
            [key]: { 
                from: '',
                to: '',
                message: '',
                status: null,
                dates:  '',
            }
        }
    }

    case BULK_CALL_TYPES.FETCH_BULK_CALL_PHONES_P: {
        return {
            ...state,
            // loading: true
        }
    }

    case BULK_CALL_TYPES.FETCH_BULK_CALL_PHONES_F: {

      const newPhones = []

        action.payload.data.forEach(item => {
            newPhones.push({ 
                label: item.phone,
                value: item.phone
            })
        })

        let phonesSelect = action.search ? newPhones : [...state.phonesSelect, ...newPhones]
        phonesSelect = phonesSelect.filter((elem, index, self) =>
            index === self.findIndex((e) => (
                e.value === elem.value
            ))
        )

        return {
            ...state,
            // loading: false,
            phonesSelect: phonesSelect,
            phonesCount: action.payload.count
        }
    }

    case BULK_CALL_TYPES.FETCH_BULK_CALL_PHONES_R: {
        return {
            ...state,
            // loading: false,
            error: action.payload.response
        }
    }


    case BULK_CALL_TYPES.TELNYX_BUSINESS_NUMBER_LIST_P: {
        return {
          ...state,
          loading: true
        }
    }
  
    case BULK_CALL_TYPES.TELNYX_BUSINESS_NUMBER_LIST_F: {
        return {
          ...state,
          error: {},
          numberList: action.payload,
          loading: false
        }
    }
  
    case BULK_CALL_TYPES.TELNYX_BUSINESS_NUMBER_LIST_R: {
        return {
          ...state,
          loading: false,
          error: action.payload.response
        }
    }

    case BULK_CALL_TYPES.TELNYX_BUSINESS_NUMBER_BUY_P: {
        return {
            ...state,
            loading: true
        }
    }

    case BULK_CALL_TYPES.TELNYX_BUSINESS_NUMBER_BUY_F: {
        return {
            ...state,
            error: {},
            phonesSelect: [
                { 
                    value: action.payload.phone, 
                    label: action.payload.phone 
                }, 
                ...state.phonesSelect
            ],
            loading: false
        }
    }

    case BULK_CALL_TYPES.TELNYX_BUSINESS_NUMBER_BUY_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.response
        }
    }

    case BULK_CALL_TYPES.UPDATE_BULK_CALL: {
        const call = action.payload
        return {
            ...state,
            data: state.data.map(item => item.callId == call.callId ? call : item )
        }
    }

    case BULK_CALL_TYPES.BULK_CALL_STATISTICS_P: {
        return {
            ...state,
            loading: true
        }
    }
  
    case BULK_CALL_TYPES.BULK_CALL_STATISTICS_F: {
        return {
            ...state,
            statistics: action.payload
        }
    }
  
    case BULK_CALL_TYPES.BULK_CALL_STATISTICS_R: {
        return {
            ...state,
            error: action.payload.data
        }
    }

    case BULK_CALL_TYPES.FETCH_COUNTRIES_SEE_ALL_P: {
        return {
            ...state,
            loading: true
        }
    }
  
    case BULK_CALL_TYPES.FETCH_COUNTRIES_SEE_ALL_F: {
        const newCountries = []
        action.payload.data.forEach(country => {
            newCountries.push({ 
              value: country.country, 
              label: country.country,
            })
        })
  
        let countriesSelect = action.search ? newCountries : [...state.countriesSelect, ...newCountries]
        countriesSelect = countriesSelect.filter((elem, index, self) =>
          index === self.findIndex((e) => (
            e.value === elem.value
          ))
        )
  
        return {
            ...state,
            loading: false,
            countriesSelect: countriesSelect,
            countriesCount: action.payload.count
        }
    }
  
    case BULK_CALL_TYPES.FETCH_COUNTRIES_SEE_ALL_R: {
        return {
            ...state,
            loading: false
        }
    }

    default:
        return state;
    }
}


export {
  bulkCall
}

