const VIDEO_PAG_F = 'VIDEO_PAG_L_F';
const VIDEO_PAG_R = 'VIDEO_PAG_L_R';
const VIDEO_SIZE_CHANGE = 'VIDEO_SIZE_CHANGE'
const VIDEO_PAGE_CHANGE = 'VIDEO_PAGE_CHANGE'
const VIDEO_FILETR_TABE_CHANGE = 'VIDEO_FILETR_TABE_CHANGE'
const VIDEO_UPDATE_F = 'VIDEO_UPDATE_F'
const VIDEO_UPDATE_R = 'VIDEO_UPDATE_R'
const VIDEO_CHECKBOX_CHANGE = 'VIDEO_CHECKBOX_CHANGE'
const VIDEO_GET_FOLDERS_F = 'VIDEO_GET_FOLDERS_F'
const VIDEO_ADD_FOLDER_F = 'VIDEO_ADD_FOLDER_F'
const VIDEO_ADD_FOLDER_R = 'VIDEO_ADD_FOLDER_R'
const VIDEO_REMOVE_FOLDER_F = 'VIDEO_REMOVE_FOLDER_F'
const VIDEO_REMOVE_FOLDER_R = 'VIDEO_REMOVE_FOLDER_R'
const VIDEO_UPDATE_FOLDER_F = 'VIDEO_UPDATE_FOLDER_F'
const VIDEO_CREATE_F = 'VIDEO_CREATE_F'
const VIDEO_CREATE_R = 'VIDEO_CREATE_R'
const GET_VIDEOS_DIR_F = 'GET_VIDEOS_DIR_F'
const GET_VIDEOS_DIR_R = 'GET_VIDEOS_DIR_R'
const VIDEO_DELETE_F = 'VIDEO_DELETE_F'
const VIDEO_DELETE_R = 'VIDEO_DELETE_R'
const VIDEO_MOVE_DIRECTORY_F = 'VIDEO_MOVE_DIRECTORY_F'
const VIDEO_MOVE_DIRECTORY_R = 'VIDEO_MOVE_DIRECTORY_R'
const VIDEO_DIALOG_VISIBLE = 'VIDEO_DIALOG_VISIBLE'
const ADD_VIDEO_IMAGE_F = 'ADD_VIDEO_IMAGE_F'
const ADD_VIDEO_IMAGE_P = 'ADD_VIDEO_IMAGE_P'
const VIDEO_BULK_ARCIVE_F = 'VIDEO_BULK_ARCIVE_F'
const VIDEO_BULK_ARCIVE_R = 'VIDEO_BULK_ARCIVE_R'
const VIDEO_BULK_ARCIVE_P = 'VIDEO_BULK_ARCIVE_P'
const ADD_MAIN_VIDEO = 'ADD_MAIN_VIDEO'
const VIDEO_CHANGE_FILE = 'VIDEO_CHANGE_FILE'
const VIDEO_REMOVE_CHANGED_FILE = 'VIDEO_REMOVE_CHANGED_FILE'
const VIDEO_CHANGE_DIRECTORY = 'VIDEO_CHANGE_DIRECTORY'
const VIDEO_CLEAR_UPLOAD_HISTORY = 'VIDEO_CLEAR_UPLOAD_HISTORY'

export {
    VIDEO_PAG_F,
    VIDEO_PAG_R,
    VIDEO_SIZE_CHANGE,
    VIDEO_PAGE_CHANGE,
    VIDEO_FILETR_TABE_CHANGE,
    VIDEO_UPDATE_F,
    VIDEO_UPDATE_R,
    VIDEO_CHECKBOX_CHANGE,
    VIDEO_GET_FOLDERS_F,
    VIDEO_ADD_FOLDER_F,
    VIDEO_ADD_FOLDER_R,
    VIDEO_REMOVE_FOLDER_F,
    VIDEO_REMOVE_FOLDER_R,
    VIDEO_UPDATE_FOLDER_F,
    VIDEO_CREATE_F,
    VIDEO_CREATE_R,
    GET_VIDEOS_DIR_F,
    GET_VIDEOS_DIR_R,
    VIDEO_DELETE_F,
    VIDEO_DELETE_R,
    VIDEO_MOVE_DIRECTORY_F,
    VIDEO_MOVE_DIRECTORY_R,
    VIDEO_DIALOG_VISIBLE,
    ADD_VIDEO_IMAGE_F,
    ADD_VIDEO_IMAGE_P,
    VIDEO_BULK_ARCIVE_F,
    VIDEO_BULK_ARCIVE_R,
    VIDEO_BULK_ARCIVE_P,
    ADD_MAIN_VIDEO,
    VIDEO_CHANGE_FILE,
    VIDEO_REMOVE_CHANGED_FILE,
    VIDEO_CHANGE_DIRECTORY,
    VIDEO_CLEAR_UPLOAD_HISTORY
}