const GET_FILTERS = 'GET_FILTERS';
const GET_FILTER_ITEM = 'GET_FILTER_ITEM';
const GET_DELETED_FILTERS = 'GET_DELETED_FILTERS ';
const SELECT_FILTER_ITEMS = 'SELECT_FILTER_ITEMS';
const RECOVER_FILTER_ITEMS = 'RECOVER_FILTER_ITEMS';
const DELETE_FILTER_ITEMS = 'DELETE_FILTER_ITEMS';
const GET_PAG_REMOVED_FILTERS = 'GET_PAG_REMOVED_FILTERS';
const GET_PAG_NOT_REMOVED_FILTERS = 'GET_PAG_NOT_REMOVED_FILTERS'
const BULK_RECOVER_FILTER_ITEMS = 'BULK_RECOVER_FILTER_ITEMS'
const ARCHIVE_FILTER_ITEMS = 'ARCHIVE_FILTER_ITEMS'
const FETCH_FILTER_SEE_ALL = 'FETCH_FILTER_SEE_ALL'
const DESTROY_FILTER = 'DESTROY_FILTER'
const BULK_DESTROY_FILTER_ITEMS = 'BULK_DESTROY_FILTER_ITEMS'

export {GET_DELETED_FILTERS, GET_FILTERS, SELECT_FILTER_ITEMS,GET_FILTER_ITEM, RECOVER_FILTER_ITEMS, DELETE_FILTER_ITEMS, GET_PAG_REMOVED_FILTERS, GET_PAG_NOT_REMOVED_FILTERS, BULK_RECOVER_FILTER_ITEMS, ARCHIVE_FILTER_ITEMS, FETCH_FILTER_SEE_ALL, DESTROY_FILTER, BULK_DESTROY_FILTER_ITEMS}