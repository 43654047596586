const GET_ALL_ATTRIBUTE = 'GET_ALL_ATTRIBUTE';
const GET_ALL_REMOVED_ATTRIBUTE = 'GET_ALL_REMOVED_ATTRIBUTE';
const GET_ALL_NOT_REMOVED_ATTRIBUTE = 'GET_ALL_NOT_REMOVED_ATTRIBUTE';
const GET_ATTRIBUTE_ITEMS = 'GET_ATTRIBUTE_ITEMS';
const SELECT_ATTRIBUTE_ITEMS = 'SELECT_ATTRIBUTE_ITEMS';
const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
const RECOVER_ATTRIBUTE = 'RECOVER_ATTRIBUTE';
const RECOVER_ATTRIBUTE_ITEMS = 'RECOVER_ATTRIBUTE_ITEMS';
const DELETE_ATTRIBUTE_ITEMS = 'DELETE_ATTRIBUTE_ITEMS';
const GET_PAG_REMOVED_ATTRIBUTES = 'GET_PAG_REMOVED_ATTRIBUTES';
const GET_PAG_NOT_REMOVED_ATTRIBUTES = 'GET_PAG_NOT_REMOVED_ATTRIBUTES';
const BULK_RECOVER_ATTRIBUTE_ITEMS = 'BULK_RECOVER_ATTRIBUTE_ITEMS';
const ARCHIVE_ATTRIBUTE_ITEMS = 'ARCHIVE_ATTRIBUTE_ITEMS';
const DESTROY_ATTRIBUTE = 'DESTROY_ATTRIBUTE';
const BULK_DESTROY_ATTRIBUTE_ITEMS = 'BULK_DESTROY_ATTRIBUTE_ITEMS'

export {
  GET_ALL_ATTRIBUTE,
  GET_ALL_REMOVED_ATTRIBUTE,
  GET_ATTRIBUTE_ITEMS,
  SELECT_ATTRIBUTE_ITEMS,
  ADD_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  RECOVER_ATTRIBUTE,
  RECOVER_ATTRIBUTE_ITEMS,
  DELETE_ATTRIBUTE_ITEMS,
  GET_PAG_REMOVED_ATTRIBUTES,
  GET_PAG_NOT_REMOVED_ATTRIBUTES,
  BULK_RECOVER_ATTRIBUTE_ITEMS,
  ARCHIVE_ATTRIBUTE_ITEMS,
  DESTROY_ATTRIBUTE,
  BULK_DESTROY_ATTRIBUTE_ITEMS,
  GET_ALL_NOT_REMOVED_ATTRIBUTE
}