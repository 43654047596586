import { ICON_TYPES } from '../../types'
import config from '../../../../config/app'

const initialState = {
  data: [],
  folders: [],
  dirs: [],
  dialogVisible: {
    isOpen: false,
    name: 'move'
  },
  checked: true,
  foldErr: { },
  canseled: false,
  files: [],
  urls: [],
  count: 0,
  directory: config['ICON_UPLOAD_URL']
};


const icons = ( state = initialState, action = {}) => {

  switch (action.type) {

    case ICON_TYPES.ICON_GET_FOLDERS_F: {
        return {
            ...state,
            dirs: action.payload,
            foldErr: {},
            dialogVisible: {isOpen: true, name: action.dialogVisibleName}
        }
    }

    case ICON_TYPES.ICON_ADD_FOLDER_F: {

      return {
        ...state,
        foldErr: {},
        dialogVisible: { ...state.dialogVisible, ...{ isOpen: false }  },
        folders: [ action.payload, ...state.folders]
      }

    }

    case ICON_TYPES.ICON_ADD_FOLDER_R: {

      return {
        ...state,
        foldErr: action.payload      
      }

    }
    
    case ICON_TYPES.ICON_REMOVE_FOLDER_F: {
      return {
        ...state,
        foldErr: {},
        folders: state.folders.filter(item => item !== action.payload)    
      }

    }

    case ICON_TYPES.ICON_REMOVE_FOLDER_R: {

      return {
        ...state,
        foldErr: action.payload
      }

    }


    case ICON_TYPES.ICON_UPDATE_FOLDER_F: {

      return {
        ...state,
        dialogVisible: { ...state.dialogVisible, ...{ isOpen: false }  },
        folders: state.folders.map(item => item == action.payload.dir ? action.payload.newDir: item)
      }

    }

    case ICON_TYPES.GET_ICONS_DIR_F: {
      
      return {
        ...state,
        canseled: false,
        data: [...new Set(action.payload.data.icon)],
        folders: action.payload.data.dirs,
        count: action.payload.data.count
      }

    }

    case ICON_TYPES.ICON_CREATE_F: {

      return { 
          ...state,
          data: [action.payload.data, ...state.data]
      }
      
    }

    case ICON_TYPES.ICON_DELETE_F: {

      return {
          ...state,
          data: state.data.filter(item => item.iconId !== action.payload)
      }
      
    }
    
    case ICON_TYPES.ICON_MOVE_DIRECTORY_F: {

        return {
          ...state,
          dirs: action.payload,
          dialogVisible: false
        }
    }


    case ICON_TYPES.ICON_DIALOG_VISIBLE: {

      return {
        ...state,
        dialogVisible: action.payload
      }

    }

    case ICON_TYPES.ICON_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case ICON_TYPES.ADD_MAIN_ICON: {
      return {
        ...state,
        data: [...new Set(action.payload)],
        folders: [],
        canseled: true
      }
    }


  case ICON_TYPES.MEDIA_CHANGE_FILE: {
      return {
          ...state,
          files: [action.payload['file'], ...state.files],
          urls: [action.payload['url'], ...state.urls]
      }
  }

  case ICON_TYPES.MEDIA_REMOVE_CHANGED_FILE: {
      return {
          ...state,
          files: state.files.filter((_file, i) => i !== action.payload),
          urls: state.urls.filter((_url, i) => i !== action.payload)
      }
  }

  case ICON_TYPES.ICON_CHANGE_DIRECTORY : {
    return {
      ...state,
      directory: action.payload
    }
  }

  case ICON_TYPES.CLEAR_UPLOAD_HISTORY : {
    return {
      ...state,
      files: [],
      urls: []
    }
  }

    default:
            return state;
  }
}
export {
    icons
}