 const Required = (data, _min = null, _max = null) => {

    return new Promise((resolve, reject) => {
   
        let errorMsg = [];
        let successMsg = []

        data.forEach( (elem) => {

            if (elem.field.length < 1 && elem.required) {

                let error = {
                    msg: elem.message,
                    name: elem.name,
                    locale: elem.language,
                    status: false
                }


                errorMsg.push(error)

            } else if (elem.min || elem.max) {
                const errorCharacter = lengthValidator(elem.field, elem.min, elem.max, elem.name)
                if (!errorCharacter.status)  {
                    let errorCharacterValidate = {
                        msg: errorCharacter.msg,
                        name: errorCharacter.name,
                        locale: elem.language,
                        status: false
                    }
                    errorMsg.push(errorCharacterValidate)
                }
                
            } else {
                let success = {
                    msg: 'success',
                    status: false
                }

                successMsg.push(success)
            }
        } )

        if (errorMsg['length'] > 0) {

            reject(errorMsg)

        } else {

           resolve(successMsg)

        }
    })
}


function lengthValidator(data = null, min = null, max = null, name = null) {
    let errorMsg = {}
    
    if (min && data.length < min) {
        errorMsg.status = false;
        errorMsg.msg = 'Minimum length should be ' + min + ' ' + 'characters.'
        errorMsg.name = name
        return errorMsg;
    }
    else if (max && data.length > max) {
        errorMsg.status = false;
        errorMsg.msg = 'Maximum length should be ' + max  + ' ' + 'characters.'
        errorMsg.name = name
        return errorMsg;
    }

    else {
        errorMsg.status = true;
        errorMsg.msg = "Correct"
    }
    return errorMsg
}


// One validation function for all file types   
function fileTypeValidation(fName, fType) {
    let extensionLists = {}
    extensionLists.video = ['m4v', 'avi','mpg','mp4', 'webm'];  
    extensionLists.image = ['jpg', 'gif', 'bmp', 'png', 'jpeg', 'JPG']; 
    extensionLists.icon = ['jpg', 'gif', 'bmp', 'png', 'jpeg', 'svg']; 

    return extensionLists[fType].indexOf(fName.split('.').pop()) > -1;

}

export { 
    Required ,
    fileTypeValidation
}