const inputs = {
  source: "",
  sortOrder: 0,
  descriptions: [{
    languageId: 1,
    name: "",
    description: "",
  }],
  filters: [],
  stores: [],
};
export {
  inputs
}
