const PLAN_SUBSCRIPTION_PAG_F = 'PLAN_SUBSCRIPTION_PAG_L_F';
const PLAN_SUBSCRIPTION_PAG_R = 'PLAN_SUBSCRIPTION_PAG_L_R';
const PLAN_SUBSCRIPTION_SIZE_CHANGE = 'PLAN_SUBSCRIPTION_SIZE_CHANGE'
const PLAN_SUBSCRIPTION_PAGE_CHANGE = 'PLAN_SUBSCRIPTION_PAGE_CHANGE'
const PLAN_SUBSCRIPTION_FILETR_TABE_CHANGE = 'PLAN_SUBSCRIPTION_FILETR_TABE_CHANGE'
const PLAN_SUBSCRIPTION_UPDATE_F = 'PLAN_SUBSCRIPTION_UPDATE_F'
const PLAN_SUBSCRIPTION_UPDATE_R = 'PLAN_SUBSCRIPTION_UPDATE_R'
const PLAN_SUBSCRIPTION_CHECKBOX_CHANGE = 'PLAN_SUBSCRIPTION_CHECKBOX_CHANGE'
const PLAN_SUBSCRIPTION_BULK_ARCIVE_F = 'PLAN_SUBSCRIPTION_BULK_ARCIVE_F'
const PLAN_SUBSCRIPTION_BULK_ARCIVE_R = 'PLAN_SUBSCRIPTION_BULK_ARCIVE_R'
const PLAN_SUBSCRIPTION_BULK_DESTROY_F = 'PLAN_SUBSCRIPTION_BULK_DESTROY_F'
const PLAN_SUBSCRIPTION_BULK_DESTROY_R = 'PLAN_SUBSCRIPTION_BULK_DESTROY_R'
const PLAN_SUBSCRIPTION_TAB_PANEL_CHANGE = 'PLAN_SUBSCRIPTION_TAB_PANEL_CHANGE'
const PLAN_SUBSCRIPTION_CREATE_F = 'PLAN_SUBSCRIPTION_CREATE_F'
const PLAN_SUBSCRIPTION_CREATE_R = 'PLAN_SUBSCRIPTION_CREATE_R'
const PLAN_SUBSCRIPTION_GET_ONE_F = 'PLAN_SUBSCRIPTION_GET_ONE_F'
const PLAN_SUBSCRIPTION_GET_ONE_R = 'PLAN_SUBSCRIPTION_GET_ONE_R'
const PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_F = 'PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_F'
const PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_R = 'PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_R'
const NEW_PLAN_SUBSCRIPTION = 'NEW_PLAN_SUBSCRIPTION'
const PLAN_SUBSCRIPTION_DATA_FILTER = 'PLAN_SUBSCRIPTION_DATA_FILTER'
const PLAN_SUBSCRIPTION_DELETE_F = 'PLAN_SUBSCRIPTION_DELETE_F'
const PLAN_SUBSCRIPTION_DELETE_R = 'PLAN_SUBSCRIPTION_DELETE_R'
const PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH = 'PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH'
const PLAN_SUBSCRIPTION_FILTER_IS_OPEN = 'PLAN_SUBSCRIPTION_FILTER_IS_OPEN'
const PLAN_SUBSCRIPTION_LANGUAGE_PANEL_CHANGE = 'PLAN_SUBSCRIPTION_LANGUAGE_PANEL_CHANGE'
const PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH_RESET = 'PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH_RESET'
const PLAN_SUBSCRIPTION_DATA_FILTER_ALL_SEARCH_RESET = 'PLAN_SUBSCRIPTION_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_PLAN_SUBSCRIPTION_SEE_ALL = 'FETCH_PLAN_SUBSCRIPTION_SEE_ALL'

export {
    PLAN_SUBSCRIPTION_PAG_F,
    PLAN_SUBSCRIPTION_PAG_R,
    PLAN_SUBSCRIPTION_SIZE_CHANGE,
    PLAN_SUBSCRIPTION_PAGE_CHANGE,
    PLAN_SUBSCRIPTION_FILETR_TABE_CHANGE,
    PLAN_SUBSCRIPTION_UPDATE_F,
    PLAN_SUBSCRIPTION_UPDATE_R,
    PLAN_SUBSCRIPTION_CHECKBOX_CHANGE,
    PLAN_SUBSCRIPTION_BULK_ARCIVE_F,
    PLAN_SUBSCRIPTION_BULK_ARCIVE_R,
    PLAN_SUBSCRIPTION_BULK_DESTROY_F,
    PLAN_SUBSCRIPTION_BULK_DESTROY_R,
    PLAN_SUBSCRIPTION_TAB_PANEL_CHANGE,
    PLAN_SUBSCRIPTION_CREATE_F,
    PLAN_SUBSCRIPTION_CREATE_R,
    PLAN_SUBSCRIPTION_GET_ONE_F,
    PLAN_SUBSCRIPTION_GET_ONE_R,
    PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_F,
    PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_R,
    NEW_PLAN_SUBSCRIPTION,
    PLAN_SUBSCRIPTION_DATA_FILTER,
    PLAN_SUBSCRIPTION_DELETE_F,
    PLAN_SUBSCRIPTION_DELETE_R,
    PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH,
    PLAN_SUBSCRIPTION_FILTER_IS_OPEN,
    PLAN_SUBSCRIPTION_LANGUAGE_PANEL_CHANGE,
    PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH_RESET,
    PLAN_SUBSCRIPTION_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_PLAN_SUBSCRIPTION_SEE_ALL
}