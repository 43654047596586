import { CHAT_TYPES, NOTIFICATION_TYPES } from '../../types'

const initialState = {
  mainNotifications: [],
  notificationsCount: 0,
  ids: [],
  files: [],
  urls: [],
  error: {},
  loading: false,
  users: [],
  messages: {},
  count: 0,
  socket: null,
  callConfig: null,
  usersSelect: [],
  usersCount: 0,
  initialFilter: {
    offset: 1, 
    limit: 20, 
    showMore: false, 
    isOnline: false, 
    unread: false, 
    read: false, 
    isAdmin: false,
    storeIds: [],
    email: '',
    phone: ''
  }
};


const chat = ( state = initialState, action = {}) => {

  switch (action.type) {

    case 'SAVE_CHAT_SOCKET': {
        return {
            ...state,
            socket: action.payload
        }
    }

    case 'CHAT_CALL_CONFIG': {
        return {
            ...state,
            callConfig: action.payload
        }
    }

    case CHAT_TYPES.UPLOAD_FILES_P: {
        return {
            ...state,
            loading: true
        }
    }

    case CHAT_TYPES.UPLOAD_FILES_F: {
        let arrIds = []
        if (!action.payload.length) {
            arrIds.push(action.payload.fileId)
        } else {
            action.payload.forEach(file => {
                arrIds.push(file.fileId)
            })
        }

        return {
            ...state,
            loading: false,
            ids: arrIds,
            files: [],
            urls: []
        }
    }

    case CHAT_TYPES.UPLOAD_FILES_R: {
        return {
            ...state,
            loading: false,
            error: action.payload
        }
    }

    case CHAT_TYPES.CHANGE_FILE: {
        return {
            ...state,
            files: [action.payload['file'], ...state.files],
            urls: [action.payload['url'], ...state.urls]
        }
    }

    case CHAT_TYPES.REMOVE_CHANGED_FILE: {
        return {
            ...state,
            files: state.files.filter((_file, i) => i !== action.payload),
            urls: state.urls.filter((_url, i) => i !== action.payload)
        }
    }


    case CHAT_TYPES.FETCH_CHAT_NOTIFICATIONS_P: {

        return {
            ...state,
            loading: true
        };
    }

    case CHAT_TYPES.FETCH_CHAT_NOTIFICATIONS_F: {
        return { 
            ...state, 
            loading: false,
            mainNotifications: action.payload
        };

    }

    case CHAT_TYPES.FETCH_CHAT_NOTIFICATIONS_R: {
        return {
            ...state,
            loading: false,
            error: action.payload,
            mainNotifications: []
        };
    }

    case NOTIFICATION_TYPES.DESTROY_NOTIFICATION_F: {
        return { 
            ...state, 
            mainNotifications: state.mainNotifications.filter(notification => notification._id !== action.payload.id),
            loading: false
        };
        
    }


    case NOTIFICATION_TYPES.UPDATE_NOTIFICATION_F: {
        const notification = action.payload.data;
        const mainNotifications = []
        state.mainNotifications.forEach(item => {
            if (item._id === notification._id) {
                item.is_read = notification.is_read
            } 
            mainNotifications.push(item)
        })
        return {
            ...state,
            mainNotifications: mainNotifications,
            loading: false,
        }
    }

    case CHAT_TYPES.BULK_REMOVE_CHAT_NOTIFICATIONS_F: {
        return {
            ...state,
            mainNotifications: state.mainNotifications.filter(notification => notification.receiver !== action.payload.id)
        };
    }
    
    case CHAT_TYPES.FETCH_USERS_CHAT_P: {

        return {
            ...state,
            loading: true,
        };
    }

    case CHAT_TYPES.FETCH_USERS_CHAT_F: {
        function array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        }
        let rooms  = []
        if (!action.sort) {
            rooms = action.showMore ? [...state.users, ...action.payload] : [...action.payload]
        } else {
            rooms = [...state.users]
            if (action.payload.length) {
                let room = action.payload[0]
                let roomIndex = rooms.findIndex(el => el._id === room._id);
                if (roomIndex !== -1) {
                    rooms = array_move(rooms, roomIndex, 0)
                } else {
                    rooms.splice(0, 0, room)                
                }
            }
        }
    
        return { 
            ...state, 
            loading: false,
            users: rooms
        };

    }

    case CHAT_TYPES.FETCH_USERS_CHAT_R: {
        return {
            ...state,
            loading: false,
            error: action.payload,
        };
    }

    case CHAT_TYPES.UPDATE_CHAT_USERS: {
        const roomId = action.payload.chatRooms[0]
        return {
            ...state,
            users: state.users.map((room) => {
                room.members = room.members.map(member => member._id == action.payload._id ? {
                    ...member,
                    online: action.payload.online
                }:member)

                if (room._id == roomId) {
                    room.online = action.payload.online
                }

                return room
            } )
        }
    }

    case CHAT_TYPES.ADD_CHAT_USER: {
        return {
            ...state,
            users: [action.payload, ...state.users],
            count: state.count + 1
        }
    }

    case CHAT_TYPES.FETCH_MESSAGES_CHAT_P: {

        return {
            ...state,
            loading: true,
        };
    }

    case CHAT_TYPES.FETCH_MESSAGES_CHAT_F: {
        const { id, payload, paginate } = action
        let messages
        if (state.messages[id] && paginate) {
            messages = { [id]: [...payload.data, ...state.messages[id]] }
        } else {
            messages = { [id]: payload.data }
        }
        return { 
            ...state, 
            loading: false,
            messages: {...state.messages, ...messages},
            count: payload.count
        };

    }

    case CHAT_TYPES.UPDATE_CHAT_MESSAGE_CONDITION: {
        const { roomId } = action.payload
        let messages
        if (state.messages[roomId]) {
            messages = { [roomId]: state.messages[roomId].map((obj) => ({ ...obj, condition: 'read' })) }
        }
        return { 
            ...state, 
            loading: false,
            messages: {...state.messages, ...messages},
            users: state.users.map((obj) => obj._id == roomId ? ({...obj, message: {...obj.message, condition: 'read'}}) : obj)
        };
    }


    case CHAT_TYPES.FETCH_MESSAGES_CHAT_R: {
        return {
            ...state,
            loading: false,
            error: action.payload,
        };
    }

    case CHAT_TYPES.ADD_MESSAGE_CHAT: {
        const { id, payload } = action
        let messages
        if (state.messages[id]) {
            messages = { [id]: [...state.messages[id], payload] }
        } else {
            messages = { [id]: [payload] }
        }
        return {
            ...state,
            loading: false,
            messages: {...state.messages, ...messages},
            users: state.users.map(user =>
                user._id === id
                  ? { 
                      ...user, 
                        lastMessage: payload.message 
                    }
                  : user
            )
        };
    }

    case CHAT_TYPES.UPDATE_CHAT_USER_NOTIFICS_COUNT: {
        const _id = action.payload
        return {
            ...state,
            users: state.users.map((room) => {
                room.members = room.members.map(member => member._id == _id ? {
                    ...member,
                    notificationsCount: action.count ? member.notificationsCount + 1 : 0
                } :member)
                return room
            } )
        }
    }

    case CHAT_TYPES.DESTROY_ONE_USER_CHAT_NOTIFICATIONS_F: {
        const _id = action.payload
        return {
            ...state,
            users: state.users.map(user =>
                user._id === _id
                  ? { ...user, notificationsCount: 0 }
                  : user
              )
        }
    }

    case CHAT_TYPES.REMOVE_CHAT_LIST : {
        return {
            ...state,
            messages: []
        }
    }

    case CHAT_TYPES.DESTROY_CHAT_MESSAGE_P: {
        return {
            ...state,
            loading: true
        };
    }

    case CHAT_TYPES.DESTROY_CHAT_MESSAGE: {
        const message  = action.payload;
        let messages = {}
        if (state.messages[message.chatRoom]) {
            messages[message.chatRoom] = state.messages[message.chatRoom].map(item => item._id === message._id ? message : item)
        }
        return { 
            ...state, 
            loading: false,
            messages: {...state.messages, ...messages},
        };
    }

    case CHAT_TYPES.DESTROY_CHAT_MESSAGE_R: {
        return {
            ...state,
            loading: false
        };
    }

    case NOTIFICATION_TYPES.ALL_READ_NOTIFICATION_F: {
        return {
            ...state,
            mainNotifications: state.mainNotifications.map(item => ({ 
                ...item, 
                is_read: true
            })),
            loading: false,
        }
    }

    case CHAT_TYPES.DESTROY_CHAT_ROOM_P: {
        return {
            ...state,
            loading: true
        }
    }

    case CHAT_TYPES.DESTROY_CHAT_ROOM_F: {
        const { id } = action.payload
        return {
            ...state,
            loading: false,
            users: state.users.filter(room => !id.includes(room._id) )
        }
    }

    case CHAT_TYPES.DESTROY_CHAT_ROOM_R: {
        return {
            ...state,
            loading: false,
            error: action.payload
        }
    }

    case CHAT_TYPES.FETCH_MEMBERS_P: {
        return {
            ...state,
            loading: true
        }
    }
  
    case CHAT_TYPES.FETCH_MEMBERS_F: {
        const newUsers = []
        action.payload.data.forEach(user => {
            if (user.username) {
                newUsers.push({ 
                    value: user._id,
                    label: user.username
                })
            }
        })
  
        let usersSelect = action.search ? newUsers : [...state.usersSelect, ...newUsers]
        usersSelect = usersSelect.filter((elem, index, self) =>
            index === self.findIndex((e) => (
                e.value === elem.value
            ))
        )
  
        return {
            ...state,
            loading: false,
            usersSelect: usersSelect,
            usersCount: action.payload.count
        }
    }
  
    case CHAT_TYPES.FETCH_MEMBERS_R: {
        return {
            ...state,
            loading: false
        }
    }

    

    case CHAT_TYPES.CHAT_ROOM_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
        }
    }  
  

    default:
            return state;
  }
}
export {
    chat
}