const GET_MANUFACTURER = 'GET_MANUFACTURER';
const GET_DELETED_MANUFACTURER = 'GET_DELETED_MANUFACTURER';
const SELECT_MANUFACTURER_ITEMS = 'SELECT_MANUFACTURER_ITEMS';
const GET_MANUFACTURER_ITEM = 'GET_MANUFACTURER_ITEM';
const GET_PAG_NOT_REMOVED_MANUFACTURERS = 'GET_PAG_NOT_REMOVED_MANUFACTURERS';
const GET_PAG_REMOVED_MANUFACTURERS = 'GET_PAG_REMOVED_MANUFACTURERS';
const DELETE_MANUFACTURER_ITEMS = 'DELETE_MANUFACTURER_ITEMS';
const RECOVER_MANUFACTURER_ITEMS = 'RECOVER_MANUFACTURER_ITEMS';
const ARCHIVE_MANUFACTURER_ITEMS = 'ARCHIVE_MANUFACTURER_ITEMS';
const BULK_RECOVER_MANUFACTURER_ITEMS = 'BULK_RECOVER_MANUFACTURER_ITEMS';
const NEW_MANUFACTURER_FULFILLED = 'NEW_MANUFACTURER_FULFILLED';
const NEW_MANUFACTURER_REJECTED = 'NEW_MANUFACTURER_REJECTED';
const SAVE_MANUFACTURER_FULFILLED = 'SAVE_MANUFACTURER_FULFILLED';
const SAVE_MANUFACTURER_REJECTED = 'SAVE_MANUFACTURER_REJECTED';
const FETCH_MANUFACTURER_FULFILLED = 'FETCH_MANUFACTURER_FULFILLED';
const FETCH_MANUFACTURER_PENDING = 'FETCH_MANUFACTURER_PENDING'
const FETCH_MANUFACTURER_SEE_ALL = 'FETCH_MANUFACTURER_SEE_ALL'
const UPDATE_MANUFACTURER_FULFILLED = 'UPDATE_MANUFACTURER_FULFILLED';
const LANG_TAB = 'LANG_TAB';
const DESTROY_MANUFACTURER = 'DESTROY_MANUFACTURER';
const BULK_DESTROY_MANUFACTURER_ITEMS = 'BULK_DESTROY_MANUFACTURER_ITEMS'
const MANUFACTURER_TAB_PANEL_CHANGE = 'MANUFACTURER_TAB_PANEL_CHANGE'
const MANUFACTURER_FILTER_IS_OPEN = 'MANUFACTURER_FILTER_IS_OPEN'
const MANUFACTURER_DATA_FILTER = 'MANUFACTURER_DATA_FILTER'
const MANUFACTURER_DATA_FILTER_SEARCH = 'MANUFACTURER_DATA_FILTER_SEARCH'
const MANUFACTURER_DATA_MIN_MAX_F = 'MANUFACTURER_DATA_MIN_MAX_F'
const MANUFACTURER_SELECT_FILTER_TYPES = 'MANUFACTURER_SELECT_FILTER_TYPES'
const MANUFACTURER_DATA_FILTER_SEARCH_RESET = 'MANUFACTURER_DATA_FILTER_SEARCH_RESET'
const MANUFACTURER_DATA_FILTER_ALL_SEARCH_RESET = 'MANUFACTURER_DATA_FILTER_ALL_SEARCH_RESET'

export {
  GET_MANUFACTURER,
  GET_DELETED_MANUFACTURER,
  SELECT_MANUFACTURER_ITEMS,
  GET_MANUFACTURER_ITEM,
  GET_PAG_NOT_REMOVED_MANUFACTURERS,
  GET_PAG_REMOVED_MANUFACTURERS,
  DELETE_MANUFACTURER_ITEMS,
  RECOVER_MANUFACTURER_ITEMS,
  ARCHIVE_MANUFACTURER_ITEMS,
  BULK_RECOVER_MANUFACTURER_ITEMS,
  NEW_MANUFACTURER_FULFILLED,
  NEW_MANUFACTURER_REJECTED,
  SAVE_MANUFACTURER_FULFILLED,
  SAVE_MANUFACTURER_REJECTED,
  FETCH_MANUFACTURER_FULFILLED,
  FETCH_MANUFACTURER_PENDING,
  FETCH_MANUFACTURER_SEE_ALL,
  UPDATE_MANUFACTURER_FULFILLED,
  LANG_TAB,
  DESTROY_MANUFACTURER,
  BULK_DESTROY_MANUFACTURER_ITEMS,
  MANUFACTURER_TAB_PANEL_CHANGE,
  MANUFACTURER_FILTER_IS_OPEN,
  MANUFACTURER_DATA_FILTER,
  MANUFACTURER_DATA_FILTER_SEARCH,
  MANUFACTURER_DATA_MIN_MAX_F,
  MANUFACTURER_SELECT_FILTER_TYPES,
  MANUFACTURER_DATA_FILTER_SEARCH_RESET,
  MANUFACTURER_DATA_FILTER_ALL_SEARCH_RESET
}