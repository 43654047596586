import  { SMS_DOMAIN_TYPES } from '../../types'
import { Notification } from 'element-react'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
const flowToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJyb290X2lkIjoyNTYsInVzZXJuYW1lIjoiYXRvbSIsInJvbGVzIjpbImFkbWluIl19.1NSakO7VEAaOgGkQJlhXhDrDyRRLGKG6i1DNXt5vfNqiKlvYUUVnQtx1-2Vff3O2'


class SmsDomainActions {
    constructor(_props) {
        this.api = config['SMS_API']
        this.rest  = 'domain/'
        this.arcive = 'b/'
    }

    addNginx = (jwt) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_DOMAIN_TYPES.ADD_DOMAIN_NGINX_P
                })

                const { data } = await axios.get(
                    `https://reg.projectnulla.info/api/nginx/promotion`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_DOMAIN_TYPES.ADD_DOMAIN_NGINX_F,
                    payload: data
                })

            } catch (e) {

                dispatch({
                    type: SMS_DOMAIN_TYPES.ADD_DOMAIN_NGINX_R,
                    payload: e
                })

            }
        }
    }


    create = (jwt, body, Type, history) => {
        return async dispatch => {
            try {

                const response = await axios.post(
                    this.api + this.rest,
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch(this.addNginx(flowToken))
          
                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_CREATE_F'],
                    payload: response.data
                })
          
                    
                history.goBack()

            } catch (e) {

                if (e.response.data && e.response.data.length) {
                    e.response.data.forEach(err => {
                        Notification.error({
                            title: err.param,
                            message: err.message
                        }, 20) 
                    })
                } 

                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_UPDATE_R'],
                    payload: e
                })
      
            }

        }
    }


    update = (jwt, body, Type, history, _account) => {
        return async dispatch => {
            try {

                const response = await axios.put(
                    this.api + this.rest + `/${body.smsDomainId}`,
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch(this.addNginx(flowToken))
        
                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_UPDATE_F'],
                    payload: body
                })
    
                history.goBack()
                
            } catch (e) {

                if (e.response.data && e.response.data.length) {
                    e.response.data.forEach(err => {
                        Notification.error({
                            title: err.param,
                            message: err.message
                        }, 20) 
                    })
                } 

                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_UPDATE_R'],
                    payload: e
                })
    
            }
        }
    }


    pagination = (jwt, params, page, count, query) => {
        return async dispatch => {
            try {

                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + this.rest + `${params}` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                if (data.data.length) {
                    data.data = data.data.map(item => ({ businesName: item.business ? item.business.name : '', ...item }))
                }

                dispatch({
                    type: SMS_DOMAIN_TYPES.SMS_DOMAIN_PAG_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_DOMAIN_TYPES.SMS_DOMAIN_PAG_R,
                    payload: e
                })
            }
        }
        
    }

    destroy = (jwt, id, Type) => {
        return async dispatch => {
            try {

                await axios.delete(
                    this.api + this.rest + `/${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_DELETE_F'],
                    payload: id
                })

            } catch (e) {

                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_DELETE_R'],
                    payload: e
                })
                
            }
        }
    }

    getOne = (jwt, id, Type) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_GET_ONE_P']
                })

                const response = await axios.get(
                    this.api + this.rest + `${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_GET_ONE_F'],
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: SMS_DOMAIN_TYPES[Type + '_GET_ONE_R'],
                    payload: e
                })

            }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
    */
    bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                this.api + this.rest + this.arcive + params,
                ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_DOMAIN_TYPES.SMS_DOMAIN_BULK_ARCIVE_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                dispatch(this.addNginx(flowToken))
                
            } catch (e) {

                dispatch({
                    type: SMS_DOMAIN_TYPES.SMS_DOMAIN_BULK_ARCIVE_R,
                    payload: e
                })

            }
        }
    }

    /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
    */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                    this.api + this.rest + 'b/d',
                    ids,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_DOMAIN_TYPES.SMS_DOMAIN_BULK_DESTROY_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: SMS_DOMAIN_TYPES.SMS_DOMAIN_BULK_DESTROY_R,
                    payload: e
                })

            }
        }
    }

    // clear page cache
    clearCache = () => {
        return async dispatch => {
            dispatch({
                type: SMS_DOMAIN_TYPES.NEW_SMS_DOMAIN
            })
        }
    }

}




export default new SmsDomainActions()