const GET_ALL_ATTRIBUTE_GROUP = 'GET_ALL_ATTRIBUTE_GROUP';
const GET_ALL_REMOVED_ATTRIBUTE_GROUP = 'GET_ALL_REMOVED_ATTRIBUTE_GROUP';
const GET_ATTRIBUTE_GROUP_ITEMS = 'GET_ATTRIBUTE_GROUP_ITEMS';
const SELECT_ATTRIBUTE_GROUP_ITEMS = 'SELECT_ATTRIBUTE_GROUP_ITEMS';
const ADD_ATTRIBUTE_GROUP = 'ADD_ATTRIBUTE_GROUP';
const DELETE_ATTRIBUTE_GROUP = 'DELETE_ATTRIBUTE_GROUP';
const RECOVER_ATTRIBUTE_GROUP = 'RECOVER_ATTRIBUTE_GROUP';
const RECOVER_ATTRIBUTE_GROUP_ITEMS = 'RECOVER_ATTRIBUTE_GROUP_ITEMS';
const DELETE_ATTRIBUTE_GROUP_ITEMS = 'DELETE_ATTRIBUTE_GROUP_ITEMS';
const GET_PAG_REMOVED_ATTRIBUTES_GROUP = 'GET_PAG_REMOVED_ATTRIBUTES_GROUP';
const GET_PAG_NOT_REMOVED_ATTRIBUTES_GROUP = 'GET_PAG_NOT_REMOVED_ATTRIBUTES_GROUP';
const BULK_RECOVER_ATTRIBUTE_GROUP_ITEMS = 'BULK_RECOVER_ATTRIBUTE_GROUP_ITEMS';
const ARCHIVE_ATTRIBUTE_GROUP_ITEMS = 'ARCHIVE_ATTRIBUTE_GROUP_ITEMS';
const DESTROY_ATTRIBUTE_GROUP = 'DESTROY_ATTRIBUTE_GROUP';
const FETCH_ATTRIBUTE_GROUP_SEE_ALL = 'FETCH_ATTRIBUTE_GROUP_SEE_ALL';
const BULK_DESTROY_ATTRIBUTE_GROUP_ITEMS = 'BULK_DESTROY_ATTRIBUTE_GROUP_ITEMS'

export {
  GET_ALL_ATTRIBUTE_GROUP,
  GET_ALL_REMOVED_ATTRIBUTE_GROUP,
  GET_ATTRIBUTE_GROUP_ITEMS,
  SELECT_ATTRIBUTE_GROUP_ITEMS,
  ADD_ATTRIBUTE_GROUP,
  DELETE_ATTRIBUTE_GROUP,
  RECOVER_ATTRIBUTE_GROUP,
  RECOVER_ATTRIBUTE_GROUP_ITEMS,
  DELETE_ATTRIBUTE_GROUP_ITEMS,
  GET_PAG_REMOVED_ATTRIBUTES_GROUP,
  GET_PAG_NOT_REMOVED_ATTRIBUTES_GROUP,
  BULK_RECOVER_ATTRIBUTE_GROUP_ITEMS,
  ARCHIVE_ATTRIBUTE_GROUP_ITEMS,
  DESTROY_ATTRIBUTE_GROUP,
  FETCH_ATTRIBUTE_GROUP_SEE_ALL,
  BULK_DESTROY_ATTRIBUTE_GROUP_ITEMS 
}