const USER_PAG_F = 'USER_PAG_L_F';
const USER_PAG_R = 'USER_PAG_L_R';
const USER_SIZE_CHANGE = 'USER_SIZE_CHANGE'
const USER_PAGE_CHANGE = 'USER_PAGE_CHANGE'
const USER_FILETR_TABE_CHANGE = 'USER_FILETR_TABE_CHANGE'
const USER_UPDATE_F = 'USER_UPDATE_F'
const USER_UPDATE_R = 'USER_UPDATE_R'
const USER_CHECKBOX_CHANGE = 'USER_CHECKBOX_CHANGE'
const USER_BULK_ARCIVE_F = 'USER_BULK_ARCIVE_F'
const USER_BULK_DESTROY_F = 'USER_BULK_DESTROY_F'
const USER_BULK_DESTROY_R = 'USER_BULK_DESTROY_R'
const USER_BULK_ARCIVE_R = 'USER_BULK_ARCIVE_R'
const USER_TAB_PANEL_CHANGE = 'USER_TAB_PANEL_CHANGE'
const USER_CREATE_F = 'USER_CREATE_F'
const USER_CREATE_R = 'USER_CREATE_R'
const USER_GET_ONE_F = 'USER_GET_ONE_F'
const USER_GET_ONE_R = 'USER_GET_ONE_R'
const USER_REQUIRED_VALIDATION_F = 'USER_REQUIRED_VALIDATION_F'
const USER_REQUIRED_VALIDATION_R = 'USER_REQUIRED_VALIDATION_R'
const NEW_USER = 'NEW_USER'
const USER_DATA_FILTER = 'USER_DATA_FILTER'
const USER_DELETE_F = 'USER_DELETE_F'
const USER_DELETE_R = 'USER_DELETE_R'
const USER_LANGUAGE_PANEL_CHANGE = 'USER_LANGUAGE_PANEL_CHANGE'
const FETCH_USER_SEE_ALL_P = 'FETCH_USER_SEE_ALL_P'
const FETCH_USER_SEE_ALL_F = 'FETCH_USER_SEE_ALL_F'
const FETCH_USER_SEE_ALL_R = 'FETCH_USER_SEE_ALL_R'


export {
    USER_PAG_F,
    USER_PAG_R,
    USER_SIZE_CHANGE,
    USER_PAGE_CHANGE,
    USER_FILETR_TABE_CHANGE,
    USER_UPDATE_F,
    USER_UPDATE_R,
    USER_CHECKBOX_CHANGE,
    USER_BULK_ARCIVE_F,
    USER_BULK_DESTROY_R,
    USER_BULK_DESTROY_F,
    USER_BULK_ARCIVE_R,
    USER_TAB_PANEL_CHANGE,
    USER_CREATE_F,
    USER_CREATE_R,
    USER_GET_ONE_F,
    USER_GET_ONE_R,
    USER_REQUIRED_VALIDATION_F,
    USER_REQUIRED_VALIDATION_R,
    NEW_USER,
    USER_DATA_FILTER,
    USER_DELETE_F,
    USER_DELETE_R,
    USER_LANGUAGE_PANEL_CHANGE,
    FETCH_USER_SEE_ALL_P,
    FETCH_USER_SEE_ALL_F,
    FETCH_USER_SEE_ALL_R
}