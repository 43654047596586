import  { NOTIFICATION_TYPE_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import {Notification} from 'element-react'

 class NotificationTypeActions {
  constructor(_props) {
    this.api = config['NOTIFICATION_API']
    this.rest  = 't/'
    this.arcive = 'b/'
  }

  pagination = (jwt, params, page, count, query) => {
    return async dispatch => {
      try {
        const searchString = qs.stringify(query)
        const response = await axios.get(
        this.api + this.rest + `${params}` + page + '/' + count + '?' + searchString,

        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: NOTIFICATION_TYPE_TYPES.NOTIFICATION_TYPE_PAG_F,
          payload: response.data
        })

      } catch (e) {

        dispatch({
          type: NOTIFICATION_TYPE_TYPES.NOTIFICATION_TYPE_PAG_R,
          payload: e
        })

      }
    }
  }


  update = (jwt, body, Type, history, _discard) => {
    return async dispatch => {
      try {
      await axios.put(
        this.api + this.rest + `${body.notificationTypeId}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        })

        dispatch({
          type: NOTIFICATION_TYPE_TYPES[Type + '_UPDATE_F'],
          payload: body
        })

        history.goBack()
          
      } catch (e) {

        if (Array.isArray(e.response.data)) {
          e.response.data.forEach(err => {
            Notification.error({
              title: err.path,
              message: err.msg
            }, 20)
          })
        } 

        dispatch({
          type: NOTIFICATION_TYPE_TYPES[Type + '_UPDATE_R'],
          payload: e
        })

      }
    }
  }

  destroy = (jwt, id, Type) => {
    return async dispatch => {
      try {
        await axios.delete(
          this.api + this.rest + `${id}`,
        {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
        }

        )
          dispatch({
              type: NOTIFICATION_TYPE_TYPES[Type + '_DELETE_F'],
              payload: id
          })
      } catch (e) {
          dispatch({
              type: NOTIFICATION_TYPE_TYPES[Type + '_DELETE_R'],
              payload: e
          })
        }
      }
  }

  getOne = (jwt, id, Type) => {
    return async dispatch => {
      try {
        const response = await axios.get(
          this.api + this.rest + `${id}`,
        {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
        }
        )
          dispatch({
              type: NOTIFICATION_TYPE_TYPES[Type + '_GET_ONE_F'],
              payload: response.data
          })
      } catch (e) {
          dispatch({
              type: NOTIFICATION_TYPE_TYPES[Type + '_GET_ONE_R'],
              payload: e
          })
        }
      }
  }

  /**
   * @name bulk
   * @param elem
   * @returns {function(*)}
   */
    bulk = (ids, jwt, params, otherDispatchParams) => {
      return async dispatch => {
        try {
            await axios.post(
            this.api + this.rest + this.arcive + params,
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: NOTIFICATION_TYPE_TYPES.NOTIFICATION_TYPE_BULK_ARCIVE_F,
                payload: ids
            })

            dispatch(this.pagination(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: NOTIFICATION_TYPE_TYPES.NOTIFICATION_TYPE_BULK_ARCIVE_R,
                payload: e
            })

          }
        }
    }
  /**
    * @name bulk
    * @param elem
    * @returns {function(*)}
  */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
          await axios.post(
            this.api + this.rest + 'b/d',
            ids,
            {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          }
          )
            dispatch({
                type: NOTIFICATION_TYPE_TYPES.NOTIFICATION_TYPE_BULK_DESTROY_F,
                payload: ids
            })

            dispatch(this.pagination(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: NOTIFICATION_TYPE_TYPES.NOTIFICATION_TYPE_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

    create = (jwt, body, Type, history, _discard) => {
    return async dispatch => {
        await axios.post(
            this.api + this.rest,
            body,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
          .then(response => {

            dispatch({
              type: NOTIFICATION_TYPE_TYPES[Type + '_CREATE_F'],
              payload: response.data
            })

            history.goBack()

          })
          .catch(e => {

            if (Array.isArray(e.response.data)) {
              e.response.data.forEach(err => {
                Notification.error({
                  title: err.path,
                  message: err.msg
                }, 20)
              })
            } 

            dispatch({
                  type: NOTIFICATION_TYPE_TYPES[Type + '_CREATE_R'],
                  payload: e
              })

          })
        }
    }

}


export default new NotificationTypeActions()