import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showInfoDialogWithMessage } from './Utils'
import {orderActions} from "../../redux-modules/actions";
import {hideModal} from "../../redux-modules/actions/dialogActions";

const CancelOrderDialog = (_props) => {
    const dispatch = useDispatch()
    const { data } = useSelector((reduxState) => reduxState.dialog)
    const account = useSelector((reduxState) => reduxState.account)
    const [disabled, setDisabled] = useState(true)
    const [state, setState] = useState({
        value: '',
        rows: 10,
        minRows: 10,
        maxRows: 10
    })

    const [errors, setError] = useState({})

    const isFirstRender = useRef(true)

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false // toggle flag after first render/mounting
            return;
        }
        if (state.value && state["value"].length > 3 && state["value"].replace(/^\s+|\s+$/g, '')) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
        validateForm()
    }, [state.value])

    const validateForm = () => {
        let err = {}
        
        if (!state["value"] || !state["value"].replace(/^\s+|\s+$/g, '')) {
            err["value"] = "*Please enter description.";
        } else if (state["value"].length < 4) {
            err["value"] = "*Minimum characters are 4";
        }

        setError(err)
    }

    const handleChange = (event) => {
		const textareaLineHeight = 24;
		const { minRows, maxRows } = state;
		
		const previousRows = event.target.rows;
  	    event.target.rows = minRows; 
		
		const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);
    
        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }
		
		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}
    
        setState({
            ...state,
            value: event.target.value,
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
    };
    
    return (
        <div className="modal-register">
            <h4 className="cancel-order__title">{data.status} Order</h4>
            <form className="form cancel-order">
                <div className="form__group">
                    <textarea
                        value={state.value}
                        placeholder={'Enter your cancel reason...'}
                        onChange={handleChange}
                        className="cancel-order__textarea"
                    />
                {!!errors.value && <div className="validation">{errors.value}</div>}
                </div>
            </form>
            <button className="btn modal-button"
                    style={{backgroundColor: '#000', opacity: disabled ? "0.4" : "1"}}
                    type={'submit'}
                    disabled={disabled}
                    form={'change-password'}
                    onClick={_e => dispatch(orderActions.changeStatus(data.id, {...data, message: state.value}, account.token)).then(_payload => {
                        showInfoDialogWithMessage(dispatch, {message: `Your order ${data.status}`})
                        setTimeout(() => dispatch(hideModal()), 5000)
                    })}
                >
                    Confirm
            </button>
        </div>
    )
}

export default CancelOrderDialog;