import { DELIVERY_ROUTE_TYPES } from '../../types'
import { updateDimensions } from '../../services/mobileService'
import Fields from '../../../delivery/routes/type'
const isMobile = updateDimensions()

const initialState = {
    data: {
        data: [],
        count: 0
    },
    deliveryRoute: new Fields(),
    pageNumber: {
        removed: localStorage.getItem('removedDeliveryRoutePage') || 1,
        notRemoved: localStorage.getItem('notRemovedDeliveryRoutePage') || 1
    },
    showEntries: {
        removed: localStorage.getItem('removedDeliveryRoutePageSize') || 10,
        notRemoved: localStorage.getItem('notRemovedDeliveryRoutePageSize') || 10
    },
    langTab : {
        languageId: 1,
        langTab: 0
    },
    filterTab: {
        filterTab: true,
        path: 'r/',
        removed: 'notRemoved'
    },
    initialFilter: {
        message: '',
        from: '',
        to: '',
        statusF: null,
        datesF:  '',
    },
    dataFilter: {
        search: '',
        message: false,
    },
    isOpen: isMobile ? false : localStorage.getItem('deliveryRouteFilter') == 'true' ? true : false,
    isOpenMobile: isMobile ? false : localStorage.getItem('isOpenMobile') == 'true' ? true : false,
    tabPanel: '1',
    checked: false,
    ids: [],
    loading: true,
    error: {}
};

const deliveryRoute = ( state = initialState, action = {}) => {

  switch (action.type) {

  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_PAG_F: {
        return {
            ...state,
            loading: false,
            data: action.payload
        }
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_PAG_R: {
        return {
            ...state,
            loading: false,
            error: action.payload
        }
    }

    case DELIVERY_ROUTE_TYPES.ADD_DELIVERY_ROUTE_P: {
        return {
            ...state,
            loading: true
        }
    }

    case DELIVERY_ROUTE_TYPES.ADD_DELIVERY_ROUTE_F: {
        return {
            ...state,
            error: {},
            loading: false
        }
    }

    case DELIVERY_ROUTE_TYPES.ADD_DELIVERY_ROUTE_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.response
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_SIZE_CHANGE: {

        const showEntries = Object.assign(state.showEntries, action.payload)
        
        return {
            ...state,
            showEntries: showEntries
        }
    }


    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_PAGE_CHANGE: {
      
        const pageNumber = Object.assign(state.pageNumber, action.payload)
 
        return {
            ...state,
            pageNumber: pageNumber
        }
    }


    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_FILETR_TABE_CHANGE: {
      
        return {
            ...state,
            filterTab: action.payload,
            checked: false
        }
    }


    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_TAB_PANEL_CHANGE: {
      
        return {
            ...state,
            tabPanel: action.payload
        }
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_CHECKBOX_CHANGE: {
        let ids = [...state.ids]
        const { checked, multiple, key } = action.payload
        const data = state.data.data.map(elem => {
  
            if (multiple) {
  
                elem['isChecked'] = checked
                return elem
  
            } else {
  
                state.data.data[key]['isChecked'] = checked
                return elem
                  
            }
  
        })
  
        data.forEach(elem => { if (elem.isChecked) ids.push(elem.deliveryRouteId) })
  
        return {
            ...state,
            data: {
                data: data,
                count: state.count
            },
            checked: !multiple ? data.every(element => element.isChecked): checked,
            ids: ids
        }
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_BULK_ARCIVE_F: {
        
        return { 
            ...state,
            checked: false
        }
  
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_BULK_DESTROY_F: {
        
        return { 
            ...state,
            checked: false
        }
  
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_LANGUAGE_PANEL_CHANGE: {
        return { 
            ...state,
            langTab: action.payload
        }
    }
 
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_DATA_FILTER: {
        return { 
            ...state,
            dataFilter: { [action.payload['key']]: action.payload['value'] },
            error: []
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_DELETE_F: {
        const id = action.payload;
        return {
            ...state,
            data: state.data.data.filter(item => item.deliveryRouteId !== id)
        }
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_FILTER_IS_OPEN: {
        localStorage.setItem('deliveryRouteFilter', !state.isOpen)
        return {
            ...state,
            isOpen: !state.isOpen
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_MOBILE_FILTER_IS_OPEN: {
        localStorage.setItem('isOpenMobile', !state.isOpenMobile)
        return {
            ...state,
            isOpenMobile: !state.isOpenMobile
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
            error: []
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_DATA_FILTER_ALL_SEARCH_RESET: {
        const { key } = action.payload
        return {
            ...state,
            [key]: { 
                from: '',
                to: '',
                message: '',
                status: null,
                dates:  '',
            }
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_NEW: {
        return {
            ...state,
            deliveryRoute: new Fields()
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_GET_ONE_P: {
        return {
            ...state,
            loading: true
        }
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_GET_ONE_F: {
    
        action.payload.ordersDeliverRoute = action.payload.ordersDeliverRoute.sort((a, b) => a.sortOrder - b.sortOrder)
        action.payload = {
            ...action.payload,
            franchise: action.payload.ordersDeliverRoute[0].order.franchise,
            deliveryRoutes: action.payload.ordersDeliverRoute.map((elem => ({
                key: 'order',
                orderId: elem.order.orderId,
                lat: elem.order.address.latitude,
                lng: elem.order.address.longitude,
                franchise: elem.order.franchise,
                order: elem.order
            })))
        }

        return {
            ...state,
            error: {},
            deliveryRoute: action.payload,
            loading: false
        }
    }
  
    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_GET_ONE_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.response
        }
    }

    case 'DELIVERY_CHANGE_DRIVER_LOCATION': {
        const { location } = action.payload;

        return {
            ...state,
            deliveryRoute: {
                ...state.deliveryRoute,
                driver: {
                    ...state.deliveryRoute.driver,
                    location: location
                }
            }
        }
    }

    case 'DELIVERY_CHANGE_ORDER_STATUS': {
        return {
            ...state,
            deliveryRoute: {
                ...state.deliveryRoute,
                deliveryRoutes: state.deliveryRoute.deliveryRoutes.map((elem) => elem.order.mongoId == action.payload.data.mongoId ?
                    {
                        ...elem,
                        order: {
                            ...elem.order,
                            ...action.payload.data,
                        },
                    } 
                    : elem
                )
            }
        }
    }

    case 'DELIVERY_CHANGE_ORDER_PAYMENT_METHOD': {
        return {
            ...state,
            deliveryRoute: {
                ...state.deliveryRoute,
                deliveryRoutes: state.deliveryRoute.deliveryRoutes.map((elem) => elem.order.mongoId == action.payload.Id ?
                    {
                        ...elem,
                        order: {
                            ...elem.order,
                            paymentMethod: action.payload.paymentMethod
                        },
                    } 
                    : elem
                )
            }
        }
    }


    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_UPDATE_STATUS_P: {
        return {
            ...state,
            loading: true
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_UPDATE_STATUS_F: {
        return {
            ...state,
            deliveryRoute: {
                ...state.deliveryRoute,
                status: action.payload.status,
            },
            loading: false
        }
    }

    case DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_UPDATE_STATUS_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.response
        }
    }

    default:
        return state;
    }
}


export {
  deliveryRoute
}

