const initialState = {
    filteredData: [],
    filterError: {},
    minMaxValues: {},
    minMaxError: {},
  }
  
  
const dataFilter = ( state = initialState, action = {}) => {

    switch (action.type) {

        case 'DATA_FILTER_F': {

            return {
                ...state,
                filteredData: action.payload
            }

        }

        case 'DATA_FILTER_R': {

            return {
                ...state,
                filterError: action.payload
            }

        }

        case 'DATA_MIN_MAX_F': {
            return {
                ...state,
                minMaxValues: action.payload
            }

        }

        case 'DATA_MIN_MAX_R': {

            return {
                ...state,
                minMaxError: action.payload
            }

        }

        default:
                return state;
    }
}

export {
    dataFilter
}
  