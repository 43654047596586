export default class {
    constructor () {
      this.locale = ''
      this.name = ''
      this.description = ''
      this.sortOrder = 1
      this.image = ''
      this.directory = ''
      this.code = ''
      this.language = ''
      this.status = true
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Language name',
                    field: 'name',
                    label: true,
                    type: 'textarea',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Name',
                    min: 2,
                    max: 200
                },
                {
                    name: 'Code',
                    field: 'code',
                    label: true,
                    type: 'textarea',
                    color: '',
                    value: '',
                    required: true,
                    tab: '1',
                    message: 'Oops! Fill in the Code',
                    min: null,
                    max: 200 
                },
                {
                    name: 'Locale',
                    field: 'locale',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Locale.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Status',
                    field: 'status',
                    tag: 'switch',
                    label: true,
                    tab: '1',
                    type: 'switch',
                    color: '',
                },
                {
                    name: 'Sort Order',
                    field: 'sortOrder',
                    label: true,
                    type: 'number',
                    color: '',
                },
            ] 
        }, 
        { 
            name: 'Icon',
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Code',
                    field: 'icon',
                    label: true,
                    type: 'textarea',
                    color: '',
                    value: '',
                    required: false,
                    tab: '2',
                    message: 'Oops! Fill in the Code'
                }
            ]  
        }, 
        // { 
        //     name: 'SEO',
        //     color: '#000000',
        //     count: 0,
        //     inputs: [
        //         {
        //             name: 'Name',
        //             field: 'name',
        //             label: true,
        //             type: 'textarea',
        //             color: '',
        //             value: '',
        //             tab: '3',
        //             required: true,
        //             message: 'Oops! Fill in the Name',
        //             min: 8,
        //             max: 200
        //         }
        //     // {
        //     //   name: 'language Name',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     // {
        //     //   name: 'locale',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     // {
        //     //   name: 'sortOrder',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     ] 
        // },
        // { 
        //     name: 'Images',
        //     color: '#000000',
        //     count: 0,
        //     inputs: [
        //     // {
        //     //   name: 'language Name',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     // {
        //     //   name: 'locale',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     // {
        //     //   name: 'sortOrder',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     ] 
        // }, 
        // { 
        //     name: 'Design',
        //     color: '#000000',
        //     count: 0,
        //     inputs: [
        //     // {
        //     //   name: 'language Name',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     // {
        //     //   name: 'locale',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     // {
        //     //   name: 'sortOrder',
        //     //   field: '',
        //     //   label: true,
        //     //   type: 'textarea'
        //     // },
        //     ] 
        // } 
      ]
    }
  }