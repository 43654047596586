import  {DIALOG_TYPES} from '../types'

const initialState = {
    isVisible: false,
    content: 'login',
    message: '',
    hideDialogHeader: false,
};

const dialog = (state = initialState, action = {}) => {
    switch (action.type) {
        case DIALOG_TYPES.SHOW_MODAL:
            return showHideModal(state, true);

        case DIALOG_TYPES.HIDE_MODAL:
            return {
                ...state,
                isVisible: false,
                hideDialogHeader: false,
            }

        case DIALOG_TYPES.SET_MODAL_CONTENT: {
            return {
                ...state,
                content: action.payload
            };
        }
        case DIALOG_TYPES.SET_AND_SHOW_MODAL: {
            return {
                ...state,
                ...action.payload,
                isVisible: true,
            }
        }

        case DIALOG_TYPES.SHOW_DIALOG_WITH_MESSAGE: {
            return {
                ...state,
                isVisible: true,
                content: action.payload.content,
                message: action.payload.message,
                phone: action.payload.phone
            }
        }

        default:
            return state;
    }
}

function showHideModal(state, isVisible) {
    return {
        ...state,
        isVisible
    }
}

export {
    dialog
}
