import {optionsService} from "../services/optionsService";
import {OPTIONS_TYPES} from "../types";
import { Notification } from 'element-react'


/**
 * @name getAll
 * @returns {function(*)}
 */
const getAll = (token) => {
  return dispatch => {
    return optionsService.getAll(token).then(options => {
      return dispatch({
        type: OPTIONS_TYPES.GET_ALL_OPTIONS,
        payload : options
      })
    })
  }
};

/**
 * @name getAllRemoved
 * @returns {function(*)}
 */
const getAllRemoved = (token) => {
  return dispatch => {
    return optionsService.getAllRemoved(token).then(options => {
      return dispatch({
        type: OPTIONS_TYPES.GET_ALL_REMOVED_OPTIONS,
        payload : options
      })
    })
  }
};

/**
 * @name getItem
 * @param id
 * @returns {function(*)}
 */
const getItem = (id,token) => {
  return dispatch => {
    return optionsService.getItem(id,token).then(option => {
      return dispatch({
        type: OPTIONS_TYPES.GET_OPTION_ITEMS,
        payload : option
      })
    })
  }
};

/**
 * @name selectItem
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : OPTIONS_TYPES.SELECT_OPTION_ITEMS,
      payload : elem
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return optionsService.getPaginationNotRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: OPTIONS_TYPES.GET_PAG_NOT_REMOVED_OPTIONS,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return optionsService.getPaginationRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: OPTIONS_TYPES.GET_PAG_REMOVED_OPTIONS,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt) => {
  return dispatch => {
    optionsService.remove(elem.optionId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: OPTIONS_TYPES.DELETE_OPTION_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    optionsService.recover(elem.optionId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: OPTIONS_TYPES.RECOVER_OPTION_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    optionsService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: OPTIONS_TYPES.ARCHIVE_OPTION_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    optionsService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: OPTIONS_TYPES.BULK_RECOVER_OPTION_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const getOptionWithRelation = (value, jwt) => {
  return dispatch => {
    optionsService.getWithRelation(value, jwt).then((data) => {
      return dispatch({
        type: OPTIONS_TYPES.GET_WITH_RELATION,
        payload : data
      })
    })
  }
};

const destroyOption = ( token, option ) => {

  return  dispatch => {
    optionsService.destroy(token , option.optionId)
     .then(response => {

      if (response.data.status) {

          Notification.success( {
            title: 'option ',
            message: `${option.source} option succesfuly deleted `
          }, 20 )

          dispatch( {
            type: OPTIONS_TYPES.DESTROY_OPTION,
            payload: option
        } )

      } else {

        Notification.error( {
          title: 'Option!',
          message: `option not deleted`
        }, 20) 

      }
     }) 
    }
  }

/**
  * @name bulkDestroy
  * @param elem
  * @returns {function(*)}
*/
 const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    optionsService.bulkDestroy(ids,  jwt).then((data) => {
      return dispatch({
        type: OPTIONS_TYPES.BULK_DESTROY_OPTION_ITEMS,
        payload : data
      })
    })
  }
};




export {getAll, getAllRemoved, getItem, selectItem, getPaginationNotRemoved, getPaginationRemoved, deleteItem, recoverItem,  bulkDelete, bulkRecover, getOptionWithRelation, destroyOption, bulkDestroy}