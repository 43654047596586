const ADD_DELIVERY_ROUTE_P = 'ADD_DELIVERY_ROUTE_P'
const ADD_DELIVERY_ROUTE_F = 'ADD_DELIVERY_ROUTE_F'
const ADD_DELIVERY_ROUTE_R = 'ADD_DELIVERY_ROUTE_R'
const DELIVERY_ROUTE_PAG_F = 'DELIVERY_ROUTE_PAG_F'
const DELIVERY_ROUTE_PAG_R = 'DELIVERY_ROUTE_PAG_R'
const DELIVERY_ROUTE_SIZE_CHANGE = 'DELIVERY_ROUTE_SIZE_CHANGE'
const DELIVERY_ROUTE_PAGE_CHANGE = 'DELIVERY_ROUTE_PAGE_CHANGE'
const DELIVERY_ROUTE_FILETR_TABE_CHANGE = 'DELIVERY_ROUTE_FILETR_TABE_CHANGE'
const DELIVERY_ROUTE_UPDATE_F = 'DELIVERY_ROUTE_UPDATE_F'
const DELIVERY_ROUTE_UPDATE_R = 'DELIVERY_ROUTE_UPDATE_R'
const DELIVERY_ROUTE_CHECKBOX_CHANGE = 'DELIVERY_ROUTE_CHECKBOX_CHANGE'
const DELIVERY_ROUTE_BULK_ARCIVE_F = 'DELIVERY_ROUTE_BULK_ARCIVE_F'
const DELIVERY_ROUTE_BULK_ARCIVE_R = 'DELIVERY_ROUTE_BULK_ARCIVE_R'
const DELIVERY_ROUTE_BULK_DESTROY_F = 'DELIVERY_ROUTE_BULK_DESTROY_F'
const DELIVERY_ROUTE_BULK_DESTROY_R = 'DELIVERY_ROUTE_BULK_DESTROY_R'
const DELIVERY_ROUTE_TAB_PANEL_CHANGE = 'DELIVERY_ROUTE_TAB_PANEL_CHANGE'
const DELIVERY_ROUTE_DATA_FILTER = 'DELIVERY_ROUTE_DATA_FILTER'
const DELIVERY_ROUTE_DELETE_F = 'DELIVERY_ROUTE_DELETE_F'
const DELIVERY_ROUTE_FILTER_IS_OPEN = 'DELIVERY_ROUTE_FILTER_IS_OPEN'
const DELIVERY_ROUTE_DATA_FILTER_SEARCH = 'DELIVERY_ROUTE_DATA_FILTER_SEARCH'
const DELIVERY_ROUTE_SELECT_FILTER_TYPES = 'DELIVERY_ROUTE_SELECT_FILTER_TYPES'
const DELIVERY_ROUTE_LANGUAGE_PANEL_CHANGE = 'DELIVERY_ROUTE_LANGUAGE_PANEL_CHANGE'
const DELIVERY_ROUTE_GET_ORDER = 'DELIVERY_ROUTE_LANGUAGE_GET_ORDER'
const DELIVERY_ROUTE_DATA_FILTER_ALL_SEARCH_RESET = 'DELIVERY_ROUTE_DATA_FILTER_ALL_SEARCH_RESET'
const UPDATE_DELIVERY_ROUTE = 'UPDATE_DELIVERY_ROUTE'
const DELIVERY_ROUTE_GET_ONE_P = 'DELIVERY_ROUTE_GET_ONE_P'
const DELIVERY_ROUTE_GET_ONE_F = 'DELIVERY_ROUTE_GET_ONE_F'
const DELIVERY_ROUTE_GET_ONE_R = 'DELIVERY_ROUTE_GET_ONE_R'
const DELIVERY_ROUTE_MOBILE_FILTER_IS_OPEN = 'DELIVERY_ROUTE_MOBILE_FILTER_IS_OPEN'
const DELIVERY_ROUTE_NEW = 'DELIVERY_ROUTE_NEW'
const DELIVERY_ROUTE_UPDATE_STATUS_P = 'DELIVERy_ROUTE_UPDATE_STATUS_P'
const DELIVERY_ROUTE_UPDATE_STATUS_F = 'DELIVERY_ROUTE_UPDATE_STATUS_F'
const DELIVERY_ROUTE_UPDATE_STATUS_R = 'DELIVERY_ROUTE_UPDATE_STATUS_R'
const DELIVERY_ROUTE_SEND_NOTIFICATION_F = 'DELIVERY_ROUTE_SEND_NOTIFICATION_F'
const DELIVERY_ROUTE_SEND_NOTIFICATION_R = 'DELIVERY_ROUTE_SEND_NOTIFICATION_R'

export {
    ADD_DELIVERY_ROUTE_P,
    ADD_DELIVERY_ROUTE_F,
    ADD_DELIVERY_ROUTE_R,
    DELIVERY_ROUTE_PAG_F,
    DELIVERY_ROUTE_PAG_R,
    DELIVERY_ROUTE_SIZE_CHANGE,
    DELIVERY_ROUTE_PAGE_CHANGE,
    DELIVERY_ROUTE_FILETR_TABE_CHANGE,
    DELIVERY_ROUTE_UPDATE_F,
    DELIVERY_ROUTE_UPDATE_R,
    DELIVERY_ROUTE_CHECKBOX_CHANGE,
    DELIVERY_ROUTE_BULK_ARCIVE_F,
    DELIVERY_ROUTE_BULK_ARCIVE_R,
    DELIVERY_ROUTE_BULK_DESTROY_R,
    DELIVERY_ROUTE_BULK_DESTROY_F,
    DELIVERY_ROUTE_TAB_PANEL_CHANGE,
    DELIVERY_ROUTE_DATA_FILTER,
    DELIVERY_ROUTE_DELETE_F,
    DELIVERY_ROUTE_FILTER_IS_OPEN,
    DELIVERY_ROUTE_DATA_FILTER_SEARCH,
    DELIVERY_ROUTE_SELECT_FILTER_TYPES,
    DELIVERY_ROUTE_LANGUAGE_PANEL_CHANGE,
    DELIVERY_ROUTE_GET_ORDER,
    DELIVERY_ROUTE_DATA_FILTER_ALL_SEARCH_RESET,
    UPDATE_DELIVERY_ROUTE,
    DELIVERY_ROUTE_GET_ONE_P,
    DELIVERY_ROUTE_GET_ONE_F,
    DELIVERY_ROUTE_GET_ONE_R,
    DELIVERY_ROUTE_MOBILE_FILTER_IS_OPEN,
    DELIVERY_ROUTE_NEW,
    DELIVERY_ROUTE_UPDATE_STATUS_P,
    DELIVERY_ROUTE_UPDATE_STATUS_F,
    DELIVERY_ROUTE_UPDATE_STATUS_R,
    DELIVERY_ROUTE_SEND_NOTIFICATION_F,
    DELIVERY_ROUTE_SEND_NOTIFICATION_R
}
