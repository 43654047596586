import React, {useState, useMemo, useEffect} from 'react';
import config from '../../../config/app'
import {
    Badge, Dropdown, DropdownToggle,
} from 'reactstrap';
import { notificationActions, chatActions, orderActions } from "../../redux-modules/actions";
import { connect}  from "react-redux";
import { useSocket } from "../../redux-modules/services/socket";
import { useDispatch } from 'react-redux';
import NotificationPopup from './NotificationPopup';
const { REACT_APP_STAGE } = process.env;

function Notification(props) {

    const socketChat = useSocket(config['CHAT'], 
        {
            path: REACT_APP_STAGE == 'development' ? '' : '/chat_socket', 
            transports: ['websocket'], 
            query: `token=${localStorage.getItem('token')}`, 
            forceNew: true 
        }
    )

    const socket = useSocket(config['CUSTOMER_SOCKET'], 
        {
            path: REACT_APP_STAGE == 'development' ? '' : "/customer_socket",
            query: `token=${localStorage.getItem('token') || ''}`, 
            forceNew: true 
        }
    )

    const socketOrder = useSocket(config['ORDER_SOCKET'], 
        {
            path: REACT_APP_STAGE == 'development' ? '' : "/order_socket",
            query: `token=${localStorage.getItem('token')}`,
            reconnect: true 
        }
    )

    const socketScrape = useSocket(config['SCRAPING_SOCKET'], 
        {
            path: REACT_APP_STAGE == 'development' ? '' : "/scrape_socket",
            query: `token=${localStorage.getItem('token')}`,
            reconnect: true 
        }
    )

    const socketOrderApi = useSocket(config['ORDER_API_SOCKET'], 
        {
            path: REACT_APP_STAGE == 'development' ? '/' : "/order_api_socket",
            query: `token=${localStorage.getItem('token')}`,
            reconnect: true 
        }
    )

    const socketPayment = useSocket(config['PAYMENT_SOCKET'], 
        {
            path: REACT_APP_STAGE == 'development' ? '' : "/payment_socket",
            // transports: ['websocket'], 
            query: `token=${localStorage.getItem('token')}`,
            reconnect: true 
            // forceNew: true 
        }
    )

    const socketSms = useSocket(config['SMS_SOCKET'], 
        {
            path: REACT_APP_STAGE == 'development' ? '' : "/sms_socket",
            query: `token=${localStorage.getItem('token')}`,
            reconnect: true 
        }
    )
    
    const { 
        mainNotifications,
        mainChatNotifications, 
        loading, 
        fetchNotifications, 
        addNotification, 
        destroyNotification,
        updateNotification,
        mainNotificationsCount,
        allRead 
    } = props
    const [isOpen, setToggle] = useState(false)
    const [connected, setConnected] = useState(false);
    const dispatch = useDispatch();

    console.log(connected, "connected")

    useMemo(() => {
        
        const eventHandler = () => setConnected(true);

        if (socketChat) {
            socketChat.on('connect', eventHandler);
            socketChat.on('CHAT_NOTIFICATION', (data) => {
                addNotification(data)
                playSound('/assets/sound/piece-of-cake-611.mp3')
            })
            props.saveSocket(socketChat)
        }

        if (socket) {
            socket.on('connect', eventHandler);
            socket.on('UPDATE-SALE-STATUS', (data) => {
                addNotification(data)
                playSound('/assets/sound/to-the-point.mp3')
            })
            socket.on('DISABLE-USER-STATUS', (_data) => {
                props.history.push('/')
            })
        }

        if (socketOrder) {
            socketOrder.on('connect', eventHandler);
            socketOrder.on('UPDATE-SALE-STATUS', (data) => {
                addNotification(data)
                playSound('/assets/sound/to-the-point.mp3')
            })
            socketOrder.on('DISABLE-USER-STATUS', (_data) => {
                props.history.push('/')
            })
        }

        if (socketScrape) {
            socketScrape.on('connect', eventHandler);
            socketScrape.on('UPDATE-SALE-STATUS', (data) => {
                addNotification(data)
                playSound('/assets/sound/to-the-point.mp3')
            })
        }

        if (socketOrderApi) {
            socketOrderApi.on('connect', eventHandler);
            socketOrderApi.on('UPDATE-SALE-STATUS', (data) => {
                addNotification(data)
                if(data.soundUrl) {
                    playSound(config['MEDIA'] + 'files/download?file=' + data.soundUrl)
                } else {
                    playSound('/assets/sound/to-the-point.mp3')
                }
            })
            socketOrderApi.on('DISABLE-USER-STATUS', (_data) => {
                props.history.push('/')
            })
        }

        if (socketPayment) {
            socketPayment.on('connect', eventHandler);
            socketPayment.on('UPDATE-PAYMENT-STATUS', (data) => {
                addNotification(data)
                playSound('/assets/sound/to-the-point.mp3')
            })
        }

        if (socketSms) {
            socketSms.on('connect', eventHandler);
            socketSms.on('inbound', (data) => {
                addNotification(data._doc)
                dispatch({
                    type: 'SMS_INBOUND',
                    payload: data
                })
                if(data.soundUrl) {
                    playSound(config['MEDIA'] + 'files/download?file=' + data.soundUrl)
                } else {
                    playSound('/assets/sound/to-the-point.mp3')
                }
            })
        }

    }, [socket, socketPayment, socketOrder, socketSms, socketChat, socketScrape])

    useEffect(() => {

        fetchNotifications(1, 10, false);

    }, []);

    function toggle() {
        setToggle(!isOpen)
    }

    function playSound(filename) {
        var mp3Source = '<source src="' + filename + '" type="audio/mpeg">';
        var oggSource = '<source src="' + filename + '" type="audio/ogg">';
        var embedSource = '<embed hidden="true" autostart="true" loop="false" src="' + filename +'">';
        document.getElementById("a1").innerHTML='<audio autoplay="autoplay">' + mp3Source + oggSource + embedSource + '</audio>';
    }

    return (
        <div className="top-bar">
            <div className="dropdown closed">
                <Dropdown isOpen={isOpen} toggle={() => toggle()}>
                    <DropdownToggle
                        className="pointer"
                        tag="span"
                        onClick={() => toggle()}
                        data-toggle="dropdown"  
                        aria-expanded={isOpen}
                    >
                        <i className="icon-bell"></i>
                        {
                            !!(mainNotificationsCount) &&
                            <Badge pill color="danger notification-count">{mainNotificationsCount}</Badge>
                        }
                    </DropdownToggle>

                    <NotificationPopup
                        isOpen = {isOpen}
                        mainNotifications = { [...mainNotifications, ...mainChatNotifications].sort((a, b) => (b.created_at - a.created_at))}
                        loading = {loading}
                        count = {props.mainNotificationsCount}
                        destroyNotification = {destroyNotification}
                        updateNotification = {updateNotification}
                        fetchNotifications = {fetchNotifications}
                        allRead={allRead}
                    />

            </Dropdown>
        </div>
    </div>
    )
 }

const mapStateToProps = (state) => {

    return {

        newOrder: state.newOrder,
        orders: state.newOrders.pagOrder,
        mainNotifications: state.notifications.mainNotifications,
        mainChatNotifications: state.chat.mainNotifications,
        mainNotificationsCount: state.notifications.mainNotificationsCount,
        loading: state.notifications.loading

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        newOrderSocket: (saleGroups) => dispatch(orderActions.newOrder(saleGroups)),
        addNotification: (data) => dispatch(notificationActions.addNotification(data)),
        fetchNotifications: (page, count, seeMore) => dispatch(notificationActions.fetchNotifications(page, count, seeMore)),
        saveSocket: (data) => dispatch ( chatActions.saveSocket(data) ),
        updateNotification: (id) => dispatch(notificationActions.update(id)),
        allRead: () => dispatch(notificationActions.allRead()),
        destroyNotification: (id) => dispatch(notificationActions.destroy(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
