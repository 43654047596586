import { PLAN_TYPES } from '../../types'
import Fields from '../../../business/Plan/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  planSelect: [],
  navItem: new Fields().navBar,
  inputFilters: new Fields().dataFilter,
  oneData: {},
  error: [],
  count: 0,
  isOpen: isMobile ? false : localStorage.getItem('planFilter') == 'true' ? true : false,
  pageNumber: {
    removed: localStorage.getItem('removedPlanPage') || 1,
    notRemoved: localStorage.getItem('notRemovedPlanPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedPlanPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedPlanPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    status: null,
  },
  dataFilter: {
    search: '',
    planId: null,
  },
  loading: false,
  tabPanel: '1',
  checked: false,
  ids: []
};



const plan = ( state = initialState, action = {}) => {

  switch (action.type) {

    case  PLAN_TYPES.PLAN_LIVE_DATA: {
      let data = state.data
      data.pop()
      data = [action.payload, ...data]
      return {
        ...state,
        data: data,
        count: state.count + 1
      }

    }

    case PLAN_TYPES.PLAN_PAG_F: {

        return {
          ...state,
          loading: false,
          data: action.payload.data.map((elem) => state.ids.includes(elem.planId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.planId)),
          count: action.payload.count
        }
    }

    case PLAN_TYPES.PLAN_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case PLAN_TYPES.PLAN_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case PLAN_TYPES.PLAN_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case PLAN_TYPES.PLAN_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case PLAN_TYPES.PLAN_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.planId) })
      
      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case PLAN_TYPES.PLAN_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case PLAN_TYPES.PLAN_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case PLAN_TYPES.PLAN_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload,
          ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
      }
    }


    case PLAN_TYPES.PLAN_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case PLAN_TYPES.PLAN_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new Fields().navBar,
          error: []
      }
    }

    case PLAN_TYPES.PLAN_REQUIRED_VALIDATION_R: {
      state.navItem = new Fields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case PLAN_TYPES.PLAN_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new Fields().navBar
      }
    }

    case PLAN_TYPES.NEW_PLAN: {
      return { 
        ...state,
        navItem: new Fields().navBar,
        error: []
      }
    }

    case PLAN_TYPES.PLAN_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case PLAN_TYPES.PLAN_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case PLAN_TYPES.PLAN_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.planId !== id)
      }
    }

    case PLAN_TYPES.PLAN_FILTER_IS_OPEN: {
      localStorage.setItem('planFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case PLAN_TYPES.PLAN_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case PLAN_TYPES.PLAN_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { 
            products: '',
            author: '',
            status: null,
            dates:  '',
            dateTo: '',
            dateFrom: ''
          }),
          error: []
      }
    }


    case PLAN_TYPES.FETCH_PLAN_SEE_ALL_P: {
      return {
        ...state,
        loading: true
      }
    }

    case PLAN_TYPES.FETCH_PLAN_SEE_ALL_F: {
      const newPlans = []
      action.payload.data.forEach(plan => {
        if (plan.descriptions.length) {
          newPlans.push({ 
            value: plan.planId, 
            label: plan.descriptions[0].name
          })
        }
      })

      let planSelect =  action.search ? newPlans : [...state.planSelect, ...newPlans]
      planSelect = planSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          loading: false,
          planSelect: planSelect,
          count: action.payload.count
      }
    }

    case PLAN_TYPES.FETCH_PLAN_SEE_ALL_R: {
      return {
        ...state,
        loading: false
      }
    }

    case PLAN_TYPES.PLAN_GET_CODE_P: {
      return {
        ...state,
        loading: true
      }
    }

    default:
            return state;
  }
}
export {
  plan
}