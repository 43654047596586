
export default class Permission {

    static getPermission = (permissions, location) => {
        let access = {
            modify: false,
            access: false,
            create: false,
            update: false,
            delete: false,
            archive_and_recover: false
        }

        if (permissions.length) {
            permissions.forEach(elem => {
                if(('/' + elem.value) === location.pathname) {
                    access[elem.type] = true
                }

                if (location.pathname.includes('sale/order') && elem.value.includes('sale/order')) {
                    const pathArray = elem.value.split('/');
                    const lastName = pathArray[pathArray.length - 1];
                    access[lastName] = true
                }
            })
        }

        return access
    };
}