const UPLOAD_FILES_P = 'UPLOAD_FILES_P';
const UPLOAD_FILES_F = 'UPLOAD_FILES_F';
const UPLOAD_FILES_R = 'UPLOAD_FILES_R';
const GET_FILE_P = 'GET_FILE_P';
const GET_FILE_F = 'GET_FILE_F';
const GET_FILE_R = 'GET_FILE_R';

export {
  UPLOAD_FILES_P,
  UPLOAD_FILES_F,
  UPLOAD_FILES_R,
  GET_FILE_P,
  GET_FILE_F,
  GET_FILE_R
}