
const initialState = {
  requiredValidationError: [],
  requiredValidationSuccess: []
};

const validations = ( state = initialState, action = {} ) => {
    switch (action.type) {

        case 'REQUIRED_VALIDATION_F': 

            return {
                ...state,
                requiredValidationError: [],
                requiredValidationSuccess: action.payload 
            }

        case 'REQUIRED_VALIDATION_R': 

            return {
                ...state,
                requiredValidationError: action.payload,
                requiredValidationSuccess: []
            }


    default:
            return state;
  }
}


export {
    validations
}