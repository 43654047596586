export default class {
    constructor () {
      this.businessId = ''
      this.code = ''
      this.address = []
      this.mails = []
      this.dataFilter = [
        {
            name: 'Name',
            field: 'name',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        }
      ]
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Store Name',
                    field: 'name',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 1,
                    required: true,
                    message: 'Oops! Fill in the Name.',
                    min: null,
                    max: 30,
                },
                {
                    name: 'Store Domain',
                    field: 'url',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 1,
                    required: true,
                    message: 'Oops! Fill in the Domain.',
                    min: null,
                    max: 50,
                },
                {
                  name: 'Store Calls',
                  field: 'callsCount',
                  label: true,
                  type: 'text',
                  color: '',
                  value: '',
                  tab: '1',
                  sortOrder: 1,
                  required: true,
                  message: 'Oops! Fill in the Calls.',
                  min: null,
                  max: 50,
              },
            ] 
        }
      ]
    }
  }