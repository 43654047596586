import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'element-theme-default';
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
import {Provider} from 'react-redux';
import {store} from "./projectNulla/redux-modules/store";
import 'react-select/dist/react-select.css';
import jquery from 'jquery';
import { i18n } from 'element-react'
import locale from 'element-react/src/locale/lang/en'
import './scss/style.scss'

i18n.use(locale);

window.React = React;
window.ReactDOM = ReactDOM;
window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>
  , document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
