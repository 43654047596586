export default class {
    constructor () {
      this.from = []
      this.to = []
      this.message = '',
      this.inboundMessage = '',
      this.sendDate = null,
      this.links = [],
      this.phones = [],
      this.texts = [],
      this.customerGroups = [],
      this.campaignName = 'Campaigns'
    }
  }