import {RECURRING_TYPES} from "../../types/index"


const allRecurring = (state = [], action = {}) => {
  if (action.type === RECURRING_TYPES.GET_ALL_RECURRINGS) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedRecurring = (state = [], action = {}) => {
  if (action.type === RECURRING_TYPES.GET_ALL_REMOVED_RECURRINGS) {
    return action.payload;
  } else {
    return state
  }
};

const recurring = (state = {}, action = {}) => {
  if (action.type === RECURRING_TYPES.GET_RECURRING_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedRecurring = (state = {}, action = {}) => {
  if (action.type === RECURRING_TYPES.SELECT_RECURRING_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

export {allRecurring,allRemovedRecurring,recurring,selectedRecurring}