import { USER_GROUP_TYPES } from '../../types'
import UserGroupFields from '../../../system/UserGroups/type'


const initialState = {
  data: [],
  navItem: new UserGroupFields().navBar,
  oneData: new UserGroupFields(),
  error: [],
  allData: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedUserGroupPage') || 1,
    notRemoved: localStorage.getItem('notRemovedUserGroupPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedUserGroupPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedUserGroupPageSize') || 10
  },
  langTab : {
    userGroupId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  dataFilter: {
    name: '',
    nameF: '',
    code: '',
    loacle: '',
    userGroupId: '',
    sortOrder: ''
  },
  tabPanel: '1',
  checked: false,
  ids: [],
  permissionLoading: false,
  permissionGroups: []
};



const userGroups = ( state = initialState, action = {}) => {

  switch (action.type) {

    case USER_GROUP_TYPES.USER_GROUP_PAG_F: {
      return {
        ...state,
        data: action.payload.data.map((elem) => state.ids.includes(elem.userGroupId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
        checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.userGroupId)),
        count: action.payload.count
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_ALL_F: {

      return {
        ...state,
        allData: action.payload
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case USER_GROUP_TYPES.USER_GROUP_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case USER_GROUP_TYPES.USER_GROUP_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case USER_GROUP_TYPES.USER_GROUP_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case USER_GROUP_TYPES.USER_GROUP_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.userGroupId) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case USER_GROUP_TYPES.USER_GROUP_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case USER_GROUP_TYPES.USER_GROUP_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case USER_GROUP_TYPES.USER_GROUP_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_GET_ONE_F: {
      if (action.payload) {
        action.payload.selectedPermissions = []
        if (action.payload.permissions) {
          action.payload.permissions.forEach(permission => {
            action.payload.selectedPermissions.push({
              value: permission.permissionId,
              label: permission.value
            })
          })
        }
      }
      return { 
          ...state,
          oneData: action.payload,
          navItem: new UserGroupFields().navBar,
          error: []
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_REQUIRED_VALIDATION_R: {
      state.navItem = new UserGroupFields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new UserGroupFields().navBar
      }
    }

    case USER_GROUP_TYPES.NEW_USER_GROUP: {
      return { 
          ...state,
          oneData: new UserGroupFields(),
          navItem: new UserGroupFields().navBar,
          error: []
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_DATA_FILTER: {
      return { 
          ...state,
          // dataFilter: Object.assign({}, state.dataFilter, { [action.payload['key']]: action.payload['value'] }),
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.userGroupId !== id)
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_PERMISSIONS_P: {
      return {
        ...state,
        permissionLoading: true 
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_PERMISSIONS_F: {
      return {
        ...state,
        permissionLoading: false,
        permissionGroups: action.payload
      }
    }

    case USER_GROUP_TYPES.USER_GROUP_PERMISSIONS_R: {
      return {
        ...state,
        permissionLoading: false,
        permissionGroups: []
      }
    }

    default:
            return state;
  }
}
export {
    userGroups
}