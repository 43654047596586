import { PAGE_TYPES } from '../../types'

const initialState = {
  loading: false,
  count: 0,
  data: [],
  page: { 
    sections: []
  },
  error: []
};

const pages = ( state = initialState, action = {}) => {

  switch (action.type) {


    case PAGE_TYPES.PAGE_GET_ONE_P: {

      return { 
        ...state,
        loading: true
      }
    }

    case PAGE_TYPES.PAGE_GET_ONE_F: {

      return { 
        ...state,
        loading: false,
        page: action.payload,
      }
    }

    case PAGE_TYPES.PAGE_GET_ONE_R: {

      return { 
        ...state,
        loading: false,
        error: action.payload
      }
    }


    case PAGE_TYPES.FETCH_PAGE_SEE_ALL_P: {
      return {
        ...state,
        loading: true
      }
    }

    case PAGE_TYPES.FETCH_PAGE_SEE_ALL_F: {
      const newPages = []
      action.payload.data.forEach(page => {
        newPages.push({ 
          value: page.pageId, 
          label: page.title,
          sections: page.sections
        })
      })

      let pagesSelect = action.search ? newPages : [...state.data, ...newPages]
      pagesSelect = pagesSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
        ...state,
        loading: false,
        data: pagesSelect,
        count: action.payload.count
      }
    }

    case PAGE_TYPES.FETCH_STORE_SEE_ALL_R: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    default:
        return state;
  }
}
export {
  pages
}