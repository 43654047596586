import React, { useState, useEffect, useRef, Fragment } from "react";
import Dialler from "./Dialler";
import { connect } from 'react-redux';
import Incoming from "./Incoming";
import "./Phone.scss";
import states from "./states";
import OuntboundModal from './OuntboundModal';


const Phone = ({ twilioDevice, webRTCall }) => {
    const [state, setState] = useState(states.CONNECTING);
    const [code, setCode] = useState("");
    const [connections, setConnections] = useState([]);
    const [conn, setConn] = useState(null);
    const [localStream, setLocalStream] = useState(null);
    const audioRef = useRef();

    const getMediaStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setLocalStream(stream);
        } catch (error) {
            console.error('Error accessing user media:', error);
        }
    };
    
    useEffect(() => {
  
        getMediaStream();
  
    }, []); 

    const setupTwilioDevice = () => {
        if (twilioDevice) {
            twilioDevice.on("ready", () => {
                setState(states.READY);
            });
            
            twilioDevice.on("connect", connection => {
                if (connection.message.To) {
                    setConn(connection);
                }
                setState(states.ON_CALL);
            });
        
            twilioDevice.on("disconnect", () => {
                setState(states.READY);
                setConn(null);
            });
    
            twilioDevice.on("incoming", connection => {
                setState(states.INCOMING);
                setConnections(prevState => [...prevState, connection]);
    
                connection.on("reject", () => {
                    setConnections(prevConnections => prevConnections.filter(conn => conn.outboundConnectionId !== connection.outboundConnectionId));
                });
    
                connection.on("disconnect", () => {
                    setConnections(prevConnections => prevConnections.filter(conn => conn.outboundConnectionId !== connection.outboundConnectionId));
                });
            });
        
            twilioDevice.on("cancel", connection => {
                setConnections(prevConnections => prevConnections.filter(conn => conn.outboundConnectionId !== connection.outboundConnectionId));
            });
        
            twilioDevice.on("reject", connection => {
                setConnections(prevConnections => prevConnections.filter(conn => conn.outboundConnectionId !== connection.outboundConnectionId));
            });
        }
    }

    useEffect(() => {
        setupTwilioDevice();
    }, [twilioDevice]);

    const handleHangup = () => {

        if (webRTCall) {
            webRTCall.hangup();
        } else if(conn) {
            conn.disconnect();
        }

    };

    useEffect(() => {
        if (audioRef.current && webRTCall && webRTCall.remoteStream) {
            audioRef.current.srcObject = webRTCall.remoteStream;
        }
    }, [webRTCall]);
    
    function toggleMute(muted) {
        
        if (webRTCall) {
            if (muted) {
                webRTCall.muteAudio();
            } else {
                webRTCall.unmuteAudio();
            }
        } else if (conn) {
            conn.mute(muted);
        }

    }

    return (
        <Fragment>
            {localStream && <audio srcObject={localStream} autoPlay />}
            <audio
                ref={audioRef}
                autoPlay
                controls={false}
                aria-label="Active call"
            />

            {
                connections.map((connection, i) => 
                    <Incoming
                        key={i}
                        connection={connection}
                    >
                        <Dialler
                            phone={false} 
                            pleaceholder="code" 
                            number={code} 
                            connection={connection}
                            setCode={setCode}
                        />
                    </Incoming>
                )
            }

            {
                (webRTCall || conn) &&
                <OuntboundModal
                    connection={conn || webRTCall}
                    handleHangup={handleHangup}
                    toggleMute={toggleMute}
                />
            }
        </Fragment>
    );
};

/**
    * @name mapStateToProps
    * @param state
    * @returns {{icons}}
*/
function mapStateToProps(state) {

    return {
        account: state.account,
        loading: state.tickets.loading,
        twilioDevice: state.tickets.twilioDevice,
        webRTCall: state.tickets.webRTCall,
        data: state.tickets.data,
        count: state.tickets.count
    }
  
}

/**
    * @name mapDispatchToProps
    * @param dispatch
    * @returns {{getFolders}}
*/
function mapDispatchToProps(_dispatch) {

    return {}

}

export default connect(mapStateToProps,mapDispatchToProps)(Phone)
