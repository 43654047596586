import React, { useEffect } from 'react';
import classnames from 'classnames';
import "./CallModal.scss"
import './CallModal.css';


function CallModal({ status, chatRoomID, rejectCall, contactUser, userAvatar, addCallConfig }) {

  function acceptWithVideo (video) {
    addCallConfig({ audio: true, video, isCaller: false, chatRoomID: chatRoomID });
  }


  useEffect(() => {
    if (status === 'active') {
      playRingtone();
    } else {
      stopRingtone();
    }
  }, [status]);


  function playRingtone() {
    const audio = document.getElementById('a2');
    if (audio) {
      audio.play();
    }
  }

  
  function stopRingtone() {
    const audio = document.getElementById('a2');
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  }


  return (
    <div className={classnames('call-modal', status)}>
      <div className="target-avatar">
        <img src={userAvatar} alt="contact-user"></img>
      </div>
      <p>
        <span className="caller">{`${contactUser}`}</span>
      </p>
      <span className="incoming">Incoming call...</span>
      <button
        type="button"
        className="btn-action fa fa-video-camera"
        onClick={() => acceptWithVideo(true)}
      />
      <button
        type="button"
        className="btn-action fa fa-phone"
        onClick={() => acceptWithVideo(false)}
      />
      <button
        type="button"
        className="btn-action hangup fa fa-phone"
        onClick={rejectCall}
      />
    </div>
  );
}


export default CallModal;