export default class {
    constructor () {
      this.storeId = ''
      this.name = ''
      this.url = ''
      this.businessId = ''
      this.trackingId = ''
      this.adsId = ''
      this.companyName = ''
      this.businessType = ''
      this.favoriteColor = ''
      this.elasticIndex = ''
      this.licenseType = ''
      this.storeIndustry = ''
      this.googleBot = false
      this.status = false
      this.verified = false
      this.main = false
      this.mails = []
      this.users = []
      this.greetingDialog = true
      this.franchises = []
      this.languages = []
      this.orderDetails = {
        minPrice: 5000,
        deliveryFee: 5,
        minOrderPrice: 70
      },
      this.tax = {
        parcent: 0,
        beforeDiscount: false
      }
      this.social = []
      this.orderMailTemplate = {
        type: 1,
        borderColor: "#F3F3F3",
        textColor: "#F1A55A",
        buttonColor: "#000",
        buttonTextColor: "#fff",
        callbackLink: "/shopping"
      },
      this.address = [
        {
          address :"",
          state: "",
          regison: "",
          postCode: "",
          phone: "",
          email: "",
          city: "",
          country: "",
          isDefault : true,
          location: {
            lat: 34.123109, 
            lng: -118.49966
          },
        }
      ],
      this.currency = [2],
      this.operation = {
        monday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        tuesday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        wednesday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        thursday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        friday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        saturday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        sunday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        }
      },
      this.hoursOperation = "",
      this.additionalInformation = {
        onlxineOrder: null,
        dogsAllowed: null,
        applePay: null,
        offersDelivery: null,
        creditCards: null,
        googlePay: null,
        wheelchairAccessible: null,
        acceptsCryptocurrency: null,
        appointmentOnly: null,
        parking: {
         valet: false,
          garage: false,
          street: false,
          provatLot: false,
          validated: false
        },
        bikeParking: null,
        offersTakeout: null,
        openToAll: null,
        contactlessDelivery: null,
        curbsidePickup: null,
        womenOwned: null
      },
      this.policy = [{
        languageId: 1,
        privacy: "",
        terms: ""
      }],
      this.dataFilter = [
        {
          name: 'Name',
          field: 'name',
          tag: 'input',
          label: true,
          type: 'text',
          color: '',
          value: ''
        },
        {
          name: 'Address',
          field: 'address',
          tag: 'input',
          label: true,
          type: 'text',
          color: '',
          value: ''
        }
      ]
    }
  }