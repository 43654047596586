import { SUPPLIER_TYPES } from '../../types'
import Fields from '../../../inventory/Supplier/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  loading: false,
  error: {},
  data: [],
  count: 0,
  supplierSelect: [],
  navItem: new Fields().navBar,
  inputFilters: new Fields().dataFilter,
  oneData: new Fields(),
  error: [],
  isOpen: isMobile ? false : localStorage.getItem('supplierFilter') == 'true' ? true : false,
  pageNumber: {
    removed: localStorage.getItem('removedSupplierPage') || 1,
    notRemoved: localStorage.getItem('notRemovedSupplierPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedSupplierPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedSupplierPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    status: null,
  },
  dataFilter: {
    search: '',
    supplierId: null,
  },
  loading: false,
  tabPanel: '1',
  checked: false,
  ids: []
};

const supplier = ( state = initialState, action = {}) => {

  switch (action.type) {

    case  SUPPLIER_TYPES.SUPPLIER_LIVE_DATA: {
      let data = state.data
      data.pop()
      data = [action.payload, ...data]
      return {
        ...state,
        data: data,
        count: state.count + 1
      }

    }

    case SUPPLIER_TYPES.SUPPLIER_PAG_F: {

        return {
          ...state,
          loading: false,
          data: action.payload.data.map((elem) => state.ids.includes(elem.supplierId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.supplierId)),
          count: action.payload.count,
          balance: action.payload.balance
        }
    }

    case SUPPLIER_TYPES.SUPPLIER_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case SUPPLIER_TYPES.SUPPLIER_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case SUPPLIER_TYPES.SUPPLIER_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case SUPPLIER_TYPES.SUPPLIER_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case SUPPLIER_TYPES.SUPPLIER_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.supplierId) })
      
      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case SUPPLIER_TYPES.SUPPLIER_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case SUPPLIER_TYPES.SUPPLIER_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload,
          ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
      }
    }


    case SUPPLIER_TYPES.SUPPLIER_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new Fields().navBar,
          error: []
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_REQUIRED_VALIDATION_R: {
      state.navItem = new Fields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new Fields().navBar
      }
    }

    case SUPPLIER_TYPES.NEW_SUPPLIER: {
      return { 
        ...state,
        oneData: new Fields(),
        navItem: new Fields().navBar,
        navItem: new Fields().navBar,
        error: []
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.supplierId !== id)
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_FILTER_IS_OPEN: {
      localStorage.setItem('supplierFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case SUPPLIER_TYPES.SUPPLIER_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { 
            products: '',
            author: '',
            status: null,
            dates:  '',
            dateTo: '',
            dateFrom: ''
          }),
          error: []
      }
    }


    case SUPPLIER_TYPES.FETCH_SUPPLIER_SEE_ALL_F: {
      const newSuppliers = []
      action.payload.data.forEach(supplier => {
        newSuppliers.push({ 
          value: supplier.supplierId, 
          label: supplier.name
        })
      })

      let supplierSelect = [...state.supplierSelect, ...newSuppliers]
      supplierSelect = supplierSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          supplierSelect: supplierSelect,
          count: action.payload.count
      }
    }


    case 'CLEAR_SUPPLIER_STATE': {
      return {
        ...state,
        supplierSelect: [],
        count: 0
      }
    }

    default:
      return state;
  }
}

export {
  supplier
}