import { PROMO_CODE_TYPES } from '../../types'
import Fields from '../../../discount/PromoCode/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  navItem: new Fields().navBar,
  inputFilters: new Fields().dataFilter,
  oneData: new Fields(),
  error: [],
  count: 0,
  isOpen: isMobile ? false : localStorage.getItem('promoCodeFilter') == 'true' ? true : false,
  pageNumber: {
    removed: localStorage.getItem('removedPromoCodePage') || 1,
    notRemoved: localStorage.getItem('notRemovedPromoCodePage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedPromoCodePageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedPromoCodePageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    author: '',
    status: null,
    dates:  '',
  },
  dataFilter: {
    search: '',
    promoCodeId: null,
  },
  loading:false,
  tabPanel: '1',
  checked: false,
  ids: []
};



const promoCodes = ( state = initialState, action = {}) => {

  switch (action.type) {

    case  PROMO_CODE_TYPES.PROMO_CODE_LIVE_DATA: {
      let data = state.data
      data.pop()
      data = [action.payload, ...data]
      return {
        ...state,
        data: data,
        count: state.count + 1
      }

    }

    case PROMO_CODE_TYPES.PROMO_CODE_PAG_F: {

        return {
          ...state,
          data: action.payload.data,
          count: action.payload.count
        }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.promoCodeId) })
      
      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case PROMO_CODE_TYPES.PROMO_CODE_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case PROMO_CODE_TYPES.PROMO_CODE_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload,
          ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
      }
    }


    case PROMO_CODE_TYPES.PROMO_CODE_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_GET_ONE_F: {

      action.payload.selectedData = {
        products: [], 
        productGroup: [],
        customers: [],
        customerGroup: [],
        store: []
      }

      if (action.payload.store && action.payload.store.length) {
        const { ids, data } = getSelectedData(action.payload.store, 'storeId', 'name')
        action.payload.store = ids
        action.payload.selectedData['store'] = data
      }

      if (action.payload.customerGroup && action.payload.customerGroup.length) {
        const { ids, data } = getSelectedData(action.payload.customerGroup, 'customerGroupId', 'descriptions')
        action.payload.customerGroup = ids
        action.payload.selectedData['customerGroup'] = data
      }

      if (action.payload.customers && action.payload.customers.length) {
        const data = []
        const ids = []
        action.payload.customers.forEach(elem => {
            data.push({ 
              value: elem.customerId, 
              label: elem.firstname || elem.telephone
            })
            ids.push(elem.customerId)
        })
        action.payload.customers = ids
        action.payload.selectedData['customers'] = data
      }

      return { 
          ...state,
          oneData: action.payload,
          navItem: new Fields().navBar,
          error: []
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_REQUIRED_VALIDATION_R: {
      state.navItem = new Fields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new Fields().navBar
      }
    }

    case PROMO_CODE_TYPES.NEW_PROMO_CODE: {
      return { 
          ...state,
          oneData: new Fields(),
          navItem: new Fields().navBar,
          error: []
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.promoCodeId !== id)
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_FILTER_IS_OPEN: {
      localStorage.setItem('promoCodeFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { 
            author: '',
            status: null,
            dates:  '',
            dateTo: '',
            dateFrom: ''
          }),
          error: []
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_GET_CODE_P: {
      return {
        ...state,
        loading: true
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_GET_CODE_F: {
      return {
        ...state,
        loading: false,
        oneData: {...state.oneData, ...{code: action.payload.code}}
      }
    }

    case PROMO_CODE_TYPES.PROMO_CODE_GET_CODE_R: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    
    default:
            return state;
  }
}

function getSelectedData (values, key1, key2) {
  const data = []
  const ids = []
  values.forEach(elem => {
    if(elem[key2] && elem[key2].length && elem[key2] instanceof Array) {
      data.push({ 
        value: elem[key1], 
        label: elem[key2][0].name
      })
      ids.push(elem[key1])
    } else {
      data.push({ 
        value: elem[key1], 
        label: elem[key2]
      })
      ids.push(elem[key1])
    }
  })
  return {
    ids,
    data
  }
}

export {
  promoCodes
}