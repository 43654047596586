const BULK_SMS_PAG_F = 'BULK_SMS_PAG_F';
const BULK_SMS_PAG_R = 'BULK_SMS_PAG_R';
const BULK_SMS_SIZE_CHANGE = 'BULK_SMS_SIZE_CHANGE'
const BULK_SMS_PAGE_CHANGE = 'BULK_SMS_PAGE_CHANGE'
const BULK_SMS_FILETR_TABE_CHANGE = 'BULK_SMS_FILETR_TABE_CHANGE'
const BULK_SMS_CHECKBOX_CHANGE = 'BULK_SMS_CHECKBOX_CHANGE'
const BULK_SMS_BULK_ARCIVE_F = 'BULK_SMS_BULK_ARCIVE_F'
const BULK_SMS_BULK_ARCIVE_R = 'BULK_SMS_BULK_ARCIVE_R'
const BULK_SMS_BULK_DESTROY_F = 'BULK_SMS_BULK_DESTROY_F'
const BULK_SMS_BULK_DESTROY_R = 'BULK_SMS_BULK_DESTROY_R'
const BULK_SMS_TAB_PANEL_CHANGE = 'BULK_SMS_TAB_PANEL_CHANGE'
const BULK_SMS_DATA_FILTER = 'BULK_SMS_DATA_FILTER'
const BULK_SMS_DELETE_F = 'BULK_SMS_DELETE_F'
const BULK_SMS_FILTER_IS_OPEN = 'BULK_SMS_FILTER_IS_OPEN'
const BULK_SMS_DATA_FILTER_SEARCH = 'BULK_SMS_DATA_FILTER_SEARCH'
const BULK_SMS_SELECT_FILTER_TYPES = 'BULK_SMS_SELECT_FILTER_TYPES'
const BULK_SMS_DATA_FILTER_ALL_SEARCH_RESET = 'BULK_SMS_DATA_FILTER_ALL_SEARCH_RESET'
const BULK_SMS_NEW = 'BULK_SMS_NEW'
const BULK_SMS_SEND_P = 'BULK_SMS_SEND_P'
const BULK_SMS_SEND_F = 'BULK_SMS_SEND_F'
const BULK_SMS_SEND_R = 'BULK_SMS_SEND_R'
const BULK_SMS_BALANCE_P = 'BULK_SMS_BALANCE_P'
const BULK_SMS_BALANCE_F = 'BULK_SMS_BALANCE_F'
const BULK_SMS_BALANCE_R = 'BULK_SMS_BALANCE_R'
const BULK_SMS_GET_ONE_P = 'BULK_SMS_GET_ONE_P'
const BULK_SMS_GET_ONE_F = 'BULK_SMS_GET_ONE_F'
const BULK_SMS_GET_ONE_R = 'BULK_SMS_GET_ONE_R'
const FETCH_BULK_SMS_PHONES_P = 'FETCH_BULK_SMS_PHONES_R'
const FETCH_BULK_SMS_PHONES_F = 'FETCH_BULK_SMS_PHONES_F'
const FETCH_BULK_SMS_PHONES_R = 'FETCH_BULK_SMS_PHONES_R'
const TELNYX_BUSINESS_NUMBER_LIST_P = 'TELNYX_BUSINESS_NUMBER_LIST_P'
const TELNYX_BUSINESS_NUMBER_LIST_F = 'TELNYX_BUSINESS_NUMBER_LIST_F'
const TELNYX_BUSINESS_NUMBER_LIST_R = 'TELNYX_BUSINESS_NUMBER_LIST_R'
const TELNYX_BUSINESS_NUMBER_BUY_P = 'TELNYX_BUSINESS_NUMBER_BUY_P'
const TELNYX_BUSINESS_NUMBER_BUY_F = 'TELNYX_BUSINESS_NUMBER_BUY_F'
const TELNYX_BUSINESS_NUMBER_BUY_R = 'TELNYX_BUSINESS_NUMBER_BUY_R'
const GET_TELNYX_BALANCE_P = 'GET_TELNYX_BALANCE_P'
const GET_TELNYX_BALANCE_F = 'GET_TELNYX_BALANCE_F'
const GET_TELNYX_BALANCE_R = 'GET_TELNYX_BALANCE_R'
const UPDATE_BULK_SMS = 'UPDATE_BULK_SMS'
const BULK_SMS_STATISTICS_P = 'BULK_SMS_STATISTICS_P'
const BULK_SMS_STATISTICS_F = 'BULK_SMS_STATISTICS_F'
const BULK_SMS_STATISTICS_R = 'BULK_SMS_STATISTICS_R'
const FETCH_COUNTRIES_SEE_ALL_P = 'FETCH_COUNTRIES_SEE_ALL_P'
const FETCH_COUNTRIES_SEE_ALL_F = 'FETCH_COUNTRIES_SEE_ALL_F'
const FETCH_COUNTRIES_SEE_ALL_R = 'FETCH_COUNTRIES_SEE_ALL_R'
const BULK_SMS_VALIDATE_P = 'BULK_SMS_VALIDATE_P'
const BULK_SMS_VALIDATE_F = 'BULK_SMS_VALIDATE_F'
const BULK_SMS_VALIDATE_R = 'BULK_SMS_VALIDATE_R'
const GET_SMS_PLAN_P = 'GET_SMS_PLAN_P'
const GET_SMS_PLAN_F = 'GET_SMS_PLAN_F'
const GET_SMS_PLAN_R = 'GET_SMS_PLAN_R'
const CHOOSE_SMS_PLAN_P = 'CHOOSE_SMS_PLAN_P'
const CHOOSE_SMS_PLAN_F = 'CHOOSE_SMS_PLAN_F'
const CHOOSE_SMS_PLAN_R = 'CHOOSE_SMS_PLAN_R'
const CHECH_SMS_PLAN_P = 'CHECH_SMS_PLAN_P'
const CHECH_SMS_PLAN_F = 'CHECH_SMS_PLAN_F'
const CHECH_SMS_PLAN_R = 'CHECH_SMS_PLAN_R'

export {
    BULK_SMS_PAG_R,
    BULK_SMS_PAG_F,
    BULK_SMS_SIZE_CHANGE,
    BULK_SMS_PAGE_CHANGE,
    BULK_SMS_FILETR_TABE_CHANGE,
    BULK_SMS_CHECKBOX_CHANGE,
    BULK_SMS_BULK_ARCIVE_F,
    BULK_SMS_BULK_ARCIVE_R,
    BULK_SMS_BULK_DESTROY_R,
    BULK_SMS_BULK_DESTROY_F,
    BULK_SMS_TAB_PANEL_CHANGE,
    BULK_SMS_DATA_FILTER,
    BULK_SMS_DELETE_F,
    BULK_SMS_FILTER_IS_OPEN,
    BULK_SMS_DATA_FILTER_SEARCH,
    BULK_SMS_SELECT_FILTER_TYPES,
    BULK_SMS_DATA_FILTER_ALL_SEARCH_RESET,
    BULK_SMS_NEW,
    BULK_SMS_SEND_P,
    BULK_SMS_SEND_R,
    BULK_SMS_SEND_F,
    BULK_SMS_BALANCE_P,
    BULK_SMS_BALANCE_F,
    BULK_SMS_BALANCE_R,
    BULK_SMS_GET_ONE_P,
    BULK_SMS_GET_ONE_F,
    BULK_SMS_GET_ONE_R,
    FETCH_BULK_SMS_PHONES_P,
    FETCH_BULK_SMS_PHONES_F,
    FETCH_BULK_SMS_PHONES_R,
    TELNYX_BUSINESS_NUMBER_LIST_P,
    TELNYX_BUSINESS_NUMBER_LIST_F,
    TELNYX_BUSINESS_NUMBER_LIST_R,
    TELNYX_BUSINESS_NUMBER_BUY_P,
    TELNYX_BUSINESS_NUMBER_BUY_F,
    TELNYX_BUSINESS_NUMBER_BUY_R,
    GET_TELNYX_BALANCE_P,
    GET_TELNYX_BALANCE_F,
    GET_TELNYX_BALANCE_R,
    UPDATE_BULK_SMS,
    BULK_SMS_STATISTICS_P,
    BULK_SMS_STATISTICS_F,
    BULK_SMS_STATISTICS_R,
    FETCH_COUNTRIES_SEE_ALL_P,
    FETCH_COUNTRIES_SEE_ALL_F,
    FETCH_COUNTRIES_SEE_ALL_R,
    BULK_SMS_VALIDATE_P,
    BULK_SMS_VALIDATE_F,
    BULK_SMS_VALIDATE_R,
    GET_SMS_PLAN_P,
    GET_SMS_PLAN_F,
    GET_SMS_PLAN_R,
    CHOOSE_SMS_PLAN_P,
    CHOOSE_SMS_PLAN_F,
    CHOOSE_SMS_PLAN_R,
    CHECH_SMS_PLAN_P,
    CHECH_SMS_PLAN_F,
    CHECH_SMS_PLAN_R
}