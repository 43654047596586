import React from "react";
import config from '../../../../../config/app'

function ImageOption (props) {
    const { option, sortArray, product, changeOptions, required }  = props

    function addOption(item, optionValue) {
        if (required) {
            product.activeOptions = product.activeOptions.filter(id => !optionValue.values.find(elem => elem.optionValueId == id))
            product.activeOptions.push(item.optionValueId)
        } else {
            if (!product.activeOptions.includes(item.optionValueId)) {
                product.activeOptions = product.activeOptions.filter(id => !optionValue.values.find(elem => elem.optionValueId == id))
            }
            if (product.activeOptions.includes(item.optionValueId)) {
                product.activeOptions =  product.activeOptions.filter(id => id !== item.optionValueId) 
            } else {
                product.activeOptions.push(item.optionValueId)
            }
        }
        changeOptions(product.activeOptions, product)
    } 

    return (
        <>
            <div className={`radio-options option-type`}>
                <div className="radio-options__title">
                    {option.option.descriptions[0].name} 
                </div>
                {
                    sortArray(option.values).map((item, i) =>
                        !!item.subtract &&  
                        <a 
                            key={i}
                            onClick={() => addOption(item, option)} 
                        >
                            <div 
                                key = {i} 
                                className={product.activeOptions.includes(item.optionValueId) ? "radio-option__item weight-option__item--active" : "radio-option__item"}
                                role="button">
                                <span 
                                    className="weight-option__item__label">
                                        {
                                            item.image &&
                                            <img style={{width: '30px'}} src={config['MEDIA'] + 'image/d/120/' + encodeURIComponent(item.image.url)}/>
                                        }
                                </span>
                            </div>
                        </a>                        
                    )
                }
            </div>
        </>
    )
}

export default ImageOption;