import React, { useEffect } from 'react'
import './PasswordValidation.css'

function PasswordValidation(props) {

    let evaluatePassword = ( password ) => {
        let score = 0;
    
        score = password.length;
        score = ( password.match( /[!]/gmi ) ) ? score + ( password.match( /[!]/gmi ).length * 3 ) : score;
        score = ( password.match( /[A-Z]/gm ) ) ? score + 3 : score;
        score = ( password.match( /[0-9]/gmi ) ) ? score + 3 : score;
    
        return score;
    };

    let scoreToData = ( score ) => {    
        if ( score >= 30 ) {
          return {
            width: '100%',
            color: '#26de81',
            label: 'Strong',
          };
        } else if ( score >= 20 ) {
          return {
            width: '66%',
            color: '#fd9644',
            label: 'Medium',
          };
        } else if (score) {
          return {
            width: '33%',
            color: '#fc5c65',
            label: 'Weak',
          };
        } else {
            return {
                width: '0%',
                color: '#fc5c65',
                label: '',
            };
        }
    };

    useEffect(() => {
        // Get element refs.
        let b = document.querySelector( '.js--password-bar' );
        let t = document.querySelector( '.js--password-text' );
    
        // Convert current value to data.
        let data = scoreToData( evaluatePassword( props.password ) );

        // Update DOM.
        b.style.width = data.width;
        b.style.background = data.color;
        t.innerText = data.label;
    }, [props.password])

    return (
        <div className="password-meter">
            <div className="password-meter__bar">                      
            <div className="password-meter__bar__inner js--password-bar"></div>
        </div>
        <p className="label">Password Strength: <span className="js--password-text"></span></p>
        </div>
    )
}

export default PasswordValidation