const CAMPAIGN_PAG_F = 'CAMPAIGN_PAG_F';
const CAMPAIGN_PAG_R = 'CAMPAIGN_PAG_R';
const CAMPAIGN_SIZE_CHANGE = 'CAMPAIGN_SIZE_CHANGE'
const CAMPAIGN_PAGE_CHANGE = 'CAMPAIGN_PAGE_CHANGE'
const CAMPAIGN_FILETR_TABE_CHANGE = 'CAMPAIGN_FILETR_TABE_CHANGE'
const CAMPAIGN_CHECKBOX_CHANGE = 'CAMPAIGN_CHECKBOX_CHANGE'
const CAMPAIGN_BULK_ARCIVE_F = 'CAMPAIGN_BULK_ARCIVE_F'
const CAMPAIGN_BULK_ARCIVE_R = 'CAMPAIGN_BULK_ARCIVE_R'
const CAMPAIGN_BULK_DESTROY_F = 'CAMPAIGN_BULK_DESTROY_F'
const CAMPAIGN_BULK_DESTROY_R = 'CAMPAIGN_BULK_DESTROY_R'
const CAMPAIGN_TAB_PANEL_CHANGE = 'CAMPAIGN_TAB_PANEL_CHANGE'
const CAMPAIGN_DATA_FILTER = 'CAMPAIGN_DATA_FILTER'
const CAMPAIGN_DELETE_F = 'CAMPAIGN_DELETE_F'
const CAMPAIGN_FILTER_IS_OPEN = 'CAMPAIGN_FILTER_IS_OPEN'
const CAMPAIGN_DATA_FILTER_SEARCH = 'CAMPAIGN_DATA_FILTER_SEARCH'
const CAMPAIGN_SELECT_FILTER_TYPES = 'CAMPAIGN_SELECT_FILTER_TYPES'
const CAMPAIGN_DATA_FILTER_ALL_SEARCH_RESET = 'CAMPAIGN_DATA_FILTER_ALL_SEARCH_RESET'
const CAMPAIGN_GET_ONE_P = 'CAMPAIGN_GET_ONE_P'
const CAMPAIGN_GET_ONE_F = 'CAMPAIGN_GET_ONE_F'
const CAMPAIGN_GET_ONE_R = 'CAMPAIGN_GET_ONE_R'
const CAMPAIGN_DATA_MIN_MAX_F = 'CAMPAIGN_DATA_MIN_MAX_F'
const CAMPAIGN_NEW = 'CAMPAIGN_NEW'
const SMS_REPORT_BULK = 'SMS_REPORT_BULK'

export {
    CAMPAIGN_PAG_R,
    CAMPAIGN_PAG_F,
    CAMPAIGN_SIZE_CHANGE,
    CAMPAIGN_PAGE_CHANGE,
    CAMPAIGN_FILETR_TABE_CHANGE,
    CAMPAIGN_CHECKBOX_CHANGE,
    CAMPAIGN_BULK_ARCIVE_F,
    CAMPAIGN_BULK_ARCIVE_R,
    CAMPAIGN_BULK_DESTROY_R,
    CAMPAIGN_BULK_DESTROY_F,
    CAMPAIGN_TAB_PANEL_CHANGE,
    CAMPAIGN_DATA_FILTER,
    CAMPAIGN_DELETE_F,
    CAMPAIGN_FILTER_IS_OPEN,
    CAMPAIGN_DATA_FILTER_SEARCH,
    CAMPAIGN_SELECT_FILTER_TYPES,
    CAMPAIGN_DATA_FILTER_ALL_SEARCH_RESET,
    CAMPAIGN_GET_ONE_P,
    CAMPAIGN_GET_ONE_F,
    CAMPAIGN_GET_ONE_R,
    CAMPAIGN_DATA_MIN_MAX_F,
    CAMPAIGN_NEW,
    SMS_REPORT_BULK
}