const GET_ALL_OPTIONS = 'GET_ALL_OPTIONS';
const GET_ALL_REMOVED_OPTIONS = 'GET_ALL_REMOVED_OPTIONS';
const GET_OPTION_ITEMS = 'GET_OPTION_ITEMS';
const SELECT_OPTION_ITEMS = 'SELECT_OPTION_ITEMS';
const ADD_OPTION = 'ADD_OPTION';
const DELETE_OPTION = 'DELETE_OPTION';
const RECOVER_OPTION = 'RECOVER_OPTION';
const CHANGE_STATUS = 'CHANGE_STATUS';
const RECOVER_OPTION_ITEMS = 'RECOVER_OPTION_ITEMS';
const DELETE_OPTION_ITEMS = 'DELETE_OPTION_ITEMS';
const GET_PAG_REMOVED_OPTIONS = 'GET_PAG_REMOVED_OPTIONS';
const GET_PAG_NOT_REMOVED_OPTIONS = 'GET_PAG_NOT_REMOVED_OPTIONS';
const BULK_RECOVER_OPTION_ITEMS = 'BULK_RECOVER_OPTION_ITEMS';
const ARCHIVE_OPTION_ITEMS = 'ARCHIVE_OPTION_ITEMS';
const GET_WITH_RELATION = 'GET_WITH_RELATION';
const DESTROY_OPTION = 'DESTROY_OPTION';
const BULK_DESTROY_OPTION_ITEMS = 'BULK_DESTROY_OPTION_ITEMS'
const OPTION_TAB_PANEL_CHANGE = 'OPTION_TAB_PANEL_CHANGE'

export {
  GET_ALL_OPTIONS,
  GET_ALL_REMOVED_OPTIONS,
  GET_OPTION_ITEMS,
  ADD_OPTION,
  DELETE_OPTION,
  RECOVER_OPTION,
  SELECT_OPTION_ITEMS,
  CHANGE_STATUS,
  RECOVER_OPTION_ITEMS,
  DELETE_OPTION_ITEMS,
  GET_PAG_NOT_REMOVED_OPTIONS,
  GET_PAG_REMOVED_OPTIONS,
  BULK_RECOVER_OPTION_ITEMS,
  ARCHIVE_OPTION_ITEMS,
  GET_WITH_RELATION,
  DESTROY_OPTION,
  BULK_DESTROY_OPTION_ITEMS,
  OPTION_TAB_PANEL_CHANGE
}