import  {MANUFACTURER_TYPES} from '../types'
import  {manufacturerService} from '../services/manufacturerService'
import {Notification} from 'element-react'


/**
 * @name selectElem
 * @description select manufacturer item that edit item
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : MANUFACTURER_TYPES.SELECT_MANUFACTURER_ITEMS,
      payload: elem
    })
  }
};


const getItem = (id, token) => {
  return dispatch => {
    return manufacturerService.getItem(id, token).then(data => {
      return dispatch({
        type: MANUFACTURER_TYPES.GET_MANUFACTURER_ITEM,
        payload: data
      })
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return manufacturerService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: MANUFACTURER_TYPES.GET_PAG_NOT_REMOVED_MANUFACTURERS,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return manufacturerService.getPaginationRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: MANUFACTURER_TYPES.GET_PAG_REMOVED_MANUFACTURERS,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const arcive = (ids, jwt) => {
  return dispatch => {
      manufacturerService.bulkDelete(ids,  jwt).then((data) => {
        if (data.success) {
        return dispatch({
          type: MANUFACTURER_TYPES.DELETE_MANUFACTURER_ITEMS,
          payload : ids
        })
      }
    })
  }
};

/**
 * @name recover
 * @param elem
 * @returns {function(*)}
 */
const recover = (ids, jwt) => {
  return dispatch => {
    manufacturerService.bulkRecover(ids,  jwt).then((data) => {
        if (data.success) {
        return dispatch({
          type: MANUFACTURER_TYPES.RECOVER_MANUFACTURER_ITEMS,
          payload : ids
        })
      }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    manufacturerService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: MANUFACTURER_TYPES.ARCHIVE_MANUFACTURER_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    manufacturerService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: MANUFACTURER_TYPES.BULK_RECOVER_MANUFACTURER_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    manufacturerService.bulkDestroy(ids,  jwt).then((_data) => {
      return dispatch({
        type: MANUFACTURER_TYPES.BULK_DESTROY_MANUFACTURER_ITEMS,
        payload : ids
      })
    })
  }
};

const newManufacturer = (language) => {
  return async dispatch => {
      try {
          dispatch({
              type: MANUFACTURER_TYPES.NEW_MANUFACTURER_FULFILLED,
              payload: language
          })
      } catch (e) {
          dispatch({
              type: MANUFACTURER_TYPES.NEW_MANUFACTURER_REJECTED,
              payload: e
          });
      }
  }
}

const saveManufacturer = (manufacturer, jwt, history, discard) => {
  return async dispatch => {
      manufacturerService.create(manufacturer, jwt)
        .then(data => {

          Notification.success({
            title: 'Created',
            message: 'Your manufacturer succesfuly created',
          }, 20)

          if (discard) {
            history.push(`${discard}`)
          } else {
            history.push('/catalog/manufacturer')
          }

          dispatch({
              type: MANUFACTURER_TYPES.SAVE_MANUFACTURER_FULFILLED,
              payload: data
          })
        })
        .catch(error => {
          error = error.response.data.error.error
              error.errors && error.errors['length'] ? error.errors.map(elem => {
                if (!elem.message.includes("id")) {
                Notification.error({
                      title: error.name,
                      message: elem.message
                  }, 20)
                }
              }):
              
              Notification.error({
                title: error.name,
                message: `${error.parent.column}. not null`
            }, 20)   
          })
        }
    }

const fetchManufacturerId = (id, token, language) => {
  return async dispatch => {
      try {
            dispatch({
              type: MANUFACTURER_TYPES.FETCH_MANUFACTURER_PENDING
            })
          const data = await manufacturerService.getItem(id, token)
          dispatch({
              type: MANUFACTURER_TYPES.FETCH_MANUFACTURER_FULFILLED,
              payload: {data, language}
          })
      } catch (e) {
          dispatch({
              type: 'FETCH_MANUFACTURER_REJECTED'
          });
      }
  }
}

const updateManufacturer = (manufacturer, id, token, history, discard) => {
  return async dispatch => {
    try {
      let data = await manufacturerService.edit(manufacturer, id, token)

        Notification.warning({
          title: 'Updated',
          message: 'Your manufacturer succesfuly updated '
        }, 20)

        if (discard) {
          history.push(`${discard}`)
        } else {
          history.push('/catalog/manufacturer')
        }
        dispatch({
            type: MANUFACTURER_TYPES.UPDATE_MANUFACTURER_FULFILLED,
            payload: data
        })
        
    } catch (error) {
        error = error.response.data.error.error
        error.errors && error.errors['length'] ? error.errors.map(elem => {
          if (!elem.message.includes("id")) {
          Notification.error({
                title: error.name,
                message: elem.message
            }, 20)
          }
        }):
        
        Notification.error({
          title: error.name,
          message: `${error.sql}`
      }, 20) 
       
      }
    }
  }

  const changeLangTab = ( id, LangTab ) => {
    return async dispatch => {
          dispatch({
            type: MANUFACTURER_TYPES.LANG_TAB,
            payload: { 
                languageId: id, 
                LangTab: LangTab 
            }
        })
      }
  }


/**
 * @name fetchManufacturerSeeAl
 * @returns {function(*)}
 */
const fetchManufacturerSeeAll = (jwt, page, count) => {
  return dispatch => {
    return manufacturerService.getPaginationNotRemoved(jwt, page, count).then(options => {
      return dispatch({
        type: MANUFACTURER_TYPES.FETCH_MANUFACTURER_SEE_ALL,
        payload : options
      })
    })
  }
};


  const destroyManufacturer = ( token, manufacturer ) => {

    return async dispatch => {

      try {

        const data = await manufacturerService.destroy(token , manufacturer.manufacturerId)
  
          Notification.success( {
            title: 'manufacturer ',
            message: `${manufacturer.name} manufacturer succesfuly deleted `
          }, 20 )

          dispatch( {
              type: MANUFACTURER_TYPES.DESTROY_MANUFACTURER,
              payload: manufacturer,
              data: data
          } )
          
      } catch (e) {

          Notification.error( {
            title: e.name,
            message: `${e.parent.column}`
          }, 20) 
         
        }
      }
    }


    const getTypes = ( token ) => {

      return async dispatch => {
  
        try {
  
          const { data } = await manufacturerService.getTypes(token)
    
          dispatch( {
              type: MANUFACTURER_TYPES.MANUFACTURER_SELECT_FILTER_TYPES,
              payload: data
          } )

        } catch (e) {

          console.log(e)

           
          }
        }
      }


export {
    getItem,  
    selectItem, 
    getPaginationNotRemoved,
    fetchManufacturerSeeAll, 
    getPaginationRemoved, 
    arcive, 
    recover, 
    bulkDelete, 
    bulkRecover, 
    newManufacturer, 
    saveManufacturer, 
    fetchManufacturerId, 
    updateManufacturer, 
    changeLangTab, 
    destroyManufacturer, 
    bulkDestroy,
    getTypes 
}