import React, {Component} from 'react';
import {connect} from 'react-redux';
import OrderList from "./OrderList";
import {orderActions} from '../../redux-modules/actions';

class Orders extends Component {

  render() {

    return (
      <div>
        <OrderList
          account = {this.props.account}
          removedItems={this.props.allRemovedOrder}
          doneItems={this.props.allDoneOrder}
          allOrderList={this.props.allOrderList}
          match={this.props.match}
          location = {this.props.location}
          getAllOrder={()=>this.props.getAllOrder()}
          getAllRemoved={()=>this.props.getAllRemoved()}
          getAllDoneList={()=>this.props.getAllDoneList()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    allDoneOrder:state.allDoneOrder,
    allOrder: state.allOrder,
    allRemovedOrder: state.allRemovedOrder,
    newOrder : state.newOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDoneList: (token) => dispatch(orderActions.getAllDoneList(token)),
    getAllRemoved: (token) => dispatch(orderActions.getAllRemoved(token)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
