import React from "react";

function ColorOption (props) {
    const { option, sortArray, product, changeOptions, required }  = props

    function addOption(item, optionValue) {
        if (required) {
            product.activeOptions = product.activeOptions.filter(id => !optionValue.values.find(elem => elem.optionValueId == id))
            product.activeOptions.push(item.optionValueId)
        } else {
            if (!product.activeOptions.includes(item.optionValueId)) {
                product.activeOptions = product.activeOptions.filter(id => !optionValue.values.find(elem => elem.optionValueId == id))
            }
            if (product.activeOptions.includes(item.optionValueId)) {
                product.activeOptions =  product.activeOptions.filter(id => id !== item.optionValueId) 
            } else {
                product.activeOptions.push(item.optionValueId)
            }
        }
        changeOptions(product.activeOptions, product)
    } 

    return (
        <div className="product-color">
            <div className="radio-options__title">
                {option.option.descriptions[0].name} 
            </div>
            {
                sortArray(option.values).map((item, i) =>
                    !!item.subtract &&  
                    <div
                        key={i}
                        onClick={() => addOption(item, option)} 
                        className={product.activeOptions.includes(item.optionValueId) ? "product-color__item product-color__item_active" : "product-color__item"}
                        style={product.activeOptions.includes(item.optionValueId) ? { borderColor: item.optionValue.descriptions[0].name } : {}}
                    >
                        <div 
                            className={"product-color__inner"} 
                            style={{ background: item.optionValue.descriptions[0].name }}>    
                        </div>
                    </div>
                )
            }
        </div> 
    )
}

export default ColorOption;