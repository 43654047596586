import { useDispatch, useSelector } from "react-redux";
import {hideModal} from "../../redux-modules/actions/dialogActions";
import Modal from "../modal/Modal";
import React from "react";
import CancelOrderDialog from '../modal/CancelOrderDialog';
import StaffDialog from '../modal/StaffDialog';
import InfoDialog from "../modal/InfoDialog";

const map = new Map();

map.set('cancelOrder', <CancelOrderDialog/>)
map.set('staffDialog', <StaffDialog/>)
map.set('infoDialog', <InfoDialog/>);

export default function Dialog() {
    const dispatch = useDispatch();
    let {isVisible, content} = useSelector((state) => state.dialog);
    content = map.get(content);

    const hideDialog = () => dispatch(hideModal());

    return (
        <Modal show={isVisible}
               setVisible={hideDialog}>
            {content}
        </Modal>
    )
}