import  { TICKET_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'

class TicketActions {
    constructor(_props) {
        this.api = config['CALL_API']
        this.rest  = 'tickets'
    }

    pagination= (jwt, page, count) => {
        return async dispatch => {
            try {

                dispatch({
                    type: TICKET_TYPES. TICKET_PAG_P
                })

                const { data } = await axios.get(
                    this.api + this.rest + `/${page}/${count}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: TICKET_TYPES. TICKET_PAG_F,
                    payload: data
                })
            } catch (e) {
                dispatch({
                    type: TICKET_TYPES. TICKET_PAG_R,
                    payload: e
                })
            }
        }
    }


    create = (jwt, body) => {
        return async dispatch => {
            try {

                dispatch({
                    type: TICKET_TYPES.ADD_TICKET_P
                })

                const { data } = await axios.post(
                    this.api + this.rest,
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: TICKET_TYPES.ADD_TICKET_F,
                    payload: data
                })
                
            } catch (e) {
                dispatch({
                    type: TICKET_TYPES.ADD_TICKET_R,
                    payload: e
                })
            }
        }
    }

    callsCount = (_jwt, startDate, endDate) => {
        return async dispatch => {
            try {

                dispatch({
                    type: TICKET_TYPES.CALL_COUNTS_P
                })

                const { data } = await axios.get(
                    `https://handler.projectnulla.info/call/count?start_date=${startDate || '2021-09-15T04:00:00.000Z'}&end_date=${endDate || '2021-09-22T04:00:00.000Z'}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                )

                dispatch({
                    type: TICKET_TYPES.CALL_COUNTS_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: TICKET_TYPES.CALL_COUNTS_R,
                    payload: e
                })
            }
        }
    }
}




export default new TicketActions()