export default class {
    constructor () {
      this.businessId = ''
      this.code = ''
      this.address = []
      this.mails = []
      this.dataFilter = []
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                  name: 'Store Calls',
                  field: 'callsCount',
                  label: true,
                  type: 'text',
                  color: '',
                  value: '',
                  tab: '1',
                  sortOrder: 1,
                  required: true,
                  message: 'Oops! Fill in the Calls.',
                  min: null,
                  max: 50,
              },
            ] 
        }
      ]
    }
  }