export default class {
    constructor () {
      this.title = ''
      this.code = ''
      this.symbolLeft = ''
      this.symbolRight = ''
      this.decimalPlace = ''
      this.deleted = false
      this.status = false
      this.value = 1.1
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Title',
                    field: 'title',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Title.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Code',
                    field: 'code',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Code.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Symbol Left',
                    field: 'symbolLeft',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Symbol Left.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Symbol Right',
                    field: 'symbolRight',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Symbol Right.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Decimal Place',
                    field: 'decimalPlace',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Decimal Place',
                    min: null,
                    max: 1,
                },
                {
                    name: 'Value',
                    field: 'value',
                    tag: 'input',
                    label: true,
                    type: 'number',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Value.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'Currency Status',
                    field: 'status',
                    tag: 'switch',
                    label: true,
                    type: 'boolean',
                    color: '',
                    value: ''
                },
            ] 
        }
      ]
    }
  }