import { CUSTOMER_TYPES } from '../../types'
import CustomerFields from '../../../customer/Customer/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  oneData: new CustomerFields(),
  error: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedCustomerPage') || 1,
    notRemoved: localStorage.getItem('notRemovedCustomerPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedCustomerPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedCustomerPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    name: '',
    emailF: '',
    phone: '',
    orderCountF: null,
    customerGroupF: '',
    statusF: null,
    datesF:  '',
    minOrderCount: '',
    maxOrderCount: '',
    minOrderTotal: '',
    maxOrderTotal: ''
  },
  dataFilter: {
    search: '',
    name: '',
    code: false,
    loacle: false,
    userGroupId: false,
    sortOrder: false
  },
  minMaxValues: {},
  isOpen: isMobile ? false : localStorage.getItem('customerFilter') == 'true' ? true : false,
  tabPanel: '1',
  checked: false,
  ids: [],
  orders: {
    page: 1,
    showEntries: 10,
    data: [],
    count: []
  },
  customerSelect: [],
  orders: [],
  ordersCount: 0,
  reviews: [],
  reviewsCount: 0,
  wishListProduct: [],
  wishListProductCount: 0,
  wishListBrand: [],
  wishListBrandCount: 0,
  cart: [],
  cartCount: 0,
  loadCard: false,
  statistics: {
    data: []
  }
};



const customers = ( state = initialState, action = {} ) => {

  switch (action.type) {

    case CUSTOMER_TYPES.CUSTOMER_PAG_F: {
        return {
          ...state,
          data: action.payload.data.map((elem) => state.ids.includes(elem.customerId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.customerId)),
          count: action.payload.count
        }
    }

    case CUSTOMER_TYPES.CUSTOMER_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case CUSTOMER_TYPES.CUSTOMER_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case CUSTOMER_TYPES.CUSTOMER_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case CUSTOMER_TYPES.CUSTOMER_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case CUSTOMER_TYPES.CUSTOMER_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
        if (multiple) {
          elem['isChecked'] = checked
          return elem
        } else {
          state.data[key]['isChecked'] = checked
          return elem
        }
      })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.customerId) })

      return {
        ...state,
        data: data,
        checked: !multiple ? data.every(element => element.isChecked): checked,
        ids: ids
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CUSTOMER_TYPES.CUSTOMER_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CUSTOMER_TYPES.CUSTOMER_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case CUSTOMER_TYPES.CUSTOMER_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_GET_ONE_F: {
      return { 
        ...state,
        oneData: action.payload,
        error: []
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_REQUIRED_VALIDATION_R: {
      return { 
        ...state,
        error: action.payload.errorMsg,
        tabPanel: tabPanel
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_REQUIRED_VALIDATION_F: {
      return { 
        ...state,
        error: [],
      }
    }

    case CUSTOMER_TYPES.NEW_CUSTOMER: {
      return { 
        ...state,
        oneData: new CustomerFields(),
        error: []
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_DATA_FILTER: {
      return { 
          ...state,
          // dataFilter: Object.assign({}, state.dataFilter, { [action.payload['key']]: action.payload['value'] }),
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.customer !== id)
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_FILTER_IS_OPEN: {
      localStorage.setItem('customerFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_DATA_MIN_MAX_F: {
      return {
          ...state,
          minMaxValues: action.payload
      }
    }
    
    case CUSTOMER_TYPES.CUSTOMER_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: { 
          name: '',
          email: '',
          phone: '',
          businesType: '',
          licenseType: '',
          status: null,
          dates:  '',
          minPrice: '',
          maxPrice: ''
        }
      }
    }


    case CUSTOMER_TYPES.FETCH_CUSTOMER_SEE_ALL_F: {
      const newCustomer = []
      action.payload.data.forEach(customer => {
        newCustomer.push({ 
          value: customer.customerId, 
          label: customer.telephone || customer.email
        }) 
      })

      let customerSelect = [...state.customerSelect, ...newCustomer]
      customerSelect = customerSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          customerSelect: customerSelect,
          count: action.payload.count
      }
    }


    case CUSTOMER_TYPES.FETCH_CUSTOMER_ORDERS_P: {
      return {
        ...state,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_ORDERS_F: {
      return {
        ...state,
        orders: action.payload.data,
        ordersCount: action.payload.count,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_ORDERS_R: {
      return {
        ...state,
        loadOrder: false
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_REVIEWS_P: {
      return {
        ...state,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_REVIEWS_F: {

      return {
        ...state,
        reviews: action.payload.data,
        reviewsCount: action.payload.count,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_REVIEWS_R: {
      return {
        ...state,
        loadOrder: false
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_BRAND_P: {
      return {
        ...state,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_BRAND_F: {

      return {
        ...state,
        wishListBrand: action.payload.data,
        wishListBrandCount: action.payload.count,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_BRAND_R: {
      return {
        ...state,
        loadOrder: false
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_PRODUCT_P: {
      return {
        ...state,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_PRODUCT_F: {

      return {
        ...state,
        wishListProduct: action.payload.data,
        wishListProductCount: action.payload.count,
        loadOrder: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_PRODUCT_R: {
      return {
        ...state,
        loadOrder: false
      }
    }


    case CUSTOMER_TYPES.FETCH_CUSTOMER_CART_P: {
      return {
        ...state,
        loadCard: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_CART_F: {

      return {
        ...state,
        cart: action.payload.data,
        cartCount: action.payload.count,
        loadCard: true
      }
    }

    case CUSTOMER_TYPES.FETCH_CUSTOMER_CART_R: {
      return {
        ...state,
        loadCard: false
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_STATISTICS_P: {
      return {
          ...state,
          loading: true
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_STATISTICS_F: {
      return {
          ...state,
          statistics: action.payload
      }
    }

    case CUSTOMER_TYPES.CUSTOMER_STATISTICS_R: {
      return {
          ...state,
          error: action.payload.data
      }
    }
    

    default:
            return state;
  }
}

export {
    customers
}

