const DISCOUNT_PAG_F = 'DISCOUNT_PAG_L_F';
const DISCOUNT_PAG_R = 'DISCOUNT_PAG_L_R';
const DISCOUNT_SIZE_CHANGE = 'DISCOUNT_SIZE_CHANGE'
const DISCOUNT_PAGE_CHANGE = 'DISCOUNT_PAGE_CHANGE'
const DISCOUNT_FILETR_TABE_CHANGE = 'DISCOUNT_FILETR_TABE_CHANGE'
const DISCOUNT_UPDATE_F = 'DISCOUNT_UPDATE_F'
const DISCOUNT_UPDATE_R = 'DISCOUNT_UPDATE_R'
const DISCOUNT_CHECKBOX_CHANGE = 'DISCOUNT_CHECKBOX_CHANGE'
const DISCOUNT_BULK_ARCIVE_F = 'DISCOUNT_BULK_ARCIVE_F'
const DISCOUNT_BULK_ARCIVE_R = 'DISCOUNT_BULK_ARCIVE_R'
const DISCOUNT_BULK_DESTROY_F = 'DISCOUNT_BULK_DESTROY_F'
const DISCOUNT_BULK_DESTROY_R = 'DISCOUNT_BULK_DESTROY_R'
const DISCOUNT_TAB_PANEL_CHANGE = 'DISCOUNT_TAB_PANEL_CHANGE'
const DISCOUNT_CREATE_F = 'DISCOUNT_CREATE_F'
const DISCOUNT_CREATE_R = 'DISCOUNT_CREATE_R'
const DISCOUNT_GET_ONE_F = 'DISCOUNT_GET_ONE_F'
const DISCOUNT_GET_ONE_R = 'DISCOUNT_GET_ONE_R'
const DISCOUNT_REQUIRED_VALIDATION_F = 'DISCOUNT_REQUIRED_VALIDATION_F'
const DISCOUNT_REQUIRED_VALIDATION_R = 'DISCOUNT_REQUIRED_VALIDATION_R'
const NEW_DISCOUNT = 'NEW_DISCOUNT'
const DISCOUNT_DATA_FILTER = 'DISCOUNT_DATA_FILTER'
const DISCOUNT_DELETE_F = 'DISCOUNT_DELETE_F'
const DISCOUNT_DELETE_R = 'DISCOUNT_DELETE_R'
const DISCOUNT_LIVE_DATA = 'DISCOUNT_LIVE_DATA'
const DISCOUNT_DATA_FILTER_SEARCH = 'DISCOUNT_DATA_FILTER_SEARCH'
const DISCOUNT_FILTER_IS_OPEN = 'DISCOUNT_FILTER_IS_OPEN'
const DISCOUNT_LANGUAGE_PANEL_CHANGE = 'DISCOUNT_LANGUAGE_PANEL_CHANGE'
const DISCOUNT_DATA_FILTER_SEARCH_RESET = 'DISCOUNT_DATA_FILTER_SEARCH_RESET'
const DISCOUNT_DATA_FILTER_ALL_SEARCH_RESET = 'DISCOUNT_DATA_FILTER_ALL_SEARCH_RESET'
const DISCOUNT_GET_CODE_P = 'DISCOUNT_GET_CODE_P'
const DISCOUNT_GET_CODE_F = 'DISCOUNT_GET_CODE_F'
const DISCOUNT_GET_CODE_R = 'DISCOUNT_GET_CODE_R'

export {
    DISCOUNT_PAG_F,
    DISCOUNT_PAG_R,
    DISCOUNT_SIZE_CHANGE,
    DISCOUNT_PAGE_CHANGE,
    DISCOUNT_FILETR_TABE_CHANGE,
    DISCOUNT_UPDATE_F,
    DISCOUNT_UPDATE_R,
    DISCOUNT_CHECKBOX_CHANGE,
    DISCOUNT_BULK_ARCIVE_F,
    DISCOUNT_BULK_ARCIVE_R,
    DISCOUNT_BULK_DESTROY_F,
    DISCOUNT_BULK_DESTROY_R,
    DISCOUNT_TAB_PANEL_CHANGE,
    DISCOUNT_CREATE_F,
    DISCOUNT_CREATE_R,
    DISCOUNT_GET_ONE_F,
    DISCOUNT_GET_ONE_R,
    DISCOUNT_REQUIRED_VALIDATION_F,
    DISCOUNT_REQUIRED_VALIDATION_R,
    NEW_DISCOUNT,
    DISCOUNT_DATA_FILTER,
    DISCOUNT_DELETE_F,
    DISCOUNT_DELETE_R,
    DISCOUNT_LIVE_DATA,
    DISCOUNT_DATA_FILTER_SEARCH,
    DISCOUNT_FILTER_IS_OPEN,
    DISCOUNT_LANGUAGE_PANEL_CHANGE,
    DISCOUNT_DATA_FILTER_SEARCH_RESET,
    DISCOUNT_DATA_FILTER_ALL_SEARCH_RESET,
    DISCOUNT_GET_CODE_P,
    DISCOUNT_GET_CODE_F,
    DISCOUNT_GET_CODE_R
}