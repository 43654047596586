/**
 * @name coordsReducers
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = {}, action = {}) => {
  if (action.type === 'CHANGE_COORDS') {
    return action.payload;
  } else {
    return state
  }
}