const GET_ALL_IMAGES = 'GET_ALL_IMAGES';
const GET_ALL_REMOVED_IMAGES = 'GET_ALL_REMOVED_IMAGES';
const GET_IMAGE_ITEMS = 'GET_IMAGE_ITEMS';
const SELECT_IMAGE_ITEMS = 'SELECT_IMAGE_ITEMS';
const ADD_IMAGE = 'ADD_IMAGE';
const DELETE_IMAGE = 'DELETE_IMAGE';
const RECOVER_IMAGES = 'RECOVER_IMAGES';
const SELECT_IMAGE_SIZE_ITEMS = 'SELECT_IMAGE_SIZE_ITEMS';
const GET_PAG_NOT_REMOVED_IMAGES = 'GET_PAG_NOT_REMOVED_IMAGES';
const GET_PAG_REMOVED_IMAGES = 'GET_PAG_REMOVED_IMAGES';
const RECOVER_IMAGE_ITEMS = 'RECOVER_IMAGE_ITEMS';
const DELETE_IMAGE_ITEMS_ALL = 'DELETE_IMAGE_ITEMS_ALL';
const DELETE_IMAGE_ITEMS_ARC = 'DELETE_IMAGE_ITEMS_ARC';
const UPDATE_IMAGE_ITEM = 'UPDATE_IMAGE_ITEM';
const ICON_BULK_ARCIVE = 'ICON_BULK_ARCIVE';
const ARCIVE_IMAGE_ITEMS = 'ARCIVE_IMAGE_ITEMS'
const ADD_MAIN_IMAGE_ALL = 'ADD_MAIN_IMAGE_ALL'
const ADD_MAIN_IMAGE_ARCHIVE = 'ADD_MAIN_IMAGE_ARCHIVE'
const ADD_IMAGES = 'ADD_IMAGES'
const CANCEL_ADD_IMAGE = 'CANCEL_ADD_IMAGE'

export {
  GET_ALL_IMAGES,
  GET_ALL_REMOVED_IMAGES,
  GET_IMAGE_ITEMS,
  SELECT_IMAGE_ITEMS,
  ADD_IMAGE,
  DELETE_IMAGE,
  RECOVER_IMAGES,
  SELECT_IMAGE_SIZE_ITEMS,
  GET_PAG_NOT_REMOVED_IMAGES,
  GET_PAG_REMOVED_IMAGES,
  RECOVER_IMAGE_ITEMS,
  DELETE_IMAGE_ITEMS_ALL,
  DELETE_IMAGE_ITEMS_ARC,
  UPDATE_IMAGE_ITEM,
  ICON_BULK_ARCIVE,
  ARCIVE_IMAGE_ITEMS,
  ADD_MAIN_IMAGE_ALL,
  ADD_MAIN_IMAGE_ARCHIVE,
  ADD_IMAGES,
  CANCEL_ADD_IMAGE
}