import config from '../../../../config/app';
const axios = require('axios');

class ImageService {
  constructor() {
    this.api =config['MEDIA'];
    this.all = 'image';
    this.allR = 'image/d';
    this.allD = 'image/r';
    this.upload = 'image/addData';
    this.sizeImg = 'image/upload/size'
    this.pagNotRem = 'image/p/r';
    this.pagRem= 'image/p/d';
    this.update = 'image/upload';
    this.archive = 'image/bulk';
    this.destroy = 'image/bulk/destroy'
  }
  
  getAll(_jwt) {
    const url = `${this.api}${this.all}`;
    return fetch(url, {
      method: 'GET',
      
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  getAllRemoved(jwt) {
    const url = `${this.api}${this.allR}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  getItem(id, jwt) {
    const url = `${this.api}${this.all}/${id}`;
    return fetch(url, {
      method: 'Get',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  create(body, jwt) {
    const url = `${this.api}${this.rest}`;
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  edit(body, id, jwt) {
    const url = `${this.api}${this.upload}/${id}`;
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  arcive(id, jwt) {
    const url = `${this.api}${this.allR}/${id}`;
    return fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  
  recover(id, jwt) {
    const url = `${this.api}${this.allD}/${id}`;
    return fetch(url, {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  delete(id, jwt) {
    const url = `${this.api}${this.all}/${id}`;
    return fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  addMultipleImage(body, sizes, jwt) {
    const url = `${this.api}${this.sizeImg}/${sizes + ',' + 120 + ',' + 500}`;
    return axios.post(url, body,{ 
      headers: {            
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${jwt}`             
      }
  } ).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response
      }
    }) 
  }

  getPaginationNotRemoved(jwt, page, count, search) {
    const url = `${this.api}${this.pagNotRem}/${page}/${count}?name=${search || ''}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }
  
  getPaginationRemoved(jwt, page, count, search) {
    const url = `${this.api}${this.pagRem}/${page}/${count}?name=${search || ''}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  updateImage(body, _jwt, query) {
    const url = `${this.api}${this.update}?id=${query.id}&&name=${query.name}&&size=${query.size}&&alt=${query.alt}&&sortOrder=${query.sortOrder}`;
    return axios.put(url, body,{ 
      headers: {            
          'content-type': 'multipart/form-data'             
      }
  } ).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response
      }
    }) 
  }

  bulk(body, jwt) {
    const url = `${this.api}${this.archive}`;
    return axios.put(url,  body,
      {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
      }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response
      }
    }) 
  }

  bulkDestroy(body, jwt) {
    const url = `${this.api}${this.destroy}`;
    return axios.post(url,  body,
      {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
      }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response
      }
    }) 
  }
}

const imageService = new ImageService();

export {
  imageService
}