import {DOWNLOAD_TYPES} from "../../types/index"


const allDownloads = (state = [], action = {}) => {
  if (action.type === DOWNLOAD_TYPES.GET_ALL_DOWNLOADS) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedDownloads = (state = [], action = {}) => {
  if (action.type === DOWNLOAD_TYPES.GET_ALL_REMOVED_DOWNLOADS) {
    return action.payload;
  } else {
    return state
  }
};

const downloads = (state = {}, action = {}) => {
  if (action.type === DOWNLOAD_TYPES.GET_DOWNLOAD_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedDownloads = (state = {}, action = {}) => {
  if (action.type === DOWNLOAD_TYPES.SELECT_DOWNLOAD_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

export {allDownloads,allRemovedDownloads,downloads,selectedDownloads}