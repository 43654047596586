const DIGITAL_CARD_PAG_F = 'DIGITAL_CARD_PAG_L_F';
const DIGITAL_CARD_PAG_R = 'DIGITAL_CARD_PAG_L_R';
const DIGITAL_CARD_SIZE_CHANGE = 'DIGITAL_CARD_SIZE_CHANGE'
const DIGITAL_CARD_PAGE_CHANGE = 'DIGITAL_CARD_PAGE_CHANGE'
const DIGITAL_CARD_FILETR_TABE_CHANGE = 'DIGITAL_CARD_FILETR_TABE_CHANGE'
const DIGITAL_CARD_UPDATE_F = 'DIGITAL_CARD_UPDATE_F'
const DIGITAL_CARD_UPDATE_R = 'DIGITAL_CARD_UPDATE_R'
const DIGITAL_CARD_CHECKBOX_CHANGE = 'DIGITAL_CARD_CHECKBOX_CHANGE'
const DIGITAL_CARD_BULK_ARCIVE_F = 'DIGITAL_CARD_BULK_ARCIVE_F'
const DIGITAL_CARD_BULK_ARCIVE_R = 'DIGITAL_CARD_BULK_ARCIVE_R'
const DIGITAL_CARD_BULK_DESTROY_F = 'DIGITAL_CARD_BULK_DESTROY_F'
const DIGITAL_CARD_BULK_DESTROY_R = 'DIGITAL_CARD_BULK_DESTROY_R'
const DIGITAL_CARD_TAB_PANEL_CHANGE = 'DIGITAL_CARD_TAB_PANEL_CHANGE'
const DIGITAL_CARD_CREATE_F = 'DIGITAL_CARD_CREATE_F'
const DIGITAL_CARD_CREATE_R = 'DIGITAL_CARD_CREATE_R'
const DIGITAL_CARD_GET_ONE_F = 'DIGITAL_CARD_GET_ONE_F'
const DIGITAL_CARD_GET_ONE_R = 'DIGITAL_CARD_GET_ONE_R'
const DIGITAL_CARD_REQUIRED_VALIDATION_F = 'DIGITAL_CARD_REQUIRED_VALIDATION_F'
const DIGITAL_CARD_REQUIRED_VALIDATION_R = 'DIGITAL_CARD_REQUIRED_VALIDATION_R'
const NEW_DIGITAL_CARD = 'NEW_DIGITAL_CARD'
const DIGITAL_CARD_DATA_FILTER = 'DIGITAL_CARD_DATA_FILTER'
const DIGITAL_CARD_DELETE_F = 'DIGITAL_CARD_DELETE_F'
const DIGITAL_CARD_DELETE_R = 'DIGITAL_CARD_DELETE_R'
const DIGITAL_CARD_LIVE_DATA = 'DIGITAL_CARD_LIVE_DATA'
const DIGITAL_CARD_DATA_FILTER_SEARCH = 'DIGITAL_CARD_DATA_FILTER_SEARCH'
const DIGITAL_CARD_FILTER_IS_OPEN = 'DIGITAL_CARD_FILTER_IS_OPEN'
const DIGITAL_CARD_LANGUAGE_PANEL_CHANGE = 'DIGITAL_CARD_LANGUAGE_PANEL_CHANGE'
const DIGITAL_CARD_DATA_FILTER_SEARCH_RESET = 'DIGITAL_CARD_DATA_FILTER_SEARCH_RESET'
const DIGITAL_CARD_DATA_FILTER_ALL_SEARCH_RESET = 'DIGITAL_CARD_DATA_FILTER_ALL_SEARCH_RESET'

export {
    DIGITAL_CARD_PAG_F,
    DIGITAL_CARD_PAG_R,
    DIGITAL_CARD_SIZE_CHANGE,
    DIGITAL_CARD_PAGE_CHANGE,
    DIGITAL_CARD_FILETR_TABE_CHANGE,
    DIGITAL_CARD_UPDATE_F,
    DIGITAL_CARD_UPDATE_R,
    DIGITAL_CARD_CHECKBOX_CHANGE,
    DIGITAL_CARD_BULK_ARCIVE_F,
    DIGITAL_CARD_BULK_ARCIVE_R,
    DIGITAL_CARD_BULK_DESTROY_F,
    DIGITAL_CARD_BULK_DESTROY_R,
    DIGITAL_CARD_TAB_PANEL_CHANGE,
    DIGITAL_CARD_CREATE_F,
    DIGITAL_CARD_CREATE_R,
    DIGITAL_CARD_GET_ONE_F,
    DIGITAL_CARD_GET_ONE_R,
    DIGITAL_CARD_REQUIRED_VALIDATION_F,
    DIGITAL_CARD_REQUIRED_VALIDATION_R,
    NEW_DIGITAL_CARD,
    DIGITAL_CARD_DATA_FILTER,
    DIGITAL_CARD_DELETE_F,
    DIGITAL_CARD_DELETE_R,
    DIGITAL_CARD_LIVE_DATA,
    DIGITAL_CARD_DATA_FILTER_SEARCH,
    DIGITAL_CARD_FILTER_IS_OPEN,
    DIGITAL_CARD_LANGUAGE_PANEL_CHANGE,
    DIGITAL_CARD_DATA_FILTER_SEARCH_RESET,
    DIGITAL_CARD_DATA_FILTER_ALL_SEARCH_RESET
}