import React, {Component} from 'react';
import {
    Button
} from 'reactstrap';

class Dropdown extends Component {  
  constructor(props) {
    super(props);    
    this.state = {
      isOpen: false
    };
  }
  
  showDropdown = () => {
    this.setState({ isOpen: true });
    document.addEventListener("click", this.hideDropdown);
  };
  
  hideDropdown = () => {
    this.setState({ isOpen: false });
    document.removeEventListener("click", this.hideDropdown);
  };

  chooseItem = (item) => {   
    if (this.status.value !== item.value) {
      this.props._changeOrderGroupListsDone(this.props.elem, item.value, item.label)
    }    
  };
  
  renderDataDropDown = (item, index) => {  
      if(this.props.elem.status === item.value){
          this.status=item;
      }  
      return (
        <li key = {index}>
          <Button
              key={index}
              color={item.color}
              size="sm"
              className="m-1"
              onClick={() => this.chooseItem(item)}>
              <i className={item.className}></i>
                  {
                    item.label
                  }
          </Button>
        </li>
      )
  };

  render () {
    const { list, elem } = this.props; 
    let item = !!list.length && list.find(({value}) => value == elem.status) 
    return (
      <div className={`dropdown ${this.state.isOpen ? 'open' : ''}`}>
        {
          !!item &&
            <Button
              color={item.color}
              size="sm"
              className="m-1 white-space"
              onClick={this.showDropdown}>
              <i className={item.className}></i>
                {
                  item.label
                }
                {
                  !this.state.isOpen ?
                  <i className="fa fa-angle-down fa-lg ml-2"></i>
                  :
                  <i className="fa fa-angle-up fa-lg ml-2"></i>
                }
            </Button>
          }
          <ul className="dropdown-menu">
            {
                list.map(this.renderDataDropDown)
            }
          </ul>
      </div>
    )
  }
}
  
export default Dropdown
