import config from '../../../config/app'
import {Service} from "./Service";

const all = "c/a/a/r";
const allR = "c/a/a/d";
const rest  = "c/a/a";
const pagNotRem = "c/a/a/r";
const pagRem= "c/a/a/d";
const bulkDel = 'c/a/a/b/a';
const bulkRec = 'c/a/a/b/r'
const bulkDes = 'c/a/a/b/d'



const attributeService = new Service(config["ATTRIBUTE"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);
export {attributeService};