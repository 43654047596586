const GET_PRODUCT_GROUP = 'GET_PRODUCT_GROUP';
const SELECT_PRODUCT_GROUP = 'SELECT_PRODUCT_GROUP';
const DESTROY_PRODUCT_GROUP = 'DESTROY_PRODUCT_GROUP';
const DELETE_PRODUCT_GROUP = 'DELETE_PRODUCT_GROUP';
const RECOVER_PRODUCT_GROUP = 'RECOVER_PRODUCT_GROUP';
const GET_PAG_NOT_REMOVED_PRODUCT_GROUPS = 'GET_PAG_NOT_REMOVED_PRODUCT_GROUPS'
const GET_PAG_REMOVED_PRODUCT_GROUPS = 'GET_PAG_REMOVED_PRODUCT_GROUPS'
const ARCHIVE_PRODUCT_GROUP = 'ARCHIVE_PRODUCT_GROUP'
const BULK_RECOVER_PRODUCT_GROUP = 'BULK_RECOVER_PRODUCT_GROUP'
const BULK_ARCHIVE_PRODUCT_GROUP = 'BULK_ARCHIVE_PRODUCT_GROUP'
const SAVE_PRODUCT_GROUP_F = 'SAVE_PRODUCT_GROUP_F'
const UPDATE_PRODUCT_GROUP_F = 'UPDATE_PRODUCT_GROUP_F'
const PRODUCT_GROUPS_DATA_FILTER = 'PRODUCT_GROUPS_DATA_FILTER'
const PRODUCT_GROUPS_OUTSIDE_CLICK_CHANGE = 'PRODUCT_GROUPS_OUTSIDE_CLICK_CHANGE'
const PRODUCT_GROUP_LANG_TAB = 'PRODUCT_GROUP_LANG_TAB'
const BULK_DESTROY_PRODUCT_GROUP = 'BULK_DESTROY_PRODUCT_GROUP'
const PRODUCT_GROUP_TAB_PANEL_CHANGE = 'PRODUCT_GROUP_TAB_PANEL_CHANGE'
const PRODUCT_GROUP_FILTER_IS_OPEN = 'PRODUCT_GROUP_FILTER_IS_OPEN'
const PRODUCT_GROUP_DATA_FILTER_SEARCH = 'PRODUCT_GROUP_DATA_FILTER_SEARCH'
const PRODUCT_GROUP_DATA_FILTER_SEARCH_RESET = 'PRODUCT_GROUP_DATA_FILTER_SEARCH_RESET'
const PRODUCT_GROUP_DATA_FILTER_ALL_SEARCH_RESET = 'PRODUCT_GROUP_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_PRODUCT_GROUP_SEE_ALL = 'FETCH_PRODUCT_GROUP_SEE_ALL'
const PRODUCT_GROUP_CHOOSE_FILTER = 'PRODUCT_GROUP_CHOOSE_FILTER'
const PRODUCT_GROUP_REMOVE_FILTER = 'PRODUCT_GROUP_REMOVE_FILTER'
const PRODUCT_GROUP_CHOOSE_FILTER_VALUE = 'PRODUCT_GROUP_CHOOSE_FILTER_VALUE'
const PRODUCT_GROUP_IMPORT_CUSTOMER = 'PRODUCT_GROUP_IMPORT_CUSTOMER'
const PRODUCT_GROUP_CHOOSE_FILTER_DATE_VALUE = 'PRODUCT_GROUP_CHOOSE_FILTER_DATE_VALUE'
const PRODUCT_GROUP_CHOOSE_FILTER_CATEGORIES  = 'PRODUCT_GROUP_CHOOSE_FILTER_CATEGORIES'
const PRODUCT_GROUP_CHOOSE_FILTER_BRANDS  = 'PRODUCT_GROUP_CHOOSE_FILTER_BRANDS'
const PRODUCT_GROUP_CHOOSE_FILTER_BUSINESS = 'PRODUCT_GROUP_CHOOSE_FILTER_BUSINESS'
const PRODUCT_GROUP_FILTER_F = 'PRODUCT_GROUP_FILTER_F'
const PRODUCT_GROUP_FILTER_R = 'PRODUCT_GROUP_FILTER_R'

export {
  GET_PRODUCT_GROUP,
  SELECT_PRODUCT_GROUP,
  DESTROY_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  GET_PAG_NOT_REMOVED_PRODUCT_GROUPS,
  GET_PAG_REMOVED_PRODUCT_GROUPS,
  ARCHIVE_PRODUCT_GROUP,
  RECOVER_PRODUCT_GROUP,
  BULK_RECOVER_PRODUCT_GROUP,
  BULK_ARCHIVE_PRODUCT_GROUP,
  SAVE_PRODUCT_GROUP_F,
  UPDATE_PRODUCT_GROUP_F,
  PRODUCT_GROUPS_DATA_FILTER,
  PRODUCT_GROUPS_OUTSIDE_CLICK_CHANGE,
  PRODUCT_GROUP_LANG_TAB,
  BULK_DESTROY_PRODUCT_GROUP,
  PRODUCT_GROUP_TAB_PANEL_CHANGE,
  PRODUCT_GROUP_FILTER_IS_OPEN,
  PRODUCT_GROUP_DATA_FILTER_SEARCH,
  PRODUCT_GROUP_DATA_FILTER_SEARCH_RESET,
  PRODUCT_GROUP_DATA_FILTER_ALL_SEARCH_RESET,
  FETCH_PRODUCT_GROUP_SEE_ALL,
  PRODUCT_GROUP_CHOOSE_FILTER,
  PRODUCT_GROUP_REMOVE_FILTER,
  PRODUCT_GROUP_CHOOSE_FILTER_VALUE,
  PRODUCT_GROUP_IMPORT_CUSTOMER,
  PRODUCT_GROUP_CHOOSE_FILTER_DATE_VALUE,
  PRODUCT_GROUP_CHOOSE_FILTER_CATEGORIES,
  PRODUCT_GROUP_CHOOSE_FILTER_BRANDS,
  PRODUCT_GROUP_CHOOSE_FILTER_BUSINESS,
  PRODUCT_GROUP_FILTER_F,
  PRODUCT_GROUP_FILTER_R
}