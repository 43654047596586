const PROMO_CODE_PAG_F = 'PROMO_CODE_PAG_L_F';
const PROMO_CODE_PAG_R = 'PROMO_CODE_PAG_L_R';
const PROMO_CODE_SIZE_CHANGE = 'PROMO_CODE_SIZE_CHANGE'
const PROMO_CODE_PAGE_CHANGE = 'PROMO_CODE_PAGE_CHANGE'
const PROMO_CODE_FILETR_TABE_CHANGE = 'PROMO_CODE_FILETR_TABE_CHANGE'
const PROMO_CODE_UPDATE_F = 'PROMO_CODE_UPDATE_F'
const PROMO_CODE_UPDATE_R = 'PROMO_CODE_UPDATE_R'
const PROMO_CODE_CHECKBOX_CHANGE = 'PROMO_CODE_CHECKBOX_CHANGE'
const PROMO_CODE_BULK_ARCIVE_F = 'PROMO_CODE_BULK_ARCIVE_F'
const PROMO_CODE_BULK_ARCIVE_R = 'PROMO_CODE_BULK_ARCIVE_R'
const PROMO_CODE_BULK_DESTROY_F = 'PROMO_CODE_BULK_DESTROY_F'
const PROMO_CODE_BULK_DESTROY_R = 'PROMO_CODE_BULK_DESTROY_R'
const PROMO_CODE_TAB_PANEL_CHANGE = 'PROMO_CODE_TAB_PANEL_CHANGE'
const PROMO_CODE_CREATE_F = 'PROMO_CODE_CREATE_F'
const PROMO_CODE_CREATE_R = 'PROMO_CODE_CREATE_R'
const PROMO_CODE_GET_ONE_F = 'PROMO_CODE_GET_ONE_F'
const PROMO_CODE_GET_ONE_R = 'PROMO_CODE_GET_ONE_R'
const PROMO_CODE_REQUIRED_VALIDATION_F = 'PROMO_CODE_REQUIRED_VALIDATION_F'
const PROMO_CODE_REQUIRED_VALIDATION_R = 'PROMO_CODE_REQUIRED_VALIDATION_R'
const NEW_PROMO_CODE = 'NEW_PROMO_CODE'
const PROMO_CODE_DATA_FILTER = 'PROMO_CODE_DATA_FILTER'
const PROMO_CODE_DELETE_F = 'PROMO_CODE_DELETE_F'
const PROMO_CODE_DELETE_R = 'PROMO_CODE_DELETE_R'
const PROMO_CODE_LIVE_DATA = 'PROMO_CODE_LIVE_DATA'
const PROMO_CODE_DATA_FILTER_SEARCH = 'PROMO_CODE_DATA_FILTER_SEARCH'
const PROMO_CODE_FILTER_IS_OPEN = 'PROMO_CODE_FILTER_IS_OPEN'
const PROMO_CODE_LANGUAGE_PANEL_CHANGE = 'PROMO_CODE_LANGUAGE_PANEL_CHANGE'
const PROMO_CODE_DATA_FILTER_SEARCH_RESET = 'PROMO_CODE_DATA_FILTER_SEARCH_RESET'
const PROMO_CODE_DATA_FILTER_ALL_SEARCH_RESET = 'PROMO_CODE_DATA_FILTER_ALL_SEARCH_RESET'
const PROMO_CODE_GET_CODE_P = 'PROMO_CODE_GET_CODE_P'
const PROMO_CODE_GET_CODE_F = 'PROMO_CODE_GET_CODE_F'
const PROMO_CODE_GET_CODE_R = 'PROMO_CODE_GET_CODE_R'

export {
    PROMO_CODE_PAG_F,
    PROMO_CODE_PAG_R,
    PROMO_CODE_SIZE_CHANGE,
    PROMO_CODE_PAGE_CHANGE,
    PROMO_CODE_FILETR_TABE_CHANGE,
    PROMO_CODE_UPDATE_F,
    PROMO_CODE_UPDATE_R,
    PROMO_CODE_CHECKBOX_CHANGE,
    PROMO_CODE_BULK_ARCIVE_F,
    PROMO_CODE_BULK_ARCIVE_R,
    PROMO_CODE_BULK_DESTROY_F,
    PROMO_CODE_BULK_DESTROY_R,
    PROMO_CODE_TAB_PANEL_CHANGE,
    PROMO_CODE_CREATE_F,
    PROMO_CODE_CREATE_R,
    PROMO_CODE_GET_ONE_F,
    PROMO_CODE_GET_ONE_R,
    PROMO_CODE_REQUIRED_VALIDATION_F,
    PROMO_CODE_REQUIRED_VALIDATION_R,
    NEW_PROMO_CODE,
    PROMO_CODE_DATA_FILTER,
    PROMO_CODE_DELETE_F,
    PROMO_CODE_DELETE_R,
    PROMO_CODE_LIVE_DATA,
    PROMO_CODE_DATA_FILTER_SEARCH,
    PROMO_CODE_FILTER_IS_OPEN,
    PROMO_CODE_LANGUAGE_PANEL_CHANGE,
    PROMO_CODE_DATA_FILTER_SEARCH_RESET,
    PROMO_CODE_DATA_FILTER_ALL_SEARCH_RESET,
    PROMO_CODE_GET_CODE_P,
    PROMO_CODE_GET_CODE_F,
    PROMO_CODE_GET_CODE_R
}