import {FILTER_TYPES} from '../types'
import {filterService} from "../services/filterService";
import {Notification} from 'element-react'

/**
 * @name getAllFilter
 * @returns {function(*)}
 */
const getAllFilter = (token) => {
  return dispatch => {
    return filterService.getAll(token).then(data => {
      dispatch({
        type : FILTER_TYPES.GET_FILTERS,
        payload: data
      })
    })
  }
};

/**
 * @name getAllRemovedFilter
 * @returns {function(*)}
 */
const getAllRemovedFilter = (token) => {
  return dispatch => {
    return filterService.getAllRemoved(token).then(data => {
       dispatch({
        type : FILTER_TYPES.GET_DELETED_FILTERS,
        payload: data
      })
    })
  }
};

/**
 * @name selectFilter
 * @param elem
 * @returns {function(*)}
 */
const selectFilter = (elem) => {
  return dispatch => {
    dispatch({
      type : FILTER_TYPES.SELECT_FILTER_ITEMS,
      payload : elem
    })
  }
};


const getItem = (id,token) => {
  return dispatch => {
    return filterService.getItem(id,token).then(data => {
      return dispatch({
        type: FILTER_TYPES.GET_FILTER_ITEM,
        payload: data,
      })
    })
  }
};


/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return filterService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: FILTER_TYPES.GET_PAG_NOT_REMOVED_FILTERS,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return filterService.getPaginationRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: FILTER_TYPES.GET_PAG_REMOVED_FILTERS,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt) => {
  return dispatch => {
    filterService.remove(elem.filterGroupId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: FILTER_TYPES.DELETE_FILTER_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    filterService.recover(elem.filterGroupId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: FILTER_TYPES.RECOVER_FILTER_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    filterService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: FILTER_TYPES.ARCHIVE_FILTER_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
*/
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    filterService.bulkDestroy(ids,  jwt).then((data) => {
      return dispatch({
        type: FILTER_TYPES.BULK_DESTROY_FILTER_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    filterService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: FILTER_TYPES.BULK_RECOVER_FILTER_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name fetchFilterSeeAl
 * @returns {function(*)}
 */
const fetchFilterSeeAll = (jwt, page, count) => {
  return dispatch => {
    return filterService.getPaginationNotRemoved(jwt, page, count).then(options => {
      return dispatch({
        type: FILTER_TYPES.FETCH_FILTER_SEE_ALL,
        payload : options
      })
    })
  }
};

const destroyFilter = ( token, filter ) => {

  return async dispatch => {

    try {

      const data = await filterService.destroy(token ,filter.filterGroupId)

        Notification.success( {
          title: 'filter ',
          message: `${filter.source} filter succesfuly deleted `
        }, 20 )

        dispatch( {
            type: FILTER_TYPES.DESTROY_FILTER,
            payload: filter,
            data: data
        } )
        
    } catch (e) {

        Notification.error( {
          title: e.name,
          message: `${e.parent.column}`
        }, 20) 
       
      }
    }
  }
export {getAllFilter, getAllRemovedFilter, selectFilter, getItem, getPaginationNotRemoved, getPaginationRemoved, deleteItem, recoverItem, bulkDelete, bulkRecover, fetchFilterSeeAll, destroyFilter, bulkDestroy}