import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import {
    smsActions
  } from "../../projectNulla/redux-modules/actions";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    ButtonToolbar,
    CardTitle,
    Progress,
    Col,
    Row
} from 'reactstrap';
import { DatePicker } from '../../projectNulla/catalogs/Globals';
const brandInfo = getStyle('--info');
const statusColors = {
    all: brandInfo,
    delivered: '#3a9d5d',
    delivery_failed: '#f86c6b',
    sent: '#ffc107',
}
  
function SmsStoreChart(_props) {
    const dispatch = useDispatch()
    let [status, setStatus] = useState(['all'])
    const { statistics } = useSelector((state) => state.sms);
    const maxCount = Math.max(...statistics.data.map(o => o.count));

    const mainChartOpts = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
                    labelColor: function(tooltipItem, chart) {
                    return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                }
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    display: false,
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxCount / 5),
                        max:  maxCount
                    },
                }
            ],
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            },
        },
    };

    const { token } = useSelector((state) => state.account);
    const [date, setDate] = useState({ startDate: '', endDate: '' })
    const [radioSelected, setRadioSelected] = useState('month');


    useEffect(() => {
        dispatch(smsActions.getStatistics(token, { 
            startDate: date.startDate,
            endDate: date.endDate,
            date: radioSelected
        }));
    }, [date, radioSelected])


    function onRadioBtnClick(value) {
        setRadioSelected(value)
    }
    
    const getClientLanguage = () => {
        let Locale = navigator.language || navigator.userLanguage;
        if (Locale.includes('-')) Locale = Locale.split('-')[0];
        return Locale.toLowerCase();
    };

    const genericFormattedDate = (DateToFormat) => {
        const UserLanguage = getClientLanguage();
        const Config = { year: 'numeric', month: 'long', hour: '2-digit', minute:'2-digit', day: '2-digit' };
        return new Date(DateToFormat).toLocaleString(UserLanguage, Config);
    };

    const mainChart = {
        labels: statistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'Sms',
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: brandInfo,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: statistics.data.map(d => d.count),
            }
        ],
    };

    if (status.length) {
        mainChart.datasets = []
        status.forEach(item => {
            mainChart.datasets.push({
                label: `Status ${item}`,
                fill: item == 'all',
                lineTension: 0.4,
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: statusColors[item], // The main line color
                borderCapStyle: 'square',
                borderDash: [], // try [5, 15] for instance
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: statusColors[item],
                pointBackgroundColor: "white",
                pointBorderWidth: 1,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "yellow",
                pointHoverBorderColor: "brown",
                pointHoverBorderWidth: 1,
                pointRadius: 2,
                pointHitRadius: 5,
                data: statistics.data.map(d => d[item] || 0),
            })
        })
    }

    function handleChangeDate (event, key) {
        setDate({
            ...date,
            [key]: event.target.value
        })
    }

    const setOrRemoveStatus = (value) => {
        if (status.includes(value)) {
            status = status.filter((elem) => value !== elem)
            if (status.length) {
                setStatus(status)
            } else {
                setStatus(['all'])
            }
        } else {
            setStatus([...status, value])
        }
    }

    return (
        <Card>
             <CardBody>
                <Row>
                    <Col sm="1">
                        <CardTitle className="mb-0">Store Sms</CardTitle>
                    </Col>
                    <Col sm="2">
                        <ButtonToolbar className="float-left" aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-1" aria-label="First group">
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('all')} active={status.includes('all')}>All</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('delivered')} active={status.includes('delivered')}>Delivered</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('sent')} active={status.includes('sent')}>Pending</Button>
                                <Button color="outline-secondary" onClick={() => setOrRemoveStatus('delivery_failed')} active={status.includes('delivery_failed')}>Error</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                    <Col sm="4">
                        <DatePicker
                            handleChange={handleChangeDate}
                            value={{
                                startDate: date.startDate,
                                endDate: date.endDate 
                            }}
                        />
                    </Col>
                    <Col sm="5" className="d-none d-sm-inline-block">
                        <ButtonToolbar className="float-right" aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-3" aria-label="First group">
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('year')} active={radioSelected === 'year'}>Year</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
                    <Line data={mainChart} options={mainChartOpts} height={300} />
                </div>
            </CardBody>
            <CardFooter>
                <Row className="text-center">
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Unique</div>
                    <strong>{Math.round(statistics.unique)} Unique</strong>
                    <Progress className="progress-xs mt-2" color="info" value={statistics.unique / statistics.count * 100} />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Count</div>
                    <strong>{Math.round(statistics.count)} Count</strong>
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Price Avg</div>
                    <strong>{statistics.price ? parseFloat(statistics.price.priceAvg).toFixed(3) : 0 + ' USD'} Price Avg</strong>
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Total</div>
                    <strong>{statistics.price ? parseFloat(statistics.price.priceTotal).toFixed(3) : 0 + ' USD'} Total</strong>
                  </Col>
                </Row>
            </CardFooter>
        </Card>
    )
}

export default SmsStoreChart