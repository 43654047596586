import {ORDER_TYPES} from '../../types'
import config from '../../../../config/app'
import { updateDimensions } from '../../services/mobileService'
import Fields from '../../../sales/Orders/OrderItem/type'
const isMobile = updateDimensions()

const allOrderList = (state = [], action = {}) => {
  if (action.type === ORDER_TYPES.GET_ALL_ORDERS) {
    return action.payload;
  } else {
    return state
  }
};


const allOrder = (state = [], action = {}) => {
  if (action.type === ORDER_TYPES.GET_ALL_ORDER_LIST) {
    return action.payload.data;
  } else {
    return state
  }
};

const newOrder = (state = [], action = {}) => {
  if (action.type === ORDER_TYPES.GET_NEW_ORDER_LIST) {
    return action.payload.data;
  } else {
    return state
  }
};

const allRemovedOrder = (state = [], action = {}) => {
  if (action.type === ORDER_TYPES.GET_ALL_REMOVED_ORDER_LIST) {
    return action.payload;
  } else {
    return state
  }
};

const allDoneOrder = (state = [], action = {}) => {
  if (action.type === ORDER_TYPES.GET_ALL_DONE_ORDER_LIST) {
    return getGroupOrder(action.payload);
  } else {
    return state
  }
};

const getGroupOrder = (order) => {
  let orderList = {};
  if (order.length) {
    order.forEach(orderItem => {
        if (orderItem.saleGroupId ) {
          if(!orderList[orderItem.saleGroupId]){
            orderList[orderItem.saleGroupId] = []
          }
          orderList[orderItem.saleGroupId].push(orderItem);

        }
      }
    )
  }
  orderList = Object.values(orderList);
  return orderList;
};


const defaultState = {
  pagOrder: [],
  order: new Fields(),
  pagOrderCount: 0,
  statusList: [],
  initialFilter: {
    user: '',
    orderId: '',
    status: null,
    dates:  '',
    minPrice: 0,
    maxPrice: '',
    storeIds: [],
    productIds: [],
    frinchiseIds: [],
    businessIds: []
  },
  dataFilter: {
    dateF: null,
    priceF: null,
    orderIdF: null,
  },
  minMaxValues: {},
  statistics: {
    data: []
  },
  categories: {
    data: [],
    count: 0
  },
  purchaseTypes: {
    data: [],
    count: 0
  },
  locations: [],
  loading: true,
  isOpen: isMobile ? false : localStorage.getItem('orderFilter') == 'true' ? true : false
};

const newOrders= ( state = defaultState, action ={}) => {
  switch (action.type) {

      case ORDER_TYPES.GET_PAGINATION_ORDERS: {
        return {
          ...state,
          pagOrder: action.payload.data,
          pagOrderCount: action.payload.count
        }
      }

      case ORDER_TYPES.GET_PAGINATION_ORDERS_PG_P: {
        return {
          ...state,
          loading: true
        }
      }

      case ORDER_TYPES.GET_PAGINATION_ORDERS_PG_F: {

        const { data, count } = action.payload;
        let updatedPagOrder = [];
        data.forEach(newOrder => {
          const existingOrder = state.pagOrder.find(order => order.orderId === newOrder.orderId);

          if (existingOrder) {
            newOrder = {
              ...newOrder,
              orderProduct: existingOrder.orderProduct
            };
          }

          updatedPagOrder.push(newOrder);

        });

        return {
          ...state,
          pagOrder: updatedPagOrder,
          pagOrderCount: count
        };
      }

      case ORDER_TYPES.GET_PAGINATION_ORDERS_PG_R: {
        return {
          ...state,
          loading: false,
        }
      }

      case ORDER_TYPES.GET_NEW_SOCKET_ORDER_LIST: {
          const _id = action.payload;
          return {
            ...state,
            pagOrder: state.pagOrder.filter(item => item.Id !== _id),
            pagOrderCount: state.pagOrderCount - 1
          }
      }
      
      case ORDER_TYPES.CHANGE_STATUS_ORDERS: {
        const order = action.payload.data
        state.pagOrder = state.pagOrder.map(item => item.mongoId === order.mongoId ? {
          ...item, 
          status: order.status, 
          message: order.message, 
          telephone: order.telephone,
          customer: { ...item.customer, telephone: order.telephone }
        } : item)
        return {
          ...state,
          pagOrder: state.pagOrder,
          pagOrderCount: state.pagOrderCount
        }
      }


      case ORDER_TYPES.CHANGE_FRANCHISE_ORDERS: {
        const order = action.payload
        return {
          ...state,
          pagOrder: state.pagOrder.map(item => item.mongoId === order.Id ? {...item, franchise: order} : item),
          pagOrderCount: state.pagOrderCount
        }
      }

      case ORDER_TYPES.CHANGE_ORDER_PAYMENT_METHOD: {
        return {
          ...state,
          pagOrder: state.pagOrder.map(item => item.mongoId === action.payload.Id ? {...item, paymentMethod: action.payload.paymentMethod} : item),
          pagOrderCount: state.pagOrderCount
        }
      }


    case ORDER_TYPES.GET_ORDER_STATUS_LIST: {
      return {
        ...state,
        statusList: action.payload,
      }
    }

    case ORDER_TYPES.ORDER_FILTER_IS_OPEN: {
      localStorage.setItem('orderFilter', !state.isOpen)
      return {
        ...state,
        isOpen: !state.isOpen
      }
    }

    case ORDER_TYPES.ORDER_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case ORDER_TYPES.ORDER_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case ORDER_TYPES.ORDER_DATA_MIN_MAX_F: {
      return {
          ...state,
          minMaxValues: action.payload
      }
    }

    case ORDER_TYPES.ORDER_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: { 
          user: '',
          orderId: '',
          status: null,
          dates:  '',
          minPrice: '',
          maxPrice: '',
          dateTo: '',
          dateFrom: '',
          businessIds: [],
          franchiseIds: [],
          storeIds: []
        }
      }
    }

    case ORDER_TYPES.GET_ORDER_STATISTICS: {
      return {
        ...state,
        statistics: action.payload
      }
    }

    case ORDER_TYPES.GET_ORDER_LOCATIONS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case ORDER_TYPES.GET_ORDER_LOCATIONS_F: {
      return {
        ...state,
        loading: false,
        locations: action.payload
      }
    }

    case ORDER_TYPES.GET_ORDER_LOCATIONS_R: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case ORDER_TYPES.GET_ORDER_P: {
      return {
        ...state,
        loading: true
      }
    }

    case ORDER_TYPES.GET_ORDER_F: {
      const order = action.payload
      order.products = order.orderProduct.map(({product, quantity, price, orderOption}) => ({
        value: product.productId,
        label: product.descriptions[0].name,
        model: product.model,
        name: product.descriptions[0].name, 
        productId: product.productId, 
        price: price,
        quantity: quantity,
        options: orderOption.map(option => ({
          ...option, 
          optionId: option.option.optionId,
          options: product.productOptions.find((elOption) => elOption.optionId == option.option.optionId)
        })),
        descriptions: product.descriptions,
        imageMain: product.imageMain,
        activeOptions: orderOption.map(option => option.productOptionValueId),
        imgUrl: product.imageMain && product.imageMain[0] && product.imageMain[0].image ? config.IMAGEURL + product.imageMain[0].image.url: ''
      }))

      if (!order.address) {
        order.address = state.order.address
      }
      
      return {
        ...state,
        loading: false,
        order: action.payload
      }
    }

    case ORDER_TYPES.GET_ORDER_R: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case 'NEW_ORDER_F': {
      return {
        ...state,
        loading: false,
        order: new Fields()
      }
    }

    case ORDER_TYPES.UPLOAD_LICENSE_FILES_P: {
      return {
        ...state,
        loading: true
      }
    }

    case ORDER_TYPES.UPLOAD_LICENSE_FILES_F: {
      return {
        ...state,
        loading: false
      }
    }

    case ORDER_TYPES.UPLOAD_LICENSE_FILES_R: {
      return {
        ...state,
        loading: false
      }
    }

    case ORDER_TYPES.GET_ORDER_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }

    case ORDER_TYPES.GET_ORDER_TYPES: {
      return {
        ...state,
        purchaseTypes: { 
          data: action.payload.data.map((item) => ({ value: item.orderTypeId, label: item.name })),
          count: action.payload.count 
        }
      }
    }

    case ORDER_TYPES.ORDER_PRODUCT_LIST_P: {
      const { id } = action.payload;
      const updatedOrders = state.pagOrder.map(order => {
        if (order.orderId === id) {
          return {
            ...order,
            loading: true
          };
        }
        return order;
      });

      return {
        ...state,
        pagOrder: updatedOrders
      }
    }

    case ORDER_TYPES.ORDER_PRODUCT_LIST_F: {
      const { data, id } = action.payload;

      const updatedOrders = state.pagOrder.map(order => {
        if (order.orderId === id) {
          return {
            ...order,
            loading: false,
            orderProduct: data
          };
        }
        return order;
      });

      return {
        ...state,
        pagOrder: updatedOrders
      }
    }

    case ORDER_TYPES.ORDER_PRODUCT_LIST_R: {
      return {
        ...state
      }
    }

    default:
      return state;
  }
}
export {allDoneOrder, allOrder, allRemovedOrder, allOrderList, newOrder, newOrders}


