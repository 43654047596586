export default class {
    constructor () {
      this.notificationTypeId = ''
      this.name = ''
      this.description = ''
      this.notificationTypeGroupId = ''
      this.sms = false
      this.email = false
      this.inApp = true
      this.push = false
      this.status = true
      this.deleted = false
      this.sounds = []
      this.dataFilter = [
        {
          name: 'Name',
          field: 'name',
          tag: 'input',
          label: true,
          type: 'text',
          color: '',
          value: ''
        }
      ]
    }
  }