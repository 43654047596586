import  { PROMO_CODE_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import { Notification } from "element-react"

 class DiscountActions {
  constructor(_props) {
    this.api = config['DISCOUNT']
    this.rest  = 'c/p'
    this.arcive = '/b/'
  }

    pagination = (jwt, params, page, count, query) => {
      return async dispatch => {
        try {
          const searchString = qs.stringify(query)
          const response = await axios.get(
          this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: PROMO_CODE_TYPES.PROMO_CODE_PAG_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: PROMO_CODE_TYPES.PROMO_CODE_PAG_R,
            payload: e
          })

        }
      }
    }

    update = (jwt, body, Type, history, _account) => {
      return async dispatch => {
        try {
          await axios.put(
          this.api + this.rest + `/${body.promoCodeId}`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: PROMO_CODE_TYPES[Type + '_UPDATE_F'],
            payload: body
          })

            
          history.goBack()
            
        } catch (e) {
          if (e.response.data && e.response.data.length) {
            e.response.data.forEach(err => {
              Notification.error({
                title: err.param,
                message: err.message
              }, 20) 
            })
          } 
          dispatch({
              type: PROMO_CODE_TYPES[Type + '_UPDATE_R'],
              payload: e
          })

        }
      }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: PROMO_CODE_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: PROMO_CODE_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }
          )
            dispatch({
                type: PROMO_CODE_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: PROMO_CODE_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: PROMO_CODE_TYPES.PROMO_CODE_BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.pagination(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: PROMO_CODE_TYPES.PROMO_CODE_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }
      /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
      */
       bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
         return async dispatch => {
           try {
              await axios.post(
               this.api + this.rest + this.arcive + 'd',
               ids,
               {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
              }
             )
               dispatch({
                   type: PROMO_CODE_TYPES.PROMO_CODE_BULK_DESTROY_F,
                   payload: ids
               })
 
               dispatch(this.pagination(...otherDispatchParams))
               
           } catch (e) {
 
               dispatch({
                   type: PROMO_CODE_TYPES.PROMO_CODE_BULK_DESTROY_R,
                   payload: e
               })
 
             }
           }
       }

      create = (jwt, body, Type, history) => {
        return async dispatch => {
            await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                dispatch({
                    type: PROMO_CODE_TYPES[Type + '_CREATE_F'],
                    payload: response
                })

                history.goBack()

              })
              .catch(e => {
                if (e.response.data && e.response.data.length) {
                  e.response.data.forEach(err => {
                    Notification.error({
                      title: err.param,
                      message: err.message
                    }, 20) 
                  })
                }
                dispatch({
                      type: PROMO_CODE_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })

              })
        }
      }

      generateCode = (jwt, Type) => {
        return async dispatch => {
          try {
            dispatch({
              type: PROMO_CODE_TYPES[Type + '_GET_CODE_P']
            })

            const response = await axios.get(
              this.api + this.rest + "/g",
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              })

              dispatch({
                type: PROMO_CODE_TYPES[Type + '_GET_CODE_F'],
                payload: response.data
              })

          } catch (e) {
              dispatch({
                type: PROMO_CODE_TYPES[Type + '_GET_CODE_R'],
                payload: e
            })
          }
        }
      }

      // clear page cache
      clearCache = () => {
        return async dispatch => {
          dispatch({
            type: PROMO_CODE_TYPES.NEW_PROMO_CODE
          })
        }
      }
}




export default new DiscountActions()