import {INFORMATION_TYPES} from "../../types/index"

const defaultState = {
  pagRemovedInformation: [],
  pagNotRemovedInformation: [],
  pagRemovedInformationCount: 0,
  pagNotRemovedInformationCount: 0,
  tabPanel: localStorage.getItem('informationActiveTab') || '1',
};

const allInformations = (state = [], action = {}) => {
  if (action.type === INFORMATION_TYPES.GET_INFORMATIONS) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedInformations = (state = [], action = {}) => {
  if (action.type === INFORMATION_TYPES.GET_DELETED_INFORMATIONS) {
    return action.payload;
  } else {
    return state
  }
};

const information = (state = {}, action = {}) => {
  if (action.type === INFORMATION_TYPES.GET_INFORMATION_ITEM) {
    return action.payload;
  } else {
    return state
  }
};

const selectedInformation = (state = {}, action = {}) => {
  if (action.type === INFORMATION_TYPES.SELECT_INFORMATION_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const informations = ( state = defaultState, action = {}) => {
  switch (action.type) {
      case INFORMATION_TYPES.GET_PAG_REMOVED_INFORMATIONS: {
          return {
            ...state,
            pagRemovedInformation: action.payload.data,
            pagRemovedInformationCount: action.payload.count

          }
      }

      case INFORMATION_TYPES.GET_PAG_NOT_REMOVED_INFORMATIONS: {
          return {
            ...state,
            pagNotRemovedInformation: action.payload.data,
            pagNotRemovedInformationCount: action.payload.count
          }
      }

      case INFORMATION_TYPES.DELETE_INFORMATION_ITEMS: {
        const id = action.payload.informationId;
        return {
            ...state,
            pagNotRemovedInformation: state.pagNotRemovedInformation.filter(item => item.informationId !== id)
        }
      }

      case INFORMATION_TYPES.RECOVER_INFORMATION_ITEMS: {
        const id = action.payload.informationId;
        state.pagNotRemovedInformation.unshift(action.payload)
        return {
            ...state,
            pagRemovedInformation: state.pagRemovedInformation.filter(item => item.informationId !== id),
            pagNotRemovedInformation: state.pagNotRemovedInformation,
            // pagNotRemovedInformationCount: state.pagNotRemovedInformationCount + 1
        }
      }

      case INFORMATION_TYPES.ARCHIVE_INFORMATION_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            // pagNotRemovedInformationCount: state.pagNotRemovedInformationCount - action.payload.length
        }
      }

      case INFORMATION_TYPES.BULK_RECOVER_INFORMATION_ITEMS: {
          return { 
            ...state,
            bulkDeletedItems: action.payload,
        }
      }

      case INFORMATION_TYPES.DESTROY_INFORMATION: {
        const id = action.payload.informationId;
        return {
            ...state,
            pagRemovedInformation: state.pagRemovedInformation.filter(item => item.informationId !== id),
            loading: false
        }
      }

      case INFORMATION_TYPES.INFORMATION_TAB_PANEL_CHANGE: {
        return {
            ...state,
            tabPanel: action.payload,
            loading: false
        }
      }
  
  
    default:
            return state;
  }
}



export {allInformations,allRemovedInformations,information,selectedInformation, informations}