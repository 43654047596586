import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactSelect from 'react-select';
import { connect } from 'react-redux';
import {
    Card, CardBody,
    CardHeader, 
    Row, Col, Label,
} from 'reactstrap';

import { globalActions, dataFilterActions, storeActions, franchiseActions, businessActions, orderActions, productActions } from '../redux-modules/actions';
import OrderFilterInput from '../filter/FilterInput';
import { DatePicker } from '../catalogs/Globals';
import SelectMultiple from "../globals/SelectMultiple/Select"
import OrderFilterSlider from '../filter/FilterSlider/FilterSlider';

const inputsData = [
    {
        type: 'text',
        key: 'user',
        styleName: 'form-control',
        name: 'User name',
        placeholder: ''
    },
    {
        type: 'text',
        key: 'saleId',
        styleName: 'form-control',
        name: 'Order Id',
        placeholder: ''
    },
    {
        type: 'text',
        key: 'telephone',
        styleName: 'form-control',
        name: 'Phone',
        placeholder: ''
    },
]


const dateDropdownData = [
    {
        type: 'dropdown',
        key: 'status',
        styleName: {
            parent: 'd-flex flex-column',
            label: 'h6 mr-2'
        },
        required: true,
        name: 'Status',
        placeholder: '',
        options: []
    }
]

function OrderFilter(props) { 
    const { statusTab, actionType, purchaseTypes, filter, franchiseLoad, businessLoad, statusList, minMaxValues, account, minMaxData, ordersCount, fetchStoreSeeAll, fetchFranchiseSeeAll, storesSelect, storesCount, franchiseSelect, franchiseCount, statistics, filterTab, storeLoad, fetchBusinessSeeAll, businessCount, businessSelect, getPurchaseTypes, productCount, productsSelect, fetchProductSeeAll } = props
    const [, updateState] = useState()
    const selectRef = useRef([]);
    const [selectedStores, setSelectedStores] = useState([])
    const [selectedBusiness, setSelectedBusines] = useState([])
    const [selectedFranchise, setSelectedFranchise] = useState([])
    const [closeOnSelectStore, setCloseOnSelectStore] = useState(false)
    const [closeOnSelectBusiness, setCloseOnSelectBusiness] = useState(false)
    const [closeOnSelectFranchise, setCloseOnSelectFranchise] = useState(false)
    const [purchaseTypePage, setPurchaseTypePage] = useState(1);
    const [closeOnSelectPurchaseType, setCloseOnSelectPurchaseType] = useState(false);
    const [businessPage, setBusinessPage] = useState(1)
    const [storePage, setStorePage] = useState(1)
    const [franchisePage, setFranchisePage] = useState(1)
    const forceUpdate = useCallback(() => updateState({}), [])

    useEffect(() => {
        filter.status = statusTab === 'all' ? null : statusTab
        minMaxData(account.token, 's/v/m/', { priceMin: true, priceMax: true, ...filter }, actionType, "ORDER")
    }, [statusTab, filterTab, filter])

    useEffect(() => {
        if (statusList.length) {
            statusList.forEach(status => {
                dateDropdownData[0].options.push({ 
                    label: status.name, 
                    value: status.name 
                })
            })
        }
    }, [statusList])

    const handleChangeDate = (event,  key) => {
        const changedKey = key === 'startDate' ? 'dateFrom' : 'dateTo'
        props.filterDataSearch(changedKey, event.target.value, props.actionType)
        props.paginationTable(props.account.token, 
            1, 
            props.showEntries, 
            { ...props.dataFilter, ...props.filter, ...{ [changedKey]: event.target.value, deleted: filterTab}}
        )
        props.orderStatistics(props.account.token, 
            { ...props.dataFilter, ...props.filter, ...{ [changedKey]: event.target.value, deleted: filterTab} }
        )
        props.onCurrentChange(1)
    }

    function changeValue(event, key) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ')
        props.filterDataSearch(key, removeExtraSpace(event.target.value), props.actionType)
        props.paginationTable(props.account.token, 
            1, 
            props.showEntries, 
            { ...props.dataFilter, ...props.filter, ...{ [key]: removeExtraSpace(event.target.value), deleted: filterTab}}
        )
        props.onCurrentChange(1)
    }

    const changeSlider = (value1, key1, value2, key2) => {
        props.filterDataSearch(key1, value1, props.actionType)
        props.filterDataSearch(key2, value2, props.actionType)
        props.paginationTable(props.account.token, 
            1, 
            props.showEntries, 
            { ...props.dataFilter, ...props.filter, ...{ [key1]: value1, [key2]: value2, deleted: filterTab}}
        )
        props.onCurrentChange(1)
        forceUpdate()
    }

    const clearFilter = () => {
        props.resetFilterDataSearch('initialFilter', actionType)
        props.paginationTable(props.account.token, 
            1, 
            props.showEntries, 
            { ...props.dataFilter, ...{deleted: filterTab}}
        )
        props.orderStatistics(props.account.token, 
            { ...props.dataFilter, ...{deleted: filterTab}}
        )
        props.onCurrentChange(1)
        setSelectedBusines([])
        setSelectedStores([])
        setSelectedFranchise([])
        forceUpdate()
    }


    const selectPurchaseType = (selectedOption) => {
        let selectedOptions = []
        let orderTypeIds = []
        const seeMore = selectedOption.find(({value}) => value === 'all')
        selectedOption.forEach(elem => {
            if (elem.value === 'all') {
                setPurchaseTypePage(purchaseTypePage + 1)
                setCloseOnSelectPurchaseType(false)
                getPurchaseTypes(account.token, purchaseTypePage + 1, 10)
            }  else {
                selectedOptions.push(elem)
                orderTypeIds.push(elem.value)
            }
        })
        if (!seeMore) {
            setSelectedStores(selectedOptions)
            props.filterDataSearch('orderTypeIds', orderTypeIds, props.actionType)
            props.paginationTable(props.account.token, 
                1, 
                props.showEntries, 
                { ...props.dataFilter, ...props.filter, ...{ ['orderTypeIds']: orderTypeIds, deleted: filterTab}}
            )
            props.orderStatistics(props.account.token, 
                { ...props.dataFilter, ...props.filter, ...{ ['orderTypeIds']: orderTypeIds, deleted: filterTab}}
            )
            props.onCurrentChange(1)
        }
    }

    const selectStore = (selectedOption) => {
        let selectedOptions = []
        let storeIds = []
        let stores = []
        const seeMore = selectedOption.find(({value}) => value === 'all')
        selectedOption.forEach(elem => {
            if (elem.value === 'all') {
                setStorePage(storePage + 1)
                setCloseOnSelectStore(false)
                fetchStoreSeeAll(account.token, storePage + 1, 10)
            }  else {
                selectedOptions.push(elem)
                storeIds.push(elem.value)
                stores.push(elem)
            }
        })
        if (!seeMore) {
            setSelectedStores(selectedOptions)
            props.filterDataSearch('storeIds', storeIds, props.actionType)
            props.filterDataSearch('stores', stores, props.actionType)
            props.paginationTable(props.account.token, 
                1, 
                props.showEntries, 
                { ...props.dataFilter, ...props.filter, ...{ ['storeIds']: storeIds, deleted: filterTab}}
            )
            props.orderStatistics(props.account.token, 
                { ...props.dataFilter, ...props.filter, ...{ ['storeIds']: storeIds, deleted: filterTab}}
            )
            props.onCurrentChange(1)
        }
    }

    const setSelectedProducts = (selectedOption) => {
        let productIds = [];
        let products = [];
        selectedOption.forEach((product) => {
            productIds.push(product.productId)
            products.push({
                productId: product.productId, 
                checked: product.checked,
                value: product.productId
            })
        })
        props.filterDataSearch('productIds', productIds, props.actionType);
        props.filterDataSearch('products', products, props.actionType);
        props.paginationTable(props.account.token, 
            1, 
            props.showEntries, 
            { ...props.dataFilter, ...props.filter, ...{ ['productIds']: productIds, deleted: filterTab}}
        )
        props.orderStatistics(props.account.token, 
            { ...props.dataFilter, ...props.filter, ...{ ['productIds']: productIds, deleted: filterTab}}
        )
        props.onCurrentChange(1)
    }

    const selectBusiness = (selectedOption) => {
        let selectedOptions = []
        let businessIds = []
        let business = []
        const seeMore = selectedOption.find(({value}) => value === 'all')
        selectedOption.forEach(elem => {
            if (elem.value === 'all') {
                setBusinessPage(businessPage + 1)
                setCloseOnSelectBusiness(false)
                fetchBusinessSeeAll(account.token, businessPage + 1, 10)
            }  else {
                selectedOptions.push(elem)
                businessIds.push(elem.value)
                business.push(elem)
            }
        })
        if (!seeMore) {
            setSelectedBusines(selectedOptions)
            props.filterDataSearch('businessIds', businessIds, props.actionType)
            props.filterDataSearch('business', business, props.actionType)
            props.paginationTable(props.account.token, 
                1, 
                props.showEntries, 
                { ...props.dataFilter, ...props.filter, ...{ ['businessIds']: businessIds, deleted: filterTab}}
            )
            props.orderStatistics(props.account.token, 
                { ...props.dataFilter, ...props.filter, ...{ ['businessIds']: businessIds, deleted: filterTab}}
            )
            props.onCurrentChange(1)
        }
    }

    const selectFranchise = (selectedOption) => {
        const seeMore = selectedOption.find(({value}) => value === 'all')
        let selectedOptions = []
        let franchiseIds = []
        let franchises = []
        selectedOption.forEach(elem => {
            if (elem.value === 'all') {
                setFranchisePage(franchisePage + 1)
                setCloseOnSelectFranchise(false)
                fetchFranchiseSeeAll(account.token, franchisePage + 1, 10)
            }  else {
                selectedOptions.push(elem)
                franchiseIds.push(elem.value)
                franchises.push(elem)
            }
        })
        if (!seeMore) {
            setSelectedFranchise(selectedOptions)
            props.filterDataSearch('franchiseIds', franchiseIds, props.actionType)
            props.filterDataSearch('franchises', franchises, props.actionType)
            props.paginationTable(props.account.token, 
                1,
                props.showEntries, 
                { ...props.dataFilter, ...props.filter, ...{ ['franchiseIds']: franchiseIds, deleted: filterTab}}
            )
            props.orderStatistics(props.account.token, 
                { ...props.dataFilter, ...props.filter, ...{ ['franchiseIds']: franchiseIds, deleted: filterTab}}
            )
            props.onCurrentChange(1)
        }
    }


    function openMenu(index) {

        if (index == 0) {
            if (!businessSelect.length) {
                fetchBusinessSeeAll(account.token, 1, 10);  
            }
        }

        if (index == 1) {
            if (!storesSelect.length) {
                fetchStoreSeeAll(account.token, 1, 10);  
            }
        }


        if (index == 2) {
            if (!franchiseSelect.length) {
                fetchFranchiseSeeAll(account.token, 1, 10);  
            }
        }

        if (index == 3) {
            if (!purchaseTypes.data.length) {
                getPurchaseTypes(account.token, 1, 20)
            }
        }
        
        selectRef.current[index].focus()

    }

    const handleInputChange = (newValue, index) => {

        const inputValue = newValue;

        if (index == 0) {
            fetchBusinessSeeAll(account.token, 1, 10, { name: inputValue }, !!inputValue.length);  
        }

        if (index == 1) {
            fetchStoreSeeAll(account.token, 1, 10, { name: inputValue }, !!inputValue.length)
        }

        if (index == 2) {
            fetchFranchiseSeeAll(account.token, 1, 10, { name: inputValue }, !!inputValue.length);  
        }
        
    };
    

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className='px-3'>
                        <Col className="pointer" onClick={() => props.setIsOpen(`${actionType}_FILTER`)}>
                            <span>Filter</span>
                        </Col>
                        <span className="pr-2 pointer" onClick={() => clearFilter()}>Clear Filter</span>
                        <svg 
                            onClick={() => props.setIsOpen(`${actionType}_FILTER`)} 
                            width="16" 
                            height="16" 
                            className={!!props.isOpen ? "svg__rotate pointer" : "pointer"}>
                            <use xlinkHref={'/assets/svg/sprite.svg#down-arrow'}/>
                        </svg>
                    </Row>
                </CardHeader>
                {
                    props.isOpen &&
                    <CardBody>
                        <div style={{width: "200px"}}>
                            <Label className='h6'>{'Purchase Type'}</Label>
                            <ReactSelect
                                ref={el => selectRef.current[3] = el}
                                onFocus={() => openMenu(3)}
                                closeOnSelect = {closeOnSelectPurchaseType}
                                name="form-field-name"
                                placeholder="Purchase Type"
                                value={props.filter['orderTypeIds']}
                                onInputChange={(e) => handleInputChange(e, 3)}
                                multi = {true}
                                onChange={selectPurchaseType}
                                options={
                                    purchaseTypes.count > purchaseTypes.data.length 
                                    ? [...purchaseTypes.data, { value: 'all', label: 'See More'}]
                                    : [...purchaseTypes.data]
                                }
                            />
                        </div>
                        <div style={{width: "200px"}}>
                            <Label className='h6'>{'Business'}</Label>
                            <ReactSelect
                                ref={el => selectRef.current[0] = el}
                                onFocus={() => openMenu(0)}
                                isLoading={businessLoad}
                                closeOnSelect = {closeOnSelectBusiness}
                                name="form-field-name"
                                value={props.filter['business']}
                                onInputChange={(e) => handleInputChange(e, 0)}
                                multi = {true}
                                onChange={selectBusiness}
                                options={
                                    businessCount > businessSelect.length 
                                    ? [...businessSelect, { value: 'all', label: 'See More'}]
                                    : [...businessSelect]
                                }
                            />
                        </div>

                        <div style={{width: "200px"}}>
                            <Label className='h6'>{'Stores'}</Label>
                            <ReactSelect
                                ref={el => selectRef.current[1] = el}
                                isLoading={storeLoad} 
                                onFocus={() => openMenu(1)}
                                closeOnSelect = {closeOnSelectStore}
                                name="form-field-name"
                                value={props.filter['stores']}
                                onInputChange={(e) => handleInputChange(e, 1)}
                                multi = {true}
                                onChange={selectStore}
                                options={
                                    storesCount > storesSelect.length 
                                    ? [...storesSelect, { value: 'all', label: 'See More'}]
                                    : [...storesSelect]
                                }
                            />
                        </div>
{/* 
                        <div style={{width: "350px"}}>
                            <SelectMultiple
                                label = "Products"
                                placeholder = "Products"
                                options = { productsSelect }
                                count = { productCount }
                                fetchData = { (token, page, count, query, search) => fetchProductSeeAll(token, page, count, {...query, storeIds: props.filter['storeIds']}, search) }
                                setValue = { (e) =>  setSelectedProducts(e) }
                                account = { account }
                                storeIds = { props.filter['storeIds'] }
                                actives = { props.filter['products'] || [] }
                                multiple
                            />
                        </div> */}

                        {
                            inputsData.map((elem, index) => 
                                <OrderFilterInput 
                                    key={index}
                                    styleName = {'h6'}
                                    elem = {elem}
                                    value = {filter[elem.key]}
                                    changeValue = {changeValue}
                                />
                            )
                        }
                        
                        <div className="mt-2" style={{width: '60%'}}>
                            <Label className={'h6 mr-2'} >{'Dates'}</Label>
                            <DatePicker  
                                handleChange={handleChangeDate}
                                value={{
                                    startDate: filter.dateFrom,
                                    endDate: filter.dateTo 
                                }}
                            />
                        </div>

                        {
                            !!Object.keys(minMaxValues).length && minMaxValues.minPrice !== minMaxValues.maxPrice &&
                            <OrderFilterSlider
                                actionType = { actionType }
                                minKey = { 'minPrice' }
                                maxKey = { 'maxPrice' }
                                minMaxValues = { minMaxValues }
                                min = { minMaxValues.minPrice || 0 }
                                max = { Math.round(minMaxValues.maxPrice) || 0 }
                                minPrice = { filter.minPrice }
                                maxPrice = { filter.maxPrice }
                                style = {{width: '240px'}}
                                minRange={1}
                                step={1}
                                color = { '#1985ac' }
                                onChange={(state)=>{
                                    console.log('react-dual-rangeslider max: ', state.max);
                                }}
                                changeSlider = { changeSlider }
                                minMaxPrice = { minMaxValues } 
                            />
                        }

                        <div style={{width: "200px"}}>
                            <Label className='h6'>{'Franchise'}</Label>
                            <ReactSelect
                                ref={el => selectRef.current[2] = el}
                                onFocus={() => openMenu(2)}
                                isLoading={franchiseLoad}
                                closeOnSelect = {closeOnSelectFranchise}
                                name="form-field-name"
                                value={props.filter['franchises']}
                                onInputChange={(e) => handleInputChange(e, 2)}
                                multi = {true}
                                onChange={selectFranchise}
                                options={
                                    franchiseCount > franchiseSelect.length 
                                    ? [...franchiseSelect, { value: 'all', label: 'See More'}]
                                    : [...franchiseSelect]
                                }
                            />
                        </div>
                        <div style={{width: "200px", height: "170px"}}>
                            {
                                !!statistics && !!props.showStatistics &&
                                <div style={{width: "200px"}}>
                                    <span>Total: {Math.round((statistics.taxTotal) * Math.pow(10,0)) / Math.pow(10,0) - Number.parseFloat(statistics.discountedTotal).toFixed(1) + Math.round((statistics.shippingTotal) * Math.pow(10,0)) / Math.pow(10,0) + Math.round((statistics.priceTotal) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'}</span>
                                    <br/>
                                    <span>Tax {Math.round((statistics.taxTotal) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'}</span>
                                    <br/>
                                    <span>Shipping {Math.round((statistics.shippingTotal) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'}</span>
                                    <br/>
                                    <span>Min price {Math.round((statistics.minPrice) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'}</span>
                                    <br/>
                                    <span>Max price {Math.round((statistics.maxPrice) * Math.pow(10,0)) / Math.pow(10,0) + ' USD'}</span>
                                    <br/>
                                    <span>Count {ordersCount}</span>
                                    <br/>
                                    <span>Avg {statistics.taxTotal ? ((Math.round((statistics.taxTotal) * Math.pow(10,0)) / Math.pow(10,0) + Math.round((statistics.shippingTotal) * Math.pow(10,0)) / Math.pow(10,0) + Math.round((statistics.priceTotal) * Math.pow(10,0)) / Math.pow(10,0)) / ordersCount).toFixed(0) + ' USD': '0 USD'}</span>
                                    <br/>
                                    <span>Custom discount {Number.parseFloat(statistics.discountedTotal).toFixed(1) + ' USD'}</span>
                                </div>
                            }
                        </div>
                    </CardBody>
                }
                
                
            </Card>
        </div>
    );
  }
  

/**
 * @name mapStateToProps
 * @param dispatch
 * @returns {{Languages}}
 */
function mapStateToProps(state) {

    return {
        account: state.account,
        minMaxValues: state.newOrders.minMaxValues,
        purchaseTypes: state.newOrders.purchaseTypes,
        stores: state.stors.data,
        storeLoad: state.stors.loading,
        storesSelect: state.stors.storesSelect,
        storesCount: state.stors.count,
        businessSelect: state.business.businessSelect,
        businessCount: state.business.count,
        franchiseSelect: state.franchises.franchiseSelect,
        franchiseCount: state.franchises.pagNotRemovedCount,
        franchiseLoad: state.franchises.franchiseLoad,
        businessLoad: state.business.businessLoad,
        productsSelect: state.products.productSelect,
        productCount: state.products.pagNotRemovedProductCount
    }
    
}

/**
 * @name mapDispatchToProps
 * @param dispatch
 * @returns {{fetchReviews, updateReviews}}
 */
function mapDispatchToProps(dispatch) {
	return {
        fetchProductSeeAll: (token, page, count, filter, search) => dispatch(productActions.fetchProductSeeAll(token, page, count, filter, search)),
        setIsOpen: (actionType) => dispatch(globalActions.isOpen(actionType)),
        fetchStoreSeeAll: (token, page, count, filter, search) => dispatch(storeActions.seeAll(token, page, count, filter, search)),
        fetchBusinessSeeAll: (token, page, count, filter, search) => dispatch(businessActions.seeAll(token, page, count, filter, search)),
        fetchFranchiseSeeAll: (token, page, count, filter, search) => dispatch(franchiseActions.seeAll(token, page, count, filter, search)),
        filterData: (key, value, actionType ) => dispatch(globalActions.filterData(key, value, actionType)),
        filterDataSearch: (key, value, actionType ) => dispatch(globalActions.filterDataSearch(key, value, actionType)),
        minMaxData: (jwt, route, query, actionType, API_KEY) => dispatch(dataFilterActions.minMaxData(jwt, route, query, actionType, API_KEY)),
        resetFilterDataSearch: (key, actionType) => dispatch(globalActions.resetFilterDataSearchAll(key, actionType)),
        getPurchaseTypes: (jwt, page, count) => dispatch(orderActions.getTypes(jwt, page, count))
	};
}


export default connect(mapStateToProps,mapDispatchToProps)(OrderFilter)