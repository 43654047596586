import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, CardBody,
    Row,
    Col, Table, Button
} from 'reactstrap';
import config from "../../../../../config/app";
import { productActions, purchaseActions } from '../../../../redux-modules/actions'
import Add from '../../../../sales/Orders/OrderItem/Add/Add';
import Options from '../../../../sales/Orders/OrderItem/Option/Options';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../catalogs/Globals/Pagination/Pagination';
import SelectDropdown from '../../../../catalogs/Globals/Select/Select';
import ProductFilter from '../../../../catalogs/utils/Filter/ProductFilter';
import './style.scss';

const getOptions = (optionIds, product) => {
    let price = Math.round(product.currentPrice);
    const options = [];
    const productOptions = [];
    product.options.forEach(({required, values, option}) => {
        values.filter((value) => optionIds.includes(value.optionValueId)).forEach((value) => {
                value.price = Math.round(value.price);
                if (!value.pricePrefix) {
                    price = value.price;
                } else {
                    options.push({...value, required, type: option.type});
                }
            productOptions.push({
                optionId: option.optionId,
                productOptionId: value.productOptionId,
                productOptionValueId: value.value,
                name: value.label,
                type: option.type,
                value: value.price,
                option: {
                    source: option.source
                },
                options: {
                    ...option,
                    values: values
                }
            })
        });
    });
    return {
        options,
        price,
        productOptions
    };
}

function List() {
    const [, forceUpdate] = useState()
    const { account } = useSelector((state) => state);
    const { loading, productSelect: data, pagNotRemovedProductCount: count, isOpen, initialFilter } = useSelector(state => state.products)
    const { oneData } = useSelector((state) => state.purchase)
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [filterTab, setFilterTab] = useState(true);
    const [showedCount, setShowedCount] = useState(10);

    const arrsMatch = (arr1, arr2) => {
        return arr1.sort().toString() === arr2.sort().toString()
    }

    const onCurrentChange = (value) => {
        dispatch(productActions.fetchProductSeeAll(account.token, value, showedCount, { ...initialFilter, filterTab: !filterTab }, true ))
        setPage(value)
    }

    useEffect(() => {
        dispatch(productActions.fetchProductSeeAll(account.token, page, showedCount, { ...initialFilter, filterTab: !filterTab }, true ))
    }, [showedCount, filterTab])

    useEffect(() => {
        dispatch(productActions.fetchProductSeeAll(account.token, 1, showedCount, { ...initialFilter, filterTab: !filterTab }, true ))
        setPage(1)
    }, [initialFilter])

    function changeFilterTab(event) {
        setFilterTab(event)
    }

    const changeOptions = (optionIds, product) => {
        const calculateSum = (arr, field) => arr.reduce((prev, curr) => prev + curr[field], 0);
        let {options, price} = getOptions(optionIds, product);
        class OptionCollection extends Array {
          sum(key) {
            return this.reduce((a, b) => a + b['pricePrefix'] + (b[key] || 0), 0);
          }
        }
        price = price + calculateSum(new OptionCollection(...options), 'price');
        product.price = price
        product.activeOptions = optionIds
        forceUpdate({})
    }
    
    return (
        <div className="animated fadeIn" style={{ maxHeight: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
            <ProductFilter 
                actionType = { 'PRODUCT' }
                isOpen = { isOpen }
                filter = { initialFilter }
                showEntries = { showedCount }
                page = { page }
                onCurrentChange={ onCurrentChange }
            />
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col col="6" sm="12" md="6" xl className="my-1">
                                    <Button 
                                        style={{margin: "0px 10px 10px 0px"}} 
                                        color="ghost-primary"
                                        className={filterTab ? 'active' : ''}
                                        onClick={() => changeFilterTab(true)}
                                    >
                                        <i className="fa fa-align-justify mr-2"> </i>
                                        All published Products
                                    </Button>
                                    <Button 
                                        style={{marginBottom: "10px"}} 
                                        color="ghost-primary" 
                                        className={filterTab ? '' : 'active'}
                                        onClick={() => changeFilterTab(false)}
                                    >
                                        <i className="fa fa-file-archive-o fa-lg mr-2"></i>
                                        Archived Products
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table hover bordered striped responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Options</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((elem, i) => {
                                            const selected = oneData.purchaseProducts.find((e) => e.productId == elem.productId ? arrsMatch(e.activeOptions, elem.activeOptions) : ( e.productId == elem.productId ))

                                            return (
                                            <tr key={i}>
                                                <td>
                                                    {elem.productId}
                                                </td>
                                                <td style={{ maxWidth: '150px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        {
                                                            elem.imageMain[0] &&
                                                            <img
                                                                style={{ width: '50px' }}
                                                                src={config.IMAGEURL + elem.imageMain[0].image.url} 
                                                                alt=""
                                                            />
                                                        }
                                                        <p style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                                                            {
                                                                elem.descriptions[0] &&
                                                                elem.descriptions[0].name
                                                            }
                                                        </p>
                                                    </div>
                                                </td>
                                                <td style = {elem.price == 0 ? {color: 'red'}: {} }>
                                                    {
                                                        '$' + Math.floor(elem.price)
                                                    }
                                                </td>
                                                <td>
                                                    <div style={{ width: '30px' }}>
                                                        <Options
                                                            product={elem}
                                                            updateProduct={(value) => {
                                                                dispatch({
                                                                    type: 'PURCHASE_ADD_PRODUCT',
                                                                    payload: value
                                                                })
                                                                if (oneData.supplierId) {
                                                                    dispatch(purchaseActions.getSupplierProductCost(account.token, oneData.supplierId, { productIds: [elem.productId] }))
                                                                }
                                                            }}
                                                            changeOptions={changeOptions}
                                                        /> 
                                                    </div>
                                                </td>
                                                <td style={{ width: '130px' }}>
                                                    <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Add 
                                                            style= {{className: 'product__btn'}}
                                                            updateProduct={(value) => {
                                                                dispatch({
                                                                    type: 'PURCHASE_ADD_PRODUCT',
                                                                    payload: value
                                                                })
                                                                if (oneData.supplierId) {
                                                                    dispatch(purchaseActions.getSupplierProductCost(account.token, oneData.supplierId, { productIds: [elem.productId] }))
                                                                }
                                                            }}
                                                            item={selected || elem}
                                                            backgroundColor="#20A8D8"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </Table>
                            {
                                (count > showedCount) &&
                                <div className="pagination-block">
                                    <Pagination
                                        layout="sizes, prev, pager, next" 
                                        total={ count }
                                        pageSize = { showedCount } 
                                        currentPage={ page }
                                        onCurrentChange = { (e) => onCurrentChange(e) }
                                    />
                                    <SelectDropdown 
                                        data = {['10', '20', '30', '40', '50', '100']}
                                        disabled={false}
                                        name={'selectOpen'} 
                                        value={ showedCount }
                                        changeSelect={(val) => setShowedCount(val)}
                                    />
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default List