import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    return (
      <React.Fragment>
        <div className="w-100 d-flex justify-content-between flex-wrap">
          <div>&copy; {new Date().getFullYear()} , Project Nulla. Powered by <a href="https://www.projectnulla.info/" target='_blank' rel="noopener"> Project Nulla</a></div>
        </div>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
