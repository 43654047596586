import {attributeGroupService} from "../services/attributeGroupService";
import {ATTRIBUTE_GROUP_TYPES} from "../types"
import { Notification } from 'element-react'


const getAll = (token) => {
  return dispatch => {
    return attributeGroupService.getAll(token).then(options => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.GET_ALL_ATTRIBUTE_GROUP,
        payload : options
      })
    })
  }
};

const getAllRemoved = (token) => {
  return dispatch => {
    return attributeGroupService.getAllRemoved(token).then(options => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.GET_ALL_REMOVED_ATTRIBUTE_GROUP,
        payload : options
      })
    })
  }
};


const getItem = (id,token) => {
  return dispatch => {
    return attributeGroupService.getItem(id,token).then(option => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.GET_ATTRIBUTE_GROUP_ITEMS,
        payload : option
      })
    })
  }
};

const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : ATTRIBUTE_GROUP_TYPES.SELECT_ATTRIBUTE_GROUP_ITEMS,
      payload : elem
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return attributeGroupService.getPaginationNotRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.GET_PAG_NOT_REMOVED_ATTRIBUTES_GROUP,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, filter) => {
  return dispatch => {
    return attributeGroupService.getPaginationRemoved(jwt, page, count, filter).then(options => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.GET_PAG_REMOVED_ATTRIBUTES_GROUP,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt) => {
  return dispatch => {
    attributeGroupService.remove(elem.attributeGroupId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.DELETE_ATTRIBUTE_GROUP_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    attributeGroupService.recover(elem.attributeGroupId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.RECOVER_ATTRIBUTE_GROUP_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    attributeGroupService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.ARCHIVE_ATTRIBUTE_GROUP_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    attributeGroupService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.BULK_RECOVER_ATTRIBUTE_GROUP_ITEMS,
        payload : data
      })
    })
  }
};

const destroyAttributeGroup = ( token, attributeGroup ) => {

  return async dispatch => {

    try {

      const data = await attributeGroupService.destroy(token , attributeGroup.attributeGroupId)

        Notification.success( {
          title: 'attributeGroup ',
          message: `${attributeGroup.source} attribute group succesfuly deleted `
        }, 20 )

        dispatch( {
            type: ATTRIBUTE_GROUP_TYPES.DESTROY_ATTRIBUTE_GROUP,
            payload: attributeGroup,
            data: data
        } )
        
    } catch (e) {

        Notification.error( {
          title: e.name,
          message: `attribute group not deleted`
        }, 20) 
       
      }
    }
  }

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    attributeGroupService.bulkDestroy(ids,  jwt).then((data) => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.BULK_DESTROY_ATTRIBUTE_GROUP_ITEMS,
        payload : data
      })
    })
  }
};

  /**
 * @name fetchAttributeGroupSeeAll
 * @returns {function(*)}
 */
const fetchAttributeGroupSeeAll = (jwt, page, count, filter, search) => {
  return dispatch => {
    return attributeGroupService.getPaginationNotRemoved(jwt, page, count, filter).then(data => {
      return dispatch({
        type: ATTRIBUTE_GROUP_TYPES.FETCH_ATTRIBUTE_GROUP_SEE_ALL,
        payload : data,
        search: search
      })
    })
  }
};

export {getAll,getAllRemoved,getItem,selectItem,getPaginationNotRemoved, getPaginationRemoved, deleteItem, recoverItem, bulkDelete, bulkRecover, destroyAttributeGroup, fetchAttributeGroupSeeAll, bulkDestroy}