import {BRAND_TYPES} from '../../types/index'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const defaultState = {
    pagRemovedBrand: [],
    pagNotRemovedBrand: [],
    pagRemovedBrandCount: 0,
    pagNotRemovedBrandCount: 0,
    selectedItem: {},
    brandSelect: [],
    tabPanel: localStorage.getItem('brandActiveTab') || '1',
    initialFilter: {
        name: '',
        email: '',
        phone: '',
        businesType: '',
        licenseType: '',
        status: null,
        dates:  '',
        minPrice: '',
        maxPrice: ''
    },
    dataFilter: {
        brandId: null,
        nameF: '',
        emailF: '',
        phoneF: '',
        businesTypeF: '',
        licenseTypeF: '',
        statusF: null,
        dateAdded:  '',
        sortorder: null
    },
    minMaxValues: {},
    isOpen: isMobile ? false : localStorage.getItem('brandFilter') == 'true' ? true : false,
    licenseTypesSelect: [],
    businesTypesSelect: []
};

const brands = ( state = defaultState, action ={} ) => {
    switch (action.type) {

        case BRAND_TYPES.GET_PAG_REMOVED_BRANDS: {

            return {
                ...state,
                pagRemovedBrand: action.payload.data,
                pagRemovedBrandCount: action.payload.count
            }

        }

        case BRAND_TYPES.GET_PAG_NOT_REMOVED_BRANDS: {

            return {
                ...state,
                pagNotRemovedBrand: action.payload.data,
                pagNotRemovedBrandCount: action.payload.count
            }

        }

        case BRAND_TYPES.DELETE_BRAND_ITEMS: {

            const id = action.payload[0];

            return {
                ...state,
                pagNotRemovedBrand: state.pagNotRemovedBrand.filter(item => item.brandId !== id)
            }

        }

        case BRAND_TYPES.SELECT_BRAND_ITEMS: {

            return {
            ...state,
            selectedItem: action.payload
            }

        }

        case BRAND_TYPES.RECOVER_BRAND_ITEMS: {

            const id = action.payload[0];
            return {
                ...state,
                pagRemovedBrand: state.pagRemovedBrand.filter(item => item.brandId !== id),
                pagNotRemovedBrandCount: state.pagNotRemovedBrandCount + 1
            }

        }

        case BRAND_TYPES.ARCHIVE_BRAND_ITEMS: {
            return { 
                ...state,
                bulkDeletedItems: action.payload,
                pagNotRemovedBrandCount: state.pagNotRemovedBrandCount - action.payload.length
            }
        }

        case BRAND_TYPES.BULK_RECOVER_BRAND_ITEMS: {

            return { 
                ...state,
                bulkDeletedItems: action.payload,
                pagRemovedBrandCount: state.pagRemovedBrandCount - action.payload.length
            }
        }

        case BRAND_TYPES.FETCH_BRAND_SEE_ALL: {

            const newBrands = []
            action.payload.data.forEach(brand => {
                if (brand.descriptions.length) {
                    newBrands.push({ 
                        value: brand.brandId, 
                        label: brand.descriptions[0].name 
                    })
                }
            })
  
            let brandSelect = [...state.brandSelect, ...newBrands].filter((elem, index, self) =>
                index === self.findIndex((e) => (
                    e.value === elem.value
                ))
            )
    
            return {
                ...state,
                brandSelect: brandSelect,
                pagNotRemovedBrandCount: action.payload.count,
                pagNotRemovedCount: action.payload.count
            }
        }

        case BRAND_TYPES.BULK_DESTROY_BRAND_ITEMS: {
            const id = action.payload[0];
            return {
                ...state,
                pagRemovedBrand: state.pagRemovedBrand.filter(item => item.brandId !== id),
            }
        }


        case BRAND_TYPES.BRAND_TAB_PANEL_CHANGE: {
            return {
                ...state,
                tabPanel: action.payload,
                loading: false
            }
        }

        case BRAND_TYPES.BRAND_FILTER_IS_OPEN: {
            localStorage.setItem('brandFilter', !state.isOpen)
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        case BRAND_TYPES.BRAND_DATA_FILTER: {
            return { 
                ...state,
                dataFilter: { [action.payload['key']]: action.payload['value'] },
                error: []
            }
        }

        case BRAND_TYPES.BRAND_DATA_FILTER_SEARCH: {
            return { 
                ...state,
                initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
                error: []
            }
        }

        case BRAND_TYPES.BRAND_DATA_MIN_MAX_F: {
            return {
                ...state,
                minMaxValues: action.payload
            }
        }

        case  BRAND_TYPES.BRAND_SELECT_FILTER_TYPES: {
            function getUnique(array, key) {
                return array.reduce((acc, cur) => [
                ...acc.filter((obj) => obj[key] !== cur[key]), cur
                ], [])
            }

            return {
                ...state,
                licenseTypesSelect: getUnique(action.payload, 'licenseType'),
                businesTypesSelect: getUnique(action.payload, 'type')
            }
        }


        case BRAND_TYPES.BRAND_DATA_FILTER_SEARCH_RESET: {
            const { key } = action.payload
            return {
                ...state,
                [key]: Object.assign({}, state.initialFilter, { name: "" })
            }
        }

        case BRAND_TYPES.BRAND_DATA_FILTER_ALL_SEARCH_RESET: {
            const { key } = action.payload
            return {
                ...state,
                [key]: { 
                    name: '',
                    email: '',
                    phone: '',
                    businesType: '',
                    licenseType: '',
                    status: null,
                    dates:  '',
                    minPrice: '',
                    maxPrice: '',
                    dateTo: '',
                    dateFrom: ''
                }
            }
        }
  
    default:
        return state;
    }
}

export {
    brands
}
