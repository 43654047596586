export default class {
    constructor () {
      this.to = []
      this.message = '',
      this.sendDate = null,
      this.links = [],
      this.phones = [],
      this.texts = [""],
      this.customerGroups = [],
      this.name = '',
      this.smsCount = 0,
      this.price = 0
    }
  }