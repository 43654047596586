const BULK_CALL_PAG_F = 'BULK_CALL_PAG_F';
const BULK_CALL_PAG_R = 'BULK_CALL_PAG_R';
const BULK_CALL_SIZE_CHANGE = 'BULK_CALL_SIZE_CHANGE'
const BULK_CALL_PAGE_CHANGE = 'BULK_CALL_PAGE_CHANGE'
const BULK_CALL_FILETR_TABE_CHANGE = 'BULK_CALL_FILETR_TABE_CHANGE'
const BULK_CALL_CHECKBOX_CHANGE = 'BULK_CALL_CHECKBOX_CHANGE'
const BULK_CALL_BULK_ARCIVE_F = 'BULK_CALL_BULK_ARCIVE_F'
const BULK_CALL_BULK_ARCIVE_R = 'BULK_CALL_BULK_ARCIVE_R'
const BULK_CALL_BULK_DESTROY_F = 'BULK_CALL_BULK_DESTROY_F'
const BULK_CALL_BULK_DESTROY_R = 'BULK_CALL_BULK_DESTROY_R'
const BULK_CALL_TAB_PANEL_CHANGE = 'BULK_CALL_TAB_PANEL_CHANGE'
const BULK_CALL_DATA_FILTER = 'BULK_CALL_DATA_FILTER'
const BULK_CALL_DELETE_F = 'BULK_CALL_DELETE_F'
const BULK_CALL_FILTER_IS_OPEN = 'BULK_CALL_FILTER_IS_OPEN'
const BULK_CALL_DATA_FILTER_SEARCH = 'BULK_CALL_DATA_FILTER_SEARCH'
const BULK_CALL_SELECT_FILTER_TYPES = 'BULK_CALL_SELECT_FILTER_TYPES'
const BULK_CALL_DATA_FILTER_ALL_SEARCH_RESET = 'BULK_CALL_DATA_FILTER_ALL_SEARCH_RESET'
const BULK_CALL_NEW = 'BULK_CALL_NEW'
const BULK_CALL_SEND_P = 'BULK_CALL_SEND_P'
const BULK_CALL_SEND_F = 'BULK_CALL_SEND_F'
const BULK_CALL_SEND_R = 'BULK_CALL_SEND_R'
const BULK_CALL_GET_ONE_P = 'BULK_CALL_GET_ONE_P'
const BULK_CALL_GET_ONE_F = 'BULK_CALL_GET_ONE_F'
const BULK_CALL_GET_ONE_R = 'BULK_CALL_GET_ONE_R'
const UPDATE_BULK_CALL = 'UPDATE_BULK_CALL'
const BULK_CALL_STATISTICS_P = 'BULK_CALL_STATISTICS_P'
const BULK_CALL_STATISTICS_F = 'BULK_CALL_STATISTICS_F'
const BULK_CALL_STATISTICS_R = 'BULK_CALL_STATISTICS_R'

export {
    BULK_CALL_PAG_R,
    BULK_CALL_PAG_F,
    BULK_CALL_SIZE_CHANGE,
    BULK_CALL_PAGE_CHANGE,
    BULK_CALL_FILETR_TABE_CHANGE,
    BULK_CALL_CHECKBOX_CHANGE,
    BULK_CALL_BULK_ARCIVE_F,
    BULK_CALL_BULK_ARCIVE_R,
    BULK_CALL_BULK_DESTROY_R,
    BULK_CALL_BULK_DESTROY_F,
    BULK_CALL_TAB_PANEL_CHANGE,
    BULK_CALL_DATA_FILTER,
    BULK_CALL_DELETE_F,
    BULK_CALL_FILTER_IS_OPEN,
    BULK_CALL_DATA_FILTER_SEARCH,
    BULK_CALL_SELECT_FILTER_TYPES,
    BULK_CALL_DATA_FILTER_ALL_SEARCH_RESET,
    BULK_CALL_NEW,
    BULK_CALL_SEND_P,
    BULK_CALL_SEND_R,
    BULK_CALL_SEND_F,
    BULK_CALL_GET_ONE_P,
    BULK_CALL_GET_ONE_F,
    BULK_CALL_GET_ONE_R,
    UPDATE_BULK_CALL,
    BULK_CALL_STATISTICS_P,
    BULK_CALL_STATISTICS_F,
    BULK_CALL_STATISTICS_R,
}