import  {FRANCHISE_TYPES} from '../../types'
import  {franchiseService} from '../../services/franchiseService'
import {Notification} from 'element-react'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

/**
 * @name selectElem
 * @description select franchise item that edit item
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : FRANCHISE_TYPES.SELECT_FRANCHISE_ITEMS,
      payload: elem
    })
  }
};


const getItem = (id, token) => {
  return dispatch => {
    return franchiseService.getItem(id, token).then(data => {
      return dispatch({
        type: FRANCHISE_TYPES.GET_FRANCHISE_ITEM,
        payload: data
      })
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return franchiseService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: FRANCHISE_TYPES.GET_PAG_NOT_REMOVED_FRANCHISES,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return franchiseService.getPaginationRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: FRANCHISE_TYPES.GET_PAG_REMOVED_FRANCHISES,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const arcive = (ids, jwt) => {
  return dispatch => {
    franchiseService.bulkDelete(ids,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: FRANCHISE_TYPES.DELETE_FRANCHISE_ITEMS,
        payload : ids
      })
    }
    })
  }
};

/**
 * @name recover
 * @param elem
 * @returns {function(*)}
 */
const recover = (ids, jwt) => {
  return dispatch => {
    franchiseService.bulkRecover(ids,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: FRANCHISE_TYPES.RECOVER_FRANCHISE_ITEMS,
        payload : ids
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    franchiseService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: FRANCHISE_TYPES.ARCHIVE_FRANCHISE_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    franchiseService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: FRANCHISE_TYPES.BULK_RECOVER_FRANCHISE_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    franchiseService.bulkDestroy(ids,  jwt).then((_data) => {
      return dispatch({
        type: FRANCHISE_TYPES.BULK_DESTROY_FRANCHISE_ITEMS,
        payload : ids
      })
    })
  }
};

const newBrand = (language) => {
  return async dispatch => {
      try {
          dispatch({
              type: FRANCHISE_TYPES.NEW_FRANCHISE_FULFILLED,
              payload: language
          })
      } catch (e) {
          dispatch({
              type: FRANCHISE_TYPES.NEW_FRANCHISE_REJECTED,
              payload: e
          });
      }
  }
}

const saveBrand = (franchise, jwt, history, discard) => {
  return async dispatch => {
    franchiseService.create(franchise, jwt)
        .then(data => {

          Notification.success({
            title: 'Created',
            message: 'Your franchise succesfuly created',
          }, 20)

          if (discard) {
            history.push(`${discard}`)
          } else {
            history.push('/store/franchise')
          }

          dispatch({
            type: FRANCHISE_TYPES.SAVE_FRANCHISE_FULFILLED,
            payload: data
          })

          dispatch({
            type: FRANCHISE_TYPES.FRANCHISE_TAB_PANEL_CHANGE,
            payload:  '1'
          }) 

        })
        .catch(error => {
            if (error.response.status === 422) {
              error.response.data.forEach(err => {
                Notification.error({
                  title: err.path,
                  message: err.message
                }, 20)
              })
            }   
          })
        }
    }

const fetchBrandId = (id, token, language) => {
  return async dispatch => {
      try {
            dispatch({
              type: FRANCHISE_TYPES.FETCH_FRANCHISE_PENDING
            })
          const data = await franchiseService.getItem(id, token)
          dispatch({
              type: FRANCHISE_TYPES.FETCH_FRANCHISE_FULFILLED,
              payload: {data, language}
          })
      } catch (e) {
          dispatch({
              type: 'FETCH_FRANCHISE_REJECTED'
          });
      }
  }
}

const updateBrand = (franchise, id, token, history, discard) => {
  return async dispatch => {
    try {
      let data = await franchiseService.edit(franchise, id, token)

        Notification.warning({
          title: 'Updated',
          message: 'Your franchise succesfuly updated '
        }, 20)

        if (discard) {
          history.push(`${discard}`)
        } else {
          history.push('/store/franchise')
        }
        dispatch({
            type: FRANCHISE_TYPES.UPDATE_FRANCHISE_FULFILLED,
            payload: data
        })

        dispatch({
          type: FRANCHISE_TYPES.FRANCHISE_TAB_PANEL_CHANGE,
          payload:  '1'
        }) 
        
    } catch (error) {
        if (error.response.status === 422) {
          error.response.data.forEach(err => {
            Notification.error({
              title: err.path,
              message: err.message
            }, 20)
          })
        }  
       
      }
    }
  }

  const changeLangTab = ( id, LangTab ) => {
    return async dispatch => {
          dispatch({
            type: FRANCHISE_TYPES.LANG_TAB,
            payload: { 
                languageId: id, 
                LangTab: LangTab 
            }
        })
      }
  }

/**
   * @name seeAll
   * @returns {function(*)}
*/
const seeAll = (jwt, page, count, query, search) => {
  return dispatch => {
    dispatch({
      type: FRANCHISE_TYPES.FETCH_FRANCHISE_SEE_ALL_P
    })
    return franchiseService.getPaginationNotRemoved(jwt, page, count, query)
    .then(options => {
      return dispatch({
        type: FRANCHISE_TYPES.FETCH_FRANCHISE_SEE_ALL_F,
        payload : options,
        search: search
      })
    })
    .catch(err => {
      return dispatch({
        type: FRANCHISE_TYPES.FETCH_FRANCHISE_SEE_ALL_R
      })
    })
  }
};



  const destroyBrand = ( token, franchise ) => {

    return async dispatch => {

      try {

        const data = await franchiseService.destroy(token , franchise.franchiseId)
  
          Notification.success( {
            title: 'franchise ',
            message: `${franchise.name} franchise succesfuly deleted `
          }, 20 )

          dispatch( {
              type: FRANCHISE_TYPES.DESTROY_FRANCHISE,
              payload: franchise,
              data: data
          } )
          
      } catch (e) {

          Notification.error( {
            title: e.name,
            message: `${e.parent.column}`
          }, 20) 
         
        }
      }
    }

    const getTypes = ( token ) => {

      return async dispatch => {
  
        try {
  
          const { data } = await franchiseService.getTypes(token)
    
          dispatch( {
              type: FRANCHISE_TYPES.FRANCHISE_SELECT_FILTER_TYPES,
              payload: data
          } )

        } catch (e) {
            // Notification.error( {
            //   title: e.name,
            //   message: `${e.parent.column}`
            // }, 20) 
          }
        }
      }

/**
 * @name getStatistic
 * @returns {function(*)}
*/
const getStatistic = (jwt, franchiseId, query) => {
  return async dispatch => {
    try {

      const searchString = qs.stringify(query)

      dispatch({
        type: FRANCHISE_TYPES.GET_FRANCHISE_ORDER_STATISTICS_P,
      })

      const response = await axios.get(`${config["FRANCHISE"]}f/s/${franchiseId}?${searchString}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      })

      dispatch({
        type: FRANCHISE_TYPES.GET_FRANCHISE_ORDER_STATISTICS_F,
        payload: response
      })

    } catch (e) {
      dispatch({
        type: FRANCHISE_TYPES.GET_FRANCHISE_ORDER_STATISTICS_R,
        payload: e
      })
    }
  }
};

/**
 * @name getStatistic
 * @returns {function(*)}
*/
const getAllStatistic = (jwt, franchiseId, query) => {
  return async dispatch => {
    try {

      const searchString = qs.stringify(query)

      dispatch({
        type: FRANCHISE_TYPES.GET_FRANCHISE_ORDER_ALL_STATISTICS_P,
      })

      const response = await axios.get(`${config["FRANCHISE"]}f/o/${franchiseId}?${searchString}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      })

      dispatch({
        type: FRANCHISE_TYPES.GET_FRANCHISE_ORDER_ALL_STATISTICS_F,
        payload: response.data
      })

    } catch (e) {
      dispatch({
        type: FRANCHISE_TYPES.GET_FRANCHISE_ORDER_ALL_STATISTICS_R,
        payload: e
      })
    }
  }
};


export {
    getItem,  
    selectItem, 
    getPaginationNotRemoved,
    seeAll, 
    getPaginationRemoved, 
    arcive, 
    recover, 
    bulkDelete, 
    bulkRecover, 
    newBrand, 
    saveBrand, 
    fetchBrandId, 
    updateBrand, 
    changeLangTab, 
    destroyBrand, 
    bulkDestroy,
    getTypes,
    getStatistic,
    getAllStatistic
}