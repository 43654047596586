export default class {
    constructor () {
      this.notificationSoundId = ''
      this.name = ''
      this.fileId = ''
      this.status = true
      this.deleted = false
      this.dataFilter = [
        {
          name: 'Name',
          field: 'name',
          tag: 'input',
          label: true,
          type: 'text',
          color: '',
          value: ''
        }
      ]
    }
  }