
import { FILE_TYPES } from "../types"
import config from '../../../config/app';
import axios from 'axios';

/**
   * @name uploadFile
   * @returns {function(*)}
*/
const uploadFile = (files, options) => {
  return async dispatch => {
    try {

      dispatch({
        type: FILE_TYPES.UPLOAD_FILES_P
      })

      const formData = new FormData()

      for(let file of files) {
        formData.append('file', file)
      }
      
      const { data } = await axios.post(
        config['MEDIA'] + 'files/upload',
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.token}`
          },
          onUploadProgress: options.onUploadProgress
        }
      )

      dispatch({
        type: FILE_TYPES.UPLOAD_FILES_F,
        payload: data
      })

      return data

    } catch (e) {
      console.log(e)
      dispatch({
        type: FILE_TYPES.UPLOAD_FILES_R,
        payload: e
      })

      return e
    }
  }
}

/**
   * @name getFile
   * @returns {function(*)}
*/
const getFile = (id) => {
  return async dispatch => {
    try {

      dispatch({
        type: FILE_TYPES.GET_FILE_P
      })

      const { data } = await axios.get(
        config['MEDIA'] + `files/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.token}`
          }
        }
      )

      dispatch({
        type: FILE_TYPES.GET_FILE_F,
        payload: data
      })

      return data

    } catch (e) {

      dispatch({
        type: FILE_TYPES.GET_FILE_R,
        payload: e
      })

    }
  }
}

export { 
  uploadFile,
  getFile
}
