// WakeLockUtils.js

// Function to request wake lock
export async function requestWakeLock() {
    try {
        const wakeLock = await navigator.wakeLock.request('screen');
        console.log('Wake Lock activated');
        // You can optionally return the wake lock object if needed
        return wakeLock;
    } catch (err) {
        console.error(`Failed to request wake lock: ${err}`);
        throw err; // Rethrow the error to handle it in the calling code
    }
}
  
//  Function to release wake lock
export async function releaseWakeLock(wakeLock) {
    try {
        if (wakeLock) {
            await wakeLock.release();
            console.log('Wake Lock released');
        }
    } catch (err) {
        console.error(`Failed to release wake lock: ${err}`);
        throw err; // Rethrow the error to handle it in the calling code
    }
}