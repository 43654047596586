export default class {
  constructor () {
    this.type = 'sms'
    this.to = []
    this.files = []
    this.message = '',
    this.sendDate = null,
    this.links = [],
    this.phones = [],
    this.texts = [""],
    this.customerGroups = [],
    this.name = '',
    this.smsCount = 0,
    this.price = 0,
    this.business = {
      name: ''
    }
  }
}