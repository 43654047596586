const GET_ALL_SLIDES = 'GET_ALL_SLIDES';
const GET_ALL_REMOVED_SLIDES = 'GET_ALL_REMOVED_SLIDES';
const GET_SLIDE_ITEMS = 'GET_SLIDE_ITEMS';
const SELECT_SLIDE_ITEMS = 'SELECT_SLIDE_ITEMS';
const ADD_SLIDE = 'ADD_SLIDE';
const DELETE_SLIDE = 'DELETE_SLIDE';
const RECOVER_SLIDES = 'RECOVER_SLIDES';

export {
  GET_ALL_SLIDES,
  GET_ALL_REMOVED_SLIDES,
  GET_SLIDE_ITEMS,
  SELECT_SLIDE_ITEMS,
  ADD_SLIDE,
  DELETE_SLIDE,
  RECOVER_SLIDES
}