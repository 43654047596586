import { OPTIONS_TYPES} from '../../types/index'


const defaultState = {
  pagRemovedOption: [],
  pagNotRemovedOption: [],
  pagRemovedOptionCount: 0,
  pagNotRemovedOptionCount: 0,
  optionWithRelation: [],
  tabPanel: localStorage.getItem('optionActiveTab') || '1',
};

const allOptions = (state = [], action = {}) => {
  if (action.type === OPTIONS_TYPES.GET_ALL_OPTIONS) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedOptions = (state = [], action = {}) => {
  if (action.type === OPTIONS_TYPES.GET_ALL_REMOVED_OPTIONS) {
    return action.payload;
  } else {
    return state
  }
};

const option = (state = {}, action = {}) => {
  if (action.type === OPTIONS_TYPES.GET_ALL_OPTIONS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedOption = (state = {}, action = {}) => {
  if (action.type === OPTIONS_TYPES.SELECT_OPTION_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const options = ( state = defaultState, action = {}) => {
  switch (action.type) {
      case OPTIONS_TYPES.GET_PAG_REMOVED_OPTIONS: {
          return {
            ...state,
            pagRemovedOption: action.payload.data,
            pagRemovedOptionCount: action.payload.count

          }
      }

      case OPTIONS_TYPES.GET_PAG_NOT_REMOVED_OPTIONS: {
          return {
            ...state,
            pagNotRemovedOption: action.payload.data,
            pagNotRemovedOptionCount: action.payload.count
          }
      }

      case OPTIONS_TYPES.DELETE_OPTION_ITEMS: {
        const id = action.payload.optionId;
        return {
            ...state,
            pagNotRemovedOption: state.pagNotRemovedOption.filter(item => item.optionId !== id)
        }
      }

      case OPTIONS_TYPES.RECOVER_OPTION_ITEMS: {
        const id = action.payload.optionId;
        state.pagNotRemovedOption.unshift(action.payload)
        return {
            ...state,
            pagRemovedOption: state.pagRemovedOption.filter(item => item.optionId !== id),
            pagNotRemovedOption: state.pagNotRemovedOption,
            pagNotRemovedOptionCount: state.pagNotRemovedOptionCount + 1
        }
      }

      case OPTIONS_TYPES.ARCHIVE_OPTION_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagNotRemovedOptionCount: state.pagNotRemovedOptionCount - action.payload.length
        }
      }

      case OPTIONS_TYPES.BULK_RECOVER_OPTION_ITEMS: {
          return { 
              ...state,
              bulkDeletedItems: action.payload,
              pagRemovedOptionCount: state.pagRemovedOptionCount - action.payload.length
        }
      }

      case OPTIONS_TYPES.GET_WITH_RELATION: {
        return { 
            ...state,
            optionWithRelation: action.payload,
      }
    }

    case OPTIONS_TYPES.DESTROY_OPTION: {
      const id = action.payload.optionId;
      return {
          ...state,
          pagRemovedOption: state.pagRemovedOption.filter(item => item.optionId !== id),
          pagRemovedOptionCount: state.pagRemovedOptionCount - 1,
          loading: false
      }
    }

    case OPTIONS_TYPES.OPTION_TAB_PANEL_CHANGE: {
      return {
          ...state,
          tabPanel: action.payload,
          loading: false
      }
    }
  
  
    default:
            return state;
  }
}
export {allOptions,allRemovedOptions,option, selectedOption, options}