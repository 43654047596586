import { PLAN_SUBSCRIPTION_TYPES } from '../../types'
import Fields from '../../../plan/Subscriptions/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  loading: false,
  error: {},
  data: [],
  count: 0,
  balance: 0,
  planSubscriptionSelect: [],
  navItem: new Fields().navBar,
  inputFilters: new Fields().dataFilter,
  oneData: {},
  error: [],
  isOpen: isMobile ? false : localStorage.getItem('planSubscriptionFilter') == 'true' ? true : false,
  pageNumber: {
    removed: localStorage.getItem('removedPlanSubscriptionPage') || 1,
    notRemoved: localStorage.getItem('notRemovedPlanSubscriptionPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedPlanSubscriptionPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedPlanSubscriptionPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    status: null,
  },
  dataFilter: {
    search: '',
    planSubscriptionId: null,
  },
  loading: false,
  tabPanel: '1',
  checked: false,
  ids: []
};

const planSubscription = ( state = initialState, action = {}) => {

  switch (action.type) {

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_PAG_F: {
  
        return {
          ...state,
          loading: false,
          data: action.payload.data.map((elem) => state.ids.includes(elem.planSubscriptionId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.planSubscriptionId)),
          count: action.payload.count,
          balance: action.payload.balance
        }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_FILETR_TABE_CHANGE: {
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_CHECKBOX_CHANGE: {
      let ids = [...state.ids]

      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.planSubscriptionId) })
      
      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case PLAN_SUBSCRIPTION_TYPES.PLANSUBSCRIPTION_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload,
          ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
      }
    }


    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new Fields().navBar,
          error: []
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_R: {
      state.navItem = new Fields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new Fields().navBar
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.NEW_PLAN_SUBSCRIPTION: {
      return { 
        ...state,
        navItem: new Fields().navBar,
        error: []
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.planSubscriptionId !== id)
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_FILTER_IS_OPEN: {
      localStorage.setItem('planSubscriptionFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { 
            products: '',
            author: '',
            status: null,
            dates:  '',
            dateTo: '',
            dateFrom: ''
          }),
          error: []
      }
    }


    case PLAN_SUBSCRIPTION_TYPES.FETCH_PLAN_SUBSCRIPTION_SEE_ALL: {
      const newPlanSubscription = []
      action.payload.data.forEach(planSubscription => {
        if (planSubscription.length) {
          newPlanSubscription.push(planSubscription)
        }
      })

      let planSubscriptionSelect = [...state.planSubscriptionSelect, ...newPlanSubscription]
      planSubscriptionSelect = planSubscriptionSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          planSubscriptionSelect: planSubscriptionSelect,
          count: action.payload.count
      }
  }

    case PLAN_SUBSCRIPTION_TYPES.PLAN_SUBSCRIPTION_GET_CODE_P: {
      return {
        ...state,
        loading: true
      }
    }

    default:
      return state;
  }
}

export {
  planSubscription
}