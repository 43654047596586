import {USERS_TYPES} from '../../types'


const userList = (state = [], action = {}) => {
  if (action.type === USERS_TYPES.GET_ALL_USERS) {
    return action.payload;
  } else {
    return state
  }
};

const userRemovedList = (state = [], action = {}) => {
  if (action.type === USERS_TYPES.GET_ALL_REMOVED_USERS) {
    return action.payload;
  } else {
    return state
  }
};

const userItem = (state = {}, action = {}) => {
  if (action.type === USERS_TYPES.GET_USERS_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedUser = (state = {}, action = {}) => {
  if (action.type === USERS_TYPES.SELECT_USERS_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const usersFranchise = (state = [], action = {}) => {
  if (action.type === USERS_TYPES.GET_USERS_FRANCHISE) {
    return action.payload;
  } else {
    return state
  }
};


export {userList, userItem, selectedUser, usersFranchise, userRemovedList}