import  { REVIEW_TYPES } from '../types'
import config from '../../../config/app'
import axios from 'axios'
import qs from 'qs'
import {Notification} from 'element-react'

 class ReviewActions {
  constructor(_props) {
    this.api = config['REVIEW']
    this.rest  = 'c/rw'
    this.arcive = '/b/'
  }

    paginationReview = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const searchString = qs.stringify(query)
              const response = await axios.get(
                this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                })
                dispatch({
                    type: REVIEW_TYPES.REVIEW_PAG_F,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: REVIEW_TYPES.REVIEW_PAG_R,
                    payload: e
                })
            }
        }
    }

    addLiveData = (data) => {
      return async dispatch => {
            
          dispatch({
              type: REVIEW_TYPES.REVIEW_LIVE_DATA,
              payload: data
          })

      }
    }

    update = (jwt, body, Type, history, account) => {
      body['adminName'] = account['user'].username
      return async dispatch => {
        try {
            await axios.put(
            this.api + this.rest + `/${body.reviewId}`,
            body,
            {
                headers: 
                  {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
            })



            dispatch({
                type: REVIEW_TYPES[Type + '_UPDATE_F'],
                payload: body
            })

              
            history.goBack()
            
        } catch (error) {

            dispatch({
                type: REVIEW_TYPES[Type + '_UPDATE_R'],
                payload: error.response.data
            })

            if (error.response.status === 422) {
              error.response.data.forEach(err => {
                Notification.error({
                  title: err.path,
                  message: err.message
                }, 20)
              })
            }

          }
        }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: REVIEW_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: REVIEW_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }
          )
            dispatch({
                type: REVIEW_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: REVIEW_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: REVIEW_TYPES.REVIEW_BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.paginationReview(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: REVIEW_TYPES.REVIEW_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }
      /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
      */
       bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
         return async dispatch => {
           try {
              await axios.post(
               this.api + this.rest + this.arcive + 'a',
               ids,
               {
                   headers: {
                     "Content-Type": "application/json",
                     'Authorization': `Bearer ${jwt}`
                   }
               }
             )
               dispatch({
                   type: REVIEW_TYPES.REVIEW_BULK_DESTROY_F,
                   payload: ids
               })
 
               dispatch(this.paginationReview(...otherDispatchParams))
               
           } catch (e) {
 
               dispatch({
                   type: REVIEW_TYPES.REVIEW_BULK_DESTROY_R,
                   payload: e
               })
 
             }
           }
       }

      create = (jwt, body, Type, history) => {
        return async dispatch => {
            await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                dispatch({
                    type: REVIEW_TYPES[Type + '_CREATE_F'],
                    payload: response
                })

                history.goBack()

              })
              .catch(e => {

                  dispatch({
                      type: REVIEW_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })
                  if (error.response.status === 422) {
                    error.response.data.forEach(err => {
                      Notification.error({
                        title: err.path,
                        message: err.message
                      }, 20)
                    })
                  }

              })
        }
      }
}




export default new ReviewActions()