import {slideService} from "../../services/media/slideService";
import {SLIDE_TYPES} from "../../types"

/**
 * @name getAll
 * @returns {function(*)}
 */
const getAll = (token) => {
  return dispatch => {
    return slideService.getAll(token).then(options => {
      return dispatch({
        type: SLIDE_TYPES.GET_ALL_SLIDES,
        payload: options
      })
    })
  }
};

/**
 * @name getAllRemoved
 * @returns {function(*)}
 */
const getAllRemoved = (token) => {
  return dispatch => {
    return slideService.getAllRemoved(token).then(options => {
      return dispatch({
        type: SLIDE_TYPES.GET_ALL_REMOVED_SLIDES,
        payload: options
      })
    })
  }
};

/**
 * @name getItem
 * @param id
 * @returns {function(*)}
 */
const getItem = (id,token) => {
  return dispatch => {
    return slideService.getItem(id,token).then(option => {
      return dispatch({
        type: SLIDE_TYPES.GET_SLIDE_ITEMS,
        payload: option
      })
    })
  }
};

/**
 * @name selectItem
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type: SLIDE_TYPES.SELECT_SLIDE_ITEMS,
      payload: elem
    })
  }
};

export {getAll, getAllRemoved, getItem, selectItem}