import { ZIP_TYPES } from '../../types'

const initialState = {
  zipsSelect: [],
  error: [],
  count: 0,
  loading:false
};



const zips = ( state = initialState, action = {}) => {

  switch (action.type) {


    case ZIP_TYPES.FETCH_ZIP_SEE_ALL_P: {
        return {
            ...state,
            loading: true
        }
    }

    case ZIP_TYPES.FETCH_ZIP_SEE_ALL_F: {
        const newZips = []
        action.payload.data.forEach(zip => {
            if (zip.postalCode) {
                newZips.push({ 
                    value: zip.id, 
                    label: zip.postalCode
                })
            }
        })

        let zipsSelect = [...state.zipsSelect, ...newZips]
        zipsSelect = zipsSelect.filter((elem, index, self) =>
            index === self.findIndex((e) => (
                e.value === elem.value
            ))
        )

        return {
            ...state,
            zipsSelect: zipsSelect,
            count: action.payload.count
        }
    }

    case ZIP_TYPES.FETCH_ZIP_SEE_ALL_R: {
        return {
            ...state,
            loading: false,
            error: action.payload
        }
    }

    default:
            return state;
  }
}
export {
  zips
}