import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Products from './Components/Products/Products'
import { customerGroupActions, productGroupActions } from '../../redux-modules/actions'
import './Filters.scss'


function CreateFilters(props) {
    const query = new URLSearchParams(props.location.search);
    const filterGroupId = query.get('filterGroupId')
    const filter = query.get('filter')
    const [name, setName] = useState('')

    const { 
        account, 
        getCustomerGroupFilters, getProductGroupFilters, 
        chooseCustomerGroupFilter, chooseProductGroupFilter
    } = props

    useEffect(() => {
        if (filter == 'customerGroup') {
            getCustomerGroupFilters(account.token, 1, 1000, { name: name })
        }
        if (filter == 'productGroup') {
            getProductGroupFilters(account.token, 1, 1000, { name: name })
        }
    }, [name, filter])

    return (
        <>
            {
                (filter == 'customerGroup' || filter == 'productGroup') ? 
                <div className="customer-group-filter-container">
                    <div className="filter-header">
                        <div className="filter-header-content">
                            <h6 className="filter-header-text">
                                Choose a filter for this segment
                            </h6>
                        </div>
                    </div>
                    <div className="filter-body">
                        <hr className="filter-body-slicer"/>
                        <div className="layout-content-wrapper">
                            <div className="direction-vertical">
                                <div className="direction-vertical filter-body-content">
                                    <div className="choose-field-search">
                                        <div className="choose-field-search-container">
                                            <div className="popover-content-search-input">
                                                <div className="field-search">
                                                    <div className="search-container">
                                                        <div className="search-icon">
                                                            <svg viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px"><path d="M19.8535534,19.1464466 C20.0488155,19.3417088 20.0488155,19.6582912 19.8535534,19.8535534 C19.6582912,20.0488155 19.3417088,20.0488155 19.1464466,19.8535534 L15.4380219,16.1451287 C14.1187738,17.3000688 12.3911257,18 10.5,18 C6.35786438,18 3,14.6421356 3,10.5 C3,6.35786438 6.35786438,3 10.5,3 C14.6421356,3 18,6.35786438 18,10.5 C18,12.3911257 17.3000688,14.1187738 16.1451287,15.4380219 L19.8535534,19.1464466 Z M17,10.5 C17,6.91014913 14.0898509,4 10.5,4 C6.91014913,4 4,6.91014913 4,10.5 C4,14.0898509 6.91014913,17 10.5,17 C14.0898509,17 17,14.0898509 17,10.5 Z"></path></svg>
                                                        </div>
                                                        <input
                                                            value={name}
                                                            onChange={(e) => setName(event.target.value)} 
                                                            className="wsr-input"
                                                            placeholder="Search filters"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-container">
                            <div className="dropdown-layout-options">
                                {
                                    props[`${filter}Filters`].map((item, i) =>
                                    !props[filter].filters.find((filter) => filter.filterGroupId == item.filterGroupId) &&
                                    <div 
                                        key={i} 
                                        className={item.filterGroupId == filterGroupId ? "dropdown-layout-item active" : "dropdown-layout-item"}
                                        onClick={() =>  { 
                                            if (filter == 'customerGroup') {
                                                chooseCustomerGroupFilter(item.filterGroup, filterGroupId) 
                                            }
                                            if (filter == 'productGroup') {
                                                chooseProductGroupFilter(item.filterGroup, filterGroupId) 
                                            }
                                        }}
                                    >
                                        <div className="dropdown-layout-item-label">
                                            <div className="dropdown-layout-item-label-container">
                                                <div>
                                                    <div>
                                                        <span style={{fontSize: '14px'}}>{item.filterGroup.source}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                filter == 'products' &&
                <Products/>
            }
        </>
    );
}
  

/**
 * @name mapStateToProps
 * @param dispatch
 * @returns {{Languages}}
 */
function mapStateToProps(state) {
    return {
        account:state.account,
        customerGroupFilters: state.customerGroups.filters,
        productGroupFilters: state.productGroups.filters,
        productGroup: state.productGroups.selectedItem,
        customerGroup: state.customerGroups.oneData,
    }
}

/**
 * @name mapDispatchToProps
 * @param dispatch
 * @returns {{fetchLanguages, updateLanguage}}
 */
function mapDispatchToProps(dispatch) {
	return {
        getCustomerGroupFilters: (jwt, page, count, query) =>  dispatch(customerGroupActions.getFilters(jwt, page, count, query)),
        getProductGroupFilters: (jwt, page, count, query) =>  dispatch(productGroupActions.getFilters(jwt, page, count, query)),
        chooseCustomerGroupFilter: (data, id) =>  dispatch(customerGroupActions.chooseFilter(data, id)),
        chooseProductGroupFilter: (data, id) =>  dispatch(productGroupActions.chooseFilter(data, id)),
	};
}


export default connect(mapStateToProps,mapDispatchToProps)(CreateFilters)