import { SMS_PHONE_TYPES } from '../../types'
import { updateDimensions } from '../../services/mobileService'
import Fields from '../../../smsServie/phone/type'
const isMobile = updateDimensions()

const initialState = {
    data: [],
    oneData: new Fields(),
    navItem: new Fields().navBar,
    inputFilters: new Fields().dataFilter,
    error: {},
    count: 0,
    pageNumber: {
        removed: localStorage.getItem('removedSmsPhonePage') || 1,
        notRemoved: localStorage.getItem('notRemovedSmsPhonePage') || 1
    },
    showEntries: {
        removed: localStorage.getItem('removedSmsPhonePageSize') || 10,
        notRemoved: localStorage.getItem('notRemovedSmsPhonePageSize') || 10
    },
    langTab : {
        languageId: 1,
        langTab: 0
    },
    filterTab: {
        filterTab: true,
        path: 'r/',
        removed: 'notRemoved'
    },
    initialFilter: {
        message: '',
        from: '',
        to: '',
        statusF: null,
        datesF:  '',
    },
    dataFilter: {
        search: '',
        message: false,
    },
    minMaxValues: {},
    isOpen: isMobile ? false : localStorage.getItem('smsPhoneFilter') == 'true' ? true : false,
    tabPanel: '1',
    checked: false,
    ids: [],
};



const smsPhone = ( state = initialState, action = {}) => {

  switch (action.type) {

    case SMS_PHONE_TYPES.NEW_SMS_PHONE: {
        return { 
            ...state,
            oneData: new Fields(),
            navItem: new Fields().navBar,
            error: []
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_GET_ONE_P: {
        return {
            ...state,
            loading: true
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_GET_ONE_F: {
        return {
            ...state,
            loading: false,
            oneData: action.payload,
            navItem: new Fields().navBar
        }
    }


    case SMS_PHONE_TYPES.SMS_PHONE_GET_ONE_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.data
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_PAG_F: {
        return {
            ...state,
            data: action.payload.data.map((elem) => state.ids.includes(elem.smsPhoneId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
            checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.smsPhoneId)),
            count: action.payload.count
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_SIZE_CHANGE: {

        const showEntries = Object.assign(state.showEntries, action.payload)
      
        return {
            ...state,
            showEntries: showEntries
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_PAGE_CHANGE: {
      
        const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_FILETR_TABE_CHANGE: {
      
        return {
            ...state,
            filterTab: action.payload,
            checked: false
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_TAB_PANEL_CHANGE: {
      
        return {
            ...state,
            tabPanel: action.payload
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_CHECKBOX_CHANGE: {
        let ids = [...state.ids]
        const { checked, multiple, key } = action.payload
        const data = state.data.map(elem => {

            if (multiple) {

                elem['isChecked'] = checked
                return elem

            } else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.smsPhoneId) })

        return {
            ...state,
            data: data,
            checked: !multiple ? data.every(element => element.isChecked): checked,
            ids: ids
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_BULK_ARCIVE_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case SMS_PHONE_TYPES.SMS_PHONE_BULK_DESTROY_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case SMS_PHONE_TYPES.SMS_PHONE_LANGUAGE_PANEL_CHANGE: {
        return { 
            ...state,
            langTab: action.payload
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_CREATE_R: {
        return { 
            ...state,
            error: action.payload.response
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_DATA_FILTER: {
        return { 
            ...state,
            dataFilter: { [action.payload['key']]: action.payload['value'] },
            error: []
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_DELETE_F: {
        const id = action.payload;
        return {
            ...state,
            data: state.data.filter(item => item.customer !== id)
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_FILTER_IS_OPEN: {
        localStorage.setItem('smsPhoneFilter', !state.isOpen)
        return {
            ...state,
            isOpen: !state.isOpen
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
            error: []
        }
    }
    
    case SMS_PHONE_TYPES.SMS_PHONE_DATA_FILTER_ALL_SEARCH_RESET: {
        const { key } = action.payload
        return {
            ...state,
            [key]: { 
                from: '',
                to: '',
                message: '',
                status: null,
                dates:  '',
            }
        }
    }

    case SMS_PHONE_TYPES.SMS_PHONE_DATA_MIN_MAX_F: {
        return {
            ...state,
            minMaxValues: action.payload
        }
    }

    default:
        return state;
    }
}


export {
    smsPhone
}

