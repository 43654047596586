const ICON_PAG_F = 'ICON_PAG_L_F';
const ICON_PAG_R = 'ICON_PAG_L_R';
const ICON_SIZE_CHANGE = 'ICON_SIZE_CHANGE'
const ICON_PAGE_CHANGE = 'ICON_PAGE_CHANGE'
const ICON_FILETR_TABE_CHANGE = 'ICON_FILETR_TABE_CHANGE'
const ICON_UPDATE_F = 'ICON_UPDATE_F'
const ICON_UPDATE_R = 'ICON_UPDATE_R'
const ICON_CHECKBOX_CHANGE = 'ICON_CHECKBOX_CHANGE'
const ICON_GET_FOLDERS_F = 'ICON_GET_FOLDERS_F'
const ICON_ADD_FOLDER_F = 'ICON_ADD_FOLDER_F'
const ICON_ADD_FOLDER_R = 'ICON_ADD_FOLDER_R'
const ICON_REMOVE_FOLDER_F = 'ICON_REMOVE_FOLDER_F'
const ICON_REMOVE_FOLDER_R = 'ICON_REMOVE_FOLDER_R'
const ICON_UPDATE_FOLDER_F = 'ICON_UPDATE_FOLDER_F'
const ICON_CREATE_F = 'ICON_CREATE_F'
const ICON_CREATE_R = 'ICON_CREATE_R'
const ICON_VIDEOS_DIR_F = 'ICON_VIDEOS_DIR_F'
const ICON_VIDEOS_DIR_R = 'ICON_VIDEOS_DIR_R'
const ICON_DELETE_F = 'ICON_DELETE_F'
const ICON_DELETE_R = 'ICON_DELETE_R'
const ICON_MOVE_DIRECTORY_F = 'ICON_MOVE_DIRECTORY_F'
const ICON_MOVE_DIRECTORY_R = 'ICON_MOVE_DIRECTORY_R'
const ICON_DIALOG_VISIBLE = 'ICON_DIALOG_VISIBLE'
const GET_ICONS_DIR_F = 'GET_ICONS_DIR_F'
const GET_ICONS_DIR_R = 'GET_ICONS_DIR_R'
const ICON_BULK_ARCIVE_F = 'ICON_BULK_ARCIVE_F'
const ICON_BULK_ARCIVE_R = 'ICON_BULK_ARCIVE_R'
const ICON_BULK_ARCIVE_P = 'ICON_BULK_ARCIVE_P'
const ADD_MAIN_ICON = 'ADD_MAIN_ICON'
const MEDIA_CHANGE_FILE = 'MEDIA_CHANGE_FILE'
const MEDIA_REMOVE_CHANGED_FILE = 'MEDIA_REMOVE_CHANGED_FILE'
const ICON_CHANGE_DIRECTORY = 'ICON_CHANGE_DIRECTORY'
const CLEAR_UPLOAD_HISTORY = 'CLEAR_UPLOAD_HISTORY'

export {
    ICON_PAG_F,
    ICON_PAG_R,
    ICON_SIZE_CHANGE,
    ICON_PAGE_CHANGE,
    ICON_FILETR_TABE_CHANGE,
    ICON_UPDATE_F,
    ICON_UPDATE_R,
    ICON_CHECKBOX_CHANGE,
    ICON_GET_FOLDERS_F,
    ICON_ADD_FOLDER_F,
    ICON_ADD_FOLDER_R,
    ICON_REMOVE_FOLDER_F,
    ICON_REMOVE_FOLDER_R,
    ICON_UPDATE_FOLDER_F,
    ICON_CREATE_F,
    ICON_CREATE_R,
    ICON_VIDEOS_DIR_F,
    ICON_VIDEOS_DIR_R,
    ICON_DELETE_F,
    ICON_DELETE_R,
    ICON_MOVE_DIRECTORY_F,
    ICON_MOVE_DIRECTORY_R,
    ICON_DIALOG_VISIBLE,
    GET_ICONS_DIR_F,
    GET_ICONS_DIR_R,
    ICON_BULK_ARCIVE_F,
    ICON_BULK_ARCIVE_P,
    ICON_BULK_ARCIVE_R,
    ADD_MAIN_ICON,
    MEDIA_CHANGE_FILE,
    MEDIA_REMOVE_CHANGED_FILE,
    ICON_CHANGE_DIRECTORY,
    CLEAR_UPLOAD_HISTORY
}