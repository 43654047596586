import React, { Fragment, useEffect } from 'react';
import {connect} from 'react-redux';
import {
  Nav,
  NavItem,
  NavLink } from 'reactstrap';

import {
  AppAsideToggler,
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/brand.svg'
import sygnet from '../../assets/img/brand/sygnet.svg'
import { accountActions, paymentActions } from '../../projectNulla/redux-modules/actions'
import { withRouter} from 'react-router-dom';
import Notification from '../../projectNulla/sales/Notification/Notification'
import { updateDimensions } from '../../projectNulla/redux-modules/services/mobileService'

const isMobile = updateDimensions()


function DefaultHeader(props) {

  function LogOut() {
    props.signOut()
    props.history.push('/login');
  }

  useEffect(() => {
    if (props.account.token) {
      props.getAccount(props.account.token)
    }
  }, [props.mainNotifications, props.account.token])

  return (
    <Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          onClick={ () =>  props.history.push('/dashboard') }
          full={{ src: logo, width: 90, height: 30, alt: 'Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg"/>
          {
            !!isMobile &&
            <Fragment>
              <NavItem className="mobile-notification">
                <Notification history = {props.history}/>
              </NavItem>
            </Fragment>
          }
        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="/">Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3" >
            <span style = {{ cursor:'pointer' }} onClick={() => LogOut()} ><i className="fa fa-sign-out sign-out" /> Logout</span>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="/">Settings</NavLink>
          </NavItem>
          {
            props.payment &&
            <NavItem className="px-3">
              <NavLink href="/"><b>Balance <span style={props.payment.balance > 0 ? { color: 'green' } : { color: 'red' }}>{ Math.round(props.payment.balance * Math.pow(10,0))/Math.pow(10,0) } {'USD'}</span></b></NavLink>
            </NavItem>
          }
        </Nav>
        <Nav className="ml-auto" navbar>
          {
            !isMobile &&
            <NavItem className="d-md-down-none">
                <Notification history = {props.history}/>
            </NavItem>
          }
          <NavItem className="d-md-down-none">
            <NavLink href="/"><i className="icon-list"></i></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="/"><i className="icon-location-pin"></i></NavLink>
          </NavItem>
          <AppHeaderDropdown  direction="down">
          </AppHeaderDropdown>
        </Nav>
        <AppAsideToggler className="d-md-down-none"/>
     </Fragment>
  )
}
  

const mapStateToProps = (state) => {
  return {
    account: state.account,
    payment: state.payment.account,
    mainNotifications: state.notifications.mainNotifications,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(accountActions.signOut()),
    getAccount: (jwt) => dispatch(paymentActions.getAccount(jwt))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader));

