import MediaDevice from './MediaDevice';
import Emitter from './Emitter';
import { requestWakeLock, releaseWakeLock } from './WakeLockUtils'; // Import the releaseWakeLock function

const PC_CONFIG = {
    iceServers: [
        {
            urls: 'stun:154.53.38.100:3478',
            credential: '337c955c910ff50d1c3ffab2f62888b05caca0b365be88bfd05d7e2376872a8b',
            username: 'vardanyanhayk26@gmail.com'
        }
    ]
};

class PeerConnection extends Emitter {
    
    /**
     * Create a PeerConnection.
     * @param {String} friendID - ID of the friend you want to call.
     */
    constructor(friendID, socket, user, socketId, mediaDeviceConfig) {
        super();
        this.pc = new RTCPeerConnection(PC_CONFIG);
        this.pc.onicecandidate = (event) => socket.emit('call', {
            to: this.friendID,
            socketId: this.socketId,
            candidate: event.candidate
        });
        this.pc.ontrack = (event) => this.emit('peerStream', event.streams[0]);
        this.mediaDevice = new MediaDevice(mediaDeviceConfig);
        this.friendID = friendID;
        this.socket = socket
        this.user = user
        this.socketId = socketId
    }

    /**
     * Starting the call
     * @param {Boolean} isCaller
     * @param {Object} config - configuration for the call {audio: boolean, video: boolean}
     */
    start(isCaller, config) {
        requestWakeLock(); // Request wake lock when the call starts
        this.mediaDevice
            .on('stream', (stream) => {
                stream.getTracks().forEach((track) => {
                    this.pc.addTrack(track, stream);
                });
                this.emit('localStream', stream);
                if (isCaller) this.socket.emit('request', { to: this.friendID, user: this.user });
                else this.createOffer();
            })
            .start(config);

        return this;
    }

    /**
     * Stop the call
     * @param {Boolean} isStarter
     */
    stop(isStarter) {
        if (isStarter) {
            this.socket.emit('end', { to: this.friendID });
        }
        this.mediaDevice.stop();
        this.pc.close();
        this.pc = null;
        this.off();
        releaseWakeLock(); // Call the releaseWakeLock function
        return this;
    }

    createOffer() {
        this.pc.createOffer()
            .then(this.getDescription.bind(this))
            .catch((err) => console.log(err));
        return this;
    }

    createAnswer(socketId) {
        this.socketId = socketId
        this.pc.createAnswer()
            .then(this.getDescription.bind(this))
            .catch((err) => console.log(err));
        return this;
    }

    getDescription(desc) {
        this.pc.setLocalDescription(desc);
        this.socket.emit('call', { to: this.friendID, socketId: this.socketId, sdp: desc });
        return this;
    }

    /**
     * @param {Object} sdp - Session description
     */
    setRemoteDescription(sdp) {
        const rtcSdp = new RTCSessionDescription(sdp);
        this.pc.setRemoteDescription(rtcSdp);
        return this;
    }

    /**
     * @param {Object} candidate - ICE Candidate
     */
    addIceCandidate(candidate) {
        if (candidate) {
            const iceCandidate = new RTCIceCandidate(candidate);
            this.pc.addIceCandidate(iceCandidate);
        }
        return this;
    }
}

export default PeerConnection;