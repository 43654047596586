import  { CHAT_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

 class ChatActions {
    constructor(_props) {
        this.api = config['MEDIA']
        this.chat = config['CHAT']
        this.rest='files'
        this.upload = 'upload'
    }

    uploadFile = (files) => {
        return async dispatch => {
            try {

                dispatch({
                    type: CHAT_TYPES.UPLOAD_FILES_P
                })

                const formData = new FormData()

                for(let file of files) {
                    formData.append('file', file)
                }
                
                const { data } = await axios.post(
                    this.api + this.rest + '/' + this.upload,
                    formData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.token}`
                        }
                    })
                dispatch({
                    type: CHAT_TYPES.UPLOAD_FILES_F,
                    payload: data,
                })

                return data

            } catch (e) {
                dispatch({
                    type: CHAT_TYPES.UPLOAD_FILES_R,
                    payload: e
                })

                return e
            }
        }
    }

    destroy = (_jwt, ids) => {
        return async dispatch => {
          try {
            await axios.post(
              this.chat + '/chat/destroy',
              ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.token}`
                }
            })
              dispatch({
                  type: CHAT_TYPES.CHAT_DELETE_F,
                  payload: ids
              })
          } catch (e) {
              dispatch({
                  type: CHAT_TYPES.CHAT_DELETE_R,
                  payload: e
              })
            }
        }
    }

    destroyRoom = (id) => {
        return async dispatch => {
            try {

                dispatch({
                    type: CHAT_TYPES.DESTROY_CHAT_ROOM_P
                })

                const response = await axios.post(
                    this.chat + `/chat/rooms/destroy`,
                    {
                        roomId: id,
                        deleted: true
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.token}`
                        }
                    }
                )

                dispatch({
                    type: CHAT_TYPES.DESTROY_CHAT_ROOM_F,
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: CHAT_TYPES.DESTROY_CHAT_ROOM_R,
                    payload: e
                })
                
            }
        }
    }

    blockRoom = (id) => {
        return async dispatch => {
            try {

                dispatch({
                    type: CHAT_TYPES.DESTROY_CHAT_ROOM_P
                })

                const response = await axios.post(
                    this.chat + `/chat/rooms/block`,
                    {
                        roomId: id,
                        blocked: true
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.token}`
                        }
                    }
                )

                dispatch({
                    type: CHAT_TYPES.DESTROY_CHAT_ROOM_F,
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: CHAT_TYPES.DESTROY_CHAT_ROOM_R,
                    payload: e
                })
                
            }
        }
    }

    updateMessageCondition = (data) => {
        return {
            type: CHAT_TYPES.UPDATE_CHAT_MESSAGE_CONDITION,
            payload: data
        }  
    };

    saveSocket = (data) => {
        return {
            type: 'SAVE_CHAT_SOCKET',
            payload: data
        }  
    };

    addCallConfig = (data) => {
        return {
            type: 'CHAT_CALL_CONFIG',
            payload: data
        }  
    };

    bulkDestroy = (_jwt) => {
        return async dispatch => {
          try {
            const response = await axios.delete(
              this.chat + '/chat/b/destroy',
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.token}`
                }
            })

            dispatch({
                type: CHAT_TYPES.BULK_DELETE_F,
                payload: response
            })
          } catch (e) {
              dispatch({
                  type: CHAT_TYPES.BULK_DELETE_R,
                  payload: e
              })
            }
        }
    }
  

    changeInputFile = (file, url) => {
        return async dispatch => {
            dispatch({
                type: CHAT_TYPES.CHANGE_FILE,
                payload: {
                    file: file,
                    url: url
                },
            })
        }
    }

    onRemoveChangedFile = (index) => {
        return async dispatch => {
            dispatch({
                type: CHAT_TYPES.REMOVE_CHANGED_FILE,
                payload: index
            })
        }
    }

    fetchNotifications = () => {
            
        return async (dispatch) => {

            try {

                dispatch({
                    type: CHAT_TYPES.FETCH_CHAT_NOTIFICATIONS_P
                })

                if (typeof localStorage !== "undefined") {
                    const { data } = await axios.create( {
                        baseURL: this.chat,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json'
                        }
                    } )
                    .get('/chat/notifics/l');
                    
                    dispatch( {
                        type: CHAT_TYPES.FETCH_CHAT_NOTIFICATIONS_F,
                        payload: data
                    } )
                }

            } catch (err) {
                dispatch({
                    type: CHAT_TYPES.FETCH_CHAT_NOTIFICATIONS_R,
                    payload: err.response
                })
            }
        }
    };

    bulkRemoveNotifications = () => {
            
        return async (dispatch) => {
    
            try {
    
                dispatch({
                    type: CHAT_TYPES.BULK_REMOVE_CHAT_NOTIFICATIONS_P
                })
    
                if (typeof localStorage !== "undefined") {
                    const { data } = await axios.create( {
                        baseURL: config['CHAT'],
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json'
                        }
                    } )
                    .delete('/chat/notifics/b/d');
                    dispatch( {
                        type: CHAT_TYPES.BULK_REMOVE_CHAT_NOTIFICATIONS_F,
                        payload: data
                    } )
                }
    
            } catch (err) {
    
                dispatch({
                    type: CHAT_TYPES.BULK_REMOVE_CHAT_NOTIFICATIONS_R,
                    payload: err.response
                })
            }
        }
    };


    destroyOneUserNotification = (id) => {
            
        return async (dispatch) => {
    
            try {
    
                dispatch({
                    type: CHAT_TYPES.DESTROY_ONE_USER_CHAT_NOTIFICATIONS_P
                })
                if (typeof localStorage !== "undefined") {
                    const { data } = await axios.create( {
                        baseURL: config['CHAT'],
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json'
                        }
                    } )
                    .delete(`/chat/notifics/o/u/${id}`);
                    dispatch( {
                        type: CHAT_TYPES.DESTROY_ONE_USER_CHAT_NOTIFICATIONS_F,
                        payload: id,
                        data: data
                    } )
                }
    
            } catch (err) {
    
                dispatch({
                    type: CHAT_TYPES.DESTROY_ONE_USER_CHAT_NOTIFICATIONS_R,
                    payload: err.response
                })
            }
        }
    };

    fetchUsers = (query) => {
            
        return async (dispatch) => {
    
            try {
                const searchString = qs.stringify(query)
                dispatch({
                    type: CHAT_TYPES.FETCH_USERS_CHAT_P
                })

                if (typeof localStorage !== "undefined") {
                    const { data } = await axios.create( {
                        baseURL: config['CHAT'],
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json'
                        }
                    } )
                    .get(`/chat/users/l?${searchString}`);
                    dispatch( {
                        type: CHAT_TYPES.FETCH_USERS_CHAT_F,
                        payload: data.data,
                        showMore: query.showMore,
                        sort: query.sort
                    } )
                }
    
            } catch (err) {
                console.log(err)
                dispatch({
                    type: CHAT_TYPES.FETCH_USERS_CHAT_R,
                    payload: err.response
                })
            }
        }
    };

    fetchRoomCount = (query) => {
            
        return async (dispatch) => {
    
            try {
    
                dispatch({
                    type: CHAT_TYPES.FETCH_ROOM_COUNT_P
                })
                if (typeof localStorage !== "undefined") {
                    const { data } = await axios.create( {
                        baseURL: config['CHAT'],
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json'
                        }
                    } )
                    .get(`/chat/users/count?isOnline=${query.online}&unread=${query.unread}&isAdmin=${query.isAdmin}&read=${query.read}`);
                    dispatch( {
                        type: CHAT_TYPES.FETCH_ROOM_COUNT_F,
                        payload: data.data,
                        showMore: query.showMore,
                        sort: query.sort
                    } )
                }
    
            } catch (err) {
                dispatch({
                    type: CHAT_TYPES.FETCH_ROOM_COUNT_R,
                    payload: err.response
                })
            }
        }
    };

    updateUsers = (data) => {
        return {
            type: CHAT_TYPES.UPDATE_CHAT_USERS,
            payload: data
        }  
    };

    addUser = (data) => {
        return {
            type: CHAT_TYPES.ADD_CHAT_USER,
            payload: data
        }  
    };

    updateUserNotificsCount = (id, count) => {
        return {
            type: CHAT_TYPES.UPDATE_CHAT_USER_NOTIFICS_COUNT,
            payload: id,
            count: count
        }  
    };


    fetchMessages = (id, page, count, paginate) => {
            
        return async (dispatch) => {
    
            try {
    
                dispatch({
                    type: CHAT_TYPES.FETCH_MESSAGES_CHAT_P
                })
    
                if (typeof localStorage !== "undefined") {
                    const { data } = await axios.create( {
                        baseURL: config['CHAT'],
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json'
                        }
                    } )
                    .get(`/chat/l/${page}/${count}/${id}`);
                    dispatch( {
                        type: CHAT_TYPES.FETCH_MESSAGES_CHAT_F,
                        payload: data,
                        id: id,
                        paginate: paginate
                    } )

                    return true
                }
    
            } catch (err) {
    
                dispatch({
                    type: CHAT_TYPES.FETCH_MESSAGES_CHAT_R,
                    payload: err.response
                })

            }
        }
    };
    

    addMessage = (id, data) => {
        return {
            type: CHAT_TYPES.ADD_MESSAGE_CHAT,
            payload: data,
            id: id
        }  
    };

    removeChatList = () => {
        return {
            type: CHAT_TYPES.REMOVE_CHAT_LIST
        }
    }

    destroyMessage = (data) => {
        return  {
            type: CHAT_TYPES.DESTROY_CHAT_MESSAGE,
            payload: data
        } 
    };

    fetchMembers = (_jwt, page, count, query, search) => {
        return async (dispatch) => {

            try {
    
                dispatch({
                    type: CHAT_TYPES.FETCH_MEMBERS_P
                })

                if (typeof localStorage !== "undefined") {
                    const searchString = qs.stringify(query)
                    const { data } = await axios.create( {
                        baseURL: config['CHAT'],
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json'
                        }
                    } )
                    .get(`/chat/users/l/${page}/${count}?${searchString}`);
                    dispatch( {
                        type: CHAT_TYPES.FETCH_MEMBERS_F,
                        payload: data,
                        search: search
                    } )
                }
    
            } catch (err) {
    
                dispatch({
                    type: CHAT_TYPES.FETCH_MEMBERS_R,
                    payload: err.response
                })
            }
        }
    }
}




export default new ChatActions()