import {Notification} from 'element-react'

export function handleError ( e ) {

    if (e.name === 'ValidationError' && 'errors' in e) {
        Object.values(e.errors).forEach(error => {
            setTimeout(() => {
                Notification.error({
                    title: error.message
                })
            }, 10)
        })
        return
    }

    let errorStatus = [604, 610, 404, 403]
    if (e.response) {
        let error = e.response.data
        if (error.errors && error.errors.length) {
            if ('message' in error.errors[0]) {
                error.errors.forEach(error => {
                    Notification.error({
                        title: error.message
                    }, 20)
                })
            }
        } else if (errorStatus.includes(error.status)) {
            Notification.error({
                title: error.message
            }, 20)
        } else if (error.errmsg) {
            Notification.error({
                title: error.errmsg
            }, 20)
        }
    }
}
