const SECTION_UPDATE_P = 'SECTION_UPDATE_P'
const SECTION_UPDATE_F = 'SECTION_UPDATE_F'
const SECTION_UPDATE_R = 'SECTION_UPDATE_R'
const SECTION_CREATE_P = 'SECTION_CREATE_F,'
const SECTION_CREATE_F = 'SECTION_CREATE_F'
const SECTION_CREATE_R = 'SECTION_CREATE_R'

export {
    SECTION_UPDATE_P,
    SECTION_UPDATE_F,
    SECTION_UPDATE_R,
    SECTION_CREATE_P,
    SECTION_CREATE_F,
    SECTION_CREATE_R,
}