const GET_ACCOUNT_P = 'GET_ACCOUNT_P';
const GET_ACCOUNT_F = 'GET_ACCOUNT_F';
const GET_ACCOUNT_R = 'GET_ACCOUNT_R';
const TRANSACTION_PAG_F = 'TRANSACTION_PAG_L_F';
const TRANSACTION_PAG_R = 'TRANSACTION_PAG_L_R';
const TRANSACTION_SIZE_CHANGE = 'TRANSACTION_SIZE_CHANGE'
const TRANSACTION_PAGE_CHANGE = 'TRANSACTION_PAGE_CHANGE'
const TRANSACTION_FILETR_TABE_CHANGE = 'TRANSACTION_FILETR_TABE_CHANGE'
const TRANSACTION_UPDATE_F = 'TRANSACTION_UPDATE_F'
const TRANSACTION_UPDATE_R = 'TRANSACTION_UPDATE_R'
const TRANSACTION_CHECKBOX_CHANGE = 'TRANSACTION_CHECKBOX_CHANGE'
const TRANSACTION_BULK_ARCIVE_F = 'TRANSACTION_BULK_ARCIVE_F'
const TRANSACTION_BULK_ARCIVE_R = 'TRANSACTION_BULK_ARCIVE_R'
const TRANSACTION_BULK_DESTROY_F = 'TRANSACTION_BULK_DESTROY_F'
const TRANSACTION_BULK_DESTROY_R = 'TRANSACTION_BULK_DESTROY_R'
const TRANSACTION_TAB_PANEL_CHANGE = 'TRANSACTION_TAB_PANEL_CHANGE'
const TRANSACTION_CREATE_F = 'TRANSACTION_CREATE_F'
const TRANSACTION_CREATE_R = 'TRANSACTION_CREATE_R'
const TRANSACTION_GET_ONE_F = 'TRANSACTION_GET_ONE_F'
const TRANSACTION_GET_ONE_R = 'TRANSACTION_GET_ONE_R'
const TRANSACTION_REQUIRED_VALIDATION_F = 'TRANSACTION_REQUIRED_VALIDATION_F'
const TRANSACTION_REQUIRED_VALIDATION_R = 'TRANSACTION_REQUIRED_VALIDATION_R'
const NEW_TRANSACTION = 'NEW_TRANSACTION'
const TRANSACTION_DATA_FILTER = 'TRANSACTION_DATA_FILTER'
const TRANSACTION_DELETE_F = 'TRANSACTION_DELETE_F'
const TRANSACTION_DELETE_R = 'TRANSACTION_DELETE_R'
const TRANSACTION_LIVE_DATA = 'TRANSACTION_LIVE_DATA'
const TRANSACTION_DATA_FILTER_SEARCH = 'TRANSACTION_DATA_FILTER_SEARCH'
const TRANSACTION_FILTER_IS_OPEN = 'TRANSACTION_FILTER_IS_OPEN'
const TRANSACTION_LANGUAGE_PANEL_CHANGE = 'TRANSACTION_LANGUAGE_PANEL_CHANGE'
const TRANSACTION_DATA_FILTER_SEARCH_RESET = 'TRANSACTION_DATA_FILTER_SEARCH_RESET'
const TRANSACTION_DATA_FILTER_ALL_SEARCH_RESET = 'TRANSACTION_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_TRANSACTION_SEE_ALL = 'FETCH_TRANSACTION_SEE_ALL'

export {
    GET_ACCOUNT_P,
    GET_ACCOUNT_F,
    GET_ACCOUNT_R,
    TRANSACTION_PAG_F,
    TRANSACTION_PAG_R,
    TRANSACTION_SIZE_CHANGE,
    TRANSACTION_PAGE_CHANGE,
    TRANSACTION_FILETR_TABE_CHANGE,
    TRANSACTION_UPDATE_F,
    TRANSACTION_UPDATE_R,
    TRANSACTION_CHECKBOX_CHANGE,
    TRANSACTION_BULK_ARCIVE_F,
    TRANSACTION_BULK_ARCIVE_R,
    TRANSACTION_BULK_DESTROY_F,
    TRANSACTION_BULK_DESTROY_R,
    TRANSACTION_TAB_PANEL_CHANGE,
    TRANSACTION_CREATE_F,
    TRANSACTION_CREATE_R,
    TRANSACTION_GET_ONE_F,
    TRANSACTION_GET_ONE_R,
    TRANSACTION_REQUIRED_VALIDATION_F,
    TRANSACTION_REQUIRED_VALIDATION_R,
    NEW_TRANSACTION,
    TRANSACTION_DATA_FILTER,
    TRANSACTION_DELETE_F,
    TRANSACTION_DELETE_R,
    TRANSACTION_LIVE_DATA,
    TRANSACTION_DATA_FILTER_SEARCH,
    TRANSACTION_FILTER_IS_OPEN,
    TRANSACTION_LANGUAGE_PANEL_CHANGE,
    TRANSACTION_DATA_FILTER_SEARCH_RESET,
    TRANSACTION_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_TRANSACTION_SEE_ALL
}