import { PRODUCT_TYPES } from "../types";
import { productService } from "../services/productService";
import { Notification } from "element-react";
import config from '../../../config/app';
import axios from 'axios';

const getOptions = (optionIds, product) => {
    let price = Math.round(product.currentPrice);
    const options = [];
    const productOptions = [];

    product.options.forEach(({ required, values, option }) => {
        values.forEach((value) => {
            if (!optionIds.includes(value.optionValueId)) return;

            value.price = Math.round(value.price);

            if (!value.pricePrefix) {
                price = value.price;
            } else {
                options.push({ ...value, required, type: option.type });
            }

            productOptions.push({
                optionId: option.optionId,
                productOptionId: value.productOptionId,
                productOptionValueId: value.value,
                name: value.label,
                type: option.type,
                value: value.price,
                option: {
                    source: option.source,
                },
                options: {
                    ...option,
                    values: values,
                },
            });
        });
    });

    return { price, options, productOptions };
};


 // calculate product price
 const calculateProductPrice = (product) => {
  const calculateSum = (arr, field) => arr.reduce((prev, curr) => prev + curr[field], 0);

  let price = Number.parseFloat(product.price).toFixed(1);

  const requiredOptions = [];
  const options = [];

  function sortArray(dataArr) {
    return dataArr.sort((a, b) => {
      return (parseInt(b.sortOrder) != null ? parseInt(b.sortOrder) : Infinity) - (parseInt(a.sortOrder) != null ? parseInt(a.sortOrder) : Infinity);
    });
  }

  if (product.options) {
    product.options.forEach(({values, required, option}, index) => {
      // remove not exist stock options
      const optionValues = values.filter(({subtract}) => subtract);
      delete product.options[index].value;
      if ((required || option.type == 'select') && optionValues.length) {
        const value = sortArray(optionValues)[0];
        value.price = Math.round((value.price) * Math.pow(10, 0)) / Math.pow(10, 0);
        if (!value.pricePrefix) {
          price = value.price;
        } else {
          options.push({...value, type: option.type});
        }
        requiredOptions.push({...value, type: option.type});
      }
    });
  }

  if (options.length) {
    class OptionCollection extends Array {
      sum(key) {
        return this.reduce((a, b) => a + b['pricePrefix'] + (b[key] || 0), 0);
      }
    }

    const option = new OptionCollection(...options);
    price = price + calculateSum(option, 'price');
  }

  return {
    price,
    options: requiredOptions,
  };
};

/**
  * @name dublicateProduct
  * @returns {function(*)}
*/
const dublicateProduct = (productId, storeId, jwt) => {
  return async dispatch => {
    try {

      dispatch({
        type: PRODUCT_TYPES.DUBLICATE_PRODUCTS_P
      })
      
      const { data } = await axios.post(
        config['PRODUCT'] + 'c/p/dublicate',
        {
          productId: productId,
          storeId: storeId
        },
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        }
      )

      dispatch({
        type: PRODUCT_TYPES.DUBLICATE_PRODUCTS_F,
        payload: data
      })

      return data

    } catch (e) {

      dispatch({
        type: PRODUCT_TYPES.DUBLICATE_PRODUCTS_R,
        payload: e
      })

      return e
    }
  }
}

/**
   * @name importProduct
   * @returns {function(*)}
*/
const importProduct = (jwt, products) => {
  return async dispatch => {
    try {

      dispatch({
        type: PRODUCT_TYPES.IMPORT_PRODUCTS_P
      })
      
      const { data } = await axios.post(
        config['PRODUCT'] + 'c/p/import',
        products,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        }
      )

      dispatch({
        type: PRODUCT_TYPES.IMPORT_PRODUCTS_F,
        payload: data
      })

      return data

    } catch (e) {

      dispatch({
        type: PRODUCT_TYPES.IMPORT_PRODUCTS_R,
        payload: e
      })

      return e
      
    }
  }
}


/**
   * @name exportProduct
   * @returns {function(*)}
*/
const exportProduct = (jwt) => {
  return async dispatch => {
    try {

      dispatch({
        type: PRODUCT_TYPES.EXPORT_PRODUCTS_P
      })
      
      const { data } = await axios.get(
        config['PRODUCT'] + 'c/p/export',
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        }
      )

      dispatch({
        type: PRODUCT_TYPES.EXPORT_PRODUCTS_F,
        payload: data
      })

      return data

    } catch (e) {

      dispatch({
        type: PRODUCT_TYPES.EXPORT_PRODUCTS_R,
        payload: e
      })

      return e
    }
  }
}

/**
   * @name getCategories
   * @returns {function(*)}
*/
const getCategories = (jwt, page, count) => {
  return async dispatch => {
    try {

      dispatch({
        type: PRODUCT_TYPES.GET_PRODUCT_CATEGORIES_P
      })
      
      const { data } = await axios.get(
        config['PRODUCT'] + `c/p/c/${page}/${count}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        }
      )

      dispatch({
        type: PRODUCT_TYPES.GET_PRODUCT_CATEGORIES_F,
        payload: data
      })

      return data

    } catch (e) {

      dispatch({
        type: PRODUCT_TYPES.GET_PRODUCT_CATEGORIES_R,
        payload: e
      })

      return e
    }
  }
}

/**
   * @name getBrands
   * @returns {function(*)}
*/
const getBrands = (jwt, page, count) => {
  return async dispatch => {
    try {

      dispatch({
        type: PRODUCT_TYPES.GET_PRODUCT_BRANDS_P
      })
      
      const { data } = await axios.get(
        config['PRODUCT'] + `c/p/b/${page}/${count}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        }
      )

      dispatch({
        type: PRODUCT_TYPES.GET_PRODUCT_BRANDS_F,
        payload: data
      })

      return data

    } catch (e) {

      dispatch({
        type: PRODUCT_TYPES.GET_PRODUCT_BRANDS_R,
        payload: e
      })

      return e
    }
  }
}

/**
 * @name getAll
 * @returns {function(*)}
 */
const getAll = (jwt) => {
  return dispatch => {
    return productService.getAll(jwt).then(options => {
      return dispatch({
        type: PRODUCT_TYPES.GET_ALL_PRODUCTS,
        payload : options
      })
    })
  }
};

const cleareProductSeeAll = () => {
  return dispatch => {
    return dispatch({
      type: PRODUCT_TYPES.CLEAR_PRODUCT_SEE_ALL
    })
  }
};

/**
     * @name getPaginationAll
     * @returns {function(*)}
*/
    const getPaginationAll = (jwt, page, count, filter, body) => {
    return async dispatch => {
        try {

            dispatch({
                type: PRODUCT_TYPES.GET_PAG_ALL_PRODUCTS_P
            })

            const data = await productService.getPaginationAll(jwt, page, count, filter, { names: body.products.map(({name}) => name) })

            const productsMap = body.products.reduce((acc, product) => {
                acc[product.name] = product;
                return acc;
            }, {});

            const newProductData = []
        
            data.data.forEach(product => {
                if (product.descriptions[0] && product.descriptions[0].name) {
                    
                    let selectData = {
                        key: product.productId,
                        productId: product.productId,
                        price: product.price,
                        model: product.model,
                        name: product.descriptions[0].name,
                        descriptions: product.descriptions,
                        imageMain: product.imageMain,
                        currentPrice: product.price,
                        value: product.productId, 
                        groupChecked: !!(product.productGroups && product.productGroups.length),
                        label: product.descriptions[0].name,
                        mainImgUrl: product.imageMain && product.imageMain[0] && product.imageMain[0].image ? config.URL + product.imageMain[0].image.url: '',
                        imgUrl: product.imageMain && product.imageMain[0] && product.imageMain[0].image ? config.IMAGEURL + product.imageMain[0].image.url: ''
                    }

                    selectData.options = []
                    selectData.activeOptions = []
                    product.productOptions.forEach((optionGroup) => {
                        let optionGroupData = {
                            key: product.productId,
                            required: optionGroup.required,
                            value: optionGroup.option.optionId,
                            label: optionGroup.option.source,
                            optionId: optionGroup.option.optionId,
                            option: optionGroup.option
                        }
            
                        optionGroupData.values = []
                        optionGroup.values.forEach((option) => {
                            if (option.optionValue && option.optionValue.descriptions && option.optionValue.descriptions.length) {
                                let optionData = {
                                    ...option,
                                    key: optionGroup.option.optionId,
                                    optionValueId: option.optionValueId,
                                    label: option.optionValue.descriptions[0].name,
                                    value: option.optionValueId,
                                    price: option.price,
                                    pointsPrefix: option.pointsPrefix
                                }
                                optionGroupData.values.push(optionData)
                            }
                        })
            
                        selectData.options.push(optionGroupData)
                    })

                    const {price, options} = calculateProductPrice(selectData);

                    selectData.price = price;
                    selectData.prevPrice = product.prevPrice; 
                    selectData.activeOptions = options.map((option) => option.optionValueId);
                    newProductData.push(selectData)
                }
            })

            let products = newProductData.map(value => {

                let { productOptions } = getOptions(value.activeOptions, value);
            
                // Find the corresponding product by name
                const matchingProduct = productsMap[value.name] || {};
            
                // Merge the value with the corresponding product details
                return {
                    productName: value.name,
                    productId: value.key,
                    orderedQty: matchingProduct.orderedQty || 0,
                    receivedQty: matchingProduct.receivedQty || 0,
                    cost: matchingProduct.cost || 0,
                    tax: matchingProduct.tax || 0,
                    quantity: value.quantity,
                    product: value,
                    options: productOptions,
                    activeOptions: value.activeOptions,
                    purchaseProductOption: productOptions,
                    price: value.price,
                    prevPrice: value.prevPrice
                };
            });

            dispatch({
                type: PRODUCT_TYPES.GET_PAG_ALL_PRODUCTS_F,
                payload: {
                    data: products,
                    count: data.count
                }
            })

            return products

        } catch (e) {

            dispatch({
                type: PRODUCT_TYPES.GET_PAG_ALL_PRODUCTS_R,
                payload: e
            })

            return e

        }
    }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, filter) => {
  return async dispatch => {
    try {

      dispatch({
        type: PRODUCT_TYPES.GET_PAG_NOT_REMOVED_PRODUCTS_P
      })

      const data = await productService.getPaginationNotRemoved(jwt, page, count, filter)

      dispatch({
        type: PRODUCT_TYPES.GET_PAG_NOT_REMOVED_PRODUCTS_F,
        payload: data
      })

    } catch (e) {

      dispatch({
        type: PRODUCT_TYPES.GET_PAG_NOT_REMOVED_PRODUCTS_R,
        payload: e
      })

    }
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, filter) => {
  return async dispatch => {
    try {

      dispatch({
        type: PRODUCT_TYPES.GET_PAG_REMOVED_PRODUCTS_P
      })

      const data = await productService.getPaginationRemoved(jwt, page, count, filter)

      dispatch({
        type: PRODUCT_TYPES.GET_PAG_REMOVED_PRODUCTS_F,
        payload: data
      })

    } catch(e) {

      dispatch({
        type: PRODUCT_TYPES.GET_PAG_REMOVED_PRODUCTS_R,
        payload: e
      })
      
    }
  }
};


/**
 * @name getAllRemoved
 * @returns {function(*)}
 */
const getAllRemoved = (jwt) => {
  return dispatch => {
    return productService.getAllRemoved(jwt).then(options => {
      return dispatch({
        type: PRODUCT_TYPES.GET_ALL_REMOVED_PRODUCTS,
        payload : options
      })
    })
  }
};

/**
 * @name getItem
 * @param id
 * @returns {function(*)}
 */
const getItem = (id, jwt) => {
  return dispatch => {
    return productService.getItem(id, jwt).then(data => {
      return dispatch({
        type: PRODUCT_TYPES.GET_PRODUCT_ITEMS,
        payload : data
      })
    })
  }
};
/**
 * @name selectItem
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    return dispatch({
      type : PRODUCT_TYPES.SELECT_PRODUCT_ITEMS,
      payload : elem
    })
  }
};


/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt) => {
  return dispatch => {
    productService.remove(elem.productId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: PRODUCT_TYPES.DELETE_PRODUCT_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    productService.recover(elem.productId,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: PRODUCT_TYPES.RECOVER_PRODUCT_ITEMS,
        payload : elem
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    productService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: PRODUCT_TYPES.ARCHIVE_PRODUCT_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    productService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: PRODUCT_TYPES.BULK_RECOVER_PRODUCT_ITEMS,
        payload : data
      })
    })
  }
};

const saveProduct = (product, jwt, history, discard, ) => {
  return async dispatch => {
    productService.create(product, jwt)
        .then(data => {

          Notification.success({
            title: 'Created',
            message: 'Your product succesfuly created',
          }, 20)
          
          if (discard) {
            history.push(`${discard}`)
          } else {
            history.push('/catalog/product')
          }

          dispatch({
              type: PRODUCT_TYPES.SAVE_PRODUCT_F,
              payload: data
          })

          dispatch({
            type: PRODUCT_TYPES.PRODUCT_TAB_PANEL_CHANGE,
            payload:  '1'
          }) 

        })
        .catch(error => {
            if (error.response.status === 422) {
              error.response.data.forEach(err => {
                Notification.error({
                  title: err.path,
                  message: err.message
                }, 20)
              })
            } 
          })
        }
    }

    const updateProduct = (product, id, token, history, discard) => {
      return async dispatch => {
        try {

            const data = await productService.edit(product, id, token)

            Notification.warning({
              title: 'Updated',
              message: 'Your product succesfuly updated '
            }, 20)

              if (discard) {
                history.push(`${discard}`)
              } else {
                history.push('/catalog/product')
              }

            dispatch({
                type: PRODUCT_TYPES.UPDATE_PRODUCT_F,
                payload: data
            })

            dispatch({
              type: PRODUCT_TYPES.PRODUCT_TAB_PANEL_CHANGE,
              payload:  '1'
            }) 
            
        } catch (error) {
          if (error.response.status === 422) {
            error.response.data.forEach(err => {
              Notification.error({
                title: err.path,
                message: err.message
              }, 20)
            })
          } 
          }
        }
      }

      const productPreview = (_token, id, url) => {
        return async dispatch => {
          try {

              window.open(`https://${url}/product/${id}`, '_blank')
              
              dispatch({
                  type: PRODUCT_TYPES.PRODUCT_PREVIEW_F,
                  payload: id
              })
              
          } catch (error) {
              error = error.response.data.error
              Notification.error({
                title: error.message,
                message: `${error.error.parent.code} / ${error.error.parent.sql}`
            }, 20) 
            }
          }
        }

        const paginationProducts = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const response = await productService.pagination(jwt, params, page, count, query)
                dispatch({
                    type: PRODUCT_TYPES.PRODUCTS_PAG_F,
                    payload: response
                })
            } catch (e) {
                dispatch({
                    type: PRODUCT_TYPES.PRODUCTS_PAG_R,
                    payload: e
                })
            }
        }
    }

    const destroyProduct = ( token, product ) => {

      return async dispatch => {
  
        try {
  
          await productService.destroy(token , product.productId)
    
            Notification.success( {
              title: 'product',
              message: `${product.descriptions[0].name} succesfuly deleted `
            }, 20 )
  
            dispatch( {
                type: PRODUCT_TYPES.DESTROY_PRODUCT,
                payload: product
            } )
            
        } catch (e) {
  
            Notification.error( {
              title: e.name,
              message: `${e.parent.column}`
            }, 20) 
           
          }
        }
      }

    /**
      * @name bulkDestroy
      * @param elem
      * @returns {function(*)}
    */
    const bulkDestroy = (ids, jwt) => {
      return dispatch => {
        productService.bulkDestroy(ids,  jwt).then((data) => {
          return dispatch({
            type: PRODUCT_TYPES.BULK_DESTROY_PRODUCT_ITEMS,
            payload : data
          })
        })
      }
    };

  
  /**
    * @name fetchBrandSeeAll
    * @returns {function(*)}
  */
  const fetchProductSeeAll = (jwt, page, count, filter, search) => {
    return dispatch => {
      return productService.getPaginationNotRemoved(jwt, page, count, filter).then(options => {
        return dispatch({
          type: PRODUCT_TYPES.FETCH_PRODUCT_SEE_ALL,
          payload : options, 
          search: search
        })
      })
    }
  };


export {getAll, getAllRemoved, getItem, selectItem, getPaginationAll, getPaginationNotRemoved, getPaginationRemoved, deleteItem, recoverItem, bulkDelete, bulkRecover, saveProduct, updateProduct, productPreview, paginationProducts, destroyProduct, bulkDestroy, fetchProductSeeAll, importProduct, exportProduct, getCategories, getBrands, dublicateProduct, cleareProductSeeAll}