import { CAMPAIGN_TYPES } from '../../types'
import Fields from '../../../smsServie/campaign/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
    data: [],
    campaign: new Fields(),
    error: {},
    count: 0,
    pageNumber: {
        removed: localStorage.getItem('removedCampaignPage') || 1,
        notRemoved: localStorage.getItem('notRemovedCampaignPage') || 1
    },
    showEntries: {
        removed: localStorage.getItem('removedCampaignPageSize') || 10,
        notRemoved: localStorage.getItem('notRemovedCampaignPageSize') || 10
    },
    langTab : {
        languageId: 1,
        langTab: 0
    },
    filterTab: {
        filterTab: true,
        path: 'r/',
        removed: 'notRemoved'
    },
    initialFilter: {
        message: '',
        from: '',
        to: '',
        statusF: null,
        datesF:  '',
    },
    dataFilter: {
        search: '',
        message: false,
    },
    minMaxValues: {},
    isOpen: isMobile ? false : localStorage.getItem('campaignFilter') == 'true' ? true : false,
    tabPanel: '1',
    checked: false,
    ids: []
};



const campaign = ( state = initialState, action = {}) => {

  switch (action.type) {


    case CAMPAIGN_TYPES.CAMPAIGN_NEW: {
        return {
            ...state,
            campaign: new Fields()
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_GET_ONE_P: {
        return {
            ...state,
            loading: true
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_GET_ONE_F: {
        return {
            ...state,
            loading: false,
            campaign: {
                ...new Fields(),
                ...action.payload
            }
        }
    }


    case CAMPAIGN_TYPES.CAMPAIGN_GET_ONE_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.data
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_PAG_F: {
        return {
            ...state,
            data: action.payload.data.map((elem) => state.ids.includes(elem.campaignId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
            checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.campaignId)),
            count: action.payload.count
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_SIZE_CHANGE: {

        const showEntries = Object.assign(state.showEntries, action.payload)
      
        return {
            ...state,
            showEntries: showEntries
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_PAGE_CHANGE: {
      
        const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_FILETR_TABE_CHANGE: {
      
        return {
            ...state,
            filterTab: action.payload,
            checked: false
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_TAB_PANEL_CHANGE: {
      
        return {
            ...state,
            tabPanel: action.payload
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_CHECKBOX_CHANGE: {
        let ids = [...state.ids]
        const { checked, multiple, key } = action.payload
        const data = state.data.map(elem => {

            if (multiple) {

                elem['isChecked'] = checked
                return elem

            } else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.campaignId) })

        return {
            ...state,
            data: data,
            checked: !multiple ? data.every(element => element.isChecked): checked,
            ids: ids
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_BULK_ARCIVE_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case CAMPAIGN_TYPES.CAMPAIGN_BULK_DESTROY_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case CAMPAIGN_TYPES.CAMPAIGN_LANGUAGE_PANEL_CHANGE: {
        return { 
            ...state,
            langTab: action.payload
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_CREATE_R: {
        return { 
            ...state,
            error: action.payload.response
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_DATA_FILTER: {
        return { 
            ...state,
            dataFilter: { [action.payload['key']]: action.payload['value'] },
            error: []
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_DELETE_F: {
        const id = action.payload;
        return {
            ...state,
            data: state.data.filter(item => item.customer !== id)
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_FILTER_IS_OPEN: {
        localStorage.setItem('campaignFilter', !state.isOpen)
        return {
            ...state,
            isOpen: !state.isOpen
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
            error: []
        }
    }
    
    case CAMPAIGN_TYPES.CAMPAIGN_DATA_FILTER_ALL_SEARCH_RESET: {
        const { key } = action.payload
        return {
            ...state,
            [key]: { 
                from: '',
                to: '',
                message: '',
                status: null,
                dates:  '',
            }
        }
    }

    case CAMPAIGN_TYPES.CAMPAIGN_DATA_MIN_MAX_F: {
        return {
            ...state,
            minMaxValues: action.payload
        }
    }

    case CAMPAIGN_TYPES.SMS_REPORT_BULK: {

        const updatedData = state.data.map((obj) =>
            obj.campaignId === action.payload.campaign_id
            ? {
                    ...obj,
                    price: action.payload.price,
                    deliveredCount: action.payload.delivered_count,
                    sentCount: action.payload.sent_count,
                    spamCount: action.payload.spam_count,
                    errorCount: action.payload.error_count,
                    pendingCount: action.payload.pending_count
                }
            : obj
        );
    
        const newState = {
            ...state,
            error: {},
            data: updatedData
        };
    
        if (action.payload.campaign_id === state.campaign.campaignId) {
            newState.campaign = {
                ...state.campaign,
                deliveredCount: action.payload.delivered_count,
                sentCount: action.payload.sent_count,
                spamCount: action.payload.spam_count,
                errorCount: action.payload.error_count,
                pendingCount: action.payload.pending_count
            };
        }

        return newState;
    }

    default:
        return state;
    }
}


export {
    campaign
}

