import React, { Fragment } from "react";
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import moment from 'moment';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';

const mapStyles = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
];

const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};


const MapWithAMarkers = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD7wKts-uMZIqoiloM7yWbROguKqZ9w3Tc&v=3.exp&libraries=geometry,drawing,places,visualization",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentDidMount() {
            const { markers } = this.props
            this.setState({
                zoomToMarkers: map => {
                    const bounds = new window.google.maps.LatLngBounds();
                    if (markers.length) {
                        markers.forEach((child) => {
                            bounds.extend(new window.google.maps.LatLng(child.lat, child.lng));
                        })
                        if (map && map.fitBounds) {
                            map.fitBounds(bounds);
                        }
                    }
                },
            })
        }
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap 
        ref={props.zoomToMarkers} 
        defaultZoom={5}
        options={options} 
        defaultCenter={{ lat: 25.0391667, lng: 121.525 }}>
        {
            props.showMarkers && props.markers.map((marker, i) => (
                <Marker
                    key={i}
                    onMouseOver={() => props.handleMouseOver(i)} 
                    onMouseOut={() => props.handleMouseExit(null)}
                    position={{ lat: marker.lat, lng: marker.lng }}
                >
                    {props.showInfoWindow == i && (
                        <InfoWindow>
                            <div>
                                <h5>{marker.address}</h5>
                                <h6>{moment(marker.date).format('MMMM Do YYYY, h:mm:ss a')}</h6>
                                <h6>{Number(marker.price)} {marker.currency}</h6>
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            ))
        }
        {
            !!props.markers.length && props.showHeatmapLayer &&
            <HeatmapLayer
                data={props.markers.map(point => (
                    {
                        location: new window.google.maps.LatLng(
                            point.lat,
                            point.lng
                        ), 
                        weight: point.weight || 500
                    }
                ))}
                options={{radius: 55}}
            />
        }
    </GoogleMap>
);

class MapComponent extends React.PureComponent {
    
    componentWillMount() {
        const { showHeatmapLayer } = this.props
        this.setState({ 
            markers: [], 
            showMarkers: showHeatmapLayer ? false : true 
        })
    }


    componentDidMount() {
        this.setMarkers()
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props

        if (prevProps.data.length !== data.length) {
            this.setMarkers()
        }
        
    }

    setMarkers() {
        const { data, elemKey } = this.props
        const markers = []
        if (data.length) {
            data.forEach(e => {
                if (e[elemKey] && e[elemKey]['latitude']) {
                    markers.push({
                        lat : parseFloat(e[elemKey]['latitude']),
                        lng: parseFloat(e[elemKey]['longitude']),
                        address: e[elemKey].address,
                        date: e.dateAdded,
                        price: Number(e.total),
                        currency: e.currencyCode
                    })
                }
            })
        }
        this.setState({ markers: markers });
    }


    handleMouseOver = (index) => {
        this.setState({
            showInfoWindow: index
        });
    };

    handleMouseExit = (index) => {
        this.setState({
            showInfoWindow: index
        });
    };

    render() {
        const { markers, showInfoWindow, showMarkers } = this.state
        const { showHeatmapLayer } = this.props
        return (
            !!markers.length &&
            <Fragment> 
                {
                    showHeatmapLayer &&
                    <button onClick={() => this.setState({showMarkers: !showMarkers})}>Show Markers</button>
                }
                <MapWithAMarkers
                    showInfoWindow={showInfoWindow}
                    markers={this.state.markers} 
                    showMarkers={showMarkers}
                    showHeatmapLayer={showHeatmapLayer}
                    handleMouseOver={
                        this.handleMouseOver
                    }
                    handleMouseExit={
                        this.handleMouseExit
                    }
                />
            </Fragment>
        )
    }
}

export default MapComponent;