import config from '../../../config/app'
import {Service} from "./Service";

const all = 'c/i/r';
const allR = 'c/i/d';
const rest = 'c/i';
const pagNotRem = "c/i/r";
const pagRem= "c/i/d";
const bulkDel = 'c/i/b/a';
const bulkRec = 'c/i/b/r'
const bulkDes = 'c/i/b/d'

const informationService = new Service(config['INFORMATION'], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);

export {informationService}