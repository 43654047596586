import config from '../../../config/app'
import {Service} from "./Service";

const all = 'c/o/r';
const allR = 'c/o/d';
const rest = 'c/o';
const pagNotRem = "c/o/r";
const pagRem= "c/o/d";
const bulkDel = 'c/o/b/a';
const bulkRec = 'c/o/b/r';
const bulkDes = 'c/o/b/d';
const relation = 'relation'

const optionsService = new Service(config['OPTION'], all, allR, rest, pagNotRem, pagRem,  bulkDel, bulkRec, bulkDes, relation);

export {optionsService}