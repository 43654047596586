const NOTIFICATION_SETTINGS_UPDATE_P = 'NOTIFICATION_SETTINGS_UPDATE_P'
const NOTIFICATION_SETTINGS_UPDATE_F = 'NOTIFICATION_SETTINGS_UPDATE_F'
const NOTIFICATION_SETTINGS_UPDATE_R = 'NOTIFICATION_SETTINGS_UPDATE_R'
const NOTIFICATION_SETTINGS_GET_ONE_P = 'NOTIFICATION_SETTINGS_GET_ONE_P'
const NOTIFICATION_SETTINGS_GET_ONE_F = 'NOTIFICATION_SETTINGS_GET_ONE_F'
const NOTIFICATION_SETTINGS_GET_ONE_R = 'NOTIFICATION_SETTINGS_GET_ONE_R'

export {
    NOTIFICATION_SETTINGS_UPDATE_P,
    NOTIFICATION_SETTINGS_UPDATE_F,
    NOTIFICATION_SETTINGS_UPDATE_R,
    NOTIFICATION_SETTINGS_GET_ONE_P,
    NOTIFICATION_SETTINGS_GET_ONE_F,
    NOTIFICATION_SETTINGS_GET_ONE_R,
}