import  { ZIP_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

 class ZipActions {
    constructor(_props) {
        this.api = config['FRANCHISE']
        this.rest  = 'z'
    }

    seeAll = (jwt, page, count, query) => {
      return async dispatch => {
        try {

            dispatch({
                type: ZIP_TYPES.FETCH_ZIP_SEE_ALL_P
            })

            const searchString = qs.stringify(query)

            const response = await axios.get(
            this.api + this.rest + `/r/` + page + '/' + count + '?' + searchString,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                    }
                })

                dispatch({
                    type: ZIP_TYPES.FETCH_ZIP_SEE_ALL_F,
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: ZIP_TYPES.FETCH_ZIP_SEE_ALL_R,
                    payload: e
                })

            }
        }
    }
 
}




export default new ZipActions()