import  { SMS_PHONE_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

class SmsPhoneActions {
    constructor(_props) {
        this.api = config['SMS_API']
        this.rest  = 'phone/'
        this.arcive = 'b/'
    }


    create = (jwt, body, Type, history) => {
        return async dispatch => {
            try {

                const response = await axios.post(
                    this.api + this.rest,
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
          
                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_CREATE_F'],
                    payload: response.data
                })
          
                    
                history.goBack()

            } catch (e) {

                if (e.response.data && e.response.data.length) {
                    e.response.data.forEach(err => {
                        Notification.error({
                            title: err.param,
                            message: err.message
                        }, 20) 
                    })
                } 

                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_UPDATE_R'],
                    payload: e
                })
      
            }

        }
    }


    update = (jwt, body, Type, history, _account) => {
        return async dispatch => {
            try {
                
                await axios.put(
                    this.api + this.rest + `/${body.smsPhoneId}`,
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
        
                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_UPDATE_F'],
                    payload: body
                })
    
                history.goBack()
                
            } catch (e) {

                if (e.response.data && e.response.data.length) {
                    e.response.data.forEach(err => {
                        Notification.error({
                            title: err.param,
                            message: err.message
                        }, 20) 
                    })
                } 

                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_UPDATE_R'],
                    payload: e
                })
    
            }
        }
    }

    pagination = (jwt, params, page, count, query) => {
        return async dispatch => {
            try {

                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + this.rest + `${params}` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                if (data.data.length) {
                    data.data = data.data.map(item => ({ businessName: item.business ? item.business.name : '', ...item }))
                }

                dispatch({
                    type: SMS_PHONE_TYPES.SMS_PHONE_PAG_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_PHONE_TYPES.SMS_PHONE_PAG_R,
                    payload: e
                })
            }
        }
    }

    destroy = (jwt, id, Type) => {
        return async dispatch => {
            try {
                await axios.delete(
                    this.api + this.rest + `/${id}`,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_DELETE_F'],
                    payload: id
                })
            } catch (e) {
                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_DELETE_R'],
                    payload: e
                })
            }
        }
    }

    getOne = (jwt, id, Type) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_GET_ONE_P']
                })

                const response = await axios.get(
                    this.api + this.rest + `${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_GET_ONE_F'],
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: SMS_PHONE_TYPES[Type + '_GET_ONE_R'],
                    payload: e
                })

            }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
    */
    bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                this.api + this.rest + this.arcive + params,
                ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_PHONE_TYPES.SMS_PHONE_BULK_ARCIVE_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: SMS_PHONE_TYPES.SMS_PHONE_BULK_ARCIVE_R,
                    payload: e
                })

            }
        }
    }

    /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
    */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                    this.api + this.rest + 'b/d',
                    ids,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_PHONE_TYPES.SMS_PHONE_BULK_DESTROY_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: SMS_PHONE_TYPES.SMS_PHONE_BULK_DESTROY_R,
                    payload: e
                })

            }
        }
    }

     // clear page cache
     clearCache = () => {
        return async dispatch => {
            dispatch({
                type: SMS_PHONE_TYPES.NEW_SMS_PHONE
            })
        }
    }

}




export default new SmsPhoneActions()