import config from '../../../config/app'
import {Service} from "./Service";
const all = 'c/c/r';
const allR = 'c/c/d';
const rest = 'c/c';
const pagNotRem = "c/c/r";
const pagRem= "c/c/d";
const bulkDel = 'c/c/b/a';
const bulkRec = 'c/c/b/r'
const bulkDes = 'c/c/b/d'


const categoriesService = new Service(config["CATEGORY"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);
export {categoriesService};