const CALL_PAG_F = 'CALL_PAG_F';
const CALL_PAG_R = 'CALL_PAG_R';
const CALL_SIZE_CHANGE = 'CALL_SIZE_CHANGE'
const CALL_PAGE_CHANGE = 'CALL_PAGE_CHANGE'
const CALL_FILETR_TABE_CHANGE = 'CALL_FILETR_TABE_CHANGE'
const CALL_UPDATE_F = 'CALL_UPDATE_F'
const CALL_UPDATE_R = 'CALL_UPDATE_R'
const CALL_CHECKBOX_CHANGE = 'CALL_CHECKBOX_CHANGE'
const CALL_BULK_ARCIVE_F = 'CALL_BULK_ARCIVE_F'
const CALL_BULK_ARCIVE_R = 'CALL_BULK_ARCIVE_R'
const CALL_BULK_DESTROY_F = 'CALL_BULK_DESTROY_F'
const CALL_BULK_DESTROY_R = 'CALL_BULK_DESTROY_R'
const CALL_TAB_PANEL_CHANGE = 'CALL_TAB_PANEL_CHANGE'
const CALL_DATA_FILTER = 'CALL_DATA_FILTER'
const CALL_DELETE_F = 'CALL_DELETE_F'
const CALL_DELETE_R = 'CALL_DELETE_R'
const CALL_FILTER_IS_OPEN = 'CALL_FILTER_IS_OPEN'
const CALL_DATA_FILTER_SEARCH = 'CALL_DATA_FILTER_SEARCH'
const CALL_DATA_MIN_MAX_F = 'CALL_DATA_MIN_MAX_F'
const CALL_SELECT_FILTER_TYPES = 'CALL_SELECT_FILTER_TYPES'
const CALL_LANGUAGE_PANEL_CHANGE = 'CALL_LANGUAGE_PANEL_CHANGE'
const CALL_GET_ORDER = 'CALL_LANGUAGE_GET_ORDER'
const CALL_DATA_FILTER_ALL_SEARCH_RESET = 'CALL_DATA_FILTER_ALL_SEARCH_RESET'
const GET_CALL_STATISTICS_P = 'GET_CALL_STATISTICS_P'
const GET_CALL_STATISTICS_F = 'GET_CALL_STATISTICS_F'
const GET_CALL_STATISTICS_R = 'GET_CALL_STATISTICS_R'
const CALL_GET_ONE_P = 'CALL_GET_ONE_P'
const CALL_GET_ONE_F = 'CALL_GET_ONE_F'
const CALL_GET_ONE_R = 'CALL_GET_ONE_R'
const CALL_RECORD_P = 'CALL_RECORD_P'
const CALL_RECORD_F = 'CALL_RECORD_F'
const CALL_RECORD_R = 'CALL_RECORD_R'
const CALL_BLOCK_OR_UNBLOCK_P = 'CALL_BLOCK_OR_UNBLOCK_P'
const CALL_BLOCK_OR_UNBLOCK_F = 'CALL_BLOCK_OR_UNBLOCK_F'
const CALL_BLOCK_OR_UNBLOCK_R = 'CALL_BLOCK_OR_UNBLOCK_R'
const CALL_CLEAR_ONE = 'CALL_CLEAR_ONE'
const CALL_CREATE_F = 'CALL_CREATE_F'
const CALL_CREATE_R = 'CALL_CREATE_R'
const CALL_CHECK_PROVIDER_P = 'CALL_CHECK_PROVIDER_P'
const CALL_CHECK_PROVIDER_F = 'CALL_CHECK_PROVIDER_F'
const CALL_CHECK_PROVIDER_R = 'CALL_CHECK_PROVIDER_R'

export {
    CALL_PAG_R,
    CALL_PAG_F,
    CALL_SIZE_CHANGE,
    CALL_PAGE_CHANGE,
    CALL_FILETR_TABE_CHANGE,
    CALL_UPDATE_F,
    CALL_UPDATE_R,
    CALL_CHECKBOX_CHANGE,
    CALL_BULK_ARCIVE_F,
    CALL_BULK_ARCIVE_R,
    CALL_BULK_DESTROY_R,
    CALL_BULK_DESTROY_F,
    CALL_TAB_PANEL_CHANGE,
    CALL_DATA_FILTER,
    CALL_DELETE_F,
    CALL_DELETE_R,
    CALL_FILTER_IS_OPEN,
    CALL_DATA_FILTER_SEARCH,
    CALL_DATA_MIN_MAX_F,
    CALL_SELECT_FILTER_TYPES,
    CALL_LANGUAGE_PANEL_CHANGE,
    CALL_GET_ORDER,
    CALL_DATA_FILTER_ALL_SEARCH_RESET,
    GET_CALL_STATISTICS_P,
    GET_CALL_STATISTICS_F,
    GET_CALL_STATISTICS_R,
    CALL_GET_ONE_P,
    CALL_GET_ONE_F,
    CALL_GET_ONE_R,
    CALL_RECORD_P,
    CALL_RECORD_F,
    CALL_RECORD_R,
    CALL_BLOCK_OR_UNBLOCK_P,
    CALL_BLOCK_OR_UNBLOCK_F,
    CALL_BLOCK_OR_UNBLOCK_R,
    CALL_CLEAR_ONE,
    CALL_CREATE_F,
    CALL_CREATE_R,
    CALL_CHECK_PROVIDER_P,
    CALL_CHECK_PROVIDER_F,
    CALL_CHECK_PROVIDER_R
}