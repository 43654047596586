import React, { useEffect, useState, useRef } from "react";

function Layout(props) {
    const { load, isFetch } = props
    const [page, setPage] = useState(1);
    const ref = useRef();

    const handleScroll = () => {
        const div = ref.current;
        if (div.scrollTop + div.clientHeight >= div.scrollHeight - 20) {
            loadMore();
        }
    }

    useEffect(() => {
        const div = ref.current;
        if (div) {
            div.addEventListener("scroll", handleScroll);
            return () => div.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll]);


    const loadMore = () => {

        if (load) {
            return
        }

        if (!isFetch) {
            return
        }

        props.fetchData(page + 1, 10)
        setPage(page + 1)
        
    }

    return (
        <div className={props.className} ref={ref}>
          
            {
                props.children
            }
  
            {!!load && props.loadComponent}
  
        </div>
    )
}

export default Layout