import  { PAGE_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

class PageActions {
  constructor(_props) {
    this.api = config['STORE']
    this.rest  = 'c/page/'
    this.arcive = 'b/'
  }

  seeAll = (jwt, page, count, query, search) => {
    return async dispatch => {
      try {

        dispatch({
          type: PAGE_TYPES.FETCH_PAGE_SEE_ALL_P
        })

        const searchString = qs.stringify(query)
        const response = await axios.get(
        this.api + this.rest + `/r/` + page + '/' + count + '?' + searchString,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
          }
        })

        dispatch({
          type: PAGE_TYPES.FETCH_PAGE_SEE_ALL_F,
          payload: response.data,
          search: search
        })

      } catch (e) {

        dispatch({
          type: PAGE_TYPES.FETCH_PAGE_SEE_ALL_R,
          payload: e
        })

      }
    }
  }

  getOne = (jwt, id) => {
    return async dispatch => {
      try {

        dispatch({
          type: PAGE_TYPES.PAGE_GET_ONE_P
        })

        const response = await axios.get(
          this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }
        )

        dispatch({
            type: PAGE_TYPES.PAGE_GET_ONE_F,
            payload: response.data
        })

      } catch (e) {

          dispatch({
            type: PAGE_TYPES.PAGE_GET_ONE_R,
            payload: e
          })

        }
      }
  }

}


export default new PageActions()