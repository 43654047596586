import  { CUSTOMER_GROUP_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import { Notification } from "element-react";

 class CustomerGroupActions {
  constructor(_props) {
    this.api = config['CUSTOMER']
    this.rest  = 'cu/g/c'
    this.arcive = '/b/'
  }


    /**
       * @name importCustomer
       * @returns {function(*)}
    */
    importCustomer = (customers) => {
      return async dispatch => {
        dispatch({
          type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_IMPORT_CUSTOMER,
          payload: customers
        })
      }
    }

    pagination = (jwt, params, page, count, query) => {
        return async dispatch => {
          try {
            const searchString = qs.stringify(query)
            const { data } = await axios.get(
              this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              })
              dispatch({
                  type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_PAG_F,
                  payload: data
              })
          } catch (e) {
              dispatch({
                  type: CUSTOMER_GROUP_TYPES.CUSTOMER_PAG_R,
                  payload: e
              })
          }
      }
    }

    chooseFilter = (data, id) => {
      return async dispatch => {
        dispatch({
          type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER,
          payload: data,
          id: id
        })
      } 
    }

    chooseFilterValue = (data) => {
      return async dispatch => {
        dispatch({
          type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_VALUE,
          payload: data
        })
      } 
    }

    chooseFilterDateValue = (data) => {
      return async dispatch => {
        dispatch({
          type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_DATE_VALUE,
          payload: data
        })
      } 
    }

    chooseFilterCategories = (data) => {
      return async dispatch => {
        dispatch({
          type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_CATEGORIES,
          payload: data
        })
      } 
    }

    chooseFilterBusiness = (data) => {
      return async dispatch => {
        dispatch({
          type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_CHOOSE_FILTER_BUSINESS,
          payload: data
        })
      } 
    }

    removeFilter = (id) => {
      return async dispatch => {
        dispatch({
          type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_REMOVE_FILTER,
          payload: id
        })
      } 
    }

    getFilters = (jwt, page, count, query) => {
      return async dispatch => {
        try {
          const searchString = qs.stringify(query)
          const { data } = await axios.get(
            this.api + this.rest + `/f/` + page + '/' + count + '?' + searchString,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })
            dispatch({
              type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_FILTER_F,
              payload: data
            })
        } catch (e) {
          dispatch({
            type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_FILTER_R,
            payload: e
          })
        }
      } 
    }

    fetchCustomerGroupSeeAll = (jwt, params, page, count, query) => {
      return async dispatch => {
        try {
          const searchString = qs.stringify(query)
          const { data } = await axios.get(
            this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })
            dispatch({
                type: CUSTOMER_GROUP_TYPES.FETCH_CUSTOMER_GROUP_SEE_ALL_F,
                payload: data
            })
          } catch (e) {
              dispatch({
                type: CUSTOMER_GROUP_TYPES.FETCH_CUSTOMER_GROUP_SEE_ALL_R,
                payload: e
              })
          }
      }
    }

    update = (jwt, body, Type, history, _discard, customerImport) => {
      return async dispatch => {
      try {
          const response = await axios.put(
            this.api + this.rest + `/${body.customerGroupId}`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: CUSTOMER_GROUP_TYPES[Type + '_UPDATE_F'],
            payload: body
          })
            
          if (!customerImport) {
            history.push('/user/customer_group')
          }

          return response.data
            
        } catch (error) {

          if (error.response.status === 422) {
            error.response.data.forEach(err => {
              Notification.error({
                title: err.path,
                message: err.message
              }, 20)
            })
          } 

          dispatch({
            type: CUSTOMER_GROUP_TYPES[Type + '_UPDATE_R'],
            payload: error
          })

        }
      }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.post(
            this.api + this.rest + '/b/d',
            [id],
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
          dispatch({
              type: CUSTOMER_GROUP_TYPES[Type + '_DELETE_F'],
              payload: id
          })
        } catch (e) {
            dispatch({
                type: CUSTOMER_GROUP_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: CUSTOMER_GROUP_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: CUSTOMER_GROUP_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_BULK_ARCIVE_F,
                  payload: ids
              })
              dispatch(this.pagination(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }

          /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + this.rest + '/b/d',
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_BULK_DESTROY_F,
                payload: ids
            })
            dispatch(this.pagination(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: CUSTOMER_GROUP_TYPES.CUSTOMER_GROUP_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

      create = (jwt, body, Type, history, _discard, customerImport) => {
        return async dispatch => {
          try {
            const response = await axios.post(
              this.api + this.rest,
              body,
              {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
              }
            )

            dispatch({
              type: CUSTOMER_GROUP_TYPES[Type + '_CREATE_F'],
              payload: response
            })

            if (!customerImport) {
              history.push('/user/customer_group')
            }

            return response.data
            
          } catch (err) {

              if (error.response.status === 422) {
                error.response.data.forEach(err => {
                  Notification.error({
                    title: err.path,
                    message: err.message
                  }, 20)
                })
              } 

              dispatch({
                type: CUSTOMER_GROUP_TYPES[Type + '_CREATE_R'],
                payload: error
              })

            }
          }
      }
}




export default new CustomerGroupActions()