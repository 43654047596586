import {SLIDE_TYPES} from "../../types/index"


const allSlides = (state = [], action = {}) => {
  if (action.type === SLIDE_TYPES.GET_ALL_SLIDES) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedSlides = (state = [], action = {}) => {
  if (action.type === SLIDE_TYPES.GET_ALL_REMOVED_SLIDES) {
    return action.payload;
  } else {
    return state
  }
};

const slides = (state = {}, action = {}) => {
  if (action.type === SLIDE_TYPES.GET_SLIDE_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedSlides = (state = {}, action = {}) => {
  if (action.type === SLIDE_TYPES.SELECT_SLIDE_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

export {allSlides,allRemovedSlides,slides,selectedSlides}