import {CATEGORY_TYPES} from '../../types/index'
import Category from '../../../types/Category'
import CategoryDescription from '../../../types/CategoryDescription';
import CategorySeoUrl from '../../../types/CategorySeoUrl';


const defaultState = {
  pagRemovedCategory: [],
  pagNotRemovedCategory: [],
  pagRemovedCategoryCount: 0,
  pagNotRemovedCategoryCount: 0,
  bulkDeletedItems: [],
  category: new Category(),
  loading: false,
  categorySelect: [],
  categorySelectCount: 0,
  tabPanel: localStorage.getItem('categoryActiveTab') || '1',
  langTab: {
    languageId:parseInt(localStorage.getItem('categoryLanguageId')) || 1,
    LangTab: parseInt(localStorage.getItem('categoryLangTab')) || 0,
  }
};

const category = (state = [], action = {}) => {
  if (action.type === CATEGORY_TYPES.GET_CATEGORY) {
    return action.payload;
  } else {
    return state
  }
};

const deletedCategory = (state = [], action = {}) => {
  if (action.type === CATEGORY_TYPES.GET_DELETED_CATEGORY) {
    return action.payload;
  } else {
    return state
  }
};

const selectedCategory = (state = {}, action = {}) => {
  if (action.type === CATEGORY_TYPES.SELECT_CATEGORY_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};


const categories = ( state = defaultState, action = {}) => {
  switch (action.type) {

      case CATEGORY_TYPES.GET_PAG_REMOVED_CATEGORIES: {

          return {
            ...state,
            pagRemovedCategory: action.payload.data,
            pagRemovedCategoryCount: action.payload.count
          }

      }

      case CATEGORY_TYPES.GET_PAG_NOT_REMOVED_CATEGORIES: {

          return {
            ...state,
            pagNotRemovedCategory: action.payload.data,
            pagNotRemovedCategoryCount: action.payload.count
          }

      }

      case CATEGORY_TYPES.DELETE_CATEGORY_ITEMS: {

          const id = action.payload.categoryId;

          return {
            ...state,
            pagNotRemovedCategory: state.pagNotRemovedCategory.filter(item => item.categoryId !== id)
          }

      }

      case CATEGORY_TYPES.RECOVER_CATEGORY_ITEMS: {

          const id = action.payload.categoryId;
          state.pagNotRemovedCategory.unshift(action.payload)

          return {
              ...state,
              pagRemovedCategory: state.pagRemovedCategory.filter(item => item.categoryId !== id),
              pagNotRemovedCategory: state.pagNotRemovedCategory,
              pagNotRemovedCategoryCount: state.pagNotRemovedCategoryCount + 1
            }

      }

      case CATEGORY_TYPES.ARCHIVE_CATEGORY_ITEMS: {

        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagNotRemovedCategoryCount: state.pagNotRemovedCategoryCount - action.payload.length
          }

      }

      case CATEGORY_TYPES.BULK_RECOVER_CATEGORY_ITEMS: {

        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagRemovedCategoryCount: state.pagRemovedCategoryCount - action.payload.length
        }
      }

      case CATEGORY_TYPES.NEW_CATEGORY_FULFILLED: {
          const { language } = action.state
          let category = new Category()
          category.descriptions = []
          category.categorySeoUrl = []
          if (language.length) {
            language.forEach(({languageId}) => {
              let description = new CategoryDescription()
              let seoUrl = new CategorySeoUrl()
              seoUrl['languageId'] = languageId
              description['languageId'] = languageId
              category.descriptions.push(description) 
              category.categorySeoUrl.push(seoUrl)
            })
          }

          return {
              ...state,
              category: category
          }
      }

      case CATEGORY_TYPES.NEW_CATEGORY_REJECTED : {

          return {
              ...state,
              error: action.payload.error
          }

      }

      case CATEGORY_TYPES.SAVE_CATEGORY_FULFILLED: {

          return {
              ...state,
          }

      }

      case CATEGORY_TYPES.FETCH_CATEGORY_SEE_ALL: {

        let newCategory = []
        action.payload.data.forEach(elem => {
          if (elem.descriptions && elem.descriptions[state.langTab.LangTab] && elem.descriptions[state.langTab.LangTab].name) {
            newCategory.push({ value: elem.categoryId, label: elem.descriptions[state.langTab.LangTab].name })
          } else {
            newCategory.push({ value: elem.categoryId, label: elem.categoryId + ' ' })
          }
        })
        let categorySelect = [...state.categorySelect, ...newCategory]
        categorySelect = categorySelect.filter((elem, index, self) =>
          index === self.findIndex((e) => (
            e.value === elem.value
          ))
        )

          return {
              ...state,
              categorySelect: categorySelect,
              categorySelectCount: action.payload.count
          }

      }

      case CATEGORY_TYPES.FETCH_CATEGORY_PENDING: {
          return {
              ...state,
              loading: true
          }
      }

      case CATEGORY_TYPES.FETCH_CATEGORY_FULFILLED: {
          const { language } = action.state
          const { data } = action.payload

          if (language.length) {
            language.forEach(({languageId}) => {
              if (!data.descriptions.find(element => element.languageId === languageId)) {
                let description = new CategoryDescription()
                description['languageId'] = languageId
                data.descriptions.push(description) 
              }

              if (!data.categorySeoUrl.find(element => element.languageId === languageId)) {
                let seoUrl = new CategorySeoUrl()
                seoUrl['languageId'] = languageId
                data.categorySeoUrl.push(seoUrl)
              }
            })
          }

          return {
              ...state,
              category: data,
              loading: false
          }

      }

      case CATEGORY_TYPES.CHANGE_CATEGORY_BODY: {
        const { language } = action.payload
        const data  = state.category

        if (language.length) {
          language.forEach(({languageId}) => {
            if (!data.descriptions.find(element => element.languageId === languageId)) {
              let description = new CategoryDescription()
              description['languageId'] = languageId
              data.descriptions.push(description) 
            }

            if (!data.categorySeoUrl.find(element => element.languageId === languageId)) {
              let seoUrl = new CategorySeoUrl()
              seoUrl['languageId'] = languageId
              data.categorySeoUrl.push(seoUrl)
            }
          })
        }

        return {
            ...state,
            category: data,
            loading: false
        }

      }

      case CATEGORY_TYPES.UPDATE_CATEGORY_FULFILLED: {
          return {
              ...state
          }
      }

      case CATEGORY_TYPES.LANG_TAB: {

          return {
              ...state,
              langTab: action.payload,
          }

      }

      case CATEGORY_TYPES.DESTROY_CATEGORY: {
        const id = action.payload.categoryId;
        return {
            ...state,
            pagRemovedCategory: state.pagRemovedCategory.filter(item => item.categoryId !== id),
            loading: false
        }
      }


      case CATEGORY_TYPES.CATEGORY_TAB_PANEL_CHANGE: {
        return {
            ...state,
            tabPanel: action.payload,
            loading: false
        }
      }
  
    default:
            return state;
  }
}
export {category, deletedCategory, selectedCategory, categories}
