import React from "react";

function CheckboxOption (props) {
    const { option, sortArray, product, changeOptions } = props

    function addOption(item) {
        if (product.activeOptions.includes(item.optionValueId)) {
            product.activeOptions = product.activeOptions.filter(id => id !== item.optionValueId) 
        } else {
            product.activeOptions.push(item.optionValueId)
        }
        changeOptions(product.activeOptions, product)
    } 

    return (
        <>
            <div className={`checkbox-option option-type`}>
                <div className={`weight-options__title`}>
                    <span>
                        {option.option.descriptions[0].name}
                    </span>
                </div>

                {
                    sortArray(option.values).map((item, i) =>
                        !!item.subtract &&
                        <a
                            onClick={() => addOption(item)}
                            >
                            <div key = {i} className="checkbox-option__item">
                                <label className="checkbox-option__label">
                                    <input 
                                        checked = {product.activeOptions.includes(item.optionValueId)}
                                        value={JSON.stringify(item)}
                                        type="checkbox" 
                                        className="checkbox-option__input"
                                    />
                                    <span className="checkbox-option__checkmark"></span>
                                    <span className="checkbox-option__name">
                                        {item.optionValue.descriptions[0].name} 
                                    </span>
                                </label>
                            </div>
                        </a>
                    )
                }
            </div>
        </>
    )
}


export default CheckboxOption