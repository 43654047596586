import { REVIEW_TYPES } from '../../types'
import ReviewFields from '../../../catalogs/Review/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  navItem: new ReviewFields().navBar,
  inputFilters: new ReviewFields().dataFilter,
  oneData: {},
  error: [],
  count: 0,
  isOpen: isMobile ? false : localStorage.getItem('reviewFilter') == 'true' ? true : false,
  pageNumber: {
    removed: localStorage.getItem('removedReviewPage') || 1,
    notRemoved: localStorage.getItem('notRemovedReviewPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedReviewPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedReviewPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    products: '',
    author: '',
    status: null,
    dates:  '',
  },
  dataFilter: {
    search: '',
    reply: null,
    date: null,
    verify: null,
    product: null,
    userName: null,
    reviewId: null,
    userRate: null
  },
  tabPanel: '1',
  checked: false,
  ids: []
};



const reviews = ( state = initialState, action = {}) => {

  switch (action.type) {

    case  REVIEW_TYPES.REVIEW_LIVE_DATA: {
      let data = state.data
      data.pop()
      data = [action.payload, ...data]
      return {
        ...state,
        data: data,
        count: state.count + 1
      }

    }

    case REVIEW_TYPES.REVIEW_PAG_F: {

        return {
          ...state,
          data: action.payload.data,
          count: action.payload.count
        }
    }

    case REVIEW_TYPES.REVIEW_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case REVIEW_TYPES.REVIEW_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case REVIEW_TYPES.REVIEW_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case REVIEW_TYPES.REVIEW_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case REVIEW_TYPES.REVIEW_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {
            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.reviewId) })
      
      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case REVIEW_TYPES.REVIEW_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case REVIEW_TYPES.REVIEW_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case REVIEW_TYPES.REVIEW_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload,
          ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
      }
    }


    case REVIEW_TYPES.REVIEW_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case REVIEW_TYPES.REVIEW_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new ReviewFields().navBar,
          error: []
      }
    }

    case REVIEW_TYPES.REVIEW_REQUIRED_VALIDATION_R: {
      state.navItem = new ReviewFields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case REVIEW_TYPES.REVIEW_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new ReviewFields().navBar
      }
    }

    case REVIEW_TYPES.NEW_REVIEW: {
      return { 
          ...state,
          oneData: new ReviewFields(),
          navItem: new ReviewFields().navBar,
          error: []
      }
    }

    case REVIEW_TYPES.REVIEW_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case REVIEW_TYPES.REVIEW_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case REVIEW_TYPES.REVIEW_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.reviewId !== id)
      }
    }

    case REVIEW_TYPES.REVIEW_FILTER_IS_OPEN: {
      localStorage.setItem('reviewFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case REVIEW_TYPES.REVIEW_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]:  {
          products: ''
        }
      }
    }

    case REVIEW_TYPES.REVIEW_DATA_FILTER_ALL_SEARCH_RESET: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { 
            products: '',
            author: '',
            status: null,
            dates:  '',
            dateTo: '',
            dateFrom: ''
          }),
          error: []
      }
    }
    
    default:
            return state;
  }
}
export {
    reviews
}