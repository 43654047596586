import React, { Component } from 'react';

class FilterSlider extends Component {
    constructor(props) {
        super();
        this.state = {
            min: null,
            max: null,
            minElement: null,
            maxElement: null,
            minRange: props.minRange || 500
        };
        this.onMouseUp = this.onMouseUp.bind(this);
    }

    componentDidMount() {
      const { minPrice, maxPrice } = this.props
      let min
      let max
      if (minPrice && maxPrice) {
        min = this.props.minValue || minPrice;
        max = this.props.maxValue || maxPrice;
      } else {
        min = this.props.minValue || this.props.min;
        max = this.props.maxValue || this.props.max;
      }
      this.state.minElement.value = min;
      this.state.maxElement.value = max;
      this.setState({min: min, max: max});
    }

    componentDidUpdate(prevProps) {
        const { minPrice, maxPrice } = this.props.minMaxValues
        if (prevProps.minMaxValues.maxPrice !== maxPrice || prevProps.minMaxValues.minPrice !== minPrice) {
            let min
            let max
            if (minPrice) {
                min = minPrice || 0;
                max = maxPrice;
            }
            this.state.minElement.value = min;
            this.state.maxElement.value = max;
            this.setState({min: min, max: max});
        }

        const { minCallCount, maxCallCount } = this.props.minMaxValues
        if (prevProps.minMaxValues.maxCallCount !== maxCallCount || prevProps.minMaxValues.minCallCount !== minCallCount) {
            let min
            let max
            min = minCallCount || 0;
            max = maxCallCount;
            this.state.minElement.value = min;
            this.state.maxElement.value = max;
            this.setState({min: min, max: max});
        }


        const { minCustomerCount, maxCustomerCount } = this.props.minMaxValues
        if (prevProps.minMaxValues.maxCustomerCount !== maxCustomerCount || prevProps.minMaxValues.minCustomerCount !== minCustomerCount) {
            let min
            let max
            min = minCustomerCount || 0;
            max = maxCustomerCount;
            this.state.minElement.value = min;
            this.state.maxElement.value = max;
            this.setState({min: min, max: max});
        }

        const { minOrderCount, maxOrderCount } = this.props.minMaxValues
        if (prevProps.minMaxValues.maxOrderCount !== maxOrderCount || prevProps.minMaxValues.minOrderCount !== minOrderCount) {
            let min
            let max
            min = minOrderCount || 0;
            max = maxOrderCount;
            this.state.minElement.value = min;
            this.state.maxElement.value = max;
            this.setState({min: min, max: max});
        }

        const { minDoneOrderCount, maxDoneOrderCount } = this.props.minMaxValues
        if (prevProps.minMaxValues.maxDoneOrderCount !== maxDoneOrderCount || prevProps.minMaxValues.minDoneOrderCount !== minDoneOrderCount) {
            let min
            let max
            min = minDoneOrderCount || 0;
            max = maxDoneOrderCount;
            this.state.minElement.value = min;
            this.state.maxElement.value = max;
            this.setState({min: min, max: max});
        }

        const { minOrderTotal, maxOrderTotal } = this.props.minMaxValues
        if (prevProps.minMaxValues.maxOrderTotal !== maxOrderTotal || prevProps.minMaxValues.minOrderTotal !== minOrderTotal) {
            let min
            let max
            min = minOrderTotal || 0;
            max = maxOrderTotal;
            this.state.minElement.value = min;
            this.state.maxElement.value = max;
            this.setState({min: min, max: max});
        }

    }
    

    onMouseUp()  {
        const { min, max } = this.state
        const { minPrice, maxPrice, changeSlider, minKey, maxKey } = this.props
        if (min != minPrice) changeSlider(min, minKey, max, maxKey)
        if (max != maxPrice) changeSlider(max, maxKey, min, minKey)
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('touchend', this.onMouseUp)
    }

  
    render() {
      const { minKey, maxKey } = this.props
      return (
            <div style={this.props.style} className={this.props.className + ' W(100%)'}>
                <div className="D(ib) C(#4e5b65)">
                    {this.state.min}
                </div>
                <div className="D(ib) Fl(end) C(#4e5b65)">
                    {this.state.max}
                </div>
                <div
                    style={this.props.style}
                    className={this.props.ClassName + ' range-slider Pos(r) Ta(c) H(35px)'}>
                    <input
                        onChange={
                            (ev)=>{
                                document.addEventListener('mouseup',this.onMouseUp);
                                document.addEventListener('touchend', this.onMouseUp)
                                if(ev.target.value < this.state.max - this.state.minRange) {
                                    this.setState(
                                        {min: parseInt(ev.target.value)},
                                        function() {
                                            this.props.onChange(this.state, maxKey);
                                          }
                                    );
                                } else {
                                    this.state.minElement.value = this.state.min;
                                    this.setState(
                                        {min: parseInt(this.state.min)},
                                        function() {
                                          this.props.onChange(this.state, minKey);
                                        }
                                    );
                                }
                            }
                        }
                        ref={(el)=>{this.state.minElement = el}}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step}
                        type="range"
                        style={{cursor: "grab"}}
                    />
                    <input
                        onChange={
                            (ev)=>{
                                document.addEventListener('mouseup',this.onMouseUp);
                                document.addEventListener('touchend', this.onMouseUp)
                                if(ev.target.value > this.state.min + this.state.minRange) {
                                    this.setState(
                                        {max: parseInt(ev.target.value)},
                                        function() {
                                          this.props.onChange(this.state);
                                        }
                                    );
                                }
                                else {
                                    this.state.maxElement.value = this.state.max;
                                    this.setState(
                                        {max: parseInt(this.state.max)},
                                        function() {
                                            this.props.onChange(this.state);
                                        }
                                    );
                                }
                            }
                        }
                        ref={(el)=>{this.state.maxElement = el}}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step}
                        type="range"
                        style={{cursor: "grab"}}
                    />
                    <div style={{
                            position: 'absolute',
                            boxSizing: 'border-box',
                            width: '100%',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            top: '7px'
                        }}>
                        <div style={
                            {
                                marginLeft: (this.state.min - this.props.min)/(this.props.max - this.props.min)*100 + '%',
                                width: (100-(this.state.min - this.props.min + this.props.max - this.state.max)/(this.props.max - this.props.min)*100) + '%', 
                                height: '4px',
                                backgroundColor: this.props.color || '#333333' 
                            }
                        }></div>
                    </div>
                </div>
            </div>
        );
    }
}



export default FilterSlider
