import { useState, useEffect } from 'react';
import config from '../../config/app';
import socketIOClient from 'socket.io-client/dist/socket.io.js';

const useLocationTracker = (token) => {
    const [location, setLocation] = useState(null);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (token) {
            const newSocket = socketIOClient(config['USER_API_SOCKET'], {
                path: process.env.REACT_APP_STAGE == 'development' ? '' : '/user_api_socket',
                query: `token=${token}`,
                reconnect: true 
            });
    
            setSocket(newSocket);

            // Function to fetch the previous location from local storage
            const prevLocationString = localStorage.getItem('prevLocation');
            const prevLocation = prevLocationString ? JSON.parse(prevLocationString) : null;

            const successHandler = (position) => {
                const { timestamp } = position;

                const { latitude, longitude, heading } = position.coords;
                const newLocation = { latitude, longitude, timestamp, heading };

                if (prevLocation) {

                    const bearing = calculateBearing(prevLocation.latitude, prevLocation.longitude, latitude, longitude);
                    newLocation.bearing = bearing;
                    const distance = calculateDistance(prevLocation.latitude, prevLocation.longitude, latitude, longitude);
                    const timeDifference = (position.timestamp - prevLocation.timestamp) / 1000; // Convert to seconds
                    newLocation.speed = distance / timeDifference

                } 

                newSocket.emit('location', newLocation);

                setLocation(newLocation);

                // Save the current location as the previous location in local storage after a timeout
                const intervalId = setInterval(() => {
                    localStorage.setItem('prevLocation', JSON.stringify(newLocation));
                }, 5000); // Save previous location after 5 seconds

                // Clear the timeout if the component unmounts or a new location is received
                return () => clearInterval(intervalId);

            };
    
            const errorHandler = (err) => {
                console.error('Error getting location:', err);
            };
    
            const options = {
                timeout: 5000, // 5 seconds timeout
                maximumAge: 10000, // accept only position, that are not older than 10 seconds
                enableHighAccuracy: true // high accuracy
            };
    
            const watchId = navigator.geolocation.watchPosition(successHandler, errorHandler, options);
    
            return () => {
                navigator.geolocation.clearWatch(watchId);
                newSocket.disconnect(); // Disconnect the socket when the component unmounts
            };
        }
    }, [token]);

    // Function to calculate bearing between two coordinates
    const calculateBearing = (lat1, lon1, lat2, lon2) => {
        const dLon = lon2 - lon1;
        const y = Math.sin(dLon) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        const bearing = (Math.atan2(y, x) * 180) / Math.PI;
        return (bearing + 360) % 360;
    };

    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // Earth radius in meters
        const φ1 = toRadians(lat1);
        const φ2 = toRadians(lat2);
        const Δφ = toRadians(lat2 - lat1);
        const Δλ = toRadians(lon2 - lon1);
    
        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        return R * c;
    }
    
    function toRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    return { location, socket };
};

export default useLocationTracker;