import { LANGUAGE_TYPES } from '../types'
import LanguageFields from '../../system/Languages/type'

const language = (state = [], action = {}) => {
  if (action.type === LANGUAGE_TYPES.GET_LANGUAGES) {
    return action.payload.reverse();
  } else {
    return state
  }
};


const initialState = {
  languages: [],
  languagesSelect: [],
  languagesSelectCount: 0,
  navItem: new LanguageFields().navBar,
  oneData: {},
  error: [],
  languagesCount: 0,
  pageNumber: {
    removed: localStorage.getItem('removedLanguagePage') || 1,
    notRemoved: localStorage.getItem('notRemovedLanguagePage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedLanguagePageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedLanguagePageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  dataFilter: {
    name: '',
    nameF: false,
    code: false,
    loacle: false,
    languageId: false,
    sortOrder: false
  },
  tabPanel: '1',
  checked: false,
  ids: []
};



const languages = ( state = initialState, action = {} ) => {

  switch (action.type) {

    case LANGUAGE_TYPES.PAG_L_F: {

        return {
          ...state,
          languages: action.payload.data.map((elem) => state.ids.includes(elem.languageId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.languageId)),
          languagesCount: action.payload.count
        }
    }

    case LANGUAGE_TYPES.FETCH_LANGUAGE_SEE_ALL_P: {
      return {
         ...state,
         loading: true
       }
    }

    case LANGUAGE_TYPES.FETCH_LANGUAGE_SEE_ALL_F: {
      return {
        ...state,
        loading: false,
        languagesSelect: [
          ...state.languagesSelect, 
          ...action.payload.data.map(({language, languageId}) => ({value: languageId, label: language}))
        ],
        languagesSelectCount: action.payload.count,
      }
    }

    case LANGUAGE_TYPES.FETCH_LANGUAGE_SEE_ALL_R: {
      return {
         ...state,
         loading: false
       }
    }

    case LANGUAGE_TYPES.LANGUAGE_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case LANGUAGE_TYPES.LANGUAGE_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case LANGUAGE_TYPES.LANGUAGE_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case LANGUAGE_TYPES.LANGUAGE_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case LANGUAGE_TYPES.LANGUAGE_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const languages = state.languages.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.languages[key]['isChecked'] = checked
                return elem
                
            }

        })

      languages.forEach(elem => { if (elem.isChecked)  ids.push(elem.languageId) })

      return {
          ...state,
          languages: languages,
          checked: !multiple ? languages.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case LANGUAGE_TYPES.BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case LANGUAGE_TYPES.BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case LANGUAGE_TYPES.LANGUAGE_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }

    // case LANGUAGE_TYPES.LANGUAGE_CREATE_F: {
    //   return { 
    //       ...state,
    //       // languages: [ action.payload.data,...state.languages ]
    //   }
    // }

    case LANGUAGE_TYPES.LANGUAGE_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case LANGUAGE_TYPES.LANGUAGE_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new LanguageFields().navBar,
          error: []
      }
    }

    case LANGUAGE_TYPES.LANGUAGE_REQUIRED_VALIDATION_R: {
      state.navItem = new LanguageFields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case LANGUAGE_TYPES.LANGUAGE_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new LanguageFields().navBar
      }
    }

    case LANGUAGE_TYPES.NEW_LANGUAGE: {
      return { 
          ...state,
          oneData: new LanguageFields(),
          navItem: new LanguageFields().navBar,
          error: []
      }
    }

    case LANGUAGE_TYPES.LANGUAGE_DATA_FILTER: {
      return { 
          ...state,
          // dataFilter: Object.assign({}, state.filter, { [action.payload['key']]: action.payload['value'] }),
          // dataFilter: { ...state.dataFilter, ...{ [action.payload['key']]: action.payload['value'] }  },
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case LANGUAGE_TYPES.LANGUAGE_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          languages: state.languages.filter(item => item.languageId !== id)
      }
    }
    default:
            return state;
  }
}
export {
  language,
  languages
}