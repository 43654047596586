const CUSTOMER_GROUP_PAG_F = 'CUSTOMER_GROUP_PAG_F';
const CUSTOMER_GROUP_PAG_R = 'CUSTOMER_GROUP_PAG_R';
const CUSTOMER_GROUP_SIZE_CHANGE = 'CUSTOMER_GROUP_SIZE_CHANGE'
const CUSTOMER_GROUP_PAGE_CHANGE = 'CUSTOMER_GROUP_PAGE_CHANGE'
const CUSTOMER_GROUP_FILETR_TABE_CHANGE = 'CUSTOMER_GROUP_FILETR_TABE_CHANGE'
const CUSTOMER_GROUP_UPDATE_F = 'CUSTOMER_GROUP_UPDATE_F'
const CUSTOMER_GROUP_UPDATE_R = 'CUSTOMER_GROUP_UPDATE_R'
const CUSTOMER_GROUP_CHECKBOX_CHANGE = 'CUSTOMER_GROUP_CHECKBOX_CHANGE'
const CUSTOMER_GROUP_BULK_ARCIVE_F = 'CUSTOMER_GROUP_BULK_ARCIVE_F'
const CUSTOMER_GROUP_BULK_ARCIVE_R = 'CUSTOMER_GROUP_BULK_ARCIVE_R'
const CUSTOMER_GROUP_BULK_DESTROY_F = 'CUSTOMER_GROUP_BULK_DESTROY_F'
const CUSTOMER_GROUP_BULK_DESTROY_R = 'CUSTOMER_GROUP_BULK_DESTROY_R'
const CUSTOMER_GROUP_TAB_PANEL_CHANGE = 'CUSTOMER_GROUP_TAB_PANEL_CHANGE'
const CUSTOMER_GROUP_CREATE_F = 'CUSTOMER_GROUP_CREATE_F'
const CUSTOMER_GROUP_CREATE_R = 'CUSTOMER_GROUP_CREATE_R'
const CUSTOMER_GROUP_GET_ONE_F = 'CUSTOMER_GROUP_GET_ONE_F'
const CUSTOMER_GROUP_GET_ONE_R = 'CUSTOMER_GROUP_GET_ONE_R'
const CUSTOMER_GROUP_REQUIRED_VALIDATION_F = 'CUSTOMER_GROUP_REQUIRED_VALIDATION_F'
const CUSTOMER_GROUP_REQUIRED_VALIDATION_R = 'CUSTOMER_GROUP_REQUIRED_VALIDATION_R'
const NEW_CUSTOMER_GROUP = 'NEW_CUSTOMER_GROUP'
const CUSTOMER_GROUP_DATA_FILTER = 'CUSTOMER_GROUP_DATA_FILTER'
const CUSTOMER_GROUP_DELETE_F = 'CUSTOMER_GROUP_DELETE_F'
const CUSTOMER_GROUP_FILTER_IS_OPEN = 'CUSTOMER_GROUP_FILTER_IS_OPEN'
const CUSTOMER_GROUP_DATA_FILTER_SEARCH = 'CUSTOMER_GROUP_DATA_FILTER_SEARCH'
const CUSTOMER_GROUP_SELECT_FILTER_TYPES = 'CUSTOMER_GROUP_SELECT_FILTER_TYPES'
const CUSTOMER_GROUP_LANGUAGE_PANEL_CHANGE = 'CUSTOMER_GROUP_LANGUAGE_PANEL_CHANGE'
const CUSTOMER_GROUP_GET_ORDER = 'CUSTOMER_GROUP_LANGUAGE_GET_ORDER'
const CUSTOMER_GROUP_DATA_FILTER_ALL_SEARCH_RESET = 'CUSTOMER_GROUP_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_CUSTOMER_GROUP_SEE_ALL_P = 'FETCH_CUSTOMER_GROUP_SEE_ALL_P'
const FETCH_CUSTOMER_GROUP_SEE_ALL_F = 'FETCH_CUSTOMER_GROUP_SEE_ALL_F'
const FETCH_CUSTOMER_GROUP_SEE_ALL_R = 'FETCH_CUSTOMER_GROUP_SEE_ALL_R'
const CUSTOMER_GROUP_FILTER_F = 'CUSTOMER_GROUP_FILTER_F'
const CUSTOMER_GROUP_FILTER_R = 'CUSTOMER_GROUP_FILTER_R'
const CUSTOMER_GROUP_CHOOSE_FILTER = 'CUSTOMER_GROUP_CHOOSE_FILTER'
const CUSTOMER_GROUP_REMOVE_FILTER = 'CUSTOMER_GROUP_REMOVE_FILTER'
const CUSTOMER_GROUP_CHOOSE_FILTER_VALUE = 'CUSTOMER_GROUP_CHOOSE_FILTER_VALUE'
const CUSTOMER_GROUP_IMPORT_CUSTOMER = 'CUSTOMER_GROUP_IMPORT_CUSTOMER'
const CUSTOMER_GROUP_CHOOSE_FILTER_DATE_VALUE = 'CUSTOMER_GROUP_CHOOSE_FILTER_DATE_VALUE'
const CUSTOMER_GROUP_CHOOSE_FILTER_CATEGORIES  = 'CUSTOMER_GROUP_CHOOSE_FILTER_CATEGORIES'
const CUSTOMER_GROUP_CHOOSE_FILTER_BUSINESS = 'CUSTOMER_GROUP_CHOOSE_FILTER_BUSINESS'

export {
    CUSTOMER_GROUP_PAG_R,
    CUSTOMER_GROUP_PAG_F,
    CUSTOMER_GROUP_SIZE_CHANGE,
    CUSTOMER_GROUP_PAGE_CHANGE,
    CUSTOMER_GROUP_FILETR_TABE_CHANGE,
    CUSTOMER_GROUP_UPDATE_F,
    CUSTOMER_GROUP_UPDATE_R,
    CUSTOMER_GROUP_CHECKBOX_CHANGE,
    CUSTOMER_GROUP_BULK_ARCIVE_F,
    CUSTOMER_GROUP_BULK_ARCIVE_R,
    CUSTOMER_GROUP_BULK_DESTROY_R,
    CUSTOMER_GROUP_BULK_DESTROY_F,
    CUSTOMER_GROUP_TAB_PANEL_CHANGE,
    CUSTOMER_GROUP_CREATE_F,
    CUSTOMER_GROUP_CREATE_R,
    CUSTOMER_GROUP_GET_ONE_F,
    CUSTOMER_GROUP_GET_ONE_R,
    CUSTOMER_GROUP_REQUIRED_VALIDATION_F,
    CUSTOMER_GROUP_REQUIRED_VALIDATION_R,
    NEW_CUSTOMER_GROUP,
    CUSTOMER_GROUP_DATA_FILTER,
    CUSTOMER_GROUP_DELETE_F,
    CUSTOMER_GROUP_FILTER_IS_OPEN,
    CUSTOMER_GROUP_DATA_FILTER_SEARCH,
    CUSTOMER_GROUP_SELECT_FILTER_TYPES,
    CUSTOMER_GROUP_LANGUAGE_PANEL_CHANGE,
    CUSTOMER_GROUP_GET_ORDER,
    CUSTOMER_GROUP_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_CUSTOMER_GROUP_SEE_ALL_P,
    FETCH_CUSTOMER_GROUP_SEE_ALL_F,
    FETCH_CUSTOMER_GROUP_SEE_ALL_R,
    CUSTOMER_GROUP_FILTER_F,
    CUSTOMER_GROUP_FILTER_R,
    CUSTOMER_GROUP_CHOOSE_FILTER,
    CUSTOMER_GROUP_REMOVE_FILTER,
    CUSTOMER_GROUP_CHOOSE_FILTER_VALUE,
    CUSTOMER_GROUP_IMPORT_CUSTOMER,
    CUSTOMER_GROUP_CHOOSE_FILTER_DATE_VALUE,
    CUSTOMER_GROUP_CHOOSE_FILTER_CATEGORIES,
    CUSTOMER_GROUP_CHOOSE_FILTER_BUSINESS
}