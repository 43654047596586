import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {accountActions} from '../../../redux-modules/actions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

class Login extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      login : '',
      password : '',
      show: false,
      errors: {}
    }
  }
  
  _changeInput(event, key) {
    this.setState({
      [key] : event.target.value
    }, () => {
      this.validate()
    })
  }


  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      const user = params.get('user')
      const password = params.get('password')
      this.props.login(user, password)
      .then(data => {
        if(data && data.token) {
          this.props.history.push('/sale/order')
        }
      })
    }
  }

  validate = () => {
    const { login, password } = this.state
    let formIsValid = true;
    const errors = {}
    if (!login) {
      formIsValid = false
      errors["login"] = "required!";
    } 

    if (!password) {
      formIsValid = false
      errors["password"] = "required!";
    }

    this.setState({errors: errors})
    return formIsValid;
  };

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this._login()
    }
  }
  
  _login() {
    const valid = this.validate()
    if (valid) {
      this.props.login(this.state.login, this.state.password)
      .then(data => {
        if(data && data.token) {
          this.props.history.push('/')
        }
      })
    }
  }

  setRegisterPage() {
    this.props.history.push('/register')
  }
  
  render() {
    const { show, errors } = this.state
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText >
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        type="text" 
                        placeholder="Username" 
                        value={this.state.login} 
                        onKeyPress={this.handleKeyPress.bind(this)} 
                        onChange={(event)=>this._changeInput(event, 'login')}
                      />
                    </InputGroup>
                    <div className={'mb-3'}>
                      <span className="required">{errors['login']}</span>
                    </div>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        type={show ? "text" : "password"} 
                        placeholder="Password" 
                        value={this.state.password}  
                        onKeyPress={this.handleKeyPress.bind(this)} 
                        onChange={(event)=>this._changeInput(event, 'password')}/>
                        <div className="eyes" onClick={() => this.setState({show: !show})}> 
                          <svg><use xlinkHref={!show ? '/assets/svg/sprite.svg#EyeHide': '/assets/svg/sprite.svg#EyeShow'} /></svg> 
                        </div>
                    </InputGroup>
                    <div className={'mb-4'}>
                      <span className="required">{errors['password']}</span>
                    </div>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4" onClick={()=>this._login()}>Login</Button>
                      </Col>
                    </Row>
                    <div className={'action'}>
                      <p 
                        className={'forgot'} 
                        onClick={() => this.props.history.push('/forgot_password')}
                        >Forgot Password
                      </p>
                    </div>
                    <div className={'footer'}>
                      <p className={'text'}>Don't have an account yet?</p>
                      <button
                        className={'btn'}
                        onClick={() => this.setRegisterPage()}
                      >
                        Create Account
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {
  
  }
};

const mapDispatchToProps = dispatch => {
  return {
    login : (login, password) => dispatch(accountActions.signIn(login, password))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
