import React, { Fragment, useEffect } from "react";
import useComponentVisible from './useComponentVisible'


/**
 * If callInside is true the setVisible function is called with false value
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Modal(props) {
    const { isComponentVisible } = useComponentVisible(true);

    useEffect(()=>{
        if (!isComponentVisible) {
            props.setVisible(false)
        }
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            clearAll()
            document.body.style.overflow = 'unset';
        }
    }, [isComponentVisible, props.show])


    let onClick = props.setVisible;
    if (props.callInside) onClick = () => { 
        props.setVisible(false)
    };

    function clearAll() {
        for (let i = setTimeout(() => {}, 0); i > 0; i--) {
          window.clearInterval(i);
          window.clearTimeout(i);
          if (window.cancelAnimationFrame) window.cancelAnimationFrame(i);
        }
    }

    return (
        <Fragment>
            {
                !!props.show &&
                <div
                    className="modal-box">
                    <div className="modal-box-center">
                        <div
                            // ref = {ref}
                            className="modal-box-content">
                            <div style={{cursor: 'pointer'}} className="modal-box-close"
                                    onClick={onClick}>
                                <svg className="modal-box-close-icon">
                                    <use xlinkHref="/assets/svg/sprite.svg#close"/>
                                </svg>
                            </div>

                            {props.children}

                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}
