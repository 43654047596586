const SMS_DOMAIN_PAG_F = 'SMS_DOMAIN_PAG_F';
const SMS_DOMAIN_PAG_R = 'SMS_DOMAIN_PAG_R';
const SMS_DOMAIN_SIZE_CHANGE = 'SMS_DOMAIN_SIZE_CHANGE'
const SMS_DOMAIN_PAGE_CHANGE = 'SMS_DOMAIN_PAGE_CHANGE'
const SMS_DOMAIN_FILETR_TABE_CHANGE = 'SMS_DOMAIN_FILETR_TABE_CHANGE'
const SMS_DOMAIN_CHECKBOX_CHANGE = 'SMS_DOMAIN_CHECKBOX_CHANGE'
const SMS_DOMAIN_BULK_ARCIVE_F = 'SMS_DOMAIN_BULK_ARCIVE_F'
const SMS_DOMAIN_BULK_ARCIVE_R = 'SMS_DOMAIN_BULK_ARCIVE_R'
const SMS_DOMAIN_BULK_DESTROY_F = 'SMS_DOMAIN_BULK_DESTROY_F'
const SMS_DOMAIN_BULK_DESTROY_R = 'SMS_DOMAIN_BULK_DESTROY_R'
const SMS_DOMAIN_TAB_PANEL_CHANGE = 'SMS_DOMAIN_TAB_PANEL_CHANGE'
const SMS_DOMAIN_DATA_FILTER = 'SMS_DOMAIN_DATA_FILTER'
const SMS_DOMAIN_DELETE_F = 'SMS_DOMAIN_DELETE_F'
const SMS_DOMAIN_FILTER_IS_OPEN = 'SMS_DOMAIN_FILTER_IS_OPEN'
const SMS_DOMAIN_DATA_FILTER_SEARCH = 'SMS_DOMAIN_DATA_FILTER_SEARCH'
const SMS_DOMAIN_SELECT_FILTER_TYPES = 'SMS_DOMAIN_SELECT_FILTER_TYPES'
const SMS_DOMAIN_DATA_FILTER_ALL_SEARCH_RESET = 'SMS_DOMAIN_DATA_FILTER_ALL_SEARCH_RESET'
const SMS_DOMAIN_GET_ONE_P = 'SMS_DOMAIN_GET_ONE_P'
const SMS_DOMAIN_GET_ONE_F = 'SMS_DOMAIN_GET_ONE_F'
const SMS_DOMAIN_GET_ONE_R = 'SMS_DOMAIN_GET_ONE_R'
const SMS_DOMAIN_DATA_MIN_MAX_F = 'SMS_DOMAIN_DATA_MIN_MAX_F'
const SMS_DOMAIN_CREATE_F = 'SMS_DOMAIN_CREATE_F'
const SMS_DOMAIN_CREATE_R = 'SMS_DOMAIN_CREATE_R'
const SMS_DOMAIN_UPDATE_F = 'SMS_DOMAIN_UPDATE_F'
const SMS_DOMAIN_UPDATE_R = 'SMS_DOMAIN_UPDATE_R'
const NEW_SMS_DOMAIN = 'NEW_SMS_DOMAIN'
const ADD_DOMAIN_NGINX_P = 'ADD_DOMAIN_NGINX_P'
const ADD_DOMAIN_NGINX_F = 'ADD_DOMAIN_NGINX_F'
const ADD_DOMAIN_NGINX_R = 'ADD_DOMAIN_NGINX_R'

export {
    SMS_DOMAIN_PAG_R,
    SMS_DOMAIN_PAG_F,
    SMS_DOMAIN_SIZE_CHANGE,
    SMS_DOMAIN_PAGE_CHANGE,
    SMS_DOMAIN_FILETR_TABE_CHANGE,
    SMS_DOMAIN_CHECKBOX_CHANGE,
    SMS_DOMAIN_BULK_ARCIVE_F,
    SMS_DOMAIN_BULK_ARCIVE_R,
    SMS_DOMAIN_BULK_DESTROY_R,
    SMS_DOMAIN_BULK_DESTROY_F,
    SMS_DOMAIN_TAB_PANEL_CHANGE,
    SMS_DOMAIN_DATA_FILTER,
    SMS_DOMAIN_DELETE_F,
    SMS_DOMAIN_FILTER_IS_OPEN,
    SMS_DOMAIN_DATA_FILTER_SEARCH,
    SMS_DOMAIN_SELECT_FILTER_TYPES,
    SMS_DOMAIN_DATA_FILTER_ALL_SEARCH_RESET,
    SMS_DOMAIN_GET_ONE_P,
    SMS_DOMAIN_GET_ONE_F,
    SMS_DOMAIN_GET_ONE_R,
    SMS_DOMAIN_DATA_MIN_MAX_F,
    SMS_DOMAIN_CREATE_F,
    SMS_DOMAIN_CREATE_R,
    SMS_DOMAIN_UPDATE_F,
    SMS_DOMAIN_UPDATE_R,
    NEW_SMS_DOMAIN,
    ADD_DOMAIN_NGINX_P,
    ADD_DOMAIN_NGINX_F,
    ADD_DOMAIN_NGINX_R
}