import  { PLAN_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import {Notification} from 'element-react'

 class BusinessActions {
  constructor(_props) {
    this.api = config['STORE']
    this.rest  = 'c/plan/'
    this.arcive = 'b/'
  }

    pagination = (jwt, params, page, count, query) => {
      return async dispatch => {
        try {
          const searchString = qs.stringify(query)
          const response = await axios.get(
          this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })
          response.data.data = response.data.data.map(item => ({...item, name: item.descriptions ? item.descriptions[0].name: ""}))
          dispatch({
            type: PLAN_TYPES.PLAN_PAG_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: PLAN_TYPES.PLAN_PAG_R,
            payload: e
          })

        }
      }
    }

    seeAll = (jwt, page, count, query, search) => {
      return async dispatch => {
        try {
          dispatch({
            type: PLAN_TYPES.FETCH_PLAN_SEE_ALL_P
          })
          const searchString = qs.stringify(query)
          const response = await axios.get(
          this.api + this.rest + `/r/` + page + '/' + count + '?' + searchString,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: PLAN_TYPES.FETCH_PLAN_SEE_ALL_F,
            payload: response.data,
            search: search
          })

        } catch (e) {

          dispatch({
            type: PLAN_TYPES.FETCH_PLAN_SEE_ALL_R,
            payload: e
          })

        }
      }
    }

    update = (jwt, body, Type, history, discard) => {
      return async dispatch => {
        try {
          await axios.put(
          this.api + this.rest + `/${body.planId}`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          Notification.warning({
            title: 'Updated',
            message: 'Business plan succesfuly updated '
          }, 20)
  
          if (discard) {
            history.push(`${discard}`)
          } else {
            history.push('/business/plan')
          }

          dispatch({
            type: PLAN_TYPES[Type + '_UPDATE_F'],
            payload: body
          })
            
        } catch (e) {

          dispatch({
              type: PLAN_TYPES[Type + '_UPDATE_R'],
              payload: e
          })

        }
      }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: PLAN_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: PLAN_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: PLAN_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: PLAN_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: PLAN_TYPES.PLAN_BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.pagination(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: PLAN_TYPES.PLAN_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }
      /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
      */
       bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
         return async dispatch => {
           try {
              await axios.post(
               this.api + this.rest + '/b/d',
               ids,
               {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
              }
             )
               dispatch({
                   type: PLAN_TYPES.PLANE_BULK_DESTROY_F,
                   payload: ids
               })
 
               dispatch(this.pagination(...otherDispatchParams))
               
           } catch (e) {
 
               dispatch({
                   type: PLAN_TYPES.PLANE_BULK_DESTROY_R,
                   payload: e
               })
 
             }
           }
       }

        create = (jwt, body, Type, history, discard) => {
        return async dispatch => {
            await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                Notification.success({
                  title: 'Created',
                  message: 'Business plain succesfuly created',
                }, 20)
        
                if (discard) {
                  history.push(`${discard}`)
                } else {
                  history.push('/business/plan')
                }
      
                dispatch({
                    type: PLAN_TYPES[Type + '_CREATE_F'],
                    payload: response
                })

              })
              .catch(e => {

                dispatch({
                      type: PLAN_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })

              })
            }
        }
}


export default new BusinessActions()