export default class {
    constructor () {
        this.deleted = false
        this.approval = 0
        this.name = ''
        this.sortOrder = 0
        this.descriptions = [
            {
                name: '',
                description: '',
                languageId: 1,
            }
        ],
        this.filters = [],
        this.customers = []
    }
}