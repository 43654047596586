import React from 'react';
import { useSelector } from 'react-redux'
import Chart from "react-apexcharts";
import {
    Card
} from 'reactstrap';
import './ProductChart.css';
import momentTz from 'moment-timezone';

function ProductChart(props) {

    const { statistics } = useSelector((state) => state.newOrders);
    const { statistics: businessStatistics } = useSelector((state) => state.business);

    const colorPalette = ['#00D8B6','#008FFB',  '#FEB019', '#FF4560', '#775DD0', '#fcf000']

    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone
    const genericFormattedDate = (date) => {
        return momentTz((date)).tz(timeZoneString).format('MMMM Do YYYY, h:mm:ss a')
    }

    const spark1 = {
        chart: {
            id: 'sparkline1',
            group: 'sparklines1',
            type: 'area',
            height: 160,
            sparkline: {
                enabled: true
            },
        },
        stroke: {
            width: 2,
            curve: 'straight'
        },
        fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
        },
        series: [{
            name: 'All Sales',
            data: statistics.data.map(d => d['total'] || 0)
        }],
        labels: statistics.data.map(d => genericFormattedDate(d.date)),
        yaxis: {
          min: 0,
          floating: true,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                show: false
            },
        },
        xaxis: {
            type: 'date'
        },
        colors: ['#008FFB'],
        title: {
            text: 'All Sales',
            offsetX: 5,
            style: {
                fontSize: '16px',
                cssClass: 'apexcharts-yaxis-title'
            }
        },
        subtitle: {
            text: '$' + statistics.data.reduce((n, {total}) => n + parseInt(total), 0),
            offsetX: 5,
            style: {
                fontSize: '14px',
                cssClass: 'apexcharts-yaxis-title'
            }
        }
    }

    const spark2 = {
        chart: {
            id: 'sparkline2',
            group: 'sparklines2',
            type: 'area',
            height: 160,
            sparkline: {
                enabled: true
            },
        },
        stroke: {
            width: 2,
            curve: 'straight'
        },
        fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
        },
        series: [{
            name: 'Done Sales',
            data: statistics.data.map(d => d['doneTotal'] || 0)
        }],
        labels: statistics.data.map(d => genericFormattedDate(d.date)),
        yaxis: {
            min: 0,
            floating: true,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                show: false
            },
        },
        xaxis: {
            type: 'date'
        },
        colors: ['#008FFB'],
        title: {
            text: 'Done Sales',
            offsetX: 5,
            style: {
                fontSize: '16px',
                cssClass: 'apexcharts-yaxis-title'
            }
        },
        subtitle: {
            text: '$' + statistics.data.reduce((n, {doneTotal}) => n + parseInt(doneTotal || 0), 0),
            offsetX: 5,
            style: {
                fontSize: '14px',
                cssClass: 'apexcharts-yaxis-title'
            }
        }
    }
      
    const spark3 = {
        chart: {
            id: 'sparkline3',
            group: 'sparklines3',
            type: 'area',
            height: 160,
            sparkline: {
                enabled: true
            },
        },
        stroke: {
            width: 2,
            curve: 'straight'
        },
        fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 100]
            }
        },
        series: [{
            name: 'Cancel Sales',
            data: statistics.data.map(d => d['cancelTotal'] || 0)
        }],
        labels: statistics.data.map(d => genericFormattedDate(d.date)),
        xaxis: {
            type: 'date'
        },
        yaxis: {
            min: 0,
            floating: true,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                show: false
            },
        },
        colors: ['#008FFB'],
        title: {
            text: 'Cancel Sales',
            offsetX: 5,
            style: {
                fontSize: '16px',
                cssClass: 'apexcharts-yaxis-title'
            }
        },
        subtitle: {
            text: '$' + statistics.data.reduce((n, {cancelTotal}) => n + parseInt(cancelTotal || 0), 0),
            offsetX: 5,
            style: {
                fontSize: '14px',
                cssClass: 'apexcharts-yaxis-title'
            }
        }
    }

    const optionDonut = {
        chart: {
            type: 'donut',
            width: '100%'
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '10%',
                },
                offsetY: 1,
            },
            stroke: {
                colors: undefined
            }
        },
        colors: colorPalette,
        title: {
            text: 'Business Sales',
            style: {
                fontSize: '16px'
            }
        },
        series: businessStatistics.map(({ordercount}) => parseInt(ordercount)),
        labels: businessStatistics.map(({name}) => name),
        legend: {
            position: 'left',
            offsetY: 50
        }
    }


    const optionsLine = {
        chart: {
            height: 320,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            stroke: {
                width: 2,
                curve: 'smooth'
            },
        },
        labels: statistics.data.map(d => genericFormattedDate(d.date)),
        colors: colorPalette,
        series: [
            {
                name: "Day Time",
                data: statistics.data.map(({nightTimeCount}) => parseInt(nightTimeCount))
            },
            {
                name: "Night Time",
                data: statistics.data.map(({dayTimeCount}) =>  parseInt(dayTimeCount))
            }
        ],
        title: {
            floating: false,
            text: 'Orders',
            align: 'left',
            style: {
                fontSize: '18px'
            }
        },
        subtitle: {
            text: statistics.data.reduce((n, {count}) => n + parseInt(count), 0),
            align: 'center',
            margin: 20,
            offsetY: 30,
            style: {
                color: '#222',
                fontSize: '24px',
            }
        },
        markers: {
            size: 0
        },
        grid: {
        
        },
        xaxis: {
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            tickAmount: 2,
            labels: {
                show: false
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            },
            min: 0,
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetY: -20,
            offsetX: -30
        }
    }


    return (
        <Card>
            <div className="statistics-wrapper">
                <div className="item-statistics">
                    <div className="box">
                        <div  id="spark">
                            <Chart
                                type={spark1.chart.type}
                                options={spark1}
                                series={spark1.series}
                            />
                        </div>
                    </div>
                </div>
                <div className="item-statistics">
                    <div className="box">
                        <div id="spark">
                            <Chart
                                type={spark2.chart.type}
                                options={spark2}
                                series={spark2.series}
                            />
                        </div>
                    </div>
                </div>
                <div className="item-statistics">
                    <div className="box">
                        <div id="spark">
                            <Chart
                                type={spark3.chart.type}
                                options={spark3}
                                series={spark3.series}
                            />
                        </div>
                    </div>
                </div>
                {
                    !!props.businessStatistics &&
                    <div className="item-statistics">
                        <div className="box">
                            <div id="">
                                <Chart
                                    type={optionDonut.chart.type}
                                    options={optionDonut}
                                    series={optionDonut.series}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="item-statistics">
                    <div className="box">
                        <div id="">
                            <Chart
                                type={optionsLine.chart.type}
                                options={optionsLine}
                                series={optionsLine.series}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ProductChart
