export default class {
    constructor () {
      this.name = ''
      this.deleted = false
      this.permission = ''
      this.permissions = []
      this.selectedPermissions = []
      this.userGroupId = null
      this.navBar = [
        { 
          name: 'General', 
          color: '#000000',
          count: 0,
          inputs: [
            {
              name: 'Name',
              field: 'name',
              tag: 'input',
              label: true,
              type: 'text',
              color: '',
              value: '',
              tab: '1',
              required: true,
              message: 'Oops! Fill in the Name.',
              min: null,
              max: 200,
            },
            {
              name: 'Permissions',
              field: 'permissions',
              label: true,
              tag: 'tag',
              color: '',
              value: '',
              tab: '1',
              required: true,
              message: 'Oops! Fill in the Permissions',
              min: null,
              max: null, 
            }
          ] 
        }
      ]
    }
  }