const GET_LANGUAGES = 'GET_LANGUAGES';
const PAG_L_F = 'PAG_L_F';
const PAG_L_R = 'PAG_L_R';
const LANGUAGE_SIZE_CHANGE = 'LANGUAGE_SIZE_CHANGE'
const LANGUAGE_PAGE_CHANGE = 'LANGUAGE_PAGE_CHANGE'
const LANGUAGE_FILETR_TABE_CHANGE = 'LANGUAGE_FILETR_TABE_CHANGE'
const LANGUAGE_UPDATE_F = 'LANGUAGE_UPDATE_F'
const LANGUAGE_UPDATE_R = 'LANGUAGE_UPDATE_R'
const LANGUAGE_CHECKBOX_CHANGE = 'LANGUAGE_CHECKBOX_CHANGE'
const BULK_ARCIVE_F = 'BULK_ARCIVE_F'
const BULK_ARCIVE_R = 'BULK_ARCIVE_R'
const BULK_DESTROY_F = 'BULK_DESTROY_F'
const BULK_DESTROY_R = 'BULK_DESTROY_R'
const LANGUAGE_TAB_PANEL_CHANGE = 'LANGUAGE_TAB_PANEL_CHANGE'
const LANGUAGE_LANGUAGE_PANEL_CHANGE = 'LANGUAGE_LANGUAGE_PANEL_CHANGE'
const LANGUAGE_CREATE_F = 'LANGUAGE_CREATE_F'
const LANGUAGE_CREATE_R = 'LANGUAGE_CREATE_R'
const LANGUAGE_GET_ONE_F = 'LANGUAGE_GET_ONE_F'
const LANGUAGE_GET_ONE_R = 'LANGUAGE_GET_ONE_R'
const LANGUAGE_REQUIRED_VALIDATION_F = 'LANGUAGE_REQUIRED_VALIDATION_F'
const LANGUAGE_REQUIRED_VALIDATION_R = 'LANGUAGE_REQUIRED_VALIDATION_R'
const NEW_LANGUAGE = 'NEW_LANGUAGE'
const LANGUAGE_DATA_FILTER = 'LANGUAGE_DATA_FILTER'
const LANGUAGE_DELETE_F = 'LANGUAGE_DELETE_F'
const LANGUAGE_DELETE_R = 'LANGUAGE_DELETE_R'
const FETCH_LANGUAGE_SEE_ALL_P = 'FETCH_LANGUAGE_SEE_ALL_P'
const FETCH_LANGUAGE_SEE_ALL_F = 'FETCH_LANGUAGE_SEE_ALL_F'
const FETCH_LANGUAGE_SEE_ALL_R = 'FETCH_LANGUAGE_SEE_ALL_R'

export {
    GET_LANGUAGES,
    PAG_L_F,
    PAG_L_R,
    LANGUAGE_SIZE_CHANGE,
    LANGUAGE_PAGE_CHANGE,
    LANGUAGE_FILETR_TABE_CHANGE,
    LANGUAGE_UPDATE_F,
    LANGUAGE_UPDATE_R,
    LANGUAGE_CHECKBOX_CHANGE,
    BULK_ARCIVE_F,
    BULK_ARCIVE_R,
    BULK_DESTROY_F,
    BULK_DESTROY_R,
    LANGUAGE_TAB_PANEL_CHANGE,
    LANGUAGE_LANGUAGE_PANEL_CHANGE,
    LANGUAGE_CREATE_F,
    LANGUAGE_CREATE_R,
    LANGUAGE_GET_ONE_F,
    LANGUAGE_GET_ONE_R,
    LANGUAGE_REQUIRED_VALIDATION_F,
    LANGUAGE_REQUIRED_VALIDATION_R,
    NEW_LANGUAGE,
    LANGUAGE_DATA_FILTER,
    LANGUAGE_DELETE_F,
    LANGUAGE_DELETE_R,
    FETCH_LANGUAGE_SEE_ALL_P,
    FETCH_LANGUAGE_SEE_ALL_F,
    FETCH_LANGUAGE_SEE_ALL_R
}