export default class {
    constructor() {
        this.source = ''
        this.parentId = null
        this.className = ''
        this.locale = ''
        this.top = true
        this.status = true
        this.deleted = false
        this.column = 0
        this.sortOrder = 0
        this.descriptions = [ ]
        this.categorySeoUrl = [ ]
        this.image = [ ]
        this.icon = []
        this.categoryFilter = [ ]
        this.categoryParents = [ ]
        this.categoryStore = []
        this.path = []
        this.stores = [ ]
    }
}
