import { BULK_SMS_TYPES } from '../../types'
import Fields from '../../../smsServie/sms/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
    data: [],
    sms: new Fields(),
    error: {},
    count: 0,
    pageNumber: {
        removed: localStorage.getItem('removedBulkSmsPage') || 1,
        notRemoved: localStorage.getItem('notRemovedBulkSmsPage') || 1
    },
    showEntries: {
        removed: localStorage.getItem('removedBulkSmsPageSize') || 10,
        notRemoved: localStorage.getItem('notRemovedBulkSmsPageSize') || 10
    },
    langTab : {
        languageId: 1,
        langTab: 0
    },
    filterTab: {
        filterTab: true,
        path: 'r/',
        removed: 'notRemoved'
    },
    initialFilter: {
        message: '',
        from: '',
        to: '',
        statusF: null,
        datesF:  '',
    },
    dataFilter: {
        search: '',
        message: false,
    },
    balance: null,
    minMaxValues: {},
    isOpen: isMobile ? false : localStorage.getItem('smsFilter') == 'true' ? true : false,
    tabPanel: '1',
    checked: false,
    ids: [],
    phonesSelect: [],
    phonesCount: 0,
    numberList: [],
    statistics: {
        data: [],
        count: 0,
        unique: 0,
        callCount: 0,
        uniqueCallCount: 0,
        deliveredPriceAvg: 0,
        deliveredPriceTotal: 0,
        priceAvg: 0,
        priceTotal: 0,
        stopCount: 0
    },
    countriesSelect: [],
    countriesCount: [],
    loading: false,
    sendSmsLoading: false,
    validationErrors: [],
    plans: [],
    plan: {}
};



const bulkSms = ( state = initialState, action = {}) => {

  switch (action.type) {

    case BULK_SMS_TYPES.BULK_SMS_GET_ONE_P: {
        return {
            ...state,
            loading: true
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_GET_ONE_F: {
        const sms =  new Fields()
        sms.store = action.payload.store
        if (action.payload.inbound) {
            sms.to = [{phone: action.payload.from}]
            sms.from = [action.payload.to]
            sms.provider = action.payload.carrier
            sms.inboundMessage = action.payload.message
            if (action.payload.store && action.payload.store.business) {
                sms.businessId = action.payload.store.business.businessId
                sms.business = action.payload.store.business
            }
        } else {
            sms.message = action.payload.message
            sms.to = [{phone: action.payload.to}]
            sms.from = [action.payload.from]
            sms.price = action.payload.price
        }
        
        if (action.payload.smsGroup) {
            sms.provider = action.payload.smsGroup.senderName
            if (action.payload.smsGroup.business) {
                sms.businessId = action.payload.smsGroup.business.businessId
                sms.business = action.payload.smsGroup.business
            }
        }

        return {
            ...state,
            error: {},
            sms: sms,
            loading: false
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_GET_ONE_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.response
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_SEND_P: {
        return {
            ...state,
            sendSmsLoading: true
        }
    }
    

    case BULK_SMS_TYPES.BULK_SMS_SEND_F: {
        return {
            ...state,
            error: {},
            sendSmsLoading: false
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_SEND_R: {
        return {
            ...state,
            sendSmsLoading: false,
            error: action.payload.response
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_PAG_F: {
        return {
            ...state,
            data: action.payload.data.map((elem) => state.ids.includes(elem.smsId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
            checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.smsId)),
            count: action.payload.count
        }
    }


    case BULK_SMS_TYPES.BULK_SMS_NEW: {
        return {
            ...state,
            sms: new Fields()
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_SIZE_CHANGE: {

        const showEntries = Object.assign(state.showEntries, action.payload)
      
        return {
            ...state,
            showEntries: showEntries
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_PAGE_CHANGE: {
      
        const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_BALANCE_F: {
      
        return {
            ...state,
            balance: action.payload,
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_BALANCE_R: {
      
        return {
            ...state,
            error: action.payload,
        }
    }

    case BULK_SMS_TYPES.GET_TELNYX_BALANCE_F: {
      
        return {
            ...state,
            balance: action.payload,
        }
    }
  
    case BULK_SMS_TYPES.GET_TELNYX_BALANCE_R: {
        
        return {
            ...state,
            error: action.payload,
            balance: null
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_FILETR_TABE_CHANGE: {
      
        return {
            ...state,
            filterTab: action.payload,
            checked: false
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_TAB_PANEL_CHANGE: {
      
        return {
            ...state,
            tabPanel: action.payload
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_CHECKBOX_CHANGE: {
        let ids = [...state.ids]
        const { checked, multiple, key } = action.payload
        const data = state.data.map(elem => {

            if (multiple) {

                elem['isChecked'] = checked
                return elem

            } else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.smsId) })

        return {
            ...state,
            data: data,
            checked: !multiple ? data.every(element => element.isChecked): checked,
            ids: ids
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_BULK_ARCIVE_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case BULK_SMS_TYPES.BULK_SMS_BULK_DESTROY_F: {
      
        return { 
            ...state,
            checked: false
        }

    }

    case BULK_SMS_TYPES.BULK_SMS_LANGUAGE_PANEL_CHANGE: {
        return { 
            ...state,
            langTab: action.payload
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_CREATE_R: {
        return { 
            ...state,
            error: action.payload.response
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_DATA_FILTER: {
        return { 
            ...state,
            dataFilter: { [action.payload['key']]: action.payload['value'] },
            error: []
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_DELETE_F: {
        const id = action.payload;
        return {
            ...state,
            data: state.data.filter(item => item.customer !== id)
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_FILTER_IS_OPEN: {
        localStorage.setItem('smsFilter', !state.isOpen)
        return {
            ...state,
            isOpen: !state.isOpen
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
            error: []
        }
    }
    
    case BULK_SMS_TYPES.BULK_SMS_DATA_FILTER_ALL_SEARCH_RESET: {
        const { key } = action.payload
        return {
            ...state,
            [key]: { 
                from: '',
                to: '',
                message: '',
                status: null,
                dates:  '',
            }
        }
    }

    case BULK_SMS_TYPES.FETCH_BULK_SMS_PHONES_P: {
        return {
            ...state,
            // loading: true
        }
    }

    case BULK_SMS_TYPES.FETCH_BULK_SMS_PHONES_F: {

      const newPhones = []

        action.payload.data.forEach(item => {
            newPhones.push({ 
                label: item.phone,
                value: item.phone
            })
        })

        let phonesSelect = action.search ? newPhones : [...state.phonesSelect, ...newPhones]
        phonesSelect = phonesSelect.filter((elem, index, self) =>
            index === self.findIndex((e) => (
                e.value === elem.value
            ))
        )

        return {
            ...state,
            // loading: false,
            phonesSelect: phonesSelect,
            phonesCount: action.payload.count
        }
    }

    case BULK_SMS_TYPES.FETCH_BULK_SMS_PHONES_R: {
        return {
            ...state,
            // loading: false,
            error: action.payload.response
        }
    }


    case BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_P: {
        return {
          ...state,
          loading: true
        }
    }
  
    case BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_F: {
        return {
          ...state,
          error: {},
          numberList: action.payload,
          loading: false
        }
    }
  
    case BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_R: {
        return {
          ...state,
          loading: false,
          error: action.payload.response
        }
    }

    case BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_P: {
        return {
            ...state,
            loading: true
        }
    }

    case BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_F: {
        return {
            ...state,
            error: {},
            phonesSelect: [
                { 
                    value: action.payload.phone, 
                    label: action.payload.phone 
                }, 
                ...state.phonesSelect
            ],
            loading: false
        }
    }

    case BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.response
        }
    }

    case BULK_SMS_TYPES.UPDATE_BULK_SMS: {
        const sms = action.payload
        return {
            ...state,
            data: state.data.map(item => item.smsId == sms.smsId ? sms : item )
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_STATISTICS_P: {
        return {
            ...state,
            loading: true
        }
    }
  
    case BULK_SMS_TYPES.BULK_SMS_STATISTICS_F: {
        return {
            ...state,
            statistics: action.payload
        }
    }
  
    case BULK_SMS_TYPES.BULK_SMS_STATISTICS_R: {
        return {
            ...state,
            error: action.payload.data
        }
    }

    case BULK_SMS_TYPES.FETCH_COUNTRIES_SEE_ALL_P: {
        return {
            ...state,
            loading: true
        }
    }
  
    case BULK_SMS_TYPES.FETCH_COUNTRIES_SEE_ALL_F: {
        const newCountries = []
        action.payload.data.forEach(country => {
            newCountries.push({ 
              value: country.country, 
              label: country.country,
            })
        })
  
        let countriesSelect = action.search ? newCountries : [...state.countriesSelect, ...newCountries]
        countriesSelect = countriesSelect.filter((elem, index, self) =>
          index === self.findIndex((e) => (
            e.value === elem.value
          ))
        )
  
        return {
            ...state,
            loading: false,
            countriesSelect: countriesSelect,
            countriesCount: action.payload.count
        }
    }
  
    case BULK_SMS_TYPES.FETCH_COUNTRIES_SEE_ALL_R: {
        return {
            ...state,
            loading: false
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_VALIDATE_P: {
        return {
            ...state,
            validationErrors: []
        }
    }


    case BULK_SMS_TYPES.BULK_SMS_VALIDATE_F: {
        return {
            ...state,
            validationErrors: []
        }
    }

    case BULK_SMS_TYPES.BULK_SMS_VALIDATE_R: {
        return {
            ...state,
            validationErrors: action.payload.data
        }
    }

    case BULK_SMS_TYPES.GET_SMS_PLAN_P: {
        return {
            ...state,
            loading: true
        }
    }


    case BULK_SMS_TYPES.GET_SMS_PLAN_F: {
        return {
            ...state,
            loading: false,
            plans: action.payload.data
        }
    }

    case BULK_SMS_TYPES.GET_SMS_PLAN_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.data
        }
    }

    case BULK_SMS_TYPES.CHECH_SMS_PLAN_P: {
        return {
            ...state,
            loading: true
        }
    }


    case BULK_SMS_TYPES.CHECH_SMS_PLAN_F: {
        return {
            ...state,
            loading: false,
            plan: action.payload.data
        }
    }

    case BULK_SMS_TYPES.CHECH_SMS_PLAN_R: {
        return {
            ...state,
            loading: false,
            error: action.payload.data
        }
    }

    case 'SMS_INBOUND': {
        return {
            ...state,
            error: {},
            data: [action.payload.content, ...state.data].slice(0, -1),
            count: state.count + 1
        }
    }


    default:
        return state;
    }
}


export {
  bulkSms
}

