import axios from 'axios';
import config from '../config/app';

export const getToken = async (account) => {
  try {
    const response = await axios.get(`${config['CALL_API']}token/generate`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.token}`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      },
      params: {
        _t: new Date().getTime()
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTelnyxToken = async (account) => {
  try {
    const response = await axios.get(`${config['CALL_API']}agents/telnyx/login`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.token}`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      },
      params: {
        _t: new Date().getTime()
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCall = async (params) => {
  try {
    const response = await axios.get(`${config['CALL_API']}calls/`, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const dial = async (account, params) => {
  try {
    const response = await axios.post(`${config['CALL_API']}actions/dial`, params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.token}`
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const dialUpdate = async (account, id, params) => {
  try {
    const response = await axios.put(`${config['CALL_API']}actions/dial/${id}`, params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.token}`
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const invite = async (account, params) => {
  try {
    const response = await axios.post(
      `${config['CALL_API']}actions/conferences/invite`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${account.token}`
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const transfer = async (account, params) => {
  try {
    const response = await axios.post(
      `${config['CALL_API']}actions/conferences/transfer`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${account.token}`
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const hangup = async (account, params) => {
  try {
    const response = await axios.post(
      `${config['CALL_API']}actions/conferences/hangup`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${account.token}`
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const mute = async (account, params) => {
  try {
    const response = await axios.post(
      `${config['CALL_API']}actions/conferences/mute`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${account.token}`
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const unmute = async (params) => {
  try {
    const response = await axios.post(
      `${config['CALL_API']}actions/conferences/unmute`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${account.token}`
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};