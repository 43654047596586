class GlobalActions {

  
    isOpen = (Type) => {
        return async dispatch => {
            dispatch({
                type: `${Type}_IS_OPEN`,
            }) 
        }
    }
    
    handleAllChecked = (checked, Type) => {

        return async dispatch => {
            dispatch({
                type: `${Type}_CHECKBOX_CHANGE`,
                payload:  checked
            }) 
        }
    }


    handleClickOutside = (value) => {

        return async dispatch => {
            
            dispatch({
                type: 'OUTSIDE_CLICK_CHANGE',
                payload: value
            }) 
        }
    }

    changeTabPanel = (tab, Type) => {

        return async dispatch => {
            dispatch({
                type: `${Type}_TAB_PANEL_CHANGE`,
                payload:  tab
            }) 
        }
    }

    changeLanguagePanel = (language, index, Type) => {
        return async dispatch => {
  
            dispatch({
                type: `${Type}_LANGUAGE_PANEL_CHANGE`,
                payload:  { languageId: language.languageId, langTab: index }
            }) 

        }
    }

    onCurrentChange(pageNumber, Type) {

        return async dispatch => {
  
            dispatch({
                type: `${Type}_PAGE_CHANGE`,
                payload:  pageNumber
            }) 

        }
    }

    onSizeChange = (showEntries, Type) => {
        return async dispatch => {
  
            dispatch({
                type: `${Type}_SIZE_CHANGE`,
                payload:  showEntries
            })  
        }
    }

    changeFilterTabe = (filterTab, Type) => {
        return async dispatch => {
  
            dispatch({
                type: `${Type}_FILETR_TABE_CHANGE`,
                payload:  filterTab
            }) 
        }
    }

    createNew (Type) {
        return async dispatch => {
      
            dispatch({
                type: `NEW_${Type}`
            })

        }
    }

    filterData (key, value , Type) {
            
        return async dispatch => {
      
            dispatch({
                type: `${Type}_DATA_FILTER`,
                payload: {
                    key: key,
                    value: value
                }
            })

        }
    }

    filterDataSearch (key, value , Type) {

        return async dispatch => {
      
            dispatch({
                type: `${Type}_DATA_FILTER_SEARCH`,
                payload: {
                    key: key,
                    value: value
                }
            })

 
        }
    }

    resetFilterDataSearch (key, Type) {
        return async dispatch => {
      
            dispatch({
                type: `${Type}_DATA_FILTER_SEARCH_RESET`,
                payload: {
                    key
                }
            })

        }
    }


    resetFilterDataSearchAll (key, Type) {
        return async dispatch => {
      
            dispatch({
                type: `${Type}_DATA_FILTER_ALL_SEARCH_RESET`,
                payload: {
                    key
                }
            })

        }
    }
    

    requiredValidation = (data, Type, Tab, _min = null, _max = null) => {
        return async dispatch => {
    
            let errorMsg = {
                errorMsg: [], 
                tab: Tab
            };

            let successMsg = []
    
            data.forEach( (elem, _index) => {

                if ( elem.required && elem.value.length < 1 ) {
    
                    elem.valid = false
                    let error = {
                        msg: elem.message,
                        name: elem.field,
                        tab: elem.tab,
                        status: false
                    }
    
    
                    errorMsg.errorMsg.push(error)
    
                } else if (elem.min || elem.max) {
                    const errorCharacter = this.lengthValidator(elem.value, elem.min, elem.max, elem.field)
                    if (!errorCharacter.status)  {
                        let errorCharacterValidate = {
                            msg: errorCharacter.msg,
                            name: errorCharacter.name,
                            tab: elem.tab,
                            status: false
                        }
                        errorMsg.errorMsg.push(errorCharacterValidate)
                    } 
                    else if (elem['validation'] === 'string') {

                        const errorCharacter = this.CustomRx(elem.value, false, false, true)

                        if (!errorCharacter.status)  {
    
                            const errorCharacterValidate = {
                                msg: errorCharacter.msg,
                                name: elem.field,
                                tab: elem.tab,
                                status: false
                            }
    
                            errorMsg.errorMsg.push(errorCharacterValidate)
                        }
                        
                    }
                    
                } else if (elem['validation'] === 'email') {

                    const errorCharacter = this.Email(elem.value)

                    if (!errorCharacter.status)  {

                        const errorCharacterValidate = {
                            msg: errorCharacter.msg,
                            name: elem.field,
                            tab: elem.tab,
                            status: false
                        }

                        errorMsg.errorMsg.push(errorCharacterValidate)
                    }
                    
                } else {

                    elem.valid = true
                    let success = {
                        msg: 'success',
                        status: false
                    }
    
                    successMsg.push(success)
                }

            } )
    
            if (errorMsg.errorMsg['length'] > 0) {
    
                dispatch({
                    type: `${Type}_REQUIRED_VALIDATION_R`,
                    payload: errorMsg
                })
    
            } else {
    
                dispatch({
                    type: `${Type}_REQUIRED_VALIDATION_F`,
                    payload: successMsg
                })
    
            }
    
            return errorMsg['errorMsg']
            
        }
    }
    
    Numeric = function (data, min = null, max = null) {
        let errorMsg = {};
        if (!data) {
            data = ''
        }
    
        if (isNaN(data)) {
            errorMsg.msg = "Please type Number only"
            errorMsg.status = false
        }
        else if (min || max) {
            errorMsg = this.lengthValidator(data, min, max)
        }
        else {
            errorMsg.msg = "Correct Value"
            errorMsg.status = true
        }
        return errorMsg;
    }

    Letter = function (data = '', min = null, max = null) {
        let errorMsg = {};
        let letters = /^[A-Za-z]+$/;
        if (!data) {
            data = ''
        }
        if (data.match(letters)) {
            errorMsg.msg = "Correct Value"
            errorMsg.status = true
    
            if (min || max) {
                errorMsg = this.lengthValidator(data, min, max)
            }
        }
        else {
            errorMsg.msg = "Please type letters only"
            errorMsg.status = false
        }
    
    
        return errorMsg;
    }
    
     AlphaNumeric = function (data = '', min = null, max = null) {
        let errorMsg = {};
        let letterNumber = /^[0-9a-zA-Z]+$/;
        if (!data) {
            data = ''
        }
        console.warn("income AlphaNumeric nx", data, min, max)
    
        if (data.match(letterNumber)) {
            errorMsg.msg = "Correct Value"
            errorMsg.status = true
    
            if (min || max) {
                errorMsg = this.lengthValidator(data, min, max)
            }
        }
        else {
            errorMsg.msg = "Please type letters and number only "
            errorMsg.status = false
        }
    
    
        return errorMsg;
    }
    
     Email = function (data) {
        let errorMsg = {};
        if (!data) {
            data = ''
        }
        let email = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    
        if (data.match(email)) {
            errorMsg.msg = "Correct Value"
            errorMsg.status = true
        }
        else {
            errorMsg.msg = "Please Enter Valid Email Address"
            errorMsg.status = false
        }
        return errorMsg;
    }
    
    
    CustomRx = function (data = '', spChar = null, capChar = null, strChar = null, min = null, max = null) {
    
        let errorMsg = {};
        let specialChar = /(?=.*?[#?!@$%^&*-])/;
        let capitalChar = /^(?=.*?[A-Z])/
        let stringChar = /[a-zA-Z]/g
        if (spChar) {
            if (!data.match(specialChar)) {
                errorMsg.msg = "Please Enter at least one Special character"
                errorMsg.status = false
                return errorMsg
            }
        }
        if (capChar) {
            if (!data.match(capitalChar)) {
                errorMsg.msg = "Please Enter at least one capital letter"
                errorMsg.status = false
                return errorMsg
            }
        }
        if (strChar) {
            if (!data.match(stringChar)) {
                errorMsg.msg = "Please Enter at least one letter"
                errorMsg.status = false
            } else {
                errorMsg.msg = "Correct Value"
                errorMsg.status = true
            }
        }
        if (min || max) {
            errorMsg = this.lengthValidator(data, min, max)
        }
        return errorMsg;
    }
    
    lengthValidator = (data = null, min = null, max = null, name = null) => {
    
        let errorMsg = {}
    
        if (min && data.length < min) {
            errorMsg.status = false;
            errorMsg.msg = 'Minimum length should be ' + min + ' ' + 'characters.'
            errorMsg.name = name
            return errorMsg;
        }
        else if (max && data.length > max) {
            errorMsg.status = false;
            errorMsg.msg = 'Maximum length should be ' + max + ' ' + 'characters.'
            errorMsg.name = name
            return errorMsg;
        }
    
        else {
            errorMsg.status = true;
            errorMsg.msg = "Correct"
        }
        return errorMsg
    }
    
  
}
  
  
  
  
  export default new GlobalActions()