export default class {
    constructor () {
        this.storeId = ''
        this.telephone = ''
        this.lastname = ''
        this.email = ''
        this.password = ''
        this.customerGroups = []
        this.deleted = false
        this.status = true
        this.verified = true
        this.newsletter = true
        this.store = {}
    }
}