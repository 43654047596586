import config from '../../../config/app'
import {Service} from "./Service";

const all = "f/r";
const allR = "f/d";
const rest = "f";
const pagNotRem = "f/r";
const pagRem = "f/d";
const bulkDel = 'f/b/a';
const bulkRec = 'f/b/r'
const bulkDes = 'f/b/d'

const franchiseService = new Service(config["FRANCHISE"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);

export {
    franchiseService
};