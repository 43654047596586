const PROMOTION_PAG_F = 'PROMOTION_PAG_L_F';
const PROMOTION_PAG_R = 'PROMOTION_PAG_L_R';
const PROMOTION_SIZE_CHANGE = 'PROMOTION_SIZE_CHANGE'
const PROMOTION_PAGE_CHANGE = 'PROMOTION_PAGE_CHANGE'
const PROMOTION_FILETR_TABE_CHANGE = 'PROMOTION_FILETR_TABE_CHANGE'
const PROMOTION_UPDATE_F = 'PROMOTION_UPDATE_F'
const PROMOTION_UPDATE_R = 'PROMOTION_UPDATE_R'
const PROMOTION_CHECKBOX_CHANGE = 'PROMOTION_CHECKBOX_CHANGE'
const PROMOTION_BULK_ARCIVE_F = 'PROMOTION_BULK_ARCIVE_F'
const PROMOTION_BULK_ARCIVE_R = 'PROMOTION_BULK_ARCIVE_R'
const PROMOTION_BULK_DESTROY_F = 'PROMOTION_BULK_DESTROY_F'
const PROMOTION_BULK_DESTROY_R = 'PROMOTION_BULK_DESTROY_R'
const PROMOTION_TAB_PANEL_CHANGE = 'PROMOTION_TAB_PANEL_CHANGE'
const PROMOTION_CREATE_F = 'PROMOTION_CREATE_F'
const PROMOTION_CREATE_R = 'PROMOTION_CREATE_R'
const PROMOTION_GET_ONE_F = 'PROMOTION_GET_ONE_F'
const PROMOTION_GET_ONE_R = 'PROMOTION_GET_ONE_R'
const PROMOTION_REQUIRED_VALIDATION_F = 'PROMOTION_REQUIRED_VALIDATION_F'
const PROMOTION_REQUIRED_VALIDATION_R = 'PROMOTION_REQUIRED_VALIDATION_R'
const NEW_PROMOTION = 'NEW_PROMOTION'
const PROMOTION_DATA_FILTER = 'PROMOTION_DATA_FILTER'
const PROMOTION_DELETE_F = 'PROMOTION_DELETE_F'
const PROMOTION_DELETE_R = 'PROMOTION_DELETE_R'
const PROMOTION_LIVE_DATA = 'PROMOTION_LIVE_DATA'
const PROMOTION_DATA_FILTER_SEARCH = 'PROMOTION_DATA_FILTER_SEARCH'
const PROMOTION_FILTER_IS_OPEN = 'PROMOTION_FILTER_IS_OPEN'
const PROMOTION_LANGUAGE_PANEL_CHANGE = 'PROMOTION_LANGUAGE_PANEL_CHANGE'
const PROMOTION_DATA_FILTER_SEARCH_RESET = 'PROMOTION_DATA_FILTER_SEARCH_RESET'
const PROMOTION_DATA_FILTER_ALL_SEARCH_RESET = 'PROMOTION_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_PROMOTION_SEE_ALL_P = 'FETCH_PROMOTION_SEE_ALL_P'
const FETCH_PROMOTION_SEE_ALL_F = 'FETCH_PROMOTION_SEE_ALL_F'
const FETCH_PROMOTION_SEE_ALL_R = 'FETCH_PROMOTION_SEE_ALL_R'

export {
    PROMOTION_PAG_F,
    PROMOTION_PAG_R,
    PROMOTION_SIZE_CHANGE,
    PROMOTION_PAGE_CHANGE,
    PROMOTION_FILETR_TABE_CHANGE,
    PROMOTION_UPDATE_F,
    PROMOTION_UPDATE_R,
    PROMOTION_CHECKBOX_CHANGE,
    PROMOTION_BULK_ARCIVE_F,
    PROMOTION_BULK_ARCIVE_R,
    PROMOTION_BULK_DESTROY_F,
    PROMOTION_BULK_DESTROY_R,
    PROMOTION_TAB_PANEL_CHANGE,
    PROMOTION_CREATE_F,
    PROMOTION_CREATE_R,
    PROMOTION_GET_ONE_F,
    PROMOTION_GET_ONE_R,
    PROMOTION_REQUIRED_VALIDATION_F,
    PROMOTION_REQUIRED_VALIDATION_R,
    NEW_PROMOTION,
    PROMOTION_DATA_FILTER,
    PROMOTION_DELETE_F,
    PROMOTION_DELETE_R,
    PROMOTION_LIVE_DATA,
    PROMOTION_DATA_FILTER_SEARCH,
    PROMOTION_FILTER_IS_OPEN,
    PROMOTION_LANGUAGE_PANEL_CHANGE,
    PROMOTION_DATA_FILTER_SEARCH_RESET,
    PROMOTION_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_PROMOTION_SEE_ALL_P,
    FETCH_PROMOTION_SEE_ALL_F,
    FETCH_PROMOTION_SEE_ALL_R,
}