import config from '../../../config/app'
import {Service} from "./Service";

const all = "c/a/ag/r";
const allR = "c/a/ag/d";
const rest = "c/a/ag";
const pagNotRem = "c/a/ag/r";
const pagRem= "c/a/ag/d";
const bulkDel = 'c/a/ag/b/a';
const bulkRec = 'c/a/ag/b/r'
const bulkDes = 'c/a/ag/b/d'

const attributeGroupService = new Service(config["ATTRIBUTE"], all, allR, rest, pagNotRem, pagRem, bulkDel, bulkRec, bulkDes);
export {attributeGroupService};