import  { 
    NOTIFICATION_TYPES
 } from '../types'
 import config from '../../../config/app'
 import axios from 'axios';


class NotificationActions {

        constructor(_props) {
            this.api = config['SMS_API']
            this.rest  = 'notification'
        }

        fetchNotifications(page, count, seeMore) {
            
            return async (dispatch) => {
                
                try {
                    const rest = `/l?offset=${page}&limit=${count}`

                    dispatch({
                        type: NOTIFICATION_TYPES.FETCH_NOTIFICATIONS_P
                    })

                    const { data } = await axios.create( {
                        baseURL: config.NOTIFICATION,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache', // Disables caching at the HTTP level
                            'Pragma': 'no-cache'
                        }
                    } )
                    .get(`${this.rest}${rest}`, {
                        params: {
                            _t: new Date().getTime() // Adds a timestamp to the request URL
                        }
                    });

                    dispatch( {
                        type: NOTIFICATION_TYPES.FETCH_NOTIFICATIONS_F,
                        payload: data,
                        seeMore: seeMore
                    } )

                } catch (err) {
                    dispatch({
                        type: NOTIFICATION_TYPES.FETCH_NOTIFICATIONS_R,
                        payload: err.response
                    })
                }
            }
        }

        destroy (id)  {
            return async (dispatch) => {

                try {

                    dispatch({
                        type: NOTIFICATION_TYPES.DESTROY_NOTIFICATION_P
                    });

                    if (typeof localStorage !== "undefined") {
                        const { data } = await axios.create( {
                            baseURL: config.NOTIFICATION,
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                                'Content-Type': 'application/json'
                            }
                        } )
                        .delete(`${this.rest}/${id}`);

                        dispatch( {
                            type: NOTIFICATION_TYPES.DESTROY_NOTIFICATION_F,
                            payload: data
                        } )
                    }

                } catch (err) {
                    dispatch({
                        type: NOTIFICATION_TYPES.DESTROY_NOTIFICATION_R,
                        payload: err.response
                    })
                }

            }
        }

        update(id)  {
            return async (dispatch) => {

                try {

                    dispatch({
                        type: NOTIFICATION_TYPES.UPDATE_NOTIFICATION_P
                    });

                    if (typeof localStorage !== "undefined") {
                        const { data } = await axios.create( {
                            baseURL: config.NOTIFICATION,
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                                'Content-Type': 'application/json'
                            }
                        } )
                        .put(`${this.rest}/${id}`);

                        dispatch( {
                            type: NOTIFICATION_TYPES.UPDATE_NOTIFICATION_F,
                            payload: data
                        } )
                    }

                } catch (err) {
                    dispatch({
                        type: NOTIFICATION_TYPES.UPDATE_NOTIFICATION_R,
                        payload: err.response
                    })
                }

            }
        }

        allRead()  {
            return async (dispatch) => {

                try {

                    dispatch({
                        type: NOTIFICATION_TYPES.ALL_READ_NOTIFICATION_P
                    });

                    if (typeof localStorage !== "undefined") {
                        const { data } = await axios.create( {
                            baseURL: config.NOTIFICATION,
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem( 'token' )}`,
                                'Content-Type': 'application/json'
                            }
                        } )
                        .put(`${this.rest}/r/a`);

                        dispatch( {
                            type: NOTIFICATION_TYPES.ALL_READ_NOTIFICATION_F,
                            payload: data
                        } )
                    }

                } catch (err) {
                    dispatch({
                        type: NOTIFICATION_TYPES.ALL_READ_NOTIFICATION_R,
                        payload: err.response
                    })
                }

            }
        }

        addNotification(data) {
            return {
                type: NOTIFICATION_TYPES.ADD_NOTIFICATION,
                payload: data
            }
        }

        
    }
  
  
  
  
  export default new NotificationActions()