export default class {
    constructor() {
        this.languageId = 1
        this.storeId = 0
        this.name = ''
        this.description = ''
        this.metaTitle = ''
        this.metaKeyword = ''
        this.metaDescription = ''
    }
}
