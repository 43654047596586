import React from 'react';
import './Checkbox.css';

function Checkbox (props) {
    const { label, value, handleCheck } = props
    return (
        <form>
            {/* <p className="title">{label}</p> */}
            <div className="new-checkbox">
                <label style={{cursor: 'pointer'}}>
                    <input
                        style={{cursor: 'pointer'}}
                        type= {'checkbox'}
                        checked={value}
                        onChange={handleCheck}
                    />
                    {
                        label
                    }
                </label>
            </div>
        </form>
    )
}
  

export default Checkbox