const PLAN_PAG_F = 'PLAN_PAG_L_F';
const PLAN_PAG_R = 'PLAN_PAG_L_R';
const PLAN_SIZE_CHANGE = 'PLAN_SIZE_CHANGE'
const PLAN_PAGE_CHANGE = 'PLAN_PAGE_CHANGE'
const PLAN_FILETR_TABE_CHANGE = 'PLAN_FILETR_TABE_CHANGE'
const PLAN_UPDATE_F = 'PLAN_UPDATE_F'
const PLAN_UPDATE_R = 'PLAN_UPDATE_R'
const PLAN_CHECKBOX_CHANGE = 'PLAN_CHECKBOX_CHANGE'
const PLAN_BULK_ARCIVE_F = 'PLAN_BULK_ARCIVE_F'
const PLAN_BULK_ARCIVE_R = 'PLAN_BULK_ARCIVE_R'
const PLAN_BULK_DESTROY_F = 'PLAN_BULK_DESTROY_F'
const PLAN_BULK_DESTROY_R = 'PLAN_BULK_DESTROY_R'
const PLAN_TAB_PANEL_CHANGE = 'PLAN_TAB_PANEL_CHANGE'
const PLAN_CREATE_F = 'PLAN_CREATE_F'
const PLAN_CREATE_R = 'PLAN_CREATE_R'
const PLAN_GET_ONE_F = 'PLAN_GET_ONE_F'
const PLAN_GET_ONE_R = 'PLAN_GET_ONE_R'
const PLAN_REQUIRED_VALIDATION_F = 'PLAN_REQUIRED_VALIDATION_F'
const PLAN_REQUIRED_VALIDATION_R = 'PLAN_REQUIRED_VALIDATION_R'
const NEW_PLAN = 'NEW_PLAN'
const PLAN_DATA_FILTER = 'PLAN_DATA_FILTER'
const PLAN_DELETE_F = 'PLAN_DELETE_F'
const PLAN_DELETE_R = 'PLAN_DELETE_R'
const PLAN_LIVE_DATA = 'PLAN_LIVE_DATA'
const PLAN_DATA_FILTER_SEARCH = 'PLAN_DATA_FILTER_SEARCH'
const PLAN_FILTER_IS_OPEN = 'PLAN_FILTER_IS_OPEN'
const PLAN_LANGUAGE_PANEL_CHANGE = 'PLAN_LANGUAGE_PANEL_CHANGE'
const PLAN_DATA_FILTER_SEARCH_RESET = 'PLAN_DATA_FILTER_SEARCH_RESET'
const PLAN_DATA_FILTER_ALL_SEARCH_RESET = 'PLAN_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_PLAN_SEE_ALL_P = 'FETCH_PLAN_SEE_ALL_P'
const FETCH_PLAN_SEE_ALL_F = 'FETCH_PLAN_SEE_ALL_F'
const FETCH_PLAN_SEE_ALL_R = 'FETCH_PLAN_SEE_ALL_R'

export {
    PLAN_PAG_F,
    PLAN_PAG_R,
    PLAN_SIZE_CHANGE,
    PLAN_PAGE_CHANGE,
    PLAN_FILETR_TABE_CHANGE,
    PLAN_UPDATE_F,
    PLAN_UPDATE_R,
    PLAN_CHECKBOX_CHANGE,
    PLAN_BULK_ARCIVE_F,
    PLAN_BULK_ARCIVE_R,
    PLAN_BULK_DESTROY_F,
    PLAN_BULK_DESTROY_R,
    PLAN_TAB_PANEL_CHANGE,
    PLAN_CREATE_F,
    PLAN_CREATE_R,
    PLAN_GET_ONE_F,
    PLAN_GET_ONE_R,
    PLAN_REQUIRED_VALIDATION_F,
    PLAN_REQUIRED_VALIDATION_R,
    NEW_PLAN,
    PLAN_DATA_FILTER,
    PLAN_DELETE_F,
    PLAN_DELETE_R,
    PLAN_LIVE_DATA,
    PLAN_DATA_FILTER_SEARCH,
    PLAN_FILTER_IS_OPEN,
    PLAN_LANGUAGE_PANEL_CHANGE,
    PLAN_DATA_FILTER_SEARCH_RESET,
    PLAN_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_PLAN_SEE_ALL_P,
    FETCH_PLAN_SEE_ALL_F,
    FETCH_PLAN_SEE_ALL_R
}