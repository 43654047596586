const FETCH_NOTIFICATIONS_P = 'FETCH_NOTIFICATIONS_P'
const FETCH_NOTIFICATIONS_F = 'FETCH_NOTIFICATIONS_F'
const FETCH_NOTIFICATIONS_R = 'FETCH_NOTIFICATIONS_R'
const DESTROY_NOTIFICATION_P = 'DESTROY_NOTIFICATION_P'
const DESTROY_NOTIFICATION_F = 'DESTROY_NOTIFICATION_F'
const DESTROY_NOTIFICATION_R = 'DESTROY_NOTIFICATION_R'
const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
const UPDATE_NOTIFICATION_P = 'UPDATE_NOTIFICATION_P'
const UPDATE_NOTIFICATION_F = 'UPDATE_NOTIFICATION_F'
const UPDATE_NOTIFICATION_R = 'UPDATE_NOTIFICATION_R'
const ALL_READ_NOTIFICATION_P = 'ALL_READ_NOTIFICATION_P'
const ALL_READ_NOTIFICATION_F = 'ALL_READ_NOTIFICATION_F'
const ALL_READ_NOTIFICATION_R = 'ALL_READ_NOTIFICATION_R'


export {
    FETCH_NOTIFICATIONS_P,
    FETCH_NOTIFICATIONS_F,
    FETCH_NOTIFICATIONS_R,
    DESTROY_NOTIFICATION_P,
    DESTROY_NOTIFICATION_F,
    DESTROY_NOTIFICATION_R,
    ADD_NOTIFICATION,
    UPDATE_NOTIFICATION_P,
    UPDATE_NOTIFICATION_F,
    UPDATE_NOTIFICATION_R,
    ALL_READ_NOTIFICATION_P,
    ALL_READ_NOTIFICATION_F,
    ALL_READ_NOTIFICATION_R
}