import  { LANGUAGE_TYPES } from '../types'
import config from '../../../config/app'
import axios from 'axios'
import qs from 'qs'

 class LanguageActions {
  constructor(_props) {
    this.api = config['LANGUAGE']
    this.rest  = 's/l/l'
    this.arcive = '/b/'
  }
    
    getLanguages = (_jwt, query) => {
      
      return dispatch => {
            const searchString = qs.stringify(query)
            const account = JSON.parse(localStorage.getItem( "account" ))
            return fetch(`${this.api + this.rest + '/r' + '?' + searchString}`, {
              method: 'Get',
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${account && account.token}`
              }
            }).then(data => {
            return data.json()
            })
            .then(data => {
              return dispatch({
                type: LANGUAGE_TYPES.GET_LANGUAGES,
                payload: data
              })
            })
            .catch(_err => {
              
            })
        }
    }

    paginationLanguage = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const searchString = qs.stringify(query)
              const response = await axios.get(
                this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }

              )
                dispatch({
                    type: LANGUAGE_TYPES.PAG_L_F,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: LANGUAGE_TYPES.PAG_L_R,
                    payload: e
                })
            }
        }
    }

    seeAll = (jwt,  page, count, query) => {
          return async dispatch => {
            try {

              dispatch({
                type: LANGUAGE_TYPES.FETCH_LANGUAGE_SEE_ALL_P
              })

              const searchString = qs.stringify(query)
              const response = await axios.get(
                this.api + this.rest + `/r/` + page + '/' + count + '?' + searchString,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )

              dispatch({
                  type: LANGUAGE_TYPES.FETCH_LANGUAGE_SEE_ALL_F,
                  payload: response.data
              })

            } catch (e) {
                dispatch({
                    type: LANGUAGE_TYPES.FETCH_LANGUAGE_SEE_ALL_R,
                    payload: e
                })
            }
        }
    }

    update = (jwt, language, Type, history) => {
      return async dispatch => {
        try {
          await axios.put(
            this.api + this.rest + `/${language.languageId}`,
            language,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: LANGUAGE_TYPES[Type + '_UPDATE_F'],
                payload: language
            })

            history.goBack()
            
        } catch (e) {
            dispatch({
                type: LANGUAGE_TYPES[Type + '_UPDATE_R'],
                payload: e
            })
          }
        }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: LANGUAGE_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: LANGUAGE_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: LANGUAGE_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: LANGUAGE_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: LANGUAGE_TYPES.BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.paginationLanguage(...otherDispatchParams))
              dispatch(this.getLanguages())
              
          } catch (e) {

              dispatch({
                  type: LANGUAGE_TYPES.BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
    */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + this.rest + this.arcive + 'a',
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: LANGUAGE_TYPES.BULK_DESTROY_F,
                payload: ids
            })

            dispatch(this.paginationLanguage(...otherDispatchParams))
            dispatch(this.getLanguages())
            
        } catch (e) {

            dispatch({
                type: LANGUAGE_TYPES.BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

      create = (jwt, body, Type, history) => {
        return async dispatch => {
          try {
             const response =  await axios.post(
              this.api + this.rest,
              body,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )

            dispatch({
                type: LANGUAGE_TYPES[Type + '_CREATE_F'],
                payload: response
            })

            history.goBack()
              
          } catch (e) {

              dispatch({
                  type: LANGUAGE_TYPES[Type + '_CREATE_R'],
                  payload: e
              })

          }
        }
      }

    
}




export default new LanguageActions()