const GET_ALL_DOWNLOADS = 'GET_ALL_DOWNLOADS';
const GET_ALL_REMOVED_DOWNLOADS = 'GET_ALL_REMOVED_DOWNLOADS';
const GET_DOWNLOAD_ITEMS = 'GET_DOWNLOAD_ITEMS';
const SELECT_DOWNLOAD_ITEMS = 'SELECT_DOWNLOAD_ITEMS';
const ADD_DOWNLOAD = 'ADD_DOWNLOAD';
const DELETE_DOWNLOAD = 'DELETE_DOWNLOAD';
const RECOVER_DOWNLOADS = 'RECOVER_DOWNLOADS';

export {
  GET_ALL_DOWNLOADS,
  GET_ALL_REMOVED_DOWNLOADS,
  GET_DOWNLOAD_ITEMS,
  SELECT_DOWNLOAD_ITEMS,
  ADD_DOWNLOAD,
  DELETE_DOWNLOAD,
  RECOVER_DOWNLOADS
}