export default class {
    constructor () {
      this.discountId = ''
      this.type = 'Percentage'
      this.appliesTo = 'All products'
      this.customerEligibility = 'Everyone'
      this.minimumRequirements = 'None'
      this.usageLimits = 'Limit number of times this discount can be used in total'
      this.store = []
      this.deleted = false
      this.parcentOff = 0
      this.amountOff = 0
      this.productGroup = []
      this.discountProducts = []
      this.customerGroup = []
      this.customers = []
      this.minPurchaseAmount = 0
      this.minQuantity = 0
      this.status = true
      this.sendNotification = true
      this.sendSms = false
      this.sendEmail = false
      this.imageId = null
      this.customerBuy = {
        selectedData: {
            products: [],
            productGroup: []
        },
        appliesTo: 'Specific products',
        quantity: null,
        minPurchaseAmount: 0,
        products: [],
        productGroup: []
      }
      this.customerGet = {
        selectedData: {
            products: [],
            productGroup: []
        },
        appliesTo: 'Specific products',
        type: 'Percentage',
        quantity: 0,
        parcentOff: 0,
        maxBuyersCount: null,
        products: [],
        productGroup: []
      }
      this.selectedData = {
        products: [], 
        productGroup: [],
        customers: [],
        customerGroup: []
      }
      this.dataFilter = [
        {
            name: 'Name',
            field: 'name',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Code',
            field: 'code',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Type',
            field: 'type',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Status',
            field: 'status',
            tag: 'select',
            label: true,
            type: 'boolean',
            color: '',
            value: ''
        },
        {
            name: 'Date Start',
            field: 'dateFrom',
            tag: 'datePicker',
            label: true,
            type: 'dateFrom',
            color: '',
            value: ''
        },
        {
            name: 'Date End',
            field: 'dateTo',
            tag: 'datePicker',
            label: true,
            type: 'dateTo',
            color: '',
            value: ''
        }
      ]
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Discount Store',
                    field: 'store',
                    label: true,
                    type: 'select',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 1,
                    message: 'Oops! Fill in the Store.',
                },
                {
                    name: 'Discount Code',
                    field: 'code',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 1,
                    required: true,
                    message: 'Oops! Fill in the Code.',
                    min: null,
                    max: 20,
                },
                {
                    name: 'name',
                    field: 'name',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 1,
                    required: true,
                    message: 'Oops! Fill in the Name.',
                    min: null,
                    max: 50,
                },
                {
                    name: 'Type',
                    field: 'type',
                    label: true,
                    type: 'radio',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 2,
                    required: true,
                    message: 'Oops! Fill in the Type.',
                    min: null,
                    max: 200,
                    defaultValue: 'Percentage',
                    data: [
                        {value: 'Percentage'},
                        {value: 'Fixed amount'},
                        {value: 'Free shipping'},
                        {value: 'Buy X get Y'}
                    ],
                    child: {
                        'Percentage': {
                            name: 'Discount Value %',
                            field: 'parcentOff',
                            label: true,
                            type: 'number',
                            color: '',
                            value: '',
                            tab: '1',
                            required: true,
                            sortOrder: 2,
                            message: 'Oops! Fill in the Discount Value.',
                            min: null,
                            max: 2,
                        },
                        'Fixed amount': {
                            name: 'Discount Value $',
                            field: 'amountOff',
                            label: true,
                            type: 'number',
                            color: '',
                            value: '',
                            tab: '1',
                            required: true,
                            sortOrder: 2,
                            message: 'Oops! Fill in the Discount Value.',
                            min: null,
                            max: 2,
                        },
                        'Buy X get Y': {
                            name: 'Customer buys',
                            field: 'customerBuys',
                            label: true,
                            type: 'component',
                            component: 'CustomerBuy'
                        }
                    } 
                },

                {
                    name: 'APPLIES TO',
                    field: 'appliesTo',
                    label: true,
                    type: 'radio',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 4,
                    required: true,
                    message: 'Oops! Fill in the APPLIES TO.',
                    min: null,
                    max: 200,
                    defaultValue: 'All products',
                    data: [
                        {value: 'All products'},
                        {value: 'Specific collections'},
                        {value: 'Specific products'}
                    ],
                    child: {
                        'Specific collections': {
                            name: 'Product Groups',
                            field: 'productGroup',
                            selectedData: 'productGroupSelect',
                            selectedCount: 'productGroupCount',
                            refIndex: 1,
                            label: true,
                            type: 'select',
                        },
                        'Specific products': {
                            name: 'Product',
                            field: 'products',
                            selectedData: 'productSelect',
                            selectedCount: 'productCount',
                            refIndex: 2,
                            label: true,
                            type: 'selectMultiple',
                        }
                    }  
                },

                {
                    name: 'Minimum requirements',
                    field: 'minimumRequirements',
                    label: true,
                    type: 'radio',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 5,
                    required: true,
                    message: 'Oops! Fill in the Minimum requirements.',
                    min: null,
                    max: 200,
                    defaultValue: 'None',
                    data: [
                        {value: 'None'},
                        {value: 'Minimum purchase amount ($)'},
                        {value: 'Minimum quantity of items'},
                    ],
                    child: {
                        'Minimum purchase amount ($)': {
                            name: 'Applies to all products',
                            field: 'minPurchaseAmount',
                            label: true,
                            type: 'number',
                        },
                        'Minimum quantity of items': {
                            name: 'Applies to all products.',
                            field: 'minQuantity',
                            label: true,
                            type: 'number',
                        }
                    } 
                },

                {
                    name: 'Customer eligibility',
                    field: 'customerEligibility',
                    label: true,
                    type: 'radio',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 6,
                    required: true,
                    message: 'Oops! Fill in the Customer eligibility.',
                    min: null,
                    max: 200,
                    defaultValue: 'Everyone',
                    data: [
                        {value: 'Everyone'},
                        {value: 'Specific groups of customers'},
                        {value: 'Specific customers'},
                    ],
                    child: {
                        'Specific groups of customers': {
                            name: 'Select groups of customers that can use this discount',
                            field: 'customerGroup',
                            refIndex: 3,
                            label: true,
                            type: 'select',
                            selectedData: 'customerGroupSelect',
                            selectedCount: 'customerGroupCount',
                        },
                        'Specific customers': {
                            name: 'customer',
                            field: 'customers',
                            refIndex: 4,
                            label: true,
                            type: 'select',
                            selectedData: 'customerSelect',
                            selectedCount: 'customerCount',
                        }
                    }
                },

                {
                    name: 'Usage limits',
                    field: 'usageLimits',
                    label: true,
                    type: 'radio',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 7,
                    required: false,
                    message: 'Oops! Fill in the Usage limits.',
                    min: null,
                    max: 200,
                    defaultValue: 'Limit number of times this discount can be used in total',
                    data: [
                        {value: 'Limit number of times this discount can be used in total'},
                        {value: 'Limit to one use per customer'}
                    ],
                    child: {
                        'Limit number of times this discount can be used in total': {
                            name: 'Limit number of times this discount',
                            field: 'limit',
                            label: true,
                            type: 'number',
                        },
                        'Limit to one use per customer': {
                            name: 'Limit to one use per customer',
                            field: 'oneUserLimit',
                            label: true,
                            type: 'number',
                        }
                    } 
                },


                {
                    name: 'Status',
                    field: 'status',
                    label: true,
                    type: 'switch',
                    tab: '1',
                    sortOrder: 8,
                    color: '',
                },
                {
                    name: 'Start Date',
                    field: 'startDate',
                    label: true,
                    type: 'dateTime',
                    sortOrder: 9,
                    tab: '1',
                    color: '',
                    value: ''
                },

                {
                    name: 'End Date',
                    field: 'endDate',
                    label: true,
                    type: 'date',
                    sortOrder: 4,
                    tab: '1',
                    color: '',
                    value: ''
                },

                {
                    name: 'Send Sms',
                    field: 'sendSms',
                    label: true,
                    type: 'checkbox',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 10,
                },

                {
                    name: 'Send Email',
                    field: 'sendEmail',
                    label: true,
                    type: 'checkbox',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 11,
                },

                {
                    name: 'Send Notification',
                    field: 'sendNotification',
                    label: true,
                    type: 'checkbox',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 11,
                }
            ] 
        },
        { 
            name: 'Image',
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'image',
                    field: 'Image',
                    label: true,
                    type: 'file',
                    color: '',
                    value: '',
                    tab: '2',
                }
            ]  
        }, 
      ]
    }
  }