export default class {
    constructor () {
      this.user_group_id = ''
      this.username = ''
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.phone = ''
      this.password = ''
      this.deleted = false
      this.status = false
      this.selectedData = {
        user_group_id: {}
      }
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Username',
                    field: 'username',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    validation: 'string',
                    message: 'Oops! Fill in the Username.',
                    min: 2,
                    max: 200,
                },
                {
                    name: 'Lastname',
                    field: 'lastname',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    validation: 'string',
                    message: 'Oops! Fill in the Lastname.',
                    min: 2,
                    max: 200,
                },
                {
                    name: 'Firstname',
                    field: 'firstname',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    validation: 'string',
                    message: 'Oops! Fill in the Firstname.',
                    min: 2,
                    max: 200,
                },
                {
                    name: 'Email',
                    field: 'email',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    validation: 'email',
                    message: 'Oops! Fill in the Email.',
                    min: null,
                    max: null,
                },
                {
                    name: 'Phone',
                    field: 'phone',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Phone.',
                    min: null,
                    max: null,
                },
                {
                    name: 'Password',
                    field: 'password',
                    tag: 'input',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Password.',
                    min: 7,
                    max: null,
                },
                {
                    name: 'Sort Order',
                    field: 'sortOrder',
                    tag: 'input',
                    label: true,
                    type: 'number',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the Name.',
                    min: null,
                    max: 200,
                },
                {
                    name: 'User Status',
                    field: 'status',
                    tag: 'switch',
                    label: true,
                    type: 'boolean',
                    color: '',
                    value: ''
                },
                {
                    name: 'Groups',
                    field: 'user_group_id',
                    label: true,
                    tag: 'tag',
                    color: '',
                    value: '',
                    tab: '1',
                    required: true,
                    message: 'Oops! Fill in the UserGroup',
                    min: null,
                    max: null, 
                }
            ] 
        }
      ]
    }
  }