import {imageService} from "../../services/media/imageService";
import {IMAGE_TYPES} from "../../types"

/**
 * @name getAll
 * @returns {function(*)}
 */
const getAll = (token) => {
  return dispatch => {
    return imageService.getAll(token).then(options => {
      return dispatch({
        type: IMAGE_TYPES.GET_ALL_IMAGES,
        payload: options
      })
    })
  }
};

/**
 * @name getAllRemoved
 * @returns {function(*)}
 */
const getAllRemoved = (token) => {
  return dispatch => {
    return imageService.getAllRemoved(token).then(options => {
      return dispatch({
        type: IMAGE_TYPES.GET_ALL_REMOVED_IMAGES,
        payload: options
      })
    })
  }
};

/**
 * @name getItem
 * @param id
 * @returns {function(*)}
 */
const getItem = (id,token) => {
  return dispatch => {
    return imageService.getItem(id,token).then(elem => {
      dispatch({
        type: IMAGE_TYPES.GET_IMAGE_ITEMS,
        payload: elem
      })
      dispatch({
        type: IMAGE_TYPES.SELECT_IMAGE_ITEMS,
        payload: elem
      })
    })
  }
};

/**
 * @name selectItem
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type: IMAGE_TYPES.SELECT_IMAGE_ITEMS,
      payload: elem
    })
  }
};

/**
 * @name selectItem
 * @param elem
 * @returns {function(*)}
 */
const addMultipleImage = (body, size, token, page, count) => {
  return dispatch => {
    return imageService.addMultipleImage(body, 
      size,
      token).then(option => {
      dispatch({
        type: IMAGE_TYPES.SELECT_IMAGE_SIZE_ITEMS,
        payload: option
      })
      dispatch(getPaginationNotRemoved(token, page, count))
  })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return imageService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: IMAGE_TYPES.GET_PAG_NOT_REMOVED_IMAGES,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return imageService.getPaginationRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: IMAGE_TYPES.GET_PAG_REMOVED_IMAGES,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const arciveItem = (elem, jwt) => {
  return dispatch => {
    imageService.arcive(elem.image_id,  jwt).then((data) => {
      if (data.status) {
      return dispatch({
          type: IMAGE_TYPES.ARCIVE_IMAGE_ITEMS,
          payload : elem
        })
      }
    })
  }
};

/**
 * @name recoverItem
 * @param elem
 * @returns {function(*)}
 */
const recoverItem = (elem, jwt) => {
  return dispatch => {
    imageService.recover(elem.image_id,  jwt).then((data) => {
      if (data.status) {
        return dispatch({
          type: IMAGE_TYPES.RECOVER_IMAGE_ITEMS,
          payload : elem
        })
      }
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const deleteItem = (elem, jwt, filterTab) => {
  return dispatch => {
    imageService.delete(elem.image_id,  jwt).then((data) => {
      if (data.status) {
        if (filterTab) {
          return dispatch({
            type: IMAGE_TYPES.DELETE_IMAGE_ITEMS_ALL,
            payload : elem
          })
        } else {
          return dispatch({
            type: IMAGE_TYPES.DELETE_IMAGE_ITEMS_ARC,
            payload : elem
          })
        }
      }
    })
  }
};

const orderList = (index) => {
  const oldIndex = index.oldIndex
  const newIndex = index.newIndex
  return {
    type: 'REORDER_LIST',
    payload: {oldIndex, newIndex},
  }
}

/**
 * @name updateImage
 * @param elem
 * @returns {function(*)}
 */
const updateImage = (body,token, query, history) => {
  return dispatch => {
    return imageService.updateImage(body, 
      token, query).then(option => {
      history.push('/tool/upload')
      dispatch({
        type: IMAGE_TYPES.UPDATE_IMAGE_ITEM,
        payload: option
      })
  })
  }
};

/**
 * @name bulk
 * @param elem
 * @returns {function(*)}
 */
const bulk = (body, token, filterTab, page, showEntries) => {
  return dispatch => {
    return imageService.bulk(body, 
      token).then(option => {
      dispatch({
        type: IMAGE_TYPES.ICON_BULK_ARCIVE,
        payload: option
      })
      if (filterTab) {
        dispatch(getPaginationNotRemoved(token, page, showEntries))
      } else {
        dispatch(getPaginationRemoved(token, page, showEntries))
      }
  })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (body, token, filterTab, page, showEntries) => {
  return dispatch => {
    return imageService.bulkDestroy(body, 
      token).then(option => {
      dispatch({
        type: IMAGE_TYPES.ICON_BULK_ARCIVE,
        payload: option
      })
      if (filterTab) {
        dispatch(getPaginationNotRemoved(token, page, showEntries))
      } else {
        dispatch(getPaginationRemoved(token, page, showEntries))
      }
  })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const addImageMain = (image, filterTab) => {
  return dispatch => {
      if (filterTab) {
        dispatch({
          type: IMAGE_TYPES.ADD_MAIN_IMAGE_ALL,
          payload: image,
        }) 
      } else {
          dispatch({
            type: IMAGE_TYPES.ADD_MAIN_IMAGE_ARCHIVE,
            payload: image,
          }) 
      }
    }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const addImages= (image) => {
  return dispatch => {
      dispatch({
        type: IMAGE_TYPES.ADD_IMAGES,
        payload: image
      }) 
    }
};


/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const cancel = (image) => {
  return dispatch => {
      dispatch({
        type: IMAGE_TYPES.CANCEL_ADD_IMAGE,
        payload: image
      }) 
    }
};



export {getAll, getAllRemoved, getItem, selectItem, addMultipleImage, getPaginationNotRemoved, getPaginationRemoved, arciveItem,bulkDestroy, recoverItem, deleteItem, orderList, updateImage, bulk, addImageMain, addImages, cancel}