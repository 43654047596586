export default class {
    constructor () {
      this.name = ''
      this.email = []
      this.phone = []
      this.staff = []
      this.stores = []
      this.status = true
      this.deleted = false
      this.dataFilter = [
        {
            name: 'Name',
            field: 'name',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        }
      ]
      this.descriptions = [
        {
          name :"",
          description: "",
          metaTitle: "",
          metaDescription: "",
          metaKeyword: "",
          latitude: 34.123109,
          languageId: 1
        }
      ],
      this.address = [
        {
          address: "",
          state: "",
          city: "",
          area: "",
          country: "",
          zipCode: null,
          latitude: 34.123109,
          longitude: -118.49966
        }
      ],
      this.hoursOfOperation = {
        monday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        tuesday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        wednesday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        thursday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        friday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        saturday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        },
        sunday: {
          open: "",
          close: "",
          fullTime: false,
          closed: false
        }
      },
      this.navBar = [
        { 
            name: 'General', 
            color: '#000000',
            count: 0,
            inputs: [
                {
                    name: 'Warehouse Name',
                    field: 'name',
                    label: true,
                    type: 'text',
                    color: '',
                    value: '',
                    tab: '1',
                    sortOrder: 1,
                    required: true,
                    message: 'Oops! Fill in the Name.',
                    min: null,
                    max: 30,
                }
            ] 
        }
      ]
    }
  }