export default class {
    constructor () {
      this.firstname = ''
      this.lastname = ''
      this.websiteUrl = ''
      this.occupation = ''
      this.license = ''
      this.customerId = ''
      this.serviceId = ''
      this.comment = ''
      this.companyName = ''
      this.status = false
      this.socials = []
      this.banners = []
      this.address = [
        {
          address :"",
          state: "",
          regison: "",
          postCode: "",
          phone: "",
          email: "",
          city: "",
          country: "",
          isDefault : true,
          location: {
            lat: 34.123109, 
            lng: -118.49966
          }
        }
      ]
    }
  }