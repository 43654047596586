export default class {
    constructor () {
      this.promoCodeId = ''
      this.name = ''
      this.code = ''
      this.type = 'Percentage'
      this.customerEligibility = 'Everyone'
      this.minimumRequirements = 'None'
      this.usageLimits = 'Limit number of times this promo code can be used in total'
      this.store = []
      this.deleted = false
      this.percentOff = 0
      this.amountOff = 0
      this.customerGroup = []
      this.customers = []
      this.minPurchaseAmount = 0
      this.minQuantity = 0
      this.status = true
      this.sendNotification = true
      this.sendSms = false
      this.sendEmail = false
      this.selectedData = {
        customers: [],
        customerGroup: []
      }
      this.dataFilter = [
        {
            name: 'Name',
            field: 'name',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Code',
            field: 'code',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Type',
            field: 'type',
            tag: 'input',
            label: true,
            type: 'text',
            color: '',
            value: ''
        },
        {
            name: 'Status',
            field: 'status',
            tag: 'select',
            label: true,
            type: 'boolean',
            color: '',
            value: ''
        },
        {
            name: 'Date Start',
            field: 'dateFrom',
            tag: 'datePicker',
            label: true,
            type: 'dateFrom',
            color: '',
            value: ''
        },
        {
            name: 'Date End',
            field: 'dateTo',
            tag: 'datePicker',
            label: true,
            type: 'dateTo',
            color: '',
            value: ''
        }
      ]
      this.navBar = [
        { 
          name: 'General', 
          color: '#000000',
          count: 0,
          inputs: [
            {
              name: 'Name *',
              field: 'name',
              tag: 'input',
              label: true,
              type: 'text',
              color: '',
              value: '',
              tab: '1',
              required: true,
              message: 'Oops! Fill in the Name.',
              min: null,
              max: 200,
            },
            {
              name: 'Code *',
              field: 'code',
              tag: 'input',
              label: true,
              type: 'text',
              color: '',
              value: '',
              tab: '1',
              required: false,
              message: 'Oops! Fill in the Code.',
              min: 5,
              max: 10
            }
          ] 
        }
      ]
    }
  }