import  { BULK_SMS_TYPES, SMS_TYPES } from '../../types'
import config from '../../../../config/app'
import { Notification } from "element-react";
import axios from 'axios'
import qs from 'qs'

class SmsActions {
    constructor(_props) {
        this.api = config['SMS_API']
        this.rest  = 'bulk/'
        this.arcive = 'b/'
    }


    sendSmsSendPulse = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_P
                })

                const { data } = await axios.post(
                    this.api + this.rest + 'send_pulse/sms/send',
                    {
                        senderName: 'SendPulse',
                        to: body.to,
                        message: body.message,
                        storeId: body.storeId,
                        customerGroups: body.customerGroups,
                        links: body.links,
                        phones: body.phones,
                        texts: [body.message],
                        campaignName: body.campaignName
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )


                Notification.success({
                    title: 'Sms',
                    message: 'Your SMS campain has been succesfuly sent!'
                }, 20)

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_F,
                    payload: data
                })

                history.push('/sms/campaign')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_R,
                    payload: e
                })
            
            }
        }
    }

    sendSmsMobinity = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_P
                })

                const { data } = await axios.post(
                    this.api + this.rest + 'mobiniti/sms/send',
                    {
                        businessId: body.businessId,
                        to: body.to,
                        message: body.message,
                        sendDate: body.sendDate,
                        storeId: body.storeId,
                        customerGroups: body.customerGroups,
                        links: body.links,
                        phones: body.phones,
                        texts: [body.message],
                        campaignName: body.campaignName
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Sms',
                    message: 'Your SMS campain has been succesfuly sent!'
                }, 20)

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_F,
                    payload: data
                })

                history.push('/sms/campaign')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_R,
                    payload: e
                })
            
            }
        }
    }

    sendSmsTelnyx = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_P
                })

                const { data } = await axios.post(
                    this.api + this.rest + 'telnyx/sms/send',
                    {
                        businessId: body.businessId,
                        to: body.to,
                        message: body.message,
                        sendDate: body.sendDate,
                        storeId: body.storeId,
                        customerGroups: body.customerGroups,
                        links: body.links,
                        phones: body.phones,
                        texts: body.texts,
                        name: body.name,
                        campaignId: body.campaignId,
                        files: body.files,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Sms',
                    message: 'Your SMS campain has been succesfuly sent!'
                }, 20)

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_F,
                    payload: data
                })

                history.push('/sms/campaign')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                            if (err.param === 'plan') {
                                history.push('/sms/plan');
                            }
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_R,
                    payload: e
                })
            
            }
        }
    }

    sendSmsTwilio = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_P
                })

                const { data } = await axios.post(
                    this.api + this.rest + 'twilio/sms/send',
                    {
                        businessId: body.businessId,
                        from: body.from,
                        to: body.to,
                        message: body.message,
                        sendDate: body.sendDate,
                        storeId: body.storeId,
                        customerGroups: body.customerGroups,
                        links: body.links,
                        phones: body.phones,
                        texts: [body.message],
                        campaignName: body.campaignName
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Sms',
                    message: 'Your SMS campain has been succesfuly sent!'
                }, 20)

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_F,
                    payload: data
                })

                history.push('/sms/campaign')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_SEND_R,
                    payload: e
                })
            
            }
        }
    }

    pagination = (jwt, params, page, count, query) => {
        return async dispatch => {
            try {

                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + this.rest +  `${params}` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_PAG_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_PAG_R,
                    payload: e
                })
            }
        }
    }

    newSms = () => {
        return dispatch => {
            return dispatch({
                type: BULK_SMS_TYPES.BULK_SMS_NEW
            })
        }
    };

    show = (jwt, id, Type) => {

        return async dispatch => {
          try {

            dispatch({
                type: SMS_TYPES[Type + '_GET_ONE_P']
            })

            const response = await axios.get(
                this.api + this.rest + `${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    }
                }
            )

            dispatch({
                type: SMS_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })

          } catch (e) {

                dispatch({
                    type: SMS_TYPES[Type + '_GET_ONE_R'],
                    payload: e
                })

            }
        }
        
    }

    destroy = (jwt, id, Type) => {
        return async dispatch => {
            try {
                await axios.delete(
                    this.api + this.rest + `/${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
                dispatch({
                    type: BULK_SMS_TYPES[Type + '_DELETE_F'],
                    payload: id
                })
            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES[Type + '_DELETE_R'],
                    payload: e
                })
            }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
    */
    bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                this.api + this.rest + this.arcive + params,
                ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_BULK_ARCIVE_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_BULK_ARCIVE_R,
                    payload: e
                })

            }
        }
    }

    /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
    */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                    this.api + this.rest + 'b/d',
                    ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_BULK_DESTROY_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_BULK_DESTROY_R,
                    payload: e
                })

            }
        }
    }

    getBalance = (jwt) => {
        return async dispatch => {
            try {
                const { data } = await axios.get(
                this.api + this.rest + 'send_pulse/sms/balance/usd',
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_BALANCE_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_BALANCE_R,
                    payload: e
                })
            }
        }
    }

    seeAllPhones = (jwt, page, count, query, search) => {

        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.FETCH_BULK_SMS_PHONES_P
                })
                
                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + this.rest + 'phones/' + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

            dispatch({
                type: BULK_SMS_TYPES.FETCH_BULK_SMS_PHONES_F,
                payload: data,
                search: search
            })

            } catch (e) {

                dispatch({
                    type: BULK_SMS_TYPES.FETCH_BULK_SMS_PHONES_R,
                    payload: e
                })
            
            }
        }
    }

    numberList = (jwt, businessId) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_P
                })

                const { data } = await axios.get(
                    this.api + this.rest + `telnyx/number/list/${businessId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_R,
                    payload: e
                })
            }
        }
    }

    numberBuy = (jwt, body) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_P
                })

                const { data } = await axios.post(
                    this.api + this.rest + 'telnyx/number/buy',
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_R,
                    payload: e
                })
            }
        }
    }


    numberListTwilio = (jwt, businessId) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_P
                })

                const { data } = await axios.get(
                    this.api + this.rest + `twilio/number/list/${businessId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_LIST_R,
                    payload: e
                })
            }
        }
    }

    numberBuyTwilio = (jwt, body) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_P
                })

                const { data } = await axios.post(
                    this.api + this.rest + 'twilio/number/buy',
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.TELNYX_BUSINESS_NUMBER_BUY_R,
                    payload: e
                })
            }
        }
    }
    

    getTelnyxBalance = (jwt, businessId) => {
        return async dispatch => {
            try {
                const { data } = await axios.get(
                this.api + this.rest + `telnyx/sms/balance/${businessId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.GET_TELNYX_BALANCE_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.GET_TELNYX_BALANCE_R,
                    payload: e
                })
            }
        }
    }


    getTwilioBalance = (jwt, businessId) => {
        return async dispatch => {
            try {
                const { data } = await axios.get(
                this.api + this.rest + `twilio/sms/balance/${businessId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.GET_TELNYX_BALANCE_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: BULK_SMS_TYPES.GET_TELNYX_BALANCE_R,
                    payload: e
                })
            }
        }
    }



    update = (data) => {
        return async dispatch => {
            dispatch({
                type: BULK_SMS_TYPES.UPDATE_BULK_SMS,
                payload: data
            })
        }
    }

    getStatistics = (jwt, query) => {
        return async dispatch => {
            try {
    
                const searchString = qs.stringify(query)
    
                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_STATISTICS_P
                })
    
                const response = await axios.get(`${this.api + `${this.rest}s?${searchString}`}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
    
                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_STATISTICS_F,
                    payload: response.data
                })
    
            } catch (e) {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_STATISTICS_R,
                    payload: e
                })

            }
        }
    };

    paginationCountries = (jwt, page, count, query, search) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.FETCH_COUNTRIES_SEE_ALL_R
                })

                const searchString = qs.stringify(query)
                const response = await axios.get(
                    this.api + this.rest + `countries/` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`,
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.FETCH_COUNTRIES_SEE_ALL_F,
                    payload: response.data,
                    search: search
                })
        
            } catch (e) {
        
                dispatch({
                    type: BULK_SMS_TYPES.FETCH_COUNTRIES_SEE_ALL_R,
                    payload: e
                })
        
            }
        }
    }


    fetchPlans = (jwt, page, count) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.GET_SMS_PLAN_P
                })

                const { data } = await axios.get(
                    this.api + 'plan/r/' + page + '/' + count,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.GET_SMS_PLAN_F,
                    payload: data
                })

            } catch (e) {

                dispatch({
                    type: BULK_SMS_TYPES.GET_SMS_PLAN_R,
                    payload: e
                })
                
            }
        }
    }
    

    choosePlan = (jwt, planId, history) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.CHOOSE_SMS_PLAN_P
                })

                const { data } = await axios.post(
                    this.api + `plan/${planId}`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: BULK_SMS_TYPES.CHOOSE_SMS_PLAN_F,
                    payload: data
                })

                history.goBack()

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 
                
                dispatch({
                    type: BULK_SMS_TYPES.CHOOSE_SMS_PLAN_R,
                    payload: e
                })
                
            }
        }
    }


    checkPlan = (jwt, history) => {
        return async dispatch => {
            try {

                dispatch({
                    type: BULK_SMS_TYPES.CHECH_SMS_PLAN_P
                })

                const { data } = await axios.get(
                    this.api + 'plan',
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
                
                dispatch({
                    type: BULK_SMS_TYPES.CHECH_SMS_PLAN_F,
                    payload: data
                })

            } catch (e) {

                history.push('/sms/plan')
                
                dispatch({
                    type: BULK_SMS_TYPES.CHECH_SMS_PLAN_R,
                    payload: e
                })
                
            }
        }
    }
    

    validate = (jwt, body) => {

        return async dispatch => {

            try {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_VALIDATE_P
                })

                const { data } = await axios.post(
                    this.api + this.rest + 'telnyx/sms/validate',
                    {
                        businessId: body.businessId,
                        to: body.to,
                        customerGroups: body.customerGroups,
                        texts: body.texts
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )


                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_VALIDATE_F,
                    payload: data
                })

            } catch (e) {

                dispatch({
                    type: BULK_SMS_TYPES.BULK_SMS_VALIDATE_R,
                    payload: e.response
                })

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.param,
                                message: err.msg
                            }, 20)
                        })
                    }
                } 
            
            }
        }
    }

}




export default new SmsActions()