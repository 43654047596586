import  { DELIVERY_ROUTE_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import { Notification } from "element-react";
import qs from 'qs'

class DeliveryRouteActions {
    constructor(_props) {
        this.api = config['ORDER']
        this.rest  = 'delivery/route/'
        this.arcive = 'b/'
    }

    create = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.ADD_DELIVERY_ROUTE_P
                })

                const { data } = await axios.post(
                    this.api + this.rest,
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Delivery Route',
                    message: 'Your delivery route has been succesfuly create!'
                }, 20)

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.ADD_DELIVERY_ROUTE_F,
                    payload: data
                })

                history.push('/delivery/routes')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.ADD_DELIVERY_ROUTE_R,
                    payload: e
                })
            
            }
        }
    }

    pagination = (jwt, params, page, count, query) => {
        return async dispatch => {
            try {

                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + this.rest +  `${params}` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_PAG_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_PAG_R,
                    payload: e
                })
            }
        }
    }

    newDeliveryRoute = () => {
        return dispatch => {
            return dispatch({
                type: DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_NEW
            })
        }
    };

    update = (jwt, body, history, Type) => {
        return async dispatch => {
            try {
                await axios.put(
                    this.api + this.rest + `${body.deliveryRouteId}`,
                    body,
                    {
                        headers: 
                        {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.warning({
                    title: 'Delivery Route',
                    message: 'Your delivery route has been succesfuly updated!'
                }, 20)

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_UPDATE_F'],
                    payload: body
                })

                
                history.push('/delivery/routes')
                
            } catch (e) {
                console.log(e)
                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_UPDATE_R'],
                    payload: e
                })

            }
        }
    }

    updateStatus = (jwt, body, Type, history) => {
        return async dispatch => {
            try {

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_UPDATE_STATUS_P']
                })

                await axios.put(
                    this.api + this.rest + `${body.deliveryRouteId}`,
                    body,
                    {
                        headers: 
                        {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.warning({
                    title: 'Delivery Route',
                    message: `Delivery ${body.status}!`
                }, 20)

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_UPDATE_STATUS_F'],
                    payload: body
                })

                if (history) {
                    history.push('/delivery/routes')
                }
                
            } catch (e) {
                console.log(e)
                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_UPDATE_STATUS_R'],
                    payload: e
                })

            }
        }
    }

    destroy = (jwt, id, Type) => {
        return async dispatch => {
            try {
                await axios.delete(
                    this.api + this.rest + `${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_DELETE_F'],
                    payload: id
                })
            } catch (e) {
                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_DELETE_R'],
                    payload: e
                })
            }
        }
    }

    show = (jwt, id, Type) => {
        return async dispatch => {
            try {

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_GET_ONE_P']
                })

                const response = await axios.get(
                    this.api + this.rest + `${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_GET_ONE_F'],
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_GET_ONE_R'],
                    payload: e
                })

            }
        }
    }

    /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
    */
    bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                this.api + this.rest + this.arcive + params,
                ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_BULK_ARCIVE_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_BULK_ARCIVE_R,
                    payload: e
                })

            }
        }
    }

    /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
    */
      bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                    this.api + this.rest + 'b/d',
                    ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_BULK_DESTROY_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: DELIVERY_ROUTE_TYPES.DELIVERY_ROUTE_BULK_DESTROY_R,
                    payload: e
                })

            }
        }
    }


    sendNotification = (jwt, body, Type) => {
        return async dispatch => {
            try {
                await axios.post(
                    this.api + this.rest + 'send/notification',
                    body,
                    {
                        headers: 
                        {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_SEND_NOTIFICATION_F'],
                    payload: body
                })

                
            } catch (e) {
                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: DELIVERY_ROUTE_TYPES[Type + '_SEND_NOTIFICATION_R'],
                    payload: e
                })

            }
        }
    }

}




export default new DeliveryRouteActions()