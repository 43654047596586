
import { PRODUCT_GROUP_TYPES} from "../types"
import {productGroupService} from "../services/productGroupService";
import { Notification } from "element-react"

/**
 * @name paginationRemoved
 * @returns {function(*)}
 */
const paginationNotRemoved = (jwt, page, count, filter) => {
    return dispatch => {
        return productGroupService.getPaginationNotRemoved(jwt, page, count, filter).then(options => {
            return dispatch({
                type: PRODUCT_GROUP_TYPES.GET_PAG_NOT_REMOVED_PRODUCT_GROUPS,
                payload : options
            })
        })
    }
};

/**
 * @name paginationNotRemoved
 * @returns {function(*)}
 */
const paginationRemoved = (jwt, page, count, filter) => {
    return dispatch => {
        return productGroupService.getPaginationRemoved(jwt, page, count, filter).then(options => {
            return dispatch({
                type: PRODUCT_GROUP_TYPES.GET_PAG_REMOVED_PRODUCT_GROUPS,
                payload : options
            })
        })
    }
};

/**
   * @name seeAll
   * @returns {function(*)}
*/
const seeAll = (jwt, page, count) => {
    return dispatch => {
            return productGroupService.getPaginationNotRemoved(jwt, page, count).then(options => {
                return dispatch({
                    type: PRODUCT_GROUP_TYPES.FETCH_PRODUCT_GROUP_SEE_ALL,
                    payload : options
                })
            })
        }
  };


/**
 * @name getOne
 * @param id
 * @returns {function(*)}
 */
const getOne = (id, jwt) => {
    return dispatch => {
        return productGroupService.getItem(id, jwt).then(data => {
            return dispatch({
                type: PRODUCT_GROUP_TYPES.GET_PRODUCT_GROUP,
                payload : data
            })
        })
    }
};
/**
 * @name selectOne
 * @param elem
 * @returns {function(*)}
 */
const selectOne = (elem) => {
    return dispatch => {
        return dispatch({
            type : PRODUCT_GROUP_TYPES.SELECT_PRODUCT_GROUP,
            payload : elem
        })
    }
};


/**
 * @name archive
 * @param elem
 * @returns {function(*)}
 */
const archive = (elem, jwt) => {
    return async dispatch => {
        try {
            const data = await productGroupService.remove(elem.productGroupId,  jwt)
            if (data.success) {
                dispatch({
                    type: PRODUCT_GROUP_TYPES.ARCHIVE_PRODUCT_GROUP,
                    payload : elem
                })
            }
            return data
        } catch(e) {
            Notification.error({
                    title: 'Archiving Error',
                    message: `Archiving Error`
                }, 
            20) 
        }
    }
};

/**
 * @name recover
 * @param elem
 * @returns {function(*)}
 */
const recover = (elem, jwt) => {
    return async dispatch => {
        try {
            const data = await productGroupService.recover(elem.productGroupId,  jwt)
            if (data.success) {
                dispatch({
                    type: PRODUCT_GROUP_TYPES.RECOVER_PRODUCT_GROUP,
                    payload : elem
                })
            }
            return data
        } catch(e) {
            Notification.error({
                    title: 'Recovering Error',
                    message: `Recovering Error`
                }, 
            20) 
        }
    }
};

/**
     * @name bulkArchive
     * @param elem
     * @returns {function(*)}
*/
const bulkArchive = (ids, jwt) => {
    return async dispatch => {
        try {
            const data = await productGroupService.bulkDelete(ids,  jwt)
            dispatch({
                type: PRODUCT_GROUP_TYPES.BULK_ARCHIVE_PRODUCT_GROUP,
                payload : data
            })
            return data
        } catch(e) {
            Notification.error({
                    title: 'Bulk Archive Error',
                    message: `Bulk Archive Error`
                }, 
            20) 
        }
    }
};

/**
     * @name bulkRecover
     * @param elem
     * @returns {function(*)}
*/
const bulkRecover = (ids, jwt) => {
    return async dispatch => {
        try {
            const data = await productGroupService.bulkRecover(ids,  jwt)
            dispatch({
                type: PRODUCT_GROUP_TYPES.BULK_RECOVER_PRODUCT_GROUP,
                payload : data
            })
            return data
        } catch(e) {
            Notification.error({
                    title: 'Bulk Recover Error',
                    message: `Bulk Recover Error`
                }, 
            20) 
        }
    }
};

const create = (product, jwt, history, discard, ) => {
  return async dispatch => {
        productGroupService.create(product, jwt)
            .then(data => {

                Notification.success({
                    title: 'Created',
                    message: 'Your product Group succesfuly created',
                }, 20)
            
                if (discard) {
                    history.push(`${discard}`)
                } else {
                    history.push('/catalog/product_group')
                }

                dispatch({
                    type: PRODUCT_GROUP_TYPES.SAVE_PRODUCT_GROUP_F,
                    payload: data
                })

                dispatch({
                    type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_TAB_PANEL_CHANGE,
                    payload:  '1'
                }) 

            })
            .catch(error => {
                if (error.response.status === 422) {
                    error.response.data.forEach(err => {
                      Notification.error({
                        title: err.path,
                        message: err.message
                      }, 20)
                    })
                  } 
                })
            }
    }

const update = (productGroup, id, token, history, discard) => {
    return async dispatch => {
    try {

        const data = await productGroupService.edit(productGroup, id, token)

        Notification.warning({
            title: 'Updated',
            message: 'Your product Group succesfuly updated '
        }, 20)

            if (discard) {
                history.push(`${discard}`)
            } else {
                history.push('/catalog/product_group')
            }

        dispatch({
            type: PRODUCT_GROUP_TYPES.UPDATE_PRODUCT_GROUP_F,
            payload: data
        })

        dispatch({
            type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_TAB_PANEL_CHANGE,
            payload:  '1'
        }) 
        
    } catch (error) {
                if (error.response.status === 422) {
                    error.response.data.forEach(err => {
                    Notification.error({
                        title: err.path,
                        message: err.message
                    }, 20)
                    })
                } 
            }
        }
    }


    const pagination = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const response = await productGroupService.pagination(jwt, params, page, count, query)
                dispatch({
                    type: PRODUCT_GROUP_TYPES.PRODUCTS_GROUPS_PAG_F,
                    payload: response
                })
            } catch (e) {
                dispatch({
                    type: PRODUCT_GROUP_TYPES.PRODUCT_GROUPS_PAG_R,
                    payload: e
                })
            }
        }
    }

    const destroy = ( token, productGroup ) => {

      return async dispatch => {
  
        try {
  
            const data = await productGroupService.destroy(token , productGroup.productGroupId)
    
            Notification.success( {
              title: 'product',
              message: `${productGroup.descriptions[0].name} succesfuly deleted `
            }, 20 )
  
            dispatch( {
                type: PRODUCT_GROUP_TYPES.DESTROY_PRODUCT_GROUP,
                payload: productGroup
            } )

            return data
            
        } catch (e) {
  
                Notification.error( {
                    title: e.name,
                    message: `${e.parent.column}`
                }, 20) 
            
            }
        }
    }

    /**
      * @name bulkDestroy
      * @param elem
      * @returns {function(*)}
    */
    const bulkDestroy = (ids, jwt) => {

        return async dispatch => {
            try {
                const data = await productGroupService.bulkDestroy(ids,  jwt)
                dispatch({
                    type: PRODUCT_GROUP_TYPES.BULK_DESTROY_PRODUCT_GROUP,
                    payload : data
                })
                return data
            } catch(e) {
                Notification.error({
                        title: 'Bulk Destroy Error',
                        message: `Bulk Destroy Error`
                    }, 
                20) 
            }
        }
        
    };

    const chooseFilter = (data, id) => {
        return async dispatch => {
            dispatch({
                type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER,
                payload: data,
                id: id
            })
        } 
    }
  
    const chooseFilterValue = (data) => {
        return async dispatch => {
            dispatch({
                type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_VALUE,
                payload: data
            })
        } 
    }
  
    const chooseFilterDateValue = (data) => {
        return async dispatch => {
            dispatch({
                type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_DATE_VALUE,
                payload: data
            })
        } 
    }
  
    const chooseFilterCategories = (data) => {
        return async dispatch => {
            dispatch({
                type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_CATEGORIES,
                payload: data
            })
        } 
    }

    const chooseFilterBrands = (data) => {
        return async dispatch => {
            dispatch({
                type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_BRANDS,
                payload: data
            })
        } 
    }
  
    const chooseFilterBusiness = (data) => {
        return async dispatch => {
            dispatch({
                type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_BUSINESS,
                payload: data
            })
        } 
    }
  
    const removeFilter = (id) => {
        return async dispatch => {
            dispatch({
                type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_REMOVE_FILTER,
                payload: id
            })
        } 
    }

    const getFilters = (jwt, page, count, filter) => {
        return dispatch => {
            return productGroupService.getFilters(jwt, page, count, filter).then(options => {
                return dispatch({
                    type: PRODUCT_GROUP_TYPES.PRODUCT_GROUP_FILTER_F,
                    payload: options
                })
            })
        }
    }

export {
    getOne, selectOne, 
    paginationNotRemoved, paginationRemoved, seeAll,
    archive, recover, bulkArchive, bulkRecover, 
    create, update, pagination, destroy, bulkDestroy,
    chooseFilter, chooseFilterValue, chooseFilterDateValue, chooseFilterCategories, chooseFilterBrands, chooseFilterBusiness, removeFilter, getFilters
}