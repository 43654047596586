export default class {
    constructor () {
        this.store_id = ''
        this.customer_id = ''
        this.status = 'new'
        this.dateAdvance = ''
        this.orderTypeId = ''
        this.paymentMethod = 'payOnDelivery'
        this.message = ''
        this.total = 0
        this.tax = 0
        this.shippingPrice = '',
        this.address = {
            address: '',
            latitude: '',
            longitude: ''
        },
        this.store = {}
        this.orderType = {}
        this.customer = {}
        this.products = []
        this.orderHistory = []
    }
}