import React, { useState, useEffect, useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
} from 'reactstrap';
import Widget03 from '../../views/Widgets/Widget03'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle } from '@coreui/coreui/dist/js/coreui-utilities';
import WebPush from '../../WebPush';
import config from '../../config/app';
import TrafficChart from '../Charts/TrafficChart';
import CallChart from '../Charts/CallChart';
import ProductChart from '../Charts/ProductChart';
import OrderChart from '../Charts/OrderChart';
import OrderMap from '../Charts/OrderMap';
import SmsChart from '../Charts/SmsChart';
import StoreSmsChart from '../Charts/StoreSmsChart';
import CartChart from '../Charts/CartChart';
import {payloadFromSubscription} from '../../Utility';
import ReactSelect from 'react-select';
import {connect} from 'react-redux';
import {
  customerActions,
  businessActions
} from "../../projectNulla/redux-modules/actions";
import { DatePicker } from '../../projectNulla/catalogs/Globals';
const applicationServerPublicKey = "BLHxWiNVmr7ROB8O3KpPRJFAMhMypwe4X9TdWMmhsPSzHszo32PDkndpvWx3H0OY2HwFCQRU98JBpZ_AEsVxWG4"

const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')


function Dashboard(props) {
    const { account, permissions, customerStatistics, businessSelect, businessCount } = props;
    const [subscription, setSubscription] = useState({
        endpoint: `${config.API}subscription/save-subscription`
    })
    const selectRef = useRef([]);
    const [selectedBusiness, setSelectedBusines] = useState([])
    const [closeOnSelectBusiness, setCloseOnSelectBusiness] = useState(false)
    const [page, setPage] = useState(1)
    const [card1IsOpen, setCard1IsOpen] = useState(false)
    const [card2IsOpen, setCard2IsOpen] = useState(false)
    const [card3IsOpen, setCard3IsOpen] = useState(false)
    const [card4IsOpen, setCard4IsOpen] = useState(false)

    const subscriveUserEnabled = true

    const onSubscriptionFailed = (error) => {
        console.log("onSubscriptionFailed:", error)
    }


    const getClientLanguage = () => {
        let Locale = navigator.language || navigator.userLanguage;
        if (Locale.includes('-')) Locale = Locale.split('-')[0];
        return Locale.toLowerCase();
    };

    const genericFormattedDate = (DateToFormat) => {
        const UserLanguage = getClientLanguage();
        const Config = { year: 'numeric', month: 'long', hour: '2-digit', minute:'2-digit', day: '2-digit' };
        return new Date(DateToFormat).toLocaleString(UserLanguage, Config);
    };

    const cardChartData1 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: brandPrimary,
                borderColor: 'rgba(255,255,255,.55)',
                data: customerStatistics.data.map(d => d.count),
            },
        ],
    }

    const cardChartOpts1 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
            {
                gridLines: {
                    color: 'transparent',
                    zeroLineColor: 'transparent',
                },
                ticks: {
                    fontSize: 2,
                    fontColor: 'transparent',
                },
        
            }],
            yAxes: [
                {
                    display: false,
                    ticks: {
                        display: false,
                        min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
                        max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
                    },
                }
            ],
        },
        elements: {
            line: {
                borderWidth: 1,
            },
            point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
            },
        }
    }

    // Card Chart 2
    const cardChartData2 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: brandInfo,
                borderColor: 'rgba(255,255,255,.55)',
                data: customerStatistics.data.map(d => d.ordercount)
            },
        ],
    };

    const cardChartOpts2 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
            {
                gridLines: {
                    color: 'transparent',
                    zeroLineColor: 'transparent',
                },
                ticks: {
                    fontSize: 2,
                    fontColor: 'transparent',
                },

            }],
            yAxes: [
            {
                display: false,
                ticks: {
                    display: false,
                    min: Math.min.apply(Math, cardChartData2.datasets[0].data) - 5,
                    max: Math.max.apply(Math, cardChartData2.datasets[0].data) + 5,
                },
            }],
        },
        elements: {
            line: {
                tension: 0.00001,
                borderWidth: 1,
            },
            point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
            },
        },
    };

    // Card Chart 3
    const cardChartData3 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(255,255,255,.2)',
                borderColor: 'rgba(255,255,255,.55)',
                data: customerStatistics.data.filter(d => !!(parseInt(d.donecount) - parseInt(d.uniquecount))).map(d => parseInt(d.donecount) - parseInt(d.uniquecount))
            },
        ],
    };
    
    const cardChartOpts3 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    display: false,
                }
            ],
            yAxes: [
                {
                    display: false,
                }
            ],
        },
        elements: {
            line: {
                borderWidth: 2,
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
            },
        },
    };

    // Card Chart 4
    const cardChartData4 = {
        labels: customerStatistics.data.map(d => genericFormattedDate(d.date)),
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: 'rgba(255,255,255,.3)',
                borderColor: 'transparent',
                data: customerStatistics.data.filter(d => parseInt(d.donecount)).map(d => d.donecount),
            },
        ],
    };

    const cardChartOpts4 = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
            {
                display: false,
                barPercentage: 0.6,
            }],
            yAxes: [
            {
                display: false,
            }],
        },
    };

    // Social Box Chart
    const socialBoxData = [
        { data: [65, 59, 84, 84, 51, 55, 40], label: 'facebook' },
        { data: [1, 13, 9, 17, 34, 41, 38], label: 'twitter' },
        { data: [78, 81, 80, 45, 34, 12, 40], label: 'linkedin' },
        { data: [35, 23, 56, 22, 97, 23, 64], label: 'google' },
    ];

    const makeSocialBoxData = (dataSetNo) => {
        const dataset = socialBoxData[dataSetNo];
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
            {
                backgroundColor: 'rgba(255,255,255,.1)',
                borderColor: 'rgba(255,255,255,.55)',
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: dataset.data,
                label: dataset.label,
            },
            ],
        };
        return () => data;
    };

    const socialChartOpts = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
            {
                display: false,
            }],
            yAxes: [
            {
                display: false,
            }],
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            },
        },
    };

    // sparkline charts
    const sparkLineChartData = [
        {
            data: [35, 23, 56, 22, 97, 23, 64],
            label: 'New Clients',
        },
        {
            data: [65, 59, 84, 84, 51, 55, 40],
            label: 'Recurring Clients',
        },
        {
            data: [35, 23, 56, 22, 97, 23, 64],
            label: 'Pageviews',
        },
        {
            data: [65, 59, 84, 84, 51, 55, 40],
            label: 'Organic',
        },
        {
            data: [78, 81, 80, 45, 34, 12, 40],
            label: 'CTR',
        },
        {
            data: [1, 13, 9, 17, 34, 41, 38],
            label: 'Bounce Rate',
        },
    ];

    const makeSparkLineData = (dataSetNo, variant) => {
        const dataset = sparkLineChartData[dataSetNo];
        const data = {
            labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                datasets: [
                {
                    backgroundColor: 'transparent',
                    borderColor: variant ? variant : '#c2cfd6',
                    data: dataset.data,
                    label: dataset.label,
                },
            ],
        };
        return () => data;
    };

    const sparklineChartOpts = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            xAxes: [
            {
                display: false,
            }],
            yAxes: [
            {
                display: false,
            }],
        },
        elements: {
            line: {
                borderWidth: 2,
            },
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            },
        },
        legend: {
            display: false,
        },
    };

    //Random Numbers
    function random(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    var elements = 27;
    var data1 = [];
    var data2 = [];
    var data3 = [];

    for (var i = 0; i <= elements; i++) {
        data1.push(random(50, 200));
        data2.push(random(80, 100));
        data3.push(65);
    }

    const [date, setDate] = useState({ startDate: '', endDate: '' })
    const [radioSelected, setRadioSelected] = useState('week');

    function handleChangeDate (event, key) {
        setDate({
            ...date,
            [key]: event.target.value
        })
    }

    useEffect(() => {
        props.getCustomerStatistics(account.token, {
            date: radioSelected,
            startDate: date.startDate,
            endDate: date.endDate,
            businessIds: selectedBusiness.map(({value}) => value) 
        })
    }, [radioSelected, date, selectedBusiness, selectedBusiness])

    const onUpdateSubscriptionOnServer = (subscription) => {
        const payload = payloadFromSubscription(subscription)
        if (localStorage.getItem('account')) {
            const SERVER_URL = `${config.API}subscription/save-subscription`;
            fetch(SERVER_URL, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem('account')).token
                },
                body: JSON.stringify(payload)
            });
        }
        setSubscription(subscription)
    }

    function onRadioBtnClick(value) {
        setRadioSelected(value)
    }


    function openMenu(index) {
        if (index == 0) {
            if (!businessSelect.length) {
                props.fetchBusinessSeeAll(account.token, 1, 10);  
            }
        }
        selectRef.current[index].focus()
    }

    const handleInputChangeBusiness = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        props.fetchBusinessSeeAll(account.token, 1, 10, { name: inputValue })
    };

    const selectBusiness = (selectedOption) => {
        let selectedOptions = []
        if (selectedOption.length) {
            selectedOption.forEach(elem => {
                if (elem.value === 'all') {
                    let businessPage = page + 1
                    setPage(businessPage)
                    setCloseOnSelectBusiness(false)
                    props.fetchBusinessSeeAll(account.token, businessPage, 10)
                }  else {
                    selectedOptions.push(elem)
                }
            })
        }
        setSelectedBusines(selectedOptions)
    }

    return (
        <div className="animated fadeIn">       
            <WebPush
                subscriveUserEnabled={subscriveUserEnabled}
                applicationServerPublicKey={applicationServerPublicKey}
                onSubscriptionFailed={onSubscriptionFailed}
                onUpdateSubscriptionOnServer={onUpdateSubscriptionOnServer}
            />
            {
                !!permissions.find(({value}) => value == 'customer/statistics') &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="3">
                                        <DatePicker
                                            handleChange={handleChangeDate}
                                            value={{
                                                startDate: date.startDate,
                                                endDate: date.endDate 
                                            }}
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <div style={{width: "200px"}}>
                                            <ReactSelect
                                                ref={el => selectRef.current[0] = el}
                                                onFocus={() => openMenu(0)}
                                                closeOnSelect = {closeOnSelectBusiness}
                                                name="form-field-name"
                                                value={selectedBusiness}
                                                onInputChange={handleInputChangeBusiness}
                                                multi = {true}
                                                onChange={selectBusiness}
                                                options={
                                                    businessCount > businessSelect.length 
                                                    ? [...businessSelect, { value: 'all', label: 'See More'}]
                                                    : [...businessSelect]
                                                }
                                                placeholder={<div>Business</div>}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            {
                !!permissions.find(({value}) => value == 'customer/statistics') &&
                <Row>
                    <Col xs="12" sm="6" lg="3">
                        <Card className="text-white bg-primary">
                            <CardBody className="pb-0">
                                <ButtonGroup className="float-right">
                                    <ButtonDropdown 
                                        id='card1' 
                                        isOpen={card1IsOpen} 
                                        toggle={() => setCard1IsOpen(!card1IsOpen)}
                                    >
                                        <DropdownToggle caret className="p-0" color="transparent">
                                        <i className="icon-settings"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                                <div className="text-value">{customerStatistics.data.map(item => item.count).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                <div>Users</div>
                            </CardBody>
                            <div className="chart-wrapper mx-3" style={{ height: '70px' }}>
                                <Line data={cardChartData1} options={cardChartOpts1} height={70} />
                            </div>
                        </Card>
                    </Col>
                    
                    <Col xs="12" sm="6" lg="3">
                        <Card className="text-white bg-info">
                            <CardBody className="pb-0">
                                <ButtonGroup className="float-right">
                                    <ButtonDropdown 
                                        id='card2' 
                                        isOpen={card2IsOpen} 
                                        toggle={() => setCard2IsOpen(!card2IsOpen)}
                                    >
                                        <DropdownToggle caret className="p-0" color="transparent">
                                        <i className="icon-settings"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                        <DropdownItem onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                                <div className="text-value">{customerStatistics.data.filter(item => parseInt(item.ordercount)).map(item => item.ordercount).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                <div>Users order</div>
                            </CardBody>
                            <div className="chart-wrapper mx-3" style={{ height: '70px' }}>
                                <Line data={cardChartData2} options={cardChartOpts2} height={70} />
                            </div>
                        </Card>
                    </Col>
            
                    <Col xs="12" sm="6" lg="3">
                        <Card className="text-white bg-warning">
                            <CardBody className="pb-0">
                                <ButtonGroup className="float-right">
                                    <ButtonDropdown 
                                        id='card3' 
                                        isOpen={card3IsOpen} 
                                        toggle={() => setCard3IsOpen(!card3IsOpen)}
                                    >
                                        <DropdownToggle caret className="p-0" color="transparent">
                                        <i className="icon-settings"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                        <DropdownItem onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</DropdownItem>
                                            <DropdownItem onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                                <div className="text-value">{customerStatistics.data.filter(item => (parseInt(item.donecount) - parseInt(item.uniquecount))).map(item => parseInt(item.donecount) - parseInt(item.uniquecount)).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                <div>Returning users</div>
                            </CardBody>
                            <div className="chart-wrapper" style={{ height: '70px' }}>
                                <Line data={cardChartData3} options={cardChartOpts3} height={70} />
                            </div>
                        </Card>
                    </Col>
            
                    <Col xs="12" sm="6" lg="3">
                        <Card className="text-white bg-danger">
                            <CardBody className="pb-0">
                                <ButtonGroup className="float-right">
                                    <ButtonDropdown 
                                        id='card4' 
                                        isOpen={card4IsOpen} 
                                        toggle={() => setCard4IsOpen(!card4IsOpen)}
                                    >
                                        <DropdownToggle caret className="p-0" color="transparent">
                                        <i className="icon-settings"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                        <DropdownItem>Action</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                                <div className="text-value">{customerStatistics.data.filter(item => parseInt(item.donecount)).map(item => item.donecount).reduce((prev, next) => parseInt(prev) + parseInt(next), 0)}</div>
                                <div>Users done orders</div>
                            </CardBody>
                            <div className="chart-wrapper mx-3" style={{ height: '70px' }}>
                                <Bar data={cardChartData4} options={cardChartOpts4} height={70} />
                            </div>
                        </Card>
                    </Col>
                </Row>
            }

            <Row>
                <Col>
                    {
                        !!permissions.find(({value}) => value == 'traffic/statistics') &&
                        <TrafficChart/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        !!permissions.find(({value}) => value == 'call/statistics') &&
                        <CallChart/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        !!permissions.find(({value}) => value == 'sale/order/statistics') &&
                        <OrderChart/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        !!permissions.find(({value}) => value == 'sale/order/statistics') &&
                        <ProductChart businessStatistics={!!permissions.find(({value}) => value == 'business/statistics')}/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* {
                        !!permissions.find(({value}) => value == 'sale/order/statistics') &&
                        <OrderMap/>
                    } */}
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        !!permissions.find(({value}) => value == 'sms/sms/statistics') &&
                        <SmsChart/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        !!permissions.find(({value}) => value == 'call/sms/statistics') &&
                        <StoreSmsChart/>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        !!permissions.find(({value}) => value == 'cart/statistics') &&
                        <CartChart/>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs="6" sm="6" lg="3">
                    <Widget03 dataBox={() => ({ variant: 'facebook', friends: '89k', feeds: '459' })} >
                        <div className="chart-wrapper">
                            <Line data={makeSocialBoxData(0)} options={socialChartOpts} height={90} />
                        </div>
                    </Widget03>
                </Col>
    
                <Col xs="6" sm="6" lg="3">
                    <div className="brand-card">
                        <div className="brand-card-header bg-twitter">
                            <i className="fa fa-twitter"></i>
                            <div className="chart-wrapper">
                            <Line data={makeSocialBoxData(1)} options={socialChartOpts} height={90} />
                            </div>
                        </div>
                        <div className="brand-card-body">
                            <div>
                            <div className="text-value">973k</div>
                            <div className="text-uppercase text-muted small">followers</div>
                            </div>
                            <div>
                            <div className="text-value">1.792</div>
                            <div className="text-uppercase text-muted small">tweets</div>
                            </div>
                        </div>
                    </div>
                </Col>
    
                <Col xs="6" sm="6" lg="3">
                    <div className="brand-card">
                        <div className="brand-card-header bg-linkedin">
                            <i className="fa fa-linkedin"></i>
                            <div className="chart-wrapper">
                            <Line data={makeSocialBoxData(2)} options={socialChartOpts} height={90} />
                            </div>
                        </div>
                        <div className="brand-card-body">
                            <div>
                            <div className="text-value">500+</div>
                            <div className="text-uppercase text-muted small">contacts</div>
                            </div>
                            <div>
                            <div className="text-value">292</div>
                            <div className="text-uppercase text-muted small">feeds</div>
                            </div>
                        </div>
                    </div>
                </Col>
    
                <Col xs="6" sm="6" lg="3">
                    <div className="brand-card">
                        <div className="brand-card-header bg-google-plus">
                            <i className="fa fa-google-plus"></i>
                            <div className="chart-wrapper">
                            <Line data={makeSocialBoxData(3)} options={socialChartOpts} height={90} />
                            </div>
                        </div>
                        <div className="brand-card-body">
                            <div>
                            <div className="text-value">894</div>
                            <div className="text-uppercase text-muted small">followers</div>
                            </div>
                            <div>
                            <div className="text-value">92</div>
                            <div className="text-uppercase text-muted small">circles</div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
    
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    Traffic {' & '} Sales
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs="12" md="6" xl="6">
                        <Row>
                          <Col sm="6">
                            <div className="callout callout-info">
                              <small className="text-muted">New Clients</small>
                              <br />
                              <strong className="h4">9,123</strong>
                              <div className="chart-wrapper">
                                <Line data={makeSparkLineData(0, brandPrimary)} options={sparklineChartOpts} width={100} height={30} />
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="callout callout-danger">
                              <small className="text-muted">Recurring Clients</small>
                              <br />
                              <strong className="h4">22,643</strong>
                              <div className="chart-wrapper">
                                <Line data={makeSparkLineData(1, brandDanger)} options={sparklineChartOpts} width={100} height={30} />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <hr className="mt-0" />
                        <div className="progress-group mb-4">
                          <div className="progress-group-prepend">
                            <span className="progress-group-text">
                              Monday
                            </span>
                          </div>
                          <div className="progress-group-bars">
                            <Progress className="progress-xs" color="info" value="34" />
                            <Progress className="progress-xs" color="danger" value="78" />
                          </div>
                        </div>
                        <div className="progress-group mb-4">
                          <div className="progress-group-prepend">
                            <span className="progress-group-text">
                            Tuesday
                            </span>
                          </div>
                          <div className="progress-group-bars">
                            <Progress className="progress-xs" color="info" value="56" />
                            <Progress className="progress-xs" color="danger" value="94" />
                          </div>
                        </div>
                        <div className="progress-group mb-4">
                          <div className="progress-group-prepend">
                            <span className="progress-group-text">
                            Wednesday
                            </span>
                          </div>
                          <div className="progress-group-bars">
                            <Progress className="progress-xs" color="info" value="12" />
                            <Progress className="progress-xs" color="danger" value="67" />
                          </div>
                        </div>
                        <div className="progress-group mb-4">
                          <div className="progress-group-prepend">
                            <span className="progress-group-text">
                            Thursday
                            </span>
                          </div>
                          <div className="progress-group-bars">
                            <Progress className="progress-xs" color="info" value="43" />
                            <Progress className="progress-xs" color="danger" value="91" />
                          </div>
                        </div>
                        <div className="progress-group mb-4">
                          <div className="progress-group-prepend">
                            <span className="progress-group-text">
                            Friday
                            </span>
                          </div>
                          <div className="progress-group-bars">
                            <Progress className="progress-xs" color="info" value="22" />
                            <Progress className="progress-xs" color="danger" value="73" />
                          </div>
                        </div>
                        <div className="progress-group mb-4">
                          <div className="progress-group-prepend">
                            <span className="progress-group-text">
                            Saturday
                            </span>
                          </div>
                          <div className="progress-group-bars">
                            <Progress className="progress-xs" color="info" value="53" />
                            <Progress className="progress-xs" color="danger" value="82" />
                          </div>
                        </div>
                        <div className="progress-group mb-4">
                          <div className="progress-group-prepend">
                            <span className="progress-group-text">
                            Sunday
                            </span>
                          </div>
                          <div className="progress-group-bars">
                            <Progress className="progress-xs" color="info" value="9" />
                            <Progress className="progress-xs" color="danger" value="69" />
                          </div>
                        </div>
                        <div className="legend text-center">
                          <small>
                            <sup className="px-1"><Badge pill color="info">&nbsp;</Badge></sup>
                            New clients
                            &nbsp;
                            <sup className="px-1"><Badge pill color="danger">&nbsp;</Badge></sup>
                            Recurring clients
                          </small>
                        </div>
                      </Col>
                      <Col xs="12" md="6" xl="6">
                        <Row>
                          <Col sm="6">
                            <div className="callout callout-warning">
                              <small className="text-muted">Pageviews</small>
                              <br />
                              <strong className="h4">78,623</strong>
                              <div className="chart-wrapper">
                                <Line data={makeSparkLineData(2, brandWarning)} options={sparklineChartOpts} width={100} height={30} />
                              </div>
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="callout callout-success">
                              <small className="text-muted">Organic</small>
                              <br />
                              <strong className="h4">49,123</strong>
                              <div className="chart-wrapper">
                                <Line data={makeSparkLineData(3, brandSuccess)} options={sparklineChartOpts} width={100} height={30} />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <hr className="mt-0" />
                        <ul>
                          <div className="progress-group">
                            <div className="progress-group-header">
                              <i className="icon-user progress-group-icon"></i>
                              <span className="title">Male</span>
                              <span className="ml-auto font-weight-bold">43%</span>
                            </div>
                            <div className="progress-group-bars">
                              <Progress className="progress-xs" color="warning" value="43" />
                            </div>
                          </div>
                          <div className="progress-group mb-5">
                            <div className="progress-group-header">
                              <i className="icon-user-female progress-group-icon"></i>
                              <span className="title">Female</span>
                              <span className="ml-auto font-weight-bold">37%</span>
                            </div>
                            <div className="progress-group-bars">
                              <Progress className="progress-xs" color="warning" value="37" />
                            </div>
                          </div>
                          <div className="progress-group">
                            <div className="progress-group-header">
                              <i className="icon-globe progress-group-icon"></i>
                              <span className="title">Organic Search</span>
                              <span className="ml-auto font-weight-bold">191,235 <span className="text-muted small">(56%)</span></span>
                            </div>
                            <div className="progress-group-bars">
                              <Progress className="progress-xs" color="success" value="56" />
                            </div>
                          </div>
                          <div className="progress-group">
                            <div className="progress-group-header">
                              <i className="icon-social-facebook progress-group-icon"></i>
                              <span className="title">Facebook</span>
                              <span className="ml-auto font-weight-bold">51,223 <span className="text-muted small">(15%)</span></span>
                            </div>
                            <div className="progress-group-bars">
                              <Progress className="progress-xs" color="success" value="15" />
                            </div>
                          </div>
                          <div className="progress-group">
                            <div className="progress-group-header">
                              <i className="icon-social-twitter progress-group-icon"></i>
                              <span className="title">Twitter</span>
                              <span className="ml-auto font-weight-bold">37,564 <span className="text-muted small">(11%)</span></span>
                            </div>
                            <div className="progress-group-bars">
                              <Progress className="progress-xs" color="success" value="11" />
                            </div>
                          </div>
                          <div className="progress-group">
                            <div className="progress-group-header">
                              <i className="icon-social-linkedin progress-group-icon"></i>
                              <span className="title">LinkedIn</span>
                              <span className="ml-auto font-weight-bold">27,319 <span className="text-muted small">(8%)</span></span>
                            </div>
                            <div className="progress-group-bars">
                              <Progress className="progress-xs" color="success" value="8" />
                            </div>
                          </div>
                          <div className="divider text-center">
                            <Button color="link" size="sm" className="text-muted" data-toggle="tooltip" data-placement="top"
                                    title="" data-original-title="show more"><i className="icon-options"></i></Button>
                          </div>
                        </ul>
                      </Col>
                    </Row>
                    <br />
                    <Table hover responsive className="table-outline mb-0 d-none d-sm-table">
                      <thead className="thead-light">
                      <tr>
                        <th className="text-center"><i className="icon-people"></i></th>
                        <th>User</th>
                        <th className="text-center">Country</th>
                        <th>Usage</th>
                        <th className="text-center">Payment Method</th>
                        <th>Activity</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td className="text-center">
                          <div className="avatar">
                            <img src={'assets/img/avatars/1.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                            <span className="avatar-status badge-success"></span>
                          </div>
                        </td>
                        <td>
                          <div>Yiorgos Avraamu</div>
                          <div className="small text-muted">
                            <span>New</span> | Registered: Jan 1, 2015
                          </div>
                        </td>
                        <td className="text-center">
                          <i className="flag-icon flag-icon-us h4 mb-0" title="us" id="us"></i>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>50%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                            </div>
                          </div>
                          <Progress className="progress-xs" color="success" value="50" />
                        </td>
                        <td className="text-center">
                          <i className="fa fa-cc-mastercard" style={{ fontSize: 24 + 'px' }}></i>
                        </td>
                        <td>
                          <div className="small text-muted">Last login</div>
                          <strong>10 sec ago</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="avatar">
                            <img src={'assets/img/avatars/2.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                            <span className="avatar-status badge-danger"></span>
                          </div>
                        </td>
                        <td>
                          <div>Avram Tarasios</div>
                          <div className="small text-muted">
    
                            <span>Recurring</span> | Registered: Jan 1, 2015
                          </div>
                        </td>
                        <td className="text-center">
                          <i className="flag-icon flag-icon-br h4 mb-0" title="br" id="br"></i>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>10%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                            </div>
                          </div>
                          <Progress className="progress-xs" color="info" value="10" />
                        </td>
                        <td className="text-center">
                          <i className="fa fa-cc-visa" style={{ fontSize: 24 + 'px' }}></i>
                        </td>
                        <td>
                          <div className="small text-muted">Last login</div>
                          <strong>5 minutes ago</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="avatar">
                            <img src={'assets/img/avatars/3.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                            <span className="avatar-status badge-warning"></span>
                          </div>
                        </td>
                        <td>
                          <div>Quintin Ed</div>
                          <div className="small text-muted">
                            <span>New</span> | Registered: Jan 1, 2015
                          </div>
                        </td>
                        <td className="text-center">
                          <i className="flag-icon flag-icon-in h4 mb-0" title="in" id="in"></i>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>74%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                            </div>
                          </div>
                          <Progress className="progress-xs" color="warning" value="74" />
                        </td>
                        <td className="text-center">
                          <i className="fa fa-cc-stripe" style={{ fontSize: 24 + 'px' }}></i>
                        </td>
                        <td>
                          <div className="small text-muted">Last login</div>
                          <strong>1 hour ago</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="avatar">
                            <img src={'assets/img/avatars/4.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                            <span className="avatar-status badge-secondary"></span>
                          </div>
                        </td>
                        <td>
                          <div>Enéas Kwadwo</div>
                          <div className="small text-muted">
                            <span>New</span> | Registered: Jan 1, 2015
                          </div>
                        </td>
                        <td className="text-center">
                          <i className="flag-icon flag-icon-fr h4 mb-0" title="fr" id="fr"></i>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>98%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                            </div>
                          </div>
                          <Progress className="progress-xs" color="danger" value="98" />
                        </td>
                        <td className="text-center">
                          <i className="fa fa-paypal" style={{ fontSize: 24 + 'px' }}></i>
                        </td>
                        <td>
                          <div className="small text-muted">Last login</div>
                          <strong>Last month</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="avatar">
                            <img src={'assets/img/avatars/5.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                            <span className="avatar-status badge-success"></span>
                          </div>
                        </td>
                        <td>
                          <div>Agapetus Tadeáš</div>
                          <div className="small text-muted">
                            <span>New</span> | Registered: Jan 1, 2015
                          </div>
                        </td>
                        <td className="text-center">
                          <i className="flag-icon flag-icon-es h4 mb-0" title="es" id="es"></i>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>22%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                            </div>
                          </div>
                          <Progress className="progress-xs" color="info" value="22" />
                        </td>
                        <td className="text-center">
                          <i className="fa fa-google-wallet" style={{ fontSize: 24 + 'px' }}></i>
                        </td>
                        <td>
                          <div className="small text-muted">Last login</div>
                          <strong>Last week</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="avatar">
                            <img src={'assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
                            <span className="avatar-status badge-danger"></span>
                          </div>
                        </td>
                        <td>
                          <div>Friderik Dávid</div>
                          <div className="small text-muted">
                            <span>New</span> | Registered: Jan 1, 2015
                          </div>
                        </td>
                        <td className="text-center">
                          <i className="flag-icon flag-icon-pl h4 mb-0" title="pl" id="pl"></i>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>43%</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                            </div>
                          </div>
                          <Progress className="progress-xs" color="success" value="43" />
                        </td>
                        <td className="text-center">
                          <i className="fa fa-cc-amex" style={{ fontSize: 24 + 'px' }}></i>
                        </td>
                        <td>
                          <div className="small text-muted">Last login</div>
                          <strong>Yesterday</strong>
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
        permissions: state.account.userGroup.permissions,
        customerStatistics: state.customers.statistics,
        businessCount: state.business.count,
        businessSelect: state.business.businessSelect
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerStatistics: (jwt, query) => dispatch(customerActions.getStatistics(jwt, query)), 
        fetchBusinessSeeAll: (jwt, page, count, query, search) => dispatch(businessActions.seeAll(jwt, page, count, query, search)) 
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
