import {FRANCHISE_TYPES} from '../../types/index'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const defaultState = {
  pagRemovedFranchise: [],
  pagNotRemovedFranchise: [],
  pagRemovedFranchiseCount: 0,
  pagNotRemovedFranchiseCount: 0,
  selectedItem: {},
  franchiseSelect: [],
  tabPanel: localStorage.getItem('franchiseActiveTab') || '1',
  initialFilter: {
    name: '',
    email: '',
    phone: '',
    businesType: '',
    licenseType: '',
    status: null,
    dates:  '',
    minPrice: '',
    maxPrice: ''
  },
  dataFilter: {
    franchiseId: null,
    nameF: '',
    emailF: '',
    phoneF: '',
    businesTypeF: '',
    licenseTypeF: '',
    statusF: null,
    dateAdded:  '',
    sortorder: null
  },
  minMaxValues: {},
  isOpen: isMobile ? false : localStorage.getItem('franchiseFilter') == 'true' ? true : false,
  licenseTypesSelect: [],
  businesTypesSelect: [],
  orders: [],
  statistic: {
    data: []
  },
};

const franchises = ( state = defaultState, action = {}) => {
  switch (action.type) {

      case FRANCHISE_TYPES.GET_PAG_REMOVED_FRANCHISES: {

          return {
            ...state,
            pagRemovedFranchise: action.payload.data,
            pagRemovedFranchiseCount: action.payload.count
          }

      }

      case FRANCHISE_TYPES.GET_PAG_NOT_REMOVED_FRANCHISES: {
          return {
            ...state,
            pagNotRemovedFranchise: action.payload.data,
            pagNotRemovedFranchiseCount: action.payload.count
          }

      }

      case FRANCHISE_TYPES.DELETE_FRANCHISE_ITEMS: {

          const id = action.payload[0];

          return {
            ...state,
            pagNotRemovedFranchise: state.pagNotRemovedFranchise.filter(item => item.franchiseId !== id)
          }

      }

      case FRANCHISE_TYPES.SELECT_FRANCHISE_ITEMS: {

        return {
          ...state,
          selectedItem: action.payload
        }

    }

      case FRANCHISE_TYPES.RECOVER_FRANCHISE_ITEMS: {
          return {
              ...state
            }

      }

      case FRANCHISE_TYPES.ARCHIVE_FRANCHISE_ITEMS: {
        return { 
            ...state
          }
      }

      case FRANCHISE_TYPES.BULK_RECOVER_FRANCHISE_ITEMS: {

        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagRemovedFranchiseCount: state.pagRemovedFranchiseCount - action.payload.length
        }
      }

      case FRANCHISE_TYPES.FETCH_FRANCHISE_SEE_ALL_P: {

        return { 
            ...state,
            franchiseLoad: true,
        }
      }

      case FRANCHISE_TYPES.FETCH_FRANCHISE_SEE_ALL_F: {
          const newFranchise = []
          action.payload.data.forEach(franchise => {
            if (franchise.name && franchise.descriptions[0] && franchise.descriptions[0].name) {
              newFranchise.push({ 
                value: franchise.franchiseId, 
                label: franchise.descriptions[0].name 
              })
            }
          })

          let franchiseSelect = action.search ? newFranchise : [...state.franchiseSelect, ...newFranchise]
          franchiseSelect = franchiseSelect.filter((elem, index, self) =>
            index === self.findIndex((e) => (
              e.value === elem.value
            ))
          )
    
          return {
              ...state,
              franchiseSelect: franchiseSelect,
              pagNotRemovedCount: action.payload.count,
              franchiseLoad: false
          }
      }

      case FRANCHISE_TYPES.FETCH_FRANCHISE_SEE_ALL_R: {

        return { 
            ...state,
            franchiseLoad: false,
        }
      }

      case FRANCHISE_TYPES.BULK_DESTROY_FRANCHISE_ITEMS: {
        const id = action.payload[0];
        return {
            ...state,
            pagRemovedFranchise: state.pagRemovedFranchise.filter(item => item.franchiseId !== id),
        }
      }


    case FRANCHISE_TYPES.FRANCHISE_TAB_PANEL_CHANGE: {
      return {
          ...state,
          tabPanel: action.payload,
          loading: false
      }
    }

    case FRANCHISE_TYPES.FRANCHISE_FILTER_IS_OPEN: {
      localStorage.setItem('franchiseFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case FRANCHISE_TYPES.FRANCHISE_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case FRANCHISE_TYPES.FRANCHISE_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case FRANCHISE_TYPES.FRANCHISE_DATA_MIN_MAX_F: {
      return {
          ...state,
          minMaxValues: action.payload
      }
    }

    case FRANCHISE_TYPES.FRANCHISE_SELECT_FILTER_TYPES: {
      function getUnique(array, key) {
        return array.reduce((acc, cur) => [
          ...acc.filter((obj) => obj[key] !== cur[key]), cur
        ], [])
      }

      return {
        ...state,
        licenseTypesSelect: getUnique(action.payload, 'licenseType'),
        businesTypesSelect: getUnique(action.payload, 'type')
      }
    }


    case FRANCHISE_TYPES.FRANCHISE_DATA_FILTER_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: Object.assign({}, state.initialFilter, { name: "" })
      }
    }

    case FRANCHISE_TYPES.FRANCHISE_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: { 
          name: '',
          email: '',
          phone: '',
          businesType: '',
          licenseType: '',
          status: null,
          dates:  '',
          dateTo: '',
          dateFrom: ''
        }
      }
    }

    case FRANCHISE_TYPES.GET_FRANCHISE_ORDER_STATISTICS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case FRANCHISE_TYPES.GET_FRANCHISE_ORDER_STATISTICS_F: {
      return {
        ...state,
        orders: action.payload.data
      }
    }

    case FRANCHISE_TYPES.GET_FRANCHISE_ORDER_STATISTICS_R: {
      return {
        ...state,
        error: action.payload.data
      }
    }

    case FRANCHISE_TYPES.GET_FRANCHISE_ORDER_ALL_STATISTICS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case FRANCHISE_TYPES.GET_FRANCHISE_ORDER_ALL_STATISTICS_F: {
      return {
        ...state,
        statistic: action.payload
      }
    }

    case FRANCHISE_TYPES.GET_FRANCHISE_ORDER_ALL_STATISTICS_R: {
      return {
        ...state,
        error: action.payload.data
      }
    }
  
    default:
            return state;
  }
}
export {
  franchises
}
