import { TICKET_TYPES } from '../../types'

const initialState = {
  data: [],
  callsCount: [],
  count: 0,
  locafing: false,
  webRTCall: null,
  twilioDevice: null
};

const tickets = ( state = initialState, action = {}) => {

  switch (action.type) {

    case 'SET_TELNYX_CLIENT_RTC': {
      return {
        ...state,
        webRTCall: action.payload
      }
    }

    case TICKET_TYPES.SET_TWILIO_DEVICE: {
      return {
        ...state,
        twilioDevice: action.payload
      }
    }

    case TICKET_TYPES.TICKET_PAG_P: {
        return {
          ...state,
          loading: true
        }
    }

    case TICKET_TYPES.TICKET_PAG_F: {
        return {
          ...state,
          data: action.payload.data,
          count: action.payload.count,
          loading: false
        }
    }

    case TICKET_TYPES.TICKET_PAG_R: {
        return {
          ...state,
          loading: false
        }
    }

    case TICKET_TYPES.ADD_TICKET_P: {
      return {
        ...state,
        loading: true
      }
  }

  case TICKET_TYPES.ADD_TICKET_F: {
      return {
        ...state,
        data: [action.payload, ...state.data],
        count: state.count + 1
      }
  }

  case TICKET_TYPES.ADD_TICKET_R: {
      return {
        ...state,
        loading: false
      }
  }

  case TICKET_TYPES.CALL_COUNTS_P: {
    return {
      ...state,
      loading: true
    }
  }

  case TICKET_TYPES.CALL_COUNTS_F: {
      return {
        ...state,
        callsCount: action.payload,
        loading: false
      }
  }

  case TICKET_TYPES.CALL_COUNTS_R: {
      return {
        ...state,
        loading: false
      }
  }

    default:
            return state;
  }
}


export {
  tickets
}

