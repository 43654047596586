import { ATTRIBUTE_TYPES} from '../../types/index'


const defaultState = {
  pagRemovedAttribute: [],
  pagNotRemovedAttribute: [],
  pagRemovedAttributeCount: 0,
  pagNotRemovedAttributeCount: 0,
};

const allAttribute = (state = [], action = {}) => {
  if (action.type === ATTRIBUTE_TYPES.GET_ALL_ATTRIBUTE) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedAttribute = (state = [], action = {}) => {
  if (action.type === ATTRIBUTE_TYPES.GET_ALL_REMOVED_ATTRIBUTE) {
    return action.payload;
  } else {
    return state
  }
};


const allNotRemovedAttribute = (state = [], action = {}) => {
  if (action.type === ATTRIBUTE_TYPES.GET_ALL_NOT_REMOVED_ATTRIBUTE) {
    return action.payload;
  } else {
    return state
  }
};

const attribute = (state = {}, action = {}) => {
  if (action.type === ATTRIBUTE_TYPES.GET_ATTRIBUTE_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedAttribute = (state = {}, action = {}) => {
  if (action.type === ATTRIBUTE_TYPES.SELECT_ATTRIBUTE_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const attributes = ( state = defaultState, action ={}) => {
  switch (action.type) {
      case ATTRIBUTE_TYPES.GET_PAG_REMOVED_ATTRIBUTES: {
          return {
            ...state,
            pagRemovedAttribute: action.payload.data,
            pagRemovedAttributeCount: action.payload.count

          }
      }

      case ATTRIBUTE_TYPES.GET_PAG_NOT_REMOVED_ATTRIBUTES: {
          return {
            ...state,
            pagNotRemovedAttribute: action.payload.data,
            pagNotRemovedAttributeCount: action.payload.count
          }
      }

      case ATTRIBUTE_TYPES.DELETE_ATTRIBUTE_ITEMS: {
        const id = action.payload.attributeId;
        return {
            ...state,
            pagNotRemovedAttribute: state.pagNotRemovedAttribute.filter(item => item.attributeId !== id)
        }
      }

      case ATTRIBUTE_TYPES.RECOVER_ATTRIBUTE_ITEMS: {
        const id = action.payload.attributeId;
        state.pagNotRemovedAttribute.unshift(action.payload)
        return {
            ...state,
            pagRemovedAttribute: state.pagRemovedAttribute.filter(item => item.attributeId !== id),
            pagNotRemovedAttribute: state.pagNotRemovedAttribute,
            pagNotRemovedAttributeCount: state.pagNotRemovedAttributeCount + 1
        }
      }
      case ATTRIBUTE_TYPES.ARCHIVE_ATTRIBUTE_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagNotRemovedAttributeCount: state.pagNotRemovedAttributeCount - action.payload.length
        }
      }

      case ATTRIBUTE_TYPES.BULK_RECOVER_ATTRIBUTE_ITEMS: {
          return { 
              ...state,
              bulkDeletedItems: action.payload,
              pagRemovedAttributeCount: state.pagRemovedAttributeCount - action.payload.length
        }
      }

      case ATTRIBUTE_TYPES.DESTROY_ATTRIBUTE: {
        const id = action.payload.attributeId;
        return {
            ...state,
            pagRemovedAttribute: state.pagRemovedAttribute.filter(item => item.attributeId !== id),
            pagRemovedAttributeCount: state.pagRemovedAttributeCount - 1,
            loading: false
        }
      }
  
    default:
            return state;
  }
}
export {allAttribute, allRemovedAttribute, allNotRemovedAttribute, attribute, selectedAttribute, attributes}