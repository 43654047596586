import {PRODUCT_GROUP_TYPES} from "../../types/index";
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()
import {inputs} from '../../../catalogs/Products/ProductGroup/Tools';

const defaultState = {
  pagRemovedProductGroup: [],
  pagRemovedProductGroupCount: 0,
  pagNotRemovedProductGroup: [],
  pagNotRemovedProductGroupCount: 0,
  productGroupSelect: [],
  selectedItem: inputs,
  initialFilter: {
    productGroupIds: [],
    name: '',
    deleted: null
  },
  pageNumber: {
    removed: 1,
    notRemoved: 1
  },
  showEntries: {
    removed: 10,
    notRemoved: 10
  },
  langTab : {
    userGroupId: 79,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'd/',
    removed: 'notRemoved'
  },
  dataFilter: {
    productGroupId: null,
    nameF: '',
    sortOrder: null,
    dateAdded: null,
    filter: false
  },
  tabPanel: localStorage.getItem('productGroupActiveTab') || '1',
  checked: false,
  ids: [],
  data: [],
  count: 0,
  loading: {
    list: false
  },
  isOpen: isMobile ? false : localStorage.getItem('productGroupFilter') == 'true' ? true : false,
  filters: []
};

const productGroups = ( state = defaultState, action = {}) => {
  switch (action.type) {

      case PRODUCT_GROUP_TYPES.GET_PAG_REMOVED_PRODUCT_GROUPS: {
          return {
            ...state,
            pagRemovedProductGroup: action.payload.data,
            pagRemovedProductGroupCount: action.payload.count,
            loading: { ...state.loading, ...{ list: false } }
          }
      }

      case PRODUCT_GROUP_TYPES.GET_PAG_NOT_REMOVED_PRODUCT_GROUPS: {
          return {
            ...state,
            pagNotRemovedProductGroup: action.payload.data,
            pagNotRemovedProductGroupCount: action.payload.count,
            loading: { ...state.loading, ...{ list: false } }
          }
      }

      case PRODUCT_GROUP_TYPES.ARCHIVE_PRODUCT_GROUP: {
        const id = action.payload.productGroupId;
        return {
            ...state,
            pagNotRemovedProductGroup: state.pagNotRemovedProductGroup.filter(item => item.productGroupId !== id)
        }
      }

      case PRODUCT_GROUP_TYPES.PRODUCT_GROUPS_DATA_FILTER: {
        return { 
            ...state,
            dataFilter:  { ...{ [action.payload['key']]: action.payload['value'] }  }
        }
      }

      case PRODUCT_GROUP_TYPES.RECOVER_PRODUCT_ITEMS: {
        const id = action.payload.productGroupId;
        state.pagNotRemovedProductGroup.unshift(action.payload)
        return {
            ...state,
            pagRemovedProductGroup: state.pagRemovedProductGroup.filter(item => item.productGroupId !== id),
            pagNotRemovedProductGroup: state.pagNotRemovedProductGroup,
            pagNotRemovedProductGroupCount: state.pagNotRemovedProductGroupCount + 1
        }
      }

      case PRODUCT_GROUP_TYPES.ARCHIVE_PRODUCT_GROUP: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
        }
      }

    case PRODUCT_GROUP_TYPES.BULK_RECOVER_PRODUCT_GROUP: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
        }
      }


      case PRODUCT_GROUP_TYPES.SELECT_PRODUCT_GROUP: {
        return {
            ...state,
            selectedItem: action.payload
        } 
      }
      

      case PRODUCT_GROUP_TYPES.DESTROY_PRODUCT_GROUP: {
        return {
            ...state,
            pagRemovedProductGroup: state.pagRemovedProductGroup.filter(item => item.productGroupId !== action.payload.productGroupId),
            loading: false
        }
    }

    case 'DATA_FILTER_F': {

        return {
            ...state,
            pagNotRemovedProductGroup: action.payload.data,
            pagNotRemovedProductGroupCount: action.payload.count,
            pagRemovedProductGroup: action.payload.data,
            pagRemovedProductGroupCount: action.payload.count
        }
        
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_TAB_PANEL_CHANGE: {
      return {
          ...state,
          tabPanel: action.payload,
          loading: false
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_FILTER_IS_OPEN: {
      localStorage.setItem('productGroupFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_DATA_FILTER_SEARCH: {
        return { 
            ...state,
            initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
            error: []
        }
    }


    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_DATA_FILTER_SEARCH_RESET: {
        const { key } = action.payload
        return {
            ...state,
            [key]: Object.assign({}, state.initialFilter, { model: "", name: "" })
        }
    }


    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_DATA_FILTER_ALL_SEARCH_RESET: {
        const { key } = action.payload
        return {
                ...state,
                [key]: Object.assign({}, state.initialFilter, { 
                productGroupIds: [],
                name: ''
            })
        }
    }

    case PRODUCT_GROUP_TYPES.FETCH_PRODUCT_GROUP_SEE_ALL: {
      const newProductGroups = []
      action.payload.data.forEach(productGroup => {
        if (productGroup.descriptions && productGroup.descriptions[0] && productGroup.descriptions[0].name) {
          newProductGroups.push({ 
            value: productGroup.productGroupId, 
            label: productGroup.descriptions[0].name 
          })
        }
      })

      let productGroupSelect = [...state.productGroupSelect, ...newProductGroups]
      productGroupSelect = productGroupSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          productGroupSelect: productGroupSelect,
          pagNotRemovedProductGroupCount: action.payload.count
      }
    }


    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER: {
      
      let filters = state.selectedItem.filters

       // todo
      if (!action.id) {

        filters.push({
          value: "",
          value1: "",
          filterGroupId: action.payload.filterGroupId,
          filterGroup: action.payload,
          filter: {},
          categories: [],
          brands: [],
          business: []
        })
        
      } else {

        filters = filters.map((filter) => 
          filter.filterGroupId == action.id 
            ? 
            {
              value: "",
              value1: "",
              filterGroupId: action.payload.filterGroupId,
              filterGroup: action.payload,
              filter: {},
              categories: [],
              brands: [],
              business: []
            } 
            : 
            filter
          )

        }

      return {
        ...state,
        selectedItem: { 
          ...state.selectedItem, 
          filters: filters 
        }
      }

    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_REMOVE_FILTER: {
      return {
        ...state,
        selectedItem: { 
          ...state.selectedItem, 
          filters: state.selectedItem.filters.filter(({filterGroupId}) => filterGroupId !== action.payload)  
        }
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_VALUE: {
      let filters = []

      state.selectedItem.filters.forEach((filterGroup) => {
        if (filterGroup.filterGroupId == action.payload.filterGroupId) {

          let filter = { 
            ...filterGroup, 
            filter: action.payload 
          }

          if (action.payload.dates.length) {
            filter.filterDate = action.payload.dates[0]
          }

          filters.push(filter)

        } else {

          filters.push(filterGroup)

        }
      })

      return {
        ...state,
        selectedItem: { 
          ...state.selectedItem, 
          filters: filters  
        }
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_DATE_VALUE: {
      return {
        ...state,
        selectedItem: { 
          ...state.selectedItem, 
          filters: state.selectedItem.filters.map((filterGroup) => filterGroup.filterGroupId == action.payload.filterGroupId ? { ...filterGroup, filterDate: action.payload }: filterGroup)  
        }
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_CATEGORIES: {
      const { all, checked, data } = action.payload
      const filters = state.selectedItem.filters.map((filterGroup) => 
        filterGroup.filterGroupId == action.payload.filterGroupId 
        ? 
        { 
          ...filterGroup, 
          categories: all ? checked ? [] : [...data] : filterGroup.categories.find(({categoryId}) => categoryId == action.payload.categoryId) ? filterGroup.categories.filter(({categoryId}) => categoryId !== action.payload.categoryId) : [...filterGroup.categories, action.payload]
        }
        : filterGroup
      ) 

      return {
        ...state,
        selectedItem: { 
          ...state.selectedItem, 
          filters: filters
        }
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_BRANDS: {
      const { all, checked, data } = action.payload
      const filters = state.selectedItem.filters.map((filterGroup) => 
        filterGroup.filterGroupId == action.payload.filterGroupId 
        ? 
        { 
          ...filterGroup, 
          brands: all ? checked ? [] : [...data] : filterGroup.brands.find(({brandId}) => brandId == action.payload.brandId) ? filterGroup.brands.filter(({brandId}) => brandId !== action.payload.brandId) : [...filterGroup.brands, action.payload]
        }
        : filterGroup
      ) 

      return {
        ...state,
        selectedItem: { 
          ...state.selectedItem, 
          filters: filters
        }
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_CHOOSE_FILTER_BUSINESS: {
      const { all, checked, data } = action.payload

      const filters = state.selectedItem.filters.map((filterGroup) => 
        filterGroup.filterGroupId == action.payload.filterGroupId 
        ? 
        { 
          ...filterGroup, 
          business: all ? checked ? [] : [...data] : filterGroup.business.find(({businessId}) => businessId == action.payload.businessId) ? filterGroup.business.filter(({businessId}) => businessId !== action.payload.businessId) : [...filterGroup.business, action.payload]
        }
        : filterGroup
      )
      
      return {
        ...state,
        selectedItem: { 
          ...state.selectedItem, 
          filters: filters 
        }
      }
    }

    case PRODUCT_GROUP_TYPES.PRODUCT_GROUP_FILTER_F: {
      return {
        ...state,
        filters: action.payload.data
      }
    }

    default:
            return state;
  }
}


export { 
    productGroups
}