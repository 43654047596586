import React, { Fragment } from 'react'
import {
    Row,Col,Button
} from 'reactstrap';


function OrderHeader(props) { 

    const {_changeFilterTab, filterTab, statusList } = props

    return (
        <Fragment>
            <Row className=' pl-2'>
                <Col col="6" sm="12" md="12" xl className="">
                    <Button color="ghost-primary" onClick={() => _changeFilterTab('all')}
                            className={filterTab === 'all' ? 'active' : ''}>
                        <i className="fa fa-align-justify mr-1"> </i>
                        All
                    </Button>
                    {
                        statusList.map((item, i) =>
                            <Button
                                key={i}
                                color="ghost-primary"
                                size="sm"
                                className={filterTab === item.name  ? 'active' : ''}
                                onClick={() => _changeFilterTab( item.name )}>
                                <i className="fa fa-check fa-lg mr-1"></i>
                                {item.name}
                            </Button>
                        )
                    }
                </Col>
            </Row>
        </Fragment>
    );
  }
  

export default OrderHeader