const SMS_PAG_F = 'SMS_PAG_F';
const SMS_PAG_R = 'SMS_PAG_R';
const SMS_SIZE_CHANGE = 'SMS_SIZE_CHANGE'
const SMS_PAGE_CHANGE = 'SMS_PAGE_CHANGE'
const SMS_FILETR_TABE_CHANGE = 'SMS_FILETR_TABE_CHANGE'
const SMS_UPDATE_F = 'SMS_UPDATE_F'
const SMS_UPDATE_R = 'SMS_UPDATE_R'
const SMS_CHECKBOX_CHANGE = 'SMS_CHECKBOX_CHANGE'
const SMS_BULK_ARCIVE_F = 'SMS_BULK_ARCIVE_F'
const SMS_BULK_ARCIVE_R = 'SMS_BULK_ARCIVE_R'
const SMS_BULK_DESTROY_F = 'SMS_BULK_DESTROY_F'
const SMS_BULK_DESTROY_R = 'SMS_BULK_DESTROY_R'
const SMS_TAB_PANEL_CHANGE = 'SMS_TAB_PANEL_CHANGE'
const SMS_DATA_FILTER = 'SMS_DATA_FILTER'
const SMS_DELETE_F = 'SMS_DELETE_F'
const SMS_FILTER_IS_OPEN = 'SMS_FILTER_IS_OPEN'
const SMS_DATA_FILTER_SEARCH = 'SMS_DATA_FILTER_SEARCH'
const SMS_DATA_MIN_MAX_F = 'SMS_DATA_MIN_MAX_F'
const SMS_SELECT_FILTER_TYPES = 'SMS_SELECT_FILTER_TYPES'
const SMS_LANGUAGE_PANEL_CHANGE = 'SMS_LANGUAGE_PANEL_CHANGE'
const SMS_GET_ORDER = 'SMS_LANGUAGE_GET_ORDER'
const SMS_DATA_FILTER_ALL_SEARCH_RESET = 'SMS_DATA_FILTER_ALL_SEARCH_RESET'
const GET_BALANCE_TELNYX_F = 'GET_BALANCE_TELNYX_F'
const GET_BALANCE_TELNYX_R = 'GET_BALANCE_TELNYX_R'
const GET_BALANCE_TWILIO_F = 'GET_BALANCE_TWILIO_F'
const GET_BALANCE_TWILIO_R = 'GET_BALANCE_TWILIO_R'
const FETCH_SMS_PHONES_P = 'FETCH_SMS_PHONES_R'
const FETCH_SMS_PHONES_F = 'FETCH_SMS_PHONES_F'
const FETCH_SMS_PHONES_R = 'FETCH_SMS_PHONES_R'
const NEW_SMS = 'NEW_SMS'
const SEND_SMS_P = 'SEND_SMS_P'
const SEND_SMS_F = 'SEND_SMS_F'
const SEND_SMS_R = 'SEND_SMS_R'
const SEND_PULSE_SMS_BALANCE_P = 'SEND_PULSE_SMS_BALANCE_P'
const SEND_PULSE_SMS_BALANCE_F = 'SEND_PULSE_SMS_BALANCE_F'
const SEND_PULSE_SMS_BALANCE_R = 'SEND_PULSE_SMS_BALANCE_R'
const TELNYX_NUMBER_LIST_P = 'TELNYX_NUMBER_LIST_P'
const TELNYX_NUMBER_LIST_F = 'TELNYX_NUMBER_LIST_F'
const TELNYX_NUMBER_LIST_R = 'TELNYX_NUMBER_LIST_R'
const TELNYX_NUMBER_BUY_P = 'TELNYX_NUMBER_BUY_P'
const TELNYX_NUMBER_BUY_F = 'TELNYX_NUMBER_BUY_F'
const TELNYX_NUMBER_BUY_R = 'TELNYX_NUMBER_BUY_R'
const UPDATE_SMS = 'UPDATE_SMS'
const SMS_STATISTICS_P = 'SMS_STATISTICS_P'
const SMS_STATISTICS_F = 'SMS_STATISTICS_F'
const SMS_STATISTICS_R = 'SMS_STATISTICS_R'
const SMS_GET_ONE_P = 'SMS_GET_ONE_P'
const SMS_GET_ONE_F = 'SMS_GET_ONE_F'
const SMS_GET_ONE_R = 'SMS_GET_ONE_R'
const SMS_CREATE_F = 'SMS_CREATE_F'
const SMS_CREATE_R = 'SMS_CREATE_R'
const SMS_MESSAGE_PAG_P = 'SMS_MESSAGE_PAG_P'
const SMS_MESSAGE_PAG_F = 'SMS_MESSAGE_PAG_F'
const SMS_MESSAGE_PAG_R = 'SMS_MESSAGE_PAG_R'
const SMS_MOBILE_FILTER_IS_OPEN = 'SMS_MOBILE_FILTER_IS_OPEN'
const SMS_UPDATE_STATUS = 'SMS_UPDATE_STATUS'
const SMS_INBOUND = 'SMS_INBOUND'
const CLEAR_SMS_MESSAGES = 'CLEAR_SMS_MESSAGES'

export {
    SMS_PAG_R,
    SMS_PAG_F,
    SMS_SIZE_CHANGE,
    SMS_PAGE_CHANGE,
    SMS_FILETR_TABE_CHANGE,
    SMS_UPDATE_F,
    SMS_UPDATE_R,
    SMS_CHECKBOX_CHANGE,
    SMS_BULK_ARCIVE_F,
    SMS_BULK_ARCIVE_R,
    SMS_BULK_DESTROY_R,
    SMS_BULK_DESTROY_F,
    SMS_TAB_PANEL_CHANGE,
    SMS_DATA_FILTER,
    SMS_DELETE_F,
    SMS_FILTER_IS_OPEN,
    SMS_DATA_FILTER_SEARCH,
    SMS_DATA_MIN_MAX_F,
    SMS_SELECT_FILTER_TYPES,
    SMS_LANGUAGE_PANEL_CHANGE,
    SMS_GET_ORDER,
    SMS_DATA_FILTER_ALL_SEARCH_RESET,
    GET_BALANCE_TWILIO_F,
    GET_BALANCE_TWILIO_R,
    GET_BALANCE_TELNYX_F,
    GET_BALANCE_TELNYX_R,
    NEW_SMS,
    FETCH_SMS_PHONES_P,
    FETCH_SMS_PHONES_F,
    FETCH_SMS_PHONES_R,
    SEND_SMS_P,
    SEND_SMS_R,
    SEND_SMS_F,
    SEND_PULSE_SMS_BALANCE_P,
    SEND_PULSE_SMS_BALANCE_F,
    SEND_PULSE_SMS_BALANCE_R,
    TELNYX_NUMBER_LIST_P,
    TELNYX_NUMBER_LIST_F,
    TELNYX_NUMBER_LIST_R,
    TELNYX_NUMBER_BUY_P,
    TELNYX_NUMBER_BUY_F,
    TELNYX_NUMBER_BUY_R,
    UPDATE_SMS,
    SMS_STATISTICS_P,
    SMS_STATISTICS_F,
    SMS_STATISTICS_R,
    SMS_GET_ONE_P,
    SMS_GET_ONE_F,
    SMS_GET_ONE_R,
    SMS_CREATE_F,
    SMS_CREATE_R,
    SMS_MESSAGE_PAG_P,
    SMS_MESSAGE_PAG_F,
    SMS_MESSAGE_PAG_R,
    SMS_MOBILE_FILTER_IS_OPEN,
    SMS_UPDATE_STATUS,
    SMS_INBOUND,
    CLEAR_SMS_MESSAGES
}