import React, { useState, Fragment } from 'react'
import './Select.css'

function Select(props) { 
    const { data, value, disabled, changeSelect } = props
    const [isOpen, setIsOpen] = useState(false)

    function toggle() {
        if (!disabled) {
            setIsOpen(!isOpen)
        }
    }

    return (
        <Fragment>
            <div className={`dropdown pagination-select ${isOpen ? 'open' : ''}`}>
                <button 
                    className="btn btn-default dropdown-toggle" 
                    id="menu1"
                    onClick={() => toggle()}>
                        {
                            value + '/page'
                        }
                    <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                    {
                        data.map((item, i) =>
                            <li onClick={() => { 
                                    changeSelect(item)
                                    setIsOpen(false)
                                }} 
                                key = {i}>
                                <a>{ item + '/page' }</a>
                            </li>
                        )
                    }
                </ul>
            </div>
        </Fragment>
    );
  }
  
export default Select
