import { ATTRIBUTE_GROUP_TYPES} from '../../types/index'


const defaultState = {
  pagRemovedAttributeGroup: [],
  pagNotRemovedAttributeGroup: [],
  pagRemovedAttributeGroupCount: 0,
  pagNotRemovedAttributeGroupCount: 0,
  attributeGroupSelect: [],
  attributeGroupSelectCount: 0
};
const allAttributeGroup = (state = [], action = {}) => {
  if (action.type === ATTRIBUTE_GROUP_TYPES.GET_ALL_ATTRIBUTE_GROUP) {
    return action.payload;
  } else {
    return state
  }
};

const allRemovedAttributeGroup = (state = [], action = {}) => {
  if (action.type === ATTRIBUTE_GROUP_TYPES.GET_ALL_REMOVED_ATTRIBUTE_GROUP) {
    return action.payload;
  } else {
    return state
  }
};

const attributeGroup = (state = {}, action = {}) => {
  if (action.type === ATTRIBUTE_GROUP_TYPES.GET_ATTRIBUTE_GROUP_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const selectedAttributeGroup = (state = {}, action = {}) => {
  if (action.type === ATTRIBUTE_GROUP_TYPES.SELECT_ATTRIBUTE_GROUP_ITEMS) {
    return action.payload;
  } else {
    return state
  }
};

const attributeGroups = ( state = defaultState, action = {}) => {
  switch (action.type) {
      case ATTRIBUTE_GROUP_TYPES.GET_PAG_REMOVED_ATTRIBUTES_GROUP: {
          return {
            ...state,
            pagRemovedAttributeGroup: action.payload.data,
            pagRemovedAttributeGroupCount: action.payload.count

          }
      }

      case ATTRIBUTE_GROUP_TYPES.GET_PAG_NOT_REMOVED_ATTRIBUTES_GROUP: {
          return {
            ...state,
            pagNotRemovedAttributeGroup: action.payload.data,
            pagNotRemovedAttributeGroupCount: action.payload.count
          }
      }

      case ATTRIBUTE_GROUP_TYPES.DELETE_ATTRIBUTE_GROUP_ITEMS: {
        const id = action.payload.attributeGroupId;
        return {
            ...state,
            pagNotRemovedAttributeGroup: state.pagNotRemovedAttributeGroup.filter(item => item.attributeGroupId !== id)
        }
      }

      case ATTRIBUTE_GROUP_TYPES.RECOVER_ATTRIBUTE_GROUP_ITEMS: {
        const id = action.payload.attributeGroupId;
        state.pagNotRemovedAttributeGroup.unshift(action.payload)
        return {
            ...state,
            pagRemovedAttributeGroup: state.pagRemovedAttributeGroup.filter(item => item.attributeGroupId !== id),
            pagNotRemovedAttributeGroup: state.pagNotRemovedAttributeGroup,
            pagNotRemovedAttributeGroupCount: state.pagNotRemovedAttributeGroupCount + 1
        }
      }

      case ATTRIBUTE_GROUP_TYPES.ARCHIVE_ATTRIBUTE_GROUP_ITEMS: {
        return { 
            ...state,
            bulkDeletedItems: action.payload,
            pagNotRemovedAttributeGroupCount: state.pagNotRemovedAttributeGroupCount - action.payload.length
        }
      }

      case ATTRIBUTE_GROUP_TYPES.BULK_RECOVER_ATTRIBUTE_GROUP_ITEMS: {
          return { 
              ...state,
              bulkDeletedItems: action.payload,
              pagRemovedAttributeGroupCount: state.pagRemovedAttributeGroupCount - action.payload.length
        }
      }

      case ATTRIBUTE_GROUP_TYPES.DESTROY_ATTRIBUTE_GROUP: {
        const id = action.payload.attributeGroupId;
        return {
            ...state,
            pagRemovedAttributeGroup: state.pagRemovedAttributeGroup.filter(item => item.attributeGroupId !== id),
            pagRemovedAttributeGroupCount: state.pagRemovedAttributeGroupCount - 1,
            loading: false
        }
    }

    case ATTRIBUTE_GROUP_TYPES.FETCH_ATTRIBUTE_GROUP_SEE_ALL: {
      let newAttribute = []
      action.payload.data.forEach(attrGroup => {
        newAttribute.push({ value: attrGroup.attributeGroupId, label: attrGroup.attributeGroupId + ' ' + attrGroup.source, ...attrGroup})
      })
      
      let attributeGroupSelect = action.search ? newAttribute : [...state.attributeGroupSelect, ...newAttribute]
    
      attributeGroupSelect = attributeGroupSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          attributeGroupSelect: attributeGroupSelect,
          attributeGroupSelectCount: action.payload.count
      }
    }
  
    default:
            return state;
  }
}

export {allAttributeGroup,allRemovedAttributeGroup,attributeGroup, selectedAttributeGroup, attributeGroups}