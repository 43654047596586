import { 
    NOTIFICATION_TYPES
} from '../types'

const initialState = {
    mainNotifications: [],
    mainNotificationsCount: 0,
    loading: false,
    error: {}
};

const notifications = (state = initialState, action = {}) => {
    switch (action.type) {

        case NOTIFICATION_TYPES.FETCH_NOTIFICATIONS_P: {

            return {
                ...state,
                loading: true
            };
        }

        case NOTIFICATION_TYPES.FETCH_NOTIFICATIONS_F: {
            const { payload, seeMore } = action;

            return { 
                ...state, 
                loading: false,
                mainNotifications: seeMore
                    ? [...state.mainNotifications, ...payload.data] // Concatenate if seeMore is true
                    : payload.data, // Replace if seeMore is false
                mainNotificationsCount: payload.count
            };
        }

        case NOTIFICATION_TYPES.FETCH_NOTIFICATIONS_R: {
            return {
                ...state,
                // loading: false,
                error: action.payload,
                mainNotifications: []
            };
        }

        case NOTIFICATION_TYPES.DESTROY_NOTIFICATION_P: {

            return {
                ...state,
                // loading: true
            };
        }

        case NOTIFICATION_TYPES.DESTROY_NOTIFICATION_F: {
            const isRead = !!state.mainNotifications.find((item) => item._id === action.payload.id && !item.is_read)

            return { 
                ...state, 
                mainNotifications: state.mainNotifications.filter(notification => notification._id !== action.payload.id),
                mainNotificationsCount: isRead ? state.mainNotificationsCount - 1 : state.mainNotificationsCount,
                loading: false
            };
            
        }

        case NOTIFICATION_TYPES.DESTROY_NOTIFICATION_R: {
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        }

        case NOTIFICATION_TYPES.ADD_NOTIFICATION: {
            return {
                ...state,
                mainNotifications: [action.payload, ...state.mainNotifications],
                mainNotificationsCount: state.mainNotificationsCount + 1,
            };
        }

        case NOTIFICATION_TYPES.UPDATE_NOTIFICATION_P: {
            return {
                ...state,
                // loading: true
            }
        }

        case NOTIFICATION_TYPES.UPDATE_NOTIFICATION_F: {
            const notification = action.payload.data;
            const mainNotifications = []
            const isRead = !!state.mainNotifications.find((item) => item._id === notification._id && !item.is_read)
            state.mainNotifications.forEach(item => {
                if (item._id === notification._id) {
                    item.is_read = notification.is_read
                } 
                mainNotifications.push(item)
            })

            return {
                ...state,
                mainNotifications: mainNotifications,
                mainNotificationsCount: isRead ? state.mainNotificationsCount - 1 : state.mainNotificationsCount,
                loading: false,
            }
        }
        

        case NOTIFICATION_TYPES.UPDATE_NOTIFICATION_R: {
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        }



        case NOTIFICATION_TYPES.ALL_READ_NOTIFICATION_P: {
            return {
                ...state,
                loading: true
            }
        }

        case NOTIFICATION_TYPES.ALL_READ_NOTIFICATION_F: {
            return {
                ...state,
                mainNotifications: state.mainNotifications.map(item => ({ 
                    ...item, 
                    is_read: true
                })),
                mainNotificationsCount: 0,
                loading: false,
            }
        }
        
        case NOTIFICATION_TYPES.ALL_READ_NOTIFICATION_R: {
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        }

        default:
            return state;
    }
}

export {
    notifications
}