import axios from 'axios'
import  {LINK_TYPES} from '../../types'
import  {linkService} from '../../services/linkService'
import {Notification} from 'element-react'
import config from '../../../../config/app'
import qs from 'qs'

/**
 * @name selectElem
 * @description select link item that edit item
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : LINK_TYPES.SELECT_LINK_ITEMS,
      payload: elem
    })
  }
};


const getItem = (id, token) => {
  return dispatch => {
    return linkService.getItem(id, token).then(data => {
      return dispatch({
        type: LINK_TYPES.GET_LINK_ITEM,
        payload: data
      })
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return linkService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: LINK_TYPES.GET_PAG_NOT_REMOVED_LINKS,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return linkService.getPaginationRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: LINK_TYPES.GET_PAG_REMOVED_LINKS,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const arcive = (ids, jwt) => {
  return dispatch => {
    linkService.bulkDelete(ids,  jwt).then((data) => {
      if (data.success) {
        return dispatch({
          type: LINK_TYPES.DELETE_LINK_ITEMS,
          payload : ids
        })
      }
    })
  }
};

/**
 * @name recover
 * @param elem
 * @returns {function(*)}
 */
const recover = (ids, jwt) => {
  return dispatch => {
    linkService.bulkRecover(ids,  jwt).then((data) => {
      if (data.success) {
        return dispatch({
          type: LINK_TYPES.RECOVER_LINK_ITEMS,
          payload : ids
        })
      }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    linkService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: LINK_TYPES.ARCHIVE_LINK_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    linkService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: LINK_TYPES.BULK_RECOVER_LINK_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    linkService.bulkDestroy(ids,  jwt).then((_data) => {
      return dispatch({
        type: LINK_TYPES.BULK_DESTROY_LINK_ITEMS,
        payload : ids
      })
    })
  }
};

const newLink = (language) => {
  return async dispatch => {
    try {
      dispatch({
        type: LINK_TYPES.NEW_LINK_FULFILLED,
        payload: language
      })
    } catch (e) {
      dispatch({
        type: LINK_TYPES.NEW_LINK_REJECTED,
        payload: e
      });
    }
  }
}

const saveLink = (link, jwt, history, discard) => {
  return async dispatch => {
    linkService.create(link, jwt)
      .then(data => {

        Notification.success({
          title: 'Created',
          message: 'Store link succesfuly created',
        }, 20)

        if (discard) {
          history.push(`${discard}`)
        } else {
          history.push('/store/link')
        }

        dispatch({
          type: LINK_TYPES.SAVE_LINK_FULFILLED,
          payload: data
        })

        dispatch({
          type: LINK_TYPES.LINK_TAB_PANEL_CHANGE,
          payload:  '1'
        }) 

      })
      .catch(e => {
          if (e.response && e.response.data && e.response.data.length) {
            e.response.data.forEach(error => {
              setTimeout(() => {
                Notification.error({
                  title: `${error.param}!`,
                  message: error.msg
                })
              }, 10)
            })
          }  
        })
      }
    }

const fetchLinkById = (id, token, language) => {
  return async dispatch => {
    try {

        dispatch({
          type: LINK_TYPES.FETCH_LINK_PENDING
        })

        const data = await linkService.getItem(id, token)

        dispatch({
          type: LINK_TYPES.FETCH_LINK_FULFILLED,
          payload: {data, language}
        })

    } catch (e) {
      dispatch({
          type: 'FETCH_LINK_REJECTED'
      });
    }
  }
}

const updateLink = (link, id, token, history, discard) => {
  return async dispatch => {
    try {
      let data = await linkService.edit(link, id, token)

        Notification.warning({
          title: 'Updated',
          message: 'Store link succesfuly updated '
        }, 20)

        if (discard) {
          history.push(`${discard}`)
        } else {
          history.push('/store/link')
        }
        dispatch({
            type: LINK_TYPES.UPDATE_LINK_FULFILLED,
            payload: data
        })

        dispatch({
          type: LINK_TYPES.LINK_TAB_PANEL_CHANGE,
          payload:  '1'
        }) 
        
    } catch (e) {
        if (e.response && e.response.data && e.response.data.length) {
          e.response.data.forEach(error => {
            setTimeout(() => {
              Notification.error({
                title: `${error.param}!`,
                message: error.msg
              })
            }, 10)
          })
        }  
      }
    }
  }

  const changeLangTab = ( id, LangTab ) => {
    return async dispatch => {
      dispatch({
        type: LINK_TYPES.LANG_TAB,
        payload: { 
          languageId: id, 
          LangTab: LangTab 
        }
      })
    }
  }

  const destroyLink = ( token, link ) => {

    return async dispatch => {

      try {

        const data = await linkService.destroy(token , link.linkId)
  
          Notification.success( {
            title: 'link ',
            message: `${link.name} link succesfuly deleted `
          }, 20 )

          dispatch( {
              type: LINK_TYPES.DESTROY_LINK,
              payload: link,
              data: data
          } )
          
      } catch (e) {

          Notification.error( {
            title: e.name,
            message: `${e.parent.column}`
          }, 20) 
         
        }
      }
    }


/**
 * @name getStatistic
 * @returns {function(*)}
*/
const getStatistic = (jwt, linkId, page, count, query) => {
  return async dispatch => {
    try {

      const searchString = qs.stringify(query)

      dispatch({
        type: LINK_TYPES.GET_STATISTICS_P,
      })

      const { data } = await axios.get(`${config["TRAFFIC"]}ls/r/${linkId}/${page}/${count}?${searchString}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      })

      dispatch({
        type: LINK_TYPES.GET_STATISTICS_F,
        payload: data
      })

    } catch (e) {
      dispatch({
        type: LINK_TYPES.GET_STATISTICS_R,
        payload: e
      })
    }
  }
};

/**
 * @name getAllStatistic
 * @returns {function(*)}
*/
const getAllStatistic = (jwt, query) => {
  return async dispatch => {
    try {

      const searchString = qs.stringify(query)

      dispatch({
        type: LINK_TYPES.GET_ALL_STATISTICS_P,
      })

      const { data } = await axios.get(`${config["TRAFFIC"]}ls/s/visitors?${searchString}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      })

      dispatch({
        type: LINK_TYPES.GET_ALL_STATISTICS_F,
        payload: data
      })

    } catch (e) {
      dispatch({
        type: LINK_TYPES.GET_ALL_STATISTICS_R,
        payload: e
      })
    }
  }
};


export {
  getItem,  
  selectItem, 
  getPaginationNotRemoved,
  getPaginationRemoved, 
  arcive, 
  recover, 
  bulkDelete, 
  bulkRecover, 
  newLink, 
  saveLink, 
  fetchLinkById, 
  updateLink, 
  changeLangTab, 
  destroyLink, 
  bulkDestroy,
  getStatistic,
  getAllStatistic
}