import { CART_TYPES } from '../types'

const initialState = {
  statistics: {
    data: []
  }
};



const cart = ( state = initialState, action = {} ) => {

  switch (action.type) {

    case CART_TYPES.CART_STATISTICS_P: {
      return {
          ...state,
          loading: true
      }
    }

    case CART_TYPES.CART_STATISTICS_F: {
      return {
          ...state,
          statistics: action.payload
      }
    }

    case CART_TYPES.CART_STATISTICS_R: {
      return {
          ...state,
          error: action.payload.data
      }
    }
    

    default:
            return state;
  }
}

export {
    cart
}

