import React, { useEffect, useState } from "react";
import classnames from 'classnames';
import Draggable from "react-draggable";
import './OutboundModal.scss'

const Outbound = ({ connection, handleHangup, toggleMute }) => {
  const [muted, setMuted] = useState(false);
  const [to, setTo] = useState(null)
  const getButtonClass = (icon, enabled) => classnames(`btn-action fa ${icon}`, { disable: !enabled });

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    if (connection.message) {
      setTo(connection.message.To);
    } else {
      setTo(connection.options.destinationNumber);
    }
  }, [connection])

  /**
    * Turn on/off a media device
    * @param {String} deviceType - Type of the device eg: Video, Audio
  */
  const toggleMediaDevice = () => {
    setMuted(!muted);
    toggleMute(!muted)
  };
  
  return (
    <Draggable
      position={position}
      onDrag={handleDrag}
      cancel=".outboundFooter"
    >
      <div className="outboundCard">
        <div className="outboundHeader">
          <p className="outboundPhoneNumber">{to}</p>
        </div>
        <div className="outboundFooter">
          <button
            key="btnAudio"
            className={getButtonClass('fa-microphone', !muted)}
            onClick={() => {
              toggleMediaDevice();
            }}
          />
          <button
            className="btn-action hangup fa fa-phone"
            onClick={() => {
              handleHangup(connection);
            }}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default Outbound;
