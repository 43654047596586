import React from "react";

function SelectOption (props) {
    const { option, sortArray, product, changeOptions }  = props

    function selectOptionValue(e) {
        let { optionValueId }= JSON.parse(e.target.value)
        product.activeOptions = product.activeOptions.filter(id => !option.values.find(elem => elem.optionValueId == id))
        product.activeOptions.push(optionValueId)
        changeOptions(product.activeOptions, product)
    }

    return (
        <>
            <div className={`option-type`}>
                <div className="select-option">
                    <div className={`weight-options__title`}>
                        <span>
                           {option.option.descriptions[0].name}
                        </span> 
                    </div>
                    <div className = "product-weight-quantity">
                        <div className="weight-product quantity-product">
                            <select 
                                value={JSON.stringify(option.values.find(elem => product.activeOptions.includes(elem.optionValueId)))}
                                className="weight-product__select" 
                                onChange={(e) => selectOptionValue(e)}>
                                {
                                    sortArray(option.values).map((item , i) => 
                                        !!item.subtract && 
                                        <option 
                                            key = { i } 
                                            title={item.optionValue.descriptions[0].name}  
                                            value={JSON.stringify(item)}>
                                            {
                                                item.optionValue.descriptions[0].name
                                            }  
                                           
                                        </option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectOption;