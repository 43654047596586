import { CALL_TYPES } from '../../types'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()

const initialState = {
  data: [],
  statistics: null,
  oneData: {},
  records: [],
  error: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedCallPage') || 1,
    notRemoved: localStorage.getItem('notRemovedCallPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedCallPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedCallPageSize') || 10
  },
  langTab : {
    languageId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  initialFilter: {
    phone: '',
    from: '',
    to: '',
    datesF:  '',
    storeIds: []
  },
  dataFilter: {
    search: '',
    message: false,
  },
  minMaxValues: {},
  isOpen: isMobile ? false : localStorage.getItem('callFilter') == 'true' ? true : false,
  tabPanel: '1',
  checked: false,
  ids: [],
  orders: {
    page: 1,
    showEntries: 10,
    data: [],
    count: []
  },
  statistics: {
    data: [],
    calls: 0,
    unique: 0
  },
  loading: false,
  loadingRecord: true,
  provider: 'twilio' 
};



const call = ( state = initialState, action = {} ) => {

  switch (action.type) {

    case CALL_TYPES.CALL_PAG_F: {
        return {
          ...state,
          statistics: { ...state.statistics, ...action.payload.statistics },
          data: action.payload.data.map((elem) => state.ids.includes(elem.callId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.callId)),
          count: action.payload.count
        }
    }


    case CALL_TYPES.CALL_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case CALL_TYPES.CALL_GET_ONE_P: {
      return { 
        ...state,
        oneData: {},
        error: [],
        loading: true
      }
    }

    case CALL_TYPES.CALL_GET_ONE_F: {
      return { 
        ...state,
        oneData: action.payload,
        error: [],
        loading: false
      }
    }

    case CALL_TYPES.CALL_CLEAR_ONE: {
      return { 
        ...state,
        oneData: {}
      }
    }

    case CALL_TYPES.CALL_GET_ONE_R: {
      return { 
        ...state,
        oneData: {},
        error: action.payload,
        loading: false
      }
    }

    case CALL_TYPES.CALL_RECORD_P: {
      return { 
        ...state,
        records: [],
        error: [],
        loadingRecord: true
      }
    }

    case CALL_TYPES.CALL_RECORD_F: {
      return { 
        ...state,
        records: action.payload,
        error: [],
        loadingRecord: false
      }
    }

    case CALL_TYPES.CALL_RECORD_R: {
      return { 
        ...state,
        records: [],
        error: action.payload,
        loadingRecord: false
      }
    }

    case CALL_TYPES.CALL_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case CALL_TYPES.CALL_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case CALL_TYPES.CALL_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case CALL_TYPES.CALL_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.callId) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case CALL_TYPES.CALL_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CALL_TYPES.CALL_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CALL_TYPES.CALL_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case CALL_TYPES.CALL_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case CALL_TYPES.CALL_DATA_FILTER: {
      return { 
          ...state,
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case CALL_TYPES.CALL_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.customer !== id)
      }
    }

    case CALL_TYPES.CALL_FILTER_IS_OPEN: {
      localStorage.setItem('callFilter', !state.isOpen)
      return {
          ...state,
          isOpen: !state.isOpen
      }
    }

    case CALL_TYPES.CALL_DATA_FILTER_SEARCH: {
      return { 
          ...state,
          initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
          error: []
      }
    }

    case CALL_TYPES.CALL_DATA_MIN_MAX_F: {
      return {
          ...state,
          minMaxValues: action.payload
      }
    }
    
    case CALL_TYPES.CALL_DATA_FILTER_ALL_SEARCH_RESET: {
      const { key } = action.payload
      return {
        ...state,
        [key]: { 
          from: '',
          to: '',
          message: '',
          status: null,
          dates:  '',
        }
      }
    }

    case CALL_TYPES.GET_CALL_STATISTICS_P: {
      return {
        ...state,
        loading: true
      }
    }

    case CALL_TYPES.GET_CALL_STATISTICS_F: {
      return {
        ...state,
        statistics: action.payload
      }
    }

    case CALL_TYPES.GET_CALL_STATISTICS_R: {
      return {
        ...state,
        error: action.payload.data
      }
    }

    case CALL_TYPES.CALL_CHECK_PROVIDER_P: {
      return {
        ...state,
        loading: true
      }
    }

    case CALL_TYPES.CALL_CHECK_PROVIDER_F: {
      return {
        ...state,
        provider: action.payload.provider
      }
    }

    case CALL_TYPES.CALL_CHECK_PROVIDER_R: {
      return {
        ...state,
        error: action.payload.data
      }
    }

    default:
            return state;
  }
}


export {
    call
}

