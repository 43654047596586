import React, { Component } from 'react';
import { TabContent, TabPane} from 'reactstrap';
import Filters from '../../projectNulla/components/filters/Filters';


class DefaultAside extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <React.Fragment>
        <Filters 
          {...this.props}
        />
      </React.Fragment>
    );
  }
}


export default DefaultAside;
