import React from "react";
import WeightOption from './WeightOption';
import SelectOption from './SelectOption';
import CheckboxOption from './CheckboxOption';
import RadioOption from './RadioOption';
import ColorOption from './ColorOption';
import ImageOption from './ImageOption';
import './Options.scss'

function Option (props) {
    const { product, updateProduct, changeOptions } = props;
    const { options } = product

    function sortArray(dataArr) { 
        return dataArr.sort((a, b) => {
          return (parseInt(b.optionValue.sortOrder) != null ? parseInt(b.optionValue.sortOrder) : Infinity) - (parseInt(a.optionValue.sortOrder) != null ? parseInt(a.optionValue.sortOrder) : Infinity)
        });
    }

    return (
        <div className="flexcontainer">
            {
                options.map((item, i) =>
                    <div key = {i}>
                        {
                            item.option['type'] === 'select' &&
                            <SelectOption 
                                key = {i}
                                product={product} 
                                option={item}
                                sortArray = {sortArray}
                                updateProduct={updateProduct}
                                changeOptions={changeOptions}
                            />
                        }
                        {
                            item.option['type'] === 'checkbox' &&
                            <CheckboxOption 
                                key = {i} 
                                product={product} 
                                option = {item}
                                sortArray = {sortArray}
                                updateProduct={updateProduct}
                                changeOptions={changeOptions}
                            />
                        }
                        {
                            item.option['type'] === 'radio' && !item.required &&
                            <WeightOption 
                                key = {i} 
                                product={product} 
                                option={item}
                                sortArray = {sortArray}
                                updateProduct={updateProduct}
                                changeOptions={changeOptions}
                            />
                        }
                        {
                            item.option['type'] === 'radio' && item.required &&
                            <RadioOption 
                                key = {i}
                                product={product} 
                                option={item}
                                sortArray = {sortArray}
                                updateProduct={updateProduct}
                                changeOptions={changeOptions}
                            />
                        }
                        {
                            item.option['type'] === 'color' &&
                            <ColorOption 
                                key = {i}
                                product={product} 
                                option={item}
                                sortArray = {sortArray}
                                updateProduct={updateProduct}
                                changeOptions={changeOptions}
                                required = {item.required}
                            />
                        }
                        {
                            item.option['type'] === 'image' &&
                            <ImageOption 
                                key = {i}
                                product={product} 
                                option={item}
                                sortArray = {sortArray}
                                updateProduct={updateProduct}
                                changeOptions={changeOptions}
                                required = {item.required}
                            />
                        }
                    </div>
                )
            }
        </div>
    )
}


export default Option
