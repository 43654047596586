const CURRENCY_PAG_F = 'CURRENCY_PAG_L_F';
const CURRENCY_PAG_R = 'CURRENCY_PAG_L_R';
const CURRENCY_SIZE_CHANGE = 'CURRENCY_SIZE_CHANGE'
const CURRENCY_PAGE_CHANGE = 'CURRENCY_PAGE_CHANGE'
const CURRENCY_FILETR_TABE_CHANGE = 'CURRENCY_FILETR_TABE_CHANGE'
const CURRENCY_UPDATE_F = 'CURRENCY_UPDATE_F'
const CURRENCY_UPDATE_R = 'CURRENCY_UPDATE_R'
const CURRENCY_CHECKBOX_CHANGE = 'CURRENCY_CHECKBOX_CHANGE'
const CURRENCY_BULK_F = 'CURRENCY_BULK_F'
const CURRENCY_BULK_R = 'CURRENCY_BULK_R'
const CURRENCY_BULK_DESTROY_F = 'CURRENCY_BULK_DESTROY_F'
const CURRENCY_BULK_DESTROY_R = 'CURRENCY_BULK_DESTROY_R'
const CURRENCY_TAB_PANEL_CHANGE = 'CURRENCY_TAB_PANEL_CHANGE'
const CURRENCY_CREATE_F = 'CURRENCY_CREATE_F'
const CURRENCY_CREATE_R = 'CURRENCY_CREATE_R'
const CURRENCY_GET_ONE_F = 'CURRENCY_GET_ONE_F'
const CURRENCY_GET_ONE_R = 'CURRENCY_GET_ONE_R'
const CURRENCY_REQUIRED_VALIDATION_F = 'CURRENCY_REQUIRED_VALIDATION_F'
const CURRENCY_REQUIRED_VALIDATION_R = 'CURRENCY_REQUIRED_VALIDATION_R'
const NEW_CURRENCY = 'NEW_CURRENCY'
const CURRENCY_DATA_FILTER = 'CURRENCY_DATA_FILTER'
const CURRENCY_DELETE_F = 'CURRENCY_DELETE_F'
const CURRENCY_DELETE_R = 'CURRENCY_DELETE_R'
const CURRENCY_ALL_F = 'CURRENCY_ALL_F'
const CURRENCY_LANGUAGE_PANEL_CHANGE = 'CURRENCY_LANGUAGE_PANEL_CHANGE'
export {
    CURRENCY_PAG_F,
    CURRENCY_PAG_R,
    CURRENCY_SIZE_CHANGE,
    CURRENCY_PAGE_CHANGE,
    CURRENCY_FILETR_TABE_CHANGE,
    CURRENCY_UPDATE_F,
    CURRENCY_UPDATE_R,
    CURRENCY_CHECKBOX_CHANGE,
    CURRENCY_BULK_F,
    CURRENCY_BULK_R,
    CURRENCY_BULK_DESTROY_F,
    CURRENCY_BULK_DESTROY_R,
    CURRENCY_TAB_PANEL_CHANGE,
    CURRENCY_CREATE_F,
    CURRENCY_CREATE_R,
    CURRENCY_GET_ONE_F,
    CURRENCY_GET_ONE_R,
    CURRENCY_REQUIRED_VALIDATION_F,
    CURRENCY_REQUIRED_VALIDATION_R,
    NEW_CURRENCY,
    CURRENCY_DATA_FILTER,
    CURRENCY_DELETE_F,
    CURRENCY_DELETE_R,
    CURRENCY_ALL_F,
    CURRENCY_LANGUAGE_PANEL_CHANGE
}