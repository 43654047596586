import { CURRENCY_TYPES } from '../../types'
import CurrencyFields from '../../../system/Currency/type'


const initialState = {
  data: [],
  navItem: new CurrencyFields().navBar,
  oneData: {},
  error: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedCurrencyPage') || 1,
    notRemoved: localStorage.getItem('notRemovedCurrencyPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedCurrencyPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedCurrencyPageSize') || 10
  },
  langTab : {
    userGroupId: 79,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  dataFilter: {
    name: '',
    nameF: false,
    code: false,
    loacle: false,
    userGroupId: false,
    sortOrder: false
  },
  tabPanel: '1',
  checked: false,
  ids: []
};



const currency = ( state = initialState, action = {}) => {

  switch (action.type) {

    case CURRENCY_TYPES.CURRENCY_PAG_F: {
 
        return {
          ...state,
          data: action.payload.data.map((elem) => state.ids.includes(elem.currencyId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.currencyId)),
          count: action.payload.count
        }
    }

    case CURRENCY_TYPES.CURRENCY_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case CURRENCY_TYPES.CURRENCY_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case CURRENCY_TYPES.CURRENCY_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case CURRENCY_TYPES.CURRENCY_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case CURRENCY_TYPES.CURRENCY_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

      data.forEach(elem => { if (elem.isChecked)  ids.push(elem.currencyId) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case CURRENCY_TYPES.CURRENCY_BULK_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CURRENCY_TYPES.CURRENCY_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case CURRENCY_TYPES.CURRENCY_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case CURRENCY_TYPES.CURRENCY_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case CURRENCY_TYPES.CURRENCY_GET_ONE_F: {
      return { 
          ...state,
          oneData: action.payload,
          navItem: new CurrencyFields().navBar,
          error: []
      }
    }

    case CURRENCY_TYPES.CURRENCY_REQUIRED_VALIDATION_R: {
      state.navItem = new CurrencyFields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case CURRENCY_TYPES.CURRENCY_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new CurrencyFields().navBar
      }
    }

    case CURRENCY_TYPES.NEW_CURRENCY: {
      return { 
          ...state,
          oneData: new CurrencyFields(),
          navItem: new CurrencyFields().navBar,
          error: []
      }
    }

    case CURRENCY_TYPES.CURRENCY_DATA_FILTER: {
      return { 
          ...state,
          // dataFilter: Object.assign({}, state.dataFilter, { [action.payload['key']]: action.payload['value'] }),
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case CURRENCY_TYPES.CURRENCY_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.currencyId !== id)
      }
    }
    
    default:
            return state;
  }
}
export {
    currency
}