const SMS_GROUP_PAG_F = 'SMS_GROUP_PAG_F';
const SMS_GROUP_PAG_R = 'SMS_GROUP_PAG_R';
const SMS_GROUP_SIZE_CHANGE = 'SMS_GROUP_SIZE_CHANGE'
const SMS_GROUP_PAGE_CHANGE = 'SMS_GROUP_PAGE_CHANGE'
const SMS_GROUP_FILETR_TABE_CHANGE = 'SMS_GROUP_FILETR_TABE_CHANGE'
const SMS_GROUP_CHECKBOX_CHANGE = 'SMS_GROUP_CHECKBOX_CHANGE'
const SMS_GROUP_BULK_ARCIVE_F = 'SMS_GROUP_BULK_ARCIVE_F'
const SMS_GROUP_BULK_ARCIVE_R = 'SMS_GROUP_BULK_ARCIVE_R'
const SMS_GROUP_BULK_DESTROY_F = 'SMS_GROUP_BULK_DESTROY_F'
const SMS_GROUP_BULK_DESTROY_R = 'SMS_GROUP_BULK_DESTROY_R'
const SMS_GROUP_TAB_PANEL_CHANGE = 'SMS_GROUP_TAB_PANEL_CHANGE'
const SMS_GROUP_DATA_FILTER = 'SMS_GROUP_DATA_FILTER'
const SMS_GROUP_DELETE_F = 'SMS_GROUP_DELETE_F'
const SMS_GROUP_FILTER_IS_OPEN = 'SMS_GROUP_FILTER_IS_OPEN'
const SMS_GROUP_DATA_FILTER_SEARCH = 'SMS_GROUP_DATA_FILTER_SEARCH'
const SMS_GROUP_SELECT_FILTER_TYPES = 'SMS_GROUP_SELECT_FILTER_TYPES'
const SMS_GROUP_DATA_FILTER_ALL_SEARCH_RESET = 'SMS_GROUP_DATA_FILTER_ALL_SEARCH_RESET'
const SMS_GROUP_GET_ONE_P = 'SMS_GROUP_GET_ONE_P'
const SMS_GROUP_GET_ONE_F = 'SMS_GROUP_GET_ONE_F'
const SMS_GROUP_GET_ONE_R = 'SMS_GROUP_GET_ONE_R'
const SMS_GROUP_DATA_MIN_MAX_F = 'SMS_GROUP_DATA_MIN_MAX_F'

export {
    SMS_GROUP_PAG_R,
    SMS_GROUP_PAG_F,
    SMS_GROUP_SIZE_CHANGE,
    SMS_GROUP_PAGE_CHANGE,
    SMS_GROUP_FILETR_TABE_CHANGE,
    SMS_GROUP_CHECKBOX_CHANGE,
    SMS_GROUP_BULK_ARCIVE_F,
    SMS_GROUP_BULK_ARCIVE_R,
    SMS_GROUP_BULK_DESTROY_R,
    SMS_GROUP_BULK_DESTROY_F,
    SMS_GROUP_TAB_PANEL_CHANGE,
    SMS_GROUP_DATA_FILTER,
    SMS_GROUP_DELETE_F,
    SMS_GROUP_FILTER_IS_OPEN,
    SMS_GROUP_DATA_FILTER_SEARCH,
    SMS_GROUP_SELECT_FILTER_TYPES,
    SMS_GROUP_DATA_FILTER_ALL_SEARCH_RESET,
    SMS_GROUP_GET_ONE_P,
    SMS_GROUP_GET_ONE_F,
    SMS_GROUP_GET_ONE_R,
    SMS_GROUP_DATA_MIN_MAX_F
}